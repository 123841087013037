import { Component, Input } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { DialogId } from '@app/shared/dialogs.model';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { CaseDocument } from '@app/shared/cases.model';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';

@Component({
  selector: 'wr-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.scss'],
})
export class DocumentSummaryComponent {
  @Input() caseNumber: string;
  @Input() employee: string;
  @Input() documentTypeLabel: string;
  @Input() userId: string;
  @Input() reportType: string;
  @Input() summaryTitle: string;
  @Input() caseId: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() steps: DocumentFormConfigStep[];
  reportTypes = DialogId;
  docTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;

  @Input()
  set documentSummaryData(value: { [key: string]: DocumentSummaryData }) {
    if (value) {
      this._value = JSON.parse(JSON.stringify(value));
      this.documentControlsSummaries = Object.values(
        JSON.parse(JSON.stringify(value)),
      );
      this.groupedStepsSummaries = this.createGroupedStepsSummaries(
        this.documentControlsSummaries,
      );
    }
  }

  get documentSummaryData(): { [key: string]: DocumentSummaryData } {
    return this._value;
  }
  @Input()
  set caseDocumentData(value: CaseDocument) {
    if (value) {
      this._caseDocumentValue = value;
    }
  }

  get caseDocumentData(): CaseDocument {
    return this._caseDocumentValue;
  }

  private _value: { [key: string]: DocumentSummaryData };
  private _caseDocumentValue: CaseDocument;

  documentControlsSummaries: DocumentSummaryData[];
  groupedStepsSummaries: DocumentSummaryData[][];

  private createGroupedStepsSummaries(
    summaries: DocumentSummaryData[],
  ): DocumentSummaryData[][] {
    const controlsPerStep = [];
    summaries.forEach((summary) => {
      if (!summary && !summary.step) {
        return false;
      } else if (
        summary &&
        (summary.value.length === 0 ||
          (Array.isArray(summary.value) &&
            summary.value.length === 1 &&
            (summary.value[0] === '' ||
              (summary.value[0].name === '' &&
                summary.value[0].option === ''))))
      ) {
        // The above condition is to check for empty text box lists or empty lists in general
        return false;
      } else {
        controlsPerStep[summary.step] = controlsPerStep[summary.step]
          ? [...controlsPerStep[summary.step], summary]
          : [summary];
      }
    });

    return controlsPerStep;
  }

  trackBy(index: number, controls: DocumentSummaryData[]): string {
    if (controls) return controls[0].key;
  }
}
