import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';

@Component({
  selector: 'wr-form-control-password',
  templateUrl: './form-control-password.component.html',
  styleUrls: ['./form-control-password.component.scss'],
})
export class FormControlPasswordComponent {
  @Input() passwordControl!: UntypedFormControl;
  @Input() title?: string;
  @Input() placeholder?: string;
  @Input() floatLabel?: FloatLabelType;
  @Input() autoFocus = false;
  hide = true;
}
