import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { DocumentsService } from '@app/shared/documents/documents.service';
import {
  DocumentsIdentifiers,
  DocumentSummaryData,
} from '@app/shared/documents.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentBuilderService } from '@app/shared/document-builder/document-builder.service';
import { ResponsivenessService } from '@app/core/services/responsiveness/responsiveness.service';
import { BREAKPOINT_MEDIUM_SCREEN } from '@app/shared/constants/breakpoints.constants';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { DocumentEditComponent } from '../document-edit/document-edit.component';
import { CaseDocument } from '@app/shared/cases.model';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';

@Component({
  selector: 'wr-document-overview',
  templateUrl: './document-overview.component.html',
  styleUrls: ['./document-overview.component.scss'],
})
export class DocumentOverviewComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  formGroup: UntypedFormGroup;

  summary: { [key: string]: DocumentSummaryData };
  caseDocumentData: CaseDocument;
  documentTypeLabel: string;
  dialogTitle: string;
  mediumScreenBreakpoint = BREAKPOINT_MEDIUM_SCREEN;

  constructor(
    private readonly documentsService: DocumentsService,
    private readonly casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    public readonly responsivenessService: ResponsivenessService,
    private readonly dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      caseId: string;
      caseNumber: string;
      caseUser: string;
      caseType: string;
      userId: string;
      type: DocumentTemplateUniqueIdentifier;
      isOwner: boolean;
      isDraft: boolean;
      dialogId: DialogId;
    },
  ) {}
  steps: DocumentFormConfigStep[]; // NEED
  stepControls: AbstractControl[];

  ngOnInit(): void {
    this.setTexts();
    this.getDocumentFormData();
  }

  onSubmit(): void {
    this.documentsService
      .setDocumentAsCompleted(this.data.documentId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.SUMMARY_CARD_DETAILS, {
          dialogResult: true,
        });
      });
  }

  onEdit(): void {
    this.dialogHelperService.closeDialog(DialogId.SUMMARY_CARD_DETAILS, {
      dialogResult: true,
    });
    this.dialogHelperService.openDialog(
      DialogId.EDIT_DOCUMENT,
      DocumentEditComponent,
      DialogClassName.WR_DIALOG_AUTO_SIZE,
      {
        caseId: this.data.caseId,
        caseType: this.data.caseType,
        documentId: this.data.documentId,
        userId: this.data.userId,
        type: this.data.type,
        caseUser: this.data.caseUser,
      },
      { disableClose: true },
    );
  }

  onClose(): void {
    this.dialogHelperService.closeDialog(DialogId.SUMMARY_CARD_DETAILS, {
      dialogResult: false,
    });
  }

  private setTexts(): void {
    this.documentTypeLabel = DOCUMENTS_TYPE_LABELS[this.data.type];
    this.dialogTitle = 'SUMMARY.TITLE.' + this.data.type;
  }

  private getDocumentFormData(): void {
    this.documentTemplatesService
      .getDocumentFormConfig(DocumentsIdentifiers[this.data.type])
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup = formGroup;
          this.steps = workability?.versions[0]?.template?.steps;
          this.stepControls = stepControls;
          this.summarySubscription = summarySubscription;
          return this.casesService.getCaseDocument(
            this.data.caseId,
            this.data.documentId,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup,
          document.fields,
        );
        this.summary = (
          this.formGroup.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData = document;
        if (
          this.data.type === DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP &&
          this.formGroup.value.step0.control1
        ) {
          this.summary = this.formGroup.value.step0.control1;
        }
        this.addSelectOptionsToSummary(this.summary, this.steps);
      });
  }
  private addSelectOptionsToSummary(summary: any, steps: any): void {
    if (steps && summary) {
      Object.keys(summary).forEach((key, index) => {
        summary[key].selectOptions = steps
          ?.find((step) => step.step === summary[key].step)
          ?.controls?.find(
            (control) => control.key === summary[key].key,
          )?.options?.selectOptions;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }
}
