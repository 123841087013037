import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import {
  DocumentSummaryData,
  DocumentsControls,
} from '@app/shared/documents.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { DocumentSummaryCardDetailsPopupComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-popup/document-summary-card-details-popup.component';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';

@Component({
  selector: 'wr-document-summary-card',
  templateUrl: './document-summary-card.component.html',
  styleUrls: ['./document-summary-card.component.scss'],
})
export class DocumentSummaryCardComponent implements OnInit, OnDestroy {
  @Input() summaryControls: DocumentSummaryData[];
  @Input() userId: string;
  @Input() steps: DocumentFormConfigStep[];

  showMe = true;

  private readonly onDestroy$ = new Subject<null>();

  shouldShowViewMore = false;

  constructor(private readonly dialogHelperService: DialogHelperService) {}

  ngOnInit(): void {
    this.showMe =
      this.summaryControls &&
      !(
        this.summaryControls[0]?.type ===
          DocumentsControls.PERCENTAGE_SLIDER_LIST ||
        this.summaryControls[0]?.type === DocumentsControls.SLIDE_TOGGLE_LIST ||
        this.summaryControls[0]?.type === DocumentsControls.MULTI_BUTTON_SELECT
      );

    if (
      this.summaryControls &&
      this.summaryControls[0]?.type === DocumentsControls.PRIORITY_SELECTOR
    ) {
      this.showMe = this.summaryControls[0].value.length > 0;
    } else {
      this.showMe =
        this.summaryControls &&
        this.summaryControls[0] &&
        this.summaryControls[0].value &&
        this.summaryControls[0].value !== '';
    }
  }

  openDetailsPopup(): void {
    this.dialogHelperService.openDialog(
      DialogId.SUMMARY_CARD_DETAILS,
      DocumentSummaryCardDetailsPopupComponent,
      DialogClassName.WR_SUMMARY_DETAILS,
      {
        summaryControls: this.summaryControls,
        userId: this.userId,
      },
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
