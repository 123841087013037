import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {PermissionService} from "@app/shared/common/permissions.service";
import {UserService} from "@app/shared/user/user.service";
import {userOnboardingInactiveStatus} from "@app/constants/inactive-status.constants";

@Directive({
  selector: 'input[wrPermissionReadOnly], button[wrPermissionReadOnly]',
})
export class PermissionReadOnlyDirective implements AfterViewInit{
  @Input() requiredPermissions: string | string[];
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private permissionService: PermissionService,

    private userService: UserService,

  ) {}
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setupObs();

  }
  setupObs() {
    const accessData = this.userService.getAuthTokenData();
    const inactiveStatus = userOnboardingInactiveStatus
    const isInactive = inactiveStatus.includes(accessData.status);
    const permissions = Array.isArray(this.requiredPermissions)
      ? this.requiredPermissions
      : [this.requiredPermissions];
    if (this.permissionService.hasPermission(permissions) && !isInactive) {
    } else {
      setTimeout(() => {
      this.render.setProperty(
        this.el.nativeElement,
        'disabled',
        true
      );
      }, 100)
    }
  }
}
