import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { OPEN_DIALOG_TIME } from '@app/shared/constants/dialogs.constants';
import { BREAKPOINT_MEDIUM_SCREEN } from '@app/shared/constants/breakpoints.constants';
import { ResponsivenessService } from '@app/core/services/responsiveness/responsiveness.service';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';
import {LoadingService} from "@app/core/services/loading/loading.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wr-generic-stepper-dialog',
  templateUrl: './generic-stepper-dialog.component.html',
  styleUrls: ['./generic-stepper-dialog.component.scss'],
})
export class GenericStepperDialogComponent implements AfterViewInit, OnDestroy {
  @ContentChildren('step', { read: TemplateRef }) templatesQueryList: QueryList<TemplateRef<any>>;

  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;

  @Input() showHeaderTitle = true;
  @Input() showCloseIcon = true;
  @Input() showSubmitButton = true;
  @Input() showBreadcrumb = true;
  _stepControls: AbstractControl[] = [];
  @Input() isSummaryDisabled = false;
  @Input() isNextDisabled = false;
  @Input() hasAdditionalStep = false;
  @Input() isAdditionalStepSummary = false;
  @Input() hasAdditionalEndStep = false;
  @Input() allowSkip = false;


  @Input() employee: string;
  @Input() documentTypeLabel: string;
  @Input() caseType: string;
  @Input() data: DocumentFormConfigStep[];

  @Output() selectedStep = new EventEmitter<number>();
  @Output() submitted = new EventEmitter<void>();
  @Output() skipped = new EventEmitter<void>();
  @Output() runStepChangeAction = new EventEmitter<number>();
  private readonly onDestroy$ = new Subject<null>();

  viewInitialized = false;
  mediumScreenBreakpoint = BREAKPOINT_MEDIUM_SCREEN;
  step = 0;
  stepControlIndex = this.hasAdditionalStep ? -1 : 0;

  title: string;
  subtitle: string;
  isCurrentPageSummary: boolean;
  firstLoad = true;
  selectedLanguage = "en";
  constructor(public readonly responsivenessService: ResponsivenessService,
              public loadingService: LoadingService,
              private readonly translateService: TranslateService) {
              }

  ngAfterViewInit(): void {
    this.selectedLanguage = this.translateService.currentLang;
    this.emitSelectedStepChange();
    // FIXME : it's hack to display steps icons when their position is ready
    setTimeout(() => {
      this.viewInitialized = true;


    }, OPEN_DIALOG_TIME);
  }
  @Input() set stepControls(value: AbstractControl[]) {
    this._stepControls = value;
  }
  get stepControls(): AbstractControl[] {
    return this._stepControls;
  }

  private emitSelectedStepChange(): void {
    this.stepper.selectionChange
      .pipe(
        map((event) => event.selectedIndex),
        takeUntil(this.onDestroy$),
      )
      .subscribe((index) => {
        if (index < this.stepper.steps.length - 1) {
          this.runStepChangeAction.emit(index);
        }
        this.step = index;
        this.selectedStep.emit(index);
        this.checkPreviousStepsValidity(index);
        let stepIndex = index;
        if (this.hasAdditionalStep) {
          stepIndex = stepIndex - 1;
        }
        this.stepControlIndex = stepIndex;
        this.firstLoad = false;
        this.title = this.data[stepIndex]?.title ? this.data[stepIndex]?.title[this.selectedLanguage] : null;
        this.subtitle = this.data[stepIndex]?.description ? this.data[stepIndex]?.description[this.selectedLanguage] : null;
        this.isCurrentPageSummary = this.data[stepIndex]?.isSummary;
      });

  }

  private checkPreviousStepsValidity(step: number = this.step): void {
    const invalidIndex: number = this._stepControls.findIndex((control) => (control ? control.invalid : false));
    if (invalidIndex > 0 && invalidIndex < step) {
      // setTimeout is needed to set selectedIndex
      // https://stackoverflow.com/questions/52142620/angular-stepper-with-selectedindex-set-matsteppernext-matstepperprevious-not-wor
      setTimeout(() => {
        this.stepper.selectedIndex = invalidIndex;
        this.selectedStep.emit(invalidIndex);
      });
    }
  }

  trackBy(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
