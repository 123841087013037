export enum DocumentTemplateUniqueIdentifier {
  ISSUE_NEW_REPORT = 'ISSUE_NEW_REPORT',
  EQUIPMENT_NEW_REPORT = 'EQUIPMENT_NEW_REPORT',
  TEMPORARY_CHANGE_REQUEST_NEW_REPORT = 'TEMPORARY_CHANGE_REQUEST_NEW_REPORT',
  SELF_CERTIFICATION_NEW_REPORT = 'SELF_CERTIFICATION_NEW_REPORT',
  SICK_LEAVE_NEW_REPORT = 'SICK_LEAVE_NEW_REPORT',
  WORKABILITY_NEW_REPORT = 'WORKABILITY_NEW_REPORT',
  SELF_CERTIFICATION_AGREEMENT = 'SELF_CERTIFICATION_AGREEMENT',
  ISSUE_NEW_REPORT_AGREEMENT = 'ISSUE_NEW_REPORT_AGREEMENT',
  EQUIPMENT_NEW_REPORT_AGREEMENT = 'EQUIPMENT_NEW_REPORT_AGREEMENT',
  WORKABILITY_UPDATE = 'WORKABILITY_UPDATE',
  FACILITATION = 'FACILITATION',
  FOLLOW_UP_PLAN = 'FOLLOW_UP_PLAN',
  NAV_FILLING = 'NAV_FILLING',
  DIALOGUE_MEETING_1 = 'DIALOGUE_MEETING_1',
  DIALOGUE_MEETING_2 = 'DIALOGUE_MEETING_2',
  DIALOGUE_MEETING_3 = 'DIALOGUE_MEETING_3',
  FACILITATION_AGREEMENT = 'FACILITATION_AGREEMENT',
  FOLLOW_UP_PLAN_AGREEMENT = 'FOLLOW_UP_PLAN_AGREEMENT',
  FOLLOW_UP_DOCUMENT = 'FOLLOW_UP_DOCUMENT',
  FOLLOW_UP_DOCUMENT_REVIEW = 'FOLLOW_UP_DOCUMENT_REVIEW',
  FOLLOW_UP_DOCUMENT_MEETING_REVIEW = 'FOLLOW_UP_DOCUMENT_MEETING_REVIEW',
  FOLLOW_UP_DOCUMENT_MEETING = 'FOLLOW_UP_DOCUMENT_MEETING',
  FOLLOW_UP_DOCUMENT_QUESTIONS = 'FOLLOW_UP_DOCUMENT_QUESTIONS',
  FOLLOW_UP_DOCUMENT_UPDATE = 'FOLLOW_UP_DOCUMENT_UPDATE',
  FOLLOW_UP_SCHEDULER = 'FOLLOW_UP_SCHEDULER',
  TEMPORARY_CHANGE_REQUEST_AGREEMENT = 'TEMPORARY_CHANGE_REQUEST_AGREEMENT',
  BASIC_FOLLOW_UP = 'BASIC_FOLLOW_UP',

  SICK_LEAVE = 'SICK_LEAVE',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  FML = 'FML',
  SELF_CERTIFICATION = 'SELF_CERTIFICATION',
  TEMPORARY_CHANGE_REQUEST = 'TEMPORARY_CHANGE_REQUEST',
  EQUIPMENT = 'EQUIPMENT',
  ISSUE = 'ISSUE',
  CLOSE_BY_LEADER = 'CLOSE_BY_LEADER',
  BASIC_FOLLOW_UP_CALL = 'BASIC_FOLLOW_UP_CALL',
  BASIC_FOLLOW_UP_MESSAGE = 'BASIC_FOLLOW_UP_MESSAGE',
  RESIDUAL_WORK_CAPACITY = 'RESIDUAL_WORK_CAPACITY',
  WORKPLACE_ACCOMMODATION = 'WORKPLACE_ACCOMMODATION',
  FML_NEW_REPORT = 'FML_NEW_REPORT',
  FML_AGREEMENT = 'FML_AGREEMENT'
}
