import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-horizontal-button-radio',
  templateUrl: './horizontal-button-radio.component.html',
  styleUrls: ['./horizontal-button-radio.component.scss'],
})
export class HorizontalButtonRadioComponent {
  @Input() control: UntypedFormControl;
  @Input() selectOptions: SelectOption<string>[];
  @Input() description: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() size: string;
  @Output() valueChange = new EventEmitter<any>();

  trackBy(index: number, option: SelectOption<string>): string {
    return option.value;
  }
}
