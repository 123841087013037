<wr-generic-report
  #sickLeaveReport
  [getDocument$]="getDocument$"
  [caseType]="caseType"
  [documentTypeLabel]="documentTypeLabel"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  [reportType]="data.dialogId"
  [rowsCount]="16"
  [hasHasCustomTitle]="true"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
>
  <ng-container title>
    <h3
      *ngIf="
        sickLeaveReport?.steps?.length == sickLeaveReport?.selectedStep + 1
      "
      class="step-description"
      mat-dialog-title
    >
      {{ documentTypeLabel | translate }}
    </h3>
  </ng-container>
</wr-generic-report>
