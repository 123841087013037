<mat-toolbar>
  <span class="space--big is-closed">
    <button
      mat-icon-button
      class="no-background isShow app-logo"
      (click)="gotoHome()"
    >
      <img src="../../../../../assets/images/svg/app-logo.svg" class="mt-n2" />
    </button>
  </span>
  <ng-container [ngSwitch]="headerState">
    <ng-container *ngSwitchDefault>
      <mat-slide-toggle
        class="me-4"
        [(ngModel)]="isToggleChecked"
        (change)="onToggleChange()"
        *ngIf="isToggleVisible"
      ></mat-slide-toggle>
      <div class="app__actions">
        <button
          mat-icon-button
          class="no-background"
          [matMenuTriggerFor]="secondaryMenu"
        >
          <mat-icon
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
              />
            </svg>
          </mat-icon>
        </button>

        <button
          mat-mini-fab
          color="primary"
          class="no-background feedback-icon"
          (click)="initiateFeedback()"
          title="Feedback"
        >
          <mat-icon svgIcon="feedback-icon" class="icon-svg-primary mt-n1">
          </mat-icon>
        </button>
        <button
          mat-icon-button
          class="no-background"
          (click)="navigate(routeSegments.MESSAGE_CENTER)"
          *ngIf="!accessData?.isExternal && !accessData?.isSuperAdmin"
        >
          <mat-icon class="icon-svg-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M280-240q-17 0-28.5-11.5T240-280v-80h520v-360h80q17 0 28.5 11.5T880-680v600L720-240H280ZM80-280v-560q0-17 11.5-28.5T120-880h520q17 0 28.5 11.5T680-840v360q0 17-11.5 28.5T640-440H240L80-280Zm520-240v-280H160v280h440Zm-440 0v-280 280Z"
              />
            </svg>
          </mat-icon>
        </button>
        <button
          mat-icon-button
          class="no-background notification-dropdown-icon"
          [matMenuTriggerFor]="notification"
          *ngIf="!accessData?.isExternal"
          [disabled]="!notificationList || notificationList.length === 0"
        >
          <mat-icon class="icon-svg-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path
                d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z"
              />
            </svg>
          </mat-icon>
          <span
            class="mat-badge-content mat-badge-active mat-badge-natification"
            *ngIf="unreadNotificationCount > 0"
            >{{ unreadNotificationCount }}</span
          >
        </button>
        <mat-menu #notification="matMenu" class="notification-menu">
          <div
            class="border-bottom d-flex justify-content-between align-items-center px-3 py-2"
            *ngIf="notificationList && notificationList.length > 0"
          >
            <h3 class="mb-0">{{ 'NOTIFICATION.TITLE' | translate }}</h3>
            <span
              class="small text-decoration-underline cursor-pointer"
              (click)="markAllAsRead(); $event.stopPropagation()"
              ><small>{{
                'NOTIFICATION.MARK_ALL_AS_READ' | translate
              }}</small></span
            >
          </div>
          <wr-notification
            [notifications]="notificationList"
            [currentUser]="currentUser"
          ></wr-notification>
          <button
            mat-menu-item
            class="text-center"
            (click)="navigateToNotifications()"
          >
            {{ 'NOTIFICATION.VIEW_ALL' | translate }}
          </button>
        </mat-menu>
        <wr-lang-selector></wr-lang-selector>

        <button
          mat-icon-button
          mat-menu-trigger-custom
          class="no-background"
          [matMenuTriggerFor]="menu"
          *ngIf="!mobileQuery.matches; else mobileMenuButton"
        >
          <img
            class="avatar"
            [src]="
              imgUrl
                ? imgUrl
                : '../../../../../../../assets/images/temp/avatar.png'
            "
            onerror="this.onerror=null;this.src='../../../../../../../assets/images/temp/avatar.png'"
          />
        </button>
        <ng-template #mobileMenuButton>
          <button
            mat-icon-button
            class="no-background"
            (click)="openMobileMenu()"
          >
            <img
              class="avatar"
              [src]="
                imageUrl
                  ? imgUrl
                  : '../../../../../../../assets/images/temp/avatar.png'
              "
              onerror="this.onerror=null;this.src='../../../../../../../assets/images/temp/avatar.png'"
            />
          </button>
          <!-- //add "active" class to slide in mobile view -->
          <div
            class="
            "
            [ngClass]="{ active: mobileMenuOpened }"
          >
            <div class="text-end mt-2 me-2 mb-3">
              <button mat-icon-button color="dark" (click)="closeMobileMenu()">
                <mat-icon class="fs-2">close</mat-icon>
              </button>
            </div>
            <wr-mobile-menu
              [isAdmin]="accessData.isCompanyAdmin"
              [imgUrl]="
                imgUrl
                  ? imgUrl
                  : '../../../../../../../assets/images/temp/avatar.png'
              "
              [currentUser]="currentUser"
              [companyId]]="companyId"
              (routeClicked)="closeMobileMenu()"
            ></wr-mobile-menu>
          </div>
        </ng-template>
        <mat-menu #menu="matMenu" class="navbar-profile-dropdown">
          <div
            class="d-flex align-items-center navbar-profile-name bg-light border-bottom mt-n2 mb-2"
          >
            <img
              class="avatar me-3"
              [src]="
                imgUrl
                  ? imgUrl
                  : '../../../../../../../assets/images/temp/avatar.png'
              "
              onerror="this.onerror=null;this.src='../../../../../../../assets/images/temp/avatar.png'"
            />
            <div>
              <h5 class="mb-0 text-truncate">
                {{ currentUser?.firstName }} {{ currentUser?.lastName }}
              </h5>
              <p class="text--small mb-0 text-truncate">
                {{ currentUser?.email }}
              </p>
            </div>
          </div>
          <button mat-menu-item (click)="navigate(routeSegments.PROFILE_PAGE)">
            <span>{{ 'MENU.MY_PROFILE.LABEL' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="!(accessData.isSuperAdmin || accessData?.isExternal)"
            (click)="
              navigate(
                routeSegments.PROFILE_PAGE +
                  '/' +
                  routeSegments.WORKSPACE_PROFILE
              )
            "
          >
            <span>{{ 'MENU.WORKSPACE_PROFILE.LABEL' | translate }}</span>
          </button>
          <button
            mat-menu-item
            *ngIf="accessData.isCompanyAdmin"
            (click)="navigate(routeSegments.COMPANY_PROFILE)"
          >
            <span>{{ 'MENU.ACCOUNT.LABEL' | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="
              navigate(
                routeSegments.PROFILE_PAGE + '/' + routeSegments.CHANGE_PASSWORD
              )
            "
          >
            <span>{{ 'MENU.CHANGE_PASSWORD.LABEL' | translate }}</span>
          </button>
          <button mat-menu-item (click)="onLogoutClick()">
            <span>{{ 'MENU.LOG_OUT.LABEL' | translate }}</span>
          </button>
        </mat-menu>
        <mat-menu #secondaryMenu="matMenu" xPosition="before">
          <small class="text-secondary m-3">Help</small>
          <button mat-menu-item class="d-flex gap-2">
            <span class="material-icons fs-5"> find_in_page </span>
            <span (click)="openLink('https://workability.no/user-guide')">{{
              'MENU.USER_GUIDES.LABEL' | translate
            }}</span>
          </button>
          <button mat-menu-item class="d-flex gap-2 d-none">
            <span class="material-icons fs-5"> contact_support </span>
            <span (click)="openLink('https://workability.no/faq')">{{
              'MENU.FAQ.LABEL' | translate
            }}</span>
          </button>
          <button
            mat-menu-item
            class="d-flex gap-2 border-top"
            (click)="openContactUs()"
          >
            <span class="material-icons fs-5"> perm_phone_msg </span>
            <span>{{ 'MENU.CONTACT_US.LABEL' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </ng-container>
</mat-toolbar>
