import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { congratsWelcomeComponentData } from '@app/shared/congratsWelcome.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CaseDocument } from '@app/shared/cases.model';

@Component({
  selector: 'wr-congrats-welcome',
  templateUrl: './congrats-welcome.component.html',
  styleUrls: ['./congrats-welcome.component.scss'],
})
export class CongratsWelcomeComponent implements OnInit {
  @Input() data: any = congratsWelcomeComponentData.COMPANY;
  @Input() companyName = '';
  @Output() clicked = new EventEmitter<CaseDocument>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.data.logo = this.sanitizer.bypassSecurityTrustHtml(this.data.logo);
  }
}
