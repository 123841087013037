import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { DatetimeService } from '@app/shared/datetime/datetime.service';
import { SIMPLE_DATE_FORMAT } from '@app/shared/constants/datetime.constants';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private _disabled: boolean;

  controlCopy: UntypedFormControl;

  @Input() control: UntypedFormControl = new UntypedFormControl('');
  @Input() description: string;
  @Input() label: string;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (this.controlCopy) {
      value ? this.controlCopy.disable() : this.controlCopy.enable();
    }
    this._disabled = value;
  }

  constructor(
    private readonly datetimeService: DatetimeService,
    private readonly iconsHelperService: IconsHelperService,
  ) {
    this.iconsHelperService.registerSvgIcons('calendar');
  }

  ngOnInit(): void {
    this.watchValueChangesAndFormatDate();
  }

  private watchValueChangesAndFormatDate(): void {
    const isDateFormatSimple = this.datetimeService.checkIsDateFormatSimple(this.control.value);
    let controlCopyValue = this.control.value;
    if (isDateFormatSimple) {
      controlCopyValue = this.datetimeService.formatSimpleDateToDayjs(controlCopyValue).toISOString();
    }
    this.controlCopy = new UntypedFormGroup({ date: new UntypedFormControl(controlCopyValue) }).get('date') as UntypedFormControl;
    this.disabled ? this.controlCopy.disable() : this.controlCopy.enable();
    if (!isDateFormatSimple) {
      this.control.patchValue(this.datetimeService.date(this.control.value).format(SIMPLE_DATE_FORMAT));
    }
    this.controlCopy.valueChanges
      .pipe(
        filter((value) => !this.datetimeService.checkIsDateFormatSimple(value)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((value) => {
        this.control.patchValue(this.datetimeService.date(value).format(SIMPLE_DATE_FORMAT));
      });
    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => this.datetimeService.checkIsDateFormatSimple(value)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((value) => {
        this.controlCopy.patchValue(this.datetimeService.formatSimpleDateToDayjs(value).toISOString());
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
