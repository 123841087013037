<wr-app-sidenav *ngIf="isSidenavShowed; else noSidenavContent">
  <div [class]="isOpen ? 'screenshot-mode' : ''" #screenshotArea>
    <h1 *ngIf="isOpen">
      {{ isOpen ? ('SCREENSHOT.HELP.TEXT' | translate) : '' }}
    </h1>
    <wr-app-header (screenshotClicked)="isOpen = true"></wr-app-header>

    <div class="app__container authorized-view">
      <router-outlet></router-outlet>
    </div>
  </div>
  <ngx-capture
    (resultImage)="saveImage($event)"
    *ngIf="isOpen"
    [target]="screenshotArea"
  ></ngx-capture>
</wr-app-sidenav>

<ng-template #noSidenavContent>
  <div class="app__container">
    <!-- <wr-app-header></wr-app-header> -->
    <router-outlet></router-outlet>
  </div>
</ng-template>
