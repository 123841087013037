export interface SidenavItem {
  icon: string;
  labelKey: string;
  link?: string;
  action?: SidenavItemsActions;
  additionalLabelKey?: string;
  permissions: string[];
  productionEnabled?: boolean;
  mobileIndex?: number;
  devEnabled?: boolean;
}

export enum SidenavItemsActions {
  NEW_ACTION = 'new-action',
}

export enum MenuStateBodyClass {
  OPENED = 'menu-opened',
  CLOSED = 'menu-closed',
}

export enum PermissionName {
  CASES_RO = 'cases_ro',
  CASES_RW = 'cases_rw',
  CHANNELS_RO = 'channels_ro',
  CHANNELS_RW = 'channels_rw',
  DOCUMENTS_RO = 'documents_ro',
  DOCUMENTS_RW = 'documents_rw',
  DOC_TEMPLATES_RO = 'doc_templates_ro',
  DOC_TEMPLATES_RW = 'doc_templates_rw',
  TASKS_RO = 'tasks_ro',
  TASKS_RW = 'tasks_rw',
  TEMP_PERMISSION_RO = 'temp_permission_ro',
  TEMP_PERMISSION_RW = 'temp_permission_rw',
  CUSTOMER_RW = 'customer_rw',
  CUSTOMER_RO = 'customer_ro',
}
