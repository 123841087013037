import {
  Attribute,
    Component, HostBinding, Input,
  } from "@angular/core";

/* tslint:disable:component-selector */
@Component({
    selector: "button[wrButton]",
    template: `
    <div class="label"><ng-content></ng-content></div>
    <div class="loader">
      <span *ngIf="loadingType==='text'; else imageLoader">{{loadingText}}</span>
    </div>
    <ng-template #imageLoader>
    <img [src]="'../../../../../assets/images/svg/btn-loading-' + (loadingType) + '.svg'"/>
    </ng-template>
    `,
    styleUrls: ["./button.component.scss"]
  })
  export class WrButtonComponent {
      @HostBinding("class.loading")
      @HostBinding("attr.aria-disabled")
      @Input()
      loading = false;

      @Input()
      loadingType ?= LoadTypeEnum.DEFAULT;

      @Input()
      loadingText = "...";

      @HostBinding("class")
      get classes(): string {
        return this.variant || "primary";
      }

      /**
       * Why we used @Atrribute instead of @Input:
       * @link https://netbasal.com/getting-to-know-the-attribute-decorator-in-angular-4f7c9fb61243
       * @param variant - button Variant
       */
      constructor(
        @Attribute("variant")
        private variant: "primary" | "secondary" | "outline" | "inverted"= "primary"
      ) {}
    }

export enum LoadTypeEnum{
      DEFAULT= "default",
      TEXT= "text",
      CIRCLE_LINES= "circle-lines",
      SEARCH= "search"
    }

