import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IArticlesRes,
  ICheckListTrackerRes,
  ICompanyAccountForm,
  ICompanyAccountResponse,
  ICompanyWorkspace,
  IContactPersonResponse,
  IPaginationData,
} from '@app/shared/interfaces/ICompanyAccount';
import { environment } from '@environment/environment';
import { ApiList } from '@app/app.constants';
import { IPermissionRes, IRoleRes } from '../../models/roles.model';

@Injectable({
  providedIn: 'root',
})
export class SysAdminService {
  constructor(private http: HttpClient) {}

  createAccount = (accountDetails: ICompanyAccountForm): Promise<any> => {
    return this.http
      .post<void>(
        `${environment.baseUrl}${ApiList.accounts.create}`,
        accountDetails,
      )
      .toPromise();
  };

  updateAccount = (
    accountDetails: ICompanyAccountForm,
    id: string,
  ): Promise<any> => {
    return this.http
      .put<void>(
        `${environment.baseUrl}${ApiList.accounts.create}/${id}`,
        accountDetails,
      )
      .toPromise();
  };

  getCreatedAccounts = (
    paginationData: IPaginationData,
    searchText: string,
  ): Promise<any> => {
    return this.http
      .get<void>(`${environment.baseUrl}${ApiList.accounts.create}`, {
        params: {
          limit: paginationData.limit.toString(),
          skip: paginationData.skip.toString(),
          sort: paginationData.sort,
          searchString: searchText,
        },
      })
      .toPromise();
  };

  getAccountDetailsById(id: string | null): Promise<ICompanyAccountResponse> {
    return this.http
      .get<ICompanyAccountResponse>(
        `${environment.baseUrl}${ApiList.accounts._}/${
          id ? id : 'workspace-details'
        }`,
      )
      .toPromise();
  }

  getWorkspaceDetails(id: string | null): Promise<ICompanyWorkspace[]> {
    return this.http
      .get<ICompanyWorkspace[]>(
        `${environment.baseUrl}${ApiList.accounts._}${ApiList.accounts.workspaceDetail}`,
        { params: id ? { id } : null },
      )
      .toPromise();
  }
  getContactPersons(id: string | null): Promise<IContactPersonResponse> {
    return this.http
      .get<IContactPersonResponse>(
        `${environment.baseUrl}${ApiList.setup.contactPerson}/${id}`,
      )
      .toPromise();
  }

  uploadCompanyLogo(image: File): Promise<{ url: string }> {
    const formDataFinal = new FormData();
    formDataFinal.append('file', image);
    return this.http
      .post<{ url: string }>(
        `${environment.baseUrl}${ApiList.accounts.uploadCompanyLogo}`,
        formDataFinal,
      )
      .toPromise();
  }

  getArticleList = (
    paginationData: IPaginationData,
    searchText: string,
  ): Promise<{ count: number; data: IArticlesRes[] }> => {
    const params = {
      limit: paginationData.limit.toString(),
      skip: paginationData.skip.toString(),
      sort: paginationData.sort,
      searchString: searchText ?? '',
    };
    const filterData = Object.entries(params)
      .filter(
        ([key, value]) =>
          key && +value !== -1 && value !== '' && value !== null,
      )
      .reduce((obj: { [key: string]: any }, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    return this.http
      .get<{ count: number; data: IArticlesRes[] }>(
        `${environment.baseUrl}${ApiList.articles._}`,
        {
          params: filterData,
        },
      )
      .toPromise();
  };

  addArticle(reqData: object): Promise<{ url: string }> {
    return this.http
      .post<{ url: string }>(
        `${environment.baseUrl}${ApiList.articles._}`,
        reqData,
      )
      .toPromise();
  }

  getCheckListTracker(): Promise<ICheckListTrackerRes[]> {
    return this.http
      .get<ICheckListTrackerRes[]>(
        `${environment.baseUrl}${ApiList.checklistTracker._}`,
      )
      .toPromise();
  }
  cancelChecklistItem(key: string): Promise<any> {
    return this.http
      .put<any[]>(
        `${environment.baseUrl}${ApiList.checklistTracker._}/${ApiList.checklistTracker.cancel}`,
        {
          key,
          status: false,
        },
      )
      .toPromise();
  }

  getRoles(): Promise<IRoleRes[]> {
    return this.http
      .get<IRoleRes[]>(`${environment.baseUrl}${ApiList.roles._}`)
      .toPromise();
  }
  getAllPermissions(roleKey: string): Promise<IPermissionRes[]> {
    return this.http
      .get<IPermissionRes[]>(
        `${environment.baseUrl}${ApiList.roles._}/${roleKey}/${ApiList.roles.permissions.all}`,
      )
      .toPromise();
  }
  getPermissions(roleKeys: string[]): Promise<string[]> {
    return this.http
      .post<string[]>(
        `${environment.baseUrl}${ApiList.roles._}/${ApiList.roles.permissions._}`,
        roleKeys,
      )
      .toPromise();
  }

  updatePermissions = (key: string, permissions: string[]): Promise<any> => {
    return this.http
      .put<void>(`${environment.baseUrl}${ApiList.roles._}/${key}`, permissions)
      .toPromise();
  };
}
