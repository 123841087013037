import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from "@angular/forms";
import {SelectOption} from "@app/shared/common.model";

@Component({
    selector: 'wr-slide-toogle-list',
    templateUrl: './slide-toogle-list.component.html',
    styleUrls: ['./slide-toogle-list.component.scss']
})
export class SlideToogleListComponent {
    @Input() formArray: UntypedFormArray;
    @Input() selectOptions: SelectOption<string>[];
    @Input() description: string;
    @Output() valueChange = new EventEmitter<any>();

    value: string[];
    icons = {
        JOB_ROLE: 'person',
        TASKS: 'work',
        LOCATION: 'location_on',
        WORKING_HOURS: 'chronic',
    };

    constructor(private readonly fb: UntypedFormBuilder) {
        // setTimeout(() => {
        //     this.valueChange.emit(true)
        // }, 1000)
    }

    onCheckboxChange(checked: boolean, value: string): void {
        const arrayValue: string[] = this.formArray.getRawValue();
        this.value = arrayValue;

        const valueInArrayIndex = arrayValue.findIndex((val) => val === value);
        if (valueInArrayIndex > -1 && !checked) {
            this.formArray.removeAt(valueInArrayIndex);
        } else if (valueInArrayIndex === -1 && checked) {
            this.formArray.push(this.fb.control(value));
        }
        this.valueChange.emit(true)
    }

    trackBy(index: number, option: SelectOption<string>): string {
        return option.value;
    }
}
