<wr-generic-dialog
  [showHeaderTitle]="false"
  [showFooter]="false"
  [showBreadcrumb]="false"
>
  <ng-container content>
    <mat-horizontal-stepper
      #stepper
      linear
      class="wa-stepper-inner hide-stepper-header"
      headerPosition="bottom"
    >
      <mat-step completed>
        <div class="wa-account-card-title">
          <p class="mb-0 small">
            {{ 'SETUP.WORKSPACE.TITLE' | translate }}
          </p>
          <h1 class="text-primary font-weight-bold">
            {{ 'SETUP.ORGANISATION' | translate }}
          </h1>
        </div>
        <p class="mb-2 small">
          {{ subtitle | translate }}
        </p>
        <wr-invite-employee-form
          [component]="componentName"
          [hrLeaderUserList]="hrList"
          [hrId]="data.teamData?.hrContactId"
          [leaderUserList]="leaderList"
          [config]="
            data && data.inviteFormConfig
              ? data.inviteFormConfig
              : {
                  isNameRequired: false,
                  backButton: false,
                  autoSave: false,
                  promptSaveAsAdmin: true,
                  isLeaderRequired: false
                }
          "
          [role]="role"
          [selectRole]="selectRole"
          [title]="subtitle2"
          [buttonText]="'BUTTON.ADD'"
          (backClicked)="handleBack()"
          (formDataOnlyAfterSubmit)="handleInviteFormData($event)"
          (formSubmitted)="handleFormSubmitted($event)"
        >
        </wr-invite-employee-form>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>
</wr-generic-dialog>
