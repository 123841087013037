import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TokenKey } from '@app/shared/common.model';
import { SecurityService } from '@app/core/services/security/security.service';
import { RouteSegments } from '@app/shared/routing.model';
import { StepToUserOnboardingStatusMapping } from '@app/modules/setup/setup.model';
import { SetupService } from '@app/modules/setup/setup.service';
import { UserService } from '../../services/user/user.service';
import { RoleName } from '../../constants/role.constants';
import { UserOnboardingStatus } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SetupFlowGuard implements CanActivate {
  private tryCounter = 0;

  constructor(
    private securityService: SecurityService,
    private router: Router,
    private setupService: SetupService,
    private userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Check if user is logged in
    if (!this.userService.isUserLoggedIn()) return false;

    const accessData = this.userService.getAuthTokenData();
    // If user setup is complete, handle it
    if (!accessData.setup) {
      this.handleCompleteSetup(state);
    }

    // Fetch workspace status from setup service
    return this.setupService
      .getWorkspaceStatus()
      .then((res) => {
        if (res.status === UserOnboardingStatus.ACTIVE) return true;
        // Set session item if user has company admin role
        if (res.roles?.includes(RoleName.COMPANY_ADMIN)) {
          this.securityService.setSessionItem(TokenKey.ADMIN_SETUP_STATUS, {
            component: '',
            value: true,
          });
        }

        if (!accessData.setup) {
          // Reset try counter and handle incomplete setup
          this.tryCounter = 0;
          this.handleCompleteSetup(state);
          return true;
        } else {
          // Increment try counter and check navigation limits
          this.tryCounter++;
          if (this.tryCounter > 15) {
            this.router.navigate([RouteSegments.LANDING_PAGE]);
            return false;
          }

          // Check if user is navigating to the correct step based on status
          const indexOfTargetURL = this.findIndexOfTargetURL(state);

          const fragmentStatus = state.url.split('#')[1];
          const minIndexAllowedByStatus = this.findMinIndexAllowedByStatus(res);

          if (
            minIndexAllowedByStatus > -1 &&
            indexOfTargetURL !== minIndexAllowedByStatus
          ) {
            this.navigateToAllowedStep(
              minIndexAllowedByStatus,
              res.status.toString(),
            );
            return false;
          } else if (
            minIndexAllowedByStatus > -1 &&
            indexOfTargetURL === minIndexAllowedByStatus &&
            (!fragmentStatus || fragmentStatus !== res.status.toString())
          ) {
            this.navigateToAllowedStep(
              minIndexAllowedByStatus,
              res.status.toString(),
            );
            return false;
          }
          return true;
        }
      })
      .catch((error) => {
        // Handle error gracefully
        console.error('Error in canActivate:', error);
        // For simplicity, return false in case of error
        return false;
      });
  }

  // Handle incomplete setup by redirecting to landing page if necessary
  private handleCompleteSetup(state: RouterStateSnapshot): void {
    const stepsMappingIgnored = StepToUserOnboardingStatusMapping.filter(
      (x) => x.route !== RouteSegments.COMPANY_PROFILE,
    );
    const currentRouteIndex = stepsMappingIgnored.findIndex(
      (step) => this.getFullSetupUrl(step.route) == state.url.split('#')[0],
    );
    if (currentRouteIndex > -1) {
      this.router.navigate([RouteSegments.LANDING_PAGE], { relativeTo: null });
    }
  }

  // Find index of target URL in setup steps mapping
  private findIndexOfTargetURL(state: RouterStateSnapshot): number {
    return StepToUserOnboardingStatusMapping.findIndex(
      (step) => this.getFullSetupUrl(step.route) == state.url.split('#')[0],
    );
  }

  // Find minimum index allowed by status in setup steps mapping
  private findMinIndexAllowedByStatus(res: any): number {
    return StepToUserOnboardingStatusMapping.findIndex((step) => {
      return step.status.some((itemStatus) => itemStatus == res.status);
    });
  }

  // Navigate to the allowed setup step based on status
  private navigateToAllowedStep(index: number, statusFragment: string): void {
    this.router.navigate(
      [`${StepToUserOnboardingStatusMapping[index].route}`],
      { relativeTo: null, fragment: statusFragment },
    );
  }

  // Generate full setup URL
  private getFullSetupUrl(route: string): string {
    return `/${route}`;
  }
}
