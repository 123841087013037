import { Component, Input, OnInit } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { WORKING_HOURS_DAYS_IN_SUMMARY } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-working-hours/document-summary-card-details-working-hours.constants';

@Component({
  selector: 'wr-document-summary-card-details-working-hours',
  templateUrl: './document-summary-card-details-working-hours.component.html',
  styleUrls: ['./document-summary-card-details-working-hours.component.scss'],
})
export class DocumentSummaryCardDetailsWorkingHoursComponent implements OnInit {
  @Input() summaryControl: DocumentSummaryData;

  isNumberType: boolean;
  daysInSummary;

  ngOnInit(): void {
    if (this.summaryControl) {
      this.isNumberType = typeof this.summaryControl.value === 'number' || !isNaN(this.summaryControl.value);
      this.createDaysInSummary();
    }
  }

  private createDaysInSummary(): void {
    if (!this.isNumberType) {
      this.daysInSummary = WORKING_HOURS_DAYS_IN_SUMMARY.map((day) => {
        const dayValue = Object.entries(this.summaryControl.value).find(([key]) => {
          return key === day.key;
        })?.[1] as { from: string; to: string; active: boolean };
        return {
          ...dayValue,
          label: day.label,
        };
      });
    }
  }
}
