import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CalendarCustomHeaderComponent
} from "@app/shared/components/documents/form-controls/calendar-custom-header/calendar-custom-header.component";

@Component({
  selector: 'wr-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Input() type: 'calendar' | 'dropdown' = "dropdown";
  @Input() inputLabel: string;
  @Input() minDate = new Date();
  @Input() maxDate: string;
  @Input() autofocus: boolean;
  @Input() value: string = new Date().toString();
  @Output() valueChange = new EventEmitter<string>();
  calendarCustomHeader = CalendarCustomHeaderComponent;
  onChange(date: string) {
    this.value = date;
    this.valueChange.emit(this.value);
  }

}
