import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as duration from 'dayjs/plugin/duration';

import { HoursAndMinutes } from '@app/shared/datetime/datetime.model';
import { SIMPLE_DATE_FORMAT_REGEXP } from '@app/shared/constants/datetime.constants';

dayjs.extend(utc);
dayjs.extend(duration);

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  date(
    date?: string | number | Date | dayjs.Dayjs,
    isUtc = false,
  ): dayjs.Dayjs {
    let newDate = dayjs(date);
    if (isUtc) {
      newDate = newDate.utc(false);
    }
    return newDate;
  }

  getHoursAndMinutesFromTime(time: string): HoursAndMinutes {
    const [hours, minutes]: number[] = time
      .split(':')
      .map((unitOfTime) => Number.parseInt(unitOfTime, 10));
    return { hours, minutes };
  }

  formatDuration(durationMilliseconds: number, format: string): string {
    return dayjs.duration(durationMilliseconds).format(format);
  }

  formatSimpleDateToDayjs(date: string): dayjs.Dayjs {
    const isDateFormatSimple = this.checkIsDateFormatSimple(date);
    if (!isDateFormatSimple) {
      throw new Error(`Provided date (${date}) isn\'t simple date format`);
    }
    const [day, month, year] = date.split('/');
    return this.date(
      new Date(
        Number.parseInt(year, 10),
        Number.parseInt(month, 10) - 1,
        Number.parseInt(day, 10),
      ),
    );
  }

  checkIsDateFormatSimple(date: string): boolean {
    return SIMPLE_DATE_FORMAT_REGEXP.test(date);
  }
}
