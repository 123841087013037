<form *ngIf="formArray && fakeArray" [formGroup]="formArray.parent" class="dropdown-with-percentage__form">
  <div class="dropdown-with-percentage__container">
    <!-- <h3 *ngIf="description" class="step-description">
      {{ description }}
    </h3> -->
    <ng-container *ngFor="let _ of fakeArray; let index = index">
      <div class="item__container">
        <form [formGroup]="formArray.at(index)" class="item__form">
          <div class="row w-100">
            <div class="col-md-8 col-xs-12 mb-3">
              <div class="item__role__container">
                <wr-select-with-search
                  [control]="formArray.at(index).get(roleSelectControlKey)"
                  [options]="selectOptions"
                ></wr-select-with-search>
              </div>
            </div>
            <div class="col-md-4 col-xs-12 mb-3 text-center">
              <div class="row justify-content-center">
                <div class="col-6">
                  <mat-form-field
                    class="item__form-field__percentage"
                    [ngClass]="{
                      'one-digit': formArray.at(index)?.get(percentageControlKey)?.value <= 9,
                      'three-digits': formArray.at(index)?.get(percentageControlKey)?.value >= 100
                    }"
                    floatLabel="never"
                  >
                    <input type="number" [formControl]="formArray.at(index).get(percentageControlKey)" matInput />
                  </mat-form-field>
                </div>
                <div class="col-1 align-self-center text-left">
                  <button
                    class="button__remove-item no-background"
                    *ngIf="index > 0"
                    (click)="removeItem(index)"
                    mat-icon-button
                  >
                    <img src="assets/images/svg/cross.svg" alt="remove" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>

    <div class="add-action-container">
      <button
        mat-button
        class="add-action-container__btn"
        [disabled]="formArray.length === selectOptions.length"
        (click)="addNext()"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span class="add-action-container__label">{{ addNewItemLabel }}</span>
    </div>
  </div>
</form>
