import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { CaseTypes } from '@app/shared/cases.model';
import { DialogId } from '@app/shared/dialogs.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { Router } from '@angular/router';
import { RouteSegments } from '@app/modules/shared/models/routing.model';

@Component({
  selector: 'wr-sick-leave',
  templateUrl: './sick-leave.component.html',
})
export class SickLeaveComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  caseType = DocumentsIdentifiers.SICK_LEAVE;
  documentTypeLabel = 'COMMON.NEW';

  constructor(
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly casesService: CasesService,
    private readonly dialogHelperService: DialogHelperService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogId: DialogId;
      caseNumber: string;
      caseUser: string;
      userId: string;
      existingCaseId: string;
    },
  ) {}

  ngOnInit(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(DocumentsIdentifiers.SICK_LEAVE);
  }

  submit(fields: { [key: string]: string | string[] }): void {
    this.casesService
      .createCase({
        documents: [
          {
            docTemplateUniqueIdentifier: DocumentsIdentifiers.SICK_LEAVE,
            docTemplateVersionNumber: this.docVersion,
            ownerId: this.data.userId,
            fields,
          },
        ],
        type: CaseTypes.SICK_LEAVE,
        caseId: this.data.existingCaseId ? this.data.existingCaseId : null,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.dialogHelperService.closeDialog(DialogId.SICK_LEAVE, { dialogResult: true });
          this.redirectToCases(data._id);
        },
        (error) => {
          // TODO : add error handling
          console.error(error);
        },
      );
  }

  redirectToCases(id: string): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`${RouteSegments.CASES}/${id}`]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
