<wr-generic-dialog
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseType]="data.caseType"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  [class.form-disabled]="loadingService.isLoading"
>
  <ng-container title>
    {{ dialogTitle | translate }}
  </ng-container>

  <ng-container content class="scrollable">
    <wr-document-summary
      [documentSummaryData]="summary"
      [caseDocumentData]="caseDocumentData"
      [documentTypeLabel]="documentTypeLabel | translate"
      [caseNumber]="data.caseNumber"
      [employee]="data.caseUser"
      [userId]="data.userId"
      [reportType]="data.type"
      [caseId]="data.caseId"
      [formGroup]="formGroup"
      [steps]="steps"
      class="mx-n3"
    >
    </wr-document-summary>
    <mat-card class="mx-4" *ngIf="displayComment">
      <wr-textarea
        class="accept_comment"
        [textControl]="commentField"
        [placeholder]="'COMMON.WRITE_COMMENT' | translate"
        [label]="'COMMON.ADD_COMMENT' | translate"
        [rowsCount]="6"
        [wrAutoFocus]
      ></wr-textarea>
      <div class="w-100 d-flex gap-3 justify-content-end">
        <button wrButton variant="outline" (click)="cancelComment()">
          {{ 'BUTTON.CANCEL' | translate }}
        </button>
        <button
          wrButton
          [loading]="loadingService.isLoading"
          (click)="commentSubmit()"
        >
          {{
            (agreementAccepted ? 'BUTTON.ACCEPT' : 'BUTTON.POST_REPLY')
              | translate
          }}
        </button>
      </div>
    </mat-card>
  </ng-container>

  <ng-container footer>
    <div class="footer__actions__container">
      <div class="footer__action__container">
        <button wrButton variant="outline" (click)="onClose()">
          {{ 'BUTTON.CLOSE' | translate }}
        </button>
      </div>
      <div class="footer__action__container" *ngIf="!data.isOwner">
        <button
          *ngIf="!displayComment"
          wrButton
          variant="primary"
          (click)="preSubmit()"
          [disabled]="firstClick && !agreementAccepted"
        >
          {{ 'BUTTON.POST_REPLY' | translate }}
        </button>
      </div>
      <div class="footer__action__container" *ngIf="!data.isOwner">
        <button
          *ngIf="!displayComment"
          wrButton
          variant="primary"
          (click)="preSubmit(true)"
          [disabled]="firstClick && agreementAccepted"
        >
          {{ 'BUTTON.ACCEPT' | translate }}
        </button>
      </div>
      <div
        class="footer__action__container"
        *ngIf="data.isOwner && data.isDraft"
      >
        <button wrButton variant="primary" (click)="onEdit()">Edit</button>
      </div>
    </div>
  </ng-container>
</wr-generic-dialog>
