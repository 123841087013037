<header>
  <div
    class="heading"
    *ngIf="showBreadcrumb || showCloseIcon || showHeaderTitle"
  >
    <!-- <p class="dialog-short-description">{{ employee || '-' }} / {{ documentTypeLabel || '-' }} / <b>{{ caseNumber ? '#' + caseNumber : '-' }}</b></p> -->
    <div>
      <wr-breadcrumb
        [homeIcon]="false"
        [linkActivated]="false"
        [breadcrumbList]="getBreadcrumData()"
        *ngIf="showBreadcrumb"
      ></wr-breadcrumb>
    </div>
    <button
      mat-icon-button
      class="heading-close"
      color="dark"
      *ngIf="showCloseIcon"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h3 *ngIf="showHeaderTitle" class="heading__title" mat-dialog-title>
    <ng-content select="[title]"></ng-content>
  </h3>
  <p *ngIf="showHeaderTitle && showHeaderSubtitle" class="heading__subTitle">
    <ng-content select="[subTitle]"></ng-content>
  </p>
</header>
<mat-dialog-content>
  <ng-content select="[content]"></ng-content>
</mat-dialog-content>

<mat-dialog-actions class="footer" *ngIf="showFooter">
  <footer class="footer__container">
    <ng-content select="[footer]"></ng-content>
  </footer>
</mat-dialog-actions>
