import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ITemplatePageType} from "@app/modules/builder/builder.component";

@Component({
  selector: 'wr-question-builder',
  templateUrl: './question-builder.component.html',
  styleUrls: ['./question-builder.component.scss', '../../builder.component.scss']
})
export class QuestionBuilderComponent implements OnInit{
  listOfPages: {name: string, type: ITemplatePageType}[] =[
  ]
  @Output()
  showHideElementSelection = new EventEmitter<boolean>();
  selected = new FormControl(0);

  ngOnInit() {
    this.addNewPage()
  }
  stepNext() {
    this.selected.setValue(this.selected.value + 1)
    this.watchStepChange();
  }

  stepPrevious() {
    this.selected.setValue(this.selected.value - 1)
    this.watchStepChange();
  }

  watchStepChange(){
    if (this.listOfPages[this.selected.value] && this.listOfPages[this.selected.value].type && this.pageTypeExist(this.listOfPages[this.selected.value].type)) {
      this.showHideElementSelection.emit(true)
    } else {
      this.showHideElementSelection.emit(false)
    }
  }

  addNewPage() {
    this.listOfPages.push( {
      name: '',
      type: {
        pageType: null,
        questionType: null
      }
    })
    this.selected.setValue(this.listOfPages.length - 1)
    this.watchStepChange();
  }

  pageTypeExist(type: ITemplatePageType) {
    return type && type.pageType && type.questionType
  }

}
