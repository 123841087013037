<mat-tab-group
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event); watchStepChange()">
   <mat-tab label="" *ngFor="let page of listOfPages">
      <ng-container *ngIf="!pageTypeExist(page.type); else creationPage">
        <wr-page-selector (pageTypeSet)="page.type = $event; watchStepChange()" ></wr-page-selector>
      </ng-container>
     <ng-template #creationPage>
       <wr-main-builder [templatePageType]="page.type"/>
     </ng-template>
   </mat-tab>
</mat-tab-group>
<div
  class="tb-pages-container-minimize tb-pages-container position-absolute bottom-0 w-100 text-center"
>
        <span class="rounded-top tb-pages-pin d-inline-flex align-items-center" (click)="addNewPage()"
        >Pages test<mat-icon>push_pin</mat-icon></span
        >
  <div class="d-flex gap-3 overflow-auto p-3 rounded-top tb-pages">
    <div class="tb-pages-preview-container" *ngFor="let page of listOfPages; index as i" (click)="selected.setValue(i); watchStepChange()">
      <div class="tb-pages-preview rounded overflow-hidden">
        <img src="../../../assets/images/png/pages-preview.png" />
      </div>
      <div class="tb-pages-preview-title text-truncate w-100 mt-1">
        {{page.name}}
      </div>
    </div>
    <div
      class="tb-pages-add rounded d-flex align-items-center justify-content-center"
      (click)="addNewPage()"
    >
      <mat-icon>addinga</mat-icon>
    </div>
  </div>
</div>

