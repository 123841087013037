import {
  Component,
  HostBinding,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ContentCreatorService } from '@app/shared/content-creator/content-creator.service';
import {
  ITemplateContent,
  ITemplatePageSaveData,
  ITemplateSaveData,
  ITemplateVersionData,
  QuestionTypeEnum,
} from '@app/modules/content-creator/content-creatorer.model';
import { Subject, Subscription } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  DocumentsIdentifiers,
  DocumentStatus,
  DocumentSummaryData,
} from '@app/shared/documents.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { CaseDocument, CaseTypes } from '@app/shared/cases.model';
import { User } from '@app/shared/user.model';
import { UserService } from '@app/shared/user/user.service';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentBuilderService } from '@app/modules/shared/services/document-builder/document-builder.service';
import { DialogId } from '@app/shared/dialogs.model';
import {
  NotificationColor,
  NotificationPosition,
  NotificationShowTime,
} from '@app/core/services/notify/notify.model';
import { SnackbarComponent } from '@app/core/snackbar/snackbar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';

@Component({
  selector: 'wr-fill-mode',
  templateUrl: './fill-mode.component.html',
  styleUrls: ['./fill-mode.component.scss'],
})
export class FillModeComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class.wr-fill-mode')
  templateData: ITemplateVersionData;
  contentPrefix = 'fill-content-';
  private readonly onDestroy$ = new Subject<null>();
  DocumentTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;

  preFilledData: ITemplateSaveData;
  currentUser: User;
  docTypeLabel: string;
  stepperDataForTitle: DocumentFormConfigStep[] = [];
  contentFillIndex = 0;

  private summarySubscription: Subscription;
  private summarySubscription1: Subscription;

  caseDocumentData: CaseDocument;
  caseDocumentData1: CaseDocument;

  summary: { [key: string]: DocumentSummaryData };
  formGroup: UntypedFormGroup;
  steps: DocumentFormConfigStep[]; // NEED
  stepControls: AbstractControl[];

  summary1: { [key: string]: DocumentSummaryData };
  formGroup1: UntypedFormGroup;
  steps1: DocumentFormConfigStep[]; // NEED
  stepControls1: AbstractControl[];
  reportTypes = DialogId;
  noteText = '';
  selectedStepIndex = 0;
  isRequiredComplete = false;
  constructor(
    private contentCreatorService: ContentCreatorService,
    private userService: UserService,
    private readonly dialogRef: MatDialogRef<FillModeComponent>,
    private dialogHelperService: DialogHelperService,
    private casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      documentId: string;
      type: DocumentTemplateUniqueIdentifier;
      commentMode: boolean;
    },
  ) {}

  async ngOnInit(): Promise<void> {
    this.docTypeLabel = DOCUMENTS_TYPE_LABELS[this.data.type];
    this.getTemplate();
    await this.getCurrentUserInfo();
    this.getFacilitationDocumentFormData(DocumentsIdentifiers.FACILITATION);
    this.getFollowupDocumentFormData(DocumentsIdentifiers.FOLLOW_UP_PLAN);
  }
  async getCurrentUserInfo(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
  }

  private getFacilitationDocumentFormData(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(DocumentsIdentifiers[type])
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup = formGroup;
          this.steps = workability?.versions[0]?.template?.steps;
          this.stepControls = stepControls;
          this.summarySubscription = summarySubscription;
          return this.casesService.getCaseDocumentByType(
            this.data.caseId,
            type,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup,
          document.fields,
        );
        this.summary = (
          this.formGroup.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData = document;
        if (
          this.data.type === DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP &&
          this.formGroup.value.step0.control1
        ) {
          this.summary = this.formGroup.value.step0.control1;
        }
      });
  }
  private getFollowupDocumentFormData(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(DocumentsIdentifiers[type])
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup1 = formGroup;
          this.steps1 = workability?.versions[0]?.template?.steps;
          this.stepControls1 = stepControls;
          this.summarySubscription1 = summarySubscription;
          return this.casesService.getCaseDocumentByType(
            this.data.caseId,
            type,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup1,
          document.fields,
        );
        this.summary1 = (
          this.formGroup1.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData1 = document;
        if (
          this.data.type === DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP &&
          this.formGroup1.value.step0.control1
        ) {
          this.summary1 = this.formGroup1.value.step0.control1;
        }
      });
  }

  getTemplate(): void {
    this.contentCreatorService
      .getTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        this.templateData = templates;
        this.prepareTitles();
        this.setEmpltyValueForConetnt();
        if (this.data.commentMode) {
          this.changeFormForComments();
        }
      });
  }

  changeFormForComments(): void {
    this.casesService
      .getCaseDocument(this.data.caseId, this.data.documentId)
      .subscribe((data) => {
        this.preFilledData = data.fields;
        this.templateData.versions.pages.forEach((page, pageIndex) => {
          page.content.forEach((content, contentIndex) => {
            const singleValue =
              this.preFilledData.versions?.pages[pageIndex].content[
                contentIndex
              ].value;
            const multiValue =
              this.preFilledData.versions?.pages[pageIndex].content[
                contentIndex
              ].multipleValue;
            if (singleValue) {
              content.value = singleValue;
            } else if (multiValue) {
              content.multipleValue = multiValue;
            }
          });
        });
      }),
      takeUntil(this.onDestroy$);
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    if (this.summarySubscription1) {
      this.summarySubscription1.unsubscribe();
    }
    this.onDestroy$.next();
  }

  isRequiredCompleteCheck(): boolean {
    const allContent: ITemplateContent[] =
      this.templateData?.versions?.pages[this.selectedStepIndex].content;
    this.isRequiredComplete = allContent.every((content) => {
      const condition = Boolean(
        !content.required ||
          (content.required &&
            content.value &&
            content.value.toString().length > 0) ||
          (content.required &&
            content.multipleValue &&
            content.multipleValue.length > 0),
      );
      return condition;
    });
    return this.isRequiredComplete;
  }

  onSubmit(): void {
    const formattedData: ITemplateSaveData = this.formatDataForSaving();
    this.casesService
      .addDocumentToCase(this.data.caseId, {
        docTemplateUniqueIdentifier: this.data.type,
        docTemplateVersionNumber: 1,
        ownerId: this.data.userId,
        fields: {
          ...formattedData,
        },
        status: DocumentStatus.COMPLETED,
        // userId: this.currentUser._id,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.dialogHelperService.closeDialog(DialogId.FILL_TEMPLATE_MODAL, {
          dialogResult: true,
        });
      });
  }

  formatDataForSaving(): ITemplateSaveData {
    const pages: ITemplatePageSaveData[] = this.templateData.versions.pages.map(
      (page) => {
        const content = page.content.map((contentData) => {
          return {
            questionNumber: contentData.questionNumber,
            value: contentData.value ? contentData.value : null,
            multipleValue: contentData.multipleValue
              ? contentData.multipleValue
              : null,
            comment: contentData.comment ? contentData.comment : null,
          };
        });
        const returnData = { pageNumber: page.pageNumber, content };
        return returnData;
      },
    );

    const data: ITemplateSaveData = {
      name: this.templateData.name,
      uniqueIdentifier: this.templateData.uniqueIdentifier,
      versions: {
        versionNumber: this.templateData.versions.versionNumber,
        pages,
        note: this.noteText,
      },
    };
    return data;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  setEmpltyValueForConetnt(): void {
    this.templateData.versions.pages.forEach((page) => {
      page.content.forEach((content) => {
        if (content.type === QuestionTypeEnum.CHECKBOX) {
          content.multipleValue = [];
        } else {
          content.value = '';
        }
        content.comment = '';
      });
    });
  }

  scrollToTop(): void {
    if (document.getElementById('title')) {
      document.getElementById('title').scrollIntoView();
    }
  }

  prepareTitles(): void {
    this.templateData?.versions.pages.forEach((page, pageIndex) => {
      this.stepperDataForTitle.push({
        step: pageIndex,
        title: { en: page.title },
        description: { en: page.subtitle },
        controls: [],
      });
    });
  }

  answerFilled(index: number): void {
    this.contentFillIndex = index + 1;
    const allContent: ITemplateContent[] =
      this.templateData?.versions?.pages[this.selectedStepIndex].content;
    if (this.contentFillIndex + 1 > allContent.length) {
      let message = 'INFO.MESSAGE.PRESS_NEXT';
      if (!this.isRequiredCompleteCheck()) {
        message = 'INFO.MESSAGE.FILL_ALL_REQUIRED';
      } else if (
        this.templateData.versions.pages.length ===
        this.selectedStepIndex + 1
      ) {
        message = 'INFO.MESSAGE.PRESS_SUBMIT';
      }
      this.showSnackBarMessage(message, NotificationColor.WARNING);
    }

    setTimeout(() => {
      document
        .getElementById(this.contentPrefix + (index + 1).toString())
        .scrollIntoView();
    }, 500);
  }
  showSnackBarMessage(
    message: string,
    color: NotificationColor,
    showTime: NotificationShowTime = NotificationShowTime.MEDIUM,
    position: NotificationPosition = NotificationPosition.BOTTOM,
  ): void {
    position = NotificationPosition.TOP;
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: showTime,
      panelClass: color,
      data: { message: this.translateService.instant(message) },
      horizontalPosition: 'center',
      verticalPosition: position,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
    }
  }
}
