import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { isEqual } from 'lodash-es';

import { SIMPLE_DATE_FORMAT } from '@app/shared/constants/datetime.constants';
import { DatetimeService } from '@app/shared/datetime/datetime.service';
import { FormValidatorsService } from '@app/shared/form-validators/form-validators.service';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { CalendarCustomHeaderComponent } from '@app/shared/components/documents/form-controls/calendar-custom-header/calendar-custom-header.component';

@Component({
  selector: 'wr-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input() group: UntypedFormGroup;
  @Input() description: string;
  @Input() max: {
    value: number;
    errorMessage: string;
  };
  @Input() uniqueIdentifier: string;

  selectedDateRange: DateRange<Date>;

  private readonly onDestroy$ = new Subject<null>();
  private _disabled: boolean;

  groupCopy: UntypedFormGroup;
  daysSelected$ = new BehaviorSubject<number>(-1);
  fromControlName: string;
  toControlName: string;

  calendarCustomHeader = CalendarCustomHeaderComponent;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    if (this.groupCopy) {
      value ? this.groupCopy.disable() : this.groupCopy.enable();
    }
    this._disabled = value;
  }

  constructor(
    private readonly datetimeService: DatetimeService,
    private readonly fb: UntypedFormBuilder,
    private readonly formValidatorsService: FormValidatorsService,
    private readonly iconsHelperService: IconsHelperService,
  ) {
    this.iconsHelperService.registerSvgIcons('calendar');
  }

  ngOnInit(): void {
    this.setControlsNames();
    this.createGroupCopy();
    this.setDisabledState();
    this.setDaysSelected();
    this.watchValuesChangesAndFormatDates();
    this.setRangeValidator();
  }

  onSelectedChange(date: Date): void {
    this.dateRangeSelection(date);

    switch (this.uniqueIdentifier) {
      case 'SELF_CERTIFICATION':
        this.selfCertification();
        break;
      default:
        this.sickLeave();
        break;
    }
  }

  selfCertification = () => {
    if (this.isValidDateRangeForCertification()) {
      const simpleDates = this.formatISOStringsToSimpleDates({
        [this.fromControlName]: this.selectedDateRange.start as any,
        [this.toControlName]: this.selectedDateRange.end,
      });

      this.groupCopy.patchValue({ ...simpleDates });
      this.group.patchValue(simpleDates);
    } else {
      this.group.setErrors({
        startDateError: true,
        msg: "Starting date cannot be selected beyond tomorrow's date.",
      });
      this.group.markAsDirty();
      this.group.markAsTouched();
      this.group.markAsPristine();
    }
  };

  sickLeave = () => {
    const simpleDates = this.formatISOStringsToSimpleDates({
      [this.fromControlName]: this.selectedDateRange.start as any,
      [this.toControlName]: this.selectedDateRange.end,
    });

    this.groupCopy.patchValue({ ...simpleDates });
    this.group.patchValue(simpleDates);
  };

  dateRangeSelection = (date: Date) => {
    const selectedDate = new Date(date);

    if (!this.selectedDateRange) {
      this.selectedDateRange = new DateRange(date, date);
    }

    if (selectedDate.getTime() < this.selectedDateRange.start.getTime()) {
      this.selectedDateRange = new DateRange(
        date,
        this.selectedDateRange.start,
      );
    }

    if (selectedDate.getTime() > this.selectedDateRange.end.getTime()) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date,
      );
    }

    if (selectedDate.getTime() < this.selectedDateRange.end.getTime()) {
      this.selectedDateRange = new DateRange(date, this.selectedDateRange.end);
    }
  };

  isValidDateRangeForCertification = (): boolean => {
    const currentDate: Date = new Date();
    const startDate: Date = new Date(this.selectedDateRange.start);
    const currentDay = currentDate.getDate();
    const startDay = startDate.getDate();
    const dayDifference = startDay - currentDay;

    if (dayDifference < 2) {
      return true;
    }
    return false;
  };

  addValidations = () => {
    this.groupCopy.markAsDirty();
    this.groupCopy.setErrors({});
  };

  getSelectedDaysCount(startDate: Date, endDate: Date): number {
    if (!startDate || !endDate) return 0;
    if (startDate === endDate) {
      return 1;
    }
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate(),
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
  }

  isValidRange(): boolean {
    if (!this.max || !this.selectedDateRange) return true;

    return (
      this.getSelectedDaysCount(
        this.selectedDateRange.start,
        this.selectedDateRange.end,
      ) <= this.max.value
    );
  }

  checkIsValid = () => {
    return this.group.invalid;
  };

  private setRangeValidator(): void {
    if (this.max) {
      this.groupCopy.setValidators(
        this.formValidatorsService.datesRangeValidator(
          this.fromControlName,
          this.toControlName,
          this.max.value,
          'day',
        ),
      );
    }
  }

  private setControlsNames(): void {
    Object.keys(this.group.getRawValue()).forEach((key, index) => {
      if (index === 0) {
        this.fromControlName = key;
      } else if (index === 1) {
        this.toControlName = key;
      }
    });
  }

  private createGroupCopy(): void {
    const groupCopyValue = this.formatSimpleDatesToISOStrings(
      this.group.getRawValue(),
    );
    this.groupCopy = this.fb.group(groupCopyValue);
  }

  private setDisabledState(): void {
    this.disabled ? this.groupCopy.disable() : this.groupCopy.enable();
  }

  private watchValuesChangesAndFormatDates(): void {
    this.groupCopy.valueChanges
      .pipe(
        filter(
          () =>
            this.groupCopy.valid ||
            isEqual(this.groupCopy.errors, { invalidRange: true }),
        ),
        distinctUntilChanged((previous, current) =>
          isEqual(
            this.formatSimpleDatesToISOStrings(previous),
            this.formatSimpleDatesToISOStrings(current),
          ),
        ),
        takeUntil(this.onDestroy$),
      )
      .subscribe((value) => {
        const valueToPatchWith = this.groupCopy.getRawValue();
        const newGroupValue =
          this.formatISOStringsToSimpleDates(valueToPatchWith);
        if (Object.values(value).every((v) => v)) {
          if (!isEqual(newGroupValue, this.group.getRawValue())) {
            this.group.patchValue(newGroupValue);
          }
        } else {
          const newGroupCopyValue =
            this.formatSimpleDatesToISOStrings(valueToPatchWith);
          this.groupCopy.patchValue(newGroupCopyValue);
          this.group.patchValue(newGroupValue);
        }
        this.setDaysSelected();
      });
  }

  private setDaysSelected(): void {
    if (Object.values(this.group.getRawValue()).every((value) => value)) {
      const [firstDate, secondDate] = Object.values(
        this.group.getRawValue() as { [key: string]: string },
      ).map((date) => {
        this.onSelectedChange(
          this.datetimeService.formatSimpleDateToDayjs(date).toDate(),
        );
        return this.datetimeService.formatSimpleDateToDayjs(date);
      });

      this.daysSelected$.next(
        Math.abs(
          this.datetimeService
            .date(firstDate)
            .diff(this.datetimeService.date(secondDate), 'day'),
        ) + 1,
      );
    } else {
      this.daysSelected$.next(0);
    }
  }

  private formatSimpleDatesToISOStrings(simpleDates: {
    [key: string]: string;
  }): { [key: string]: string } {
    const valueToReturn = Object.assign({}, simpleDates);
    Object.entries(valueToReturn).forEach(([key, value]: [string, string]) => {
      if (this.datetimeService.checkIsDateFormatSimple(value)) {
        valueToReturn[key] = value
          ? this.datetimeService.formatSimpleDateToDayjs(value).toISOString()
          : null;
      }
    });
    return valueToReturn;
  }

  private formatISOStringsToSimpleDates(ISOString: { [key: string]: string }): {
    [key: string]: string;
  } {
    const valueToReturn = Object.assign({}, ISOString);
    Object.entries(valueToReturn).forEach(([key, value]: [string, string]) => {
      if (!this.datetimeService.checkIsDateFormatSimple(value)) {
        valueToReturn[key] = value
          ? this.datetimeService.date(value).format(SIMPLE_DATE_FORMAT)
          : null;
      }
    });
    return valueToReturn;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
