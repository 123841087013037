import { Component, Input, OnInit } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-document-summary-card-details-dropdown-with-percentage',
  templateUrl: './document-summary-card-details-dropdown-with-percentage.component.html',
  styleUrls: ['./document-summary-card-details-dropdown-with-percentage.component.scss'],
})
export class DocumentSummaryCardDetailsDropdownWithPercentageComponent implements OnInit {
  @Input() summaryControl: DocumentSummaryData;

  summaryValueKey: string;
  summaryPercentageKey: string;

  constructor(private readonly iconsHelperService: IconsHelperService) {
    this.iconsHelperService.registerSvgIcons(['summary-current', 'summary-percentage']);
  }

  ngOnInit(): void {
    this.findValueAndPercentageKeys();
  }

  private findValueAndPercentageKeys(): void {
    if (
      this.summaryControl?.value &&
      Array.isArray(this.summaryControl.value) &&
      typeof this.summaryControl.value[0] === 'object'
    ) {
      Object.entries(this.summaryControl.value[0]).forEach(([key, value]) => {
        if (typeof value === 'string') {
          this.summaryValueKey = key;
        } else {
          this.summaryPercentageKey = key;
        }
      });
    }
  }
}
