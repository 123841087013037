import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-single-answer',
  templateUrl: './single-answer.component.html',
  styleUrls: ['./single-answer.component.scss'],
})
export class SingleAnswerComponent {
  @Input() type: 'radio' | 'select' | 'button' = 'radio';
  @Input() formArray: UntypedFormArray;
  @Input() inputLabel: string;
  @Input() description: string;
  @Input() selectOptions: SelectOption<string>[];
  @Input() value: string;
  @Output() valueChange = new EventEmitter<any>();

  handleValueChange(value: string, checked: boolean) {
    if (checked) {
      this.value = value;
    } else {
      this.value = '';
    }

    this.valueChange.emit(this.value);
  }

  handleSelectValueChange() {
    this.valueChange.emit(this.value);
  }
}
