import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { FormValidatorsService } from '@app/shared/form-validators/form-validators.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { GenericDialogComponent } from '@app/shared/components/generic-dialog/generic-dialog.component';
import { GenericStepperDialogComponent } from '@app/shared/components/generic-stepper-dialog/generic-stepper-dialog.component';
import { TextareaComponent } from '@app/shared/components/documents/form-controls/textarea/textarea.component';
import { TextboxesListComponent } from '@app/shared/components/documents/form-controls/textboxes-list/textboxes-list.component';
import { ControlSelectorComponent } from '@app/shared/components/documents/form-controls/control-selector/control-selector.component';
import { DocumentSummaryComponent } from '@app/shared/components/documents/document-summary/document-summary.component';
import { DropdownWithPercentageComponent } from '@app/shared/components/documents/form-controls/dropdown-with-percentage/dropdown-with-percentage.component';
import { FacilitationComponent } from '@app/shared/components/documents/reports/facilitation/facilitation.component';
import { WorkplaceAccommodationComponent } from '@app/shared/components/documents/reports/workplace-accommodations/workplace-accommodations.component';
import { SelectWithSearchModule } from '@app/shared/custom-components/select-with-search/select-with-search.module';
import { TextboxComponent } from '@app/shared/components/documents/form-controls/textbox/textbox.component';
import { TextboxWithChipsComponent } from '@app/shared/components/documents/form-controls/textbox-with-chips/textbox-with-chips.component';
import { WorkingHoursComponent } from '@app/shared/components/documents/form-controls/working-hours/working-hours.component';
import { SimpleTimepickerModule } from '@app/shared/custom-components/simple-timepicker/simple-timepicker.module';
import { HoursTimeRangeModule } from '@app/shared/directives/hours-time-range/hours-time-range.module';
import { DatepickerComponent } from '@app/shared/components/documents/form-controls/datepicker/datepicker.component';
import { QuestionWithYesNoAnswerComponent } from '@app/shared/components/documents/form-controls/question-with-yes-no-answer/question-with-yes-no-answer.component';
import { FollowUpDocumentComponent } from '@app/shared/components/documents/reports/follow-up-document/follow-up-document.component';
import { SmallTextareaListComponent } from '@app/shared/components/documents/form-controls/small-textarea-list/small-textarea-list.component';
import { FollowUpPlanComponent } from '@app/shared/components/documents/reports/follow-up-plan/follow-up-plan.component';
import { FollowUpPlanDatesControlComponent } from '@app/shared/components/documents/reports/follow-up-plan/follow-up-plan-dates-control/follow-up-plan-dates-control.component';
import { SingleButtonSelectComponent } from '@app/shared/components/documents/form-controls/single-button-select/single-button-select.component';
import {
  CUSTOM_DATE_FORMATS,
  CustomDateAdapter,
} from '@app/shared/constants/datetime.constants';
import { SelectComponent } from '@app/shared/components/documents/form-controls/select/select.component';
import { DateRangeComponent } from '@app/shared/components/documents/form-controls/date-range/date-range.component';
import { SlideToggleComponent } from '@app/shared/components/documents/form-controls/slide-toggle/slide-toggle.component';
import { DocumentSummaryCardComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card.component';
import { DocumentSummaryCardDetailsDefaultComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-default/document-summary-card-details-default.component';
import { DocumentSummaryCardDetailsWorkingHoursComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-working-hours/document-summary-card-details-working-hours.component';
import { DocumentSummaryCardDetailsSelectorComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-selector/document-summary-card-details-selector.component';
import { DocumentSummaryHeaderComponent } from '@app/shared/components/documents/document-summary/document-summary-header/document-summary-header.component';
import { DocumentSummaryCardDetailsListComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-list/document-summary-card-details-list.component';
import { DocumentSummaryCardDetailsDropdownWithPercentageComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-dropdown-with-percentage/document-summary-card-details-dropdown-with-percentage.component';
import { DocumentSummaryCardDetailsFollowUpPlanDatesComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-follow-up-plan-dates/document-summary-card-details-follow-up-plan-dates.component';
import { DocumentSummaryCardDetailsPopupComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-popup/document-summary-card-details-popup.component';
import { HumanizeBooleanModule } from '@app/shared/pipes/humanize-boolean.module';
import { DocumentSummaryCardDetailsDateRangeComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-date-range/document-summary-card-details-date-range.component';
import { MultiButtonSelectComponent } from '@app/shared/components/documents/form-controls/multi-button-select/multi-button-select.component';
import { TemporaryChangeRequestComponent } from '@app/shared/components/documents/reports/temporary-change-request/temporary-change-request.component';
import { GenericReportComponent } from '@app/shared/components/documents/reports/generic-report/generic-report.component';
import { SickLeaveComponent } from '@app/shared/components/documents/reports/sick-leave/sick-leave.component';
import { SelfCertificationComponent } from '@app/shared/components/documents/reports/self-certification/self-certification.component';
import { ReportIssueComponent } from '@app/shared/components/documents/reports/report-issue/report-issue.component';
import { TextboxesWithSelectListComponent } from '@app/shared/components/documents/form-controls/textboxes-with-select-list/textboxes-with-select-list.component';
import { HorizontalButtonRadioComponent } from '@app/shared/components/documents/form-controls/horizontal-button-radio/horizontal-button-radio.component';
import { DocumentSummaryCardDetailsTextboxesWithSelectListComponent } from '@app/shared/components/documents/document-summary/document-summary-card/textboxes-with-select-list/textboxes-with-select-list.component';
import { StandardFacilitationComponent } from '@app/shared/components/documents/reports/standard-facilitation/standard-facilitation.component';
import { PercentageComponent } from '@app/shared/components/documents/form-controls/percentage/percentage.component';
import { CustomDaysAndHoursComponent } from '@app/shared/components/documents/form-controls/custom-days-and-hours/custom-days-and-hours.component';
import { DocumentSummaryCardDetailsPercentageComponent } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-percentage/document-summary-card-details-percentage.component';
import { DocumentAgreementComponent } from '@app/shared/components/documents/reports/document-agreement/document-agreement.component';
import { FormControlPasswordComponent } from './components/form-controls/password/form-control-password/form-control-password.component';
import { DocumentOverviewComponent } from './components/documents/document-overview/document-overview.component';
import { DocumentEditComponent } from './components/documents/document-edit/document-edit.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CalendarCustomHeaderComponent } from './components/documents/form-controls/calendar-custom-header/calendar-custom-header.component';
import { AppHeaderComponent } from '@app/core/app-header/app-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SickLeaveSummaryComponent } from './components/documents/reports/sick-leave/sick-leave-summary/sick-leave-summary.component';
import { DateDiffDaysPipe } from '@app/shared/pipes/date-diff.pipe';
import { CustomDatePipe } from '@app/shared/pipes/custom-data.pipe';
import { LangSelectorComponent } from './components/lang-selector/lang-selector.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { OnboardingStepperComponent } from './components/generic-stepper-dialog/onboarding-stepper/onboarding-stepper.component';
import { CongratsWelcomeComponent } from './components/congrats-welcome/congrats-welcome.component';
import { WorkspaceProfileComponent } from './components/workspace-profile/workspace-profile.component';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { AddressFormatPipe } from './pipes/address-format.pipe';
import { ExtractColKeyPipe } from '@app/shared/pipes/extract-col-key.pipe';
import { FocusDirective } from './directives/focus.directive';
import { BreadcrumbComponent } from './custom-components/breadcrumb/breadcrumb.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@environment/environment';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ScrollEndDirective } from './directives/scroll-end/scroll-end.directive';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { FollowUpSchedulerComponent } from './components/documents/reports/follow-up-scheduler/follow-up-scheduler.component';
import { SchedulerComponent } from '@app/shared/components/scheduler/scheduler.component';
import { SchedulerMainComponent } from '@app/shared/components/scheduler-main/scheduler.component';
import { SchedulerSummaryComponent } from './components/scheduler/scheduler-summary/scheduler-summary.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { FollowUpScheduledEventComponent } from './components/documents/reports/follow-up-scheduled-event/follow-up-scheduled-event.component';
import { AutofocusDirective } from './directives/auto-focus/autoFocus.directive';
import { CloseTaskComponent } from './components/documents/reports/close-task/close-task.component';
import { FollowUpScheduledEventSummaryComponent } from './components/documents/reports/follow-up-scheduled-event/follow-up-scheduled-event-summary/follow-up-scheduled-event-summary.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DialogueMeetingSummaryComponent } from './components/documents/reports/dialogue-meeting-summary/dialogue-meeting-summary.component';
import { ChangesToTaskConfirmationComponent } from './components/documents/reports/changes-to-task-confirmation/changes-to-task-confirmation.component';
import { PlatformModule } from '@angular/cdk/platform';
import { NotificationComponent } from '@app/core/notification/notification.component';
import { SchedulerMainSummaryComponent } from './components/scheduler-main/scheduler-main-summary/scheduler-main-summary.component';
import { QuestionaireSummaryModeComponent } from '@app/shared/components/questionaire-summary-mode/questionaire-summary-mode.component';
import { PreviewContentComponent } from '@app/shared/components/preview-content/preview-content.component';
import { FacilitationSummaryComponent } from './components/documents/reports/facilitation/facilitation-summary/facilitation-summary.component';
import { OnlyNumberDirective } from './directives/only-numbers/only-number.directive';
import { WrButtonsModule } from './components/buttons/button.module';
import { MobileMenuComponent } from '../core/components/app-header/mobile-menu/mobile-menu.component';
import { PercentageSliderComponent } from './components/documents/form-controls/percentage-slider/percentage-slider.component';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { PrioritySelectorComponent } from './components/documents/form-controls/priority-selector/priority-selector.component';
import { ResidualWorkCapacityComponent } from './components/documents/reports/residual-work-capacity/residual-work-capacity.component';
import { SlideToogleListComponent } from './components/documents/form-controls/slide-toogle-list/slide-toogle-list.component';
import { ResidualWorkCapacitySummaryComponent } from './components/documents/reports/residual-work-capacity/residual-work-capacity-summary/residual-work-capacity-summary.component';
import { DocumentSummaryCardDetailsPrioritySelectorComponent } from './components/documents/document-summary/document-summary-card/document-summary-card-details-priority-selector/document-summary-card-details-priority-selector.component';
import { LocationTextboxComponent } from './components/documents/form-controls/location-textbox/location-textbox.component';
import { SelfAssessmentComponent } from './components/documents/reports/self-assessment/self-assessment.component';
import { PercentageSliderListComponent } from './components/documents/form-controls/percentage-slider-list/percentage-slider-list.component';
import { SnackbarComponent } from '../core/components/snackbar/snackbar.component';
import { PanelComponent } from './components/documents/form-controls/panel/panel.component';
import { A11yModule } from '@angular/cdk/a11y';
import { TimePickerComponent } from './components/form-controls/time-picker/time-picker.component';
import { CommentContentComponent } from './components/comment-content/comment-content.component';
import { FmlComponent } from './components/documents/reports/fml/fml.component';
import { PermissionDirective } from './directives/permissions/permissions.directive';
import { PermissionReadOnlyDirective } from './directives/permissions/permissionsReadonly.directive';
import { PermissionService } from './services/common/permissions.service';
import { SelectTilesWithSearchComponent } from './custom-components/select-tiles-with-search/select-tiles-with-search.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatStepperModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatRadioModule,
    MatCardModule,
    MatListModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    SelectWithSearchModule,
    SimpleTimepickerModule,
    HoursTimeRangeModule,
    HumanizeBooleanModule,
    TranslateModule,
    MatToolbarModule,
    MatMenuModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    MatExpansionModule,
    PlatformModule,
    WrButtonsModule,
    MatSliderModule,
    A11yModule,
  ],
  providers: [
    FormValidatorsService,
    DialogHelperService,
    BreadcrumbService,
    PermissionService,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    },
  ],
  declarations: [
    GenericDialogComponent,
    ReportIssueComponent,
    GenericStepperDialogComponent,
    TextareaComponent,
    TextboxesListComponent,
    ControlSelectorComponent,
    DocumentSummaryComponent,
    DropdownWithPercentageComponent,
    FacilitationComponent,
    WorkplaceAccommodationComponent,
    TextboxComponent,
    TextboxWithChipsComponent,
    WorkingHoursComponent,
    DatepickerComponent,
    QuestionWithYesNoAnswerComponent,
    FollowUpDocumentComponent,
    SmallTextareaListComponent,
    FollowUpPlanComponent,
    FollowUpPlanDatesControlComponent,
    SingleButtonSelectComponent,
    DocumentSummaryCardComponent,
    DocumentSummaryCardDetailsDefaultComponent,
    DocumentSummaryCardDetailsWorkingHoursComponent,
    DocumentSummaryCardDetailsSelectorComponent,
    DocumentSummaryHeaderComponent,
    DocumentSummaryCardDetailsListComponent,
    DocumentSummaryCardDetailsDropdownWithPercentageComponent,
    DocumentSummaryCardDetailsFollowUpPlanDatesComponent,
    SingleButtonSelectComponent,
    SickLeaveComponent,
    SelfCertificationComponent,
    SelectComponent,
    DateRangeComponent,
    SlideToggleComponent,
    DocumentSummaryCardDetailsDateRangeComponent,
    DocumentSummaryCardDetailsFollowUpPlanDatesComponent,
    DocumentSummaryCardDetailsPopupComponent,
    MultiButtonSelectComponent,
    TemporaryChangeRequestComponent,
    GenericReportComponent,
    TextboxesWithSelectListComponent,
    HorizontalButtonRadioComponent,
    DocumentSummaryCardDetailsTextboxesWithSelectListComponent,
    StandardFacilitationComponent,
    PercentageComponent,
    PercentageSliderComponent,
    PanelComponent,
    PrioritySelectorComponent,
    CustomDaysAndHoursComponent,
    DocumentSummaryCardDetailsPercentageComponent,
    DocumentAgreementComponent,
    FormControlPasswordComponent,
    DocumentOverviewComponent,
    DocumentEditComponent,
    CalendarCustomHeaderComponent,
    AppHeaderComponent,
    MobileMenuComponent,
    NotificationComponent,
    SnackbarComponent,
    SickLeaveSummaryComponent,
    CongratsWelcomeComponent,
    QuestionaireSummaryModeComponent,

    // pipes
    DateDiffDaysPipe,
    CustomDatePipe,
    NameInitialsPipe,
    AddressFormatPipe,
    ExtractColKeyPipe,
    LangSelectorComponent,
    OnboardingStepperComponent,
    WorkspaceProfileComponent,
    AutofocusDirective,
    OnlyNumberDirective,
    FocusDirective,
    PermissionDirective,
    PermissionReadOnlyDirective,
    BreadcrumbComponent,
    ScrollEndDirective,
    EmptyListComponent,
    SchedulerComponent,
    SchedulerMainComponent,
    FollowUpSchedulerComponent,
    SchedulerSummaryComponent,
    FollowUpScheduledEventComponent,
    CloseTaskComponent,
    FollowUpScheduledEventSummaryComponent,
    DialogueMeetingSummaryComponent,
    ChangesToTaskConfirmationComponent,
    SchedulerMainSummaryComponent,
    PreviewContentComponent,
    FacilitationSummaryComponent,
    PercentageSliderComponent,
    PrioritySelectorComponent,
    ResidualWorkCapacityComponent,
    SlideToogleListComponent,
    ResidualWorkCapacitySummaryComponent,
    DocumentSummaryCardDetailsPrioritySelectorComponent,
    LocationTextboxComponent,
    SelfAssessmentComponent,
    PercentageSliderListComponent,
    PanelComponent,
    TimePickerComponent,
    CommentContentComponent,
    FmlComponent,
    SelectTilesWithSearchComponent,
  ],
  exports: [
    AppHeaderComponent,
    MobileMenuComponent,
    NotificationComponent,
    SnackbarComponent,
    GenericDialogComponent,
    FormControlPasswordComponent,
    LangSelectorComponent,
    OnboardingStepperComponent,
    CongratsWelcomeComponent,
    WorkspaceProfileComponent,
    BreadcrumbComponent,
    EmptyListComponent,
    SchedulerComponent,
    SchedulerMainComponent,
    SchedulerMainSummaryComponent,
    SchedulerSummaryComponent,
    FollowUpScheduledEventSummaryComponent,
    GenericStepperDialogComponent,
    QuestionaireSummaryModeComponent,
    PreviewContentComponent,
    SelectTilesWithSearchComponent,

    // pipes
    CustomDatePipe,
    DateDiffDaysPipe,
    NameInitialsPipe,
    ExtractColKeyPipe,
    AddressFormatPipe,

    // directives
    AutofocusDirective,
    OnlyNumberDirective,
    FocusDirective,
    ScrollEndDirective,
    PermissionDirective,
    PermissionReadOnlyDirective,

    WrButtonsModule,
    ResidualWorkCapacitySummaryComponent,
    TimePickerComponent,
    DocumentSummaryComponent,
    TextareaComponent,
    CommentContentComponent,
  ],
})
export class SharedModule {}
