import { Component, Input } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import {
  DocumentsControls,
  DocumentSummaryData,
} from '@app/shared/documents.model';
import { Languages } from '@app/shared/common.model';

@Component({
  selector: 'wr-document-summary-card-details-selector',
  templateUrl: './document-summary-card-details-selector.component.html',
  styleUrls: ['./document-summary-card-details-selector.component.scss'],
})
export class DocumentSummaryCardDetailsSelectorComponent {
  @Input()
  set summaryControls(value: DocumentSummaryData[]) {
    if (value && Array.isArray(value)) {
      this.translatedSummaryControls = value.map((summary) => ({
        ...this.translateSummaryControlsTexts(summary),
      }));
    }
  }

  private readonly selectedLanguage: Languages;

  documentControls: typeof DocumentsControls = DocumentsControls;
  translatedSummaryControls: DocumentSummaryData[];

  constructor(private readonly translateService: TranslateService) {
    this.selectedLanguage = this.translateService.currentLang as Languages;
  }

  private translateSummaryControlsTexts(
    summary: DocumentSummaryData,
  ): DocumentSummaryData {
    const newSummary: DocumentSummaryData = cloneDeep(summary);
    if (newSummary.heading && typeof newSummary.heading === 'object') {
      newSummary.heading = newSummary.heading[this.selectedLanguage];
    }
    if (newSummary.noValueInfo && typeof newSummary.noValueInfo === 'object') {
      newSummary.noValueInfo = newSummary.noValueInfo
        ? newSummary.noValueInfo[this.selectedLanguage]
        : null;
    }
    return newSummary;
  }

  trackBy(index: number): number {
    return index;
  }
}
