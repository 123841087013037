import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Router } from '@angular/router';

import {
  IAssignLanguageReq,
  IAssignUserRoleReq,
  IRemoveUserRoleRes,
  ITermsAndConditionReq,
  ITermsAndConditionRes,
  ITwoFactorInfo,
  User,
} from '@app/shared/user.model';
import { RouteSegments } from '@app/shared/routing.model';
import { TokenKey } from '../../models/common.model';
import jwt_decode from 'jwt-decode';
import { JwtResponse, JwtResponseEnc } from '@app/shared/jwt.model';
import {
  ETncType,
  ETwoFactorTypes,
  IEmailJWTDecoded,
  LoginResponse,
} from '@app/entry/entry.model';
import { ApiList } from '@app/app.constants';
import { SecurityService } from '@app/modules/core/services/security/security.service';
import { localStorageKeys } from '@app/core/services/security/security.service.constants';
import { RoleName, ADMIN_ROLES } from '@app/constants/role.constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  isLeader$ = new BehaviorSubject<boolean>(null);
  private currentUser: User;

  constructor(
    private readonly http: HttpClient,
    private readonly securityService: SecurityService,
    private readonly router: Router,
  ) {}

  getCurrentUser(forceRequest = false): Observable<User> {
    const userData = this.securityService.getSessionItem('cu');
    if (!forceRequest && userData) {
      return of(JSON.parse(this.securityService.decrypt(userData)));
    }
    return this.currentUser && !forceRequest
      ? of(this.currentUser)
      : this.http.get<User>(`${environment.baseUrl}${ApiList.users.me}`).pipe(
          tap((user) => {
            const data = this.securityService.encrypt(JSON.stringify(user));
            this.securityService.setSessionItem('cu', data);
            this.currentUser = user;
          }),
        );
  }

  getAuthTokenData(forceRequest = false): JwtResponse {
    const token = this.securityService.getToken(TokenKey.ACCESS) || null;
    return this.getDecryptedData(token);
  }

  getDecryptedData(token: string): JwtResponse {
    if (token) {
      // return jwt_decode(token) as JwtResponse;
      const tokenData = jwt_decode(token) as JwtResponseEnc;
      const data = this.securityService.decrypt(tokenData.data);
      const userInfo = JSON.parse(data) as JwtResponse;
      userInfo.isSuperAdmin = userInfo.roles.includes(RoleName.SUPER_ADMIN);
      userInfo.isCompanyAdmin = userInfo.roles.includes(RoleName.COMPANY_ADMIN);

      userInfo.isAdmin = userInfo.roles.some((role) =>
        ADMIN_ROLES.includes(role),
      );
      userInfo.isExternal = userInfo.roles.includes(RoleName.EXTERNAL);
      userInfo.isHR = userInfo.roles.includes(RoleName.HR);
      userInfo.isLeader = userInfo.roles.includes(RoleName.LEADER);
      //
      return userInfo;
    }
  }

  isUserLoggedIn(): boolean {
    const token = this.securityService.getToken(TokenKey.ACCESS) || null;
    const rememberedToken =
      this.securityService.getLocalStorageItem(TokenKey.ACCESS) || null;
    const chatToken =
      this.securityService.getLocalStorageItem(TokenKey.ACCESS) || null;
    if (token) {
      const accessData = jwt_decode(token) as JwtResponse;
      if (accessData && Date.now() >= accessData.exp * 1000) {
        return false;
      }
      return true;
    } else if (!token && rememberedToken) {
      this.securityService.setToken(TokenKey.ACCESS, rememberedToken);
      this.securityService.setToken(TokenKey.CONVERSATION, chatToken);
      return this.isUserLoggedIn();
    }
    return false;
  }

  uploadProfilePicture(image: File): Promise<{ url: string }> {
    const formDataFinal = new FormData();
    formDataFinal.append('file', image);
    return this.http
      .post<{ url: string }>(
        `${environment.baseUrl}${ApiList.users.uploadProfilePicture}`,
        formDataFinal,
      )
      .toPromise();
  }

  async switchWorkspace(
    workspaceId: string,
    redirect = true,
    reload = true,
  ): Promise<void> {
    const res = await this.http
      .get<LoginResponse>(
        `${environment.baseUrl}${ApiList.userWorkspace.switch}/${workspaceId}`,
      )
      .toPromise();
    this.securityService.setToken(TokenKey.ACCESS, res.accessToken);
    this.getCurrentUser(true).toPromise();
    if (redirect) {
      this.router.navigate([`${RouteSegments.LANDING_PAGE}`], {
        relativeTo: null,
        fragment: this.router.url,
      });
    } else if (reload) {
      window.location.reload();
    }
  }

  requestQr(): Promise<any> {
    return this.http
      .get(`${environment.baseUrl}${ApiList.auth.requestQr}`)
      .toPromise();
  }

  authenticateCode(
    code: string,
    type: ETwoFactorTypes,
  ): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(
        `${environment.baseUrl}${ApiList.auth.authenticate}`,
        {
          code,
          type,
        },
      )
      .pipe(
        tap((res) => {
          this.securityService.setToken(TokenKey.ACCESS, res.accessToken);
          this.router.navigate([`${RouteSegments.LANDING_PAGE}`], {
            relativeTo: null,
          });
        }),
      );
  }

  requestCode(
    type: ETwoFactorTypes,
    force: boolean = false,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${environment.baseUrl}${ApiList.auth.request}`,
      { type, force },
    );
  }

  activate(code: string, type: ETwoFactorTypes): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>(`${environment.baseUrl}${ApiList.auth.activate}`, {
        code,
        type,
      })
      .pipe(
        tap((res) => {
          this.securityService.setToken(TokenKey.ACCESS, res.accessToken);

          this.router.navigate([`${RouteSegments.LANDING_PAGE}`], {
            relativeTo: null,
          });
        }),
      );
  }

  handleMultiFactorAuthentication(
    accessData: JwtResponse,
    login = false,
    token?: string,
  ): boolean {
    const tncSessionData = this.securityService.getToken(
      TokenKey.TERMSANDCONDITION,
    );

    if (!accessData.isTwoFactorEnabled && accessData.setup) {
      this.router.navigate(
        [RouteSegments.ENTRY + '/' + RouteSegments.ENABLE_2FACTOR],
        { relativeTo: null },
      );
    } else if (
      accessData.isTwoFactorEnabled &&
      !accessData.isTwoFactorAuthenticated
    ) {
      this.router.navigate(
        [RouteSegments.ENTRY + '/' + RouteSegments.AUTHENTICATE_2FACTOR],
        { relativeTo: null },
      );
    } else if (this.checkIfEmailVerifyProcess()) {
      // For email change verification process
      this.router.navigate(
        [RouteSegments.PROFILE_PAGE, RouteSegments.EMAIL_VERIFICATION_PROCESS],
        { relativeTo: null },
      );
    } else if (accessData.tncApproved === false && !tncSessionData) {
      this.router.navigate(
        [RouteSegments.CONSENT_APPROVAL, RouteSegments.TERMS_AND_CONDITION],
        { relativeTo: null },
      );
    }
    // else if (!accessData.gdpr) {
    //   this.router.navigate(
    //       [RouteSegments.CONSENT_APPROVAL, RouteSegments.GDPR],
    //       { relativeTo: null },
    //   );
    // }
    else if (login) {
      let jwtDecodedValue: IEmailJWTDecoded | null = null;
      if (token) {
        const decryptedToken = this.securityService.decrypt(token);
        jwtDecodedValue = jwt_decode(decryptedToken);
      }
      if (!accessData.isExternal) {
        this.router.navigate([RouteSegments.LANDING_PAGE], {
          relativeTo: null,
        });
      } else if (accessData.isExternal) {
        if (jwtDecodedValue.caseId && jwtDecodedValue.caseId !== '') {
          this.router.navigate(
            [`${RouteSegments.CASES}/${jwtDecodedValue.caseId}`],
            { relativeTo: null },
          );
        } else {
          this.router.navigate([RouteSegments.CASES], { relativeTo: null });
        }
      }
    } else {
      return true;
    }
  }

  checkIfEmailVerifyProcess(): boolean {
    // to check if the user is undergoing email changing process
    const token =
      this.securityService.getSessionItem(
        localStorageKeys.EMAIL_VERIFY_TOKEN,
      ) || null;
    return !!token;
  }

  logout(): void {
    this.securityService.clearAllTokens();
    delete this.currentUser;
    this.router.navigate([RouteSegments.ENTRY]);
  }

  getTermsAndCondition(
    tncType: ETncType = ETncType.USER,
  ): Observable<ITermsAndConditionRes> {
    return this.http.get<ITermsAndConditionRes>(
      `${environment.baseUrl}${ApiList.users.termsAndCondition}`,
      { params: { tncType } },
    );
  }
  acceptTermsAndCondition(req: ITermsAndConditionReq): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrl}${ApiList.users.termsAndCondition}`,
      req,
    );
  }

  removeUserRole(userId: string): Observable<IRemoveUserRoleRes[]> {
    return this.http.post<IRemoveUserRoleRes[]>(
      `${environment.baseUrl}${ApiList.setup.userWorkspace}/${userId}/${ApiList.userWorkspace.roleRemove}`,
      {},
    );
  }

  assignUserRole(assignUserRoleData: IAssignUserRoleReq): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrl}${ApiList.setup.userWorkspace}/${ApiList.userWorkspace.roleAssign}`,
      assignUserRoleData,
    );
  }
  assignHrUserRole(id: string): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrl}${ApiList.setup.userWorkspace}/${id}/${ApiList.userWorkspace.hrRoleAssign}`,
      {},
    );
  }

  assignLanguage(languageData: IAssignLanguageReq): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrl}${ApiList.users.language}`,
      languageData,
    );
  }
  updateMfaStatus(status: boolean): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrl}${ApiList.users.mfa.status}`,
      { status },
    );
  }
  verifyNewEmailId(token: string): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrl}${ApiList.users.verifyOtpAndToken}`,
      {},
      { headers: { ResetToken: token } },
    );
  }
  updateEmailOtpVerify(req: { otp: number }): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrl}${ApiList.users.verifyOtpAndToken}`,
      req,
    );
  }
  updateExistingEmail(req: {
    email: string;
    isPrimary: boolean;
  }): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrl}${ApiList.users.updateExistingEmail}`,
      req,
    );
  }

  getTwoFactorData(): Promise<ITwoFactorInfo> {
    return this.http
      .get<ITwoFactorInfo>(`${environment.baseUrl}${ApiList.users.mfa._}`)
      .toPromise();
  }
}
