<div class="wa-container">
  <div class="page-header">
    <div class="row">
      <div class="col-12">
        <wr-breadcrumb
          [breadcrumbList]="[
            {
              title: 'TEMPLATE.TEMPLATE_CATEGORY.TITLE' | translate
            }
          ]"
        ></wr-breadcrumb>
      </div>
      <div class="col-12 col-sm">
        <h1 class="page-title">
          {{ 'TEMPLATE.TEMPLATE_CATEGORY.TITLE' | translate }}
        </h1>
      </div>
      <div class="col-sm-auto"></div>
    </div>
    <div class="row toolbar">
      <div class="col d-sm-flex">
        <mat-form-field appearance="fill" bg="white" class="me-2 search-input">
          <mat-icon color="primary" matPrefix>search</mat-icon>
          <input
            #searchInput
            autocomplete="off"
            matInput
            [placeholder]="'SEARCH.CATEGORY' | translate"
          />
          <mat-icon color="primary" matSuffix>close</mat-icon>
        </mat-form-field>
        <button
          [color]="'primary'"
          class="ms-auto"
          mat-flat-button
          (click)="addCategory()"
        >
          {{ 'TEMPLATE.ADD_CATEGORY.LABEL' | translate }}
        </button>
      </div>
    </div>
    <div class="account-container__filter-section"></div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        {{ node.name }}
        <ng-container
          *ngTemplateOutlet="addDeleteActionRef; context: { node: node }"
        ></ng-container>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ node.name }}
        <ng-container
          *ngTemplateOutlet="addDeleteActionRef; context: { node: node }"
        ></ng-container>
      </mat-tree-node>
    </mat-tree>
  </div>

  <ng-template #addDeleteActionRef let-node="node">
    <div class="ml-5 action-buttons">
      <a href="javascript:void(0)">
        <mat-icon>more_vert</mat-icon>
      </a>
      <a href="javascript:void(0)" (click)="insertItem(node)">
        <mat-icon>add</mat-icon>
      </a>
      <a href="javascript:void(0)" (click)="addCategory(node._id, node.name)">
        <mat-icon>edit </mat-icon>
      </a>
      <a href="javascript:void(0)" (click)="deleteCategory(node._id)">
        <mat-icon>delete </mat-icon>
      </a>
    </div>
  </ng-template>
</div>
