<p class="custom-days-and-hours__label" *ngIf="inputLabel">{{ inputLabel }}</p>
<div class="custom-days-and-hours__container">
  <div class="textbox-with-chips__container">
    <wr-textbox-with-chips
      [formArray]="formArray"
      [description]="description"
      [availableOptions]="availableOptions"
    >
    </wr-textbox-with-chips>
  </div>
  <div class="custom-days-and-hours__from-to__container">
    <div class="custom-days-and-hours__from-to__label">
      <span>Or add custom</span>
      <span>(From - To)</span>
    </div>
    <div class="custom-days-and-hours__from-to__timepickers">
      <wr-simple-timepicker
        class="d-inline-block w-auto custom-days-and-hours__from-to__timepickers__from"
        [minutesInStep]="15"
        [formControl]="fromToGroup.get('from')"
      ></wr-simple-timepicker>
      <span class="custom-days-and-hours__from-to__timepickers__separator"
        >-</span
      >
      <wr-simple-timepicker
        class="custom-days-and-hours__from-to__timepickers__to"
        [minutesInStep]="15"
        [formControl]="fromToGroup.get('to')"
      ></wr-simple-timepicker>
    </div>
    <button
      mat-button
      class="button__add-custom button--small"
      [disabled]="fromToGroup.invalid"
      (click)="addCustom()"
    >
      Add
    </button>
  </div>
</div>
