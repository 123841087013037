import {Component, Input, OnInit} from '@angular/core';
import {CaseDocument} from "@app/shared/cases.model";
import {UntypedFormGroup} from "@angular/forms";
import {DocumentSummaryData} from "@app/shared/documents.model";
import {DocumentBuilderService} from "@app/shared/document-builder/document-builder.service";
import {DocumentFormConfigStep} from "@app/modules/forms/models/document-templates.model";

@Component({
  selector: 'wr-residual-work-capacity-summary',
  templateUrl: './residual-work-capacity-summary.component.html',
  styleUrls: ['./residual-work-capacity-summary.component.scss']
})
export class ResidualWorkCapacitySummaryComponent implements OnInit {
  _value: CaseDocument;
  @Input() steps: DocumentFormConfigStep[];

  @Input()
  set caseDocumentData(value: CaseDocument) {
    if (value) {
      this._value = value;
      this.getSummary();
    }
  }

  get caseDocumentData(): CaseDocument {
    return this._value;
  }
  @Input() formGroup: UntypedFormGroup;
  @Input() preparedData: any;
  @Input() groupedStepsSummaries: DocumentSummaryData[][];
  modifiedSummaries: DocumentSummaryData[][];
  constructor(private readonly documentBuilderService: DocumentBuilderService) {}

  ngOnInit(): void {
      this.getSummary();
  }

  getSummary(): DocumentSummaryData[][] {
    let preparedData: any = this.documentBuilderService.prepareDataBeforeSubmit(this.formGroup);

    if (this.caseDocumentData) {
      preparedData = this.caseDocumentData.fields;
    }

    this.modifiedSummaries = this.groupedStepsSummaries;
    this.modifiedSummaries.forEach((summaryArr): void => {
      summaryArr.forEach((summary): void => {
        if (preparedData[summary.key]) {
          summary.value = preparedData[summary.key];
        }
        // Adding options
        // @ts-ignore
        summary.selectOptions = this.steps?.find(step => step.step === summary.step)?.controls.find(control => control.key === summary.key)?.options?.selectOptions;
        });
    });

    this.modifiedSummaries = this.removeUnwantedSummaries(this.modifiedSummaries);
    return this.modifiedSummaries;
  }

  removeUnwantedSummaries(modifiedSummary: DocumentSummaryData[][]): DocumentSummaryData[][] {
    this.modifiedSummaries.forEach((modSummary, ind1) => {
      modSummary.forEach((summ, ind2) => {
        if (!summ.value || summ.value.length === 0) {
          this.modifiedSummaries[ind1].splice(ind2, 1)
        }
      })
    })
    return modifiedSummary;
  }
}