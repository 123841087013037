import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import {
  DocumentFormConfig,
  DocumentFormConfigControl,
  DocumentFormConfigStep,
  DocumentSummaryControl,
} from '@app/modules/forms/models/document-templates.model';
import {
  DEFAULT_STEP_CHANGE_ACTIONS,
  DOCUMENT_CONTROL_DEFAULT_VALUE,
  DOCUMENT_CONTROL_TYPE,
} from '@app/shared/constants/document-builder.constants';
import { DocumentBuilderOutput, DocumentsControls, DocumentSummaryData } from '@app/shared/documents.model';
import { Languages } from '@app/shared/common.model';
import { DatetimeService } from '@app/shared/datetime/datetime.service';
import { SIMPLE_BACKEND_DATE_FORMAT, SIMPLE_DATE_FORMAT_REGEXP } from '@app/shared/constants/datetime.constants';

@Injectable({
  providedIn: 'root',
})
export class DocumentBuilderService {
  private readonly selectedLanguage: Languages;
  private onStepChangeActions: (() => void)[] = [];
  private groupControlsAlreadyUsedInSummary: number[] = [];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly datetimeService: DatetimeService,
    private readonly translateService: TranslateService,
  ) {
    this.selectedLanguage = this.translateService.currentLang as Languages;
  }

  buildDocumentFrom(data: DocumentFormConfig): DocumentBuilderOutput {
    this.onStepChangeActions = [];
    const formGroup: UntypedFormGroup = this.fb.group({});
    let summarySubscription: Subscription = new Subscription();
    data?.versions[0]?.template?.steps?.forEach((step) => {
      if (step.isSummary) {
        this.groupControlsAlreadyUsedInSummary = [];
        summarySubscription = this.addSummaryStepFormGroup(
          formGroup,
          step.controls as DocumentSummaryControl[],
          data?.versions[0]?.template?.steps,
        );
      } else {
        this.addControlsStepFormGroup(formGroup, step);
      }
    });
    return {
      formGroup,
      onStepChangeActions: this.onStepChangeActions,
      summarySubscription,
      stepControls: this.buildStepControls(formGroup),
    };
  }

  updateFormValue(form: UntypedFormGroup, value: { [key: string]: any }): void {
    const keysMatchingRegExp: string[] = [];
    Object.entries(form.getRawValue()).forEach(([key, v]) => {
      Object.keys(v).forEach((controlKey) => {
        const keyMatchingRegExp = /(control\d)/.exec(controlKey)?.[1];
        if (keyMatchingRegExp) {
          v = {
            ...(v as object),
            ...v[keyMatchingRegExp],
          };
          keysMatchingRegExp.push(keyMatchingRegExp);
          delete v[keyMatchingRegExp];
        }
      });
      Object.keys(v).forEach((controlKey) => {
        const matchingKey = Object.keys(value).find((k) => k === controlKey);
        if (matchingKey) {
          const newValue = value[matchingKey];
          let controlToPatch: AbstractControl = (form.get(key) as UntypedFormGroup).get(matchingKey);
          if (!controlToPatch) {
            keysMatchingRegExp.forEach((keyMatchingRegExp) => {
              const potentialControlToPatch = (form.get(key) as UntypedFormGroup).get(keyMatchingRegExp);
              if (potentialControlToPatch) {
                controlToPatch = potentialControlToPatch.get(matchingKey);
              }
            });
          }
          if (typeof controlToPatch.value === 'object' && newValue) {
            if (typeof newValue[0] === 'object') {
              (controlToPatch as UntypedFormArray).controls.forEach((_, index) => {
                (controlToPatch as UntypedFormArray).removeAt(index);
              });
              const [inputControlName, selectControlName] = Object.keys(newValue[0]);

              (newValue as any[]).forEach((newVal) => {
                (controlToPatch as UntypedFormArray).push(
                    this.fb.group({
                      [inputControlName]: [newVal[inputControlName]],
                      [selectControlName]: [newVal[selectControlName]],
                    })
                );
              });
            } else if (Array.isArray(controlToPatch.value) && controlToPatch.value.length < newValue.length) {
              (controlToPatch as UntypedFormArray).controls.forEach((_, index) => {
                (controlToPatch as UntypedFormArray).removeAt(index);
              });
              (newValue as any[]).forEach(() => {
                (controlToPatch as UntypedFormArray).push(this.fb.control(''));
              });
            }
          }
          controlToPatch.patchValue(newValue);
        }
      });
    });
  }

  createSummaryFields(summary: { [key: string]: DocumentSummaryData }): { [key: string]: string | string[] } {
    const fields: { [key: string]: string | string[] } = {};
    Object.values(summary).forEach((summaryValue) => {
      let fieldValue = summaryValue.value;
      if (!Array.isArray(fields[summaryValue.key]) && SIMPLE_DATE_FORMAT_REGEXP.test(fieldValue)) {
        // dd.MM.YYYY is changed to DD-MM-YYYY to match backend format
        fieldValue = this.datetimeService.formatSimpleDateToDayjs(fieldValue).format(SIMPLE_BACKEND_DATE_FORMAT);
      }
      fields[summaryValue.key] = fieldValue;
    });
    return fields;
  }

  private buildStepControls(formGroup: UntypedFormGroup): AbstractControl[] {
    let stepControls = [...Object.values(formGroup.controls)];
    stepControls = stepControls.map((stepControl) => {
      const controlName = Object.keys(stepControl.value)?.[0];
      let controlToReturn = stepControl;
      if (controlName && controlName.match(/control\d/)) {
        controlToReturn = (stepControl as UntypedFormGroup).controls[controlName];
      }
      return controlToReturn;
    });
    return stepControls;
  }

  private addSummaryStepFormGroup(
    formGroup: UntypedFormGroup,
    controls: DocumentSummaryControl[],
    steps: DocumentFormConfigStep[],
  ): Subscription {
    formGroup.addControl('summary', this.fb.group({}));
    const summaryFormGroup: UntypedFormGroup = formGroup.get('summary') as UntypedFormGroup;
    let currentControlIndex = 0;
    controls.forEach((control) => {
      if (typeof control.key === 'string') {
        this.addSummaryControlWithSpecificKey(
          formGroup,
          summaryFormGroup,
          currentControlIndex,
          control.key,
          control,
          steps,
        );
        currentControlIndex++;
      } else if (Array.isArray(control.key)) {
        (control.key as string[]).forEach((controlKey) => {
          this.addSummaryControlWithSpecificKey(
            formGroup,
            summaryFormGroup,
            currentControlIndex,
            controlKey,
            control,
            steps,
          );
          currentControlIndex++;
        });
      }
    });

    return formGroup.valueChanges.pipe(distinctUntilChanged(isEqual)).subscribe(() => {
      const formValue: any = formGroup.getRawValue();
      let controlIndex = 0;
      controls.forEach((control) => {
        let controlValue = null;
        if (typeof control.key === 'string') {
          controlValue = formValue[`step${control?.controlStep}`][control.key];
          this.patchSummaryFormGroup(summaryFormGroup, controlIndex, controlValue);
          controlIndex++;
        } else if (Array.isArray(control.key)) {
          (control.key as string[]).forEach((key) => {
            controlValue = formValue[`step${control.controlStep}`][key];
            if (controlValue === undefined) {
              controlValue = Object.values(formValue[`step${control.controlStep}`])[0][key];
              if (!controlValue) {
                controlValue = {
                  control: null,
                  array: [],
                  group: {},
                }[DOCUMENT_CONTROL_TYPE[control.type]];
              }
            }
            this.patchSummaryFormGroup(summaryFormGroup, controlIndex, controlValue);
            controlIndex++;
          });
        }
      });
    });
  }

  private patchSummaryFormGroup(summaryFormGroup: UntypedFormGroup, index: number, value: any): void {
    const control = summaryFormGroup.get(`${index}`);
    this.handleSummaryArrayDifference(control, value);
    control.get('value').patchValue(value, { emitEvent: false });
  }

  private handleSummaryArrayDifference(control: AbstractControl, value: any): void {
    if (DOCUMENT_CONTROL_TYPE[control.get('type').value] === 'array') {
      const controlValue = control.get('value').value as any[];
      if (controlValue.length < (value as any[]).length) {
        (value as any[]).forEach((item, i) => {
          if (controlValue[i] === undefined) {
            (control.get('value') as UntypedFormArray).push(this.fb.control(item));
          }
        });
      } else if (controlValue.length > (value as any[]).length) {
        controlValue.forEach((_, i) => {
          if (value[i] === undefined) {
            (control.get('value') as UntypedFormArray).removeAt(i);
          }
        });
      }
    }
  }

  private addSummaryControlWithSpecificKey(
    formGroup: UntypedFormGroup,
    summaryFormGroup: UntypedFormGroup,
    index: number,
    key: string,
    control: DocumentSummaryControl,
    steps: DocumentFormConfigStep[],
  ): void {
    const value = this.findValueInGroupByKey(formGroup, key);
    const selectOptions = this.findSelectOptionsInStepByKey(steps, control.controlStep, key);
    let controlType: 'control' | 'array' | 'group' = 'control';
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        controlType = 'array';
      } else {
        controlType = 'group';
      }
    }
    let heading = control.summaryHeading;
    if (Array.isArray(heading)) {
      let countOfControlsWithTheSameKeyAndIndex = 0;
      Object.values(summaryFormGroup.getRawValue()).forEach((v: DocumentSummaryData) => {
        if (v.step === control.controlStep && v.type === control.type) {
          countOfControlsWithTheSameKeyAndIndex++;
        }
      });
      heading = heading[countOfControlsWithTheSameKeyAndIndex];
    }
    summaryFormGroup.addControl(
      `${index}`,
      this.fb.group({
        description: control.summaryDescription?.[this.selectedLanguage],
        type: control.type,
        value: this.fb[controlType](value || ''),
        key,
        step: control.controlStep,
        heading,
        selectOptions: this.fb.group(selectOptions),
        disabledUnderline: control.disabledUnderline,
        noValueInfo: control.noValueInfo,
        parentKey: control.parentKey,
      }),
    );
  }

  private findSelectOptionsInStepByKey(
    steps: DocumentFormConfigStep[],
    stepIndex: number,
    key: string,
  ): { [key: string]: string } {
    if (!steps[stepIndex]?.controls) {
      return {};
    }
    const control: DocumentFormConfigControl = (steps[stepIndex]?.controls as DocumentFormConfigControl[]).find(
      (ctrl) => ctrl.key === key,
    );
    const options = {};
    control?.options?.selectOptions?.forEach((opt) => {
      if (typeof opt === 'string') {
        options[opt] = opt;
      } else if (typeof opt === 'object') {
        options[opt.value] = opt.label;
        if (typeof options[opt.value] === 'object') {
          options[opt.value] = options[opt.value][this.selectedLanguage];
        }
      }
    });
    return options;
  }

  private findValueInGroupByKey(formGroup: UntypedFormGroup, key: string): any {
    let value = null;
    if (JSON.stringify(formGroup.getRawValue()).indexOf(key) > -1) {
      if (formGroup.get(key)) {
        value = formGroup.get(key).value;
      } else if (Object.values(formGroup.getRawValue()).some((v) => v[key] || v[key] === '')) {
        const keyOfSearchedValue: string = Object.entries(formGroup.getRawValue()).find(
          ([_, v]) => v[key] || v[key] === '',
        )?.[0];
        value = formGroup.getRawValue()[keyOfSearchedValue];
        if (
          formGroup.getRawValue()[keyOfSearchedValue][key] ||
          formGroup.getRawValue()[keyOfSearchedValue][key] === ''
        ) {
          value = formGroup.getRawValue()[keyOfSearchedValue][key];
        }
      } else if (Object.values(formGroup.getRawValue()).some((v) => Object.keys(v).some((k) => k.match(/control\d/)))) {
        const objectsArray = Object.values(
          Object.values(formGroup.getRawValue()).find((v) =>
            Object.keys(v).some(
              (k) =>
                k.match(/control\d/) &&
                Object.values(v)[0] &&
                Object.keys(Object.values(v)[0]).some((nestedKey) => nestedKey === key),
            ),
          ),
        );
        value = objectsArray[this.groupControlsAlreadyUsedInSummary.length];
        if (value === undefined) {
          value = objectsArray[0];
        }
        if (value?.[key]) {
          value = value[key];
        }
        this.groupControlsAlreadyUsedInSummary.push(this.groupControlsAlreadyUsedInSummary.length);
      }
    } else {
      console.warn(`No value with \"${key}\" key`);
    }
    return value;
  }

  private addControlsStepFormGroup(form: UntypedFormGroup, step: DocumentFormConfigStep): void {
    form.addControl(`step${step.step}`, this.fb.group({}));
    const currentFormGroup: UntypedFormGroup = (form.controls as { [key: string]: UntypedFormGroup })[`step${step.step}`];
    step.controls?.forEach((stepControl) => {
      this.addStepControl(currentFormGroup, stepControl);
      const currentControl: AbstractControl = currentFormGroup.get(stepControl.key);
      if (currentControl) {
        this.updateStepChangeActions(currentControl, stepControl.type);
        if (stepControl.options?.required) {
          this.addRequiredFieldValidator(currentControl, stepControl.type);
        }
      }
    });
  }

  private prepareControlDefaultValue(stepControl: DocumentFormConfigControl, defaultValue: any): any {
    const isControlKeyArrayType = Array.isArray(stepControl.key);
    if (
      (isControlKeyArrayType || stepControl.nestedKeys) &&
      (isControlKeyArrayType ? !(stepControl.key as string[]).some((k) => k === 'followUpPlanStartDate') : true)
    ) {
      (stepControl.nestedKeys || (stepControl.key as string[])).forEach((key, index) => {
        const isGroupType = DOCUMENT_CONTROL_TYPE[stepControl.type] === 'group';
        const selectedDefaultValue = isGroupType ? defaultValue : defaultValue[0];
        const keyWithTheSameIndex = Object.keys(selectedDefaultValue).find(
          (defaultValueKey) => defaultValueKey === `key${index}`,
        );
        const keyWithTheSameIndexValue = selectedDefaultValue[keyWithTheSameIndex];
        delete selectedDefaultValue[keyWithTheSameIndex];
        selectedDefaultValue[key] = keyWithTheSameIndexValue;
      });
    }

    if (
      DOCUMENT_CONTROL_TYPE[stepControl.type] === 'array' &&
      Array.isArray(defaultValue) &&
      typeof defaultValue[0] === 'object'
    ) {
      defaultValue = (defaultValue as object[]).map((value) => this.fb.group(value));
    }

    if (DOCUMENT_CONTROL_TYPE[stepControl.type] === 'group') {
      Object.entries(defaultValue).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          Object.entries(defaultValue[key] || defaultValue).forEach(([nestedKey, nestedValue]) => {
            const controlValueObject = defaultValue[key] || defaultValue;
            if (controlValueObject && nestedValue !== undefined) {
              if (typeof nestedValue === 'object' && !Array.isArray(nestedValue)) {
                controlValueObject[nestedKey] = nestedValue ? this.fb.group(nestedValue) : this.fb.control(null);
              } else if (Array.isArray(nestedValue)) {
                controlValueObject[nestedKey] = this.fb.array((nestedValue as any[]) || []);
              } else if (
                typeof nestedValue === 'boolean' ||
                typeof nestedValue === 'string' ||
                typeof nestedValue === 'number'
              ) {
                controlValueObject[nestedKey] = this.fb.control(nestedValue || null);
              }
            }
          });
          defaultValue[key] = value ? this.fb.group(value) : this.fb.control(null);
        }
      });
    }
    return defaultValue;
  }

  private addStepControl(form: UntypedFormGroup, stepControl: DocumentFormConfigControl): void {
    if (DOCUMENT_CONTROL_TYPE[stepControl.type] && DOCUMENT_CONTROL_TYPE[stepControl.type]) {
      const defaultValue = this.prepareControlDefaultValue(
        stepControl,
        JSON.parse(JSON.stringify(DOCUMENT_CONTROL_DEFAULT_VALUE[stepControl.type])),
      );
      const newControl: UntypedFormGroup | UntypedFormArray | UntypedFormControl = this.fb[DOCUMENT_CONTROL_TYPE[stepControl.type]](
        defaultValue,
        stepControl.options?.required ? Validators.required : undefined,
      );
      form.addControl(
        Array.isArray(stepControl.key)
          ? `control${Object.keys(form.getRawValue()).length}`
          : (stepControl.key as string),
        newControl,
      );
    } else {
      console.error(`'${stepControl.type}' control type is not defined`);
    }
  }

  private addRequiredFieldValidator(control: AbstractControl, stepControlType: DocumentsControls): void {
    if (
      stepControlType === DocumentsControls.TEXTBOXES_LIST ||
      stepControlType === DocumentsControls.SMALL_TEXTAREA_LIST
    ) {
      (control as UntypedFormArray).controls.forEach((arrayControl: UntypedFormControl) =>
        arrayControl.setValidators([Validators.required]),
      );
    }
  }

  private updateStepChangeActions(control: AbstractControl, stepControlType: DocumentsControls): void {
    this.onStepChangeActions = [...this.onStepChangeActions, DEFAULT_STEP_CHANGE_ACTIONS(control)[stepControlType]];
  }

  prepareDataBeforeSubmit(formData: UntypedFormGroup): any {
    const formValue = formData.value;

    let finalVal = {};
    Object.keys(formValue).forEach((key) => {

      if (this.checkIfKeyBeginsWith('step', key)) {
        if (formValue[key].control0) {
          // TODO need to revamp as no proper fix available
          finalVal = { ...finalVal, ...formValue[key].control0 };
        } else {
          Object.keys(formValue[key]).forEach(objKey => {
            if (!objKey || objKey === 'undefined') {
              delete  formValue[key][objKey]
            }
            // else if (formValue && formValue[key] && formValue[key][objKey] && formValue[key][objKey].length === 0 || (formValue[key][objKey].length === 1 && formValue[key][objKey][0] === '')) {
            //   // The above condition is to check for empty text box lists or empty lists in general
            //   formValue[key][objKey] = null;
            // }
          })
          finalVal = { ...finalVal, ...formValue[key] };
        }
      }
    });
    return finalVal;
  }

  checkIfKeyBeginsWith(checkVal: string, key: string): boolean {
    const str = '^' + checkVal;
    const regexp = new RegExp(str);

    return regexp.test(key); // true
  }
}
