import { Component, Input } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-document-summary-card-details-list',
  templateUrl: './document-summary-card-details-list.component.html',
  styleUrls: ['./document-summary-card-details-list.component.scss'],
})
export class DocumentSummaryCardDetailsListComponent {
  @Input() summaryControl: DocumentSummaryData;
  @Input() type: string = ListTypeEnum.BULLET;
  @Input() icon = 'adjust';

  constructor(private readonly iconsHelperService: IconsHelperService) {
    this.iconsHelperService.registerSvgIcons('summary-current');
  }
}

export enum ListTypeEnum{
BULLET = 'bullet',
TAGS = 'tags'
}