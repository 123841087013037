import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { LONG_TIME_PATTERN, MINUTES_IN_HOUR } from '@app/shared/directives/hours-time-range/hours-time-range.constants';
import { DatetimeService } from '@app/shared/datetime/datetime.service';

@Directive({
  selector: '[wrHoursTimeRange]',
})
export class HoursTimeRangeDirective {
  @Input() hoursRange = 24;
  @Input() allowMaxRangeValue = false;

  constructor(private readonly controlAccessor: NgControl, private readonly datetimeService: DatetimeService) {}

  @HostListener('input')
  onChange(): void {
    setTimeout(() => {
      this.changeTimeIfItsOverRange();
    });
  }

  private changeTimeIfItsOverRange(): void {
    if (this.isControlValueValid() && this.isTimeOverRange()) {
      this.controlAccessor.control.patchValue(
        this.allowMaxRangeValue ? `${this.hoursRange}:00` : `${this.hoursRange - 1}:59`,
      );
    }
  }

  private isControlValueValid(): boolean {
    return (
      this.controlAccessor &&
      this.controlAccessor.control &&
      this.controlAccessor.control.value &&
      LONG_TIME_PATTERN.test(this.controlAccessor.control.value)
    );
  }

  private isTimeOverRange(): boolean {
    const { hours, minutes } = this.datetimeService.getHoursAndMinutesFromTime(this.controlAccessor.control.value);
    return hours * MINUTES_IN_HOUR + minutes >= this.hoursRange * MINUTES_IN_HOUR;
  }
}
