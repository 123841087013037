import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {FollowUpComponent} from "@app/modules/follow-up/follow-up.component";

const routes: Routes = [
  {
    path: '',
    component: FollowUpComponent,
    data: { breadcrumb: 'Followup' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FollowUpRoutingModule { }
