import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInviteEmployeeFormData } from '@app/modules/setup/setup.model';

@Component({
  selector: 'wr-common-team-add-display',
  templateUrl: './common-team-add-display.component.html',
  styleUrls: ['./common-team-add-display.component.scss'],
})
export class CommonTeamAddDisplayComponent implements OnInit {
  @Input()
  mode?: TeamViewEnum = null;
  @Input()
  data: IInviteEmployeeFormData | null = null;
  @Input()
  showExcelButton = false;

  @Output()
  deleteDataEvent = new EventEmitter<Boolean>();
  @Output()
  addDataEvent = new EventEmitter<Boolean>();
  @Output()
  searchDataEvent = new EventEmitter<Boolean>();
  @Output()
  selectExcelDataEvent = new EventEmitter<Boolean>();
  constructor() {}

  ngOnInit(): void {}
  deleteData() {
    this.deleteDataEvent.emit(true);
  }
  addData() {
    this.addDataEvent.emit(true);
  }
  searchData() {
    this.searchDataEvent.emit(true);
  }

  selectExcelData() {
    this.selectExcelDataEvent.emit(true);
  }
  getTooltipText() {
    return `${this.getFullName()}
    ${this.data.email}`;
  }
  getFullName(): string {
    const name =
      this.stringReturnEmptyIfNull(this.data.firstName) +
      ' ' +
      this.stringReturnEmptyIfNull(this.data.lastName);
    return name.trim();
  }

  stringReturnEmptyIfNull(str: string): string {
    if (str === null || str === undefined || str === '') {
      return '';
    } else {
      return str.trim();
    }
  }
}

export enum TeamViewEnum {
  ADD = 0,
  SEARCH = 1,
  READONLY = 2,
}
