<div class="align-middle">
  <mat-spinner [diameter]="50" *ngIf="!schedulerData"></mat-spinner>
</div>

<div class="modal-body-inner" *ngIf="schedulerData">
  <mat-accordion
    class="example-headers-align accordion-custom scheduler-summary"
    multi
  >
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark"
          >{{ 'SCHEDULER.TEXT.HOW_OFTEN_TO_FOLLOW_UP' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <mat-icon>description</mat-icon>
          <span>{{
            (schedulerData.schedulerRepeat
              ? 'SCHEDULER.LABEL.REPEAT'
              : 'SCHEDULER.LABEL.ONCE'
            ) | translate
          }}</span>
        </div>
        <div
          class="scheduler-summary-item"
          *ngIf="schedulerData.schedulerRepeat"
        >
          <p>{{ 'SCHEDULER.LABEL.OCCURRENCE' | translate }}</p>
          <span
            >{{ 'SCHEDULER.LABEL.EVERY' | translate }}
            {{ schedulerData.schedulerRepeatValue }}
            {{ schedulerData.schedulerRepeatFrequency }}</span
          >
        </div>
        <div
          class="scheduler-summary-item"
          *ngIf="schedulerData.schedulerRepeat"
        >
          <p>{{ 'SCHEDULER.LABEL.FOR' | translate }}</p>
          <span>
            {{ schedulerData.schedulerDurationValue }}
            {{ schedulerData.schedulerDurationFrequency }}</span
          >
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="">
        <mat-panel-title class="text-primary-dark">{{
          'SCHEDULER.LABEL.FOLLOW_UP_DATE_TIME' | translate
        }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <span class="text-muted me-5">
            <mat-icon class="me-0">calendar_today</mat-icon>
            {{ schedulerData.schedulerPrefferedDate | customDate }}
          </span>
          <span class="text-muted">
            <mat-icon class="me-0">schedule</mat-icon
            >{{ schedulerData.schedulerPrefferedTime }}
          </span>
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="">
        <mat-panel-title class="text-primary-dark">{{
          'SCHEDULER.TEXT.HOW_WOULD_YOU_LIKE_TO_FOLLOW_UP' | translate
        }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <span>{{ schedulerData.schedulerFollowupType | uppercase }}</span>
        </div>
        <!-- <div
          class="scheduler-summary-item"
          *ngIf="schedulerData.schedulerFollowupType == 'message' && schedulerData.schedulerFollowupMessage"
        >
          <p>Follow-up message</p>
          <span>{{ schedulerData.schedulerFollowupMessage | uppercase }}</span>
        </div> -->
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</div>
