export enum NotificationColor {
  SUCCESS = 'success-notification-overlay',
  WARNING = 'warning-notification-overlay',
  ERROR = 'error-notification-overlay',
}

export enum NotificationShowTime {
  SHORT = 1500,
  MEDIUM = 3000,
  LONG = 8000,
}

export enum NotificationPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum NotificationHorizontalPosition {
  LEFT = 'left',
  CENTER = 'center',
  right = 'right',
}
