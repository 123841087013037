import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { WrButtonComponent } from "./button.component";

@NgModule({
  declarations: [WrButtonComponent],
  imports: [CommonModule],
  exports: [WrButtonComponent]
})
export class WrButtonsModule {}
