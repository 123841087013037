<div class="example-header">
  <div>
    <mat-icon [className]="'arrow'" (click)="previousClicked('month')">west</mat-icon>
  </div>

  <span class="example-header-label">{{ periodLabel }}</span>

  <div>
    <mat-icon [className]="'arrow'" (click)="nextClicked('month')">east</mat-icon>
  </div>
</div>
