import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'wr-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss']
})
export class FollowUpComponent implements OnInit {
  optionsList: ITemplateOptionList[] = [
    {id: 1, name: 'Follow-up', icon: 'calendar_add_on', class: ''},
    {id: 2, name: 'Quick Follow-up or Dialogue', icon: 'quick_phrases', class: 'info'},
    {id: 3, name: 'Survey or Question task', icon: 'quick_reference', class: 'warning'},

  ]
  selectedOption: null | number = null;
  constructor(
  ) {
  }

  ngOnInit(): void {
  }
  toggleOption(option: ITemplateOptionList) {
    this.selectedOption = option.id;
  }
}

export interface ITemplateOptionList  {
  id: number,
  name: string,
  icon: string,
  class: string,
}
