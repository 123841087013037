import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BREAKPOINT_MEDIUM_SCREEN} from "@app/constants/breakpoints.constants";
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ResponsivenessService} from "@app/core/services/responsiveness/responsiveness.service";
import {DialogHelperService} from "@app/shared/dialog-helper/dialog-helper.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {DialogId} from "@app/shared/dialogs.model";

@Component({
  selector: 'wr-template-cateogry-add',
  templateUrl: './template-cateogry-add.component.html',
  styleUrls: ['./template-cateogry-add.component.scss']
})
export class TemplateCateogryAddComponent implements OnInit, OnDestroy {

  mediumScreenBreakpoint = BREAKPOINT_MEDIUM_SCREEN;
private readonly onDestroy$ = new Subject<null>();
  categoryAddForm: UntypedFormGroup;

  constructor(
    public readonly responsivenessService: ResponsivenessService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly fb: UntypedFormBuilder,
  @Inject(MAT_DIALOG_DATA)
public data: {updateValue: string},
) {}

  ngOnInit(): void {
    this.buildAddForm();
  }
private buildAddForm(): void {
    this.categoryAddForm = this.fb.group({
      name: [this.data?.updateValue],
    });
  }
  closeDialog = (): void => {
    this.dialogHelperService.closeDialog(DialogId.ADD_TEMPLATE_CATEGORY, {
      dialogResult: false,
    });
  };

   save():void {
     this.dialogHelperService.closeDialog(DialogId.ADD_TEMPLATE_CATEGORY, {
       dialogResult: this.categoryAddForm.value.name,
     });
}
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
