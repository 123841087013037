<div class="d-flex">
  <div class="m-auto location-container">
    <mat-radio-group
      (change)="radioButtonChanged(true)"
    >
      <mat-radio-button *ngFor="let option of selectOptions"
                        (click)="value = option.value; radioButtonChanged(true)"
                        [value]="option.value"
                        [checked]="value === option.value">
        {{ option.label | translate }}
      </mat-radio-button>
      <mat-radio-button (click)="showCustomField = true" [value]="'custom_location_val_not_needed'" [checked]="isCustomChecked()">
        {{ 'OTHER_LOCATION' | translate }}
      </mat-radio-button>

    </mat-radio-group>
    <form
      class="textbox__form"
      *ngIf="isCustomChecked()"
    >
      <mat-form-field [floatLabel]="false" class="textbox__form-field">
        <mat-icon color="primary" matPrefix>location_on</mat-icon>
        <input
          type="text"
          matInput
          #customLocationInput
          [value]="customLocationVal"
          (keyup)="inputChanged($event.target.value)"
          [wrAutoFocus]="autofocus"
          class="ms-2"
        />
      </mat-form-field>
    </form>
  </div>
</div>
