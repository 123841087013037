<form [formGroup]="control.parent" class="percentage__form">
  <h3 *ngIf="description" class="percentage__form-title">
    {{ description }}
  </h3>
  <p *ngIf="label">{{ label }}</p>
  <mat-form-field
    class="percentage__form-field"
    ngDefaultControl
    [ngClass]="{
      'one-digit': control.value <= 9,
      'three-digits': control.value >= 100
    }"
    floatLabel="never"
  >
    <input type="number" [formControl]="control" matInput />
  </mat-form-field>
</form>
