import {Component, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {BREAKPOINT_MEDIUM_SCREEN} from "@app/constants/breakpoints.constants";
import {DialogHelperService} from "@app/shared/dialog-helper/dialog-helper.service";
import {ResponsivenessService} from "@app/core/services/responsiveness/responsiveness.service";
import {IBreadcrumbItems} from "@app/shared/custom-components/breadcrumb/breadcrumb.component";
import {DialogId} from "@app/shared/dialogs.model";
import {SelectOption} from "@app/shared/common.model";
import {DocumentsControls} from "@app/shared/documents.model";

export interface IPageData {
  key: string;
  label: string,
  inputLabel: string,
  type: string,
  value: string | number | Array<any>
  options?: SelectOption<any>[]
  hidden: boolean
  showControl?: {
    controlId: string;
    when: string
  },
  showPage?: {
    pageId: string;
    when: string
  }
}

export interface ITemplateData {
  hidden: boolean;
  key: string;
  title: string,
  controls: IPageData[];
}

@Component({
  selector: 'wr-template-page',
  templateUrl: './template-page.component.html',
  styleUrls: ['./template-page.component.scss']
})

export class TemplatePageComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  loading = false;
  mediumScreenBreakpoint = BREAKPOINT_MEDIUM_SCREEN;
  currentStep = 0;
  templateData: ITemplateData[] =
    [
      {
        hidden: false,
        key: 'firstPage',
        title: 'This is the first page', controls: [
          {
            hidden: false,
            key: '1', label: 'This is a short text input', type: 'text', value: 'prefilled data', inputLabel: 'Name'
          },
          {
            hidden: false,
            key: '2',
            label: 'This is a multi val input',
            type: 'multiAnswer',
            value: [],
            inputLabel: 'Job',
            options: [{label: 'Testing as val1', value: 1}, {label: 'Testing as val2', value: 2}]
          },
          {
            showPage: {
              pageId: 'thirdPage', when: "=== 3"
            },
            hidden: false,
            key: '3',
            label: 'Show third page?',
            type: 'singleAnswer',
            value: '',
            inputLabel: 'Type',
            options: [{label: 'SHOW THE THIRD PAGE', value: 3}, {label: 'HIDE THE THIRD PAGE', value: 4}]
          },
        ]
      },
      {
        hidden: false,
        key: 'secondPage',
        title: 'This is the second page', controls: [
          {
            showControl: {
              controlId: '5', when: ">= 4"
            }, hidden: false, key: '4', label: 'how much do you rate it', type: 'rating', value: 3, inputLabel: 'Name'
          },
          {hidden: false, key: '5', label: 'Percentage you like', type: 'slider', value: 50, inputLabel: 'Job'},
          {
            hidden: false,
            key: '6',
            label: 'Do you want to switch off notification',
            type: 'toggle',
            value: '',
            inputLabel: 'Type'
          },
        ]
      },
      {
        hidden: false,
        key: 'thirdPage',
        title: 'This is the third page', controls: [
          {hidden: false, key: '7', label: 'how much do you rate it', type: 'rating', value: 3, inputLabel: 'Name'},
          {hidden: false, key: '8', label: 'Percentage you like', type: 'slider', value: 50, inputLabel: 'Job'},
          {
            hidden: false,
            key: '9',
            label: 'Do you want to switch off notification',
            type: 'toggle',
            value: '',
            inputLabel: 'Type'
          },
        ]
      },
    ]
  protected readonly documentControls = DocumentsControls;

  constructor(
    private readonly dialogHelperService: DialogHelperService,
    public readonly responsivenessService: ResponsivenessService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.initializeControlsToHide();
    this.loading = false;
  }

  initializeControlsToHide() {
    this.templateData.forEach(data => {
      data.controls.forEach(control => {
        this.handleControlVisibility(control)
      })
    })
  }

  getBreadcrumbItems(): IBreadcrumbItems[] {
    return [{title: 'select leader'}];
  }

  onClose(): void {
    this.dialogHelperService.closeDialog(DialogId.MANAGE_EMPTY_TEAMSPACE, {dialogResult: false});
  }

  stepperNext(): void {
    this.currentStep += 1;
    this.stepper.selectedIndex = this.currentStep;
  }

  stepperPrev(): void {
    this.currentStep -= 1;
    this.stepper.selectedIndex = this.currentStep;
  }

  test(dd) {
    return JSON.stringify(dd);
  }

  handleControlVisibility(control: IPageData) {
    setTimeout(() => {
      if (control.showPage) {
        this.handlePageVisibility(control);
      }
      if (!control.showControl) {
        return true; // If no showControl configuration, default to visible
      }

      const controlId = control.showControl.controlId;
      const when = control.showControl.when;
      // Evaluate the visibility condition
      const shouldShow = eval(`control.value
      ${when}`);
      this.templateData.forEach(data => {
        data.controls.forEach(ctrl => {
          if (ctrl.key === controlId) {
            ctrl.hidden = !shouldShow
          }
        })
      })
    }, 250)

  }

  handlePageVisibility(control: IPageData) {
    setTimeout(() => {
      if (!control.showPage) {
        return true; // If no showControl configuration, default to visible
      }
      const pageId = control.showPage.pageId;
      const when = control.showPage.when;
      // Evaluate the visibility condition
      const shouldShow = eval(`control.value
      ${when}`);
      this.templateData.forEach(page => {
        if (page.key === pageId) {
          page.hidden = !shouldShow
        }
      })
      }, 250)

  }

}
