import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MatLegacyMenuPanel as MatMenuPanel } from '@angular/material/legacy-menu';
import { DOCUMENT } from '@angular/common';

import { BREAKPOINT_SMALL_SCREEN } from '@app/shared/constants/breakpoints.constants';
import {
  MenuStateBodyClass,
  PermissionName,
  SidenavItem,
  SidenavItemsActions,
} from '@app/core/app-sidenav/app-sidenav.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';
import { UserService } from '@app/shared/user/user.service';
import { SIDENAV_ITEMS } from '@app/core/app-sidenav/app-sidenav.constants';
import { RouteSegments } from '@app/shared/routing.model';
import { CaseColor } from '@app/shared/cases.model';
import {
  COLOR_GREEN_1,
  COLOR_ORANGE_1,
  COLOR_RED_1,
} from '@app/shared/constants/theme.constants';
import { WorkspaceService } from '@app/modules/shared/services/workspace/workspace.service';
import {
  IUserWorkspaceList,
  User,
} from '@app/modules/shared/models/user.model';
import { AppHeaderService } from '@app/core/services/app-header/app-header.service';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { ChangesToTaskConfirmationComponent } from '@app/shared/components/documents/reports/changes-to-task-confirmation/changes-to-task-confirmation.component';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { CommonService } from '@app/modules/shared/services/user/common.service';
import { OnboardingComponent } from '@app/modules/setup/components/onboarding/onboarding.component';
import { JwtResponse } from '@app/modules/shared/models/jwt.model';
import { PermissionService } from '@app/modules/shared/services/common/permissions.service';
import { PERMISSIONS } from '@app/modules/shared/constants/permissions.contants';
import { SecurityService } from '../../services/security/security.service';

@Component({
  selector: 'wr-app-sidenav',
  templateUrl: './app-sidenav.component.html',
  styleUrls: ['./app-sidenav.component.scss'],
})
export class AppSidenavComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  @ViewChild(MatSidenav, { static: false }) matSidenav: MatSidenav;
  @ViewChild('newActionMenu', { static: true }) newActionMenu: MatMenuPanel;

  accessData: JwtResponse;
  isLeader: boolean;
  isAdmin: boolean;

  private readonly onDestroy$ = new Subject<null>();
  private mobileQueryListener: () => void;

  isOpened: boolean;
  openingInProgress = false;
  closingInProgress = false;
  animationInProgress = false;
  mobileQuery: MediaQueryList;
  sidenavItems: SidenavItem[];
  activatedRoute: string;
  routeSegments: typeof RouteSegments = RouteSegments;
  actionMenus: { [key: string]: MatMenuPanel };
  caseUser: string;
  caseNumber = 'CASE NUMBER';

  workabilityCircleSliceStyle = 'none';
  workabilityStatusColor: string;

  userWorkspaceList: IUserWorkspaceList[] = [];
  currentWorkspace: IUserWorkspaceList;
  currentUserWorkspaceId: string;

  existingPermissions: PermissionName[] = [];
  mobileBottomMenu: SidenavItem[];
  mobileAdditionalBottomMenu: SidenavItem[];
  bottomMenuOpened = false;
  bottomAdditionaMenuOpened = false;
  currentUser: User;
  productionEnabled = false;
  actionMenuItems = [
    {
      icon: 'self-certification-button-icon',
      label: 'LANDING_PAGE.DOCUMENTS.BUTTONS.SELF_CERTIFICATION',
      action: async () => await this.openSelfCertificationDialog(),
      permissions: [PERMISSIONS.FOLLOW_UP.CREATE.SELF_CERTIFICATION],
    },
    {
      icon: 'sick-leave-button-icon',
      label: 'LANDING_PAGE.DOCUMENTS.BUTTONS.SICK_LEAVE',
      action: async () => await this.openSickLeaveDialog(),
      permissions: [PERMISSIONS.FOLLOW_UP.CREATE.SICK_LEAVE],
    },
    {
      icon: 'report-work-issue-button-icon',
      label: 'LANDING_PAGE.DOCUMENTS.BUTTONS.REPORT_AN_ISSUE',
      action: async () => await this.openReportIssueDialog(),
      permissions: [PERMISSIONS.FOLLOW_UP.CREATE.OTHER_ISSUE],
    },
    {
      icon: 'temporary-change-button-icon',
      label: 'LANDING_PAGE.DOCUMENTS.BUTTONS.TEMPORARY_CHANGE_REQUEST',
      action: async () => await this.openTemporaryChangeRequestDialog(),
      permissions: [PERMISSIONS.FOLLOW_UP.CREATE.TEMPORARY_CHANGE_REQUEST],
    },
    {
      icon: 'self-assessment-button-icon',
      label: 'LANDING_PAGE.DOCUMENTS.BUTTONS.SELF_ASSESSMENT',
      action: async () => await this.openSelfAssessmentDialog(),
      permissions: [PERMISSIONS.FOLLOW_UP.CREATE.WORKABILITY_SELF_ASSESSMENT],
    },
    {
      icon: 'fml',
      label: 'LANDING_PAGE.DOCUMENTS.BUTTONS.FML',
      action: async () => await this.openFMLDialog(),
      permissions: [],
    },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly media: MediaMatcher,
    private dialogHelperService: DialogHelperService,
    private readonly iconsHelperService: IconsHelperService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly workspaceService: WorkspaceService,
    private readonly appHeaderService: AppHeaderService,
    private _bottomSheet: MatBottomSheet,
    private readonly commonService: CommonService,
    private readonly permissionService: PermissionService,
    private readonly securityService: SecurityService,
  ) {
    this.iconsHelperService.registerSvgIcons([
      'toggle-arrow',
      'menu-sign-out',
      'self-certification-button-icon',
      'report-work-issue-button-icon',
      'temporary-change-button-icon',
      'sick-leave-button-icon',
      'menu-new-action',
      'menu-new-action-mobile',
      'person',
      'menu-my-cases',
      'mobile_ms_add',
      'mobile_ms_person',
      'mobile_ms_cases',
      'mobile_ms_chat',
      'mobile_ms_arrow_right',
      'menu-my-tasks',
      'menu-home',
      'leadership-portal',
      'self-assessment-button-icon',
      'fml',
      'calendar',
      'grid_guides',
    ]);
    this.runResponsivenessActions();
    this.commonService.productionEnabled =
      this.securityService.getLocalStorageItem('full-mode');
    this.accessData = this.userService.getAuthTokenData();
    this.isLeader = this.accessData.user.isLeader;
    this.currentUserWorkspaceId = this.accessData.userWorkspaceId;
  }

  async ngOnInit(): Promise<void> {
    this.appHeaderService.getWorkspacePicture().subscribe((img) => {
      if (this.currentWorkspace) this.currentWorkspace.logo = img;
    });
    // await this.permissionService.initializePermission();

    this.setSidenavItems();
    this.watchRouteChanges();
    this.getCurrentUserInfo();
    this.document.body.classList.add(MenuStateBodyClass.CLOSED);
    this.workabilityStatusColor = this.getColor(CaseColor.GREEN);
    // this.workabilityCircleSliceStyle = this.prepareCircleSliceStyle(this.workabilityStatus);
    // if (!this.externalRolesArray.includes(this.currentUserRole)) {
    await this.getActiveWorkSpaces();
    // }
    // this.setActionMenus();
  }

  async getCurrentUserInfo(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser(false).toPromise();
    this.isLeader = this.currentUser.isLeader;
  }

  async getActiveWorkSpaces(): Promise<void> {
    const userWorkspaces = await this.workspaceService.fetchUserWorkspaces(
      'active',
    );
    this.currentWorkspace = userWorkspaces.find(
      (x) => x._id === this.currentUserWorkspaceId,
    );
    this.userWorkspaceList = userWorkspaces.filter(
      (x) => x._id !== this.currentUserWorkspaceId,
    );
  }

  private getColor(color: CaseColor): string {
    return {
      [CaseColor.GREEN]: COLOR_GREEN_1,
      [CaseColor.ORANGE]: COLOR_ORANGE_1,
      [CaseColor.RED]: COLOR_RED_1,
    }[color];
  }

  ngAfterContentInit(): void {
    this.setActionMenus();
  }

  private setActionMenus(): void {
    this.actionMenus = {
      [SidenavItemsActions.NEW_ACTION]: this.newActionMenu,
    };
  }

  private watchRouteChanges(): void {
    this.setActivatedRoute();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.setActivatedRoute();
      });
  }

  private setActivatedRoute(): void {
    this.activatedRoute = this.router.url.match(/^\/((\w|-)+)\/?/)?.[1];
  }

  private runResponsivenessActions(): void {
    this.mobileQuery = this.media.matchMedia(
      `(max-width: ${BREAKPOINT_SMALL_SCREEN}px)`,
    );
    this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  private setSidenavItems(): void {
    this.sidenavItems = SIDENAV_ITEMS;
    this.registerSidenavIcons();

    const sortedMenuForMobile = [...this.sidenavItems]
      .filter((x) => this.checkVisibility(x))
      .sort((a, b) => a.mobileIndex - b.mobileIndex);
    const bottomMenuCount = sortedMenuForMobile.length > 5 ? 4 : 5;
    this.mobileBottomMenu = sortedMenuForMobile.slice(0, bottomMenuCount);
    this.mobileAdditionalBottomMenu =
      sortedMenuForMobile.slice(bottomMenuCount);
  }

  private registerSidenavIcons(): void {
    this.sidenavItems.forEach((item) => {
      this.iconsHelperService.registerSvgIcons(item.icon);
    });
  }

  setIsShowedClassInMenus(): void {
    const menusClasses = [
      'app-sidenav__company-menu',
      'app-sidenav__new-action-menu',
    ];
    menusClasses.forEach((menuClass) => {
      setTimeout(() => {
        this.document
          .querySelector(`.${menuClass}`)
          ?.classList.add('is-showed');
      }, 200);
    });
  }

  onSidenavItemClick(item: SidenavItem): void {
    if (item.action) {
      ({
        [SidenavItemsActions.NEW_ACTION]: () => {},
      })[item.action]();
      //  TODO : Remove RouteSegments.UNKNOWN when all links will have its components
    } else if (item.link !== RouteSegments.UNKNOWN) {
      this.router.navigate([item.link]).then(() => {
        if (this.mobileQuery.matches && this.isOpened) {
          this.matSidenav.toggle(false);
        }
      });
    }
  }

  onToggleClick(): void {
    this.isOpened
      ? (this.closingInProgress = true)
      : (this.openingInProgress = true);
    this.animationInProgress = true;
    setTimeout(() => {
      this.closingInProgress = this.openingInProgress = false;
      setTimeout(() => {
        this.animationInProgress = false;
      }, 300);
    }, 450);
  }

  handleToggle(opened: boolean): void {
    this.isOpened = opened;
    if (opened) {
      this.document.body.classList.add(MenuStateBodyClass.OPENED);
      this.document.body.classList.remove(MenuStateBodyClass.CLOSED);
      this.document
        .getElementById(`sidemenu-arrow`)
        ?.classList.add('menu-closed');
    } else {
      this.document.body.classList.add(MenuStateBodyClass.CLOSED);
      this.document.body.classList.remove(MenuStateBodyClass.OPENED);
      this.document
        .getElementById(`sidemenu-arrow`)
        ?.classList.remove('menu-closed');
    }
  }

  async openSelfCertificationDialog(): Promise<void> {
    await this.appHeaderService.openSelfCertificationDialog(this.caseNumber);
  }

  async openReportIssueDialog(): Promise<void> {
    await this.appHeaderService.openReportIssueDialog();
  }

  async openTemporaryChangeRequestDialog(): Promise<void> {
    await this.appHeaderService.openTemporaryChangeRequestDialog(
      this.caseNumber,
    );
  }

  async openSickLeaveDialog(): Promise<void> {
    await this.appHeaderService.openSickLeaveDialog(this.caseNumber);
  }

  async openSelfAssessmentDialog(): Promise<void> {
    await this.appHeaderService.openSelfAssessmentDialog(this.caseNumber);
  }
  async openFMLDialog(): Promise<void> {
    await this.appHeaderService.openFMLDialog(this.caseNumber);
  }

  trackBy(index: number, item: SidenavItem): string {
    return item.link;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.onDestroy$.next();
  }

  checkVisibility(sidenavItem: SidenavItem): boolean {
    if (sidenavItem.devEnabled === false) return false;
    if (
      !this.commonService.productionEnabled &&
      sidenavItem.productionEnabled === false
    ) {
      return false;
    }

    return (
      sidenavItem.permissions.length === 0 ||
      this.permissionService.hasPermission(sidenavItem.permissions)
    );
  }

  switchWorkspace(workspaceId: string): void {
    this.userService.switchWorkspace(workspaceId, false);
  }

  openBottomMenu(): void {
    if (!this.bottomMenuOpened) {
      this.bottomMenuOpened = true;
      this._bottomSheet
        .open(BottomMenuComponent, {
          data: {
            sidenavItems: this.mobileAdditionalBottomMenu,
            actionMenus: this.actionMenus,
            caseNumber: this.caseNumber,
          },
          panelClass: 'bottom-mobile-menu',
          backdropClass: 'bottom-nav-space',
        })
        .afterDismissed()
        .subscribe((data) => {
          this.bottomMenuOpened = false;
        });
    } else {
      this.bottomMenuOpened = false;
      this._bottomSheet.dismiss();
    }
  }

  openBottomAdditionalMenu(): void {
    if (!this.bottomAdditionaMenuOpened) {
      this.bottomAdditionaMenuOpened = true;
      this._bottomSheet
        .open(BottomAdditionalMenuComponent, {
          data: {
            caseNumber: this.caseNumber,
          },
          panelClass: 'bottom-mobile-menu',
          backdropClass: 'bottom-nav-space',
        })
        .afterDismissed()
        .subscribe((data) => {
          this.bottomAdditionaMenuOpened = false;
        });
    } else {
      this.bottomAdditionaMenuOpened = false;
      this._bottomSheet.dismiss();
    }
  }

  onMobileNavItemClick(route): void {
    this.router.navigate([route]);
  }
  testFunc(): void {
    this.dialogHelperService
      .openDialog(
        DialogId.CHANGES_TO_TASK_CONFIRMATION,
        ChangesToTaskConfirmationComponent,
        [DialogClassName.WR_DIALOG_MEDIUM],
        {},
        { disableClose: true },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {});
  }
  testFunc1(): void {
    this.dialogHelperService
      .openDialog(
        DialogId.CHANGES_TO_TASK_CONFIRMATION,
        OnboardingComponent,
        [DialogClassName.WR_DIALOG_MEDIUM],
        {},
        { disableClose: true },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {});
  }
}

@Component({
  selector: 'wr-bottom-menu',
  templateUrl: 'bottom-menu.html',
})
export class BottomMenuComponent {
  isAdmin: boolean;
  isLeader: boolean;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomMenuComponent>,
    private userService: UserService,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      sidenavItems: SidenavItem[];
      existingPermissions: PermissionName[];
    },
    private router: Router,
  ) {
    const jwtData = this.userService.getAuthTokenData();
    this.isLeader = jwtData.user.isLeader;
    this.isAdmin = jwtData.isAdmin;
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  onSidenavItemClick(item: SidenavItem): void {
    this.router.navigate([item.link]).then((el) => {
      this._bottomSheetRef.dismiss();
    });
  }
}

@Component({
  selector: 'wr-bottom-additional-menu',
  templateUrl: 'bottom-additional-menu.html',
})
export class BottomAdditionalMenuComponent {
  constructor(
    private appHeaderService: AppHeaderService,
    private _bottomSheetRef: MatBottomSheetRef<BottomMenuComponent>,
    private readonly iconsHelperService: IconsHelperService,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      caseNumber: string;
    },
  ) {
    this.iconsHelperService.registerSvgIcons([
      'self-certification-button-icon',
      'report-work-issue-button-icon',
      'temporary-change-button-icon',
      'sick-leave-button-icon',
      'self-assessment-button-icon',
    ]);
  }

  async openSelfCertificationDialog(): Promise<void> {
    await this.appHeaderService.openSelfCertificationDialog(
      this.data.caseNumber,
    );
    this.dismiss();
  }

  async openReportIssueDialog(): Promise<void> {
    await this.appHeaderService.openReportIssueDialog();
    this.dismiss();
  }

  async openTemporaryChangeRequestDialog(): Promise<void> {
    await this.appHeaderService.openTemporaryChangeRequestDialog(
      this.data.caseNumber,
    );
    this.dismiss();
  }

  async openSickLeaveDialog(): Promise<void> {
    await this.appHeaderService.openSickLeaveDialog(this.data.caseNumber);
    this.dismiss();
  }

  async openSelfAssessmentDialog(): Promise<void> {
    await this.appHeaderService.openSelfAssessmentDialog(this.data.caseNumber);
    this.dismiss();
  }

  dismiss(): void {
    this._bottomSheetRef.dismiss();
  }
}
