import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

export const defaultDialogConfiguration: MatDialogConfig = {
  disableClose: false,
  autoFocus: false,
  hasBackdrop: true,
  closeOnNavigation: true,
};

export const OPEN_DIALOG_TIME = 200;
