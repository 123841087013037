import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectOption} from "@app/shared/common.model";

@Component({
  selector: 'wr-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() value: string | number;
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() autofocus: boolean;
  @Output() valueChange = new EventEmitter<string | number>();
  @Input() selectOptions: SelectOption<string | number>[];
  showCustomField =false;
  customLocationVal: string |number = '';
  ngOnInit(): void {
    const allOpts = this.selectOptions.map(opts => opts.value);
    if(this.value && !allOpts.includes(this.value)) {
      this.showCustomField = true;
      this.customLocationVal = this.value;
    }
  }

  isCustomChecked() {
    return this.showCustomField;
  }

  radioButtonChanged(reset = false): void {
    if (reset) {
      this.showCustomField = false;
      this.customLocationVal = '';
    }
    this.valueChange.emit(this.value);
  }

  inputChanged(val: string) {
    this.customLocationVal = val;
    this.valueChange.emit(val);
    this.showCustomField = true;
  }
}
