import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, Subscription} from "rxjs";
import {UntypedFormGroup} from "@angular/forms";
import {DocumentFormConfig} from "@app/modules/forms/models/document-templates.model";
import {DocumentsIdentifiers, DocumentStatus, DocumentSummaryData} from "@app/shared/documents.model";
import {DOCUMENTS_TYPE_LABELS} from "@app/shared/constants/documents.constants";
import {CasesService} from "@app/shared/cases/cases.service";
import {DocumentTemplatesService} from "@app/modules/forms/services/document-templates.service";
import {DocumentBuilderService} from "@app/shared/document-builder/document-builder.service";
import {DialogHelperService} from "@app/shared/dialog-helper/dialog-helper.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {CaseTypes} from "@app/shared/cases.model";
import {DocumentTemplateUniqueIdentifier} from "@app/modules/case-page/models/case-statuses.model";
import {switchMap, takeUntil} from "rxjs/operators";
import {DialogId} from "@app/shared/dialogs.model";

@Component({
  selector: 'wr-residual-work-capacity',
  templateUrl: './residual-work-capacity.component.html',
  styleUrls: ['./residual-work-capacity.component.scss']
})
export class ResidualWorkCapacityComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  formGroup: UntypedFormGroup;
   workabilityData: { [key: string]: any };
  // private summaryData: any;
  userId: number;
  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  workabilityIssueSummary: { [key: string]: DocumentSummaryData };
  documentTypeLabel = DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY];
    docTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;
  constructor(
      private readonly casesService: CasesService,
      private readonly documentTemplatesService: DocumentTemplatesService,
      private readonly documentBuilderService: DocumentBuilderService,
      private readonly dialogHelperService: DialogHelperService,
      @Inject(MAT_DIALOG_DATA)
      public data: {
        userId: string;
        caseId: string;
        caseType: CaseTypes;
        caseNumber: string;
        caseUser: string;
        caseEndDate: string;
        type: DocumentTemplateUniqueIdentifier;
      },
  ) {}

  ngOnInit(): void {
    this.getDocumentFormData();
  }

  submit(fields: UntypedFormGroup): void {
    const preparedData = this.documentBuilderService.prepareDataBeforeSubmit(fields);
    this.casesService
        .addDocumentToCase(this.data.caseId, {
          status: DocumentStatus.COMPLETED,
          docTemplateUniqueIdentifier: DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY,
          docTemplateVersionNumber: this.docVersion,
          ownerId: this.data.userId,
          fields: {
            ...preparedData,
            ...this.workabilityData,
          },
        })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.dialogHelperService.closeDialog(DialogId.RESIDUAL_WORK_CAPACITY, { dialogResult: true });
        });
  }

  private getDocumentFormData(): void {
    const a = {
      [CaseTypes.WORKABILITY]: DocumentsIdentifiers.WORKABILITY_UPDATE,
      [CaseTypes.EQUIPMENT]: DocumentsIdentifiers.EQUIPMENT,
      [CaseTypes.ISSUE]: DocumentsIdentifiers.ISSUE,
      [CaseTypes.SICK_LEAVE]: DocumentsIdentifiers.WORKABILITY_UPDATE,
      [CaseTypes.SELF_CERTIFICATION]: DocumentsIdentifiers.SELF_CERTIFICATION,
      [CaseTypes.TEMPORARY_CHANGE_REQUEST]: DocumentsIdentifiers.TEMPORARY_CHANGE_REQUEST,
    };

    this.documentTemplatesService
        .getDocumentFormConfig(a[this.data.caseType])
        .pipe(
            switchMap((workability) => {
              const { formGroup, summarySubscription } = this.documentBuilderService.buildDocumentFrom(workability);
              this.formGroup = formGroup;
              this.summarySubscription = summarySubscription;
              const toSendTaskType =
                  this.data.type && this.data.type === DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_1
                      ? DocumentsIdentifiers.DIALOGUE_MEETING_1
                      : DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY;
              return this.casesService.getCaseDocTemplate(this.data.caseId, toSendTaskType);
            }),
            takeUntil(this.onDestroy$),
        )
        .subscribe((value) => {
          this.workabilityData = value.additionalData;
          this.documentBuilderService.updateFormValue(this.formGroup, value.additionalData);
          this.getDocument$ = of(value.docTemplate);
          this.workabilityIssueSummary = (this.formGroup.get('summary') as UntypedFormGroup)?.getRawValue();
        });
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }

  selectedStep(pageNumber: number): void {
    if (pageNumber === 2) {
    }
  }

  setsumarryData(data): void {
    // this.summaryData = data;
  }
}

