import { Component, Inject, OnInit } from '@angular/core';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ITeamsListData } from '@app/shared/teams.model';
import { SetupService } from '@app/modules/setup/setup.service';
import {
  IInviteEmployeeFormConfig,
  IInviteEmployeeFormDataReturn, IInviteEmployeeFormSubmit,
} from '@app/modules/setup/setup.model';
import { DialogId } from '@app/shared/dialogs.model';
import { IUserList } from '@app/modules/shared/interfaces/user.interface';
import { RoleName } from '@app/modules/shared/constants/role.constants';

@Component({
  selector: 'wr-onboarding-team-add',
  templateUrl: './onboarding-team-add.component.html',
  styleUrls: ['./onboarding-team-add.component.scss'],
})
export class OnboardingTeamAddComponent implements OnInit {
  componentName = 'teams';

  hrList: IUserList[] = [];
  leaderList: IUserList[] = [];
  role;
  selectRole = false;
  subtitle: string;
  subtitle2 = '';
  constructor(
    private dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      teamData: ITeamsListData;
      inviteFormConfig: IInviteEmployeeFormConfig;
    },
    private setupService: SetupService,
  ) {}
  async ngOnInit(): Promise<void> {
    this.hrList = [];
    this.leaderList = [];
    if (this.data && this.data.teamData) {
      const teamData = this.data.teamData;
      this.role = teamData.roles;
      this.selectRole = teamData.roles.includes(RoleName.HR);
      this.leaderList = [
        {
          name: teamData.name,
          id: teamData._id,
        },
      ];
      await this.getHrList();
      this.subtitle = 'SETUP.ONBOARDING.INVITE.EMPLOYEE';
      this.subtitle2 = `${teamData.name} | ${teamData.userWorkspaceName}`;
    } else {
      await this.getUserLists();
      this.subtitle = 'SETUP.ONBOARDING.INVITE.EMPLOYEE';
    }
    this.hrList = [...this.hrList];
    this.leaderList = [...this.leaderList];
  }
  handleBack(): void {
    this.dialogHelperService.closeDialog(DialogId.ONBOARDING_TEAM_ADD, {
      dialogResult: true,
    });
  }

  handleInviteFormData(data: IInviteEmployeeFormDataReturn): void {
    if (data) {
      this.dialogHelperService.closeDialog(DialogId.ONBOARDING_TEAM_ADD, {
        dialogResult: data,
      });
    }
  }

  handleFormSubmitted(data: IInviteEmployeeFormSubmit): void {
    if (data) {
      this.dialogHelperService.closeDialog(DialogId.ONBOARDING_TEAM_ADD, {
        dialogResult: data,
      });
    }
  }
  async getHrList(): Promise<void> {
    const hrRoles = [RoleName.HR];
    const hrRes = await this.setupService.getDropdownTeams(hrRoles);
    this.hrList = hrRes.map((el) => ({ name: el.name, id: el._id }));
  }
  async getUserLists(): Promise<void> {
    const leaderRes = await this.setupService.getDropdownTeams();
    const hrRes = leaderRes.filter((x) => x.roles.includes(RoleName.HR));
    this.hrList = hrRes.map((el) => ({ name: el.name, id: el._id }));
    this.leaderList = leaderRes.map((el) => ({ name: el.name, id: el._id }));
  }
  closeDialog(): void {
    this.dialogHelperService.closeDialog(DialogId.ONBOARDING_TEAM_ADD, {
      dialogResult: false,
    });
  }
}
