<div>
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="fs-4 mb-0">Choose a template</h2>
    <span class="text-orange small fw-medium">View All</span>
  </div>
  <div class="choose-template d-flex gap-3 mt-2 mb-4">
    <mat-radio-button
      *ngFor="let template of templateCategoryList"
      (click)="routeTo(template.route)"
      class="mat-button mat-button-base mat-button-square medium text-center border-radius-4 "
      [class]="template.customClass ? template.customClass : 'mat-button-square-white'"
    >
      <mat-icon>{{ template.icon }}</mat-icon>
      <p>{{ template.name }}</p>
    </mat-radio-button>
  </div>
</div>
<div class="d-flex justify-content-between align-items-center mt-5 pt-4 mb-4">
  <h2 class="fs-4 mb-0">Template</h2>
  <div>
    <button class="mat-button-white text-dark" mat-flat-button>Test</button>
    <mat-form-field appearance="fill" bg="white" class="mx-2 search-input pb-0">
      <mat-select>
        <mat-option>Test</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="mat-button-white text-dark" mat-flat-button>
      <mat-icon>star</mat-icon>
      Test
    </button>
  </div>
</div>
<h5 class="text-primary">Roles & Tasks</h5>
<div class="row">
  <div class="col-sm-4 mb-4" *ngFor="let item of templateList">
    <div class="followUps-thumb-card">
      <div class="followUps-thumb">
        <div class="followUps-contain">
          <img
            src="../../../../../../../assets/images/temp/avatar.png"
            class="followUps-thumb-img"
          />
          <span class="followUps-like"><mat-icon>favorite</mat-icon></span>
          <div class="followUps-contain-detail">
            <span class="followUps-type">{{ item.name }}</span>
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
        <div class="followUps-avatar">
          <span class="avatar avatar-location">
            <figure [ngStyle]=""></figure>
            <figcaption>{{ item.figureCaption }}</figcaption>
          </span>
        </div>
      </div>
      <h2 class="mb-0 mt-3">{{ item.title }}</h2>
      <div class="d-flex gap-3">
        <span class="d-inline-flex align-items-center"
          ><mat-icon class="text-primary">task</mat-icon
          ><strong class="d-inline-block me-2 text-dark">{{
            item.used
          }}</strong>
          Used</span
        >
        <span class="d-inline-flex align-items-center"
          ><mat-icon class="text-warning">favorite</mat-icon
          ><strong class="d-inline-block me-2 text-dark">
            {{ item.favorite }}</strong
          >
          Favorite</span
        >
      </div>
    </div>
  </div>
  <div class="col-sm-4" *ngFor="let item of templateList">
    <div class="followUps-thumb-card">
      <div class="followUps-thumb">
        <div class="followUps-contain">
          <img
            src="../../../../../../../assets/images/temp/avatar.png"
            class="followUps-thumb-img"
          />
          <span class="followUps-like"><mat-icon>favorite</mat-icon></span>
          <div class="followUps-contain-detail">
            <span class="followUps-type">{{ item.name }}</span>
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
        <div class="followUps-avatar">
          <span class="avatar avatar-location">
            <figure [ngStyle]=""></figure>
            <figcaption>{{ item.figureCaption }}</figcaption>
          </span>
        </div>
      </div>
      <h2 class="mb-0 mt-3">{{ item.title }}</h2>
      <div class="d-flex gap-3">
        <span class="d-inline-flex align-items-center"
          ><mat-icon class="text-primary">task</mat-icon
          ><strong class="d-inline-block me-2 text-dark">{{
            item.used
          }}</strong>
          Used</span
        >
        <span class="d-inline-flex align-items-center"
          ><mat-icon class="text-warning">favorite</mat-icon
          ><strong class="d-inline-block me-2 text-dark">
            {{ item.favorite }}</strong
          >
          Favorite</span
        >
      </div>
    </div>
  </div>
</div>
