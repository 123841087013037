import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { ICountryListRes } from '@app/shared/common.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  INotificationResponse,
  StatusEnum,
} from '../../models/notifications.model';
import { ApiList } from '@app/app.constants';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { FeedbackComponent } from '@app/modules/feedback/feedback.component';
import { filter } from 'rxjs/operators';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import {
  NotificationColor,
  NotificationHorizontalPosition,
  NotificationPosition,
  NotificationShowTime,
} from '@app/core/services/notify/notify.model';
import { CustomSnackbarNotificationComponent } from '@app/core/snackbar/custom-snackbar-notification/custom-snackbar-notification.component';
import {
  ICompanyDropdownRes,
  IPaginationData,
  ISubscriptionHistoryRes,
  ISubscriptionPackageReq,
  ISubscriptionPackagesFilter,
  ISubscriptionPackagesRes,
  ISubscriptionPlanResponse,
} from '@app/shared/interfaces/ICompanyAccount';
import * as moment from 'moment/moment';
import { SnackbarComponent } from '@app/core/snackbar/snackbar.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  productionEnabled = false;
  notifications: INotificationResponse[] = [];
  notification$ = new BehaviorSubject<any>(null);
  snackbarRef: MatSnackBarRef<any>;
  // audio = new Audio('../../../../../assets/sounds/notification.wav'); // Replace with your sound file path

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) {}
  getDateDifference(data: any): string {
    if (data.startDate && data.endDate) {
      const startDate = moment(data.startDate);
      const endDate = moment(data.endDate);
      const days: number = endDate.diff(startDate, 'days') + 1;
      const daysData = this.getCustomDateFormat(days);
      const daysUnit = this.translateService.instant(daysData.unit);
      return `${daysData.count} ${daysUnit} (${startDate.format(
        'MMM DD',
      )} - ${endDate.format('MMM DD')})`;
    } else {
      return '.';
    }
  }
  /* tslint:disable:no-bitwise */
  getCustomDateFormat(days: number): { count: number; unit: string } {
    let count: number;
    let unit: string;

    if (days < 14) {
      count = days;
      unit = days === 1 ? 'TIME.DAY' : 'TIME.DAYS';
    } else if (days < 84) {
      count = Math.floor(days / 7);
      unit = count === 1 ? 'TIME.WEEK' : 'TIME.WEEKS';
    } else if (days >= 84 && days < 365) {
      count = Math.floor(days / 30);
      unit = count === 1 ? 'TIME.MONTH' : 'TIME.MONTHS';
    } else if (days >= 365) {
      count = Math.floor(days / 365);
      unit = count === 1 ? 'TIME.YEAR' : 'TIME.YEARS';
    } else {
      count = days;
      unit = 'TIME.DAYS';
    }

    return { count, unit };
  }

  getCountryList = (): Promise<ICountryListRes> => {
    return this.http
      .get<ICountryListRes>(
        `${environment.baseUrl}${ApiList.countries.countries}`,
      )
      .toPromise();
  };

  getSubscriptionPlanList = (
    mode: 'upgrade' | '' = '',
  ): Promise<ISubscriptionPlanResponse[]> => {
    return this.http
      .get<ISubscriptionPlanResponse[]>(
        `${environment.baseUrl}${ApiList.subscription.plans}`,
        { params: { mode } },
      )
      .toPromise();
  };
  getSubscriptionPackageList = (
    paginationData: IPaginationData,
    searchText: string,
    subscriptionFilter: ISubscriptionPackagesFilter,
  ): Promise<{ count: number; data: ISubscriptionPackagesRes[] }> => {
    let params = {
      limit: paginationData.limit.toString(),
      skip: paginationData.skip.toString(),
      sort: paginationData.sort,
      searchString: searchText ?? '',
    };
    params = { ...params, ...subscriptionFilter };
    const filterData = Object.entries(params)
      .filter(
        ([key, value]) =>
          key && +value !== -1 && value !== '' && value !== null,
      )
      .reduce((obj: { [key: string]: any }, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    return this.http
      .get<{ count: number; data: ISubscriptionPackagesRes[] }>(
        `${environment.baseUrl}${ApiList.subscription.plans}/all`,
        {
          params: filterData,
        },
      )
      .toPromise();
  };

  updateSubscriptionPackageStatus(
    id: string,
    status = false,
  ): Promise<{ message: string }> {
    return this.http
      .put<{ message: string }>(
        `${environment.baseUrl}${ApiList.subscription.plans}/${id}/${ApiList.subscription.status}`,
        {},
        {
          params: {
            status: status.toString(),
          },
        },
      )
      .toPromise();
  }

  addSubscription = (reqData: object): Promise<ISubscriptionPlanResponse[]> => {
    return this.http
      .post<ISubscriptionPlanResponse[]>(
        `${environment.baseUrl}${ApiList.subscription._}`,
        reqData,
      )
      .toPromise();
  };
  upgradeSubscription = (
    reqData: object,
  ): Promise<ISubscriptionPlanResponse[]> => {
    return this.http
      .put<ISubscriptionPlanResponse[]>(
        `${environment.baseUrl}${ApiList.subscription._}/${ApiList.subscription.upgrade}`,
        reqData,
      )
      .toPromise();
  };
  addSubscriptionPackage = (
    reqData: ISubscriptionPackageReq,
  ): Promise<{ message: string }> => {
    return this.http
      .post<{ message: string }>(
        `${environment.baseUrl}${ApiList.subscription.plans}`,
        reqData,
      )
      .toPromise();
  };

  getCompanyList = (): Promise<ICompanyDropdownRes[]> => {
    return this.http
      .get<ICompanyDropdownRes[]>(
        `${environment.baseUrl}${ApiList.setup.companiesDropdown}`,
      )
      .toPromise();
  };

  getSubscriptionHistory = (
    companyId?: string,
    isActive = false,
  ): Promise<ISubscriptionHistoryRes[]> => {
    // Create an object to store the parameters
    const params: { [key: string]: string } = { isActive: isActive.toString() };

    // Add companyId to params if it is not null or empty
    if (companyId && companyId.trim() !== '') {
      params.companyId = companyId.trim();
    }

    return this.http
      .get<ISubscriptionHistoryRes[]>(
        `${environment.baseUrl}${ApiList.subscription._}`,
        { params },
      )
      .toPromise();
  };

  cancelSubscription(id: string): Promise<{ message: string }> {
    return this.http
      .put<{ message: string }>(
        `${environment.baseUrl}${ApiList.subscription._}/${id}/${ApiList.subscription.cancel}/`,
        {},
      )
      .toPromise();
  }
  resumeSubscription(): Promise<{ message: string }> {
    return this.http
      .put<{ message: string }>(
        `${environment.baseUrl}${ApiList.subscription._}/${ApiList.subscription.resume}/`,
        {},
      )
      .toPromise();
  }
  getNotifications(): any[] {
    return this.notifications;
  }

  getNotificationCount(): number {
    return (
      this.notifications.filter((x) => x.status === StatusEnum.UNREAD)
        ?.length ?? 0
    );
  }

  addNotifications(notif: any[]): void {
    this.notifications = notif;
    this.notification$.next(this.notifications);
  }
  addNotification(notif: any): void {
    this.displayNotificationInSnackBar(notif);
    this.notifications.unshift(notif);
    this.notification$.next(this.notifications);
  }

  setNotificationAsRead(id: string = ''): void {
    if (id === '') {
      this.notifications.forEach((x) => (x.status = StatusEnum.READ));
    } else {
      this.notifications.find((x) => x._id === id).status = StatusEnum.READ;
    }
    this.notification$.next(this.notifications);
  }

  openFeedback(): void {
    this.dialogHelperService
      .openDialog(
        DialogId.FEEDBACK,
        FeedbackComponent,
        [DialogClassName.WR_DIALOG_MAX_WIDTH_900],
        null,
        { disableClose: false },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((dialogResult) => {
        if (dialogResult) {
          // this.getTeams();
        }
      });
  }

  feedbackFormSubmit(
    data: {
      type: string;
      message: string;
      locationUrl: string;
      category: string;
      subject: string;
    },
    images: File[] | null,
  ): Observable<any> {
    const formData: FormData = new FormData();
    for (const image of images) {
      formData.append('files', image);
    }
    formData.append('subject', data.subject);
    formData.append('category', data.category);
    formData.append('type', data.type);
    formData.append('message', data.message);
    formData.append('locationUrl', data.locationUrl);

    return this.http.post<any>(
      `${environment.baseUrl}${ApiList.contact._}`,
      formData,
    );
  }

  contactFormSubmit(data: {
    category: string;
    message: string;
    type: number;
  }): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrl}${ApiList.contactForm._}`,
      data,
    );
  }

  displayNotificationInSnackBar(notification: INotificationResponse): void {
    // this.audio.play();
    this.snackbarRef = this.snackBar.openFromComponent(
      CustomSnackbarNotificationComponent,
      {
        duration: NotificationShowTime.LONG,
        panelClass: ['snack-bar-push-notification', 'bg-white'],
        data: notification,
        horizontalPosition: NotificationHorizontalPosition.LEFT,
        verticalPosition: NotificationPosition.BOTTOM,
      },
    );
  }

  displayMessageOnSnackBar(message: string): void {
    // this.audio.play();
    this.snackbarRef = this.snackBar.openFromComponent(SnackbarComponent, {
      duration: NotificationShowTime.MEDIUM,
      data: { message: this.translateService.instant(message) },
      horizontalPosition: NotificationHorizontalPosition.CENTER,
      verticalPosition: NotificationPosition.TOP,
      panelClass: NotificationColor.SUCCESS,
    });
  }

  closeSnackbar(): void {
    if (this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
  }
}
