<div
  class="document-summary-header__container"
  *ngIf="caseNumber || employee || documentTypeLabel"
></div>
<div class="document-summary-cards__container">
  <ng-container [ngSwitch]="reportType">
    <ng-container *ngSwitchCase="docTemplateUniqueIdentifier.SELF_ASSESSMENT">
      <wr-residual-work-capacity-summary
        [formGroup]="formGroup"
        [groupedStepsSummaries]="groupedStepsSummaries"
        [caseDocumentData]="caseDocumentData"
        [steps]="steps"
        class="col-12"
      ></wr-residual-work-capacity-summary>
    </ng-container>
    <ng-container *ngSwitchCase="docTemplateUniqueIdentifier.BASIC_FOLLOW_UP">
      <wr-scheduler-summary
        [schedulerData]="documentSummaryData"
        class="col-12"
      ></wr-scheduler-summary>
    </ng-container>
    <ng-container *ngSwitchCase="docTemplateUniqueIdentifier.FOLLOW_UP_PLAN">
      <wr-scheduler-main-summary
        [caseDocumentData]="caseDocumentData"
        [caseId]="caseId"
        class="col-12"
      ></wr-scheduler-main-summary>
    </ng-container>
    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT"
    >
      <wr-scheduler-main-summary
        [caseDocumentData]="caseDocumentData"
        [caseId]="caseId"
        class="col-12"
      ></wr-scheduler-main-summary>
    </ng-container>
    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL"
    >
      <wr-follow-up-scheduled-event-summary
        [schedulerData]="caseDocumentData"
        class="col-12"
      ></wr-follow-up-scheduled-event-summary>
    </ng-container>
    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE"
    >
      <wr-follow-up-scheduled-event-summary
        [schedulerData]="caseDocumentData"
        class="col-12"
      ></wr-follow-up-scheduled-event-summary>
    </ng-container>
    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_QUESTIONS"
    >
      <wr-questionaire-summary-mode
        [caseDocumentData]="caseDocumentData"
        class="col-12"
      ></wr-questionaire-summary-mode>
    </ng-container>
    <ng-container
      *ngSwitchCase="
        docTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW
      "
    >
      <wr-questionaire-summary-mode
        [caseDocumentData]="caseDocumentData"
        class="col-12"
      ></wr-questionaire-summary-mode>
    </ng-container>
    <ng-container
      *ngSwitchCase="
        docTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_REVIEW
      "
    >
      <wr-questionaire-summary-mode
        [caseDocumentData]="caseDocumentData"
        class="col-12"
      ></wr-questionaire-summary-mode>
    </ng-container>
    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING"
    >
      <wr-questionaire-summary-mode
        [caseDocumentData]="caseDocumentData"
        class="col-12"
      ></wr-questionaire-summary-mode>
    </ng-container>
    <ng-container *ngSwitchCase="docTemplateUniqueIdentifier.FACILITATION">
      <wr-facilitation-summary
        [formGroup]="formGroup"
        [groupedStepsSummaries]="groupedStepsSummaries"
        [caseDocumentData]="caseDocumentData"
        [steps]="steps"
        class="col-12"
      ></wr-facilitation-summary>
    </ng-container>
    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST"
    >
      <wr-facilitation-summary
        [formGroup]="formGroup"
        [groupedStepsSummaries]="groupedStepsSummaries"
        [caseDocumentData]="caseDocumentData"
        [steps]="steps"
        class="col-12"
      ></wr-facilitation-summary>
    </ng-container>

    <ng-container
      *ngSwitchCase="docTemplateUniqueIdentifier.FACILITATION_AGREEMENT"
    >
      <wr-facilitation-summary
        [formGroup]="formGroup"
        [groupedStepsSummaries]="groupedStepsSummaries"
        [caseDocumentData]="caseDocumentData"
        [steps]="steps"
        class="col-12"
      ></wr-facilitation-summary>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div
        class="document-summary-card__container"
        *ngFor="
          let summaryControls of groupedStepsSummaries;
          let i = index;
          trackBy: trackBy
        "
      >
        <wr-document-summary-card
          [summaryControls]="summaryControls"
          [steps]="steps"
        ></wr-document-summary-card>
      </div>
    </ng-container>
  </ng-container>
</div>
