import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { DocumentUpdateFieldsData } from '@app/modules/forms/models/document-templates.model';
import {ApiList} from "@app/app.constants";

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private readonly http: HttpClient) {}

  updateDocument(documentId: string, fields: DocumentUpdateFieldsData): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}${ApiList.documents._}${documentId}`, fields);
  }

  setDocumentAsCompleted(documentId: string, message?: string): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.documents._}${documentId}/${ApiList.documents._completed}`,
      message ? message : null,
    );
  }
  postCommentInFeed(caseId: string, uniqueIdentifier: string, message?: string): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/comment/${uniqueIdentifier}`,
      { comment: message },
    );
  }
}
