<form [formGroup]="group">
  <div class="modal-body-inner">
    <div class="px-sm-3">
      <div class="row">
        <!-- <h2 class="text-center text-dark mb-1">Schedule a follow-up</h2>
      <p class="text-center">Please set the duration and frequency of the following up meeting</p> -->
        <div [ngClass]="{ 'col-md-6': 'showNextFollowupDiv' }">
          <div>
            <ng-container *ngIf="!disableRepeat">
              <p class="text-black">
                {{ 'SCHEDULER.TEXT.HOW_OFTEN_TO_FOLLOW_UP' | translate }}
              </p>
              <div class="d-flex gap-sm-2 mb-3">
                <mat-radio-group
                  formControlName="schedulerRepeat"
                  (change)="schedulerSetToRepeat($event)"
                  aria-label="Select an option"
                >
                  <mat-radio-button
                    class="schedule-radio-custom me-2"
                    [value]="false"
                    >{{ 'SCHEDULER.LABEL.ONCE' | translate }}</mat-radio-button
                  >
                  <mat-radio-button
                    class="schedule-radio-custom"
                    [value]="true"
                  >
                    {{ 'SCHEDULER.LABEL.REPEAT' | translate }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </ng-container>
            <ng-template [ngIf]="group.controls['schedulerRepeat'].value">
              <div class="d-flex flex-column flex-sm-row gap-sm-3">
                <div class="form-inline-label">Every</div>
                <mat-form-field class="mat-form-width-xs" appearance="fill">
                  <input
                    [wrAutoFocus]
                    type="number"
                    formControlName="schedulerRepeatValue"
                    autocomplete="off"
                    matInput
                    [placeholder]="
                      'SCHEDULER.PLACEHOLDER.ENTER_DAYS' | translate
                    "
                    min="1"
                  />
                </mat-form-field>
                <mat-form-field class="width-auto" appearance="fill">
                  <mat-select
                    formControlName="schedulerRepeatFrequency"
                    [placeholder]="'' | translate"
                  >
                    <mat-option
                      *ngFor="let item of repeatList"
                      [value]="item"
                      >{{ 'TIME.' + item | uppercase | translate }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <hr class="mt-3 mb-2" />
              <p class="text-black">
                {{ 'SCHEDULER.TEXT.HOW_LONG_TO_FOLLOW_UP' | translate }}
              </p>
              <div class="d-sm-flex flex-wrap gap-2">
                <mat-form-field
                  class="mat-form-width-xs w-sm-100"
                  appearance="fill"
                >
                  <input
                    type="number"
                    formControlName="schedulerDurationValue"
                    autocomplete="off"
                    matInput
                    [placeholder]="
                      'SCHEDULER.PLACEHOLDER.ENTER_DAYS' | translate
                    "
                    min="1"
                  />
                </mat-form-field>
                <mat-form-field class="width-auto w-sm-100" appearance="fill">
                  <mat-select
                    formControlName="schedulerDurationFrequency"
                    [placeholder]="'' | translate"
                  >
                    <mat-option
                      *ngFor="let item of repeatList"
                      [value]="item"
                      >{{ 'TIME.' + item | uppercase | translate }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-template>
            <hr class="mt-3 mb-2" />
            <p class="text-black">
              {{ 'SCHEDULER.TEXT.SCHEDULE_FIRST_EVENT' | translate }}
            </p>
            <div class="d-sm-flex flex-wrap gap-2">
              <mat-form-field
                class="mat-date-custom w-sm-100"
                appearance="fill"
              >
                <input
                  matInput
                  [matDatepicker]="datepicker"
                  formControlName="schedulerPrefferedDate"
                  placeholder="Enter date"
                  [min]="minDate"
                  (focus)="datepicker.open()"
                />
                <mat-datepicker-toggle matPrefix [for]="datepicker">
                  <mat-icon matDatepickerToggleIcon class="mx-2" color="primary"
                    >calendar_month</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>

              <!--              <wr-time-picker-->
              <!--                [selectedTime]="group.controls['schedulerPrefferedTime'].value"-->
              <!--                (selectedTimeChange)="onTimeSelectionChange($event)"-->
              <!--              >-->
              <!--              </wr-time-picker>-->
              <wr-simple-timepicker
                [formControl]="group.get('schedulerPrefferedTime')"
                [minutesInStep]="15"
                class="week__day__time-from"
                [showIcon]="true"
              ></wr-simple-timepicker>
            </div>
            <hr class="mt-3 mb-2" />
            <p class="text-black">
              {{ 'SCHEDULER.TEXT.HOW_WOULD_YOU_LIKE_TO_FOLLOW_UP' | translate }}
            </p>
            <div class="d-flex gap-2 mb-3">
              <mat-radio-group
                formControlName="schedulerFollowupType"
                aria-label="Select an option"
                (change)="schedulerFollowupTypeChange($event)"
              >
                <mat-radio-button
                  class="schedule-radio-custom me-2"
                  [value]="'call'"
                  >{{ 'SCHEDULER.LABEL.CALL' | translate }}</mat-radio-button
                >
                <mat-radio-button
                  class="schedule-radio-custom"
                  [value]="'message'"
                  >{{ 'SCHEDULER.LABEL.MESSAGE' | translate }}</mat-radio-button
                >
              </mat-radio-group>
            </div>
            <div class="d-flex gap-2">
              <mat-form-field
                class="w-100 scheduler-message"
                floatLabel="never"
                appearance="fill"
                *ngIf="
                  group.controls['schedulerFollowupType'].value == 'message'
                "
              >
                <textarea
                  [wrAutoFocus]
                  formControlName="schedulerFollowupMessage"
                  autocomplete="off"
                  matInput
                  [placeholder]="
                    'SCHEDULER.PLACEHOLDER.DEFAULT_MESSAGE' | translate
                  "
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="3"
                  rows="3"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-6" *ngIf="showNextFollowupDiv">
          <div class="bg-primary-light p-4 rounded">
            <h2 class="text-orange mb-2">
              {{ 'SCHEDULER.TEXT.NEXT_FOLLOW_UP' | translate }}
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4 class="fw-bold mb-1">
                  {{
                    group.controls['schedulerPrefferedDate'].value | customDate
                  }}
                </h4>
                <p>
                  Time: {{ group.controls['schedulerPrefferedTime'].value }}
                </p>
              </div>
              <div>
                <span class="rounded-pill px-3 py-1 bg-primary text-white">{{
                  group.controls['schedulerFollowupType'].value
                }}</span>
              </div>
            </div>
          </div>

          <mat-card
            class="mt-4 mat-card-list"
            *ngIf="
              this.group.value.schedulerRepeat &&
              this.group.value.schedulerRepeatValue &&
              this.group.value.schedulerRepeatFrequency
            "
          >
            <mat-card-header>
              <mat-card-title>{{
                'SCHEDULER.TEXT.UPCOMING_FOLLOW_UPS' | translate
              }}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="upcoming-list">
              <mat-list role="list">
                <mat-list-item
                  role="listitem"
                  *ngFor="let followupDate of getUpcommingFollowupDates"
                >
                  <div
                    class="d-flex justify-content-between align-items-center py-2 w-100"
                  >
                    <span class="upcoming-list-time"
                      ><mat-icon>calendar_today</mat-icon>
                      {{ followupDate.date | customDate: 'MMM dd, YYYY' }}</span
                    >
                    <span class="upcoming-list-time ps-4"
                      ><mat-icon>schedule</mat-icon
                      >{{ followupDate.time }}</span
                    >
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</form>
