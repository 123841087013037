<wr-generic-dialog
  [showCloseIcon]="true"
  [showHeaderTitle]="true"
  [employee]="data.caseUser"
  [documentTypeLabel]="documentTypeLabel"
  [caseType]="data.caseType"
  [showBreadcrumb]="true"
>
  <!-- <ng-container title>
        {{'Follow up'}}
    </ng-container>
    <ng-container subTitle>
        {{'Meeting'}}
    </ng-container> -->
  <ng-container content>
    <div class="bg-primary-light p-4 mb-4 rounded" style="min-width: 650px">
      <h2 class="text-orange text-center mb-2 lh-1">Reminder</h2>
      <div class="d-flex justify-content-center align-items-center">
        <div class="text-center">
          <h5 class="mb-3">
            Followup via :
            <span class="fw-bold">Meeting</span>
          </h5>
          <p class="mb-0 text-primary">{{ data?.caseUser }}</p>
          <div class="d-flex gap-3">
            <p class="d-flex mb-0">
              <mat-icon class="opacity-50">event</mat-icon>
              {{ data?.deadline | customDate }}
            </p>
            <p class="d-flex mb-0">
              <mat-icon class="opacity-50">schedule</mat-icon>
              {{ data?.deadline | customDate: 'HH:MM' }}
            </p>
          </div>
          <h5 class="mb-3">
            Location :
            <span
              class="fw-bold"
              [innerHTML]="urlify(summary?.location)"
            ></span>
          </h5>
        </div>
      </div>
    </div>

    <div>
      <div class="scheduler-summary mb-4">
        <div class="modal-body-inner d-flex">
          <div class="w-100 m-auto py-4 text-left">
            <wr-document-summary
              [documentSummaryData]=""
              [caseDocumentData]="caseDocumentData"
              [documentTypeLabel]="documentTypeLabel | translate"
              [caseNumber]="data.caseNumber"
              [employee]="data.caseUser"
              [userId]="data.userId"
              [reportType]="data.type"
              [caseId]="data.caseId"
              [formGroup]=""
              [steps]=""
              class="mx-n3"
            >
            </wr-document-summary>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="mt-0">
      <button (click)="templateFill()" wrButton>
        {{ 'BUTTON.FILL_QUESTIONNAIRE' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
