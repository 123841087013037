import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { DocumentsService } from '@app/shared/documents/documents.service';
import { DocumentSummaryData } from '@app/shared/documents.model';
import {
  AGREEMENT_DOCUMENT_TEMPLATE,
  AGREEMENT_TITLES,
  AGREEMENT_TYPE_LABELS,
} from '@app/shared/constants/documents.constants';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentBuilderService } from '@app/shared/document-builder/document-builder.service';
import { ResponsivenessService } from '@app/core/services/responsiveness/responsiveness.service';
import { BREAKPOINT_MEDIUM_SCREEN } from '@app/shared/constants/breakpoints.constants';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { CaseDocument } from '@app/modules/shared/models/cases.model';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';
import { LoadingService } from '@app/core/services/loading/loading.service';
import { DocumentEditComponent } from '../../document-edit/document-edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wr-document-agreement',
  templateUrl: './document-agreement.component.html',
  styleUrls: ['./document-agreement.component.scss'],
})
export class DocumentAgreementComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  formGroup: UntypedFormGroup;
  caseDocumentData: CaseDocument;

  summary: { [key: string]: DocumentSummaryData };
  documentTypeLabel: string;
  dialogTitle: string;
  mediumScreenBreakpoint = BREAKPOINT_MEDIUM_SCREEN;

  commentField = new UntypedFormControl('DEDEDE');
  displayComment = false;
  agreementAccepted = false;
  firstClick = false;
  steps: DocumentFormConfigStep[];
  constructor(
    private readonly documentsService: DocumentsService,
    private readonly translateService: TranslateService,
    private readonly casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    public readonly responsivenessService: ResponsivenessService,
    private readonly dialogHelperService: DialogHelperService,
    private elementRef: ElementRef,
    public loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      caseId: string;
      caseNumber: string;
      caseUser: string;
      caseType: string;
      userId: string;
      type: DocumentTemplateUniqueIdentifier;
      isOwner: boolean;
      isDraft: boolean;
      dialogId: DialogId;
    },
  ) {}

  ngOnInit(): void {
    this.setTexts();
    this.getDocumentFormData();
  }

  scrollToBottom(): void {
    try {
      const scrollContainer = this.elementRef.nativeElement.querySelector(
        '.mat-dialog-content',
      );
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: 'smooth',
      });
    } catch (err) {}
  }

  preSubmit(hasAccepted = false): void {
    this.displayComment = true;
    let prefilledComment = '';
    if (
      this.data.type ===
      DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT
    ) {
      prefilledComment = this.translateService.instant(
        'ACCEPTANCE_STEP.PREFILLED_COMMENT.THANKS',
      );
    }
    if (
      this.data.type ===
      DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT
    ) {
      prefilledComment = this.translateService.instant(
        'ACCEPTANCE_STEP.PREFILLED_COMMENT.SORRY',
      );
    }
    setTimeout(() => {
      this.commentField.setValue(prefilledComment);
      this.scrollToBottom();
    }, 100);

    this.firstClick = true;
    this.agreementAccepted = hasAccepted;
  }
  cancelComment(): void {
    this.displayComment = false;
    this.firstClick = false;
    this.agreementAccepted = false;
  }

  async commentSubmit(): Promise<void> {
    await this.onPostComment();
    if (this.agreementAccepted) {
      await this.onSubmit();
    }
    this.onClose(true);
  }
  onEdit(): void {
    this.dialogHelperService.closeDialog(DialogId.SUMMARY_CARD_DETAILS, {
      dialogResult: true,
    });
    this.dialogHelperService.openDialog(
      DialogId.EDIT_DOCUMENT,
      DocumentEditComponent,
      DialogClassName.WR_DIALOG_AUTO_SIZE,
      {
        caseId: this.data.caseId,
        caseType: this.data.caseType,
        documentId: this.data.documentId,
        userId: this.data.userId,
        type: this.data.type,
        caseUser: this.data.caseUser,
      },
      { disableClose: true },
    );
  }
  async onSubmit(): Promise<void> {
    return await this.documentsService
      .setDocumentAsCompleted(this.data.documentId, this.commentField.value)
      .toPromise();
  }
  onPostComment(): Promise<void> {
    if (this.commentField.value && this.commentField.value.trim() !== '') {
      return this.documentsService
        .postCommentInFeed(
          this.data.caseId,
          AGREEMENT_DOCUMENT_TEMPLATE[this.data.type],
          this.commentField.value,
        )
        .toPromise();
    }
  }

  onClose(dialogResult = false): void {
    this.dialogHelperService.closeDialog(DialogId.SUMMARY_CARD_DETAILS, {
      dialogResult,
    });
  }

  private setTexts(): void {
    this.documentTypeLabel = AGREEMENT_TYPE_LABELS[this.data.type];
    this.dialogTitle = AGREEMENT_TITLES[this.data.type];
  }

  private getDocumentFormData(): void {
    this.documentTemplatesService
      .getDocumentFormConfig(AGREEMENT_DOCUMENT_TEMPLATE[this.data.type])
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup = formGroup;
          this.steps = workability?.versions[0]?.template?.steps;

          this.summarySubscription = summarySubscription;
          return this.casesService.getCaseDocument(
            this.data.caseId,
            this.data.documentId,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup,
          document.fields,
        );
        this.summary = (
          this.formGroup.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData = document;
        if (
          this.data.type === DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP &&
          this.formGroup.value.step0.control1
        ) {
          this.summary = this.formGroup.value.step0.control1;
        }
      });
  }
  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }
}
