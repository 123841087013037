import {Component, EventEmitter, Input, Output} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { SelectOption } from '@app/shared/common.model';
// import {MultiButtonSelectService} from "@app/shared/components/documents/form-controls/multi-button-select/multi-button-select-service/multi-button-select.service";

@Component({
  selector: 'wr-multi-button-select',
  templateUrl: './multi-button-select.component.html',
  styleUrls: ['./multi-button-select.component.scss'],
})
export class MultiButtonSelectComponent {
  @Input() formArray: UntypedFormArray;
  @Input() selectOptions: SelectOption<string>[];
  @Input() description: string;
  @Output() valueChange = new EventEmitter<any>();

  value: string[];
  currentArrayValue: string;
  icons = {
    JOB_ROLE: 'person',
    TASKS: 'work',
    LOCATION: 'location_on',
    WORKING_HOURS: 'chronic',
    EQUIPMENT_RESOURCES_SKILLS: 'engineering',
    TASKS_SPECIALISMS: 'work'
  };
  constructor(private readonly fb: UntypedFormBuilder) {

   // const intervalId = setTimeout(() => {
   //   this.valueChange.emit(true)
   //   clearInterval(intervalId);
   // }, 500)
  }
  // private data: MultiButtonSelectService
  // tslint:disable-next-line:use-lifecycle-interface
  // ngOnInit(): void {
  //   this.data.currentValue.subscribe(value => this.value = value)
  // }

  onCheckboxChange(checked: boolean, value: string): void {
    const arrayValue: string[] = this.formArray.getRawValue();
    this.value = arrayValue;

    const valueInArrayIndex = arrayValue.findIndex((val) => val === value);
    if (valueInArrayIndex > -1 && !checked) {
      this.formArray.removeAt(valueInArrayIndex);
    } else if (valueInArrayIndex === -1 && checked) {
      this.formArray.push(this.fb.control(value));
    }
    this.valueChange.emit(true)
  }

  trackBy(index: number, option: SelectOption<string>): string {
    return option.value;
  }
}
