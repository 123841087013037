import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { map } from 'rxjs/operators';

import {
  CreateDocumentTemplateData,
  DocumentFormConfig,
  DocumentTemplate,
  DocumentTemplatesOptions,
  DocumentTemplateUpdateData,
  DocumentTemplateUpdateFieldsData,
} from '@app/modules/forms/models/document-templates.model';
import { GenericList } from '@app/shared/response.model';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { ApiList } from '@app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplatesService {
  constructor(private readonly http: HttpClient) {}

  createTemplate(
    data: CreateDocumentTemplateData,
  ): Observable<DocumentTemplate> {
    return this.http.post<DocumentTemplate>(
      `${environment.baseUrl}${ApiList.documentTemplates.templates}`,
      data,
    );
  }

  getTemplatesList(
    options?: DocumentTemplatesOptions,
  ): Observable<DocumentTemplate[]> {
    let params: HttpParams;
    if (options) {
      params = new HttpParams();
      Object.entries(options).forEach(
        ([key, value]) =>
          (params = params.append(key, encodeURIComponent(value))),
      );
    }
    return this.http
      .get<GenericList<DocumentTemplate>>(
        `${environment.baseUrl}${ApiList.documentTemplates.templates}`,
        params ? { params } : undefined,
      )
      .pipe(map((response) => response.data));
  }

  getTemplate(
    id: string,
    versionNumber?: number,
  ): Observable<DocumentTemplate> {
    return this.http.get<DocumentTemplate>(
      `${environment.baseUrl}${ApiList.documentTemplates.templates}/${id}${
        versionNumber ? `/${versionNumber}` : ''
      }`,
    );
  }

  updateTemplate(
    id: string,
    updatedData: DocumentTemplateUpdateData,
  ): Observable<DocumentTemplate> {
    return this.http.put<DocumentTemplate>(
      `${environment.baseUrl}${ApiList.documentTemplates.templates}/${id}`,
      updatedData,
    );
  }

  updateTemplateFields(
    id: string,
    updatedFields: DocumentTemplateUpdateFieldsData,
  ): Observable<DocumentTemplate> {
    return this.http.put<DocumentTemplate>(
      `${environment.baseUrl}${ApiList.documentTemplates.templates}${id}/${ApiList.documentTemplates._fields}`,
      updatedFields,
    );
  }

  getDocumentFormConfig(
    document: DocumentsIdentifiers,
  ): Observable<DocumentFormConfig> {
    return this.http.get<DocumentFormConfig>(
      `assets/data/flows/${document}.json`,
    );
    // let data;
    // this.getDocumentConfigData(document)
    //   .pipe()
    //   .subscribe({
    //     next: (masterDataResponse: DocumentFormConfig) => {
    //       data = masterDataResponse;
    //     },
    //     error: (error: any) => {},
    //   });
    // return data;
    // return this.http.get<DocumentFormConfig>(
    //   `${environment.baseUrl}${ApiList.documentTemplates.templates}${ApiList.documentTemplates._uniqueIdentifier}${document}`,
    // );
  }
  getDocumentConfigData(
    document: DocumentsIdentifiers,
  ): Observable<DocumentFormConfig> {
    return this.http.get<DocumentFormConfig>(
      `assets/data/flows/${document}.json`,
    );
  }
}
