import { Directive, ElementRef, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[wrScrollEnd]',
})
export class ScrollEndDirective implements AfterViewInit {
  constructor(private element: ElementRef) {}

  @Output() elementVisible = new EventEmitter<boolean>();
  @Input() isTargetElement: boolean;

  public intersectionOptions = {
    root: null, // implies the root is the document viewport
    rootMargin: '0px',
    threshold: [0, 0.5, 1],
  };

  ngAfterViewInit(): void {
    const observer = new IntersectionObserver(this.intersectionCallback.bind(this), this.intersectionOptions);
    if (this.isTargetElement) {
      observer.observe(this.element.nativeElement);
    }
  }

  intersectionCallback(entries, observer): void {
    entries.forEach((entry) => {
      if (entry.intersectionRatio === 1) {
        this.elementVisible.emit(true); // element is completely visible in the viewport
      } else {
        this.elementVisible.emit(false);
      }
    });
  }
}
