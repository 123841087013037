<wr-generic-dialog
  [showCloseIcon]="showCloseIcon"
  [showHeaderTitle]="showHeaderTitle"
  [employee]="employee"
  [documentTypeLabel]="documentTypeLabel"
  [caseType]="caseType"
  [showBreadcrumb]="showBreadcrumb"
  [class.form-disabled]="loadingService.isLoading"
>
  <ng-container title>
    {{
      title ||
        (firstLoad && !hasAdditionalStep && data
          ? data[0]?.title[selectedLanguage]
          : '')
    }}
  </ng-container>
  <ng-container subTitle>
    {{
      subtitle ||
        (firstLoad && !hasAdditionalStep && data
          ? data[0]?.description[selectedLanguage]
          : '')
    }}
  </ng-container>
  <ng-container content>
    <mat-horizontal-stepper
      #stepper
      [linear]="true"
      [class.view-initialized]="viewInitialized"
      [class.wr-summary-bg-light]="
        isCurrentPageSummary ||
        (hasAdditionalStep &&
          isAdditionalStepSummary &&
          stepper.selectedIndex === 0)
      "
    >
      <ng-container
        *ngFor="
          let template of templatesQueryList.toArray();
          let i = index;
          trackBy: trackBy
        "
      >
        <mat-step
          [style.visibility]="
            stepper.selectedIndex === i ? 'visible' : 'hidden'
          "
        >
          <ng-container *ngIf="viewInitialized && stepper.selectedIndex === i">
            <ng-container *ngTemplateOutlet="template"></ng-container>
          </ng-container>
        </mat-step>
      </ng-container>
    </mat-horizontal-stepper>
  </ng-container>
  <ng-container footer>
    <div class="footer__actions__container">
      <!-- <div class="footer__action__container"></div> -->
      <div class="footer__action__container">
        <button
          mat-stroked-button
          *ngIf="stepper.selectedIndex > 0"
          class="mat-flat-button matStepperPrevious mat-stroked-button me-2"
          (click)="stepper.previous()"
        >
          {{ 'BUTTON.BACK' | translate }}
        </button>
        <button
          wrButton
          *ngIf="allowSkip && stepper.selectedIndex === 0"
          [variant]="primary"
          [loading]="loadingService.isLoading"
          (click)="skipped.emit()"
        >
          {{ 'BUTTON.SKIP' | translate }}
        </button>
        <ng-container *ngIf="stepControls">
          <ng-container
            *ngIf="
              stepper.selectedIndex ===
                templatesQueryList.toArray().length - 1 && showSubmitButton
            "
          >
            <button
              wrButton
              [loading]="loadingService.isLoading"
              class="step-button step-submit"
              [disabled]="isSummaryDisabled"
              (click)="submitted.emit()"
            >
              {{ 'BUTTON.SUBMIT' | translate }}
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              stepper.selectedIndex < templatesQueryList.toArray().length - 1
            "
          >
            <button
              mat-button
              class="step-button step-forward"
              [disabled]="
                stepControls[stepControlIndex]
                  ? stepControls[stepControlIndex].invalid
                  : isNextDisabled
              "
              (click)="stepper.next(); selectedStep.emit(stepper.selectedIndex)"
            >
              {{ 'BUTTON.NEXT' | translate }}
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</wr-generic-dialog>
