<form [formGroup]="formArray.parent" class="max-w-75">
  <!-- <h3 *ngIf="description" class="textboxes-with-select-list__form-title">
    {{ description }}
  </h3> -->
  <ng-container *ngFor="let _ of fakeArray; let index = index">
    <div class="item__container">
      <div class="item__inputs">
        <mat-form-field class="select__form-field" floatLabel="never">
          <mat-label>{{'COMMON.SELECT' | translate}}</mat-label>
          <mat-select
            [formControl]="formArray.at(index)?.get(selectControlName)"
            *ngIf="selectOptions"
            placeHolder
          >
            <mat-option
              *ngFor="let option of selectOptions"
              [value]="option.value"
              >{{ option.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input__form-field" floatLabel="never">
          <input
            type="text"
            [formControl]="formArray.at(index)?.get(inputControlName)"
            [placeholder]="('PLACEHOLDER.ADD_DESCRIPTION' | translate)"
            matInput
            [wrAutoFocus]="true"
          />
        </mat-form-field>
      </div>
      <button
        type="button"
        *ngIf="index < fakeArray?.length - 1"
        (click)="removeItem(index)"
        mat-icon-button
        class="no-background text-danger fs-2"
        color="warn"
      >
        <mat-icon class="text-muted fs-4 mb-2">delete</mat-icon>
      </button>
      <button
        *ngIf="index == fakeArray?.length - 1"
        (click)="addNext()"
        [disabled]="this.formArray?.length >= this.maxArrayLength"
        mat-icon-button
        class="no-background fs-2"
      >
        <mat-icon class="fs-4 mb-2">add_circle</mat-icon>
      </button>
    </div>
  </ng-container>
  <!-- <button
    mat-button
    class="button__add-new-item"
    (click)="addNext()"
    [disabled]="this.formArray.length >= this.maxArrayLength"
  >
    {{ addNewItemText }}
  </button> -->
</form>
