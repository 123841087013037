<div class="d-flex justify-content-between align-items-center px-4 mb-3 active">
  <div class="d-flex align-items-center">
    <span class="avatar me-1">
      <figure [ngStyle]=""></figure>
      <figcaption>{{ (currentUser?.firstName + ' ' + currentUser.lastName) | nameInitials }}</figcaption>
    </span>
    <mat-card-header class="px-2">
      <mat-card-title class="text-dark text-start">{{ (currentUser?.firstName + ' ' + currentUser.lastName) }}</mat-card-title>
      <mat-card-subtitle class="mb-0 small text-muted d-flex align-items-center"
        >{{ 'Leader' }}
        <span class="mx-1" style="opacity: 0.2">|</span>
      </mat-card-subtitle>
    </mat-card-header>
  </div>
  <div class="text-muted d-flex align-items-center gap-3 text-end">
    <mat-label class="me-2">Required</mat-label>
    <mat-slide-toggle [(ngModel)]="content.required" class="align-middle"></mat-slide-toggle>
    <button mat-icon-button class="btn-option-change" (click)="replaceQuestion()">
      <mat-icon>published_with_changes</mat-icon>
    </button>
    <button mat-icon-button class="btn-option-delete" (click)="removeQuestion()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>

<mat-card-content class="">
  <p class="feed-text">
    <input
      class="feed-comment mat-input-element-custom"
      matInput
      placeholder="Enter the question you would like to review?"
      [(ngModel)]="content.text"
      type="text"
      #question
    />
  </p>
  <div class="feed-text template-option" *ngIf="contentTypesWithOptions.includes(content.type)">
    <span class="mb-2 d-inline-block">Options:</span>
    <div *ngFor="let contentVal of content.values; let i = index">
      <input
        class="feed-comment template-option-edit"
        matInput
        placeholder="Enter the option value"
        type="text"
        [value]="content.values[i]"
        (change)="content.values[i] = $event.target.value"
      />

      <ng-template [ngIf]="i < content.values.length - 1" [ngIfElse]="addOptionButton">
        <button mat-icon-button class="btn-option-close" (click)="removeOption(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </ng-template>
      <ng-template #addOptionButton>
        <button mat-icon-button class="btn-option-add" (click)="addOption(content)">
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    </div>
  </div>
</mat-card-content>
