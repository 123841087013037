import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ITemplateContent, QuestionTypeEnum } from '@app/modules/content-creator/content-creatorer.model';
import { User } from '@app/modules/shared/models/user.model';
import { UserService } from '@app/modules/shared/services/user/user.service';

@Component({
  selector: 'wr-edit-content',
  templateUrl: './edit-content.component.html',
  styleUrls: ['./edit-content.component.scss'],
})
export class EditContentComponent implements OnInit {
  @ViewChild('question') question: ElementRef<HTMLInputElement>;
  @Input() content: ITemplateContent;
  currentUser: User;
  contentTypes = QuestionTypeEnum;
  contentTypesWithOptions: QuestionTypeEnum[] = [QuestionTypeEnum.CHECKBOX, QuestionTypeEnum.RADIO_BTN];
  @Output() contentDeleted = new EventEmitter<boolean>();
  @Output() contentReplace = new EventEmitter<boolean>();

  constructor(private readonly userService: UserService) {

  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    // this.question.nativeElement.focus();

  }

  addOption(content: ITemplateContent): void {
    content.values.push('');
  }

  removeOption(index: number): void {
    this.content.values.splice(index, 1);
  }

  removeQuestion(): void {
    this.contentDeleted.emit(true);
  }

  replaceQuestion(): void {
    this.contentReplace.emit(true);
  }
}
