import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@app/shared/user/user.service';
import { RouteSegments } from '@app/shared/routing.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isUserLoggedIn = this.userService.isUserLoggedIn();
    if (!isUserLoggedIn) {
      this.router.navigate([RouteSegments.ENTRY]);
      return false;
    }

    const accessData = this.userService.getAuthTokenData();
    if (isUserLoggedIn && accessData) {
      return this.userService.handleMultiFactorAuthentication(accessData);
    }
  }
}
