import { Component } from '@angular/core';

@Component({
  selector: 'wr-builder-container',
  templateUrl: './builder-container.component.html',
  styleUrls: ['./builder-container.component.scss', '../builder.component.scss']
})
export class BuilderContainerComponent {
  showElementSelection = false

}
