<form [formGroup]="control.parent" class="horizontal-button-radio__form">
  <mat-radio-group
    [formControl]="control"
    (change)="valueChange.emit(true)"
    class="d-flex justify-content-center gap-3 mb-4"
  >
    <mat-radio-button
      *ngFor="let option of selectOptions"
      class="radio-custom"
      [value]="option.value"
    >
      <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon>
      {{ option.label | translate }}
    </mat-radio-button>
  </mat-radio-group>
</form>
