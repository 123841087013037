<ul [class]="linkActivated ? 'wa-breadcrumb' : 'wa-breadcrumb-dialog'">
  <li *ngIf="homeIcon" (click)="redirect(homeUrl)">
    <mat-icon>home</mat-icon>
  </li>
  <li
    *ngFor="let breadcrumb of breadcrumbList; let i = index"
    (click)="redirect(breadcrumb.route)"
  >
    <span [class]="i == breadcrumbList.length - 1 ? 'text-disabled' : ''">{{
      breadcrumb.title | translate
    }}</span>
  </li>
</ul>
