<form [formGroup]="textControl.parent">
  <!-- <h3 *ngIf="description" class="textarea__form-title">
    {{ description }}
  </h3> -->
  <p class="textarea__label mb-1" *ngIf="label">{{ label }}</p>
  <mat-form-field floatLabel="never">
    <textarea
      [formControl]="textControl"
      [cdkAutosizeMinRows]="rowsCount"
      [placeholder]="placeholder"
      matInput
      cdkTextareaAutosize
      [wrAutoFocus]="autofocus"
      [ngModel]="defaultValue"
    ></textarea>
  </mat-form-field>
</form>
