<form
  *ngIf="invitationForm"
  [formGroup]="invitationForm"
  class="form_container"
>
  <p *ngIf="title && title !== ''" class="mb-3 text--small fw-bold tt">
    {{ title | translate }}
  </p>
  <mat-checkbox
    class="mb-4"
    *ngIf="config.promptSaveAsAdmin"
    matInput
    color="primary"
    formControlName="saveAsAdmin"
    (change)="saveAsAdminChange($event)"
  >
    <p class="text--small">
      {{ 'SETUP.ORGANISATION.SET_ME_AS' | translate }}
      <b>{{ config.saveAsAdminLabel | translate }}</b>
    </p>
  </mat-checkbox>
  <br />
  <mat-form-field>
    <input
      [placeholder]="'ENTRY.COMMON.CARD.LABEL_FIRST_NAME' | translate"
      formControlName="firstName"
      matInput
      type="text"
      [readonly]="invitationForm.get('saveAsAdmin').value"
      [wrAutoFocus]
    />
    <mat-error *ngIf="invitationForm.get('firstName').hasError('required')">
      {{ 'SETUP.COMPANY.FIRST_NAME_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input
      [placeholder]="'ENTRY.COMMON.CARD.LABEL_LAST_NAME' | translate"
      formControlName="lastName"
      matInput
      type="text"
      [readonly]="invitationForm.get('saveAsAdmin').value"
    />
    <mat-error *ngIf="invitationForm.get('lastName').hasError('required')">
      {{ 'SETUP.COMPANY.LAST_NAME_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input
      [placeholder]="'ENTRY.COMMON.CARD.LABEL_EMAIL' | translate"
      formControlName="email"
      matInput
      type="email"
      [readonly]="invitationForm.get('saveAsAdmin').value"
    />
    <mat-error *ngIf="invitationForm.get('email').hasError('required')">
      {{ 'SETUP.COMPANY.EMAIL_IS_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="invitationForm.get('email').hasError('email')">
      {{ 'SETUP.COMPANY.EMAIL_IS_INVALID' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="selectRole">
    <mat-select
      formControlName="userType"
      [placeholder]="'KEY.USERTYPE' | translate"
      [(value)]="role"
      [disabled]="invitationForm.get('saveAsAdmin').value"
      multiple
    >
      <mat-option *ngFor="let role of filteredRoles" [value]="role.value">{{
        roleLabel[role.label] | translate
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="invitationForm.get('userType').hasError('required')">
      {{ 'COMMON.ERROR.USER_TYPE_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <wr-select-with-search
    *ngIf="leaderUserList"
    [control]="invitationForm.get('parentUserWorkspaceId')"
    [fieldName]="'LEADER'"
    [options]="filteredUsers"
    [placeholder]="'ENTRY.COMMON.CARD.LEADER' | translate"
    [readonly]="config.isLeaderRequired && leaderUserList.length == 1"
    [emptyValue]="'-'"
  ></wr-select-with-search>
  <wr-select-with-search
    *ngIf="hrLeaderUserList"
    [control]="invitationForm.get('hrContactId')"
    [fieldName]="'HR_CONTACT'"
    [options]="filteredHrUsers"
    [placeholder]="'ENTRY.COMMON.CARD.HR_CONTACT' | translate"
    [emptyValue]="'-'"
  ></wr-select-with-search>

  <div class="d-flex justify-content-end gap-3">
    <button
      type="button"
      (click)="back()"
      *ngIf="config.backButton"
      mat-button
      class="mat-stepper-previous"
    >
      {{ 'BUTTON.BACK' | translate }}
    </button>
    <button
      wrButton
      variant="primary"
      [loading]="loading"
      (click)="save()"
      [disabled]="
        invitationForm.invalid || invitationForm.value.email.length <= 0
      "
    >
      {{ buttonText | translate }}
    </button>
  </div>
</form>
