<div class="document-summary-header">
  <div class="document-summary-header__details" *ngIf="caseNumber">
    <span class="document-summary-header__details__label">Case number</span>
    <span class="document-summary-header__details__value">{{ caseNumber ? '#' + caseNumber : '-' }}</span>
  </div>
  <div class="document-summary-header__details">
    <span class="document-summary-header__details__label">Employee</span>
    <span class="document-summary-header__details__value">{{ employee || '-' }}</span>
  </div>
  <div class="document-summary-header__details">
    <span class="document-summary-header__details__label">Form type</span>
    <span class="document-summary-header__details__value">{{ documentTypeLabel || '-' }}</span>
  </div>
</div>
