import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@app/shared/common.model';
import { AccessType } from '@app/modules/case-page/models/access.model';
import { SweetAlertService } from '@app/core/services/alert/sweet-alert.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from '@app/shared/user/common.service';
import { NgxCaptureService } from 'ngx-capture';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'wr-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  feedbackForm: UntypedFormGroup;
  categoryList = [
    { value: '0', label: 'FEEDBACK.LABEL.BUG' },
    { value: '1', label: 'FEEDBACK.LABEL.IMPROVEMENT' },
  ];
  screenShotAdded = false;
  screenShotImages: File[] = [];
  screenShotImagesPreview = [];
  loading = false;
  screenShotMode = false;
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private sweetAlertService: SweetAlertService,
    private captureService: NgxCaptureService,
    readonly dialogRef: MatDialogRef<FeedbackComponent>,
  ) {}

  ngOnInit(): void {
    this.buildFeedbackForm();
  }

  buildFeedbackForm(): void {
    const currentUrl = window.location.href;
    this.feedbackForm = this.fb.group({
      category: ['', [Validators.required]],
      locationUrl: [currentUrl, [Validators.required]],
      subject: ['', [Validators.required, Validators.minLength(5)]],
      message: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  trackBy(index: number, option: SelectOption<AccessType>): string {
    return option.value;
  }

  fullScreenShot(): void {
    this.screenShotMode = true;
    const documentForSc = document.body;
    documentForSc
      .getElementsByClassName('cdk-overlay-container')[0]
      ?.setAttribute('data-html2canvas-ignore', 'true');
    setTimeout(() => {
      this.captureService
        .getImage(documentForSc, true, { useCORS: true })
        .pipe(
          tap((img) => {
            if (img && img.length > 0) {
              this.onScreenshot(img);
              this.screenShotMode = false;
            }
          }),
        )
        .subscribe();
    }, 400);
  }

  onScreenshot(image: string): void {
    if (image) {
      const imageBlob = this.DataURIToBlob(image);
      const imageFile = new File(
        [imageBlob],
        'Screenshot-' + Date.now().toString() + '.jpg',
        { type: 'image/jpg' },
      );
      this.screenShotImages.push(imageFile);
      this.addImagePreview(imageFile);
      this.screenShotAdded = true;
    }
  }

  onImageSelected(event: any): void {
    for (const file of event.target.files) {
      if (this.screenShotImages.length < 5) {
        this.screenShotImages.push(file);
        this.addImagePreview(file);
      }
    }
  }

  onImageUnSelect(index: number): void {
    if (this.screenShotImages[index].name.includes('Screenshot-')) {
      this.screenShotAdded = false;
    }
    this.screenShotImages.splice(index, 1);
    this.screenShotImagesPreview.splice(index, 1);
  }

  addImagePreview(screenShotImage: File): void {
    const reader = new FileReader();
    reader.onload = (e) => this.screenShotImagesPreview.push(reader.result);
    reader.readAsDataURL(screenShotImage);
  }

  async submit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.feedbackForm.value;
      data.type = 1;
      const res = await this.commonService
        .feedbackFormSubmit(data, this.screenShotImages)
        .toPromise();
      if (res) {
        this.sweetAlertService.showAlert({
          icon: 'success',
          html: 'FEEDBACK.SUCCESS.MESSAGE',
        });
        this.dialogRef.close();
      }
    } catch (err) {
      this.loading = false;
    }

    this.loading = false;
  }

  DataURIToBlob(dataURI: string): Blob {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
}
