<wr-generic-stepper-dialog
  (submitted)="onSubmit()"
  [isSummaryDisabled]="!isRequiredComplete"
  [showHeaderTitle]="true"
  [employee]="data.caseUser"
  [documentTypeLabel]="docTypeLabel"
  [caseType]="data.caseType"
  [data]="stepperDataForTitle"
  (selectedStep)="scrollToTop()"
  (runStepChangeAction)="contentFillIndex = 0"
>
  <ng-container
    *ngFor="let pages of templateData?.versions.pages; let pageIndex = index"
  >
    <ng-template #step class="flex-row">
      <!--                <div class="text-center w-100" id="title">-->
      <!--                        <h5 class="mb-1 fw-bold text-dark">{{ pages.title }}</h5>-->
      <!--                        <p class="small">{{ pages.subtitle }}</p>-->
      <!--                </div>-->

      <div class="row w-100">
        <div class="col-auto" *ngIf="pages.pageType === 1">
          <mat-card class="content-review">
            <mat-card-content>
              <wr-document-summary
                *ngIf="pageIndex === 1"
                [documentSummaryData]="summary"
                [caseDocumentData]="caseDocumentData"
                [documentTypeLabel]="reportTypes.FACILITATION | translate"
                [caseNumber]="data.caseNumber"
                [employee]="data.caseUser"
                [userId]="data.userId"
                [reportType]="reportTypes.FACILITATION"
                [caseId]="data.caseId"
                [formGroup]="formGroup"
                [steps]="steps"
                class="mx-n3"
              >
              </wr-document-summary>
              <!--TODO: THE REPORT TYPE MENTIONED BELOW NEEDS TO BE MANAGED FROM SOME EXISTING MODEL-->
              <wr-document-summary
                *ngIf="pageIndex === 2"
                [documentSummaryData]="summary1"
                [caseDocumentData]="caseDocumentData1"
                [documentTypeLabel]="'documentTypeLabel' | translate"
                [caseNumber]="data.caseNumber"
                [employee]="data.caseUser"
                [userId]="data.userId"
                [reportType]="'FOLLOW_UP_PLAN'"
                [caseId]="data.caseId"
                [formGroup]="formGroup1"
                [steps]="steps"
                class="mx-n3"
              >
              </wr-document-summary>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col">
          <ng-container
            *ngFor="let content of pages.content; let contentIndex = index"
          >
            <div
              *ngIf="
                data.type ===
                  DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_REVIEW ||
                data.type ===
                  DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW ||
                contentIndex <= contentFillIndex
              "
              [id]="contentPrefix + contentIndex"
              class="w-100 my-auto"
              [class]="
                data.type ===
                  DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_REVIEW ||
                data.type ===
                  DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW
                  ? 'leader-mode'
                  : ''
              "
            >
              <mat-card
                class="feed-listed feed-listed-width mx-auto my-3 px-0 py-4"
              >
                <div #previewMode>
                  <wr-comment-content
                    [currentUser]="currentUser"
                    [content]="content"
                    [commentMode]="data.commentMode"
                    [fillMode]="true"
                    (answerFilled)="answerFilled(contentIndex)"
                  ></wr-comment-content>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
        <div class="col-auto" >
          <mat-card class="content-review">
            <mat-card-content>
              <h2> {{ 'COMMON.NOTE' | translate}}</h2>
              <textarea
                class="text-area-custom mat-input-element-custom"
                matInput
                [placeholder]="'COMMON.NOTE.PLACEHOLDER' | translate"
                type="text"
                rows="12"
                [(ngModel)]="noteText"
              ></textarea>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-template>
  </ng-container>
</wr-generic-stepper-dialog>
