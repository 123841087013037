<h4 class="card-details__title">{{ summaryControl.heading }}</h4>
<ng-container *ngFor="let value of summaryControl.value; let i = index">
  <div class="card-details__value__container" *ngIf="value; else noData">
    <div class="card-details__value" *ngIf="summaryValueKey">
      <mat-icon class="card-details__value__icon" svgIcon="summary-current" color="primary"></mat-icon>
      <span class="card-details__value__text">{{
        summaryControl.selectOptions[value[summaryValueKey]] || summaryControl.noValueInfo || summaryControl.value[i][summaryValueKey] || '-'
      }}</span>
    </div>
    <div *ngIf="summaryPercentageKey" class="card-details__percentage">
      <ng-container
        *ngIf="
          summaryValueKey && (value[summaryPercentageKey] || value[summaryPercentageKey] === 0);
          else noPercentageData
        "
      >
        <mat-icon class="card-details__value__icon" svgIcon="summary-percentage" color="primary"></mat-icon>
        <span class="card-details__value__text">{{ value[summaryPercentageKey] }}</span>
      </ng-container>
      <ng-template #noPercentageData>
        <span *ngIf="i === 0" class="card-details__no-value__text">{{ summaryControl.noValueInfo || '-' }}</span>
      </ng-template>
    </div>
  </div>
  <ng-template #noData>
    <span *ngIf="i === 0" class="card-details__no-value__text">{{ summaryControl.noValueInfo || '-' }}</span>
  </ng-template>
</ng-container>
