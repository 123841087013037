<wr-generic-report
  [getDocument$]="getDocument$"
  [hasAdditionalStep]="false"
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseNumber]="data.caseNumber"
  [caseType]="data.caseType"
  [employee]="data.caseUser"
  [rowsCount]="18"
  [caseEndDate]="data.caseEndDate"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
  (selectedStepChange)="selectedStep($event)"
>
  <!--    <ng-container additionalStep>-->
  <!--        <ng-container *ngIf="workabilityIssueSummary">-->
  <!--            <wr-document-summary-->
  <!--                    [documentSummaryData]="workabilityIssueSummary"-->
  <!--                    [documentTypeLabel]="workabilityDocumentTypeLabel"-->
  <!--                    [caseNumber]="data.caseNumber"-->
  <!--                    [employee]="data.caseUser"-->
  <!--                    [userId]="userId"-->
  <!--            >-->
  <!--            </wr-document-summary>-->
  <!--        </ng-container>-->
  <!--    </ng-container>-->
</wr-generic-report>
