<ng-container
  *ngIf="getDocument$ && (additionalLabels || data.type !== documentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS)"
>
  <wr-generic-report
    [getDocument$]="getDocument$"
    [additionalLabels]="additionalLabels"
    [documentTypeLabel]="documentTypeLabel | translate"
    [caseNumber]="data.caseNumber"
    [employee]="data.caseUser"
    (submitForm)="submit($event)"
    (docVersion)="docVersion = $event"
  >
  </wr-generic-report>
</ng-container>
