import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteSegments } from '../../models/routing.model';

@Component({
  selector: 'wr-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbList: IBreadcrumbItems[] = [];
  @Input() homeIcon = true;
  @Input() linkActivated = true;
  homeUrl = RouteSegments.LANDING_PAGE;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirect(route: string): void {
    if (route) {
      this.router.navigate([route]);
    }
  }
}

export interface IBreadcrumbItems {
  title: string;
  route?: string;
}
