import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormControl} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";

@Component({
  selector: 'wr-percentage-slider',
  templateUrl: './percentage-slider.component.html',
  styleUrls: ['./percentage-slider.component.scss']
})
export class PercentageSliderComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  @Input() control: UntypedFormControl;
  @Input() description: string;
  @Input() label: string;

  ngOnInit(): void {
    this.watchControlChanges();
  }

  private watchControlChanges(): void {
    this.control.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.control.patchValue(this.changeNumberToBeInRange(value));
    });
  }

  private changeNumberToBeInRange(value: number): number {
    let numberToReturn = Math.floor(value);
    if (numberToReturn < 0) {
      numberToReturn = 0;
    }
    if (numberToReturn > 100) {
      numberToReturn = 100;
    }
    return numberToReturn;
  }
  formatLabel(value: number) : string {
      return value + '%';
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
