import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from "@angular/forms";
import {SelectOption} from "@app/shared/common.model";

@Component({
  selector: 'wr-priority-selector',
  templateUrl: './priority-selector.component.html',
  styleUrls: ['./priority-selector.component.scss']
})
export class PrioritySelectorComponent implements OnInit{
  @Input() formArray: UntypedFormArray;
  @Input() selectOptions: ({label: { en: string, no: string }})[];
  @Input() description: string;

  priorityList = ['NONE', 'MEDIUM', 'HIGH']
  value: string[];
  icons = {
    JOB_ROLE: 'person',
    TASKS: 'work',
    LOCATION: 'location_on',
    WORKING_HOURS: 'chronic',
  };
  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.selectOptions.length !== this.formArray.length) {
        this.selectOptions.forEach(opt => {
          this.formArray.push(this.fb.control('NONE'));
        })
    }
  }

  changeVal(val: any, index: number): void {
    this.formArray.at(index).patchValue(val)

  }

  trackBy(index: number, option: SelectOption<string>): string {
    return option.value;
  }
}
