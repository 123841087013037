<form class="datepicker__form">
  <mat-form-field appearance="fill" class="datepicker__form-field" *ngIf="type === 'dropdown'">
    <mat-label *ngIf="inputLabel">{{ inputLabel }}</mat-label>
    <input matInput [min]="minDate" [max]="maxDate"
           [matDatepicker]="datepicker" [ngModel]="value" name="custom-date-picker"
           (dateChange)="onChange($event.value)" />
    <mat-datepicker-toggle matPrefix [for]="datepicker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" color="primary"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>

  <div class="datepicker__open-calendar" *ngIf="type === 'calendar'">
    <mat-calendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      (selectedChange)="onChange($event)"
      [selected]="value"
      [headerComponent]="calendarCustomHeader"
    ></mat-calendar>
  </div>
</form>
