import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wr-textboxes-list',
  templateUrl: './textboxes-list.component.html',
  styleUrls: ['./textboxes-list.component.scss'],
})
export class TextboxesListComponent implements OnInit {
  @Input() description: string;
  @Input() addNewItemText: string;
  @Input() formArray: UntypedFormArray;
  @Input() placeholder: string;

  fakeArray: Array<number>;
  maxArrayLength = 5;

  ngOnInit(): void {
    this.generateFakeArray();
  }

  private generateFakeArray(): void {
    this.fakeArray = new Array(this.formArray.length);
  }

  addNext(): void {
    if (this.fakeArray.length < this.maxArrayLength) {
      this.formArray.push(new UntypedFormControl('', this.formArray.at(0).validator));
      this.generateFakeArray();
    }
  }

  removeItem(index: number): void {
    this.formArray.removeAt(index);
    this.generateFakeArray();
  }
}
