<div
  class="wa-container"
  style="
    background-image: url('../../../assets/images/svg/followUp-Banner.svg');
    background-repeat: repeat-x;
    background-size: auto 350px;
  "
>
  <div class="page-header">
    <div class="row">
      <div class="col-12">
        <wr-breadcrumb
          [breadcrumbList]="[{ title: 'CASES.CASES_LIST.HEADER.TITLE' }]"
        ></wr-breadcrumb>
      </div>
      <div class="col-12 col-sm">
        <h1 class="page-title">
          {{ 'CASES.CASES_LIST.HEADER.TITLE' | translate }}
        </h1>
      </div>
      <div class="col-12 m mt-3">
        <div class="width-1200 px-5 boxSizing">
          <h2 class="text-center mb-4 text-primary-dark fs-2">Create New</h2>
          <div class="mb-5 pb-4">
            <div
              class="d-md-flex gap-5 justify-content-center align-items-lg-stretch"
            >
              <div
                class="w-100 mb-4 mb-md-0 boxSizing"
                *ngFor="let option of optionsList"
              >
                <mat-card
                  [class]="
                    'follow-up-option py-4 px-5 h-100 boxSizing ' + option.class
                  "
                  [ngClass]="{ active: option.id === selectedOption }"
                  (click)="toggleOption(option)"
                >
                  <mat-icon class="mb-4">{{ option.icon }}</mat-icon>
                  <p class="mb-0">{{ option.name }}</p>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
        <div class="width-1500">
          <div class="account-container__filter-section"></div>
          <wr-template-list *ngIf="!selectedOption"></wr-template-list>
          <wr-follow-up-template
            *ngIf="selectedOption === 1"
          ></wr-follow-up-template>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noDataAvailable>
  <wr-empty-list message="FOLLOW_UPS"></wr-empty-list>
</ng-template>
