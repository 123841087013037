<form [formGroup]="control.parent" class="slide-toggle__form">
  <h3 *ngIf="description" class="slide-toggle__form-title">
    {{ description }}
  </h3>
  <p *ngIf="additionalInfo" class="slide-toggle__form-additional-info">
    {{ additionalInfo }}
  </p>
  <mat-slide-toggle class="slide-toggle__control" ngDefaultControl color="accent" [formControl]="control">{{
    label
  }}</mat-slide-toggle>
</form>
