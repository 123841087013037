export interface LoginData {
  email: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  chatsToken: string;
}

export interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  confirmPassword?: string;
  language?: string;
}

export interface ResetData {
  email: string;
}

export interface ConfirmResetData {
  email: string;
  password: string;
}

export interface ResetResponse {
  message: string;
}

export interface IEmailJWTDecoded {
  companyId: string;
  email: string;
  exp: number;
  firstName: string;
  iat: number;
  lastName: string;
  phoneNumber: string;
  userType: string;
  caseId?: string;
}

export interface IVerifySessionIdRes {
  mode: EVerifySessionMode;
  data: IVerifySessionIdData;
}

export interface IVerifySessionIdData {
  firstName: string;
  lastName: string;
  nin: string;
  photo: string;
  phone_number: string;
  email: string;
}

export interface IVerifySessionIdRes {
  mode: EVerifySessionMode,
  data: IVerifySessionIdData
}

export interface IVerifySessionIdData {
    firstName: string,
    lastName: string,
    nin: string,
    photo: string,
    phone_number: string,
    email: string
}
export enum EVerifySessionMode {
  REGISTER = 0 ,
  LOGIN = 1
}

export enum ETwoFactorTypes {
  TOTP = '0',
  EMAIL = '1',
  SMS = '2',
}

export enum ETncType {
  USER = '0',
  COMPANY = '1',
}