export enum RoleName {
  SUPER_ADMIN = '0x53AS',
  COMPANY_ADMIN = '0x43AC',
  WORKSPACE_ADMIN = '0x57AW',
  HR = '0x48AH',
  LEADER = '0x4CAL',
  EMPLOYEE = '0x45AE',
  EXTERNAL = '0x45AX',
  SUPPORT = '0x45US',
}

export const RoleLabels = {
  [RoleName.SUPER_ADMIN]: `ROLENAME.${RoleName.SUPER_ADMIN}.LABEL`,
  [RoleName.COMPANY_ADMIN]: `ROLENAME.${RoleName.COMPANY_ADMIN}.LABEL`,
  [RoleName.WORKSPACE_ADMIN]: `ROLENAME.${RoleName.WORKSPACE_ADMIN}.LABEL`,
  [RoleName.HR]: `ROLENAME.${RoleName.HR}.LABEL`,
  [RoleName.LEADER]: `ROLENAME.${RoleName.LEADER}.LABEL`,
  [RoleName.EMPLOYEE]: `ROLENAME.${RoleName.EMPLOYEE}.LABEL`,
  [RoleName.EXTERNAL]: `ROLENAME.${RoleName.EXTERNAL}.LABEL`,
  [RoleName.SUPPORT]: `ROLENAME.${RoleName.SUPPORT}.LABEL`,
};
export const ADMIN_ROLES = [RoleName.COMPANY_ADMIN, RoleName.WORKSPACE_ADMIN];
export const EXTERNAL_ROLES = [RoleName.EXTERNAL];

export const ASSIGNABLE_ROLES = [RoleName.WORKSPACE_ADMIN, RoleName.HR];
