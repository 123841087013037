import { Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { takeUntil } from 'rxjs/operators';
import { DialogHelperService } from '@app/modules/shared/services/dialog-helper/dialog-helper.service';
import { DialogId } from '@app/shared/dialogs.model';
import { DocumentsService } from '@app/modules/shared/services/documents/documents.service';

@Component({
  selector: 'wr-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.scss'],
})
export class DocumentEditComponent implements OnInit {
  private readonly onDestroy$ = new Subject<null>();

  getDocument$: Observable<DocumentFormConfig>;
  documentTypeLabel = DOCUMENTS_TYPE_LABELS[this.data.type];
  caseType = this.data.caseType;
  docVersion: number;

  constructor(
    private readonly documentsService: DocumentsService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseType: string;
      caseNumber: string;
      caseUser: string;
      type: DocumentTemplateUniqueIdentifier;
      caseId: string;
      documentId: string;
      userId: string;
    },
  ) {}

  ngOnInit(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(DocumentsIdentifiers[this.data.type]);
  }

  submit(updatedFields: { [key: string]: string | string[] }): void {
    this.documentsService
      .updateDocument(this.data.documentId, { fields: updatedFields })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.EDIT_DOCUMENT, { dialogResult: true });
      });
  }
}
