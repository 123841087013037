import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'wr-short-answer',
  templateUrl: './short-answer.component.html',
  styleUrls: ['./short-answer.component.scss']
})
export class ShortAnswerComponent {
  @Input() textControl: UntypedFormControl;
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() autofocus: boolean;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();


  onChange(val: string) {
    this.valueChange.emit(val);
  }
}
