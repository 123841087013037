import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WEEK_DAYS_SHORT } from '@app/shared/components/documents/form-controls/working-hours/working-hours.constants';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';

@Component({
  selector: 'wr-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent implements OnInit, OnDestroy {
  @Input() group: UntypedFormGroup;
  @Input() description: string;

  private readonly onDestroy$ = new Subject<null>();
  hoursControlName: string;
  daysControlName: string;
  daysNames: string[] = [];
  weekDays = WEEK_DAYS_SHORT;
  setHours: 'daily' | 'weekly' = 'weekly';

  ngOnInit(): void {
    this.setControlNames();
    this.setDayNames();
    this.watchWeekHoursValue();
    this.watchDayHoursValue();

    // TO SET THE RADIO BUTTON VALUE
    const dailyVals = this.group.get(this.daysControlName).value;
    this.setHours = 'weekly';
    this.daysNames.forEach((day) => {
      if (dailyVals[day].active) {
        this.setHours = 'daily';
      }
    });
  }

  private setControlNames(): void {
    Object.entries(this.group.getRawValue()).forEach(([key, value]) => {
      if (
        typeof value === 'number' ||
        (typeof value === 'string' && !isNaN(Number(value)))
      ) {
        this.hoursControlName = key;
      } else {
        this.daysControlName = key;
      }
    });
  }

  private watchWeekHoursValue(): void {
    const control = this.group.get(this.hoursControlName);
    if (control) {
      control.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((value) => {
          control.patchValue(value, {
            emitEvent: false,
            onlySelf: true,
          });
        });
    }
  }

  private watchDayHoursValue(): void {
    const control = this.group.get(this.daysControlName);
    if (control) {
      control.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((value) => {
          const controlVal: {
            [key: string]: { from: string; to: string; active: string };
          } = control.value;
          let totalHours = 0;
          Object.keys(controlVal).forEach((key) => {
            if (controlVal[key].active) {
              totalHours =
                totalHours +
                this.timeDifferenceInHours(
                  controlVal[key].from,
                  controlVal[key].to,
                );
            }
          });
          if (totalHours <= 0) totalHours = 45;
          this.group
            .get(this.hoursControlName)
            .setValue(Math.round(totalHours));
        });
    }
  }

  timeDifferenceInHours(startTime: string, endTime: string): number {
    const parseTime = (time: string) => {
      const [hours, minutes] = time.split(':').map(Number);
      return new Date(0, 0, 0, hours, minutes);
    };

    const start = parseTime(startTime);
    const end = parseTime(endTime);

    // Calculate the time difference in milliseconds
    let diff = end.getTime() - start.getTime();

    if (diff < 0) {
      // If the end time is before the start time, consider it as a time span over midnight
      diff += 24 * 60 * 60 * 1000;
    }

    // Calculate the time difference in hours
    const hoursDiff = diff / (1000 * 60 * 60);

    return hoursDiff;
  }
  radioChange($event: MatRadioChange): void {
    const hourControl = this.group.get(this.hoursControlName);
    const hourValue = hourControl.value;
    let valueToAssign = true;
    if ($event.value === 'weekly') {
      valueToAssign = false;
    }
    const control = this.group.get(this.daysControlName);
    if (control) {
      const controlVal: {
        [key: string]: { from: string; to: string; active: boolean };
      } = control.value;
      Object.keys(controlVal).forEach((key) => {
        let valueToSet = valueToAssign;
        if (key === 'sun' || key === 'sat') valueToSet = false;
        controlVal[key].active = valueToSet;
      });
      control.setValue(controlVal);
      hourControl.setValue(Math.round(hourValue));
    }
  }

  private setDayNames(): void {
    const daysValue = (
      this.group.get(this.daysControlName) as UntypedFormGroup
    )?.getRawValue();
    if (daysValue) {
      this.daysNames = Object.keys(daysValue);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
