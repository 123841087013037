import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from "@angular/material/legacy-snack-bar";
import {User} from "@app/shared/user.model";
import {UserService} from "@app/shared/user/user.service";
import * as moment from "moment/moment";
import {Dayjs} from "dayjs";
import {TaskColor} from "@app/shared/tasks.model";
import {DatetimeService} from "@app/shared/datetime/datetime.service";
import {
  INotificationResponse,
  NotificationEventName,
  NotificationTypeEnum,
  StatusEnum
} from "@app/shared/notifications.model";
import {RouteSegments} from "@app/shared/routing.model";
import {Router} from "@angular/router";
import {NotificationsService} from "@app/shared/notifications/notifications.service";
import { CommonService } from '@app/modules/shared/services/user/common.service';

@Component({
  selector: 'wr-custom-snackbar-notification',
  templateUrl: './custom-snackbar-notification.component.html',
  styleUrls: ['./custom-snackbar-notification.component.scss']
})
export class CustomSnackbarNotificationComponent implements OnInit {
  currentUser: User
  icons = {
    SICK_LEAVE: 'sick',
    TASK: 'description',
    STATUS_UPDATE: 'description',
    NEW_FEED: 'post_add',
    NEW_COMMENT: 'mode_comment',
    ANNOUNCEMENT : 'brand_awareness',
    REMINDER: 'acute',
    SELF_CERTIFICATION: 'sick',
    TEMPORARY_CHANGE_REQUEST: 'description',
    ISSUE: 'description'
  };
  readonly NotificationTypeEnum = NotificationTypeEnum;
  readonly NotificationEventName = NotificationEventName;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: any, readonly userService: UserService,
              private readonly router: Router,
              private readonly notificationService: NotificationsService,
              private readonly datetimeService: DatetimeService,
              private readonly commonService: CommonService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser().toPromise();

  }
  getDateDifference(data: any): string {
    if (data.startDate && data.endDate){
      const startDate = moment(data.startDate);
      const endDate = moment(data.endDate);
      const days: number = endDate.diff(startDate, 'days') + 1;
      return (
        this.getCustomDateFormat(days) +
        ' (' +
        startDate.format('MMM DD') +
        ' - ' +
        endDate.format('MMM DD') +
        ')'
      );
    }else{
      return '.';
    }
  }

  getCustomDateFormat(days: number): string{
    switch (true) {
      case days < 2:
        return '1 day';
      case days < 14:
        return `${days} days`
      case days < 84:
        // tslint:disable-next-line:no-bitwise
        return `${(days / 7) | 0} weeks`
      case days >= 84:
        // tslint:disable-next-line:no-bitwise
        return `${(days / 30) | 0} months`
      default:
        return `${days} day(s)`
    }
  }

  getTaskColor(deadline): string {
    const currentDate: Dayjs = this.datetimeService.date();
    const taskDueDate: Dayjs = this.datetimeService.date(deadline);
    const daysDiff = taskDueDate.diff(currentDate, 'day');
    if (daysDiff < 0) {
      return TaskColor.RED;
    } else if (daysDiff === 0) {
      return TaskColor.ORANGE;
    } else if (daysDiff <= 7) {
      return TaskColor.GREEN;
    } else {
      return TaskColor.GRAY;
    }
  }

  navigateToCase(notification: INotificationResponse): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let elementIdToFocus = '';
    if (notification.notificationType === NotificationTypeEnum.NEW_COMMENT) {
      elementIdToFocus = notification.content.feed.feedId
    } else if (notification.notificationType === NotificationTypeEnum.NEW_FEED) {
      elementIdToFocus = notification.content.feed.feedId
    }
    this.router.navigate([`${RouteSegments.CASES}/${notification.caseId}`], {queryParams: {focusItem: notification.notificationType, focusId: elementIdToFocus}});
    if (notification.status === StatusEnum.UNREAD) {
      this.notificationService.setReadStatus(notification._id);
    }
  }

  getCreatedDisplayDate(date: string): string {
    return moment(date).fromNow(true); // eg. 1 day ago, 2 hours ago etc
  }

  close() : void{
    this.commonService.closeSnackbar();
  }
}
