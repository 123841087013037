import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[wrFocus]',
})
export class FocusDirective implements OnInit {
  @Input() wrFocus: HTMLElement;
  constructor() {}

  @HostListener('openedChange') onMouseEnter(): void {
    setTimeout(() => {
      this.wrFocus.focus();
    }, 100);
  }

  ngOnInit(): void {}
}
