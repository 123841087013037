<ng-container *ngIf="groupCopy">
  <!-- <h3 *ngIf="description" class="date-range__form-title">
    {{ description }}
  </h3> -->
  <div class="date-range__container">
    <div [formGroup]="groupCopy" class="date-range__section">
      <div>
        <mat-calendar
          (selectedChange)="onSelectedChange($event)"
          [selected]="selectedDateRange"
          [headerComponent]="calendarCustomHeader"
        ></mat-calendar>
      </div>
    </div>
    <div class="date-range__section">
      <div class="date-range-section__container">
        <h2 class="date-range__selected-days">
          {{
            getSelectedDaysCount(
              selectedDateRange?.start,
              selectedDateRange?.end
            )
          }}
          {{ 'DAYS_SELECTED' | translate }}
        </h2>

        <p class="date-range__date-info">
          {{ 'COMMON.FILTERS.LABEL.START_DATE' | translate }}:
          {{
            selectedDateRange?.start
              ? (selectedDateRange?.start | customDate: 'dd/MM/YYYY')
              : '-'
          }}
        </p>
        <p class="date-range__date-info">
          {{ 'COMMON.FILTERS.LABEL.END_DATE' | translate }}:
          {{
            selectedDateRange?.end
              ? (selectedDateRange?.end | customDate: 'dd/MM/YYYY')
              : '-'
          }}
        </p>
      </div>
    </div>
  </div>

  <div class="date-range__container">
    <mat-error *ngIf="checkIsValid()">{{ group?.errors?.msg }}</mat-error>
    <mat-error *ngIf="!checkIsValid() && max?.value && !isValidRange()">{{
      max.errorMessage
    }}</mat-error>
  </div>
</ng-container>
