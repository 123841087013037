import { Pipe, PipeTransform } from '@angular/core';
import { ITableCols } from '@app/shared/tablePreRequisites.model';

@Pipe({
  name: 'extractColKeys',
})
export class ExtractColKeyPipe implements PipeTransform {
  constructor() {}

  transform(value: Array<ITableCols>): Array<string> {
    return value.map((val) => val.key);
  }
}
