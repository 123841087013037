<mat-accordion
  class="example-headers-align accordion-custom scheduler-summary"
  multi
>
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header class="align-middle">
      <mat-panel-title class="text-primary-dark">{{
        summaryTitle ? summaryTitle : 'Sick Leave'
      }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-card>
      <div
        *ngIf="summaryControls['sickLeaveStatus']"
        class="scheduler-summary-item"
      >
        <div class="summary-card-item">
          <div class="summary-card-item__title">
            {{ summaryControls['sickLeaveStatus'].heading }}:
          </div>
          <div class="summary-card-item__value">
            {{
              summaryControls['sickLeaveStatus'].value
                ? summaryControls['sickLeaveStatus'].value + '%'
                : summaryControls['sickLeaveStatus'].noValueInfo
            }}
          </div>
        </div>
      </div>

      <div
        *ngIf="summaryControls['sickLeaveStartDate']"
        class="scheduler-summary-item"
      >
        <div class="summary-card-item">
          <div class="summary-card-item__title">
            {{ summaryControls['sickLeaveStartDate'].heading }}:
          </div>
          <div class="summary-card-item__value">
            {{
              summaryControls['sickLeaveStartDate'].value
                ? summaryControls['sickLeaveStartDate'].value
                : summaryControls['sickLeaveStartDate'].noValueInfo
            }}
          </div>
        </div>
      </div>

      <div
        *ngIf="summaryControls['sickLeaveEndDate']"
        class="scheduler-summary-item"
      >
        <div class="summary-card-item">
          <div class="summary-card-item__title">
            {{ summaryControls['sickLeaveEndDate'].heading }}:
          </div>
          <div class="summary-card-item__value">
            {{
              summaryControls['sickLeaveEndDate'].value
                ? summaryControls['sickLeaveEndDate'].value
                : summaryControls['sickLeaveEndDate'].noValueInfo
            }}
          </div>
        </div>
      </div>
      <div
        *ngIf="
          summaryControls['sickLeaveStartDate'] &&
          summaryControls['sickLeaveEndDate']
        "
        class="scheduler-summary-item"
      >
        <div class="summary-card-item">
          <div class="summary-card-item__title">
            {{ 'COMMON.DOCUMENTS.DAYS_SELECTED' | translate }}
          </div>
          <div class="summary-card-item__value">
            {{
              summaryControls['sickLeaveStartDate'].value
                | customDateDiffDays: summaryControls['sickLeaveEndDate'].value
            }}
          </div>
        </div>
      </div>
    </mat-card>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion
  class="example-headers-align accordion-custom scheduler-summary"
  multi
>
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header class="align-middle">
      <mat-panel-title class="text-primary-dark">
        {{ 'COMMON.DOCUMENTS.SUMMARY.COMMENTS' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-card>
      <!-- <div *ngIf="summaryControls['sickLeaveComment']" class="scheduler-summary-item">
        <div class="summary-card-item">
          <div class="summary-card-item__value">
            {{
              summaryControls['sickLeaveComment'].value
                ? summaryControls['sickLeaveComment'].value
                : summaryControls['sickLeaveComment'].noValueInfo
            }}
          </div>
        </div>
      </div> -->
      <div
        *ngIf="summaryControls['isRelatedToWorkplace']"
        class="scheduler-summary-item"
      >
        <div class="summary-card-section">
          <h3 class="summary-card-section__title">
            {{ summaryControls['isRelatedToWorkplace'].heading }}
          </h3>
          <div class="summary-card-separator"></div>
          <div class="separator"></div>
          <div
            *ngIf="summaryControls['isRelatedToWorkplace']"
            class="summary-card-section__content"
          >
            <mat-icon
              class="summary-card-item__cross"
              color="primary"
              svgIcon="summary-current"
            ></mat-icon>
            {{
              summaryControls['isRelatedToWorkplace']?.value | humanizeBoolean
            }}
          </div>
        </div>
      </div>
    </mat-card>
  </mat-expansion-panel>
</mat-accordion>
