import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Languages, SelectOption} from "@app/shared/common.model";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";

@Component({
  selector: 'wr-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss']
})
export class InputChipsComponent implements OnInit {
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() color: string;
  @Input() value: Array<string> = [];
  @Input() selectOptions: SelectOption<string>[];
  @Input() autofocus: boolean;
  @Output() valueChange = new EventEmitter<any>();


  private selectedLanguage: Languages;

  chipsValues: string[] = [];
  chipsLabels: { [key: string]: string } = {};
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor( private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang as Languages;
    this.prepareChipsLabel();
    this.chipsValues = this.value;
    this.disableAlreadySelectedOptions();
  }

  private disableAlreadySelectedOptions(): void {
    if (this.selectOptions) {
      this.chipsValues.forEach((value) => {
        this.setOptionDisabledStatus(value, true);
      });
    }
  }

  private setOptionDisabledStatus(value: string, disabled: boolean): void {
    (this.selectOptions.find((option) => option.value === value) || ({} as SelectOption<string>)).disabled =
      disabled;
  }

  private prepareChipsLabel(): void {
    this.selectOptions?.forEach((option) => {
      this.chipsLabels[option.value] = option.label;
      if (typeof this.chipsLabels[option.value] === 'object') {
        this.chipsLabels[option.value] = this.chipsLabels[option.value][this.selectedLanguage];
      }
    });
  }

  private updateFormArray(value: string): void {
    this.chipsValues.push(value);
    this.valueChange.emit(this.chipsValues);
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;
    const trimmedValue = (value || '').trim();
    if (trimmedValue && !this.chipsValues.some((chipValue) => chipValue === trimmedValue)) {
      this.updateFormArray(trimmedValue);
    }
    if (input) {
      input.value = '';
    }
  }

  remove(chipValue: string): void {
    const index = this.chipsValues.indexOf(chipValue);
    if (index >= 0) {
      this.chipsValues.splice(index, 1);
    }
    if (this.selectOptions) {
      this.setOptionDisabledStatus(chipValue, false);
    }
    if (this.chipsValues.length === 0) {
    }
  }

  handleSelectionChange(value: string): void {
    const trimmedValue = value.trim();
    this.updateFormArray(trimmedValue);
    this.setOptionDisabledStatus(value, true);
  }

  trackBy(index: number, value: string): string {
    return value;
  }
}
