<div class="d-flex flex-column h-90 position-relative gap-3 pt-5">
  <ng-container *ngIf="readOnlyMode && companyAdminData()">
    <div class="row border-bottom pb-3 align-items-center">
      <div class="col-md-4">
        <h2 class="mb-1 fw-bold">Company Admin</h2>
        <p class="me-4" style="font-size: 15px !important"></p>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-7 overflow-auto pt-3">
        <ng-container>
          <wr-common-team-add-display
            [data]="companyAdminData()"
            [mode]="readOnlyMode ? 2 : 3"
            class="pe-1"
          ></wr-common-team-add-display>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <!-- Loop through different sections -->
  <ng-container *ngFor="let section of sections">
    <div
      class="row border-bottom pb-3 align-items-center"
      style="min-height: 150px"
      *ngIf="section.visible || (!section.visible && section.data?.length > 1)"
    >
      <div class="col-md-4">
        <h2 class="mb-1 fw-bold">{{ section.title | translate }}</h2>
        <p
          class="me-4"
          style="font-size: 15px !important"
          *ngIf="!readOnlyMode"
        >
          {{ section.description | translate }}
        </p>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-7 overflow-auto pt-3">
        <wr-common-team-add-display
          *ngIf="
            !readOnlyMode &&
            (section.multiple ||
              (!section.multiple &&
                (!section.data || section.data.length == 0)))
          "
          [mode]="section.mode"
          [showExcelButton]="existingInviteList.length > 0"
          (searchDataEvent)="initiateSelectExistingUser(section.type)"
          (selectExcelDataEvent)="initiateSelectExcelUser(section.type)"
          (addDataEvent)="addTeam(section.type)"
          class="pe-1"
        ></wr-common-team-add-display>
        <!-- Conditionally render add team button or team display -->
        <ng-container *ngIf="section.data">
          <wr-common-team-add-display
            *ngFor="let invites of section.data; let inviteIndex = index"
            [data]="invites"
            [mode]="readOnlyMode || section.mode == 2 ? 2 : 3"
            (deleteDataEvent)="clearTeamData(section.tier, inviteIndex)"
            class="pe-1"
          ></wr-common-team-add-display>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
