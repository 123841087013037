export enum RouteSegments {
  ENTRY = 'entry',
  SETUP = 'setup',
  COMPANY_PROFILE = 'account',
  LOGIN = 'login',
  LOGIN_WITH_TOKEN = 'login/:token',
  REGISTER = 'register',
  REGISTER_SUCCESS = 'register-success',
  REGISTER_WITH_TOKEN = 'register/:token',
  CONFIRM_REGISTER = 'confirm-register',
  ENABLE_2FACTOR = 'enable-2factor',
  AUTHENTICATE_2FACTOR = 'authenticate-2factor',
  RESET = 'reset',
  CONFIRM_RESET = 'confirm-reset',
  CONFIRM_VALIDATE = 'verify',
  EMAIL_VERIFY = 'email-verify',
  LANDING_PAGE = 'home',
  CASES = 'follow-ups',
  FOLLOW_UP = 'follow-up',
  TASKS = 'tasks',
  CHAT = 'chat',
  CHAT_HISTORY = 'history',
  TEAMS = 'teams',
  FORMS = 'forms',
  DOCUMENT_TEMPLATES = 'document-templates',
  UNKNOWN = '#',
  PROFILE_PAGE = 'profile',
  MESSAGE_CENTER = 'message-center',
  WORKSPACES = 'workspaces',
  SYS_ADMIN = 'sys-admin',
  ACCOUNTS = 'sys-admin/accounts',
  SUBSCRIPTIONS = 'sys-admin/subscription-packages',
  ARTICLES = 'sys-admin/articles',
  ROLES = 'sys-admin/roles',
  MY_WORKABILITY = 'my-workability',
  WORKSPACE = 'workspace',
  WORKSPACE_CONFIGURE = 'workspace-configure',
  WORKSPACE_HR_CONFIGURE = 'hr-configure',
  WORKSPACE_PROFILE = 'workspace',
  EMAIL_VERIFICATION_PROCESS = 'email-verification-process',
  CHANGE_PASSWORD = 'change-password',
  WORKSPACE_DETAILS = 'workspace/details',
  WORKSPACE_CONFIRM = 'workspace/confirm',
  NOTIFICATIONS = 'notifications',
  CONTACT_US = 'contact-us',
  FEEDBACK = 'feedback',
  // SETUP COMPONENTS
  WELCOME = 'welcome',
  COMPANY_SETUP = 'company',
  WORKSPACE_SETUP = 'workspace',
  WORKSPACE_ONBOARDING = 'onboarding',
  WORKSPACE_ORGANISATION = 'organisation',

  TEAMSPACE = 'teamspace/profile',
  TEAMSPACE_INVITE = 'teamspace/invite',

  // CONTENT CREATOR COMPONENT
  CONTENT_CREATOR = 'content-creator',
  TEMPLATE_LIST = 'template-list',
  TEMPLATE_PREVIEW = 'template-preview',

  // SCHEDULER
  SCHEDULER = 'scheduler',

  // ACCOUNTS
  PREVIEW_ACCOUNTS = 'accounts',

  // WELLNESS CENTER
  WELLNESS_CENTER= 'wellness-center',

  // CONSENT APPROVAL
  CONSENT_APPROVAL= 'consent',
  TERMS_AND_CONDITION= 'terms-and-condition',
  GDPR= 'gdpr',

  // MY LEADERSHIP PORTAL
  LEADERSHIP_PORTAL= 'leadership-portal',

  // EVENT CALENDAR
  EVENT_CALENDAR = 'event-calendar',

  TEMPLATES = 'templates',

  // FEEDBACK LIST
  FEEDBACK_LIST = 'feedback-list',

  FORBIDDEN = 'forbidden',
  BUILDER = 'builder',

}
