export interface ITemplatesListRes {
  count: number;
  data: any[];
}

export interface ITemplateData {
  description: string;
  _id: string;
  title: { en: string };
  currentVersion: number;
  uniqueIdentifier: string;
  versions: ITemplateVersionData[];
}

export interface ITemplateVersionData {
  versions: {
    pages: ITemplatePageData[];
    versionNumber: number;
    note?: string;
  };
  name: string;
  description: string;
  _id: string;
  user: { firstName: string; lastName: string; _id: string };
  uniqueIdentifier: string;
}

export interface ITemplatePageData {
  pageType: number;
  pageNumber: number;
  subtitle: string;
  documentId: string;
  _id: string;
  title: string;
  content: ITemplateContent[];
}

export interface ITemplatePageData {
    pageType: number,
        pageNumber: number,
        subtitle: string,
        documentId: string,
        _id: string,
        title: string,
        content: ITemplateContent[]
}

export interface ITemplateContent {
  values: any[];
  _id?: string;
  text: string;
  type: QuestionTypeEnum;
  required: boolean;
  questionNumber: number;
  isEditMode?: boolean;
  value?: any;
  multipleValue?: any[];
  comment?: string;
}

export interface ITemplateSaveData {
  name: string;
  uniqueIdentifier: string;
  versions: {
    pages: ITemplatePageSaveData[];
    versionNumber: number;
    note?: string;
  };
}
export interface ITemplatePageSaveData {
  pageNumber: number;
  content: {
    questionNumber: number;
    value?: any;
    multipleValue?: any[];
    comment?: string;
  }[];
}
export enum QuestionTypeEnum {
  SHORT = 0,
  LONG = 1,
  CHECKBOX = 2,
  RADIO_BTN = 3,
  RATING = 4,
  NOTE= 99 // CUSTOM QUESTION TYPE ADDED FROM FRONTEND ONLY
}
