import {Component, Input} from '@angular/core';
import {ITemplatePageType} from "@app/modules/builder/builder.component";

@Component({
  selector: 'wr-main-builder',
  templateUrl: './main-builder.component.html',
  styleUrls: ['./main-builder.component.scss']
})
export class MainBuilderComponent {
  @Input()
  templatePageType: ITemplatePageType;
}
