<form [formGroup]="formArray.parent">
  <div class="controls__container">
    <span *ngIf="inputLabel" class="textarea__label mb-1"
      >{{ inputLabel }}
      <mat-hint
        ><small>({{ 'TEXTBOX_WITH_CHIP.HINT' | translate }})</small></mat-hint
      ></span
    >
    <div class="row mt-1">
      <div class="col-12 mb-4">
        <div class="controls__input">
          <mat-chip-list #chipList class="mb-1">
            <mat-chip
              *ngFor="let chipValue of chipsValues; trackBy: trackBy"
              [selectable]="true"
              [removable]="true"
              (removed)="remove(chipValue)"
              [color]="color"
              [ngClass]="'mat-chip-' + color"
            >
              {{
                availableOptions
                  ? chipsLabels[chipValue] || chipValue
                  : chipValue
              }}
              <button class="remove-chip__button" matChipRemove>
                <img
                  class="remove-chip__icon"
                  src="assets/images/svg/cross.svg"
                  alt="remove"
                />
              </button>
            </mat-chip>
            <wr-select-with-search
              *ngIf="availableOptions; else input"
              [options]="availableOptions"
              (valueSelect)="handleSelectionChange($event)"
            ></wr-select-with-search>
          </mat-chip-list>

          <ng-template #input>
            <mat-form-field>
              <input
                matInput
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event)"
                [wrAutoFocus]="autofocus"
              />
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-sm-12 col-xs-12">
        <div class="controls__chips"></div>
      </div>
    </div>
  </div>
</form>
