import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { WEEK_DAYS_SHORT } from '@app/shared/components/documents/form-controls/working-hours/working-hours.constants';
// import {takeUntil} from "rxjs/operators";
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'wr-work-hours',
  templateUrl: './work-hours.component.html',
  styleUrls: ['./work-hours.component.scss'],
})
export class WorkHoursComponent implements OnInit, OnDestroy, DoCheck {
  @Input() value: Object;
  oldVal: Object;
  @Input() description: string;
  @Output() valueChange: EventEmitter<object> = new EventEmitter<object>();

  private readonly onDestroy$ = new Subject<null>();
  hoursControlName: string;
  daysControlName: string;
  daysNames: string[] = [];
  weekDays = WEEK_DAYS_SHORT;
  setHours: 'daily' | 'weekly' = 'weekly';

  ngOnInit(): void {
    this.setDefaultVal();
    this.updateOldVal();
    this.setControlNames();
    this.setDayNames();
    this.watchWeekHoursValue();
    this.watchDayHoursValue();

    // TO SET THE RADIO BUTTON VALUE
    const dailyVals = this.value[this.daysControlName];
    this.setHours = 'weekly';
    this.daysNames.forEach((day) => {
      if (dailyVals[day].active) {
        this.setHours = 'daily';
      }
    });
  }

  ngDoCheck() {
    this.checkIfValChanged();
  }

  setDefaultVal() {
    if (!this.value || (this.value && Object.keys(this.value).length === 0)) {
      this.value = {
        hoursWeek: 40,
        hoursWeekSchedule: {
          mon: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
          tue: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
          wed: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
          thu: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
          fri: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
          sat: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
          sun: {
            from: '08:00',
            to: '16:00',
            active: false,
          },
        },
      };
    }
  }
  updateOldVal() {
    this.oldVal = JSON.parse(JSON.stringify(this.value));
  }

  private setControlNames(): void {
    Object.entries(this.value).forEach(([key, value]) => {
      if (
        typeof value === 'number' ||
        (typeof value === 'string' && !isNaN(Number(value)))
      ) {
        this.hoursControlName = key;
      } else {
        this.daysControlName = key;
      }
    });
  }

  private watchWeekHoursValue(): void {
    // const control = this.value[this.hoursControlName];
    // if (control) {
    //   control.valueChanges
    //     .pipe(takeUntil(this.onDestroy$))
    //     .subscribe((value) => {
    //       control.patchValue(value, {
    //         emitEvent: false,
    //         onlySelf: true,
    //       });
    //     });
    // }
  }

  private watchDayHoursValue(): void {
    // const control = this.value[this.daysControlName];
    // if (control) {
    //   control.valueChanges
    //     .pipe(takeUntil(this.onDestroy$))
    //     .subscribe((value) => {
    //       const controlVal: {
    //         [key: string]: { from: string; to: string; active: string };
    //       } = control.value;
    //       let totalHours = 0;
    //       Object.keys(controlVal).forEach((key) => {
    //         if (controlVal[key].active) {
    //           totalHours =
    //             totalHours +
    //             this.timeDifferenceInHours(
    //               controlVal[key].from,
    //               controlVal[key].to,
    //             );
    //         }
    //       });
    //       if (totalHours <= 0) totalHours = 45;
    //       this.value[this.hoursControlName] = Math.round(totalHours);
    //     });
    // }
  }

  timeDifferenceInHours(startTime: string, endTime: string): number {
    const parseTime = (time: string) => {
      const [hours, minutes] = time.split(':').map(Number);
      return new Date(0, 0, 0, hours, minutes);
    };

    const start = parseTime(startTime);
    const end = parseTime(endTime);

    // Calculate the time difference in milliseconds
    let diff = end.getTime() - start.getTime();

    if (diff < 0) {
      // If the end time is before the start time, consider it as a time span over midnight
      diff += 24 * 60 * 60 * 1000;
    }

    // Calculate the time difference in hours
    const hoursDiff = diff / (1000 * 60 * 60);

    return hoursDiff;
  }
  radioChange($event: MatRadioChange): void {
    const hourControl = this.value[this.hoursControlName];
    const hourValue = hourControl;
    let valueToAssign = true;
    if ($event.value === 'weekly') {
      valueToAssign = false;
    }
    let control = this.value[this.daysControlName];
    if (control) {
      const controlVal: {
        [key: string]: { from: string; to: string; active: boolean };
      } = control;
      Object.keys(controlVal).forEach((key) => {
        let valueToSet = valueToAssign;
        if (key === 'sun' || key === 'sat') valueToSet = false;
        controlVal[key].active = valueToSet;
      });
      this.value[this.daysControlName] = controlVal;
      this.value[this.hoursControlName] = Math.round(hourValue);
    }
  }

  private setDayNames(): void {
    const daysValue = this.value[this.daysControlName];
    if (daysValue) {
      this.daysNames = Object.keys(daysValue);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  checkIfValChanged() {
    if (!isEqual(this.oldVal, this.value)) {
      this.valueChange.emit(this.value);
      this.updateOldVal();
    } else {
      //
    }
  }
}
