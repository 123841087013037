import { Injectable } from '@angular/core';
import {
  IFollowUpDates,
  ScheduleFollowUpDto,
} from '@app/shared/components/scheduler/scheduler.component';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  constructor() {}

  getFollowUpDates(schedulerData: ScheduleFollowUpDto): IFollowUpDates[] {
    moment.locale('en');
    try {
      // const startDate = moment(schedulerData.firstEventDate, 'YYYY-MM-DD');
      const { firstEventDate, duration, frequency, preferDays, preferTime } =
        schedulerData;
      if (frequency.interval <= 0 || duration.interval <= 0) return [];
      const resultSchedule: IFollowUpDates[] = [];
      const startDate = moment(`${firstEventDate}`);
      const durationOfSchedule = startDate
        .clone()
        .add(
          duration.interval,
          duration.unit as moment.unitOfTime.DurationConstructor,
        );
      let currentDate = moment(startDate).clone();
      let iterationCount = 0;
      // const dates = [];
      while (currentDate < durationOfSchedule || iterationCount <= 20) {
        iterationCount = iterationCount + 1;
        if (preferDays && preferDays.length > 0) {
          let currentPreferDay = currentDate.clone();
          for (let i = 1; i <= 7; i++) {
            const currentDay = currentPreferDay.format('ddd').toUpperCase();

            if (preferDays.includes(currentDay)) {
              if (currentPreferDay <= durationOfSchedule) {
                resultSchedule.push({
                  date: moment(currentPreferDay).format('YYYY-MM-DD'),
                  time: preferTime,
                });
              }
            }
            currentPreferDay = moment(currentPreferDay).add(1, 'days');
          }
          currentDate = moment(currentDate).add(
            frequency.interval.toString(),
            frequency.unit as moment.unitOfTime.DurationConstructor,
          );
        } else {
          currentDate = moment(currentDate).add(
            frequency.interval.toString(),
            frequency.unit as moment.unitOfTime.DurationConstructor,
          );
          if (currentDate <= durationOfSchedule) {
            resultSchedule.push({
              date: moment(currentDate).format('YYYY-MM-DD'),
              time: preferTime,
            });
          }
        }
      }
      resultSchedule.pop();
      return resultSchedule;
    } catch {
      return [];
    }
  }
}
export enum DayOfTheWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  NEXT_SUNDAY = 7,
  NEXT_MONDAY = 8,
}
