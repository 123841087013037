import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DocumentFormConfigText } from '@app/modules/forms/models/document-templates.model';

export enum ReportIssueChoices {
  WORKABILITY_UPDATE = 'WORKABILITY_UPDATE',
  RESIDUAL_WORK_CAPACITY = 'RESIDUAL_WORK_CAPACITY',
  PROBLEM_WITH_EQUIPMENT = 'EQUIPMENT',
  OTHER_ISSUES = 'ISSUE',
  SELF_CERTIFICATION = 'SELF_CERTIFICATION',
  BASIC_FOLLOW_UP = 'BASIC_FOLLOW_UP',
}

export enum DocumentsIdentifiers {
  FACILITATION = 'FACILITATION',
  WORKABILITY = 'WORKABILITY',
  FOLLOW_UP_DOCUMENT_MEETING = 'FOLLOW_UP_DOCUMENT_MEETING',
  FOLLOW_UP_DOCUMENT_MEETING_REVIEW = 'FOLLOW_UP_DOCUMENT_MEETING_REVIEW',
  FOLLOW_UP_DOCUMENT_REVIEW = 'FOLLOW_UP_DOCUMENT_REVIEW',
  FOLLOW_UP_DOCUMENT_UPDATE = 'FOLLOW_UP_DOCUMENT_UPDATE',
  FOLLOW_UP_DOCUMENT_QUESTIONS = 'FOLLOW_UP_DOCUMENT_QUESTIONS',
  FOLLOW_UP_PLAN = 'FOLLOW_UP_PLAN',
  SICK_LEAVE = 'SICK_LEAVE',
  SELF_CERTIFICATION = 'SELF_CERTIFICATION',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  FML = 'FML',
  TEMPORARY_CHANGE_REQUEST = 'TEMPORARY_CHANGE_REQUEST',
  STANDARD_FACILITATION = 'STANDARD_FACILITATION',
  BASIC_FOLLOW_UP = 'BASIC_FOLLOW_UP',
  DIALOGUE_MEETING_1 = 'DIALOGUE_MEETING_1',
  WORKABILITY_UPDATE = 'WORKABILITY_UPDATE',
  TEMPORARY_CHANGE_REQUEST_AGREEMENT = 'TEMPORARY_CHANGE_REQUEST_AGREEMENT',
  SELF_CERTIFICATION_AGREEMENT = 'SELF_CERTIFICATION_AGREEMENT',
  CLOSE_BY_LEADER = 'CLOSE_BY_LEADER',
  RESIDUAL_WORK_CAPACITY = 'RESIDUAL_WORK_CAPACITY',
  WORKPLACE_ACCOMMODATION = 'WORKPLACE_ACCOMMODATION',
  BASIC_FOLLOW_UP_CALL = 'BASIC_FOLLOW_UP_CALL',
  BASIC_FOLLOW_UP_MESSAGE = 'BASIC_FOLLOW_UP_MESSAGE',
  FML_NEW_REPORT = 'FML_NEW_REPORT',
  FML_AGREEMENT = 'FML_AGREEMENT',
  ISSUE = 'ISSUE',
  EQUIPMENT = 'EQUIPMENT',
}

export enum FollowUpDocumentType {
  MEETING = 'meeting',
  WRITTEN_UPDATE = 'written-update',
  QUESTIONS = 'questions',
}

export enum DocumentStatus {
  TODO = 'TODO',
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
}

export enum DocumentsControls {
  PANEL = 'PANEL',
  TEXTBOXES_LIST = 'TEXTBOXES_LIST',
  TEXTAREA = 'TEXTAREA',
  DROPDOWN_WITH_PERCENTAGE = 'DROPDOWN_WITH_PERCENTAGE',
  TEXTBOX = 'TEXTBOX',
  LOCATION = 'LOCATION',
  TEXTBOX_WITH_CHIPS = 'TEXTBOX_WITH_CHIPS',
  WORKING_HOURS = 'WORKING_HOURS',
  DATEPICKER = 'DATEPICKER',
  QUESTION_WITH_YES_NO_ANSWER = 'QUESTION_WITH_YES_NO_ANSWER',
  SMALL_TEXTAREA_LIST = 'SMALL_TEXTAREA_LIST',
  FOLLOW_UP_PLAN_DATES = 'FOLLOW_UP_PLAN_DATES',
  SINGLE_BUTTON_SELECT = 'SINGLE_BUTTON_SELECT',
  DATE_RANGE = 'DATE_RANGE',
  SELECT = 'SELECT',
  SLIDE_TOGGLE = 'SLIDE_TOGGLE',
  MULTI_BUTTON_SELECT = 'MULTI_BUTTON_SELECT',
  SLIDE_TOGGLE_LIST = 'SLIDE_TOGGLE_LIST',
  PERCENTAGE_SLIDER_LIST = 'PERCENTAGE_SLIDER_LIST',
  HORIZONTAL_BUTTON_RADIO = 'HORIZONTAL_BUTTON_RADIO',
  TEXTBOXES_WITH_SELECT_LIST = 'TEXTBOXES_WITH_SELECT_LIST',
  PERCENTAGE = 'PERCENTAGE',
  PERCENTAGE_SLIDER = 'PERCENTAGE_SLIDER',
  PRIORITY_SELECTOR = 'PRIORITY_SELECTOR',
  CUSTOM_DAYS_AND_HOURS = 'CUSTOM_DAYS_AND_HOURS',

  BASIC_SCHEDULER = 'BASIC_SCHEDULER',
  DIALOGUE_MEETING_SCHEDULER = 'DIALOGUE_MEETING_SCHEDULER',
  BASIC_SCHEDULER_SUMMARY = 'BASIC_SCHEDULER_SUMMARY',
  FOLLOW_UP_PLAN = 'FOLLOW_UP_PLAN',
}

export interface DocumentSummaryData {
  description: DocumentFormConfigText;
  heading: DocumentFormConfigText | DocumentFormConfigText[] | string;
  selectOptions: { [key: string]: string };
  value: any;
  key: string;
  parentKey: string;
  type: DocumentsControls;
  step: number;
  disabledUnderline?: boolean;
  noValueInfo?: string;
}

export interface DocumentBuilderOutput {
  formGroup: UntypedFormGroup;
  onStepChangeActions: (() => void)[];
  summarySubscription: Subscription;
  stepControls: AbstractControl[];
}

export interface DocumentAdditionalLabel {
  controlType: DocumentsControls;
  label: string | string[];
}
