import { Component, Input, OnInit } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { DatetimeService } from '@app/shared/datetime/datetime.service';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-document-summary-card-details-date-range',
  templateUrl: './document-summary-card-details-date-range.component.html',
  styleUrls: ['./document-summary-card-details-date-range.component.scss'],
})
export class DocumentSummaryCardDetailsDateRangeComponent implements OnInit {
  @Input() summaryControl: DocumentSummaryData;

  isValueValid: boolean;

  constructor(
    private readonly datetimeService: DatetimeService,
    private readonly iconsHelperService: IconsHelperService,
  ) {
    this.iconsHelperService.registerSvgIcons('summary-current');
  }

  ngOnInit(): void {
    this.isValueValid = this.datetimeService.checkIsDateFormatSimple(this.summaryControl.value);
  }
}
