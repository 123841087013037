<wr-generic-report
  #temporaryChange
  [getDocument$]="getDocument$"
  [caseType]="caseType"
  [documentTypeLabel]="documentTypeLabel"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
  [rowsCount]="20"
  [hasHasCustomTitle]="true"
  [reportType]="data.type"

>
  <ng-container title>
    <h3
      *ngIf="temporaryChange?.steps?.length == temporaryChange?.selectedStep + 1"
      class="step-description"
      mat-dialog-title
    >
      {{ documentTypeLabel | translate }}
    </h3>
  </ng-container>
</wr-generic-report>
