import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CaseTypes } from '@app/shared/cases.model';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CasesService } from '@app/shared/cases/cases.service';
import { IBasicSchedulerData } from '@app/shared/components/scheduler/scheduler-summary/scheduler-summary.component';
import { DocumentStatus } from '@app/shared/documents.model';
import { DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { TasksService } from '@app/modules/tasks/services/tasks.service';
import { SweetAlertService } from '@app/core/services/alert/sweet-alert.service';

@Component({
  selector: 'wr-follow-up-scheduled-event',
  templateUrl: './follow-up-scheduled-event.component.html',
  styleUrls: ['./follow-up-scheduled-event.component.scss'],
})
export class FollowUpScheduledEventComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  madeTheCall = true;
  postponeValue = '';
  callNote = '';
  message = '';
  postponeItems: number[] = [15, 30, 60];
  documentTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;
  schedulerData: IBasicSchedulerData;
  docVersion: number;

  constructor(
    private casesService: CasesService,
    private tasksService: TasksService,
    private dialogHelperService: DialogHelperService,
    private sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      taskId: string;
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      type: DocumentTemplateUniqueIdentifier;
      documentId: string;
    },
  ) {}

  ngOnInit(): void {
    this.getDocumentFormData();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  private getDocumentFormData(): void {
    this.casesService.getCaseDocument(this.data.caseId, this.data.documentId).subscribe((value) => {
      this.docVersion = value.docTemplateVersionNumber;
      this.schedulerData = value.fields as any;

      this.message = this.schedulerData.schedulerFollowupMessage;
    }),
      takeUntil(this.onDestroy$);
  }

  async postponeValueChanged(): Promise<void> {
    await this.tasksService.postponeTask(this.data.taskId, this.postponeValue);
    this.sweetAlertService.showAlert({
      icon: 'success',
      title: 'Success',
      text: 'Event postponed successfully',
    });
    this.dialogHelperService.closeDialog(DialogId.BASIC_FOLLOW_UP_MESSAGE, { dialogResult: true });
  }

  submit(): void {
    const followupEventData = {
      postponeValue: this.postponeValue,
      callNote: this.callNote,
      message: this.message,
    };

    this.casesService
      .addDocumentToCase(this.data.caseId, {
        docTemplateUniqueIdentifier: this.data.type,
        docTemplateVersionNumber: this.docVersion,
        ownerId: this.data.userId,
        fields: {
          ...followupEventData,
        },
        status: DocumentStatus.COMPLETED,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.BASIC_FOLLOW_UP_MESSAGE, { dialogResult: true });
      });
  }
}
