import { Injectable } from '@angular/core';
import { SysAdminService } from '../user/sys-admin.service';
import { UserService } from '../user/user.service';
import { SecurityService } from '@app/modules/core/services/security/security.service';
import { TokenKey } from '../../models/common.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  permissions: string[];
  constructor(
    private readonly userService: UserService,
    private sysAdminService: SysAdminService,
    private securityService: SecurityService,
  ) {
    this.initializePermission();
  }
  async initializePermission(force: boolean = false): Promise<void> {
    if (this.userService.isUserLoggedIn()) {
      let permissions = force ? null : this.permissions;
      if (!permissions) {
        const res = this.securityService.getSessionItem(TokenKey.PERMISSIONS);
        if (res) {
          permissions = res.split(',');
          this.permissions = permissions;
        }
      }
      if (!permissions || permissions.length === 0) {
        const jwtData = this.userService.getAuthTokenData();
        this.permissions = await this.sysAdminService.getPermissions(
          jwtData.roles,
        );
        this.securityService.setSessionItem(
          TokenKey.PERMISSIONS,
          this.permissions,
        );
      }
    }
  }
  hasPermission(requiredPermissions: string[]): boolean {
    if(requiredPermissions.length === 0) {
      return true
    }
    this.initializePermission();
    return requiredPermissions.some((permission) =>
      this.permissions?.includes(permission),
    );
  }
}
