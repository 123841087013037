import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CaseDocument } from '@app/shared/cases.model';
import { takeUntil } from 'rxjs/operators';
import { ContentCreatorService } from '@app/shared/content-creator/content-creator.service';
import { Subject } from 'rxjs';
import { ITemplateVersionData } from '@app/modules/content-creator/content-creatorer.model';
import { AccessUser } from '@app/modules/case-page/models/access.model';
import { CasesService } from '@app/shared/cases/cases.service';

@Component({
  selector: 'wr-scheduler-main-summary',
  templateUrl: './scheduler-main-summary.component.html',
  styleUrls: ['./scheduler-main-summary.component.scss'],
})
export class SchedulerMainSummaryComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
// TODO: IFollowupData is its type but throws ngIf not found in preview mode of questionnaires
  followupData: any;
  questionTemplateData: ITemplateVersionData;
  accessUsers: AccessUser[] = [];
  @Input() caseId: string;
  @Input()
  set caseDocumentData(value: CaseDocument) {
    if (value) {
      this.followupData = value.fields;
    }
  }

// TODO: IFollowupData is its type but throws nigIf not found in preview mode of questionnaires
  get followUpDataVal(): any {
    return this.followupData;
  }
  constructor(
    private readonly contentCreatorService: ContentCreatorService,
    private readonly casesService: CasesService,
  ) {}

  async ngOnInit(): Promise<void> {
    // this.followupData = this.caseDocumentData.fields;
    this.getQuestionTemplates();
    this.accessUsers = await this.casesService.getCaseAccessUsers(this.caseId).toPromise();
  }

  getQuestionTemplates(): void {
    this.contentCreatorService
      .getTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        this.questionTemplateData = templates;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
