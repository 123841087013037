import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { INotificationResponse } from '../../models/notifications.model';
import { ApiList } from '@app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private httpClient: HttpClient) {}

  fetchNotifications = (): Promise<INotificationResponse[]> => {
    return this.httpClient
      .get<INotificationResponse[]>(
        `${environment.baseUrl}${ApiList.notifications._}#no-track`,
      )
      .toPromise();
  };

  fetchNotification = (id: string): Promise<INotificationResponse> => {
    return this.httpClient
      .get<INotificationResponse>(
        `${environment.baseUrl}${ApiList.notifications._}/fetch?id=${id}`,
      )
      .toPromise();
  };

  setReadStatus = (notificationId?: string): Promise<any> => {
    let url = `${environment.baseUrl}${ApiList.notifications._}/true/${ApiList.notifications.status}`;
    if (notificationId && notificationId !== '') {
      url = url + `?id=${notificationId}`;
    }
    return this.httpClient.put<any>(url, null).toPromise();
  };
}
