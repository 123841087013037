import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {RouterModule} from '@angular/router';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {TranslateModule} from '@ngx-translate/core';

import {globalRippleConfig} from '@app/core/core.module.constants';
import {AppHeaderService} from '@app/core/services/app-header/app-header.service';
import {AuthInterceptorService} from '@app/core/interceptors/auth-interceptor/auth-interceptor.service';
import {SecurityService} from '@app/core/services/security/security.service';
import {
    AppSidenavComponent,
    BottomAdditionalMenuComponent,
    BottomMenuComponent
} from '@app/core/app-sidenav/app-sidenav.component';
import {SharedModule} from '../shared/shared.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatExpansionModule} from "@angular/material/expansion";
import { CustomSnackbarNotificationComponent } from './components/snackbar/custom-snackbar-notification/custom-snackbar-notification.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatSnackBarModule,
        RouterModule,
        HttpClientModule,
        TranslateModule,
        MatMenuModule,
        MatBottomSheetModule,
        SharedModule,
        MatTooltipModule,
        MatExpansionModule,
    ],
    declarations: [AppSidenavComponent, BottomMenuComponent, BottomAdditionalMenuComponent, CustomSnackbarNotificationComponent],
    providers: [
        AppHeaderService,
        SecurityService,
        AuthInterceptorService,
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: globalRippleConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
    ],
    exports: [AppSidenavComponent],
})
export class CoreModule {
}
