import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
})
export class NameInitialsPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const valueToReturn = '';
    if (value && typeof value === 'string') {
      const regex = /(\b\w)/g;
      const matches = [...value.match(regex)];

      return matches
        .map((char) => char.toUpperCase())
        .join('')
        .substring(0, 2);
    }
    return valueToReturn;
  }
}
