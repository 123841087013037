import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DatetimeService } from '@app/shared/datetime/datetime.service';
import { SIMPLE_DATE_FORMAT } from '@app/shared/constants/datetime.constants';
import {
  FOLLOW_UP_PLAN_DAYS_OPTIONS,
  FOLLOW_UP_PLAN_FREQUENCY_OPTIONS,
  FOLLOW_UP_PLAN_TIME_OPTIONS,
  FOLLOW_UP_PLAN_WEEKS_OPTIONS,
} from '@app/shared/components/documents/reports/follow-up-plan/follow-up-plan-dates-control/follow-up-plan-dates-control.constants';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-follow-up-plan-dates-control',
  templateUrl: './follow-up-plan-dates-control.component.html',
  styleUrls: ['./follow-up-plan-dates-control.component.scss'],
})
export class FollowUpPlanDatesControlComponent implements OnInit, OnDestroy {
  @Input() group: UntypedFormGroup;

  private readonly onDestroy$ = new Subject<null>();

  weekOptions = FOLLOW_UP_PLAN_WEEKS_OPTIONS;
  frequencyOptions = FOLLOW_UP_PLAN_FREQUENCY_OPTIONS;
  daysOptions = FOLLOW_UP_PLAN_DAYS_OPTIONS;
  timeOptions = FOLLOW_UP_PLAN_TIME_OPTIONS;

  constructor(
    private readonly datetimeService: DatetimeService,
    private readonly iconsHelperService: IconsHelperService,
  ) {
    this.iconsHelperService.registerSvgIcons('calendar');
  }

  ngOnInit(): void {
    this.watchValueChangesAndFormatDate();
  }

  private watchValueChangesAndFormatDate(): void {
    this.group.valueChanges
      .pipe(
        distinctUntilChanged((previous, current) => {
          return (
            previous.followUpPlanStartDate === current.followUpPlanStartDate &&
            previous.followUpPlanWeeks === current.followUpPlanWeeks
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.updateEndTime();
      });
  }

  private updateEndTime(): void {
    const { followUpPlanStartDate, followUpPlanWeeks } = this.group.getRawValue();
    if (followUpPlanWeeks) {
      const newEndDate = this.datetimeService
        .formatSimpleDateToDayjs(followUpPlanStartDate)
        .add(followUpPlanWeeks, 'week')
        .format(SIMPLE_DATE_FORMAT);
      this.group.controls.followUpPlanEndDate.patchValue(newEndDate);
    }
  }

  trackByWeek(index: number, week: number): number {
    return week;
  }

  trackByFrequency(index: number, frequency: { value: string; label: string }): string {
    return frequency.value;
  }

  trackByDay(index: number, day: { value: string; label: string }): string {
    return day.value;
  }

  trackByTime(index: number, time: string): string {
    return time;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
