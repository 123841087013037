export enum CaseType {
  ISSUE = 'ISSUE',
  EQUIPMENT = 'EQUIPMENT',
  TEMPORARY_CHANGE_REQUEST = 'TEMPORARY_CHANGE_REQUEST',
  SELF_CERTIFICATION = 'SELF_CERTIFICATION',
  SICK_LEAVE = 'SICK_LEAVE',
  WORKABILITY = 'WORKABILITY',
  FML = 'FML',
}

export enum CaseTypeLabel {
  SICK_LEAVE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_SICK_LEAVE',
  WORKABILITY = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_WORKABILITY',
  SELF_CERTIFICATION = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_SELF_CERTIFICATION',
  EQUIPMENT = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_EQUIPMENT',
  ISSUE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_ISSUE',
  TEMPORARY_CHANGE_REQUEST = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_TEMPORARY_CHANGE_REQUEST',
  FML = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_FML',
}

export enum CaseTypeIcons {
  SICK_LEAVE = 'sick-leave-button-icon',
  WORKABILITY = 'report-work-issue-button-icon',
  SELF_CERTIFICATION = 'self-certification-button-icon',
  EQUIPMENT = 'equipment-button-icon',
  ISSUE = 'report-work-issue-button-icon',
  TEMPORARY_CHANGE_REQUEST = 'temporary-change-button-icon',
  FML = 'sick-leave-button-icon',
}

export enum CaseFieldType {
  TEXT = 'text',
  DATE = 'date',
}

export enum CaseField {
  CASE_TYPE = 'case-type',
  FOLLOW_UP_STATUS = 'follow-up-status',
  END_DATE = 'end-date',
  NEXT_FOLLOW_UP_ACTION = 'follow-up-action',
  NEXT_FOLLOW_UP_DATE = 'next-follow-up-date',
  STATE_DATE = 'state-date',
  CASE_START_DATE = 'case-start-date',
  CASE_END_DATE = 'case-end-date',
  NEXT_FOLLOW_UP_TASK = 'next-follow-up-task',
  FOLLOW_UP_REMAINING = 'follow-up-remaining',
}

export enum CaseFieldLabel {
  CASE_TYPE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_CASE_TYPE',
  FOLLOW_UP_STATUS = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_FOLLOW_UP_STATUS',
  END_DATE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_END_DATE',
  NEXT_FOLLOW_UP_ACTION = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_NEXT_FOLLOW_UP_ACTION',
  NEXT_FOLLOW_UP_DATE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_NEXT_FOLLOW_UP_DATE',
  STATE_DATE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_STATE_DATE',
  CASE_START_DATE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_CASE_START_DATE',
  CASE_END_DATE = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_CASE_END_DATE',
  NEXT_FOLLOW_UP_TASK = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_NEXT_FOLLOW_UP_TASK',
  FOLLOW_UP_REMAINING = 'CASES.CASE_PAGE.CASE_DETAILS_CARD.FIELD_LABEL_FOLLOW_UP_REMAINING',
}

export enum CaseStatus {
  NEW = 'NEW',
  CLOSED = 'CLOSED',
  TEMPORARY_CHANGE_REQUEST_AGREEMENT = 'TEMPORARY_CHANGE_REQUEST_AGREEMENT',
  CASE_ACCESS_ACCEPT = 'CASE_ACCESS_ACCEPT',
  CLOSE_BY_LEADER = 'CLOSE_BY_LEADER',
  BASIC_FOLLOW_UP = 'BASIC_FOLLOW_UP',
  DIALOGUE_MEETING_1 = 'DIALOGUE_MEETING_1',
  DIALOGUE_MEETING_2 = 'DIALOGUE_MEETING_2',
  DIALOGUE_MEETING_3 = 'DIALOGUE_MEETING_3',
  FACILITATION = 'FACILITATION',
  FACILITATION_AGREEMENT = 'FACILITATION_AGREEMENT',
  FOLLOW_UP_PLAN = 'FOLLOW_UP_PLAN',
  FOLLOW_UP_PLAN_AGREEMENT = 'FOLLOW_UP_PLAN_AGREEMENT',
  FOLLOW_UP_DOCUMENT = 'FOLLOW_UP_DOCUMENT',
  FOLLOW_UP_DOCUMENT_MEETING = 'FOLLOW_UP_DOCUMENT_MEETING',
  FOLLOW_UP_DOCUMENT_UPDATE = 'FOLLOW_UP_DOCUMENT_UPDATE',
  FOLLOW_UP_DOCUMENT_QUESTIONS = 'FOLLOW_UP_DOCUMENT_QUESTIONS',
  NAV_FILLING = 'NAV_FILLING',
  NEW_REPORT = 'NEW_REPORT',
  WORKABILITY_UPDATE = 'WORKABILITY_UPDATE',
  ISSUE_NEW_REPORT = 'ISSUE_NEW_REPORT',
  EQUIPMENT_NEW_REPORT = 'EQUIPMENT_NEW_REPORT',
  TEMPORARY_CHANGE_REQUEST_NEW_REPORT = 'TEMPORARY_CHANGE_REQUEST_NEW_REPORT',
  SELF_CERTIFICATION_NEW_REPORT = 'SELF_CERTIFICATION_NEW_REPORT',
  SICK_LEAVE_NEW_REPORT = 'SICK_LEAVE_NEW_REPORT',
  WORKABILITY_NEW_REPORT = 'WORKABILITY_NEW_REPORT',
  SELF_CERTIFICATION_AGREEMENT = 'SELF_CERTIFICATION_AGREEMENT',
  ISSUE_NEW_REPORT_AGREEMENT = 'ISSUE_NEW_REPORT_AGREEMENT',
  EQUIPMENT_NEW_REPORT_AGREEMENT = 'EQUIPMENT_NEW_REPORT_AGREEMENT',
  BASIC_FOLLOW_UP_CALL = 'BASIC_FOLLOW_UP_CALL',
  BASIC_FOLLOW_UP_MESSAGE = 'BASIC_FOLLOW_UP_MESSAGE',
  FOLLOW_UP_DOCUMENT_REVIEW = 'FOLLOW_UP_DOCUMENT_REVIEW',
  FOLLOW_UP_DOCUMENT_MEETING_REVIEW = 'FOLLOW_UP_DOCUMENT_MEETING_REVIEW',
  RESIDUAL_WORK_CAPACITY = 'RESIDUAL_WORK_CAPACITY',
  WORKPLACE_ACCOMMODATION = 'WORKPLACE_ACCOMMODATION',
  FML_AGREEMENT = 'FML_AGREEMENT'
}

export interface CaseFieldDetails {
  field: CaseField;
  type: CaseFieldType;
  label: CaseFieldLabel;
}

export interface CaseDetails {
  type: CaseType;
  fields: CaseFieldDetails[];
}

export interface ICaseListRes {
  openedDate: string;
  owner: string;
  accessLevel: string;
  isOwner: boolean;
  photo: string;
  type: CaseType;
  accesses: {
    role: string;
    accessLevel: string;
    isAccepted: boolean;
    name: string;
    userId: string;
  }[];
  status: CaseStatus;
  _id: string;
}

export interface ICasesFilterData {
  followUpTypes: CaseType[];
  caseOwnersWorkspaceIds: string[];
  onlyMyCases: boolean;
}
