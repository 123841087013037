<form [formGroup]="formArray.parent">
  <h3 *ngIf="description" class="small-textarea-list_form-title">
    {{ description }}
  </h3>
  <ng-container *ngFor="let _ of fakeArray; let index = index">
    <div class="small-textarea__container">
      <p *ngIf="labels" class="small-textarea__label">{{ labels[index] }}</p>
      <mat-form-field floatLabel="never" class="small-textarea__form-field">
        <textarea
          [formControl]="formArray.at(index)"
          [cdkAutosizeMinRows]="3"
          [placeholder]="placeholder"
          matInput
          cdkTextareaAutosize
        ></textarea>
      </mat-form-field>
    </div>
  </ng-container>
</form>
