export const congratsWelcomeComponentData = {
  COMPANY: {
    logo: `<svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9917 37.3334H9.64173V13.8667L22.3834 0.666748L24.1709 2.08758C24.3542 2.24036 24.4917 2.45425 24.5834 2.72925C24.6751 3.00425 24.7209 3.34036 24.7209 3.73758V4.19591L22.6584 13.8667H36.3626C37.0959 13.8667 37.7376 14.1417 38.2876 14.6917C38.8376 15.2417 39.1126 15.8834 39.1126 16.6167V20.3751C39.1126 20.589 39.1355 20.8105 39.1813 21.0397C39.2271 21.2688 39.2042 21.4904 39.1126 21.7042L33.3376 34.9959C33.0626 35.6376 32.6119 36.1876 31.9855 36.6459C31.3591 37.1042 30.6945 37.3334 29.9917 37.3334ZM12.3917 34.5834H30.5876L36.3626 20.8792V16.6167H19.2667L21.6959 5.20425L12.3917 15.0126V34.5834ZM12.3917 15.0126V34.5834V15.0126ZM9.64173 13.8667V16.6167H3.5459V34.5834H9.64173V37.3334H0.795898V13.8667H9.64173Z"
        fill="#00C7B2"
      />
    </svg>`,
    title: 'MESSAGE.CONGRATULATIONS',
    messageBottom: `COMPANY.MESSAGE_BOTTOM`,
    info: `COMPANY.MESSAGE_INFO`,
    btnText: `BUTTON.LETS_GO`,
    index: 1,
  },
  WORKSPACE: {
    logo: `<svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9917 37.3334H9.64173V13.8667L22.3834 0.666748L24.1709 2.08758C24.3542 2.24036 24.4917 2.45425 24.5834 2.72925C24.6751 3.00425 24.7209 3.34036 24.7209 3.73758V4.19591L22.6584 13.8667H36.3626C37.0959 13.8667 37.7376 14.1417 38.2876 14.6917C38.8376 15.2417 39.1126 15.8834 39.1126 16.6167V20.3751C39.1126 20.589 39.1355 20.8105 39.1813 21.0397C39.2271 21.2688 39.2042 21.4904 39.1126 21.7042L33.3376 34.9959C33.0626 35.6376 32.6119 36.1876 31.9855 36.6459C31.3591 37.1042 30.6945 37.3334 29.9917 37.3334ZM12.3917 34.5834H30.5876L36.3626 20.8792V16.6167H19.2667L21.6959 5.20425L12.3917 15.0126V34.5834ZM12.3917 15.0126V34.5834V15.0126ZM9.64173 13.8667V16.6167H3.5459V34.5834H9.64173V37.3334H0.795898V13.8667H9.64173Z"
        fill="#00C7B2"
      />
    </svg>`,
    title: 'MESSAGE.KEEP_IT_UP',
    messageBottom: `WORKSPACE.MESSAGE_BOTTOM`,
    info: `WORKSPACE.MESSAGE_INFO`,
    btnText: `BUTTON.LETS_GO`,
    index: 2,
  },
  ORGANISATION: {
    logo: `<svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9917 37.3334H9.64173V13.8667L22.3834 0.666748L24.1709 2.08758C24.3542 2.24036 24.4917 2.45425 24.5834 2.72925C24.6751 3.00425 24.7209 3.34036 24.7209 3.73758V4.19591L22.6584 13.8667H36.3626C37.0959 13.8667 37.7376 14.1417 38.2876 14.6917C38.8376 15.2417 39.1126 15.8834 39.1126 16.6167V20.3751C39.1126 20.589 39.1355 20.8105 39.1813 21.0397C39.2271 21.2688 39.2042 21.4904 39.1126 21.7042L33.3376 34.9959C33.0626 35.6376 32.6119 36.1876 31.9855 36.6459C31.3591 37.1042 30.6945 37.3334 29.9917 37.3334ZM12.3917 34.5834H30.5876L36.3626 20.8792V16.6167H19.2667L21.6959 5.20425L12.3917 15.0126V34.5834ZM12.3917 15.0126V34.5834V15.0126ZM9.64173 13.8667V16.6167H3.5459V34.5834H9.64173V37.3334H0.795898V13.8667H9.64173Z"
        fill="#00C7B2"
      />
    </svg>`,
    title: 'MESSAGE.ALMOST_THERE',
    messageBottom: `ORGANISATION.MESSAGE_BOTTOM`,
    info: `ORGANISATION.MESSAGE_INFO`,
    btnText: `BUTTON.LETS_GO`,
    index: 3,
  },
  ONBOARDING: {
    logo: `<svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9917 37.3334H9.64173V13.8667L22.3834 0.666748L24.1709 2.08758C24.3542 2.24036 24.4917 2.45425 24.5834 2.72925C24.6751 3.00425 24.7209 3.34036 24.7209 3.73758V4.19591L22.6584 13.8667H36.3626C37.0959 13.8667 37.7376 14.1417 38.2876 14.6917C38.8376 15.2417 39.1126 15.8834 39.1126 16.6167V20.3751C39.1126 20.589 39.1355 20.8105 39.1813 21.0397C39.2271 21.2688 39.2042 21.4904 39.1126 21.7042L33.3376 34.9959C33.0626 35.6376 32.6119 36.1876 31.9855 36.6459C31.3591 37.1042 30.6945 37.3334 29.9917 37.3334ZM12.3917 34.5834H30.5876L36.3626 20.8792V16.6167H19.2667L21.6959 5.20425L12.3917 15.0126V34.5834ZM12.3917 15.0126V34.5834V15.0126ZM9.64173 13.8667V16.6167H3.5459V34.5834H9.64173V37.3334H0.795898V13.8667H9.64173Z"
        fill="#00C7B2"
      />
    </svg>`,
    title: 'MESSAGE.CONGRATULATIONS',
    messageBottom: `ONBOARDING.MESSAGE_BOTTOM`,
    info: ``,
    btnText: `BUTTON.LETS_GO`,
    index: 4,
  },
  TEAMSPACE: {
    logo: `<img class="my-3" src="../../../../../assets/images/svg/workability-logo.svg" />`,
    title: `MESSAGE.CONGRATULATIONS`,
    messageBottom: `TEAMSPACE.MESSAGE_BOTTOM`,
    info: ``,
    btnText: `BUTTON.START_WORKABILITY`,
    index: 2,
    isTeamSpace: true,
  },
  WELCOME: {
    logo: `<img class="my-3" src="../../../../../assets/images/svg/workability-logo.svg" />`,
    title: `CARD.COMMON.WELCOME_TO_WORKABILITY`,
    subTitle: `WELCOME.SUBTITLE`,
    messageBottom: `WELCOME.MESSAGE_BOTTOM`,
    info: ``,
    btnText: `BUTTON.GET_STARTED`,
    index: -1,
  },
};

export interface ICongratsWelcomeComponentData {
  logo: string;
  title: string;
  messageBottom: string;
  info: string;
  btnText: string;
}
