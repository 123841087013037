export const BREAKPOINT_MOBILE = 480;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_SMALL_SCREEN = 1024;
export const BREAKPOINT_MEDIUM_SCREEN = 1200;

export enum Breakpoints {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  SMALL_SCREEN = 'small-screen',
  MEDIUM_SCREEN = 'medium-screen',
}
