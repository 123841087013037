<wr-generic-report
  [getDocument$]="getDocument$"
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseNumber]="data.caseNumber"
  [caseType]="data.caseType"
  [employee]="data.caseUser"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
  [caseId]="data.caseId"
  [documentId]="data.documentId"
  [rowsCount]="18"
  [reportType]="data.type"
  [taskType]="data.type"
>
</wr-generic-report>
