<div class="d-flex">
  <div class="m-auto w-75">
    <mat-radio-group
      [(ngModel)]="setHours"
      [ngModelOptions]="{ standalone: true }"
      (change)="radioChange($event)"
      class="flex-grow-0 mat-radio-group-custom"
      xmlns="http://www.w3.org/1999/html"
    >
      <mat-radio-button value="weekly" class="fw-bold">
        {{ 'SET_HOURS_WEEKLY' | translate }}
      </mat-radio-button>
    </mat-radio-group>
    <p class="my-2 small ps-4">{{'WORK_HOURS.HOURS_YOU_CAN_WORK_FOR' | translate}}</p>
    <form [formGroup]="group.parent">
      <div *ngIf="hoursControlName" class="hours-week__container ps-4 mb-2">
        <mat-form-field
          class="hours-week__hours-input"
          readOnly
          floatLabel="never"
        >
          <input
            [readonly]="setHours === 'daily' ? true : false"
            [formControl]="group.get(hoursControlName)"
            matInput
            wrOnlyNumber
            maxlength="2"
            max="99"
            min="0"
            type="text"
            class="hide-lock"
          />
        </mat-form-field>
        <div class="hours-week__label">{{'WORK_HOURS.HOURS_PER_WEEK' | translate}}</div>
      </div>

      <mat-radio-group
        [(ngModel)]="setHours"
        [ngModelOptions]="{ standalone: true }"
        (change)="radioChange($event)"
        class="flex-grow-0"
        xmlns="http://www.w3.org/1999/html"
      >
        <mat-radio-button value="daily" class="fw-bold">
          {{ 'SET_HOURS_DAILY' | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <p class="mt-2 mb-3 small ps-4">{{'WORK_HOURS.HOURS_YOU_CAN_WORK_EVERYDAY' | translate}}</p>
      <div *ngIf="setHours === 'daily'">
        <div class="week__days__container">
          <div class="week__days__labels">
            <span class="week__days__labels__days">{{'COMMON.DAYS' | translate}}</span>
            <span class="week__days__labels__times">{{'COMMON.TIMES' | translate}}</span>
          </div>
          <div *ngIf="daysControlName" class="week__days">
            <div *ngFor="let day of daysNames; let i = index" class="week__day">
              <div class="week__day__active-toggle__container">
                <mat-checkbox
                  [formControl]="
                    group.get(daysControlName)?.get(day)?.get('active')
                  "
                  class="button-type"
                >
                  {{ weekDays[i] }}
                </mat-checkbox>
              </div>
              <div class="week__day__timepickers">
                <wr-simple-timepicker
                  [formControl]="
                    group.get(daysControlName)?.get(day)?.get('from')
                  "
                  [minutesInStep]="15"
                  class="week__day__time-from"
                ></wr-simple-timepicker>
              </div>

              <div class="week__day__timepickers">
                <wr-simple-timepicker
                  [formControl]="
                    group.get(daysControlName)?.get(day)?.get('to')
                  "
                  [minutesInStep]="15"
                  class="week__day__time-from"
                ></wr-simple-timepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
