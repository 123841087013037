import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dayjs } from 'dayjs';

import {
  ADD_MINUTE_KEY_CODES,
  SUBTRACT_MINUTE_KEY_CODES,
  TIME_MASK,
  TIME_MASK_PATTERN,
  LONG_TIME_MASK_PATTERN,
  LONG_TIME_MASK,
} from '@app/shared/custom-components/simple-timepicker/simple-timepicker.constants';
import { SimpleTimepickerService } from '@app/shared/custom-components/simple-timepicker/service/simple-timepicker.service';

@Component({
  selector: 'wr-simple-timepicker',
  templateUrl: './simple-timepicker.component.html',
  styleUrls: ['./simple-timepicker.component.scss'],
})
export class SimpleTimepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() minTime: string;
  @Input() hoursRange = 24;
  @Input() minutesInStep = 1;
  @Input() isLongTime = false;
  @Input() allowMaxRangeValue = false;
  @Input() showIcon = false;

  @Output() statusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  private readonly onDestroy$: Subject<null> = new Subject();

  private initialDayjs: Dayjs;

  public formControl: UntypedFormControl;
  public displayTimeForm: UntypedFormGroup = this.fb.group({ time: '' });
  public timeMask;
  public previousDisplayTime: string;
  hours: string[] = this.generateTimeArray(24);
  minutes: string[] = this.generateTimeArray(75, 15, true);
  constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    private readonly fb: UntypedFormBuilder,
    private readonly simpleTimepickerService: SimpleTimepickerService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.formControl = this.ngControl.control as UntypedFormControl;
    if (this.formControl.disabled) {
      this.displayTimeForm.disable();
    }
    this.updateDisplayTime(this.formControl.value);
    this.setValidators();
    this.setTimeMask();
    this.initialDayjs = this.simpleTimepickerService.getCurrentValueDate(this.formControl.value);
    this.watchDisplayTimeChanges();
    this.updateDisplayTimeOnControlChange();
  }

  private setTimeMask(): void {
    this.timeMask = this.isLongTime ? LONG_TIME_MASK : TIME_MASK;
  }

  private setValidators(): void {
    if (this.formControl.validator) {
      this.displayTimeForm.controls.time.setValidators([
        this.formControl.validator,
        Validators.pattern(this.isLongTime ? LONG_TIME_MASK_PATTERN : TIME_MASK_PATTERN),
      ]);
    }
  }

  private updateDisplayTimeOnControlChange(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.updateDisplayTime(value);
    });
  }

  private updateDisplayTime(value: string | number): void {
    this.previousDisplayTime = this.simpleTimepickerService.processTimeToDisplayTime(
      value,
      this.initialDayjs,
      this.minutesInStep,
      this.isLongTime,
      this.formControl,
    );
    this.displayTimeForm.controls.time.patchValue(this.previousDisplayTime, { emitEvent: false });
  }

  private watchDisplayTimeChanges(): void {
    this.displayTimeForm.controls.time.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      const isValid: boolean = (this.isLongTime ? LONG_TIME_MASK_PATTERN : TIME_MASK_PATTERN).test(value);
      if (isValid) {
        const secondsDifference: number =
          this.simpleTimepickerService.getSecondsFromTime(value) -
          this.simpleTimepickerService.getSecondsFromTime(this.previousDisplayTime);
        this.formControl.patchValue(
          this.simpleTimepickerService.countNewControlValueFromDisplayTime(secondsDifference, this.formControl.value),
        );
        this.previousDisplayTime = value;
      }
      this.statusChanged.emit(isValid);
    });
  }

  changeTime(value: number): void {
    let newValue: string | number = this.simpleTimepickerService.countNewControlValueFromDisplayTime(
      60 * value,
      this.formControl.value,
    );
    if (this.minTime && newValue < this.minTime) {
      newValue = this.minTime;
    }
    this.formControl.patchValue(newValue, { emitEvent: false });
    this.updateDisplayTime(newValue);
    this.inputElement.nativeElement.focus();
    const displayTimeLength: number = this.displayTimeForm.controls.time.value.length;
    setTimeout(() => {
      this.inputElement.nativeElement.setSelectionRange(displayTimeLength, displayTimeLength);
      this.inputElement.nativeElement.dispatchEvent(new Event('input'));
    });
  }

  onKeyDown(keyEvent: KeyboardEvent): void {
    if (ADD_MINUTE_KEY_CODES.includes(keyEvent.key)) {
      this.changeTime(this.minutesInStep);
    } else if (SUBTRACT_MINUTE_KEY_CODES.includes(keyEvent.key)) {
      this.changeTime(-this.minutesInStep);
    }
  }
  private generateTimeArray(limit: number, interval: number = 1, startWithZero = false): string[] {
    const options: string[] = [];
    for (let i = 0; i < limit; i += interval) {
      const timeString = (i + (startWithZero ? 0 : 1)).toString().padStart(2, '0');
      options.push(timeString);
    }
    return options;
  }
  writeValue(): void {}

  registerOnChange(): void {}

  registerOnTouched(): void {}

  setDisabledState(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
