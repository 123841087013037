<h4 class="card-details__title" *ngIf="isFirstValue">{{ summaryControl.heading }}</h4>

<div class="card-details__value__container">
  <span class="card-details__value__label">
    {{ summaryQuestions[summaryControl.key] }}
  </span>
  <span class="card-details__value__text" *ngIf="displayValue; else noData">
    {{ displayValue }}
  </span>
</div>

<ng-template #noData>
  <span class="card-details__no-value__text">{{ summaryControl.noValueInfo || '-' }}</span>
</ng-template>
