<wr-generic-report
        [getDocument$]="getDocument$"
        [hasAdditionalStep]="false"
        [documentTypeLabel]="documentTypeLabel | translate"
        [caseType]="data.caseType"
        [caseNumber]="data.caseNumber"
        [employee]="data.caseUser"
        [reportType]="data.type"
        [rowsCount]="18"
        [taskType]="data.type"
        [caseId]="data.caseId"
        [documentId]=""
        (submitForm)="submit($event)"
        (docVersion)="docVersion = $event"
>
    <!--    <ng-container additionalStep>-->
    <!--        <ng-container *ngIf="workabilityIssueSummary">-->
    <!--            <wr-document-summary-->
    <!--                    [documentSummaryData]="workabilityIssueSummary"-->
    <!--                    [documentTypeLabel]="workabilityDocumentTypeLabel"-->
    <!--                    [caseNumber]="data.caseNumber"-->
    <!--                    [employee]="data.caseUser"-->
    <!--                    [userId]="userId"-->
    <!--            >-->
    <!--            </wr-document-summary>-->
    <!--        </ng-container>-->
    <!--    </ng-container>-->
</wr-generic-report>
