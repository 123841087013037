import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { IUserWorkabilityStatus, IUserWorkspaceList } from '@app/shared/user.model';
import {ApiList} from "@app/app.constants";
import {IPaginationData} from "@app/shared/interfaces/ICompanyAccount";
import {CaseIssueResponse} from "@app/shared/cases.model";

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(private httpClient: HttpClient) {}

  fetchUserWorkspaces = (status: string = ''): Promise<IUserWorkspaceList[]> => {
    const statusToFetch = status === '' ? '' : `?status=` + status;
    return this.httpClient
      .get<IUserWorkspaceList[]>(`${environment.baseUrl}${ApiList.userWorkspace._}${statusToFetch}`)
      .toPromise();
  };

  createWorkspace = (payload: any): Observable<any> => {
    return this.httpClient.post('assets/data/accounts/accounts.json', payload);
  };

  fetchWorkspaceById = (workspaceId: string): Observable<any> => {
    return this.httpClient.get(`assets/data/accounts/accounts.json/${workspaceId}`);
  };

  getWorkabilityStatus = (workspaceId: string = ''): Promise<IUserWorkabilityStatus> => {
    const workspaceIdToFetch = workspaceId === '' ? '' : `?id=` + workspaceId;
    return this.httpClient
      .get<IUserWorkabilityStatus>(
        `${environment.baseUrl}${ApiList.userWorkspace.workspaceStatus}${workspaceIdToFetch}`,
      )
      .toPromise();
  };
  getMySelfAssessment(
      paginationData?: IPaginationData,
  ): Promise< {
    data: CaseIssueResponse[],
    count: number
  }> {
    return this.httpClient
        .get<any>(`${environment.baseUrl}${ApiList.cases._}${ApiList.cases._mySelfAssessmentDocuments}`, {
          params: {
            limit: paginationData.limit.toString(),
            skip: paginationData.skip.toString(),
            sort: paginationData.sort,
          },
        })
        .toPromise();
  }
}
