<div class="template-builder flex-grow-1 position-relative overflow-hidden">
  <mat-card class="boxSizing template-builder-core">
    <div class="row mt-3">
      <div class="col-md-4">
        <mat-form-field class="w-100">
          <input matInput placeholder="Page Title" type="text"/>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="w-100">
          <input matInput placeholder="Description" type="text"/>
        </mat-form-field>
      </div>
    </div>
    <div class="template-builder-dropbox d-flex">
      <div class="template-builder-placeholder m-auto">
        <span>Drag and drop the component to build a template</span>
        <div>
          <span> Page Type: {{ templatePageType.pageType }} | Question Type: {{ templatePageType.questionType }}</span>
        </div>
        <div class="template-builder-placeholder-question"></div>
        <div class="template-builder-placeholder-answer"></div>
      </div>
    </div>
  </mat-card>
</div>
