import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HumanizeBooleanPipe } from '@app/shared/pipes/humanize-boolean.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HumanizeBooleanPipe],
  exports: [HumanizeBooleanPipe],
})
export class HumanizeBooleanModule {}
