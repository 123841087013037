import { Component, Input, OnInit } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { FOLLOW_UP_SUMMARY_QUESTIONS } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-follow-up-plan-dates/document-summary-card-details-follow-up-plan-dates.constants';
import {
  FOLLOW_UP_PLAN_DAYS_OPTIONS,
  FOLLOW_UP_PLAN_FREQUENCY_OPTIONS,
} from '@app/shared/components/documents/reports/follow-up-plan/follow-up-plan-dates-control/follow-up-plan-dates-control.constants';

@Component({
  selector: 'wr-document-summary-card-details-follow-up-plan-dates',
  templateUrl: './document-summary-card-details-follow-up-plan-dates.component.html',
  styleUrls: ['./document-summary-card-details-follow-up-plan-dates.component.scss'],
})
export class DocumentSummaryCardDetailsFollowUpPlanDatesComponent implements OnInit {
  @Input() summaryControl: DocumentSummaryData;

  isFirstValue: boolean;
  summaryQuestions = FOLLOW_UP_SUMMARY_QUESTIONS;
  displayValue: string;

  ngOnInit(): void {
    this.checkIsKeyTheFirstOne();
    this.setDisplayValue();
  }

  private checkIsKeyTheFirstOne(): void {
    if (this.summaryControl) {
      this.isFirstValue = (this.summaryControl.key as string) === 'followUpPlanStartDate';
    }
  }

  private setDisplayValue(): void {
    this.displayValue = this.summaryControl.value;
    if (this.summaryControl.key === 'followUpPlanFrequency') {
      this.displayValue =
        FOLLOW_UP_PLAN_FREQUENCY_OPTIONS.find((item) => (item.value = this.summaryControl.value))?.label ||
        this.summaryControl.value;
    }
    if (this.summaryControl.key === 'followUpPlanPrefferedDay') {
      this.displayValue =
        FOLLOW_UP_PLAN_DAYS_OPTIONS.find((item) => (item.value = this.summaryControl.value))?.label ||
        this.summaryControl.value;
    }
  }
}
