<wr-generic-report
  [getDocument$]="getDocument$"
  [hasAdditionalStep]="true"
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  additionalTitle="Standard facilitation"
  (selectedStepChange)="selectedStep = $event"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
>
  <ng-container additionalStep>
    <mat-radio-group [formControl]="selectedOption" class="standard-facilitation__options">
      <mat-radio-button [value]="standardFacilitationOption.NEW"> Create a new standard facilitation </mat-radio-button>
      <mat-radio-button [value]="standardFacilitationOption.EXISTING" [disabled]="true">
        Edit existing facilitation
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
</wr-generic-report>
