import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { NO_SIDENAV_ROUTES } from '@app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageService } from '@app/shared/global-language.service';
import { MessagingService } from '@app/shared/messaging.service';
import { TokenKey } from '@app/shared/common.model';
import { SecurityService } from '@app/core/services/security/security.service';
import { CommonService } from '@app/shared/user/common.service';

@Component({
  selector: 'wr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isSidenavShowed: boolean;
  message;
  isOpen = false;
  @ViewChild('screenshotArea', { static: true }) screen: any;

  constructor(
    private readonly translateService: TranslateService,
    public readonly router: Router,
    private globalLanguageService: GlobalLanguageService,
    private messagingService: MessagingService,
    private securityService: SecurityService,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    //this.messagingService.requestPermission()
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    this.watchRouteChanges();
    this.setAppLanguage();
    this.globalLanguageService.checkAndSetLanguage();
  }

  getIsOpen(): boolean {
    return this.isOpen;
  }
  private watchRouteChanges(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isSidenavShowed = !NO_SIDENAV_ROUTES.some((route) =>
          this.router.url.includes(route),
        );
      });
  }

  private setAppLanguage(): void {
    this.translateService.addLangs(['en', 'nb']);
    this.translateService.setDefaultLang('en');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|nb/) ? browserLang : 'en');
  }
  saveImage(img: string): void {
    if (img && img.length > 0) {
      this.securityService.setSessionItem(TokenKey.SCREENSHOT, img);
      this.isOpen = false;
      this.commonService.openFeedback();
    }
  }
}
