<div
  class="d-flex justify-content-center justify-content-sm-end align-items-center gap-2"
>
  <mat-slider
    [max]="maxValue"
    [min]="minValue"
    [step]="step"
    thumbLabel="true"
    class="w-75"
    [value]="value"
    (change)="onChange($event.value)"
    aria-labelledby="example-name-label"
  >
  </mat-slider>
  <span class="fw-bold text-muted"
  >{{ value }} {{ type === 'percentage' ? '%' : '' }}</span
  >
</div>
