import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TemplateCategoryComponent} from "@app/modules/templates/template-category/template-category.component";
import {TemplatesComponent} from "@app/modules/templates/templates.component";
import {TemplatePageComponent} from "@app/modules/templates/template-page/template-page.component";

const routes: Routes = [
  {
    path: '',
    component: TemplatesComponent,
  },
  {
    path: 'category',
    component: TemplateCategoryComponent,
  },
  {
    path: 'test',
    component: TemplatePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplatesRoutingModule { }
