<form [formGroup]="controlCopy.parent" class="datepicker__form">
  <h3 *ngIf="description" class="datepicker__form-title">
    {{ description }}
  </h3>
  <mat-form-field appearance="fill" class="datepicker__form-field">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input matInput [matDatepicker]="datepicker" [formControl]="controlCopy" />
    <mat-datepicker-toggle matPrefix [disabled]="disabled" [for]="datepicker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" color="primary"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
</form>
