<div class="d-flex justify-content-between align-items-center px-4 mb-3">
  <div class="d-flex align-items-center hide-when-leader">
    <span class="avatar me-1">
        <figure [ngStyle]=""></figure>
        <figcaption *ngIf="taskType && taskType === DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_QUESTIONS; else elseFigCaption">
          {{
          currentUser?.leader?.name  | nameInitials
          }}
        </figcaption>
        <ng-template #elseFigCaption>
        <figcaption>
          {{
          currentUser?.firstName + ' ' + currentUser?.lastName | nameInitials
          }}
        </figcaption>
          </ng-template>
    </span>
    <mat-card-header class="px-2">
      <mat-card-title class="text-dark text-start">
        {{
        taskType && taskType === DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_QUESTIONS ? currentUser?.leader?.name : ( currentUser?.firstName + ' ' + currentUser?.lastName)
        }}</mat-card-title
      >
      <mat-card-subtitle class="mb-0 small text-muted d-flex align-items-center"
        >{{ 'Leader' }}
        <span class="mx-1" style="opacity: 0.2">|</span>
      </mat-card-subtitle>
    </mat-card-header>
  </div>
  <div
    *ngIf="content.required"
    class="px-2 py-0 small rounded-pill bg-danger-light text-danger text-end"
  >
    {{ 'Required' }}
  </div>
</div>
<mat-card-content class="">
  <p class="feed-text">
    {{ content.text }}
  </p>
</mat-card-content>

<ng-container *ngIf="content.comment && content.comment.length > 0">
  <mat-card-actions
    class="border-top px-4 pt-3 mb-0 mt-3"
    *ngFor="let comment of content.comment"
  >
    <div class="d-flex gap-2">
      <span class="avatar">
        <figure
          [ngStyle]="{ 'background-image': 'url(' + null + ')' }"
        ></figure>
        <figcaption>
          {{
            currentUser?.firstName + ' ' + currentUser?.lastName | nameInitials
          }}
        </figcaption>
      </span>
      <div class="d-flex w-100">
        <input
          class="feed-comment mat-input-element-custom"
          matInput
          placeholder="Write a comment"
          type="text"
          [value]="comment"
          readonly
        />
      </div>
    </div>
  </mat-card-actions>
</ng-container>
<mat-card-actions class="border-top px-4 pt-3 mb-0 mt-3">
  <div
    class="d-flex gap-2"
    [ngClass]="{ 'align-items-center': content.type === contentTypes.SHORT }"
  >
    <span class="avatar">
      <figure [ngStyle]="{ 'background-image': 'url(' + null + ')' }"></figure>
      <figcaption>
        {{ currentUser?.firstName + ' ' + currentUser.lastName | nameInitials }}
      </figcaption>
    </span>
    <div class="d-flex w-100">
      <ng-container [ngSwitch]="content.type">
        <ng-container *ngSwitchCase="contentTypes.SHORT">
          <input
            class="feed-comment mat-input-element-custom"
            matInput
            placeholder="Write a comment"
            type="text"
            [(ngModel)]="content.value"
            [readonly]="!fillMode || (fillMode && commentMode)"
            (keyup.enter)="answerFilled.emit()"
            [cdkTrapFocusAutoCapture]="
              !(!fillMode || (fillMode && commentMode))
            "
            [cdkTrapFocusAutoCapture]="
              !(!fillMode || (fillMode && commentMode))
            "
            [cdkTrapFocus]="!(!fillMode || (fillMode && commentMode))"
          />
        </ng-container>

        <ng-container *ngSwitchCase="contentTypes.LONG">
          <textarea
            class="text-area-custom mat-input-element-custom"
            matInput
            placeholder="Write a comment"
            type="text"
            rows="5"
            (keyup.enter)="answerFilled.emit()"
            (keyup)="answerValChanged.emit()"
            [(ngModel)]="content.value"
            [readonly]="!fillMode || (fillMode && commentMode)"
          ></textarea>
        </ng-container>

        <ng-container *ngSwitchCase="contentTypes.CHECKBOX">
          <div class="d-flex gap-3 flex-column">
            <div class="checkbox-custom" *ngFor="let option of content.values">
              <mat-checkbox
                color="primary"
                [checked]="
                  content.multipleValue &&
                  content.multipleValue.includes(option)
                "
                (change)="
                  valueChange(content.multipleValue, option, $event);
                  answerValChanged.emit();
                  answerFilled.emit()
                "
                [disabled]="!fillMode || (fillMode && commentMode)"
                >{{ option }}
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="contentTypes.RADIO_BTN">
          <mat-radio-group
            [(ngModel)]="content.value"
            (change)="answerFilled.emit(); answerValChanged.emit()"
          >
            <mat-radio-button
              class="radio-custom"
              *ngFor="let option of content.values"
              [value]="option"
              [disabled]="!fillMode || (fillMode && commentMode)"
            >
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>
        <ng-container *ngSwitchCase="contentTypes.RATING">
          <div
            class="rating my-auto d-flex gap-2 align-items-center cursor-pointer text-decoration-none"
          >
            <span
              class="fs-4"
              *ngFor="let ratingId of ratingArr; index as i"
              [id]="'star_' + i"
              (click)="
                !fillMode || (fillMode && commentMode)
                  ? content.value
                  : (content.value = i + 1);
                !fillMode || (fillMode && commentMode)
                  ? content.value
                  : (content.value = i + 1);
                answerFilled.emit();
                answerValChanged.emit()
              "
            >
              <img [src]="showIcon(content.value, i)" />
            </span>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <button
      mat-icon-button
      [ngClass]="{ 'mt-auto': content.type === contentTypes.LONG }"
      *ngIf="fillMode && !commentMode"
      (click)="answerFilled.emit(); answerValChanged.emit()"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</mat-card-actions>
<mat-card-actions
  class="border-top px-4 pt-3 mb-0 mt-3"
  *ngIf="commentMode && assignValue"
>
  <div class="d-flex gap-2">
    <span class="avatar">
      <figure [ngStyle]="{ 'background-image': 'url(' + null + ')' }"></figure>
      <figcaption>{{ 'Commenter Name' | nameInitials }}</figcaption>
    </span>
    <div class="d-flex w-100">
      <input
        class="feed-comment mat-input-element-custom"
        matInput
        placeholder="Write a comment"
        type="text"
        [(ngModel)]="content.comment"
      />
    </div>

    <button mat-icon-button class="mt-auto" (click)="answerFilled.emit()">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</mat-card-actions>
