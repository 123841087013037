import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CasesService } from '@app/shared/cases/cases.service';

import {
  DocumentFormConfig,
  DocumentFormConfigControl,
  DocumentFormConfigStep,
} from '@app/modules/forms/models/document-templates.model';
import {
  DocumentAdditionalLabel,
  DocumentsIdentifiers,
  DocumentSummaryData,
  FollowUpDocumentType,
} from '@app/shared/documents.model';
import { DocumentBuilderService } from '@app/shared/document-builder/document-builder.service';
import { Languages } from '@app/shared/common.model';
import { DialogId } from '@app/shared/dialogs.model';
import { CaseDocument, CaseTypes } from '@app/shared/cases.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

@Component({
  selector: 'wr-generic-report',
  templateUrl: './generic-report.component.html',
  styleUrls: ['./generic-report.component.scss'],
})
export class GenericReportComponent implements OnInit, OnDestroy {
  @Input() showHeaderTitle = true;
  @Input() hasAdditionalStep = false;
  @Input() isAdditionalStepSummary = false;
  @Input() hasAdditionalEndStep = false;
  @Input() hasHasCustomTitle = false;
  @Input() additionalLabels: DocumentAdditionalLabel[];
  @Input() additionalTitle: string;
  @Input() caseNumber: string;
  @Input() employee: string;
  @Input() documentTypeLabel: string;
  @Input() caseType: CaseTypes;
  @Input() caseId: string;
  @Input() documentId: string;
  @Input() reportType: DialogId;
  @Input() rowsCount: number;
  @Input() caseEndDate: string;
  @Input() taskType: DocumentsIdentifiers;
  @Input() allowSkip = false;
  @Input() hideStepsIndex: number[] = [];

  @Input()
  get getDocument$(): Observable<DocumentFormConfig> {
    return this._getDocument$;
  }

  set getDocument$(value$: Observable<DocumentFormConfig>) {
    if (value$) {
      this._getDocument$ = value$;
      if (this.isComponentInitialized) {
        this.buildDocumentForm();
      }
    }
  }

  @Output() submitForm = new EventEmitter<
    { [key: string]: string | string[] } | UntypedFormGroup
  >();
  @Output() skipForm = new EventEmitter<boolean>();
  @Output() docVersion = new EventEmitter<number>();
  @Output() selectedStepChange = new EventEmitter<number>();
  caseDocumentData: CaseDocument; // NEED
  private readonly onDestroy$ = new Subject<null>();
  private readonly onOptionChanged$ = new Subject<null>();

  private summarySubscription: Subscription;
  private onStepChangeActions: (() => void)[] = [];
  private _getDocument$: Observable<DocumentFormConfig>;
  private isComponentInitialized = false;

  docTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;
  steps: DocumentFormConfigStep[]; // NEED
  stepsCopy: DocumentFormConfigStep[];
  formGroup: UntypedFormGroup; // NEED
  summary: { [key: string]: DocumentSummaryData }; // NEED
  stepControls: AbstractControl[];
  isSummaryDisabled = false; // TODO: was true
  title: string;
  formConfig: DocumentFormConfig;
  summaryTitle?: string;
  selectedStep = 0;
  selectedLanguage: Languages;
  userId: string;
  uniqueIdentifier: string;

  summarySubscription1: Subscription;
  caseDocumentData1: CaseDocument;
  summary1: { [key: string]: DocumentSummaryData };
  formGroup1: UntypedFormGroup;
  steps1: DocumentFormConfigStep[]; // NEED
  stepControls1: AbstractControl[];

  summarySubscription2: Subscription;
  caseDocumentData2: CaseDocument;
  summary2: { [key: string]: DocumentSummaryData };
  formGroup2: UntypedFormGroup;
  steps2: DocumentFormConfigStep[]; // NEED
  stepControls2: AbstractControl[];

  summarySubscription3: Subscription;
  caseDocumentData3: CaseDocument;
  summary3: { [key: string]: DocumentSummaryData };
  formGroup3: UntypedFormGroup;
  steps3: DocumentFormConfigStep[]; // NEED
  stepControls3: AbstractControl[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: FollowUpDocumentType },
    private readonly documentBuilderService: DocumentBuilderService,
    private readonly casesService: CasesService,
    private readonly translateService: TranslateService,
    private documentTemplatesService: DocumentTemplatesService,
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang as Languages;
    if (this.getDocument$) {
      this.buildDocumentForm();
    }
    this.isComponentInitialized = true;
  }

  private getSickLeaveFormData(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(type)
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup1 = formGroup;
          this.steps1 = workability?.versions[0]?.template?.steps;
          this.stepControls1 = stepControls;
          this.summarySubscription1 = summarySubscription;
          return this.casesService.getCaseDocumentByType(this.caseId, type);
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup1,
          document.fields,
        );
        this.summary1 = (
          this.formGroup1.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData1 = document;
        if (this.formGroup1.value.step0.control1) {
          this.summary1 = this.formGroup1.value.step0.control1;
        }
        this.addSelectOptionsToSummary(this.summary1, this.steps1);
      });
  }
  private getResidualWorkCapacity(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(type)
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup2 = formGroup;
          this.steps2 = workability?.versions[0]?.template?.steps;
          this.stepControls2 = stepControls;
          this.summarySubscription2 = summarySubscription;
          return this.casesService.getCaseDocumentByType(this.caseId, type);
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup2,
          document.fields,
        );
        this.summary2 = (
          this.formGroup2.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData2 = document;
        if (this.formGroup2.value.step0.control1) {
          this.summary2 = this.formGroup2.value.step0.control1;
        }
        this.addSelectOptionsToSummary(this.summary2, this.steps2);
      });
  }

  private getWorkplaceAccommodation(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(type)
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup3 = formGroup;
          this.steps3 = workability?.versions[0]?.template?.steps;
          this.stepControls3 = stepControls;
          this.summarySubscription3 = summarySubscription;
          return this.casesService.getCaseDocumentByType(this.caseId, type);
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup3,
          document.fields,
        );
        this.summary3 = (
          this.formGroup3.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData3 = document;
        if (this.formGroup3.value.step0.control1) {
          this.summary3 = this.formGroup3.value.step0.control1;
        }
        this.addSelectOptionsToSummary(this.summary3, this.steps3);
      });
  }
  private buildDocumentForm(): void {
    this.getDocument$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((formConfig) => {
        this.formConfig = JSON.parse(JSON.stringify(formConfig));
        this.title = formConfig.title
          ? formConfig?.title[this.selectedLanguage]
          : null;
        this.uniqueIdentifier = formConfig.uniqueIdentifier;
        this.summaryTitle = formConfig?.versions[0]?.template?.steps?.find(
          (x) => x.isSummary,
        )?.title[this.selectedLanguage];
        this.docVersion.emit(formConfig.currentVersion);
        this.steps = formConfig?.versions[0]?.template?.steps;
        this.stepsCopy = formConfig?.versions[0]?.template?.steps;
        if (this.hideStepsIndex && this.hideStepsIndex.length > 0) {
          const indexInDescOrder = this.hideStepsIndex.sort((a, b) => b - a);
          indexInDescOrder.forEach((index) => {
            this.steps.splice(index, 1);
          });
        }
        this.addLabelsToControls();
        const {
          formGroup,
          onStepChangeActions,
          summarySubscription,
          stepControls,
        } = this.documentBuilderService.buildDocumentFrom(formConfig);
        this.formGroup = formGroup;

        if (
          this.caseId &&
          this.documentId &&
          this.taskType !== DocumentsIdentifiers.FACILITATION
        ) {
          // TODO: facilitation auto fill should work only after confirmation
          this.casesService
            .getCaseDocument(this.caseId, this.documentId)
            .subscribe((document) => {
              this.caseDocumentData = document;
              this.documentBuilderService.updateFormValue(
                this.formGroup,
                document.fields,
              );
            });
        }
        this.onStepChangeActions = onStepChangeActions;
        this.summarySubscription = summarySubscription;
        this.stepControls = stepControls;
        this.watchSummaryChanges();
        this.removeStepsWithParentKey(); // to remove parent key dependent steps before hand
        this.addSelectOptionsToSummary(this.summary, this.steps);
        const isSickLeaveSummaryNeeded = this.steps.filter(
          (step) => step.isSickLeaveSummary,
        );
        if (isSickLeaveSummaryNeeded.length > 0) {
          this.getSickLeaveFormData(DocumentsIdentifiers.SICK_LEAVE);
        }
        const isResidualWorkSummaryNeeded = this.steps.filter(
          (step) => step.isResidualWorkCapacity,
        );
        if (isResidualWorkSummaryNeeded.length > 0) {
          this.getResidualWorkCapacity(
            DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY,
          );
        }
        const isWorkplaceAccommodationSummaryNeeded = this.steps.filter(
          (step) => step.isWorkplaceAccommodation,
        );
        if (isWorkplaceAccommodationSummaryNeeded.length > 0) {
          this.getWorkplaceAccommodation(
            DocumentsIdentifiers.WORKPLACE_ACCOMMODATION,
          );
        }
      });
  }

  private addSelectOptionsToSummary(summary: any, steps: any): void {
    if (steps && summary) {
      Object.keys(summary).forEach((key, index) => {
        summary[key].selectOptions = steps
          ?.find((step) => step.step === summary[key].step)
          ?.controls?.find(
            (control) => control.key === summary[key].key,
          )?.options?.selectOptions;
      });
    }
  }

  private watchSummaryChanges(): void {
    this.summary = (this.formGroup.get('summary') as UntypedFormGroup)?.getRawValue();
    this.formGroup.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.summary = (
          this.formGroup.get('summary') as UntypedFormGroup
        )?.getRawValue();

        let stepsParentKey = this.steps.map((step) => step.parentKey);
        stepsParentKey = [...new Set(stepsParentKey)];

        const stepsDisplayed = this.steps.map((step) => step.step);

        Object.keys(this.summary).forEach((key, index) => {
          if (!stepsDisplayed.includes(this.summary[key].step)) {
            delete this.summary[key];
          }
        });

        this.addSelectOptionsToSummary(this.summary, this.steps);

        if (this.summary) {
          this.isSummaryDisabled = Object.values(this.summary).every(
            (summaryItem) =>
              !(summaryItem.value?.join && summaryItem.value.length > 0
                ? summaryItem.value.join('')
                : summaryItem.value),
          );
        } else {
          this.isSummaryDisabled = false;
        }
      });
  }

  private addLabelsToControls(): void {
    if (this.steps && this.additionalLabels) {
      this.steps.forEach((step) => {
        if (!step.isSummary) {
          this.additionalLabels.forEach((additionalLabel) => {
            const controlWithQuestionsIndex = step.controls.findIndex(
              (control) => control.type === additionalLabel.controlType,
            );
            if (controlWithQuestionsIndex > -1) {
              (
                step.controls[
                  controlWithQuestionsIndex
                ] as DocumentFormConfigControl
              ).options.label = additionalLabel.label;
            }
          });
        }
      });
    }
  }

  callOnStepChangeActions(index?: number): void {
    if (this.steps && this.steps[index] && this.steps[index].controls[0])
      this.handleValueChange(this.steps[index].controls[0].key as string);

    this.onStepChangeActions.forEach((action) => action());
    if (this.caseType === CaseTypes.TEMPORARY_CHANGE_REQUEST && index === 1) {
      this.handleStepsForTemporaryChange();
    }
    // else if (
    //     this.caseType === CaseTypes.SICK_LEAVE &&
    //     this.taskType && this.taskType === DocumentsIdentifiers.FACILITATION &&
    //     index <= 2
    // ) {
    //     this.handleStepsForTemporaryChange();
    // }
  }

  handleStepsForTemporaryChange(): void {
    const selectedRequests: string[] =
      this.stepControls[0].value.temporaryChangeRequests;

    this.steps = this.stepsCopy.filter((step) => {
      if (!step.key) {
        return true;
      } else if (step.key && selectedRequests.includes(step.key)) {
        return true;
      }
      return false;
    });
  }

  handleValueChange(valueChangedKey: string): void | boolean {
    if (
      this.taskType === DocumentsIdentifiers.FACILITATION &&
      valueChangedKey === 'guidanceForFacilitationLeader'
    ) {
      // control that decides if the facilitation should be prefilled or not
      const stepWithVal = Object.keys(this.formGroup.value).find(
        (key) =>
          key &&
          this.formGroup.value[key] &&
          this.formGroup.value[key][valueChangedKey],
      );
      if (!stepWithVal) return false;
      // const guidanceForFacilitationLeaderVal:  'START_NEW' | 'USE_AND_EDIT_SUGGESTION_FROM_STEP_2B' = this.stepControls.find(ctrl => ctrl && ctrl.value && ctrl.value[valueChangedKey])?.value[valueChangedKey];
      const guidanceForFacilitationLeaderVal:
        | 'START_NEW'
        | 'USE_AND_EDIT_SUGGESTION_FROM_STEP_2B' =
        this.formGroup.value[stepWithVal][valueChangedKey];

      if (
        guidanceForFacilitationLeaderVal ===
        'USE_AND_EDIT_SUGGESTION_FROM_STEP_2B'
      ) {
        this.buildDocumentForm();
        const valToAdd = {
          guidanceForFacilitationLeader: 'USE_AND_EDIT_SUGGESTION_FROM_STEP_2B',
        };
        this.documentBuilderService.updateFormValue(this.formGroup, valToAdd);

        this.casesService
          .getCaseDocument(this.caseId, this.documentId)
          .pipe(takeUntil(this.onOptionChanged$))
          .subscribe((document) => {
            this.caseDocumentData = null;

            this.documentBuilderService.updateFormValue(this.formGroup, {
              ...document.fields,
              ...valToAdd,
            });
          });
      } else if (guidanceForFacilitationLeaderVal === 'START_NEW') {
        this.onOptionChanged$.next();
        this.caseDocumentData = null;
        // const {formGroup} =
        //     this.documentBuilderService.buildDocumentFrom(this.formConfig);
        // // this.formGroup.reset();
        //
        this.buildDocumentForm();

        const valToAdd = {
          guidanceForFacilitationLeader: 'START_NEW',
          hoursWeekSchedule: {
            mon: { from: '08:00', to: '16:00', active: false },
            tue: { from: '08:00', to: '16:00', active: false },
            wed: { from: '08:00', to: '16:00', active: false },
            thu: { from: '08:00', to: '16:00', active: false },
            fri: { from: '08:00', to: '16:00', active: false },
            sat: { from: '08:00', to: '16:00', active: false },
            sun: { from: '08:00', to: '16:00', active: false },
          },
          sickLeaveStatus: 100,
        };
        this.documentBuilderService.updateFormValue(this.formGroup, valToAdd);
      }
    } else {
      let parentKeys = this.stepsCopy
        .filter((step) => step.parentKey)
        .map((step2) => step2.parentKey);
      parentKeys = [...new Set(parentKeys)];
      if (parentKeys.includes(valueChangedKey)) {
        this.steps = JSON.parse(JSON.stringify(this.stepsCopy));
        parentKeys.forEach((key) => {
          const selectedRequest = this.stepControls.find(
            (ctrl) => ctrl.value && ctrl.value[key],
          ).value[key];
          this.steps = this.steps.filter((step) => {
            if (!step.parentKey || step.parentKey !== key) {
              return true;
            } else if (
              typeof selectedRequest[0] === 'object' &&
              step.key &&
              selectedRequest.map((req) => req.key).includes(step.key)
            ) {
              const selectedKeysWithValLess100 = selectedRequest
                .filter((req) => req.value && req.value < 100)
                .map((el) => el.key);
              if (
                selectedKeysWithValLess100.includes(step.key) &&
                step.parentKey === key
              ) {
                return true;
              }
            } else if (
              step.key &&
              selectedRequest.includes(step.key) &&
              step.parentKey === key
            ) {
              return true;
            }
            return false;
          });
        });
      }
    }
  }

  removeStepsWithParentKey(): void {
    this.steps = this.stepsCopy.filter((step) => step && !step.parentKey);
  }

  onSubmit(): void {
    const conditionalTasks = [
      DocumentsIdentifiers.FACILITATION,
      DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY,
      DocumentsIdentifiers.WORKPLACE_ACCOMMODATION,
    ];
    if (this.summary && !conditionalTasks.includes(this.taskType)) {
      // TODO: Could not make summary working for facilitation
      this.submitForm.emit(
        this.documentBuilderService.createSummaryFields(this.summary),
      );
    } else {
      this.submitForm.emit(this.formGroup);
    }
  }

  onSkip(): void {
    this.skipForm.emit(true);
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }

  getTitle(): string {
    return 'Test - ';
  }
}
