<div class="card-details__value__container">
  <mat-icon color="primary">date_range</mat-icon>
  <div class="card-details__label">{{ summaryControl.heading }}:</div>
  <div *ngIf="summaryControl?.value && isValueValid; else noData" class="card-details__value card-details__value__text">
    {{ summaryControl.value }}
  </div>
</div>
<ng-template #noData>
  <span class="card-details__no-value__text">{{ summaryControl.noValueInfo || '-' }}</span>
</ng-template>
