import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wr-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent {
  @Input() textControl: UntypedFormControl;
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() autofocus: boolean;
}
