export enum MODULES {
  ADMINISTRATOR = "m0",
  ACCOUNT = 'm1',
  ROLES = 'm2',
  SUBSCRIPTION = 'm3',
  ARTICLES = 'm4',
  FOLLOW_UP = 'm5',
  TASKS = 'm6',
  TEAMS = 'm7',
  MY_WORKABILITY = 'm8',
  QUESTION_OF_THE_DAY = 'm9',
  EVENTS = 'm10',
  MESSAGE_CENTER = 'm11',
}

export enum FOLLOW_UP_TYPES {
  SELF_CERTIFICATION = 'f1',
  SICK_LEAVE = 'f2',
  OTHER_ISSUE = 'f3',
  TEMPORARY_CHANGE_REQUEST = 'f4',
  WORKABILITY_SELF_ASSESSMENT = 'f5',
  FAMILY_MEDICAL_LEAVE = 'f6',
}

export enum SUB_MODULES {
  CONTACT_PERSON = 's1',
  SUBSCRIPTION = 's2',
  BILLING = 's3',
  WORKSPACE = 's4',
  ADDRESS = 's5',
  POLICY = 's6',
  HEALTH_SERVICE_PROVIDER = 's7',
  USERS = 's8',
  DASHBOARD= 's9'
}

export enum OPERATION {
  LIST = 'o0',
  CREATE = 'o1',
  UPDATE = 'o2',
  DELETE = 'o3',
  VIEW = 'o4',
  HISTORY = 'o5',
  ACCEPT = 'o6',
  CLOSE = 'o7',
  INVITE = 'o8',
  GET_CHILDREN = 'o9',
  SKIP = 'o10',
  TRANSFER = 'o11',
  MODIFY = 'o12',
}

export const PERMISSIONS = {
  ADMINISTRATOR: {
    DASHBOARD : MODULES.ADMINISTRATOR + SUB_MODULES.DASHBOARD
  },
  ACCOUNT: {
    LIST: MODULES.ACCOUNT + OPERATION.LIST,
    VIEW: MODULES.ACCOUNT + OPERATION.VIEW,
    CREATE: MODULES.ACCOUNT + OPERATION.CREATE,
    UPDATE: MODULES.ACCOUNT + OPERATION.UPDATE,
    WORKSPACE: {
      LIST : MODULES.ACCOUNT + SUB_MODULES.WORKSPACE + OPERATION.LIST,
      VIEW : MODULES.ACCOUNT + SUB_MODULES.WORKSPACE + OPERATION.VIEW,
      CREATE : MODULES.ACCOUNT + SUB_MODULES.WORKSPACE + OPERATION.CREATE,
      UPDATE : MODULES.ACCOUNT + SUB_MODULES.WORKSPACE + OPERATION.UPDATE,
      DELETE : MODULES.ACCOUNT + SUB_MODULES.WORKSPACE + OPERATION.DELETE,
    },
    CONTACT_PERSON : {
      LIST : MODULES.ACCOUNT + SUB_MODULES.CONTACT_PERSON + OPERATION.LIST,
      CREATE : MODULES.ACCOUNT + SUB_MODULES.CONTACT_PERSON + OPERATION.CREATE,
      DELETE : MODULES.ACCOUNT + SUB_MODULES.CONTACT_PERSON + OPERATION.DELETE,
    },
    POLICY : {
      VIEW : MODULES.ACCOUNT + SUB_MODULES.POLICY + OPERATION.VIEW,
      UPDATE : MODULES.ACCOUNT + SUB_MODULES.POLICY + OPERATION.UPDATE,
    },
    HEALTH_SERVICE_PROVIDER: {
      VIEW : MODULES.ACCOUNT + SUB_MODULES.HEALTH_SERVICE_PROVIDER + OPERATION.VIEW,
      UPDATE : MODULES.ACCOUNT + SUB_MODULES.HEALTH_SERVICE_PROVIDER + OPERATION.UPDATE,
    },
    USERS: MODULES.ACCOUNT + SUB_MODULES.USERS + OPERATION.VIEW,
  },
  ROLES: {
    LIST: MODULES.ROLES + OPERATION.LIST,
    CREATE: MODULES.ROLES + OPERATION.CREATE,
    UPDATE: MODULES.ROLES + OPERATION.UPDATE,
  },
  SUBSCRIPTION: {
    LIST: MODULES.SUBSCRIPTION + OPERATION.LIST,
    VIEW: MODULES.SUBSCRIPTION + OPERATION.VIEW,
    CREATE: MODULES.SUBSCRIPTION + OPERATION.CREATE,
    UPDATE: MODULES.SUBSCRIPTION + OPERATION.UPDATE,
  },
  ARTICLES: {
    LIST: MODULES.ARTICLES + OPERATION.LIST,
    CREATE: MODULES.ARTICLES + OPERATION.CREATE,
    UPDATE: MODULES.ARTICLES + OPERATION.UPDATE,
  },
  FOLLOW_UP: {
    LIST: MODULES.FOLLOW_UP + OPERATION.LIST,
    CREATE: {
      SELF_CERTIFICATION: MODULES.FOLLOW_UP + OPERATION.CREATE + FOLLOW_UP_TYPES.SELF_CERTIFICATION,
      SICK_LEAVE: MODULES.FOLLOW_UP + OPERATION.CREATE + FOLLOW_UP_TYPES.SICK_LEAVE,
      OTHER_ISSUE: MODULES.FOLLOW_UP + OPERATION.CREATE + FOLLOW_UP_TYPES.OTHER_ISSUE,
      TEMPORARY_CHANGE_REQUEST: MODULES.FOLLOW_UP + OPERATION.CREATE + FOLLOW_UP_TYPES.TEMPORARY_CHANGE_REQUEST,
      WORKABILITY_SELF_ASSESSMENT: MODULES.FOLLOW_UP + OPERATION.CREATE + FOLLOW_UP_TYPES.WORKABILITY_SELF_ASSESSMENT,
      FAMILY_MEDICAL_LEAVE: MODULES.FOLLOW_UP + OPERATION.CREATE + FOLLOW_UP_TYPES.FAMILY_MEDICAL_LEAVE,
    },
  },
  TASKS: MODULES.TASKS,
  TEAMS: {
    LIST: MODULES.TEAMS + OPERATION.LIST,
    VIEW: MODULES.TEAMS + OPERATION.VIEW,
    CREATE: MODULES.TEAMS + OPERATION.CREATE,
    UPDATE: MODULES.TEAMS + OPERATION.UPDATE,
  },
  MY_WORKABILITY: MODULES.MY_WORKABILITY,
  QUESTION_OF_THE_DAY: MODULES.QUESTION_OF_THE_DAY,
  EVENTS: MODULES.EVENTS,
  MESSAGE_CENTER: MODULES.MESSAGE_CENTER,
};
