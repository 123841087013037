import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import {
  DocumentsIdentifiers,
  DocumentStatus,
  DocumentSummaryData,
} from '@app/shared/documents.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { CasesService } from '@app/shared/cases/cases.service';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentBuilderService } from '@app/shared/document-builder/document-builder.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CaseTypes } from '@app/shared/cases.model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DialogId } from '@app/shared/dialogs.model';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

@Component({
  selector: 'wr-follow-up-scheduler',
  templateUrl: './follow-up-scheduler.component.html',
  styleUrls: ['./follow-up-scheduler.component.scss'],
})
export class FollowUpSchedulerComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  private formGroup: UntypedFormGroup;
  private workabilityData: { [key: string]: any };
  // private summaryData: any;
  userId: number;
  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  workabilityIssueSummary: { [key: string]: DocumentSummaryData };
  documentTypeLabel =
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP];

  constructor(
    private readonly casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    private readonly dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      caseEndDate: string;
      type: DocumentTemplateUniqueIdentifier;
    },
  ) {}

  ngOnInit(): void {
    this.getDocumentFormData();
  }

  submit(fields: UntypedFormGroup): void {
    const schedulerData1 = fields.controls.step0.value;
    const schedulerData = schedulerData1.control0;

    this.casesService
      .addDocumentToCase(this.data.caseId, {
        docTemplateUniqueIdentifier: DocumentsIdentifiers.BASIC_FOLLOW_UP,
        docTemplateVersionNumber: this.docVersion,
        ownerId: this.data.userId,
        fields: {
          ...schedulerData,
          ...this.workabilityData,
          ...{ caseEndDate: this.data.caseEndDate },
          ...{ timeZone: new Date().getTimezoneOffset() },
        },
        status: DocumentStatus.COMPLETED,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.BASIC_FOLLOW_UP, {
          dialogResult: true,
        });
      });
  }

  private getDocumentFormData(): void {
    const a = {
      [CaseTypes.WORKABILITY]: DocumentsIdentifiers.WORKABILITY_UPDATE,
      [CaseTypes.EQUIPMENT]: DocumentsIdentifiers.EQUIPMENT,
      [CaseTypes.ISSUE]: DocumentsIdentifiers.ISSUE,
      [CaseTypes.SICK_LEAVE]: DocumentsIdentifiers.WORKABILITY_UPDATE,
      [CaseTypes.SELF_CERTIFICATION]: DocumentsIdentifiers.SELF_CERTIFICATION,
      [CaseTypes.TEMPORARY_CHANGE_REQUEST]:
        DocumentsIdentifiers.TEMPORARY_CHANGE_REQUEST,
      [CaseTypes.FML]: DocumentsIdentifiers.FML,
    };

    this.documentTemplatesService
      .getDocumentFormConfig(a[this.data.caseType])
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup = formGroup;
          this.summarySubscription = summarySubscription;
          const toSendTaskType =
            this.data.type &&
            this.data.type ===
              DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_1
              ? DocumentsIdentifiers.DIALOGUE_MEETING_1
              : DocumentsIdentifiers.BASIC_FOLLOW_UP;
          return this.casesService.getCaseDocTemplate(
            this.data.caseId,
            toSendTaskType,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((value) => {
        this.workabilityData = value.additionalData;
        this.documentBuilderService.updateFormValue(
          this.formGroup,
          value.additionalData,
        );
        this.getDocument$ = of(value.docTemplate);
        this.workabilityIssueSummary = (
          this.formGroup.get('summary') as UntypedFormGroup
        )?.getRawValue();
      });
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }

  selectedStep(pageNumber: number): void {
    if (pageNumber === 2) {
    }
  }

  setsumarryData(data): void {
    // this.summaryData = data;
  }
}
