import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wr-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent {
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() description: string;
  @Input() additionalInfo: string;
}
