<form [formGroup]="formArray.parent" class="multi-button-select__form">
    <!-- <h3 *ngIf="description" class="step-description">
      {{ description }}
    </h3> -->

    <div class="multi-button-select__form__checkbox-buttons__container multi-button-select-custom-big">
            <mat-slide-toggle
                    (change)="onCheckboxChange($event.checked, option.value)"
                    *ngFor="let option of selectOptions; trackBy: trackBy"
                    [checked]="formArray.value.includes(option.value)"
                    labelPosition="before"
            >{{ option.value | translate }}</mat-slide-toggle>
    </div>
</form>

<!-- <div class="d-flex flex-wrap gap-5 justify-content-center">

  <button class="mat-button-square" mat-flat-button>
    <mat-icon>add_task</mat-icon>
    <p>Meeting Only</p>
  </button>
  <button class="mat-button-square" mat-flat-button>
    <mat-icon>sort</mat-icon>
    <p>Meeting Only</p>
  </button>
</div> -->
