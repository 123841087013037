import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuilderRoutingModule } from './builder-routing.module';
import { BuilderContainerComponent } from './builder-container/builder-container.component';
import { MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {SharedModule} from "@app/shared/shared.module";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import { QuestionBuilderComponent } from './builder-container/question-builder/question-builder.component';
import { PageSelectorComponent } from '@app/modules/builder/builder-container/question-builder/page-selector/page-selector.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MainBuilderComponent } from './builder-container/question-builder/main-builder/main-builder.component';

@NgModule({
  declarations: [
    BuilderContainerComponent,
    QuestionBuilderComponent,
    PageSelectorComponent,
    MainBuilderComponent
  ],
  imports: [CommonModule, BuilderRoutingModule, MatRadioModule, MatTabsModule, SharedModule, MatCardModule, MatIconModule, MatIconModule, MatIconModule, MatIconModule, MatIconModule, MatIconModule, MatIconModule, MatMenuModule, MatInputModule, MatButtonModule],
  exports: [
    BuilderContainerComponent
  ]
})
export class BuilderModule {}
