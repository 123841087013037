import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from '../../services/common/permissions.service';
import { UserService } from '@app/shared/user/user.service';
import { userOnboardingInactiveStatus } from '@app/constants/inactive-status.constants';

@Directive({
  selector: '[wrHasPermission]',
})
export class PermissionDirective implements OnInit {
  @Input('wrHasPermission') requiredPermissions: string | string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    const accessData = this.userService.getAuthTokenData();
    console.log(accessData);
    const inactiveStatus = userOnboardingInactiveStatus;
    const isInactive = inactiveStatus.includes(accessData.status);
    const permissions = Array.isArray(this.requiredPermissions)
      ? this.requiredPermissions
      : [this.requiredPermissions];
    if (this.permissionService.hasPermission(permissions) && !isInactive) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
