<wr-generic-dialog
  [showHeaderSubtitle]="false"
  [showFooter]="false"
  [showBreadcrumb]="false"
  class="wr-stepper-dot-style"
>
  <ng-container title
  ><span class="text-primary">
      {{ 'FEEDBACK.CATEGORY.LABEL' | translate }}
    </span></ng-container
  >
  <ng-container content>
    <div class="company_setup_card wa-account-card" *ngIf="categoryAddForm">
      <form [formGroup]="categoryAddForm" class="form_container">
        <p class="mb-4 text--small">
          {{
            'TEMPLATE.TEMPLATE_CATEGORY.ADD.TITLE'| translate
          }}
        </p>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="name"
            [placeholder]="'ENTRY.COMMON.CARD.LABEL_NAME' | translate"
          />
          <mat-error
            *ngIf="categoryAddForm.get('name').hasError('required')"
          >
            {{ 'SETUP.COMPANY.STREET_IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </form>
      <div class="d-flex justify-content-end gap-3">
        <button
          wrButton
          variant="primary"
          [disabled]="categoryAddForm.invalid"
          (click)="save()"
        >
          {{ 'BUTTON.SAVE' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</wr-generic-dialog>
