<form class="textboxes-form" [formGroup]="formArray.parent">
  <!-- <h3 *ngIf="description" class="textboxes-list__form-title">
    {{ description }}
  </h3> -->
  <div class="texboxes-list__container">
    <ng-container *ngFor="let _ of fakeArray; let index = index">
      <div class="item__container">
        <mat-form-field floatLabel="never">
          <input type="text" [formControl]="formArray.at(index)" [placeholder]="placeholder" matInput />
        </mat-form-field>
        <button class="button__remove-item no-background" *ngIf="index > 0" (click)="removeItem(index)" mat-icon-button>
          <img src="assets/images/svg/cross.svg" alt="remove" />
        </button>
      </div>
    </ng-container>
    <div class="add-action-container">
      <button
        mat-button
        class="add-action-container__btn"
        (click)="addNext()"
        [disabled]="this.fakeArray.length >= this.maxArrayLength"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span class="add-action-container__label">{{ addNewItemText }}</span>
    </div>
  </div>
</form>
