import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'wr-calendar-custom-header',
  templateUrl: './calendar-custom-header.component.html',
  styleUrls: ['./calendar-custom-header.component.scss'],
})
export class CalendarCustomHeaderComponent<D> implements OnDestroy {
  // tslint:disable-next-line:typedef
  get periodLabel() {
    return (this._calendar.activeDate as any).toLocaleString('en-us', { month: 'short' });
  }

  private _destroyed = new Subject<void>();

  constructor(private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>, cdr: ChangeDetectorRef) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  // tslint:disable-next-line:typedef
  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  // tslint:disable-next-line:typedef
  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
