import { Component, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IBreadcrumbItems } from '../../custom-components/breadcrumb/breadcrumb.component';
// import {MultiButtonSelectService} from "@app/shared/components/documents/form-controls/multi-button-select/multi-button-select-service/multi-button-select.service";

@Component({
  selector: 'wr-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent {
  @Input() showHeaderTitle = true;
  @Input() showHeaderSubtitle = true;
  @Input() showCloseIcon = true;
  @Input() caseNumber: string;
  @Input() caseType: string;
  @Input() employee: string;
  @Input() documentTypeLabel: string;
  @Input() userId: string;
  @Input() showFooter = true;
  @Input() showBreadcrumb = true;
  @Input() breadCrumData: IBreadcrumbItems[] = undefined;

  value: string;
  currentArrayValue: string;
  currentValue: string;

  constructor(
    private readonly dialogRef: MatDialogRef<GenericDialogComponent>,
  ) {}

  getBreadcrumData(): IBreadcrumbItems[] {
    if (this.breadCrumData && this.breadCrumData.length > 0) {
      return this.breadCrumData;
    } else {
      return [
        {
          title: this.employee,
        },
        {
          title: 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_' + this.caseType,
        },
        { title: this.documentTypeLabel },
      ];
    }
  }

  // , private data: MultiButtonSelectService

  // tslint:disable-next-line:use-lifecycle-interface
  // ngOnInit(): void {
  //   this.data.currentValue.subscribe(value => this.value = value)
  // }

  close(): void {
    this.dialogRef.close(false);
  }
}
