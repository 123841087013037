<wr-generic-dialog [showBreadcrumb]="false">
  <ng-container title>
    {{ 'CONTACT_US.HEADER.TITLE' | translate }}
  </ng-container>
  <ng-container subTitle>
    {{ 'CONTACT_US.HEADER.SUBTITLE' | translate }}
  </ng-container>
  <ng-container content>
    <mat-tab-group class="mat-tab-header-center" animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- PROFILE_PAGE.CONNECTED_COMPANIES.HEADER.TITLE -->
          {{ 'CONTACT_US.TAB.EMAIL' | translate }}
          <div class="ms-2" matBadgeColor="dark" matBadgeOverlap="false"></div>
        </ng-template>
        <div class="custom-dialog-content text-center">
          <div class="mt-5">
            <mat-icon class="fs-1 text-primary mb-3">mail</mat-icon>
          </div>
          <h2 class="text-dark fs-5 mb-0">
            {{ 'CONTACT_US.TEXT.CONTACT_EMAIL' | translate }}
          </h2>
          <a class="text-dark fs-3">
            {{ 'support@workability.no' }}
          </a>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- PROFILE_PAGE.CONNECTED_COMPANIES.HEADER.TITLE -->
          {{ 'CONTACT_US.TAB.PHONE' | translate }}
          <div class="ms-2" matBadgeColor="dark" matBadgeOverlap="false"></div>
        </ng-template>
        <div class="custom-dialog-content">
          <div class="text-center mt-5">
            <mat-icon class="fs-1 text-primary mb-3">support_agent</mat-icon>
          </div>
          <h2 class="text-dark text-center fs-5">
            {{ 'CONTACT_US.TEXT.PHONE' | translate }}
          </h2>
          <h3 class="text-dark text-center fs-3">{{ '+47 4166 8505' }}</h3>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- PROFILE_PAGE.CONNECTED_COMPANIES.HEADER.TITLE -->
          {{ 'CONTACT_US.TAB.CONTACT_US' | translate }}
          <div class="ms-2" matBadgeColor="dark" matBadgeOverlap="false"></div>
        </ng-template>
        <div class="custom-dialog-content pb-4">
          <form
            [formGroup]="contactUsFrom"
            class="form_container w-90 mx-2 mt-2"
          >
            <mat-form-field class="invite__role-select">
              <mat-label>{{
                'CONTACT_US.CATEGORY.LABEL' | translate
              }}</mat-label>
              <mat-select
                formControlName="category"
                panelClass="category-select-dropdown"
              >
                <mat-option
                  *ngFor="let option of categoryList; trackBy: trackBy"
                  [value]="option.value"
                  >{{ option.label | translate }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="contactUsFrom.get('category').hasError('required')"
              >
                {{ 'HELP.CATEGORY.REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="feed-post w-100">
              <mat-label>{{ 'HELP.SUBJECT' | translate }}</mat-label>
              <input formControlName="subject" matInput type="text" />
              <mat-error
                *ngIf="contactUsFrom.get('subject').hasError('required')"
              >
                {{ 'COMMON.ERROR.SUBJECT_IS_REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="feed-post w-100">
              <mat-label>{{
                'CONTACT_US.MESSAGE.LABEL' | translate
              }}</mat-label>
              <textarea
                cdkAutosizeMaxRows="5"
                cdkAutosizeMinRows="5"
                cdkTextareaAutosize
                formControlName="message"
                matInput
              ></textarea>
              <mat-error
                *ngIf="contactUsFrom.get('message').hasError('required')"
              >
                {{ 'HELP.MESSAGE.REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container footer>
    <div class="mt-0">
      <button
        (click)="submit()"
        [disabled]="contactUsFrom.invalid"
        [loading]="loading"
        wrButton
      >
        {{ 'BUTTON.SUBMIT' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
