import { Component, Input, OnInit } from '@angular/core';

// import { DocumentSummaryData } from '@app/shared/documents.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wr-document-summary-card-details-textboxes-with-select-list',
  templateUrl: './textboxes-with-select-list.component.html',
  styleUrls: ['./textboxes-with-select-list.component.scss'],
})
export class DocumentSummaryCardDetailsTextboxesWithSelectListComponent implements OnInit {
  @Input() summaryControl: any;

  inputControlName: string;
  selectControlName: string;
  selectOptionsLabel : { [key: string]: string } = {};

  selectedLanguage: string;

  constructor(private readonly iconsHelperService: IconsHelperService, private readonly translateService: TranslateService) {
    this.iconsHelperService.registerSvgIcons('summary-current');
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;
    this.setControlNames();
  }

  private setControlNames(): void {
    [this.inputControlName, this.selectControlName] = Object.keys(this.summaryControl.value[0]);

    this.summaryControl.selectOptions.forEach(element => {
      this.selectOptionsLabel[element.value] = element.label[this.selectedLanguage];
    });

  }
}
