<wr-generic-report
  [getDocument$]="getDocument$"
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseType]="data.caseType"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  [reportType]="data.type"
  [rowsCount]="18"
  [taskType]="data.type"
  [caseId]="data.caseId"
  [documentId]="data.documentId"
  (submitForm)="submit($event)"
  (skipForm)="skip($event)"
  (docVersion)="docVersion = $event"
  [hasAdditionalStep]="false"
  [isAdditionalStepSummary]="false"
  [allowSkip]="true"
>
  <!-- <ng-container additionalStep>
    <ng-container *ngIf="workabilityIssueSummary">
      <wr-document-summary
        [documentSummaryData]="workabilityIssueSummary"
        [documentTypeLabel]=""
        [caseNumber]="data.caseNumber"
        [employee]="data.caseUser"
        [userId]="userId"
      >
      </wr-document-summary>
    </ng-container>
  </ng-container> -->
</wr-generic-report>
