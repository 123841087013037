<form [formGroup]="formArray.parent" class="multi-button-select__form">
  <!-- <h3 *ngIf="description" class="step-description">
    {{ description }}
  </h3> -->

  <div
    class="mx-auto multi-button-select__form__checkbox-buttons__container multi-button-select-custom-big"
  >
    <mat-checkbox
      class="mat-button mat-button-base mat-button-square"
      *ngFor="let option of selectOptions; trackBy: trackBy"
      [checked]="formArray.value.includes(option.value)"
      (change)="onCheckboxChange($event.checked, option.value)"
      [disabled]="option.disabled"
    >
      <mat-icon>{{ icons[option.value] }}</mat-icon>
      <p>{{ option.label | translate }}</p>
    </mat-checkbox>
  </div>
</form>
