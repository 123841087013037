import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[wrAutoFocus]',
})
export class AutofocusDirective implements AfterContentInit {
  @Input() public wrAutoFocus = true;
  constructor(private el: ElementRef) {}

  public ngAfterContentInit(): void {
    if (this.wrAutoFocus === undefined || this.wrAutoFocus === true) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 500);
    }
  }
}
