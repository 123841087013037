export enum DialogClassName {
  WR_DIALOG_AUTO_SIZE = 'wr-dialog--auto-size',
  WR_SUMMARY_DETAILS = 'wr-summary-details',
  WR_DIALOG_TRANSPARENT = 'wr-dialog-transparent',
  WR_WIDTH_FULL = 'vw-100',
  WR_MAX_WIDTH = 'max-vw-100',
  WR_DIALOG_FORM = 'wr-dialog-form',
  WR_DIALOG_STEPPER_HIDE = 'wr-dialog-stepper-hide',
  // TODO: remove all above clasess

  // New Size for dialog
  WR_DIALOG_FULL = 'wr-dialog-full',
  WR_DIALOG_MEDIUM = 'wr-dialog',
  WR_DIALOG_AUTO = 'wr-dialog-auto',
  WR_DIALOG_SMALL = 'wr-dialog-small',
  WR_DIALOG_HIDE_STEPPER = 'wr-dialog-hide-stepper',
  WR_DIALOG_CONTENT_TOP_NEGATIVE = 'wr-dialog-content-top-n32',
  WR_DIALOG_SHOW_STEPPER_ON_HEADER = 'wr-dialog-show-stepper-onheader',
  WR_DIALOG_BG = 'wr-dialog-bg-light',
  WR_DIALOG_SLIDE = 'wr-dialog-slide',
  WR_DIALOG_MOBILE_MENU = 'wr-dialog-mobile-menu',
  WR_DIALOG_MAX_WIDTH_900 = 'wr-dialog-max-width-900',
}

export enum DialogId {
  REPORT_ISSUE = 'report-issue',
  FACILITATION = 'facilitation',
  FOLLOW_UP_DOCUMENT = 'follow-up-document',
  FOLLOW_UP_DOCUMENT_QUESTIONS = 'follow-up-document-questions',
  FOLLOW_UP_DOCUMENT_MEETING_REVIEW = 'follow-up-document-meeting-review',
  FOLLOW_UP_DOCUMENT_REVIEW = 'follow-up-document-review',
  SICK_LEAVE = 'sick-leave',
  SELF_CERTIFICATION = 'self-certification',
  SELF_ASSESSMENT = 'self-assessment',
  FML = 'fml',
  FOLLOW_UP_PLAN = 'follow-up-plan',
  SUMMARY_CARD_DETAILS = 'summary-card-details',
  TEMPORARY_CHANGE_REQUEST = 'temporary-change-request',
  STANDARD_FACILITATION = 'standard-facilitation',
  ACCESS_SETTINGS = 'access-settings',
  DOCUMENT_AGREEMENT = 'document-agreement',
  EDIT_DOCUMENT = 'edit-document',
  ADD_ACCOUNT = 'add-account',
  UPDATE_ACCOUNT = 'update-account',
  ADD_EDIT_COMPANY_ADDRESS = 'add-edit-company-address',
  ADD_TEMPLATE_CATEGORY = 'ADD_TEMPLATE_CATEGORY',
  TEAMS_ADD_EMPLOYEE = 'teams-add-employee',
  CONTACT_US = 'contact-us',
  FEEDBACK = 'feedback',
  FEEDBACK_OPTION = 'feedback-option',
  BASIC_FOLLOW_UP = 'basic-follow-up',
  BASIC_FOLLOW_UP_CALL = 'basic-follow-up-call-message',
  BASIC_FOLLOW_UP_MESSAGE = 'basic-follow-up-call-message',
  CLOSE_BY_LEADER = 'close-by-leader',
  RESIDUAL_WORK_CAPACITY = 'residual-work-capacity',

  FILL_TEMPLATE_MODAL = 'fill-template-modal',
  FOLLOW_UP_DOCUMENT_MEETING = 'dialogue-meeting-summary-modal',
  CHANGES_TO_TASK_CONFIRMATION = 'changes-to-task-confirmation',
  MOVE_LEADER_SELECT_LEADER = 'select-leader',
  MOVE_TEAM = 'move-team',
  HANLDE_EXISTING_LEADER = 'handle-existing-leader',
  REMOVE_EMPLOYEE = 'remove-employee',
  MANAGE_EMPTY_TEAMSPACE = 'select-leader-for-empty-teamspace',
  MOBILE_MENU_ITEMS = 'mobile-menu-items',
  TEAMSPACE_UPDATE = 'teamspace-update',
  HR_ASSIGN = 'hr-assign',
  WORKPLACE_ACCOMMODATION = 'workplace-accommodations',
  CHANGE_EMAIL_VERIFY_OTP = 'change-email-verify-otp',
  QUESTION_OF_THE_DAY = 'question-of-the-day',
  ADD_SUBSCRIPTION_PACKAGE = 'add-subscription-package',
  UPGRADE_SUBSCRIPTION_PACKAGE = 'upgrade-subscription-package',
  UPGRADE_SUBSCRIPTION_PANEL = 'upgrade-subscription-panel',
  ADD_NEW_ARTICLE = 'add-new-article',
  FEEDBACK_TICKET_DIALOG = 'feedback-ticket-dialog',
  IMG_POPUP = 'image-popup',
  ONBOARDING_TEAM_ADD = 'onboarding-team-add',
  ONBOARDING_SELECT_EXISTING_USER = 'select-existing-user',
  WORKSPACE_UPDATE = 'workspace-update',
  INTEGRATION = 'integration-dialog',
  ASSIGN_SYSTEM_RIGHTS = 'assign-system-rights',
}
