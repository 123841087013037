<div class="navbar-profile-dropdown d-flex flex-column h-100">
  <div class="d-flex align-items-center navbar-profile-name border-bottom pt-0">
    <img
      class="avatar me-3"
      [src]="imgUrl"
      onerror="this.onerror=null;this.src='../../../../../../../assets/images/temp/avatar.png'"
      alt="Profile Picture"
    />
    <div>
      <h5 class="mb-0 text-truncate fw-bold fs-5">
        {{ currentUser?.firstName }} {{ currentUser?.lastName }}
      </h5>
      <p class="text--small mb-0 text-truncate">
        {{ currentUser?.email }}
      </p>
    </div>
  </div>
  <mat-accordion class="mb-4 d-block">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="d-flex flex-wrap gap-2">
            <div class="avatar mt-1">
              <figure
                *ngIf="currentWorkspace?.logo"
                [ngStyle]="{
                  'background-image': 'url(' + currentWorkspace?.logo + ')'
                }"
              ></figure>
              <figcaption class="fs-5 mt-2">
                {{ currentWorkspace?.companyName | nameInitials }}
              </figcaption>
            </div>
            <div style="width: calc(100% - 58px)">
              <h5 class="mb-0 text-truncate fw-bold fs-6">
                {{ currentWorkspace?.companyName }}
              </h5>
              <p class="text--small mb-0 text-truncate">
                {{ currentWorkspace?.jobTitle }}
              </p>
              <span
                class="rounded-pill px-3 text-white py-1 small bg-primary mobile-menu-switch"
                *ngIf="userWorkspaceList && userWorkspaceList.length > 0"
                >Swtich Workspace</span
              >
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div
        class="d-flex flex-wrap gap-2 py-3"
        *ngFor="let workspace of userWorkspaceList"
      >
        <div class="avatar mt-1">
          <figure
            [ngStyle]="{
              'background-image': 'url(' + workspace?.logo + ')'
            }"
          ></figure>
          <figcaption>
            {{ workspace?.companyName | nameInitials }}
          </figcaption>
        </div>
        <div
          class="d-flex flex-wrap align-items-center"
          style="width: calc(100% - 58px)"
        >
          <div style="width: calc(100% - 36px)">
            <h5 class="mb-0 text-truncate fw-bold fs-6">
              {{ workspace?.companyName }}
            </h5>
            <p class="text--small mb-1 text-truncate">
              {{ workspace?.jobTitle }}
            </p>
          </div>
          <mat-icon
            class="ms-2 text-muted"
            style="font-size: 24px"
            (click)="switchWorkspace(workspace._id)"
          >
            swap_horiz
          </mat-icon>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-menu-item (click)="navigate(routeSegments.PROFILE_PAGE)">
    <span>{{ 'MENU.MY_PROFILE.LABEL' | translate }}</span>
  </button>
  <button
    mat-menu-item
    (click)="
      navigate(
        routeSegments.PROFILE_PAGE + '/' + routeSegments.WORKSPACE_PROFILE
      )
    "
  >
    <span>{{ 'MENU.WORKSPACE_PROFILE.LABEL' | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="accessData.isAdmin"
    (click)="navigate(routeSegments.COMPANY_PROFILE)"
  >
    <span>{{ 'MENU.ACCOUNT.LABEL' | translate }}</span>
  </button>
  <button
    mat-menu-item
    (click)="
      navigate(routeSegments.PROFILE_PAGE + '/' + routeSegments.CHANGE_PASSWORD)
    "
  >
    <span>{{ 'MENU.CHANGE_PASSWORD.LABEL' | translate }}</span>
  </button>
  <div class="p-3 mt-auto">
    <button
      class="bg-light border-0 d-block p-3 rounded-2 w-100 fw-bold shadow-sm"
      (click)="onLogoutClick()"
    >
      <span>{{ 'MENU.LOG_OUT.LABEL' | translate }}</span>
    </button>
  </div>
</div>
