import {Component, EventEmitter, Input,  Output} from '@angular/core';

@Component({
  selector: 'wr-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  @Input() type: 'number' | 'percentage' = 'percentage'
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() autofocus: boolean;
  @Input() value: number;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 100;
  @Input() step: number = 10;
  @Output() valueChange = new EventEmitter<number>();




  onChange(val: number) {
    this.value = val;
    this.valueChange.emit(val);
  }
}
