import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

export enum ITaskStatus {
  TODO = 'TODO',
  DONE = 'DONE',
  CREATED = 'CREATED',
}

export enum TaskColor {
  RED = '#ED5656',
  ORANGE = '#FF861C',
  GRAY = '#999999',
  GREEN = '#06e1ca',
}

export interface ITaskRes {
  firstName: string;
  lastName: string;
  ownerWorspaceId: string;
  ownerUserId: string;
  ownerId: string;
  photo: string;
  caseFollowUpType: string;
  _id: string;
  deadline: string;
  type: DocumentTemplateUniqueIdentifier;
  completedDate: string;
  status: ITaskStatus;
  color?: string;
  documentId?: string;
  schedulerId?: string;
  caseId?: string;
  __v?: number;
}

export interface TasksSortOptions {
  sort?: string;
}

export interface ITaskFilterData {
  fromDate: string;
  toDate: string;
  status: ITaskStatus;
  isOnlyMe: boolean;
}
