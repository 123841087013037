import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';
import {
  ICompanySetupReq,
  IInviteResponse,
} from '@app/modules/setup/components/company/company.component';
import {
  IChangePasswordFormData,
  IInviteEmployeeFormData,
  IInviteReqData,
  IInvitesList,
  IJobRolePagination,
  IJobRoleResponse,
  ITeamDropdownRes,
  ITeamList,
  IWorkspaceProfileRes,
  IWorkSpaceUpdateData,
} from './setup.model';
import { UserOnboardingStatus } from '@app/shared/user.model';
import { TokenKey } from '@app/shared/common.model';
import { SecurityService } from '../core/services/security/security.service';
import { ApiList } from '@app/app.constants';
import {
  EChecklistTrackerKey,
  ICheckListTrackerRes,
  ICompanyResponse,
} from '../shared/interfaces/ICompanyAccount';
import { UserService } from '../shared/services/user/user.service';
import { RoleName } from '../shared/constants/role.constants';
import {
  IWorkspaceSetupReq,
  IWorkspaceSetupRes,
} from '../shared/interfaces/IWorkspaceModel';

@Injectable({
  providedIn: 'root',
})
export class SetupService {
  constructor(
    private readonly http: HttpClient,
    private securityService: SecurityService,
    private userService: UserService,
  ) {}

  updateCompanyData = (
    accountDetails: ICompanySetupReq,
    id: string,
    isTask: boolean = false,
  ): Promise<any> => {
    return this.http
      .put<void>(
        `${environment.baseUrl}${ApiList.setup.companies}/${id}?task=${isTask}`,
        accountDetails,
      )
      .toPromise();
  };
  saveCompanyData = (accountDetails: ICompanySetupReq): Promise<any> => {
    return this.http
      .post<void>(
        `${environment.baseUrl}${ApiList.setup.companies}`,
        accountDetails,
      )
      .toPromise();
  };

  getCompanyData = (id: string): Promise<any> => {
    return this.http
      .get<void>(`${environment.baseUrl}${ApiList.setup.companies}/${id}`)
      .toPromise();
  };
  getCompanyInfoFromBrReg = (
    registrationNumber: string,
  ): Promise<ICompanyResponse> => {
    return this.http
      .get<ICompanyResponse>(
        `${environment.baseUrl}${ApiList.setup.companies}/info/${registrationNumber}`,
      )
      .toPromise();
  };
  getCompanyContactPerson = (id: string): Promise<any> => {
    return this.http
      .get<void>(`${environment.baseUrl}${ApiList.setup.contactPerson}/${id}`)
      .toPromise();
  };

  getWorkspaceData = (id: string): Promise<IWorkspaceSetupRes> => {
    return this.http
      .get<IWorkspaceSetupRes>(
        `${environment.baseUrl}${ApiList.setup.workspaces}/${id}`,
      )
      .toPromise();
  };

  getWorkspacePolicies = (): Promise<any> => {
    return this.http
      .get<void>(`${environment.baseUrl}${ApiList.setup.getWorkspacePolicies}`)
      .toPromise();
  };

  updateWorkspaceData = (
    data: IWorkSpaceUpdateData,
    id: string,
  ): Promise<any> => {
    if (data?.status) {
      this.securityService.setSessionItem(
        TokenKey.WORKSPACE_STATUS,
        data?.status,
      );
    }
    return this.http
      .put<void>(
        `${environment.baseUrl}${ApiList.setup.userWorkspace}/${id}`,
        data,
      )
      .toPromise();
  };

  addWorkspaceData = (reqData: IWorkspaceSetupReq): Promise<any> => {
    return this.http
      .post<void>(`${environment.baseUrl}${ApiList.setup.workspaces}`, reqData)
      .toPromise();
  };
  updateWorkspace = (
    id: string,
    reqData: IWorkspaceSetupReq,
    task: boolean = false,
  ): Promise<any> => {
    return this.http
      .put<void>(
        `${environment.baseUrl}${ApiList.setup.workspaces}/${id}?task=${task}`,
        reqData,
      )
      .toPromise();
  };

  // TODO: this needs to be part of the 'addWorkspaceData'
  addHealthServiceData = (reqData: any): Promise<any> => {
    return this.http
      .post<void>(`${environment.baseUrl}api/healthServiceProvider`, reqData)
      .toPromise();
  };

  updateWorkspaceStatus = async (status: UserOnboardingStatus) => {
    const accessData = this.userService.getAuthTokenData();
    const res = await this.updateWorkspaceData(
      { status },
      accessData.userWorkspaceId,
    );
    return res;
  };

  sendInvite = (reqData: IInviteReqData[]): Promise<IInviteResponse[]> => {
    reqData.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (!data[key] || data[key] === '') {
          data[key] = null;
        }
      });
    });
    const finalReq = { users: reqData };
    return this.http
      .post<IInviteResponse[]>(
        `${environment.baseUrl}${ApiList.userWorkspace.invite}`,
        finalReq,
      )
      .toPromise();
  };

  sendMultipleInvite = (
    reqData: IInviteEmployeeFormData[],
    isOnboarding: boolean = false,
  ): Promise<IInviteResponse[]> => {
    reqData.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (!data[key] || data[key] === '') {
          data[key] = null;
        }
      });
    });
    const finalReq = { users: reqData };
    return this.http
      .post<IInviteResponse[]>(
        `${environment.baseUrl}${ApiList.userWorkspace.invite}?isOnboarding=${isOnboarding}`,
        finalReq,
      )
      .toPromise();
  };

  getAllInvites = (): Promise<IInvitesList[]> => {
    return this.http
      .get<IInvitesList[]>(`${environment.baseUrl}${ApiList.setup.invitation}`)
      .toPromise();
  };

  kickoffInvites = (
    reqData: IInviteEmployeeFormData[],
    isOnboarding: boolean = false,
  ): Promise<IInviteResponse[]> => {
    reqData.forEach((data) => {
      Object.keys(data).forEach((key) => {
        if (!data[key] || data[key] === '') {
          data[key] = null;
        }
      });
    });
    const finalReq = { users: reqData };
    return this.http
      .post<IInviteResponse[]>(
        `${environment.baseUrl}${ApiList.setup.inviteAll}?isOnboarding=${isOnboarding}`,
        finalReq,
      )
      .toPromise();
  };

  // TEAMSPACE METHODS
  getUserWorkspaceProfile = (): Promise<IWorkspaceProfileRes> => {
    return this.http
      .get<IWorkspaceProfileRes>(
        `${environment.baseUrl}${ApiList.setup.userWorkspaceProfile}`,
      )
      .toPromise();
  };

  getJobRoles = (pagination: IJobRolePagination): Promise<IJobRoleResponse> => {
    return this.http
      .get<IJobRoleResponse>(
        `${environment.baseUrl}${ApiList.setup.jobRoles}?${this.getParams(
          pagination,
        )}`,
      )
      .toPromise();
  };

  getWorkspaceTeams = (): Promise<ITeamList[]> => {
    return this.http
      .get<ITeamList[]>(
        `${environment.baseUrl}${ApiList.setup.userWorkspaceTeam}`,
      )
      .toPromise();
  };

  changePassword = (data: IChangePasswordFormData) => {
    return this.http
      .put<ITeamList[]>(
        `${environment.baseUrl}${ApiList.users.changePassword}`,
        data,
      )
      .toPromise();
  };

  getWorkspaceStatus = () => {
    const wsStatus = this.securityService.getSessionItem(
      TokenKey.WORKSPACE_STATUS,
    );
    if (wsStatus) {
      return new Promise<{ status: UserOnboardingStatus; roles: RoleName[] }>(
        (resolve, reject) => {
          resolve({ status: wsStatus, roles: undefined });
        },
      );
    } else {
      const data = this.http
        .get<{ status: UserOnboardingStatus; roles: RoleName[] }>(
          `${environment.baseUrl}${ApiList.userWorkspace.status}`,
        )
        .toPromise();
      data.then((res) => {
        this.securityService.setSessionItem(
          TokenKey.WORKSPACE_STATUS,
          res.status,
        );
      });
      return data;
    }
  };

  getDropdownTeams(
    roles: RoleName[] = [],
    self: boolean = false,
  ): Promise<ITeamDropdownRes[]> {
    return this.http
      .post<ITeamDropdownRes[]>(
        `${environment.baseUrl}${ApiList.userWorkspace.dropdownTeam}`,
        { roles, self },
      )
      .toPromise();
  }

  getParams(obj): HttpParams {
    return Object.keys(obj).reduce(
      (params, key) => (obj[key] ? params.append(key, obj[key]) : params),
      new HttpParams(),
    );
  }
  updateChecklistTracker(
    key: EChecklistTrackerKey,
  ): Promise<ICheckListTrackerRes[]> {
    return this.http
      .put<ICheckListTrackerRes[]>(
        `${environment.baseUrl}${ApiList.checklistTracker._}`,
        {
          key,
          status: true,
        },
      )
      .toPromise();
  }
}
