import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address.model';

@Pipe({
  name: 'addressFormat',
})
export class AddressFormatPipe implements PipeTransform {
  constructor() {}

  transform(value?: Address): string {
    if (value === undefined || value === null) return '';
    const array = new Array(value.street, value.zipCode, value.city, value.country);
    return array.filter(Boolean).join(", ");
  }
}
