<form [formGroup]="control.parent" class="percentage_slider__form text-center">
<!--  <h3 *ngIf="description" class="percentage__form-title">-->
<!--    {{ description }}-->
<!--  </h3>-->
<!--  <p *ngIf="label">{{ label }}</p>-->
  <mat-form-field class="textbox__form-field slider-percentage">
    <input type="text" matInput [value]="control.value + '%'" />
  </mat-form-field>
  <div>
    <mat-slider
      class="w-75"
      max="100"
      min="0"
      step="5"
      thumbLabel="true"
      [value]="control.value"
      (change)="control.setValue($event.value)"
      (input)="control.setValue($event.value)"
      aria-labelledby="example-name-label"
      [displayWith]="formatLabel"
    >
    </mat-slider>
  </div>
</form>
