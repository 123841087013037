import { Component, Inject, OnInit } from '@angular/core';
import { ITemplateVersionData } from '@app/modules/content-creator/content-creatorer.model';
import { takeUntil } from 'rxjs/operators';
import { ContentCreatorService } from '@app/shared/content-creator/content-creator.service';
import { Subject } from 'rxjs';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {CaseDocument, CaseTypes} from '@app/shared/cases.model';
import { IBreadcrumbItems } from '@app/shared/custom-components/breadcrumb/breadcrumb.component';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { FillModeComponent } from '@app/modules/content-creator/fill-mode/fill-mode.component';
import { IFollowupData } from '@app/shared/components/scheduler/scheduler.component';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import {CommentModeComponent} from "@app/modules/content-creator/comment-mode/comment-mode.component";
import {TASK_DIALOG_ID} from "@app/modules/case-page/constants/tasks.constants";

@Component({
  selector: 'wr-dialogue-meeting-summary',
  templateUrl: './dialogue-meeting-summary.component.html',
  styleUrls: ['./dialogue-meeting-summary.component.scss'],
})
export class DialogueMeetingSummaryComponent implements OnInit {
  private readonly onDestroy$ = new Subject<null>();
  questionTemplateData: ITemplateVersionData;
  documentTypeLabel = DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING];
  documentTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;

  breadcrumbData: IBreadcrumbItems[] = [
    {
      title: this.data.caseUser,
    },
    {
      title: 'CASES.CASE_PAGE.CASE_DETAILS_CARD.CASE_TYPE_' + this.data.caseType,
    },
    { title: this.documentTypeLabel },
  ];
  summary: IFollowupData;
  caseDocumentData: CaseDocument;

  constructor(
    private readonly contentCreatorService: ContentCreatorService,
    private readonly dialogHelperService: DialogHelperService,
    private casesService: CasesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      documentId: string;
      type: DocumentTemplateUniqueIdentifier;
      deadline: string;
      commentMode: boolean;
      schedulerId: string;
    },
  ) {}

  ngOnInit(): void {
    this.getQuestionTemplates();
    this.getDocumentFormData();
  }

  private getDocumentFormData(): void {
    this.casesService.getCaseDocument(this.data.caseId, this.data.documentId).subscribe((data) => {
      this.summary = data.fields;
      this.caseDocumentData = data;

    }),
      takeUntil(this.onDestroy$);
  }

  getQuestionTemplates(): void {
    this.contentCreatorService
      .getTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        this.questionTemplateData = templates;
      });
  }

  templateFill(): void {
      if (this.data.commentMode) {
        this.dialogHelperService
          .openDialog(DialogId.FILL_TEMPLATE_MODAL, CommentModeComponent, [DialogClassName.WR_DIALOG_MEDIUM], {...this.data}, {disableClose: true}, )
          .afterClosed()
          .subscribe((data) => {
            this.dialogHelperService.closeDialog(TASK_DIALOG_ID[DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW], { dialogResult: true });
          });
      } else {
        this.dialogHelperService
          .openDialog(DialogId.FILL_TEMPLATE_MODAL, FillModeComponent, [DialogClassName.WR_DIALOG_MEDIUM], {...this.data}, {disableClose: true}, )
          .afterClosed()
          .subscribe((data) => {
            this.dialogHelperService.closeDialog(TASK_DIALOG_ID[DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW], { dialogResult: true });
          });
    }
  }

  urlify(text): string {
    if (!text) return '';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => '<a href="' + url + '">' + url + '</a>');
  }
}
