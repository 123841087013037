import {Component, Input} from '@angular/core';
import {DocumentSummaryData} from "@app/shared/documents.model";
import {IconsHelperService} from "@app/core/services/icons-helper/icons-helper.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wr-document-summary-card-details-priority-selector',
  templateUrl: './document-summary-card-details-priority-selector.component.html',
  styleUrls: ['./document-summary-card-details-priority-selector.component.scss']
})
export class DocumentSummaryCardDetailsPrioritySelectorComponent {
  @Input() summaryControl: DocumentSummaryData;
  selectedLanguage = "en";
  constructor(private readonly iconsHelperService: IconsHelperService, private readonly translateService: TranslateService) {
    this.iconsHelperService.registerSvgIcons('summary-current');
    this.selectedLanguage = this.translateService.currentLang;

  }
}
