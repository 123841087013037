<div class="d-flex">
  <div
    mat-menu-item
    class="notification-menu-item d-flex border-bottom"
    (click)="navigateToCase(notification)"
  >
    <mat-icon class="notification-menu-item-icon me-2">{{
      notification.notificationType === 'CASE'
        ? icons[notification.caseType]
        : icons[notification.notificationType]
      }}</mat-icon>
    <div
      class="w-100 d-flex gap-2 flex-column"
      [ngSwitch]="notification.notificationType"
    >
      <div *ngSwitchCase="'CASE'">
        <span class="fw-bold text-dark text-uppercase"
        >{{ 'NOTIFICATION.' + notification?.caseType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? 'You'
            : notification?.user?.name
          }}</b>
        {{'NOTIFICATION.FILED_A_NEW_REPORT.TEXT' | translate}}
        {{
        notification.content.case.startDate &&
        notification.content.case.endDate
          ? 'for '
          : ''
        }}<b>{{ getDateDifference(notification.content.case) }}</b>
      </div>
      <div *ngSwitchCase="'STATUS_UPDATE'">
        <span class="fw-bold text-dark text-uppercase"
        >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? 'You'
            : notification.user.name
          }}</b>
        completed
        <b
        >{{
          'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_' +
          notification.content.nextTask.currentStatus | translate
          }}
        </b>
        <ng-container *ngIf="notification.content.nextTask.nextStatus">
          <mat-icon class="m-0 text-center">arrow_forward</mat-icon> New Status
                                                                     :
          <b>{{
            'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_' +
            notification.content.nextTask.nextStatus | translate
            }}</b>
        </ng-container>
      </div>
      <div *ngSwitchCase="'NEW_FEED'">
        <span class="fw-bold text-dark text-uppercase"
        >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? 'You'
            : notification.user.name
          }}</b>
        {{'NOTIFICATION.ADDED_NEW_POST_TO_FEED.TEXT' | translate}}
      </div>
      <div *ngSwitchCase="'NEW_COMMENT'">
        <span class="fw-bold text-dark text-uppercase"
        >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? 'You'
            : notification.user.name
          }}</b>
        {{'NOTIFICATION.ADDED_COMMENT_TO_FEED.TEXT' | translate}}
      </div>
      <div *ngSwitchCase="NotificationTypeEnum.REMINDER">
        <span class="fw-bold text-dark text-uppercase"
        >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? ('COMMON.YOU' | translate)
            : notification.user.name
          }}</b>
        <span *ngIf="notification.eventName === NotificationEventName.TASKS_EXPIRED">
          {{'NOTIFICATION.TEXT.REMINDER_EXPIRED' | translate}}
        </span>
        <span *ngIf="notification.eventName === NotificationEventName.TASKS_DUE_TODAY">
          {{'NOTIFICATION.TEXT.REMINDER_DUE_TODAY' | translate}}
        </span>
      </div>

      <div
        class="notification-menu-item-avatar d-flex justify-content-end align-items-center gap-1 mt-3"
      >
        <span class="rounded-pill border py-1 px-2 me-1">{{
          'COMMON.DOCUMENTS.' + notification?.caseType + '.NAME' | translate
          }}</span>
        <span class="avatar avatar-sm">
          <figure
            [ngStyle]="{
              'background-image': 'url(' + notification?.user?.photo + ')'
            }"
          ></figure>
          <figcaption>{{ notification?.user?.name | nameInitials }}</figcaption>
        </span>
        <span>{{ notification?.user?.name }}</span>
        <span>&#x2022;</span>
        <span>{{ getCreatedDisplayDate(notification?.createdAt) }}</span>
      </div>
    </div>
  </div>
  <button
    mat-icon-button
    class="heading-close"
    color="dark"
    (click)="close(); $event.stopPropagation()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
