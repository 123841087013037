import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wr-small-textarea-list',
  templateUrl: './small-textarea-list.component.html',
  styleUrls: ['./small-textarea-list.component.scss'],
})
export class SmallTextareaListComponent implements OnInit {
  @Input() labels: string[];
  @Input() placeholder: string;
  @Input() formArray: UntypedFormArray;
  @Input() description: string;

  fakeArray: Array<number>;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createNewArrayItemsToMatchLabels();
    this.generateFakeArray();
  }

  private createNewArrayItemsToMatchLabels(): void {
    if (this.labels) {
      while (this.formArray.length !== this.labels.length) {
        this.formArray.push(this.fb.control('', this.formArray.at(0).validator));
      }
    }
  }

  private generateFakeArray(): void {
    this.fakeArray = new Array(this.formArray.length);
  }
}
