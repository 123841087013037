import { Component, Input } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-document-summary-card-details-percentage',
  templateUrl: './document-summary-card-details-percentage.component.html',
  styleUrls: ['./document-summary-card-details-percentage.component.scss'],
})
export class DocumentSummaryCardDetailsPercentageComponent {
  @Input() summaryControl: DocumentSummaryData;

  constructor(private readonly iconsHelperService: IconsHelperService) {
    this.iconsHelperService.registerSvgIcons('summary-percentage');
  }
}
