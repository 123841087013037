import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import {
  IInviteEmployeeFormConfig,
  IInviteEmployeeFormData,
  IInviteEmployeeFormDataReturn,
  IInviteEmployeeFormSubmit,
} from '@app/modules/setup/setup.model';
import { SetupService } from '../../setup.service';
import { SelectOption } from '@app/shared/common.model';
import { User } from '@app/shared/user.model';
import { UserService } from '@app/shared/user/user.service';
import { NotifyService } from '@app/modules/core/services/notify/notify.service';
import { NotificationColor } from '@app/modules/core/services/notify/notify.model';
import { RoleName } from '@app/modules/shared/constants/role.constants';
import { IUserList } from '@app/modules/shared/interfaces/user.interface';

@Component({
  selector: 'wr-invite-employee-form',
  templateUrl: './invite-employee-form.component.html',
  styleUrls: ['./invite-employee-form.component.scss'],
})
export class InviteEmployeeFormComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  @Input() leaderUserList: IUserList[];
  @Input() hrLeaderUserList: IUserList[];
  @Input() hrId: string;
  @Input() title: string;
  @Input() component: string;
  @Input() config: IInviteEmployeeFormConfig = {
    isNameRequired: true,
    backButton: false,
    autoSave: true,
    isLeaderRequired: true,
  };
  @Input() role?: RoleName[];
  @Input() selectRole = false;
  @Input() buttonText = 'BUTTON.NEXT';
  @Output() formSubmitted = new EventEmitter<IInviteEmployeeFormSubmit>();
  @Output() formDataOnlyAfterSubmit =
    new EventEmitter<IInviteEmployeeFormDataReturn>();
  @Output() backClicked = new EventEmitter<string>();
  @Output() saveAsAdminClicked = new EventEmitter<{
    component: string;
    value: boolean;
  }>();
  filteredUsers: SelectOption<any>[];
  filteredHrUsers: SelectOption<any>[];
  filteredRoles: SelectOption<any>[];

  invitationForm: UntypedFormGroup;
  leaderCtrl = new UntypedFormControl();
  hrContactCtrl = new UntypedFormControl();

  currentUser: User;

  loading = false;
  roleLabel: { [key: string]: string } = {
    [RoleName.SUPER_ADMIN]: 'ROLENAME.0x53AS.LABEL',
    [RoleName.COMPANY_ADMIN]: 'ROLENAME.0x43AC.LABEL',
    [RoleName.WORKSPACE_ADMIN]: 'ROLENAME.0x57AW.LABEL',
    [RoleName.HR]: 'ROLENAME.0x48AH.LABEL',
    [RoleName.LEADER]: 'ROLENAME.0x4CAL.LABEL',
    [RoleName.EMPLOYEE]: 'ROLENAME.0x45AE.LABEL',
    [RoleName.EXTERNAL]: 'ROLENAME.0x45AX.LABEL',
    [RoleName.SUPPORT]: 'ROLENAME.0x45US.LABEL',
  };
  constructor(
    private readonly fb: UntypedFormBuilder,
    private setupService: SetupService,
    private userService: UserService, // private setupService: SetupService,
    private readonly notifyService: NotifyService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildOrganisationInviteForm();
    this.prepareUsersListForDropdown();
    this.currentUser = await this.userService.getCurrentUser().toPromise();
  }

  ngAfterViewInit(): void {
    if (this.trigger) {
      this.trigger.panelClosingActions.subscribe((e) => {
        if (!(e && e.source)) {
          this.leaderCtrl.setValue('');
          this.hrContactCtrl.setValue('');
          this.trigger.closePanel();
        }
      });
    }

    this.resetErrors();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.leaderUserList || changes.hrLeaderUserList) {
      this.buildOrganisationInviteForm();
      this.prepareUsersListForDropdown();
    }
  }

  resetErrors(): void {
    // CAUTION: Done to handle errors showing on load, check for better alternative
    // this.invitationForm.reset();
    Object.keys(this.invitationForm.controls).forEach((key) => {
      this.invitationForm.get(key).setErrors(null);
    });
  }
  prepareUsersListForDropdown(): void {
    this.filteredUsers = this.leaderUserList?.map((el) => ({
      label: el.name,
      value: el.id,
    }));
    this.filteredHrUsers = this.hrLeaderUserList
      ? this.hrLeaderUserList.map((el) => ({ label: el.name, value: el.id }))
      : undefined;
    this.filteredRoles = [
      { label: RoleName.HR, value: RoleName.HR },
      { label: RoleName.EMPLOYEE, value: RoleName.EMPLOYEE },
    ];
  }
  private buildOrganisationInviteForm(): void {
    const isSingleLeader =
      this.config.isLeaderRequired &&
      this.leaderUserList &&
      this.leaderUserList.length === 1
        ? this.leaderUserList[0].id
        : '';
    const isSingleHr =
      this.hrLeaderUserList && this.hrLeaderUserList.length === 1
        ? this.hrLeaderUserList[0].id
        : '';
    const jwtData = this.userService.getAuthTokenData();

    if (this.selectRole) {
      this.role = [RoleName.HR];
    } else if (jwtData.roles.includes(RoleName.SUPPORT)) {
      this.role = [RoleName.SUPPORT];
    } else {
      this.role = this.role ?? [RoleName.EMPLOYEE];
    }
    this.invitationForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.required, Validators.email]],
      parentUserWorkspaceId: [isSingleLeader],
      hrContactId: [isSingleHr || this.hrId],
      userType: [this.role],
      saveAsAdmin: [false],
    });
    if (this.config.isNameRequired) {
      this.invitationForm.get('firstName').setValidators(Validators.required);
      this.invitationForm.get('lastName').setValidators(Validators.required);
    }
    if (
      this.leaderUserList &&
      this.leaderUserList.length > 0 &&
      this.config.isLeaderRequired
    ) {
      this.invitationForm
        .get('parentUserWorkspaceId')
        .setValidators(Validators.required);
    }
    if (this.hrLeaderUserList && this.hrLeaderUserList.length > 0) {
      this.invitationForm.get('hrContactId').setValidators(Validators.required);
    }
  }

  async save(): Promise<void> {
    this.loading = true;
    try {
      let formData: IInviteEmployeeFormData = this.invitationForm.value;

      if (this.checkIfUserInvitedThemself(formData)) return;
      if (this.config.autoSave) {
        await this.setupService
          .sendInvite([formData])
          .then((x) => {
            if (x.length === 1) {
              this.formSubmitted.emit({
                component: this.component,
                data: x[0],
              });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.notifyService.showNotification(
              err.error.message,
              NotificationColor.ERROR,
            );
          });
      } else {
        this.formDataOnlyAfterSubmit.emit({
          component: this.component,
          data: formData,
        });
      }
    } finally {
      this.loading = false;
    }
  }
  checkIfUserInvitedThemself(data: IInviteEmployeeFormData): boolean {
    if (data.email === this.currentUser.email && !data.saveAsAdmin) {
      this.notifyService.showNotification(
        "You can't invite yourself",
        NotificationColor.ERROR,
      );
      return true;
    }
    return false;
  }
  displayValOfLeader(userId): string {
    const selected = this.leaderUserList.find((user) => user.id === userId);
    if (!selected) return '';
    return selected.name;
  }

  displayValOfHr(userId): string {
    const selected = this.hrLeaderUserList.find((user) => user.id === userId);
    if (!selected) return '';
    return selected.name;
  }

  saveAsAdminChange(event): void {
    if (event.checked) {
      this.invitationForm.get('firstName').setValue(this.currentUser.firstName);
      this.invitationForm.get('lastName').setValue(this.currentUser.lastName);
      this.invitationForm.get('email').setValue(this.currentUser.email);
    } else {
      this.invitationForm.get('firstName').setValue('');
      this.invitationForm.get('lastName').setValue('');
      this.invitationForm.get('email').setValue('');
    }
    this.saveAsAdminClicked.emit({
      component: this.component,
      value: event.checked,
    });
  }

  back(): void {
    this.backClicked.emit(this.component);
  }
}
