import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'wr-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() inputLabelOff: string;
  @Input() inputLabelOn: string;
  @Input() value: boolean
  @Output() valueChange = new EventEmitter<any>();

  onChange(checked: boolean) {
    this.value = checked;
    this.valueChange.emit(this.value);
  }

}
