// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // TODO : change it into local environment url
  baseUrl: 'https://api-dev.workability.app/',
  cryptoPassword: 'eJ399!Ydt!2B0&lv2OpirahxZSwB3m$eJafFuiiUO@SEq9jx*$',
  integrationUrl: 'https://tt02.altinn.no/ui/Authentication',
  signicat: {
    url: `https://was.sandbox.signicat.com/auth/open/connect/authorize?client_id=sandbox-square-train-553&response_type=code&scope=openid%20profile%20nin&code_challenge_method=S256&acr_values=idp:nbid&prompt=login`,
  },
  firebase: {
    apiKey: 'AIzaSyC__uyCV3FlhNsJW92VbWdv1_qP35ZD17o',
    authDomain: 'workability-5a2dd.firebaseapp.com',
    projectId: 'workability-5a2dd',
    storageBucket: 'workability-5a2dd.appspot.com',
    messagingSenderId: '944235504700',
    appId: '1:944235504700:web:15397ad1dd30d939470f39',
    vapidKey:
      'BIBzemTmgeTkTBUospPT7yejtfEN1U3YLR094ZGA6_YyFnF7PpXn8NNjzVpRXsYUQO_kgG5t1FxgZ1kulAqBM1s',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
