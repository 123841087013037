<mat-card-content class="review-questionaire">
  <p class="feed-text">Q. {{ content.text }}</p>
  <p class="feed-text">
    A. {{ content.multipleValue && content.multipleValue.length > 0 ? content.multipleValue.join(', ') : content.value  }}
  </p>
</mat-card-content>

<!--<ng-container *ngIf="content.comment && content.comment.length > 0">-->
<!--  <mat-card-actions-->
<!--    class="border-top px-4 pt-3 mb-0 mt-3 review-questionaire-action"-->
<!--    *ngFor="let comment of content.comment"-->
<!--  >-->
<!--    <div class="d-flex gap-2">-->
<!--      <span class="avatar">-->
<!--        <figure-->
<!--          [ngStyle]="{ 'background-image': 'url(' + null + ')' }"-->
<!--        ></figure>-->
<!--        <figcaption>-->
<!--          {{-->
<!--            currentUser?.firstName + ' ' + currentUser?.lastName | nameInitials-->
<!--          }}-->
<!--        </figcaption>-->
<!--      </span>-->
<!--      <div class="d-flex w-100">-->
<!--        <input-->
<!--          class="feed-comment mat-input-element-custom"-->
<!--          matInput-->
<!--          placeholder="Write a comment"-->
<!--          type="text"-->
<!--          [value]="comment"-->
<!--          readonly-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </mat-card-actions>-->
<!--</ng-container>-->
<mat-card-actions
  class="border-top px-4 pt-3 mb-0 mt-3 review-questionaire-action"
  *ngIf="commentMode && assignValue"
>
  <div class="d-flex gap-2">
    <div class="d-flex w-100">
      <input
        class="feed-comment mat-input-element-custom"
        matInput
        placeholder="Write a comment"
        type="text"
        [(ngModel)]="content.comment"
      />
    </div>

    <!-- <button mat-icon-button class="mt-auto" (click)="answerFilled.emit()">
      <mat-icon>send</mat-icon>
    </button> -->
  </div>
</mat-card-actions>
