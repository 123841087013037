<wr-generic-report
    #genericReport
    [getDocument$]="getDocument$"
    [reportType]="data.dialogId"
    [caseType]="caseType"
    [documentTypeLabel]="documentTypeLabel | translate"
    [caseNumber]="data.caseNumber"
    [employee]="data.caseUser"
    (submitForm)="submit($event)"
    (docVersion)="docVersion = $event"
    [hasHasCustomTitle]="true"
    [rowsCount]="18"

    [hasAdditionalStep]="false"
    [documentId]=""
>
    <ng-container title>
        <h3
                *ngIf="genericReport?.steps?.length == genericReport?.selectedStep + 1"
                class="report-issue-title"
                mat-dialog-title
        >
            {{ documentTypeLabel | translate }}
        </h3>
    </ng-container>
</wr-generic-report>
