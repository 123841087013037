import { NativeDateAdapter } from '@angular/material/core';
import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    let dateToReturn = date.toDateString();
    const dayjsDate = dayjs(date);
    if (dayjsDate.isValid()) {
      if (displayFormat === 'input') {
        dateToReturn = dayjsDate.format(SIMPLE_DATE_FORMAT);
      } else {
        dateToReturn = dayjsDate.format(MEDIUM_LENGTH_DATE_FORMAT);
      }
    } else {
      throw new Error('Invalid date');
    }
    return dateToReturn;
  }
}

export const SIMPLE_DATE_FORMAT_REGEXP =
  /((^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01]))$)|((^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4})$)/;
export const SIMPLE_DATE_FORMAT = 'DD/MM/YYYY';
export const SIMPLE_TIME_DATE_FORMAT = 'HH:mm, dd.MM.YYYY';
export const SIMPLE_TIME_FORMAT = 'HH:mm';
export const SIMPLE_BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const MEDIUM_LENGTH_DATE_FORMAT = 'MMM, YYYY';
