<ng-container
  *ngFor="let summaryControl of translatedSummaryControls; trackBy: trackBy"
>
  <ng-container [ngSwitch]="summaryControl.type">
    <ng-container *ngSwitchCase="documentControls.WORKING_HOURS">
      <wr-document-summary-card-details-working-hours
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-working-hours>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.TEXTBOX_WITH_CHIPS">
      <wr-document-summary-card-details-list
        [summaryControl]="summaryControl"
        type="tags"
      >
      </wr-document-summary-card-details-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.TEXTBOXES_LIST">
      <wr-document-summary-card-details-list [summaryControl]="summaryControl">
      </wr-document-summary-card-details-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.MULTI_BUTTON_SELECT">
      <wr-document-summary-card-details-list [summaryControl]="summaryControl">
      </wr-document-summary-card-details-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.SLIDE_TOGGLE_LIST">
      <wr-document-summary-card-details-list [summaryControl]="summaryControl">
      </wr-document-summary-card-details-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.SMALL_TEXTAREA_LIST">
      <wr-document-summary-card-details-list [summaryControl]="summaryControl">
      </wr-document-summary-card-details-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.CUSTOM_DAYS_AND_HOURS">
      <wr-document-summary-card-details-list [summaryControl]="summaryControl">
      </wr-document-summary-card-details-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.DROPDOWN_WITH_PERCENTAGE">
      <wr-document-summary-card-details-dropdown-with-percentage
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-dropdown-with-percentage>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.FOLLOW_UP_PLAN_DATES">
      <wr-document-summary-card-details-follow-up-plan-dates
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-follow-up-plan-dates>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.DATE_RANGE">
      <wr-document-summary-card-details-date-range
        class="test"
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-date-range>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.TEXTBOXES_WITH_SELECT_LIST">
      <wr-document-summary-card-details-textboxes-with-select-list
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-textboxes-with-select-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.PERCENTAGE">
      <wr-document-summary-card-details-percentage
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-percentage>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.PRIORITY_SELECTOR">
      <wr-document-summary-card-details-priority-selector
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-priority-selector>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <wr-document-summary-card-details-default
        [summaryControl]="summaryControl"
      >
      </wr-document-summary-card-details-default>
    </ng-container>
  </ng-container>
</ng-container>
