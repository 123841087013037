<form [formGroup]="formArray.parent" class="multi-button-select__form">
  <!-- <h3 *ngIf="description" class="step-description">
      {{ description }}
    </h3> -->

  <table
    class="multi-button-select__form__checkbox-buttons__container multi-button-select-custom-big m-auto"
  >
    <!--        <mat-slide-toggle-->
    <!--                (change)="onCheckboxChange($event.checked, option.value)"-->
    <!--                *ngFor="let option of selectOptions; trackBy: trackBy"-->
    <!--                [checked]="formArray.value.includes(option.value)"-->
    <!--                labelPosition="before"-->
    <!--        >{{ option.label }}</mat-slide-toggle>-->
    <tr
      class=""
      *ngFor="let option of selectOptions; let i = index; trackBy: trackBy"
    >
      <td>
        <p class="flex-grow-1 mb-n2 mb-sm-0 pe-sm-5 text-center text-sm-left">
          {{ option.label }}
        </p>
      </td>
      <td>
        <div
          class="d-flex justify-content-center justify-content-sm-end align-items-center gap-2"
        >
          <mat-slider
            max="100"
            min="0"
            step="10"
            thumbLabel="true"
            class="w-75"
            [value]="formArray.value[i].value"
            (change)="changeVal($event.value, i, option.value)"
            aria-labelledby="example-name-label"
          >
          </mat-slider>
          <span class="fw-bold text-muted"
            >{{ formArray.value[i].value }}%</span
          >
        </div>
      </td>
    </tr>
  </table>
</form>

<!-- <div class="d-flex flex-wrap gap-5 justify-content-center">

  <button class="mat-button-square" mat-flat-button>
    <mat-icon>add_task</mat-icon>
    <p>Meeting Only</p>
  </button>
  <button class="mat-button-square" mat-flat-button>
    <mat-icon>sort</mat-icon>
    <p>Meeting Only</p>
  </button>
</div> -->
