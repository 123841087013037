<form class="rating__form">
  <h3 *ngIf="description" class="step-description">
    {{ description }}
  </h3>
  <mat-form-field *ngIf="type === 'number'" class="textbox__form-field">
    <input
      name="rating"
      (change)="onChange($event.target.value)"
      (keyup)="onChange($event.target.value)"
      [max]="maxValue"
      [min]="minValue"
      [ngModel]="value"
      [placeholder]="inputLabel"
      [wrAutoFocus]="autofocus"
      matInput
      type="number"
    />
  </mat-form-field>
  <div class="d-flex justify-content-center gap-2 rating">
    <ng-container
      *ngIf="type === 'star'"
    >
          <span
            *ngFor="let ratingId of ratingArr; index as i"
            (click)=" onChange(i + minValue)"
            class="d-flex flex-column gap-1 align-items-center rating-active"
          >
            <img [src]="getRatingSrc(i)"/>
            <span>{{ i + 1 }}</span>
          </span>
    </ng-container>
  </div>
</form>
