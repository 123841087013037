<h1>Test file templates </h1>
<mat-card>
<h3>Example of short answer</h3>
  <wr-short-answer [(value)]="stringTextValue" inputLabel="Input label" ></wr-short-answer>
  Value: {{stringTextValue}}
</mat-card>


<mat-card>
  <h3>Example of multi answer: CHECKBOX</h3>
  <wr-multiple-answer  [(value)]="arrayStringValue" [selectOptions]="testSelectOption" inputLabel="Input label for multi select" ></wr-multiple-answer>
  <h3>Example of multi answer: SELECT</h3>
  <wr-multiple-answer type="select" [(value)]="arrayStringValue" [selectOptions]="testSelectOption" inputLabel="Input label for multi select" ></wr-multiple-answer>
  <h3>Example of multi answer: BUTTON</h3>
  <wr-multiple-answer type="button" [(value)]="arrayStringValue" [selectOptions]="testSelectOption" inputLabel="Input label for multi select" ></wr-multiple-answer>
  Value: {{test(arrayStringValue)}}
</mat-card>

<mat-card>
  <h3>Example of single answer: RADIO BUTTON</h3>
  <wr-single-answer [(value)]="singleStringValue"  [selectOptions]="testSelectOption" inputLabel="Input label for single select"></wr-single-answer>
  <h3>Example of single answer: SELECT</h3>
  <wr-single-answer type="select" [(value)]="singleStringValue" [selectOptions]="testSelectOption" inputLabel="Input label for multi select" ></wr-single-answer>
  <h3>Example of multi answer: BUTTON</h3>
  <wr-single-answer type="button" [(value)]="singleStringValue" [selectOptions]="testSelectOption" inputLabel="Input label for multi select" ></wr-single-answer>
  Value: {{(singleStringValue)}}
</mat-card>

<mat-card>
  <h3>Example of Rating: NUMBER</h3>
  <wr-rating [(value)]="numberTextValue" inputLabel="Rating label" ></wr-rating>

  <h3>Example of Rating: STAR</h3>
  <wr-rating [(value)]="numberTextValue" type='star' inputLabel="Rating label" ></wr-rating>
  Value: {{numberTextValue}}
</mat-card>


<mat-card>
  <h3>Example of Slider: Percentage</h3>
  <wr-slider [(value)]="sliderValue" inputLabel="Rating label" ></wr-slider>

  <h3>Example of Slider: Number</h3>
  <wr-slider type="number" [(value)]="sliderValue" maxValue="250" step="2" inputLabel="Rating label" ></wr-slider>
  Value: {{sliderValue}}


</mat-card>
<mat-card>
  <h3>Example of Toggle</h3>
  <wr-toggle inputLabelOn="Switch on" inputLabelOff="Switch Off" [(value)]="booleanValue"></wr-toggle>

  Value: {{booleanValue.toString()}}

</mat-card>
<mat-card>
  <h3>Example of Date picker: Dropdown</h3>
  <wr-date-picker inputLabel="Example of date picker"  [(value)]="dateValue"></wr-date-picker>

  <h3>Example of Date picker: Calendar</h3>
  <wr-date-picker type='calendar' inputLabel="Example of date picker"  [(value)]="dateValue"></wr-date-picker>
  Value: {{dateValue}}

</mat-card>
<mat-card>
  <h3>Example of Time picker: Without select dropdown</h3>
  <wr-template-time-picker [(value)]="timeValue"></wr-template-time-picker>
  Value: {{timeValue}}

  <h3>Example of Time picker: With select dropdown</h3>
  <wr-template-time-picker [enableDropdown]="true" [(value)]="timeValue"></wr-template-time-picker>
  Value: {{timeValue}}

</mat-card>
<mat-card>
  <h3>Example of Date range picker: Without select dropdown</h3>
  <wr-date-range-picker type="calendar"  [max]="{value: 3, errorMessage: 'Max number of allowed days is 3'}"></wr-date-range-picker>

  <h3>Example of Date range picker: With  dropdown</h3>
  <wr-date-range-picker  [max]="{value: 3, errorMessage: 'Max number of allowed days is 3'}"></wr-date-range-picker>

</mat-card>

<mat-card>
  <h3>WORK HOURS</h3>
  <wr-work-hours description="desc goes here" [(value)]="workHourValue"></wr-work-hours>
  Value: {{test(workHourValue)}}
</mat-card>



<mat-card>
  <h3>Address</h3>
  <wr-address description="desc goes here" [(value)]="addressTextValue" [selectOptions]="testSelectOption"></wr-address>
  Value: {{addressTextValue}}
</mat-card>

<mat-card>
  <h3>Input Chips</h3>
  <wr-input-chips description="desc goes here" [(value)]="chipsValue" ></wr-input-chips>
  Value: {{test(chipsValue)}}
</mat-card>
