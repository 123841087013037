<div class="wizard">
  <ul>
    <li *ngFor="let step of listOfSteps" [ngClass]="{ done: step.status == 'done', active: step.status == 'active' }">
      <div class="wizard-icon">
        <mat-icon *ngIf="step.status !== 'done'">{{ step.icon }}</mat-icon>
        <mat-icon *ngIf="step.status == 'done'">check</mat-icon>
      </div>
      <div class="wizard-text">{{ step.text | translate }}</div>
    </li>
  </ul>
</div>
