import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { IInviteEmployeeFormData } from '@app/modules/setup/setup.model';
import { IInviteResponse } from '@app/modules/setup/components/company/company.component';
import { SetupService } from '@app/modules/setup/setup.service';
import { MatStepper } from '@angular/material/stepper';
import { congratsWelcomeComponentData } from '@app/shared/congratsWelcome.model';
import { User, UserOnboardingStatus } from '@app/shared/user.model';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EChecklistTrackerKey } from '@app/shared/interfaces/ICompanyAccount';
import { RoleName } from '@app/modules/shared/constants/role.constants';
import { UserService } from '@app/modules/shared/services/user/user.service';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationColor } from '@app/core/services/notify/notify.model';
import { NotifyService } from '@app/core/services/notify/notify.service';

@Component({
  selector: 'wr-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class OnboardingComponent implements OnInit {
  congratsWelcomeComponentData: typeof congratsWelcomeComponentData =
    congratsWelcomeComponentData;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;

  formSubmitted = false;

  role = RoleName.EMPLOYEE;

  currentUser: User;
  loading = false;
  preparedInviteList: IInviteEmployeeFormData[] = [];
  titles: { [key: number]: any } = {
    '-1': {
      title: 'ONBOARDING.SELECT_ORGANISATION_STRUCTURE.TITLE',
      subtitle: 'ONBOARDING.UPLOAD_EXCEL.SUBTITLE',
    },
    '0': {
      title: 'ONBOARDING.SELECT_ORGANISATION_STRUCTURE.TITLE',
      subtitle: 'ONBOARDING.SELECT_ORGANISATION_STRUCTURE.SUBTITLE',
    },
    '1': {
      title: 'ONBOARDING.REVIEW_ORGANISATION.TITLE',
      subtitle: 'ONBOARDING.REVIEW_ORGANISATION.SUBTITLE',
    },
  };
  parsedData: IInviteEmployeeFormData[] = [];
  error: string | null = null;
  showExcelImportedData = false;

  displayedColumns = ['firstName', 'lastName', 'email', 'action'];
  dataSource = new MatTableDataSource<any>([]);
  constructor(
    private setupService: SetupService,
    private readonly dialogRef: MatDialogRef<OnboardingComponent>,
    private readonly userService: UserService,
    private readonly notifyService: NotifyService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
  }

  setPreparedData(data: IInviteEmployeeFormData[]): void {
    this.preparedInviteList = data;
  }
  async invitePreparedData(
    preparedInvites: IInviteEmployeeFormData[],
  ): Promise<void> {
    this.loading = true;
    try {
      if (preparedInvites.length > 0) {
        const mergedUsers = this.mergeFormData(preparedInvites);
        const res: IInviteResponse[] =
          await this.setupService.sendMultipleInvite(mergedUsers, true);
        if (res) {
          this.formSubmitted = true;
          this.dialogRef.close({ success: true });
        }
      }
    } finally {
      this.loading = false;
    }
  }

  mergeFormData(users: IInviteEmployeeFormData[]): IInviteEmployeeFormData[] {
    const mergedUsers: { [key: string]: IInviteEmployeeFormData } = {};

    users.forEach((user) => {
      const { email, userType, tier, ...rest } = user;
      if (!mergedUsers[email]) {
        mergedUsers[email] = {
          ...rest,
          email,
          userType: [...userType],
          tier: tier ?? [],
        };
      } else {
        mergedUsers[email].userType = [
          ...new Set([...mergedUsers[email].userType, ...userType]),
        ];
        mergedUsers[email].tier = [
          ...new Set([...(mergedUsers[email].tier || []), ...(tier || [])]),
        ];
      }
    });
    return Object.values(mergedUsers);
    // const data = Object.values(mergedUsers);
    // const dataEmail = data.map(x=>x.email);
    // const excelData = this.parsedData.filter(x=>!dataEmail.includes(x.email));
    // excelData.forEach(element => {
    //   element.tier =
    // });
  }

  async updateWorkspaceStatus(): Promise<void> {
    await this.setupService.updateWorkspaceStatus(UserOnboardingStatus.ACTIVE);
    await this.setupService.updateChecklistTracker(
      EChecklistTrackerKey.ORGANISATION_SETUP,
    );
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  runStepAction(): void {
    if (this.showExcelImportedData && this.stepper.selectedIndex === 0) {
      this.showExcelImportedData = false;
    } else {
      this.stepper.next();
    }
  }

  validateOrgSetup(): boolean {
    if (this.showExcelImportedData && this.stepper.selectedIndex === 0) {
      return true;
    }
    if (this.preparedInviteList.length > 1) {
      const leaders = this.preparedInviteList.filter((x) =>
        x.userType.includes(RoleName.WORKSPACE_ADMIN),
      );
      const hrs = this.preparedInviteList.filter((x) =>
        x.userType.includes(RoleName.HR),
      );
      if (leaders && leaders.length > 0 && hrs && hrs.length > 0) {
        return true;
      }
    }
    return false;
  }

  initiateExcelImport() {
    this.showExcelImportedData = true;
    setTimeout(() => {
      this.stepper.previous();
    }, 250);
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.onFileChange(files[0]);
    }
  }
  openFileInput() {
    // Programmatically trigger click event on the file input
    document.getElementById('fileInput')?.click();
  }
  onFileChange(evefilent: any) {
    const file = evefilent;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      this.parseExcel(worksheet);
    };

    reader.readAsArrayBuffer(file);
  }
  parseExcel(worksheet: XLSX.WorkSheet) {
    let headerRow: Array<any> = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
    })[0] as Array<any>;
    console.log(headerRow);
    headerRow = headerRow.map((str) => str.trim().toLowerCase());
    console.log(headerRow);

    // Check if required columns are present
    if (
      !headerRow.includes('firstname') ||
      !headerRow.includes('lastname') ||
      !headerRow.includes('email')
    ) {
      this.error = 'ERROR.IMPORT_EXCEL_FORMAT.MESSAGE';
      this.notifyService.showNotification(this.error, NotificationColor.ERROR);
      this.fileInputRef.nativeElement.value = '';
      return;
    }

    // Parse data, skipping the first row (header)
    const data = XLSX.utils.sheet_to_json(worksheet, {
      header: 'A',
      defval: '',
      range: 1,
    });

    // Convert data format to { firstName: 'value', lastName: 'value', email: 'value' }
    this.parsedData = data.map((row: any) => ({
      firstName: row['A']?.trim(),
      lastName: row['B']?.trim(),
      email: row['C']?.trim(),
      tier: [5],
      userType: [RoleName.EMPLOYEE],
    }));
    this.dataSource = new MatTableDataSource<any>(this.parsedData);
    const existingEmails = this.preparedInviteList.map((x) => x.email);
    const filteredList = this.parsedData.filter(
      (x) => !existingEmails.includes(x.email),
    );
    const finalData = [...this.preparedInviteList, ...filteredList];
    this.preparedInviteList = finalData;
    console.log(this.preparedInviteList);
    this.showExcelImportedData = true;
    setTimeout(() => {
      this.stepper.previous();
    }, 250);
    // Reset the file input
    this.fileInputRef.nativeElement.value = '';

    this.error = null;
  }

  deleteParsedData(email: string) {
    const existingIndex = this.parsedData.findIndex(
      (data) => data.email === email,
    );
    this.parsedData.splice(existingIndex, 1);
    this.dataSource = new MatTableDataSource<any>(this.parsedData);
    this.preparedInviteList = this.preparedInviteList.filter(
      (data) => data.email != email,
    );
  }
}
