<form class="follow-up-plan-dates__form" [formGroup]="group">
  <div class="follow-up-plan-dates__labels">
    <span class="follow-up-plan-dates__labels__start-date"> Start date: </span>

    <span class="follow-up-plan-dates__labels__weeks"> How many weeks will follow-up continue? </span>

    <span class="follow-up-plan-dates__labels__frequency"> How ofter would you like to follow-up? </span>

    <span class="follow-up-plan-dates__labels__day-and-time"> Preferred days & times for follow-up? </span>

    <span class="follow-up-plan-dates__labels__end-date"> End date: </span>
  </div>

  <div class="follow-up-plan-dates__controls">
    <wr-datepicker
      [control]="group.get('followUpPlanStartDate')"
      class="follow-up-plan-dates__controls__start-date"
    ></wr-datepicker>

    <mat-form-field class="follow-up-plan-dates__controls__weeks">
      <mat-select formControlName="followUpPlanWeeks">
        <mat-option *ngFor="let option of weekOptions; trackBy: trackByWeek" [value]="option">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="follow-up-plan-dates__controls__frequency">
      <mat-select formControlName="followUpPlanFrequency">
        <mat-option *ngFor="let option of frequencyOptions; trackBy: trackByFrequency" [value]="option.value">{{
          option.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="follow-up-plan-dates__controls__day-and-time">
      <mat-form-field class="follow-up-plan-dates__day-and-time__preferred-day">
        <mat-select formControlName="followUpPlanPrefferedDay">
          <mat-option *ngFor="let option of daysOptions; trackBy: trackByDay" [value]="option.value">{{
            option.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="follow-up-plan-dates__day-and-time__preferred-time">
        <mat-select formControlName="followUpPlanPrefferedTime">
          <mat-option *ngFor="let option of timeOptions; trackBy: trackByTime" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <wr-datepicker
      [control]="group.get('followUpPlanEndDate')"
      [disabled]="true"
      class="follow-up-plan-dates__controls__end-date"
    ></wr-datepicker>
  </div>
</form>
