import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wr-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss'],
})
export class PercentageComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  @Input() control: UntypedFormControl;
  @Input() description: string;
  @Input() label: string;

  ngOnInit(): void {
    this.watchControlChanges();
  }

  private watchControlChanges(): void {
    this.control.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.control.patchValue(this.changeNumberToBeInRange(value));
    });
  }

  private changeNumberToBeInRange(value: number): number {
    let numberToReturn = Math.floor(value);
    if (numberToReturn < 0) {
      numberToReturn = 0;
    }
    if (numberToReturn > 100) {
      numberToReturn = 100;
    }
    return numberToReturn;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
