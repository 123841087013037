<ng-container *ngIf="control">
  <ng-container [ngSwitch]="documentControl">
    <ng-container *ngSwitchCase="documentControls.TEXTAREA">
      <wr-textarea
              [textControl]="control"
              [description]="description"
              [placeholder]="placeholder"
              [label]="label"
              [rowsCount]="rowsCount"
              [autofocus]="autofocus"
              [defaultValue]="defaultValue"
      ></wr-textarea>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.TEXTBOXES_LIST">
      <wr-textboxes-list
        [formArray]="control"
        [placeholder]="placeholder"
        [description]="description"
        [addNewItemText]="addNewItemText"
      >
      </wr-textboxes-list>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.DROPDOWN_WITH_PERCENTAGE">
      <wr-dropdown-with-percentage
        [formArray]="control"
        [addNewItemLabel]="addNewItemText"
        [description]="description"
        [selectOptions]="translatedOptions"
      >
      </wr-dropdown-with-percentage>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.TEXTBOX_WITH_CHIPS">

      <wr-textbox-with-chips
        [formArray]="control"
        [description]="description"
        [inputLabel]="label"
        [availableOptions]="translatedOptions"
        [autofocus]="autofocus"
        [color]="color"
      >
      </wr-textbox-with-chips>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.TEXTBOX">
      <wr-location-textbox [textControl]="control" [description]="description" [inputLabel]="label" [autofocus]="autofocus">
      </wr-location-textbox>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.LOCATION">
      <wr-location-textbox [textControl]="control" [description]="description" [inputLabel]="label" [autofocus]="autofocus">
      </wr-location-textbox>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.WORKING_HOURS">
      <wr-working-hours [group]="control" [description]="description"> </wr-working-hours>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.DATEPICKER">
      <wr-datepicker [control]="control" [description]="description" [label]="label"> </wr-datepicker>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.QUESTION_WITH_YES_NO_ANSWER">
      <wr-question-with-yes-no-answer [control]="control" [description]="description" [label]="label">
      </wr-question-with-yes-no-answer>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.SMALL_TEXTAREA_LIST">
      <wr-small-textarea-list
        [formArray]="control"
        [placeholder]="placeholder"
        [description]="description"
        [labels]="translatedLabels"
      >
      </wr-small-textarea-list>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.FOLLOW_UP_PLAN_DATES">
      <wr-follow-up-plan-dates-control [group]="control"> </wr-follow-up-plan-dates-control>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.SINGLE_BUTTON_SELECT">
      <wr-single-button-select [control]="control" [description]="description" [selectOptions]="translatedOptions">
      </wr-single-button-select>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.DATE_RANGE">
      <wr-date-range [group]="control" [description]="description" [max]="max" [uniqueIdentifier]="uniqueIdentifier">
      </wr-date-range>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.SELECT">
      <wr-select
        [control]="control"
        [label]="label"
        [additionalInfo]="additionalInfo"
        [description]="description"
        [selectOptions]="translatedOptions"
      >
      </wr-select>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.SLIDE_TOGGLE">
      <wr-slide-toggle
        [control]="control"
        [label]="label"
        [additionalInfo]="additionalInfo"
        [description]="description"
      >
      </wr-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.MULTI_BUTTON_SELECT">
      <wr-multi-button-select [formArray]="control" [selectOptions]="translatedOptions" [description]="description" (valueChange)="valueChange.emit(true)">
      </wr-multi-button-select>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.SLIDE_TOGGLE_LIST">
      <wr-slide-toogle-list [formArray]="control" [selectOptions]="translatedOptions" [description]="description" (valueChange)="valueChange.emit(true)">
      </wr-slide-toogle-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.PERCENTAGE_SLIDER_LIST">
      <wr-percentage-slider-list [formArray]="control" [selectOptions]="translatedOptions" [description]="description" (valueChange)="valueChange.emit(true)">
      </wr-percentage-slider-list>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.TEXTBOXES_WITH_SELECT_LIST">
      <wr-textboxes-with-select-list
        [formArray]="control"
        [selectOptions]="translatedOptions"
        [description]="description"
        [addNewItemText]="addNewItemText"
        [placeholder]="placeholder"
      >
      </wr-textboxes-with-select-list>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.HORIZONTAL_BUTTON_RADIO">
      <wr-horizontal-button-radio [control]="control" [description]="description" [label]="label" [size]="size" [selectOptions]="translatedOptions" (valueChange)="valueChange.emit(true)">
      </wr-horizontal-button-radio>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.PERCENTAGE">
      <wr-percentage [control]="control" [description]="description" [label]="label"> </wr-percentage>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.PERCENTAGE_SLIDER">
      <wr-percentage-slider [control]="control" [description]="description" [label]="label"> </wr-percentage-slider>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.PRIORITY_SELECTOR">
      <wr-priority-selector [formArray]="control" [selectOptions]="translatedOptions" [description]="description">
      </wr-priority-selector>
    </ng-container>

    <ng-container *ngSwitchCase="documentControls.CUSTOM_DAYS_AND_HOURS">
      <wr-custom-days-and-hours
        [formArray]="control"
        [description]="description"
        [inputLabel]="label"
        [availableOptions]="translatedOptions"
      >
      </wr-custom-days-and-hours>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.BASIC_SCHEDULER">
      <wr-scheduler [group]="control" [caseEndDate]="caseEndDate"></wr-scheduler>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.DIALOGUE_MEETING_SCHEDULER">
      <wr-scheduler [group]="control" [caseEndDate]="caseEndDate" [disableRepeat]="true"></wr-scheduler>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.DIALOGUE_MEETING_SCHEDULER">
      <wr-scheduler  [group]="control" [caseEndDate]="caseEndDate" [disableRepeat]="true"
      ></wr-scheduler>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.DIALOGUE_MEETING_SCHEDULER">
      <wr-scheduler  [group]="control" [caseEndDate]="caseEndDate" [disableRepeat]="true"
      ></wr-scheduler>
    </ng-container>
    <ng-container *ngSwitchCase="documentControls.BASIC_SCHEDULER_SUMMARY">
      <wr-scheduler-summary [schedulerData]="prevStepData.controls.control0.value"> </wr-scheduler-summary>
    </ng-container>

    <ng-container *ngSwitchDefault> Unknown document control - {{ documentControl }} </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="!control">
  <ng-container [ngSwitch]="documentControl">
    <ng-container *ngSwitchCase="documentControls.PANEL">
      <wr-panel
              [description]="controlDesc[selectedLanguage]"
              [title]="title[selectedLanguage]"
              [icon]="icon"
              [panelSteps]="panelSteps"
      ></wr-panel>
    </ng-container>
  </ng-container>
</ng-container>