import { Component, Input, OnInit } from '@angular/core';
import { EmptyListIcons } from '../../constants/empty-list.constants';

@Component({
  selector: 'wr-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent implements OnInit {
  @Input() icon: EmptyListIcons = EmptyListIcons.DEFAULT;
  @Input() message = 'No data found';
  constructor() {}

  ngOnInit(): void {}
}
