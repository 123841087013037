import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() control: UntypedFormControl;
  @Input() selectOptions: SelectOption<string>[];
  @Input() description: string;
  @Input() label: string;
  @Input() additionalInfo: string;
}
