import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'customDate',
  pure: false,
})
export class CustomDatePipe implements PipeTransform {
  private currentLocale: string;

  constructor(
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.currentLocale = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLocale = event.lang;
    });
  }

  transform(value: any, format: string = 'MMM dd, YYYY'): string {
    if (value) {
      this.changeDetector.markForCheck(); // Trigger change detection
      return new DatePipe(this.currentLocale).transform(value, format);
    } else {
      return '-'; // Handle null or invalid date values
    }
  }
}
