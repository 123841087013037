import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DocumentsIdentifiers, ReportIssueChoices } from '@app/shared/documents.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { CaseTypes, ICheckIfCaseExistsResponse } from '@app/shared/cases.model';
import { DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { DOCUMENTS_TYPE_LABELS } from '@app/modules/shared/constants/documents.constants';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { RouteSegments } from '@app/shared/routing.model';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
})
export class ReportIssueComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  docVersion: number;
  reportIssueChoices: typeof ReportIssueChoices = ReportIssueChoices;
  selectedIssueToReport: ReportIssueChoices;
  selectedStep = 0;
  getDocument$: Observable<DocumentFormConfig>;
  documentTypeLabel = 'New'; // DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.WORKABILITY];
  existingCaseId: string | null = null;
  constructor(
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly casesService: CasesService,
    private readonly dialogHelperService: DialogHelperService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseNumber: string;
      caseUser: string;
      userId: string;
      caseType: CaseTypes;
    },
  ) {}

  ngOnInit(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(DocumentsIdentifiers.ISSUE);
    this.documentTypeLabel = DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.ISSUE];
    return;
    if (this.data.caseType === CaseTypes.SICK_LEAVE) {
      this.onIssueSelectionChange(DocumentsIdentifiers[this.reportIssueChoices.WORKABILITY_UPDATE]);
      this.selectedStep = 1;
    }
  }

  async onIssueSelectionChange(document: DocumentsIdentifiers): Promise<void> {
    this.selectedIssueToReport = document as string as ReportIssueChoices;

    let proceedToExistingCase: SweetAlertResult | undefined;
    if (
      this.data.caseType !== CaseTypes.SICK_LEAVE &&
      this.selectedIssueToReport === ReportIssueChoices.WORKABILITY_UPDATE
    ) {
      const doesCaseExist = await this.checkIfCaseExists(CaseTypes.WORKABILITY);
      if (doesCaseExist && doesCaseExist.exists) {
        proceedToExistingCase = await this.proceedToExistingCase();
      }
      if (proceedToExistingCase.isConfirmed && doesCaseExist.caseId) {
        this.dialogHelperService.closeDialog(DialogId.REPORT_ISSUE, { dialogResult: true });
        this.routeToCaseDetail(doesCaseExist.caseId);
      } else if (doesCaseExist.caseId) {
        this.existingCaseId = doesCaseExist.caseId;
      }
    } else {
      this.existingCaseId = null;
    }

    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(document);
    this.documentTypeLabel = DOCUMENTS_TYPE_LABELS[document];
  }

  proceedToExistingCase(): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'You already have an existing case. New case will be added to the feed. How would you like to proceed?',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: 'Take me to existing case',
      denyButtonText: `Send the new update to feed`,
      confirmButtonColor: '#00c7b2',
    });
  }

  routeToCaseDetail(caseId: string): void {
    this.router.navigate([`${RouteSegments.CASES}/${caseId}`]);
  }

  async checkIfCaseExists(caseType: CaseTypes): Promise<ICheckIfCaseExistsResponse> {
    const doesCaseExist = await this.casesService.checkIfCaseExists(caseType).toPromise();
    return doesCaseExist;
  }

  submit(fields: { [key: string]: string | string[] }): void {
        this.casesService
      .createCase({
        documents: [
          {
            docTemplateUniqueIdentifier: DocumentsIdentifiers.ISSUE,
            docTemplateVersionNumber: this.docVersion,
            ownerId: this.data.userId,
            fields,
          },
        ],
        type: CaseTypes.ISSUE,
        caseId: this.existingCaseId ? this.existingCaseId : '',
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.dialogHelperService.closeDialog(DialogId.REPORT_ISSUE, { dialogResult: true });
          this.redirectToCases(data._id);
        },
        (error) => {
          // TODO : add error handling
          console.error(error);
        },
      );
  }

  redirectToCases(id: string): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`${RouteSegments.CASES}/${id}`]);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
