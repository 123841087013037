import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wr-question-with-yes-no-answer',
  templateUrl: './question-with-yes-no-answer.component.html',
  styleUrls: ['./question-with-yes-no-answer.component.scss'],
})
export class QuestionWithYesNoAnswerComponent {
  @Input() control: UntypedFormControl = new UntypedFormControl(false);
  @Input() description: string;
  @Input() label: string;
}
