import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
})
export class SelectWithSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: UntypedFormControl = new UntypedFormControl('');
  @Input() options: SelectOption<any>[];
  @Input() placeholder = 'Select here';
  @Input() fieldName = '';
  @Input() readonly = false;
  @Input() multiple = false;
  @Output() valueSelect = new EventEmitter<any>();
  @Input() floatLabel = 'auto';
  @Input() searchEnabled = true;
  @Input() emptyValue?: string;

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  private readonly onDestroy$ = new Subject<null>();

  filteredOptions: SelectOption<any>[] = [];
  searchControl: UntypedFormControl = new UntypedFormControl();

  ngOnInit(): void {
    this.watchSearchChanges();
    this.filteredOptions = this.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = this.options;
    }
  }

  private watchSearchChanges(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterItems();
      });
  }

  private filterItems(): void {
    let search = this.searchControl.value;
    if (!search) {
      this.filteredOptions = this.options;
    } else {
      search = search.toLowerCase();
      this.filteredOptions = this.options.filter(
        (option) => option.label.toLowerCase().indexOf(search) > -1,
      );
    }
  }

  resetValue(): void {
    if (this.singleSelect) {
      this.singleSelect.value = '';
    }
    this.control.patchValue('');
  }

  trackBy(index: number, item: SelectOption<any>): string {
    return item.label;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
