<form [formGroup]="control.parent" class="single-button-select__form">
  <h3 *ngIf="description" class="single-button-select__form__title">
    {{ description }}
  </h3>
  <mat-radio-group [formControl]="control" class="single-button-select__form__radio-group">
    <mat-radio-button
      *ngFor="let option of selectOptions"
      class="single-button-select__form__radio-group__option button-type"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-radio-button>
  </mat-radio-group>
</form>
