import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'humanizeBoolean',
})
export class HumanizeBooleanPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    let valueToReturn = value;
    if (typeof value === 'boolean') {
      valueToReturn = this.translateService.instant(
        valueToReturn ? 'COMMON.APP.HUMANIZED_BOOLEAN.YES' : 'COMMON.APP.HUMANIZED_BOOLEAN.NO',
      );
    }
    return valueToReturn;
  }
}
