<form  class="textbox__form">
 <h3 *ngIf="description" class="step-description">
    {{ description }}
  </h3>
  <mat-form-field class="textbox__form-field">
    <input
      type="text"
      matInput
      [placeholder]="inputLabel"
      [wrAutoFocus]="autofocus"
      [ngModel]="value"
      (input)="onChange($event.target.value)"
      name="short-answer"
    />
  </mat-form-field>
</form>
