import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  IJobRoleList,
  IJobRolePagination,
  IJobRoleResponse,
  IWorkspaceProfileRes,
} from '@app/modules/setup/setup.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SetupService } from '@app/modules/setup/setup.service';
import { UserService } from '@app/shared/user/user.service';
import { SelectOption } from '@app/shared/common.model';
import { SweetAlertResult } from 'sweetalert2';
import { SweetAlertService } from '@app/modules/core/services/alert/sweet-alert.service';
import { RoleName } from '../../constants/role.constants';
import { JwtResponse } from '../../models/jwt.model';

@Component({
  selector: 'wr-workspace-profile',
  templateUrl: './workspace-profile.component.html',
  styleUrls: ['./workspace-profile.component.scss'],
})
export class WorkspaceProfileComponent implements OnInit {
  @Input() btnName = 'BUTTON.NEXT';
  @Input() backButton = false;
  @Input() loading = false;
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() backClicked = new EventEmitter<string>();
  workspaceProfileData: IWorkspaceProfileRes;
  workspaceProfileForm: UntypedFormGroup;
  jobRoleList: IJobRoleList[] = [];
  totalJobs = 0;
  setupMode = false;
  jobRolesPagination: IJobRolePagination = {
    limit: 20,
    skip: null,
    searchString: null,
  };
  hrLeaderUserList: SelectOption<any>[];

  leaderUserList: SelectOption<any>[];
  accessData: JwtResponse;

  selectedLanguage: string;
  constructor(
    private readonly fb: UntypedFormBuilder,
    private setupService: SetupService,
    private userService: UserService,
    private readonly translateService: TranslateService,
    private readonly sweetAlertService: SweetAlertService,
  ) {
    this.accessData = this.userService.getAuthTokenData();
    this.setupMode = this.accessData.setup;
  }

  async ngOnInit(): Promise<void> {
    await this.getDropdownList();
    this.buildWorkspaceProfileForm();
    this.selectedLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  async getJobRoles(): Promise<void> {
    const res: IJobRoleResponse = await this.setupService.getJobRoles(
      this.jobRolesPagination,
    );
    this.jobRoleList = res.data;
    this.totalJobs = res.count;
  }
  private async buildWorkspaceProfileForm(): Promise<void> {
    this.workspaceProfileData =
      await this.setupService.getUserWorkspaceProfile();

    this.workspaceProfileForm = this.fb.group({
      companyName: [
        { value: this.workspaceProfileData.companyName, disabled: true },
        Validators.required,
      ],
      teamName: [{ value: this.workspaceProfileData.teamName, disabled: true }],
      parentUserWorkspaceId: [
        {
          value: this.workspaceProfileData.parentUserWorkspaceId,
          disabled: this.workspaceProfileData.parentUserWorkspaceId,
        },
      ],
      hrContactId: [this.workspaceProfileData.hrContactId],
      jobTitle: [this.workspaceProfileData.jobTitle, [Validators.required]],
    });
  }

  onKey(value): void {
    this.jobRolesPagination.searchString = value;
    this.getJobRoles();
  }

  async save(): Promise<void> {
    let proceedWithHrUpdate: SweetAlertResult | undefined;
    if (!this.setupMode) {
      if (
        this.workspaceProfileData.parentUserWorkspaceId ===
        this.workspaceProfileForm.value.parentUserWorkspaceId
      )
        delete this.workspaceProfileForm.value.parentUserWorkspaceId;

      if (
        this.workspaceProfileData.hrContactId ===
        this.workspaceProfileForm.value.hrContactId
      ) {
        delete this.workspaceProfileForm.value.hrContactName;
      } else {
        proceedWithHrUpdate = await this.proceedWithHrUpdate();
      }
    }
    if (
      (proceedWithHrUpdate && proceedWithHrUpdate.isConfirmed) ||
      !proceedWithHrUpdate
    ) {
      this.formSubmitted.emit(this.workspaceProfileForm.value);
      this.loading = false;
    }
  }

  back(): void {
    this.backClicked.emit('');
  }

  async getDropdownList(): Promise<void> {
    let userRes = await this.setupService.getDropdownTeams();
    userRes = userRes.filter((x) => x._id !== this.accessData.userWorkspaceId);
    const hrRes = userRes.filter((x) => x.roles.includes(RoleName.HR));
    this.hrLeaderUserList = hrRes.map((el) => ({
      label: el.name,
      value: el._id,
    }));
    this.leaderUserList = userRes.map((el) => ({
      label: el.name,
      value: el._id,
    }));
  }

  async proceedWithHrUpdate(): Promise<SweetAlertResult> {
    return await this.sweetAlertService.showAlert({
      title: 'HR_CHANGE.TITLE',
      html: 'HR_CHANGE.QUESTION',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: 'BUTTON.YES',
      denyButtonText: 'BUTTON.NO',
    });
  }
}
