import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { takeUntil } from 'rxjs/operators';

import {
  DocumentAdditionalLabel,
  DocumentsControls,
  DocumentsIdentifiers,
  DocumentStatus,
} from '@app/shared/documents.model';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { CaseTypes } from '@app/shared/cases.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { DialogId } from '@app/shared/dialogs.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';

@Component({
  selector: 'wr-follow-up-document',
  templateUrl: './follow-up-document.component.html',
})
export class FollowUpDocumentComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  docVersion: number;
  documentsIdentifiers: typeof DocumentsIdentifiers = DocumentsIdentifiers;
  getDocument$: Observable<DocumentFormConfig>;
  documentTypeLabel: string;
  additionalLabels: DocumentAdditionalLabel[];

  constructor(
    private readonly casesService: CasesService,
    private readonly dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      type: DocumentTemplateUniqueIdentifier | DocumentsIdentifiers;
      userId: string;
    },
  ) {}

  ngOnInit(): void {
    this.selectDocumentTypeLabel();
    this.getFollowUpDocumentData();
  }

  private selectDocumentTypeLabel(): void {
    this.documentTypeLabel = {
      [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE]:
        DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE],
      [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS]:
        DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS],
      [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING]:
        DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING],
    }[this.data.type];
  }

  private getFollowUpDocumentData(): void {
    this.casesService
      .getCaseDocTemplate(this.data.caseId, this.data.type as any)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        if (this.data.type === DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS) {
          this.additionalLabels = [
            { controlType: DocumentsControls.SMALL_TEXTAREA_LIST, label: value.additionalData.followUpPlanQuestions },
          ];
        }
        this.getDocument$ = of(value.docTemplate);
      });
  }

  submit(fields: { [key: string]: string | string[] }): void {
    this.casesService
      .addDocumentToCase(this.data.caseId, {
        docTemplateUniqueIdentifier: this.data.type,
        docTemplateVersionNumber: this.docVersion,
        ownerId: this.data.userId,
        fields,
        status: DocumentStatus.DRAFT,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.FOLLOW_UP_DOCUMENT, { dialogResult: true });
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
