import { Component, Input, OnInit } from '@angular/core';
import { CaseDocument } from '@app/shared/cases.model';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

@Component({
  selector: 'wr-follow-up-scheduled-event-summary',
  templateUrl: './follow-up-scheduled-event-summary.component.html',
  styleUrls: ['./follow-up-scheduled-event-summary.component.scss'],
})
export class FollowUpScheduledEventSummaryComponent implements OnInit {
  @Input() schedulerData: CaseDocument;
  documentTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {}, 500);
  }
}
