import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

import {
  BREAKPOINT_MEDIUM_SCREEN,
  BREAKPOINT_MOBILE,
  BREAKPOINT_SMALL_SCREEN,
  BREAKPOINT_TABLET,
  Breakpoints,
} from '@app/shared/breakpoints.model';

@Injectable({
  providedIn: 'root',
})
export class ResponsivenessService {
  innerWidth$ = new BehaviorSubject<number>(null);
  innerHeight$ = new BehaviorSubject<number>(null);
  currentBreakpoint$ = new BehaviorSubject<Breakpoints>(null);

  constructor() {
    this.innerWidth$.next(window.innerWidth);
    this.setBreakpoint();
    this.watchResize();
  }

  watchResize(): void {
    fromEvent(window, 'resize').subscribe(() => {
      this.innerWidth$.next(window.innerWidth);
      this.innerHeight$.next(window.innerHeight);
      this.setBreakpoint();
    });
  }

  private setBreakpoint(): void {
    const currentWidth = this.innerWidth$.getValue();
    if (currentWidth < BREAKPOINT_MEDIUM_SCREEN) {
      if (currentWidth < BREAKPOINT_SMALL_SCREEN) {
        this.currentBreakpoint$.next(Breakpoints.SMALL_SCREEN);
      }
      if (currentWidth < BREAKPOINT_TABLET) {
        this.currentBreakpoint$.next(Breakpoints.TABLET);
      }
      if (currentWidth < BREAKPOINT_MOBILE) {
        this.currentBreakpoint$.next(Breakpoints.MOBILE);
      }
    } else {
      this.currentBreakpoint$.next(Breakpoints.MEDIUM_SCREEN);
    }
  }
}
