import { Pipe, PipeTransform } from '@angular/core';
import { DatetimeService } from '@app/shared/datetime/datetime.service';

@Pipe({
  name: 'dateDiffDays',
})
export class DateDiffDaysPipe implements PipeTransform {
  constructor(public datetimeService: DatetimeService) {}

  transform(startDate: string, endDate?: string): any {
    return this.getSelectedDaysCount(startDate, endDate);
  }

  private getSelectedDaysCount(startDate: string, endDate: string): string {
    if (!startDate) return '';
    if (!endDate) return '1';

    const start: Date = this.datetimeService.formatSimpleDateToDayjs(startDate).toDate();
    const end: Date = this.datetimeService.formatSimpleDateToDayjs(endDate).toDate();

    // @ts-ignore
    const diffTime = Math.abs(end - start);

    const result = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return result ? result.toString() : '-';
  }
}
