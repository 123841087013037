<form [formGroup]="control.parent" class="select__form">
  <!-- <h3 *ngIf="description" class="step-description">
    {{ description }}
  </h3> -->
  <mat-form-field class="select__form-field">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select [formControl]="control">
      <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.label }}</mat-option>
    </mat-select>
  </mat-form-field>
  <p *ngIf="additionalInfo" class="select__additional-info">{{ additionalInfo }}</p>
</form>
