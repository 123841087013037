import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IBreadcrumbItems } from '@app/shared/custom-components/breadcrumb/breadcrumb.component';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';
import { IBasicSchedulerData } from '@app/shared/components/scheduler/scheduler-summary/scheduler-summary.component';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { SchedulerService } from '../../services/scheduler/scheduler.service';

@Component({
  selector: 'wr-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit, OnDestroy {
  @Input() group: UntypedFormGroup;
  @Input() caseEndDate: Date;
  @Input() disableRepeat = false;
  private readonly onDestroy$ = new Subject<null>();

  breadCrumbItems: IBreadcrumbItems[] = [
    { title: `Owner's Name` },
    { title: `Case Type` },
    { title: `Workflow setup name` },
  ];
  followUpTypes = FollowupTypesEnum;
  repeatList = ['days', 'weeks'];
  minDate = new Date();
  constructor(private readonly iconsHelperService: IconsHelperService, private schedulerService: SchedulerService) {
    this.iconsHelperService.registerSvgIcons('calendar');
  }

  ngOnInit(): void {
    const schedulerData: IBasicSchedulerData = this.group.value;
    if (!schedulerData.schedulerFollowupType && this.group.controls) {
      this.group.controls?.schedulerRepeat?.setValue(false);
      this.group.controls?.schedulerPrefferedDate?.setValidators([Validators.required]);
      this.group.controls?.schedulerPrefferedTime?.setValidators([Validators.required]);
      this.group.controls?.schedulerPrefferedTime?.setValue('12:00');
      this.group.controls?.schedulerFollowupType?.setValue('call');
      this.group.controls?.timeZone?.setValue(new Date().getTimezoneOffset());
    }
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  get getUpcommingFollowupDates(): { date: string; time: string }[] {
    const schedulerData: IBasicSchedulerData = this.group.value;
    if (!schedulerData.schedulerRepeat) {
      return []; // No Upcoming dates
    }
    const schedulerObj: ScheduleFollowUpDto = {
      frequency: { interval: schedulerData.schedulerRepeatValue, unit: schedulerData.schedulerRepeatFrequency },
      duration: { interval: schedulerData.schedulerDurationValue, unit: schedulerData.schedulerDurationFrequency },
      firstEventDate: schedulerData.schedulerPrefferedDate,
      preferTime: schedulerData.schedulerPrefferedTime,
      followUpType: schedulerData.schedulerFollowupType,
    };
    return this.schedulerService.getFollowUpDates(schedulerObj);
  }

  get showNextFollowupDiv(): boolean {
    const schedulerData: IBasicSchedulerData = this.group.value;
    if (schedulerData.schedulerPrefferedDate && schedulerData.schedulerPrefferedTime) return true;
    else return false;
  }

  schedulerFollowupTypeChange($event: MatRadioChange): void {
    if ($event.value === 'call') {
      this.group.controls.schedulerFollowupMessage.setValue('');
    }
  }

  schedulerSetToRepeat($event: MatRadioChange): void {
    if ($event.value) {
      this.group.controls.schedulerRepeatValue.setValue(1);
      this.group.controls.schedulerRepeatFrequency.setValue('days');
    }
  }

  // You can handle the selectedTime change here if needed
  onTimeSelectionChange(value: string): void {
    this.group.controls.schedulerPrefferedTime.setValue(value);
  }

}

export interface IFollowupData {
  duration: string;
  followUpCommunicationType: string;
  note: string;
  followUpType: FollowupTypesEnum;
  attendees: string[];
  preferTime: string;
  preferDays: string[];
  location: string;
  deadline: string;
  deadlineDuration: string;
  firstEventDate: string;
  frequency: string;
  timeZone: number;
}

export enum FollowupTypesEnum {
  DAILY = '0',
  WEEKLY = '1',
  BI_WEEKLY = '2',
  CUSTOM = '4',
}

export interface IFollowUpDates {
  date: string;
  time: string;
}

export interface IntervalWithFrequency {
  interval: number;
  unit: string;
}

export class ScheduleFollowUpDto {
  // {
  //   "followUpType": "MEETING_WITH_TASK",
  //   "startDate": "2023-01-26",
  //   "frequency": "5 days",
  //   "duration": "5 months",
  //   "preferenceDateAndTime": ["tue", "wed", "15:30"],
  //   "deadline": "3 days",
  //   "location": "https://meet.google.com/mwy-ztib-mtz",
  //   "followUpCommunicationType": "call",
  //   "note": "Follow Up Planned"
  // }
  followUpType?: string;
  firstEventDate?: Date;
  lastEventDate?: string;
  frequency?: IntervalWithFrequency;
  duration?: IntervalWithFrequency;
  preferDays?: string[];
  preferTime?: string;
  deadline?: IntervalWithFrequency;
  location?: string;
}
