export interface ICompanyAccountForm {
  name: string;
  phoneNumbers: string[];
  email: string;
  website: string;
  logo: string;
  addresses: ICompanyAddress[];
}

export interface ICompanyAddress {
  type: string;
  isDefault: boolean;
  street: string;
  streetNo: string;
  streetNoSuffix: string;
  zipCode: string;
  city: string;
  region: string;
  countryCode: string | null;
  country?: string | null;
}

export interface ICompanyAccountAdminForm {
  name: string;
  phoneNumber: string;
  email: string;
}

export interface ICompanyAccountResponse {
  name: string;
  logo: string;
  address: ICompanyAddress;
  billingDetails: {
    email: string;
    contactName: string;
  };
  registrationNumber: string;
  _id: string;
  status: string;
  caseCount: number;
  sector: string;
  bio: string;
  website: string;
}

export interface ICompanyWorkspace {
  country: string;
  companyId: string;
  name: string;
  location: string;
  _id: string;
  hasHealthServiceProvider: boolean;
}

export interface IPaginationData {
  limit: number;
  skip: number;
  search?: string;
  sort?: string;
}

export interface ICompanyListResponse {
  count: number;
  data: ICompanyAccountResponse[];
}

export interface IContactPersonResponse {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  photo: string;
}

export interface ISubscriptionPlanResponse {
  id: string;
  name: string;
  type: ESubscriptionTypes;
  period: IPeriodRes;
  price: string;
}

export interface IPeriodRes {
  unit: string;
  value: number;
}

export interface ISubscriptionHistoryRes {
  endDate: string;
  name: string;
  subsequent: ISubscriptionHistoryRes;
  id: string;
  history: {
    endDate: string;
    action: string;
    remarks: string;
    startDate: string;
  }[];
  startDate: string;
  status: string;
  type: ESubscriptionTypes;
  period: IPeriodRes;
  planId: string;
}

export interface ISubscriptionPackagesRes {
  period: { unit: string; value: number };
  companies: any[];
  price: number;
  name: string;
  _id: string;
  type: number;
  isActive: boolean;
}

export enum ESubscriptionTypes {
  TRIAL = 0,
  STANDARD = 1,
  TAILORED = 2,
}

export interface ISubscriptionFormValue {
  subscriptionId: string;
  subscriptionType: ESubscriptionTypes;
  periodValue: number;
  periodUnit: string;
  price: number;
}

export interface ISubscriptionPackageFormValue {
  name: string;
  subscriptionType: ESubscriptionTypes;
  periodValue: number;
  periodUnit: string;
  price: number;
  companyId: Array<string>;
}

export interface ISubscriptionReq {
  type: number;
  subscriptionTypeId: string;
  price: number;
  period: {
    value: number;
    unit: string;
  };
}

export interface ICompanyDropdownRes {
  _id: string;
  name: string;
}

export const SubscriptionPeriodUnits = [
  { label: 'TIME.MONTHS', value: 'months' },
];

export const SubscriptionTypeArray = [
  {
    label: 'TRIAL',
    value: 0,
  },
  {
    label: 'STANDARD',
    value: 1,
  },
  {
    label: 'TAILORED',
    value: 2,
  },
];

export interface ISubscriptionPackagesFilter {
  type: ESubscriptionTypes | null;
  isActive: boolean | null;
}

export interface ISubscriptionPackageReq {
  period: { unit: string; value: number };
  price: number;
  name: string;
  type: ESubscriptionTypes;
  companyId: Array<string>;
}

export interface ICompanyResponse {
  success: boolean;
  data: ICompanyAccountResponse;
}

export interface IArticlesRes {
  featured: number;
  img: string;
  link: string;
  _id: string;
  isActive: boolean;
  title: string;
  content: string;
}

export interface ICheckListTrackerRes {
  buttonText: string;
  icon: string;
  description: string;
  title: string;
  type: number;
  key: EChecklistTrackerKey;
  required: boolean;
  status: boolean;
  cancelled: boolean;
  cancellable: boolean;
  predecessor?: EChecklistTrackerKey;
}

export enum EChecklistTrackerKey {
  COMPANY_SETUP = 'COMPANY_SETUP',
  WORKSPACE_SETUP = 'WORKSPACE_SETUP',
  ORGANISATION_SETUP = 'ORGANISATION_SETUP',
  KICK_OFF_ONBOARDING = 'KICK_OFF_ONBOARDING',
  INTEGRATION = 'INTEGRATION',
}
export interface ICompanyAddressRes {
  locations: ICompanyAddressLocation[];
  _id: string;
}

export interface ICompanyAddressLocation {
  zipCode: string;
  country: string;
  city: string;
  street: string;
  _id: string;
  region: string;
}

export interface ICompanyPolicyRes {
  policies: { isEditable: boolean; name: string; _id: string; value: number }[];
  _id: string;
}

export interface ICompanyHSPRes {
  companyId: string;
  companyName: string;
  telephone: string;
  _id: string;
  email: string;
  website: string;
  workspaceId: string;
}

export interface IActiveUserList {
  year: number;
  month: number;
  count: number;
}
