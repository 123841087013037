import { Component } from '@angular/core';
import {RouteSegments} from "@app/shared/routing.model";
import {Router} from "@angular/router";

@Component({
  selector: 'wr-follow-up-template',
  templateUrl: './follow-up-template.component.html',
  styleUrls: ['./follow-up-template.component.scss']
})
export class FollowUpTemplateComponent {
  templateCategoryList = [
    {icon: 'add', name: 'Create Blank', route: RouteSegments.BUILDER, customClass: 'blank'},
    {icon: 'person', name: 'Roles & Tasks', route: RouteSegments.BUILDER},
    {icon: 'supervisor_account', name: 'Targets & Performance', route: RouteSegments.BUILDER},
  ]

  templateList = [
    {
      name: 'Follow-up1',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,\n' +
        '              beatae? Voluptatem sequi nam nihil quod. Excepturi perferendis\n' +
        '              dolores esse, ab at reiciendis eius atque eos qui minus. Eveniet\n' +
        '              ab, explicabo ducimus debitis, accusantium praesentium sequi\n' +
        '              maxime, amet ea eum vitae veritatis delectus inventore. Dolorem\n' +
        '              voluptate quis impedit corrupti alias, culpa doloremque totam\n' +
        '              debitis ducimus repudiandae aliquid officiis iusto, cupiditate\n' +
        '              libero incidunt',
      figureCaption: 'SD',
      title: 'Random Title',
      used: '12k',
      favorite: '13k'
    },
    {
      name: 'Mock question',
      description: 'A short description regarding thetemplate',
      figureCaption: 'SD',
      title: 'Not so Title',
      used: '69',
      favorite: '1K'
    },

  ]

  constructor(
    private router: Router
  ) {
  }
  routeTo(route: RouteSegments) {
    this.router.navigate([route]);
  }
}
