<form [formGroup]="textControl.parent" class="textbox__form">
  <!-- <h3 *ngIf="description" class="step-description">
    {{ description }}
  </h3> -->
  <mat-form-field class="textbox__form-field">
    <input
      type="text"
      matInput
      [formControl]="textControl"
      [placeholder]="inputLabel"
      [wrAutoFocus]="autofocus"
    />
  </mat-form-field>
</form>
