<form class="single-answer-select__form">
  <div *ngIf="type === 'radio'">
    <mat-radio-group
      (change)="handleValueChange($event.value, true)"
      class="d-flex justify-content-center gap-3 mb-4"
    >
      <mat-radio-button
        *ngFor="let option of selectOptions"
        class="radio-custom"
        [checked]="value === option.value"
        [value]="option.value"
      >
        <mat-icon *ngIf="option.icon">{{option.icon}}</mat-icon>
        {{ option.label | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="type === 'select'">

    <mat-form-field class="select__form-field">
      <mat-label *ngIf="inputLabel">{{ inputLabel }}</mat-label>
      <mat-select name="single-select-custom" [(ngModel)]="value" (selectionChange)="handleSelectValueChange()" >
        <mat-option *ngFor="let option of selectOptions" [value]="option.value">{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    *ngIf="type === 'button'"
    class="mx-auto multi-button-select__form__checkbox-buttons__container multi-button-select-custom-big"
  >
    <mat-checkbox
      class="mat-button mat-button-base mat-button-square"
      *ngFor="let option of selectOptions"
      [checked]="value === option.value"
      (change)="handleValueChange(option.value, $event.checked)"
      [disabled]="option.disabled"
    >
      <mat-icon>{{ option.icon }}</mat-icon>
      <p>{{ option.label | translate }}</p>
    </mat-checkbox>
  </div>
</form>
