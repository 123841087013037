<wr-generic-dialog [showBreadcrumb]="false">
  <ng-container title>
    {{ 'FEEDBACK.MAIN.TITLE' | translate }}
  </ng-container>
  <ng-container subTitle>
    {{ 'FEEDBACK.SUB.TITLE' | translate }}
  </ng-container>
  <ng-container content>
    <div class="custom-dialog-content">
      <form [formGroup]="feedbackForm" class="form_container w-90 mx-2 mt-1">
        <label class="font-weight-bold mb-3 d-block text-center"
          >{{ 'FEEDBACK.TEXT.SCREENSHOTS' | translate }}
          <mat-hint
            ><small
              >({{
                'FEEDBACK.TEXT.ADD_SCREENSHOT_MAX_5_ALLOWED' | translate
              }})</small
            ></mat-hint
          ></label
        >
        <div class="feed-attachment screenshot mt-2">
          <div
            class="feed-attachment-img"
            *ngFor="let item of screenShotImagesPreview; let i = index"
          >
            <mat-icon
              (click)="onImageUnSelect(i)"
              class="feed-attachment-remove"
              >close
            </mat-icon>
            <img [src]="item" alt="" class="rounded" height="80" width="80" />
            <span _ngcontent-ovb-c334="" class="feed-attachment-name">
              {{ screenShotImages[i].name }}</span
            >
          </div>
          <div
            class="feed-attachment-img feed-attachment-upload"
            *ngIf="
              !screenShotAdded &&
              screenShotImages &&
              screenShotImages.length < 5
            "
            (click)="fullScreenShot()"
          >
            <mat-icon
              class="feed-attachment-file text-primary animated-rotation"
              *ngIf="screenShotMode"
            >
              progress_activity
            </mat-icon>
            <mat-icon
              class="feed-attachment-file text-primary"
              *ngIf="!screenShotMode"
              >screenshot_region
            </mat-icon>
            <span *ngIf="!screenShotMode">{{
              'FEEDBACK.TEXT.TAKE_SCREENSHOT' | translate
            }}</span>
          </div>
          <div
            class="feed-attachment-img feed-attachment-upload"
            *ngIf="screenShotImages && screenShotImages.length < 5"
            (click)="imageInput.click()"
          >
            <mat-icon class="feed-attachment-file text-primary"
              >add_photo_alternate
            </mat-icon>
            <span>{{ 'FEEDBACK.TEXT.UPLOAD_SCREENSHOT' | translate }}</span>
          </div>
        </div>
        <hr class="mb-4" />
        <mat-form-field class="invite__role-select">
          <mat-label>{{ 'FEEDBACK.TYPE' | translate }}</mat-label>
          <mat-select
            formControlName="category"
            panelClass="category-select-dropdown"
          >
            <mat-option
              *ngFor="let option of categoryList; trackBy: trackBy"
              [value]="option.value"
              >{{ option.label | translate }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="feedbackForm.get('category').hasError('required')">
            {{ 'COMMON.ERROR.TYPE_IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="feed-post w-100">
          <mat-label>{{ 'FEEDBACK.LOCATION_URL' | translate }}</mat-label>
          <input formControlName="locationUrl" matInput type="text" />
          <mat-error
            *ngIf="feedbackForm.get('locationUrl').hasError('required')"
          >
            {{ 'COMMON.ERROR.LOCATION_URL_IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="feed-post w-100">
          <mat-label>{{ 'FEEDBACK.SUBJECT' | translate }}</mat-label>
          <input formControlName="subject" matInput type="text" />
          <mat-error *ngIf="feedbackForm.get('subject').hasError('required')">
            {{ 'COMMON.ERROR.SUBJECT_IS_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="feed-post w-100">
          <mat-label>{{ 'CONTACT_US.MESSAGE.LABEL' | translate }}</mat-label>
          <textarea
            cdkAutosizeMaxRows="5"
            cdkAutosizeMinRows="5"
            cdkTextareaAutosize
            formControlName="message"
            matInput
          ></textarea>
          <mat-error *ngIf="feedbackForm.get('message').hasError('required')">
            {{ 'COMMON.ERROR.MESSAGE_IS_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="feedbackForm.get('message').hasError('minlength')">
            {{ 'COMMON.ERROR.MESSAGE_MIN_5' | translate }}
          </mat-error>
        </mat-form-field>
        <input
          hidden
          (change)="onImageSelected($event)"
          #imageInput
          type="file"
          multiple
          accept="image/png, image/gif, image/jpeg"
        />
      </form>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="mt-0">
      <!--            <button-->
      <!--                    (click)="dialogRef.close()"-->
      <!--                    mat-button-->
      <!--                    type="button"-->
      <!--            >-->
      <!--                {{ 'BUTTON.CANCEL' | translate }}-->
      <!--            </button>-->
      <button
        (click)="submit()"
        [disabled]="feedbackForm.invalid"
        [loading]="loading"
        wrButton
      >
        {{ 'BUTTON.SUBMIT' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
