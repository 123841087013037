<form class="multi-button-select__form">
  <!--  <form [formGroup]="formArray.parent" class="multi-button-select__form">-->
  <!-- <h3 *ngIf="description" class="step-description">
      {{ description }}
    </h3> -->

  <div *ngIf="type === 'checkbox'">
    <mat-checkbox
      class="custom-checkbox"
      *ngFor="let option of selectOptions"
      [checked]="value.includes(option.value)"
      (change)="handleValueChange(option.value, $event.checked)"
      [disabled]="option.disabled"
    >
      <p>{{ option.label | translate }}</p>
    </mat-checkbox>
  </div>
    <div *ngIf="type === 'select'">

    <mat-form-field class="select__form-field">
      <mat-label *ngIf="inputLabel">{{ inputLabel }}</mat-label>
      <mat-select name="multi-select-custom" [ngModel]="value" (selectionChange)="handleSelectValueChange($event.value)" multiple>
        <mat-option *ngFor="let option of selectOptions"  [value]="option.value">{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    *ngIf="type === 'button'"
    class="mx-auto multi-button-select__form__checkbox-buttons__container multi-button-select-custom-big"
  >
    <mat-checkbox
      class="mat-button mat-button-base mat-button-square"
      *ngFor="let option of selectOptions"
      [checked]="value.includes(option.value)"
      (change)="handleValueChange(option.value, $event.checked)"
      [disabled]="option.disabled"
    >
      <mat-icon>{{ option.icon }}</mat-icon>
      <p>{{ option.label | translate }}</p>
    </mat-checkbox>
  </div>
</form>
