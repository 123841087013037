import { RoleName } from '@app/modules/shared/constants/role.constants';
import { ETncType, ETwoFactorTypes } from '@app/entry/entry.model';

export interface User {
  _id: string;
  email: string;
  firstName: string;
  isLeader?: boolean;
  lastName: string;
  permissions?: string[];
  phoneNumber?: string;
  nin?: string;
  userRole?: RoleName;
  userWorkspaceId: string;
  photo?: string;
  userWorkspaceName?: string;
  jobTitle?: string;
  createdAt?: string;
  leader?: { name: string; photo: string; _id: string; email: string };
  hrContactId?: string;
}

export enum UserRole {
  OWNER = 'owner',
  EMPLOYEE = 'employee',
  ADMIN = 'admin',
}

export enum UserOnboardingStatus {
  ARCHIEVED = 0,
  ACTIVE = 1,
  PENDING = 2,
  COMPANY = 3,
  WORKSPACE = 4,
  ACCOUNT = 5,
  ONBOARDING = 6,
  USER_WORKSPACE = 7,
  USER_TEAMSPACE = 8,
  USER_INVITATION = 9,
  EXPIRED = 10,
  TERMINATED = 11,
  COMPANY_TNC = 12,
  INACTIVE = 13,
}

export interface IUserWorkspaceList {
  companyId: string;
  companyName: string;
  email: string;
  invitationToken: string;
  jobRole: string;
  jobTitle: string;
  logo: string;
  status: UserOnboardingStatus;
  isHr: boolean;
  workspaceName: string;
  _id: string;
}

export interface IUserWorkabilityStatus {
  totalSickLeaves: number;
  totalSelfCertifications: number;
  workabilityPercentage: number;
  sickLeavesTaken: number;
  _id: string;
  selfCertificationsTaken: number;
  activeCases: string[];
}

export interface ITermsAndConditionRes {
  data: {
    createdAt: string;
    _id: string;
    version: number;
    content: {
      en: string;
      nb: string;
    };
    acceptOptions: ITncOptions[];
  };
  isAccepted: boolean;
}

export interface ITermsAndConditionReq {
  type: ETncType;
  version: number;
  answers: {
    key: string;
    value: string;
  }[];
}

export interface ITncOptions {
  label: {
    en: string;
    nb: string;
  };
  key: string;
  isRequired: boolean;
  value?: boolean;
}

export interface IRemoveUserRoleRes {
  id: string;
  name: string;
  photo: string;
}

export interface IAssignUserRoleReq {
  assignNewUser: boolean;
  oldHrContactId: string;
  newHrContactId?: string;
  isHrLeader: boolean;
}

export interface IAssignLanguageReq {
  language: string;
}

export interface ITwoFactorInfo {
  isTwoFactorEnabled: boolean;
  twoFactorData: ITwoFactorData[];
}

export interface ITwoFactorData {
  enabled: true;
  type: ETwoFactorTypes;
}
