<div
  class="scheduler-summary-item mt-1"
  *ngFor="let value of summaryControl.value; let i = index"
>
  <div
    class="card-details__value__container"
    *ngIf="
      inputControlName &&
        selectControlName &&
        (value[inputControlName] || value[selectControlName]);
      else noData
    "
  >
    <mat-icon color="primary">check</mat-icon>
    <span class="card-details__value__text">
      <span class="fw-bold">{{
        selectOptionsLabel[value[selectControlName]] ||
          value[selectControlName] ||
          '-'
      }}</span>
      - {{ (value[inputControlName] || '-') + ' ' }}
    </span>
  </div>
  <ng-template #noData>
    <span *ngIf="i === 0" class="card-details__no-value__text">{{
      summaryControl.noValueInfo || '-'
    }}</span>
  </ng-template>
</div>
