import { Component, OnInit } from '@angular/core';
import { Languages } from '../../models/common.model';
import { GlobalLanguageService } from '@app/shared/global-language.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'wr-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent implements OnInit {

  constructor(
    private globalLanguageService: GlobalLanguageService,
    private userService: UserService,
  ) {}
  languages: typeof Languages = Languages;
  selectedLanguage: Languages;

  get;

  ngOnInit(): void {
    this.selectedLanguage = this.globalLanguageService.getSelectedLanguage();
  }
  onLanguageChange(language: Languages): void {
    this.selectedLanguage = language;
    this.globalLanguageService.setLanguage(language);
    if (this.userService.isUserLoggedIn()) {
      this.userService.assignLanguage({ language }).toPromise();
    }
  }
}
