<mat-nav-list class="p-3">
  <span class="d-block mb-2 fs-5 fw-4 text-center" translate
    >MENU.SIDENAV.NEW_ACTION.WHAT_WOULD_YOU_LIKE_TO_DO</span
  >

  <div
    (click)="openSelfCertificationDialog()"
    class="d-flex align-items-center gap-4 py-3"
  >
    <mat-icon
      class="text-primary fs-2"
      mat-list-icon
      svgIcon="self-certification-button-icon"
    ></mat-icon>

    <span class="fs-6" mat-line
      >{{ 'LANDING_PAGE.DOCUMENTS.BUTTONS.SELF_CERTIFICATION' | translate
      }}</span
    >
  </div>
  <div
    (click)="openReportIssueDialog()"
    class="d-flex align-items-center gap-4 py-3"
  >
    <mat-icon
      class="text-primary fs-2"
      mat-list-icon
      svgIcon="report-work-issue-button-icon"
    ></mat-icon>

    <span class="fs-6" mat-line
      >{{ 'LANDING_PAGE.DOCUMENTS.BUTTONS.REPORT_AN_ISSUE' | translate }}</span
    >
  </div>
  <div
    (click)="openTemporaryChangeRequestDialog()"
    class="d-flex align-items-center gap-4 py-3"
  >
    <mat-icon
      class="text-primary fs-2"
      mat-list-icon
      svgIcon="temporary-change-button-icon"
    ></mat-icon>

    <span class="fs-6" mat-line
      >{{ 'LANDING_PAGE.DOCUMENTS.BUTTONS.TEMPORARY_CHANGE_REQUEST' | translate
      }}</span
    >
  </div>
  <div
    (click)="openSickLeaveDialog()"
    class="d-flex align-items-center gap-4 py-3"
  >
    <mat-icon
      class="text-primary fs-2"
      mat-list-icon
      svgIcon="sick-leave-button-icon"
    ></mat-icon>

    <span class="fs-6" mat-line
      >{{ 'LANDING_PAGE.DOCUMENTS.BUTTONS.SICK_LEAVE' | translate }}</span
    >
  </div>
  <div
    (click)="openSelfAssessmentDialog()"
    class="d-flex align-items-center gap-4 py-3"
  >
    <mat-icon
      class="text-primary fs-2"
      mat-list-icon
      svgIcon="self-assessment-button-icon"
    ></mat-icon>

    <span class="fs-6" mat-line
      >{{ 'LANDING_PAGE.DOCUMENTS.BUTTONS.SELF_ASSESSMENT' | translate }}</span
    >
  </div>
</mat-nav-list>
