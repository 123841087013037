import { AccessLevel } from '@app/modules/case-page/models/access.model';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import {
  DocumentsIdentifiers,
  DocumentStatus,
  ReportIssueChoices,
} from '@app/shared/documents.model';
import {
  CaseStatus,
  CaseTypeLabel,
} from '@app/modules/case-page/models/case-details.model';

export enum CaseTypes {
  ISSUE = 'ISSUE',
  EQUIPMENT = 'EQUIPMENT',
  TEMPORARY_CHANGE_REQUEST = 'TEMPORARY_CHANGE_REQUEST',
  SELF_CERTIFICATION = 'SELF_CERTIFICATION',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  FML = 'FML',
  SICK_LEAVE = 'SICK_LEAVE',
  WORKABILITY = 'WORKABILITY',
}

export enum CaseColor {
  RED = 'RED',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
}

export enum CaseChannelType {
  GENERAL = 'CASE_GENERAL',
  OWNER_LEADER = 'CASE_OWNER_LEADER',
}

export interface CaseDocument {
  docTemplateUniqueIdentifier?:
    | DocumentTemplateUniqueIdentifier
    | DocumentsIdentifiers
    | ReportIssueChoices;
  docTemplateVersionNumber?: number;
  fields: any;
  status?: DocumentStatus;
  ownerId: string;
  userId?: string;
  _id?: string;
  __v?: string;
}

export enum ChannelParticipantRole {
  OWNER = 'owner',
  USER = 'user',
}

export interface ChannelParticipant {
  avatar: string;
  name: string;
  role: ChannelParticipantRole;
  userWorkspaceId: string;
}

export interface CaseChannel {
  _id: string;
  caseId: {};
  conversationSid: string;
  members: {}[];
  participants: ChannelParticipant[];
  name: string;
  ownerId: string;
  type: CaseChannelType;
}

export interface CaseIssueData {
  documents: CaseDocument[];
  type?: CaseTypes;
  caseId?: string;
}

export interface CaseIssueResponse {
  _id: string;
  createdAt: string;
  updatedAt: string;
  caseNumber: string;
  closedDate: string;
  documents: CaseDocument[];
  endDate: string;
  userId: object;
  userWorkspaceId: object;
  startDate: string;
  status: object;
  type: CaseTypes;
}

export interface CaseAccess {
  accessLevel: AccessLevel;
  isAccepted: boolean;
  name: string;
  role: string;
  userId: string;
}

export interface Case {
  _id: string;
  createdAt: string;
  updatedAt: string;
  caseNumber: string;
  closedDate: string;
  documents: CaseDocument[];
  startDate: string;
  endDate: string;
  userId: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    _id: string;
  };
  userWorkspaceId: {
    companyId: string;
    name: string;
    parentUserWorkspaceId: string;
    roleId: string;
    userId: string;
    workabilityStatus: number;
    _id: string;
  };
  status: CaseStatus;
  type: CaseTypes;
  accessLevel: AccessLevel;
  accesses: CaseAccesses;
  hrUserWorkspaceId: string;
}

export interface CaseAccesses {
  feeds: boolean;
  timeline: boolean;
  document: boolean;
}

export interface DetailedCaseDetails {
  companyName: string;
  firstName: string;
  lastName: string;
  nextTask: DocumentTemplateUniqueIdentifier;
  nextTaskDeadline: string;
  userWorkspaceName: string;
  workabilityStatus: number;
  workabilityStatusColor: CaseColor;
  jobTitle?: string;
  userPhoto?: string;
  followUpRemainingDays?: number;
}

export interface DetailedCase {
  case: Case;
  details: DetailedCaseDetails;
}

export interface CasesSortOptions {
  sort?: string;
}

export interface AccessBodyData {
  channels: string[];
  documents: string[];
  email: string;
  roleName: string;
}

export interface AccessUpdateBodyData {
  channels: string[];
  documents: string[];
}

export interface ICaseFilterOptionsResponse {
  caseTypes: (keyof typeof CaseTypeLabel)[];
  employees: {
    userWorkspaceid: string;
    userWorkspaceName: string;
    firstName: string;
    lastName: string;
  }[];
}

export interface ICheckIfCaseExistsResponse {
  exists: boolean;
  caseId: string;
  recentlyClosed: boolean;
}
