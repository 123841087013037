import { Component, Input, OnInit } from '@angular/core';
import { IconsHelperService } from '@app/modules/core/services/icons-helper/icons-helper.service';
import * as moment from 'moment';

@Component({
  selector: 'wr-scheduler-summary',
  templateUrl: './scheduler-summary.component.html',
  styleUrls: ['./scheduler-summary.component.scss'],
})
export class SchedulerSummaryComponent implements OnInit {
  @Input() schedulerData: IBasicSchedulerData;

  schedulerSumarryData = {
    nextFollowup: { date: '', time: '' },
    followupType: '',
    upcomingFollowUps: [],
  };
  constructor(private readonly iconsHelperService: IconsHelperService) {
    this.iconsHelperService.registerSvgIcons('calendar');
  }

  ngOnInit(): void {
    if (this.schedulerData) {
      this.schedulerSumarryData.nextFollowup.date = moment(this.schedulerData.schedulerPrefferedDate).format('LL');
      this.schedulerSumarryData.nextFollowup.time = this.schedulerData.schedulerPrefferedTime;
      this.schedulerSumarryData.followupType = this.schedulerData.schedulerFollowupType;
      if (!this.schedulerData.schedulerRepeat) {
        this.schedulerSumarryData.upcomingFollowUps = [
          { date: this.schedulerData.schedulerPrefferedDate, time: this.schedulerData.schedulerPrefferedTime },
        ];
      } else {
        [...Array(this.schedulerData.schedulerRepeatValue).keys()].forEach((el, i) => {
          const dateToPush = moment(this.schedulerData.schedulerPrefferedDate)
            .add(i + 1, this.schedulerData.schedulerRepeatFrequency === 'days' ? 'days' : 'weeks')
            .format('DD-MMM-YYYY');
          this.schedulerSumarryData.upcomingFollowUps.push({
            date: dateToPush,
            time: this.schedulerData.schedulerPrefferedTime,
          });
        });
      }
    }
  }
}

export interface IBasicSchedulerData {
  schedulerRepeat: boolean;
  schedulerRepeatValue: number;
  schedulerRepeatFrequency: string;
  schedulerDurationValue: number;
  schedulerDurationFrequency: string;
  schedulerPlanFrequency: string;
  schedulerPrefferedDate: Date;
  schedulerPrefferedTime: string;
  schedulerFollowupType: string;
  schedulerFollowupMessage: string;
  timeZone: number;
}
