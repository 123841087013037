<wr-generic-report
  #report
  [getDocument$]="getDocument$"
  [hasAdditionalStep]="false"
  [hasHasCustomTitle]="true"
  [documentTypeLabel]="documentTypeLabel"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  [caseType]="data.caseType"
  [rowsCount]="18"
  (selectedStepChange)="selectedStep = $event"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
>
  <!-- <ng-container title>
    <ng-container *ngIf="report?.steps?.length == report?.selectedStep">
      <ng-container *ngIf="selectedStep === 0; else issueTypeTitle"> Report an issue </ng-container>
      <ng-template #issueTypeTitle>
        <ng-container [ngSwitch]="selectedIssueToReport">
          <ng-container *ngSwitchCase="reportIssueChoices.WORKABILITY_UPDATE"> Workability status </ng-container>
          <ng-container *ngSwitchCase="reportIssueChoices.PROBLEM_WITH_EQUIPMENT">
            Problem with equipment
          </ng-container>
          <ng-container *ngSwitchCase="reportIssueChoices.OTHER_ISSUES"> Other issues </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container> -->

  <!-- <ng-container additionalStep>
    <div class="select-issue-type__container">
      <h3>What type of issue would you like to report?</h3>
      <mat-form-field class="document-templates__header__options__sort-by">
        <mat-label>Choose an option</mat-label>
        <mat-select (selectionChange)="onIssueSelectionChange($event.value)">
          <mat-option [value]="reportIssueChoices.WORKABILITY_UPDATE">Workability Report </mat-option>
          <mat-option [value]="reportIssueChoices.PROBLEM_WITH_EQUIPMENT">Problem with equipment </mat-option>
          <mat-option [value]="reportIssueChoices.OTHER_ISSUES">Other issues </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container> -->
</wr-generic-report>
