import { Injectable } from '@angular/core';
import { HeaderStates } from '@app/core/app-header/app-header.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { SweetAlertResult } from 'sweetalert2';
import { CaseTypes, ICheckIfCaseExistsResponse } from '@app/shared/cases.model';
import { SickLeaveComponent } from '@app/shared/components/documents/reports/sick-leave/sick-leave.component';
import { RouteSegments } from '@app/shared/routing.model';
import { Router } from '@angular/router';
import { UserService } from '@app/shared/user/user.service';
import { CasesService } from '@app/shared/cases/cases.service';
import { SelfCertificationComponent } from '@app/shared/components/documents/reports/self-certification/self-certification.component';
import { ReportIssueComponent } from '@app/shared/components/documents/reports/report-issue/report-issue.component';
import { TemporaryChangeRequestComponent } from '@app/shared/components/documents/reports/temporary-change-request/temporary-change-request.component';
import { SelfAssessmentComponent } from '@app/shared/components/documents/reports/self-assessment/self-assessment.component';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { SweetAlertService } from '@app/modules/core/services/alert/sweet-alert.service';
import { FmlComponent } from '@app/shared/components/documents/reports/fml/fml.component';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/modules/shared/models/user.model';

@Injectable()
export class AppHeaderService {
  headerState$ = new Subject<HeaderStates>();
  private profilePictureSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private workspacePictureSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly casesService: CasesService,
    private readonly sweetAlertService: SweetAlertService,
    private readonly translateService: TranslateService,
  ) {}

  async checkLeaderExists(): Promise<boolean> {
    let user: User;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));
    if (user.leader?._id || user.hrContactId) {
      return true;
    } else {
      const informLeader=  await this.informLeader();
      if(informLeader.isConfirmed) {
        console.log('send email from here');
      }
      return false;
    }
  }

  informLeader(): Promise<SweetAlertResult> {
    return this.sweetAlertService.showAlert({
      html: `CONFIRM.LEADER_DOES_NOT_EXIST`,
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'BUTTON.DISMISS',
      confirmButtonText: 'BUTTON.NOTIFY_ADMIN',
      confirmButtonColor: '#00c7b2',
    });
  }
  async openSickLeaveDialog(caseNumber?: string): Promise<void> {
    const leaderExists = await this.checkLeaderExists();
    if (!leaderExists) {
      return;
    }
    let user;
    let proceedToExistingCase: SweetAlertResult | undefined;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));

    const doesCaseExist = await this.checkIfCaseExists(CaseTypes.SICK_LEAVE);
    if (doesCaseExist) {
      if (doesCaseExist.exists) {
        proceedToExistingCase = await this.proceedToExistingCase(
          CaseTypes.SICK_LEAVE,
        );
      } else if (doesCaseExist.recentlyClosed) {
        proceedToExistingCase = await this.proceedToRecentlyClosedCase(
          CaseTypes.SICK_LEAVE,
        );
      }
    }

    if (
      ((proceedToExistingCase && proceedToExistingCase.isDenied) ||
      !proceedToExistingCase)
    ) {
      this.dialogHelperService.openDialog(
        DialogId.SICK_LEAVE,
        SickLeaveComponent,
        DialogClassName.WR_DIALOG_AUTO_SIZE,
        {
          caseNumber:
            proceedToExistingCase && proceedToExistingCase.isDenied
              ? caseNumber
              : '',
          caseUser: `${user.firstName}  ${user.lastName}`,
          userId: user._id,
          caseType: CaseTypes.SICK_LEAVE,
          existingCaseId:
            doesCaseExist && doesCaseExist.caseId ? doesCaseExist.caseId : null,
        },
        { disableClose: true },
      );
    } else if (proceedToExistingCase.isConfirmed) {
      if (doesCaseExist.exists && doesCaseExist.caseId) {
        this.routeToCaseDetail(doesCaseExist.caseId, false);
      } else {
        this.router.navigate([RouteSegments.MESSAGE_CENTER], {
          queryParams: null,
        });
      }
    }
  }

  async openSelfAssessmentDialog(caseNumber?: string): Promise<void> {
    const leaderExists = await this.checkLeaderExists();
    if (!leaderExists) {
      return;
    }
    let user;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));
    this.dialogHelperService.openDialog(
      DialogId.SELF_ASSESSMENT,
      SelfAssessmentComponent,
      DialogClassName.WR_DIALOG_AUTO_SIZE,
      {
        caseNumber: '',
        caseUser: `${user.firstName}  ${user.lastName}`,
        userId: user._id,
        caseType: CaseTypes.SELF_ASSESSMENT,
        existingCaseId: null,
        dialogId: CaseTypes.SELF_ASSESSMENT,
      },
      { disableClose: true },
    );
  }
  async openFMLDialog(caseNumber?: string): Promise<void> {
    const leaderExists = await this.checkLeaderExists();
    if (!leaderExists) {
      return;
    }
    let user;
    let proceedToExistingCase: SweetAlertResult | undefined;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));
    const doesCaseExist = await this.checkIfCaseExists(CaseTypes.FML);
    if (doesCaseExist && doesCaseExist.exists) {
      proceedToExistingCase = await this.proceedToExistingCase(CaseTypes.FML);
    }
    if (
      (proceedToExistingCase && proceedToExistingCase.isDenied) ||
      !proceedToExistingCase
    ) {
      this.dialogHelperService.openDialog(
        DialogId.FML,
        FmlComponent,
        DialogClassName.WR_DIALOG_AUTO_SIZE,
        {
          caseNumber:
            proceedToExistingCase && proceedToExistingCase.isDenied
              ? caseNumber
              : '',
          caseUser: `${user.firstName}  ${user.lastName}`,
          userId: user._id,
          caseType: CaseTypes.FML,
          existingCaseId:
            doesCaseExist && doesCaseExist.caseId ? doesCaseExist.caseId : null,
          dialogId: CaseTypes.FML,
        },
        { disableClose: true },
      );
    } else if (proceedToExistingCase.isConfirmed && doesCaseExist.caseId) {
      this.routeToCaseDetail(doesCaseExist.caseId);
    }
  }

  async openSelfCertificationDialog(caseNumber?: string): Promise<void> {
    const leaderExists = await this.checkLeaderExists();
    if (!leaderExists) {
      return;
    }
    let user;
    let proceedToExistingCase: SweetAlertResult | undefined;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));

    const doesCaseExist = await this.checkIfCaseExists(
      CaseTypes.SELF_CERTIFICATION,
    );
    if (doesCaseExist) {
      if (doesCaseExist.exists) {
        proceedToExistingCase = await this.proceedToExistingCase(
          CaseTypes.SELF_CERTIFICATION,
        );
      } else if (doesCaseExist.recentlyClosed) {
        proceedToExistingCase = await this.proceedToRecentlyClosedCase(
          CaseTypes.SELF_CERTIFICATION,
        );
      }
    }
    if (
      (proceedToExistingCase && proceedToExistingCase.isDenied) ||
      !proceedToExistingCase
    ) {
      this.dialogHelperService.openDialog(
        DialogId.SELF_CERTIFICATION,
        SelfCertificationComponent,
        DialogClassName.WR_DIALOG_AUTO_SIZE,
        {
          caseNumber:
            proceedToExistingCase && proceedToExistingCase.isDenied
              ? caseNumber
              : '',
          caseUser: `${user.firstName}  ${user.lastName}`,
          userId: user._id,
          caseType: CaseTypes.SELF_CERTIFICATION,
          existingCaseId:
            doesCaseExist && doesCaseExist.caseId ? doesCaseExist.caseId : null,
        },
        { disableClose: true },
      );
    } else if (proceedToExistingCase.isConfirmed) {
      if (doesCaseExist.exists && doesCaseExist.caseId) {
        this.routeToCaseDetail(doesCaseExist.caseId, true);
      } else {
        this.router.navigate([RouteSegments.MESSAGE_CENTER], {
          queryParams: null,
        });
      }
    }
  }

  async openReportIssueDialog(): Promise<void> {
    const leaderExists = await this.checkLeaderExists();
    if (!leaderExists) {
      return;
    }
    let user;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));
    this.dialogHelperService.openDialog(
      DialogId.REPORT_ISSUE,
      ReportIssueComponent,
      DialogClassName.WR_DIALOG_AUTO_SIZE,
      {
        caseNumber: '',
        caseUser: `${user.firstName}  ${user.lastName}`,
        userId: user._id,
        caseType: CaseTypes.WORKABILITY,
      },
      { disableClose: true },
    );
  }

  async openTemporaryChangeRequestDialog(caseNumber?: string): Promise<void> {
    const leaderExists = await this.checkLeaderExists();
    if (!leaderExists) {
      return;
    }
    let user;
    let proceedToExistingCase: SweetAlertResult | undefined;
    this.userService.getCurrentUser().subscribe((usr) => (user = usr));
    const doesCaseExist = await this.checkIfCaseExists(
      CaseTypes.TEMPORARY_CHANGE_REQUEST,
    );
    if (doesCaseExist && doesCaseExist.exists) {
      proceedToExistingCase = await this.proceedToExistingCase();
    }
    if (
      (proceedToExistingCase && proceedToExistingCase.isDenied) ||
      !proceedToExistingCase
    ) {
      this.dialogHelperService.openDialog(
        DialogId.TEMPORARY_CHANGE_REQUEST,
        TemporaryChangeRequestComponent,
        DialogClassName.WR_DIALOG_AUTO_SIZE,
        {
          caseNumber:
            proceedToExistingCase && proceedToExistingCase.isDenied
              ? caseNumber
              : '',
          caseUser: `${user.firstName}  ${user.lastName}`,
          userId: user._id,
          caseType: CaseTypes.TEMPORARY_CHANGE_REQUEST,
          existingCaseId:
            doesCaseExist && doesCaseExist.caseId ? doesCaseExist.caseId : null,
          type: DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST,
        },
        { disableClose: true },
      );
    } else if (proceedToExistingCase.isConfirmed && doesCaseExist.caseId) {
      this.routeToCaseDetail(doesCaseExist.caseId);
    }
  }

  routeToCaseDetail(caseId: string, triggerTodoTask = false): void {
    this.router.navigate([`${RouteSegments.CASES}/${caseId}`], {
      queryParams: { triggerTodoTask },
    });
  }

  async checkIfCaseExists(
    caseType: CaseTypes,
  ): Promise<ICheckIfCaseExistsResponse> {
    const doesCaseExist = await this.casesService
      .checkIfCaseExists(caseType)
      .toPromise();
    return doesCaseExist;
  }

  proceedToExistingCase(caseType: CaseTypes = null): Promise<SweetAlertResult> {
    const caseStrToTranslate =
      'CONFIRM.CASE.' + caseType.toUpperCase().toString();
    const caseStr = this.translateService.instant(caseStrToTranslate);
    return this.sweetAlertService.showAlert(
      {
        html: `CONFIRM.EXISTING`,
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: 'BUTTON.POST_A_COMMENT',
        denyButtonText: `BUTTON.UPDATE_EXISTING_CASE`,
        confirmButtonColor: '#00c7b2',
      },
      '',
      { caseStr },
    );
  }

  proceedToRecentlyClosedCase(
    caseType: CaseTypes = null,
  ): Promise<SweetAlertResult> {
    const caseStrToTranslate =
      'CONFIRM.CASE.' + caseType.toUpperCase().toString();
    const caseStr = this.translateService.instant(caseStrToTranslate);
    return this.sweetAlertService.showAlert(
      {
        html: `CONFIRM.RECENTLY_CLOSED`,
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: 'BUTTON.START_A_DIALOG',
        denyButtonText: `BUTTON.UPDATE_EXISTING_CASE`,
        confirmButtonColor: '#00c7b2',
      },
      '',
      { caseStr },
    );
  }

  getProfilePicture(): BehaviorSubject<any> {
    return this.profilePictureSubject;
  }

  setProfilePicture(img: any): void {
    this.profilePictureSubject.next(img);
  }

  getWorkspacePicture(): BehaviorSubject<any> {
    return this.workspacePictureSubject;
  }

  setWorkspacePicture(img: any): void {
    this.workspacePictureSubject.next(img);
  }
}
