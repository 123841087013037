<div *ngIf="showMe">
<div
  *ngIf="summaryControls && summaryControls[0] && summaryControls[0].key && summaryControls[0].key != 'unableTodoTasks';
    else normalSummaryItem
  "
  class="modal-body-inner"
>
  <mat-accordion class="example-headers-align accordion-custom scheduler-summary" multi>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">{{ summaryControls[0].description }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <wr-document-summary-card-details-selector [summaryControls]="summaryControls">
          </wr-document-summary-card-details-selector>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #normalSummaryItem>
  <mat-accordion class="example-headers-align accordion-custom scheduler-summary" multi *ngIf="summaryControls && summaryControls[0]">
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">{{
          summaryControls && summaryControls[0] && summaryControls[0].description || summaryControls[0].heading || ''
        }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <wr-document-summary-card-details-selector [summaryControls]="summaryControls">
          </wr-document-summary-card-details-selector>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
</div>