<div class="d-flex">
  <mat-form-field appearance="fill" class="me-2 search-input w-100">
    <mat-icon color="primary" matPrefix>search</mat-icon>
    <input
      (keyup)="setFilteredList()"
      [(ngModel)]="searchString"
      [placeholder]="'COMMON.SEARCH.TEXT' | translate"
      autocomplete="off"
      matInput
    />
    <mat-icon color="light" matSuffix (click)="clearSearch()">close</mat-icon>
  </mat-form-field>
</div>

<div class="d-flex search-team">
  <mat-list class="custom-mat-list custom-mat-list-hover w-100" role="list">
    <mat-list-item
      (click)="handleSelectionChange(item.value)"
      *ngFor="let item of filteredOptions"
      class="bg-light"
      role="listitem"
    >
      <div class="text-truncate-depend gap-2 align-items-center d-flex w-100">
        <span class="avatar avatar-table">
          <figure
            [ngStyle]="{ 'background-image': 'url(' + item?.photo + ')' }"
          ></figure>
          <figcaption>
            {{ item.label | nameInitials }}
          </figcaption>
        </span>
        <div class="w-100">
          <div class="text-truncate">
            {{ item.label }}
          </div>
        </div>
      </div>
      <mat-icon
        *ngIf="item.value === selectedValue"
        class="icon-circle icon-circle-sm bg-primary ms-auto"
        >check
      </mat-icon>
      <!--            <mat-icon class="icon-circle icon-circle-sm bg-secondary ms-1"-->
      <!--            >close-->
      <!--            </mat-icon>-->
    </mat-list-item>
  </mat-list>
</div>
