export enum Languages {
  ENGLISH = 'en',
  NORWEGIAN = 'nb',
}

export interface SelectOption<T> {
  label: string;
  value: T;
  disabled?: boolean;
  icon?: string;
}

export interface ArrayResponse<T> {
  count: number;
  data: T[];
}

export enum TokenKey {
  ACCESS = 'accessToken',
  CONVERSATION = 'conversationsToken',
  PERMISSIONS = '__p',
  TERMSANDCONDITION = 'tnc',
  WORKSPACE_STATUS = 'wsstatus',
  ADMIN_SETUP_STATUS = 'castat',
  SCREENSHOT = 'screenshot',
}

export interface ICountryListRes {
  count: number;
  data: { code: string; name: string }[];
}
