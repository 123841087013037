<div class="container template-builder-container d-flex gap-5 mb-0">
  <div class="template-builder-elements minimize rounded overflow-auto" *ngIf="showElementSelection">
    <div class="template-builder-elements-header d-flex mb-3">
      <span class="text-truncate w-100 me-2">Elements</span>
      <mat-icon>unfold_less</mat-icon>
    </div>
    <mat-card class="mb-3">
      <!-- <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> This is the expansion title </mat-panel-title>
            <mat-panel-description>
              This is a summary of the content
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>This is the primary content of the panel.</p>
        </mat-expansion-panel>
      </mat-accordion> -->
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
    </mat-card>
    <mat-card class="mb-3">
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
    </mat-card>
    <mat-card class="mb-3">
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
      <div class="template-builder-element rounded text-center">
        <img src="../../../assets/images/png/tb-rating.png" />
      </div>
    </mat-card>
  </div>
  <div class="template-builder flex-grow-1 position-relative overflow-hidden">
      <wr-question-builder (showHideElementSelection)="showElementSelection = $event"></wr-question-builder>
    <button class="template-builder-save-btn" mat-button>Save</button>
<!--    <div-->
<!--      class="tb-pages-container-minimize tb-pages-container position-absolute bottom-0 w-100 text-center"-->
<!--    >-->
<!--        <span class="rounded-top tb-pages-pin d-inline-flex align-items-center"-->
<!--        >Pages <mat-icon>push_pin</mat-icon></span-->
<!--        >-->
<!--      <div class="d-flex gap-3 overflow-auto p-3 rounded-top tb-pages">-->
<!--        <div class="tb-pages-preview-container">-->
<!--          <div class="tb-pages-preview rounded overflow-hidden">-->
<!--            <img src="../../../assets/images/png/pages-preview.png" />-->
<!--          </div>-->
<!--          <div class="tb-pages-preview-title text-truncate w-100 mt-1">-->
<!--            Title will goes here-->
<!--          </div>-->
<!--        </div>-->
<!--        <div-->
<!--          class="tb-pages-add rounded d-flex align-items-center justify-content-center"-->
<!--        >-->
<!--          <mat-icon>add</mat-icon>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>


