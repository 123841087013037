import {DocumentTemplateUniqueIdentifier} from "@app/modules/case-page/models/case-statuses.model";

export interface INotificationResponse{
  _id: string,
  status: StatusEnum,
  caseId: string,
  caseType?: any,
  notificationType: NotificationTypeEnum,
  eventName: NotificationEventName,
  userWorkspaceId: string,
  user: IUserResponse,
  content: {
    feed: {
      feedId: string
    },
    nextTask: { nextStatus: string; currentStatus: string; dueDate: string; type: DocumentTemplateUniqueIdentifier }
    case: any
  }
  createdAt: any;
}

export interface IUserResponse{
  userId: string,
  name: string,
  photo: string
}

export enum StatusEnum{
  READ = 1,
  UNREAD = 0,
}

export enum NotificationTypeEnum {
  TASK = 'TASK',
  CASE = 'CASE',
  STATUS_UPDATE = 'STATUS_UPDATE',
  NEW_FEED = 'NEW_FEED',
  NEW_COMMENT = 'NEW_COMMENT',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  REMINDER = 'REMINDER',
}
export enum NotificationEventName {
  CASES_CREATED = 'cases.created',
  CASES_CLOSED = 'cases.closed',
  CASES_DOCUMENT_ADDED = 'cases.documents.added-one',
  CASES_DOCUMENT_ADDED_NOTIFICATION = 'cases.documents.added-one.notification',
  CASES_SET_WORKABILITY_STATUS = 'cases.set-workability-status',
  CASES_NEW_SICK_LEAVE_WORKABILITY_DOCUMENT_ADDED = 'cases.documents.new-sick-leave.new-workability.added-one',
  TASKS_CREATED = 'tasks.created',
  TASKS_CLOSED = 'tasks.closed',
  NEW_FEED = 'feeds.new',
  NEW_COMMENT = 'comments.new',
  TEAMSPACE_TRANSFER = 'teamspace.transferred',
  CREATE_CHANNEL = 'create.channel',
  UPDATE_CHANNELS_ON_TRANSFER = 'channels.userWorkspace.transfer-status',
  ADD_CHAT_HISTORY = 'chatBot.history.add',
  SEND_EMAIL = 'mail.send',
  HR_UPDATED = 'teamspace.hr.updated',
  TASKS_EXPIRED = 'tasks.expired',
  TASKS_DUE_TODAY = 'tasks.due-today',
}
