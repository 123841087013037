import { Component, Input, OnInit } from '@angular/core';
import { DocumentSummaryData } from '@app/shared/documents.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-sick-leave-summary',
  templateUrl: './sick-leave-summary.component.html',
  styleUrls: ['./sick-leave-summary.component.scss'],
})
export class SickLeaveSummaryComponent implements OnInit {
  @Input() summaryTitle: string;

  @Input() set summaryControls(value: { [key: string]: DocumentSummaryData }) {
    this.formattedValue = Object.values(value).reduce((acc, val) => ((acc[val.key] = val), acc), {});
  }

  get summaryControls(): { [key: string]: DocumentSummaryData } {
    return this.formattedValue;
  }

  @Input() userId: string;

  private formattedValue: { [key: string]: DocumentSummaryData };

  constructor(private readonly iconsHelperService: IconsHelperService) {
    this.iconsHelperService.registerSvgIcons('summary-current');
  }

  ngOnInit(): void {}
}
