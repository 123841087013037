import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import {Languages, SelectOption} from '@app/shared/common.model';
import {DocumentsControls} from '@app/shared/documents.model';
import {DocumentFormConfigText} from '@app/modules/forms/models/document-templates.model';

@Component({
    selector: 'wr-control-selector',
    templateUrl: './control-selector.component.html',
    styleUrls: ['./control-selector.component.scss'],
})
export class ControlSelectorComponent {
    private readonly selectedLanguage: Languages;
    private _label: string;
    private _additionalInfo: string;
    private _max: {
        value: number;
        errorMessage: DocumentFormConfigText | string;
    };
    _documentControl: DocumentsControls;
    @Input() set documentControl(control) {
        this._documentControl = control
    }
    get documentControl(): DocumentsControls {
        return this._documentControl
    }
    @Input() control: AbstractControl;
    @Input() controlDesc: string;
    @Input() description: string;
    @Input() title: string;
    @Input() icon: string;
    @Input() panelSteps: any;
    @Input() addNewItemText: string;
    @Input() placeholder: string;
    @Input() defaultValue: string;
    @Input() rowsCount: number;
    @Input() uniqueIdentifier: string;
    @Input() prevStepData: UntypedFormGroup;
    @Input() autofocus: boolean;
    @Input() caseEndDate: string;
    @Input() color: string;
    @Input() size: string;

    @Input()
    set label(value: string | string[]) {
        if (value) {
            if (typeof value[this.selectedLanguage] === 'string') {
                this._label = value[this.selectedLanguage];
            } else if (Array.isArray(value)) {
                this.translatedLabels =
                    typeof value[0] === 'string' ? value : value.map((label) => label[this.selectedLanguage]);
            }
        }
    }

    get label(): string | string[] {
        return this._label;
    }

    @Input()
    set additionalInfo(value: string | DocumentFormConfigText) {
        if (value) {
            if (typeof value === 'string') {
                this._additionalInfo = value;
            } else {
                this._additionalInfo = value[this.selectedLanguage];
            }
        }
    }

    get additionalInfo(): string | DocumentFormConfigText {
        return this._additionalInfo;
    }

    @Input()
    set options(
        options: {
            label: { [key: string]: string };
            value: any;
        }[],
    ) {
        if (options) {
            this.translatedOptions = options.map((option) => ({
                ...option,
                label: (option.label[this.selectedLanguage] || option.label) as string,
            }));
        }
    }
    @Output() valueChange = new EventEmitter<any>();

    @Input()
    set max(value: { value: number; errorMessage: DocumentFormConfigText | string }) {
        const valueCopy = value ? {...value} : null;
        if (valueCopy && typeof valueCopy.errorMessage === 'object') {
            valueCopy.errorMessage = valueCopy.errorMessage[this.selectedLanguage];
        }
        this._max = valueCopy;
    }


    get max(): {
        value: number;
        errorMessage: DocumentFormConfigText | string;
    } {
        return this._max;
    }

    documentControls: typeof DocumentsControls = DocumentsControls;
    translatedOptions: SelectOption<any>[];
    translatedLabels: string[];

    constructor(private readonly translateService: TranslateService) {
        this.selectedLanguage = this.translateService.currentLang as Languages;

    }


    schedulerChangedEvent(data: any): void {
        this.control.setValue(data);
    }
}
