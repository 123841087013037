import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wr-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit{
  @Input() type: 'number' | 'star' = 'number'
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() autofocus: boolean;
  @Input() value: number;
  @Input() minValue: number = 1;
  @Input() maxValue: number = 5;
  @Output() valueChange = new EventEmitter<number>();


  ratingArr = [];

  ngOnInit(): void {
    for (let index = this.minValue; index <= this.maxValue; index++) {
      this.ratingArr.push(index);
    }
  }
  onChange(val: number) {
    this.value = val;
    this.valueChange.emit(val);
    this.getRatingSrc(1);
  }

  getRatingSrc(index: number): string {
    if (this.value >= this.minValue + index) {
      return '../../../../assets/images/svg/star-fill.svg';
    } else {
      return '../../../../assets/images/svg/star-outline.svg';
    }
  }

}
