<mat-nav-list class="p-3">
  <ng-container *ngFor="let sidenavItem of data.sidenavItems">
    <div
      (click)="onSidenavItemClick(sidenavItem)"
      class="d-flex align-items-center gap-4 py-3"
    >
      <mat-icon
        [svgIcon]="sidenavItem.icon"
        class="text-primary fs-2"
        mat-list-icon
      ></mat-icon>

      <span class="fs-6" mat-line>{{sidenavItem.labelKey | translate }}</span>
    </div>
  </ng-container>
</mat-nav-list>
