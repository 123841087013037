import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../../services/common/permissions.service';
import { RouteSegments } from '../../models/routing.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = route.data.permissions as string[];
    if (!permissions || permissions.length === 0) {
      // No permissions required, allow access
      return true;
    }

    // Check if the user has all required permissions
    const hasPermission = this.permissionService.hasPermission(permissions);
    if (!hasPermission) {
      // Redirect to a page indicating unauthorized access
      this.router.navigate([RouteSegments.FORBIDDEN]);
    }
    return hasPermission;
  }
}
