import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IInviteEmployeeFormConfig,
  IInviteEmployeeFormData,
  IInviteEmployeeFormDataReturn,
} from '@app/modules/setup/setup.model';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { OnboardingTeamAddComponent } from '@app/modules/setup/components/onboarding/onboarding-team-add/onboarding-team-add.component';
import { filter } from 'rxjs/operators';
import { RoleName } from '@app/constants/role.constants';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { SelectExistingUserWithSearchComponent } from '@app/modules/setup/components/onboarding/onboarding-hierarchy-setup/select-existing-user-with-search/select-existing-user-with-search.component';
import { TeamViewEnum } from './common-team-add-display/common-team-add-display.component';
import { User } from '@app/modules/shared/models/user.model';
import { SelectExcelUserComponent } from '@app/modules/setup/components/onboarding/onboarding-hierarchy-setup/select-excel-user/select-excel-user.component';

export enum EInviteUserHierarchy {
  HEAD = 'head',
  HR = 'hr',
  DIRECTOR = 'director',
  HR_INVITEE = 'hr-invitee',
  OTHERS = 'others',
}

export interface Section {
  title: string;
  description: string;
  data: any[];
  type: EInviteUserHierarchy;
  mode: TeamViewEnum;
  multiple: boolean;
  tier: number;
  roles: RoleName[];
  visible: boolean;
}
@Component({
  selector: 'wr-onboarding-hierarchy-setup',
  templateUrl: './onboarding-hierarchy-setup.component.html',
  styleUrls: ['./onboarding-hierarchy-setup.component.scss'],
})
export class OnboardingHierarchySetupComponent implements OnInit, OnChanges {
  @Output()
  preparedInviteListChanged = new EventEmitter<IInviteEmployeeFormData[]>();

  @Input()
  existingInviteList: IInviteEmployeeFormData[] = [];

  @Input()
  excelInviteList: IInviteEmployeeFormData[] = [];

  @Input()
  readOnlyMode = false;

  @Input()
  currentUser: User;

  sections: Section[] = [
    {
      title: 'SETUP.ORGANISATION.SINGLE_POINT_TOP_LEADER.LABEL',
      description: 'SETUP.ORGANISATION.SINGLE_POINT_TOP_LEADER.DESCRIPTION',
      data: [],
      type: EInviteUserHierarchy.HEAD,
      mode: TeamViewEnum.ADD,
      multiple: false,
      tier: 1,
      roles: [RoleName.WORKSPACE_ADMIN],
      visible: true,
    },
    {
      title: 'SETUP.ORGANISATION.DEPARTMENT_HEADS.LABEL',
      description: 'SETUP.ORGANISATION.DEPARTMENT_HEADS.DESCRIPTION',
      data: [],
      mode: TeamViewEnum.ADD,
      type: EInviteUserHierarchy.DIRECTOR,
      multiple: true,
      tier: 2,
      roles: [RoleName.WORKSPACE_ADMIN],
      visible: true,
    },
    {
      title: 'SETUP.ORGANISATION.HR_FUNCTION.LABEL',
      description: 'SETUP.ORGANISATION.HR_FUNCTION.DESCRIPTION',
      data: [],
      mode: TeamViewEnum.SEARCH,
      type: EInviteUserHierarchy.HR,
      multiple: false,
      tier: 3,
      roles: [RoleName.HR, RoleName.WORKSPACE_ADMIN],
      visible: true,
    },
    {
      title: 'SETUP.ORGANISATION.HR_PERSONNEL.LABEL',
      description: 'SETUP.ORGANISATION.HR_PERSONNEL.DESCRIPTION',
      data: [],
      mode: TeamViewEnum.ADD,
      type: EInviteUserHierarchy.HR_INVITEE,
      multiple: true,
      tier: 4,
      roles: [RoleName.HR, RoleName.WORKSPACE_ADMIN],
      visible: true,
    },
    {
      title: 'SETUP.ORGANISATION.OTHERS.LABEL',
      description: 'SETUP.ORGANISATION.OTHERS.DESCRIPTION',
      data: [],
      mode: TeamViewEnum.READONLY,
      type: EInviteUserHierarchy.OTHERS,
      multiple: true,
      tier: 5,
      roles: [],
      visible: false,
    },
  ];
  EInviteUserHierarchy = EInviteUserHierarchy;
  preparedData: IInviteEmployeeFormData[] = [];
  constructor(private dialogHelperService: DialogHelperService) {}

  ngOnInit(): void {
    console.log('12', this.excelInviteList);
    this.parseExistingInviteList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('1', this.excelInviteList);
    if (changes.existingInviteList) {
      this.parseExistingInviteList();
    }
  }
  parseExistingInviteList() {
    if (this.existingInviteList.length > 0) {
      this.sections.forEach((section) => {
        section.data = this.existingInviteList.filter((x) =>
          x.tier.includes(section.tier),
        );
      });
      this.prepareDataToEmit();
    }
    console.log(this.sections);
  }

  addTeam(hierarchy: EInviteUserHierarchy): void {
    const hierarchySection = this.sections.find((x) => x.type == hierarchy);
    const inviteFormConfig: IInviteEmployeeFormConfig = {
      isNameRequired: false,
      backButton: false,
      autoSave: false,
      promptSaveAsAdmin: this.promptSaveAsAdmin(hierarchy),
      isLeaderRequired: false,
      saveAsAdminLabel: hierarchySection.title,
    };
    this.dialogHelperService
      .openDialog(
        DialogId.ONBOARDING_TEAM_ADD,
        OnboardingTeamAddComponent,
        [
          DialogClassName.WR_DIALOG_SMALL,
          DialogClassName.WR_DIALOG_HIDE_STEPPER,
          DialogClassName.WR_DIALOG_CONTENT_TOP_NEGATIVE,
        ],
        { inviteFormConfig },
        { disableClose: true },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((dialogResult: IInviteEmployeeFormDataReturn) => {
        if (dialogResult && typeof dialogResult === 'object') {
          if (!this.checkIfEmailExists(hierarchy, dialogResult.data.email)) {
            const data: IInviteEmployeeFormData = {
              ...dialogResult.data,
              userType: hierarchySection.roles,
              tier: [hierarchySection.tier],
            };
            hierarchySection.data.push(data);
          }
          this.prepareDataToEmit();
        }
      });
  }
  initiateSelectExistingUser(hierarchy: EInviteUserHierarchy): void {
    const listUniqueByEmail = this.preparedData.reduce((acc, obj) => {
      if (!acc.find((item) => item.email === obj.email)) {
        acc.push(obj);
      }
      return acc;
    }, []);
    if (this.promptSaveAsAdmin(hierarchy)) {
      this.addCurrentUserIntoSearch(listUniqueByEmail);
    }
    this.dialogHelperService
      .openDialog(
        DialogId.ONBOARDING_SELECT_EXISTING_USER,
        SelectExistingUserWithSearchComponent,
        [
          DialogClassName.WR_DIALOG_SMALL,
          DialogClassName.WR_DIALOG_HIDE_STEPPER,
          DialogClassName.WR_DIALOG_CONTENT_TOP_NEGATIVE,
        ],
        { preparedInviteList: [...listUniqueByEmail, ...this.excelInviteList] },
        { disableClose: false },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((dialogResult: IInviteEmployeeFormData) => {
        if (!this.checkIfEmailExists(hierarchy, dialogResult.email)) {
          const hierarchySection = this.sections.find(
            (x) => x.type == hierarchy,
          );
          dialogResult.userType = hierarchySection.roles;
          dialogResult.tier = [hierarchySection.tier];
          hierarchySection.data.push(dialogResult);
          this.prepareDataToEmit();
        }
      });
  }

  initiateSelectExcelUser(hierarchy: EInviteUserHierarchy): void {
    const userList = JSON.parse(JSON.stringify(this.preparedData));
    const listUniqueByEmail = userList.reduce((acc, obj) => {
      if (!acc.find((item) => item.email === obj.email)) {
        acc.push(obj);
      }
      return acc;
    }, []);
    if (this.promptSaveAsAdmin(hierarchy)) {
      this.addCurrentUserIntoSearch(listUniqueByEmail);
    }
    const hierarchySection = this.sections.find((x) => x.type == hierarchy);
    this.dialogHelperService
      .openDialog(
        DialogId.ONBOARDING_SELECT_EXISTING_USER,
        SelectExcelUserComponent,
        [
          DialogClassName.WR_DIALOG_SMALL,
          DialogClassName.WR_DIALOG_HIDE_STEPPER,
          DialogClassName.WR_DIALOG_CONTENT_TOP_NEGATIVE,
        ],
        {
          preparedInviteList: listUniqueByEmail,
          title: hierarchySection.title,
          multiSelect: hierarchySection.multiple,
        },
        { disableClose: false },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((dialogResults: IInviteEmployeeFormData[]) => {
        console.log('existin old', this.existingInviteList);
        console.log('existin', dialogResults);
        console.log('section old', this.sections);

        for (let index = 0; index < dialogResults.length; index++) {
          const dialogResult = dialogResults[index];
          if (!this.checkIfEmailExists(hierarchy, dialogResult.email)) {
            dialogResult.userType = hierarchySection.roles;
            dialogResult.tier = [hierarchySection.tier];
            hierarchySection.data.push(dialogResult);
            this.prepareDataToEmit();
          }
        }
        console.log('existing', this.existingInviteList);

        console.log('section', this.sections);
      });
  }

  addCurrentUserIntoSearch(users: IInviteEmployeeFormData[]): void {
    if (!users.find((x) => x.email === this.currentUser.email)) {
      const currUser: IInviteEmployeeFormData = {
        email: this.currentUser.email,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        userType: [this.currentUser.userRole],
        saveAsAdmin: true,
        userWorkspaceId: this.currentUser._id,
      };
      users.push(currUser);
    }
  }

  prepareDataToEmit(): void {
    let preparedData = [];
    this.sections.forEach((x) => {
      preparedData = [...preparedData, ...x.data];
    });
    this.preparedData = preparedData;
    console.log('prepared data', this.preparedData);
    this.preparedInviteListChanged.emit(preparedData);
  }
  clearTeamData(tier: number, index: number): void {
    this.sections.find((x) => x.tier === tier).data.splice(index, 1);
    this.prepareDataToEmit();
  }

  promptSaveAsAdmin(hierarchy: EInviteUserHierarchy): boolean {
    return !this.checkIfEmailExists(hierarchy, this.currentUser.email);
  }

  checkIfEmailExists(hierarchy: EInviteUserHierarchy, email: string): boolean {
    const leaders = [EInviteUserHierarchy.DIRECTOR, EInviteUserHierarchy.HEAD];
    const hrs = [EInviteUserHierarchy.HR, EInviteUserHierarchy.HR_INVITEE];
    const arrayToCheck = leaders.includes(hierarchy) ? leaders : hrs;
    const sectionList = this.sections.filter((x) =>
      arrayToCheck.includes(x.type),
    );
    const filteredLists = sectionList.filter((section) =>
      section.data.find((item) => item.email === email),
    );
    return filteredLists?.length > 0;
  }

  companyAdminData(): IInviteEmployeeFormData {
    const filteredLists = this.sections.find((section) =>
      section.data.find((item) => item.email === this.currentUser.email),
    );
    let data: IInviteEmployeeFormData;
    if (!filteredLists) {
      data = {
        email: this.currentUser?.email,
        firstName: this.currentUser?.firstName,
        lastName: this.currentUser?.lastName,
        userType: [this.currentUser?.userRole],
      };
    }
    return data;
  }
}
