<div
  *ngIf="
    summaryControl && summaryControl.value && summaryControl.value.length > 0
  "
>
  <h4
    class="card-details__title"
    *ngIf="summaryControl.heading && summaryControl.heading !== ''"
  >
    {{ summaryControl.heading }}
  </h4>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'bullet'">
      <ng-container *ngFor="let value of summaryControl.value; let i = index">
        <div class="card-details__value__container" *ngIf="value; else noData">
          <span class="card-details__value__text"
            ><mat-icon class="card-details__value__icon" color="primary">{{
              icon
            }}</mat-icon
            >{{
              summaryControl.selectOptions[value] || value | translate
            }}</span
          >
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tags'">
      <div class="d-flex gap-3"></div>
      <ng-container *ngFor="let value of summaryControl.value; let i = index">
        <span class="badge" *ngIf="value; else noData">{{
          (summaryControl &&
          summaryControl.selectOptions &&
          summaryControl.selectOptions[value]
            ? summaryControl.selectOptions[value]
            : value
          ) | translate
        }}</span>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #noData>
  <span *ngIf="i === 0" class="card-details__no-value__text">{{
    summaryControl.noValueInfo || '-'
  }}</span>
</ng-template>
