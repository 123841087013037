import {Component, OnInit} from "@angular/core";
import {SelectOption} from "@app/shared/common.model";

@Component({
  selector: 'wr-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit{
  stringTextValue = "";
  addressTextValue = "first";
  numberTextValue = 1;
  booleanValue = true;
  dateValue = '';
  timeValue = '13:33';
  sliderValue = 0
  arrayStringValue = ['first'];
  chipsValue = ['first'];
  singleStringValue = 'first';
  testSelectOption: SelectOption<string>[] = [
    {label: 'First item', value: 'first', icon: 'library_add'},
    {label: 'Second item', value: 'second', icon: 'edit'}
  ]

  workHourValue = {};
  constructor() {

  }

  ngOnInit() {
  }
  test(dd) {
    return JSON.stringify(dd);
  }
}
