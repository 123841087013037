import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { RouteSegments } from '@app/shared/routing.model';
import { IUserWorkspaceList, User } from '@app/shared/user.model';
import { Router } from '@angular/router';
import { UserService } from '@app/shared/user/user.service';
import { WorkspaceService } from '@app/shared/workspace/workspace.service';
import { JwtResponse } from '@app/modules/shared/models/jwt.model';

@Component({
  selector: 'wr-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  routeSegments: typeof RouteSegments = RouteSegments;

  @Input() isAdmin: boolean;
  @Input() imgUrl: string;
  @Input() currentUser: User;
  @Input() companyId: string;
  @Output() routeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  currentWorkspace: IUserWorkspaceList;
  userWorkspaceList: IUserWorkspaceList[] = [];
  currentUserWorkspaceId: string;
  currentCompanyId: string;
  accessData: JwtResponse;
  constructor(
    private userService: UserService,
    private router: Router,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit(): void {
    this.accessData = this.userService.getAuthTokenData();
    this.currentUserWorkspaceId = this.accessData.userWorkspaceId;
    this.currentCompanyId = this.accessData.companyId;
    this.getActiveWorkSpaces();
  }
  navigate(route: string): void {
    this.routeClicked.emit(true);
    this.router.navigate([route]);
  }

  onLogoutClick(): void {
    this.userService.logout();
  }

  async getActiveWorkSpaces(): Promise<void> {
    const userWorkspaces = await this.workspaceService.fetchUserWorkspaces(
      'active',
    );
    this.currentWorkspace = userWorkspaces.find(
      (x) => x._id === this.currentUserWorkspaceId,
    );
    this.userWorkspaceList = userWorkspaces.filter(
      (x) => x._id !== this.currentUserWorkspaceId,
    );
  }

  switchWorkspace(workspaceId: string): void {
    this.userService.switchWorkspace(workspaceId);
  }
}
