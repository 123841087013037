<wr-generic-stepper-dialog
  (selectedStep)="selectedStep = $event; selectedStepChange.emit($event)"
  (submitted)="onSubmit()"
  (skipped)="onSkip()"
  [stepControls]="stepControls"
  (runStepChangeAction)="callOnStepChangeActions($event)"
  [isSummaryDisabled]="isSummaryDisabled"
  [showHeaderTitle]="showHeaderTitle"
  [employee]="employee"
  [documentTypeLabel]="documentTypeLabel"
  [caseType]="caseType"
  [showHeaderTitle]="true"
  [data]="steps"
  [hasAdditionalStep]="hasAdditionalStep"
  [isAdditionalStepSummary]="isAdditionalStepSummary"
  [hasAdditionalEndStep]="hasAdditionalEndStep"
  [allowSkip]="allowSkip"
>
  <ng-container title>
    <div class="modal-top-bar">
      <wr-app-header></wr-app-header>
    </div>
  </ng-container>

  <ng-container *ngIf="hasAdditionalStep">
    <ng-template #step>
      <ng-content select="[additionalStep]"></ng-content>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="steps">
    <ng-container *ngFor="let documentStep of steps; let stepIndex = index">
      <ng-container
        *ngIf="
          documentStep.isSummary ||
            documentStep.isSickLeaveSummary ||
            documentStep.isResidualWorkCapacity ||
            documentStep.isWorkplaceAccommodation;
          else controlsStep
        "
      >
        <ng-template #step>
          <ng-container *ngIf="documentStep.isSickLeaveSummary">
            <wr-document-summary
              [documentSummaryData]="summary1"
              [caseDocumentData]="caseDocumentData1"
              [documentTypeLabel]="'documentTypeLabel' | translate"
              [caseNumber]="caseNumber"
              [userId]="userId"
              [reportType]="docTemplateUniqueIdentifier.SICK_LEAVE"
              [caseId]="caseId"
              [formGroup]="formGroup1"
              [steps]="steps1"
              class="mx-n3"
            >
            </wr-document-summary>
          </ng-container>
          <ng-container *ngIf="documentStep.isResidualWorkCapacity">
            <wr-document-summary
              [documentSummaryData]="summary2"
              [caseDocumentData]="caseDocumentData2"
              [documentTypeLabel]="'documentTypeLabel' | translate"
              [caseNumber]="caseNumber"
              [userId]="userId"
              [reportType]="docTemplateUniqueIdentifier.RESIDUAL_WORK_CAPACITY"
              [caseId]="caseId"
              [formGroup]="formGroup2"
              [steps]="steps2"
              class="mx-n3"
            >
            </wr-document-summary>
          </ng-container>
          <ng-container *ngIf="documentStep.isWorkplaceAccommodation">
            <wr-document-summary
              [documentSummaryData]="summary3"
              [caseDocumentData]="caseDocumentData3"
              [documentTypeLabel]="'documentTypeLabel' | translate"
              [caseNumber]="caseNumber"
              [userId]="userId"
              [reportType]="docTemplateUniqueIdentifier.WORKPLACE_ACCOMMODATION"
              [caseId]="caseId"
              [formGroup]="formGroup3"
              [steps]="steps3"
              class="mx-n3"
            >
            </wr-document-summary>
          </ng-container>
          <ng-container *ngIf="documentStep.isSummary">
            <wr-document-summary
              [documentSummaryData]="summary"
              [summaryTitle]="summaryTitle"
              [caseNumber]="caseNumber"
              [employee]="employee"
              [userId]="userId"
              [documentTypeLabel]="documentTypeLabel | translate"
              [reportType]="reportType"
              [formGroup]="formGroup"
              [caseDocumentData]="caseDocumentData"
              [steps]="steps"
            >
            </wr-document-summary>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #controlsStep>
        <ng-template #step>
          <form *ngIf="formGroup" [formGroup]="formGroup">
            <ng-container
              *ngFor="let control of documentStep.controls; let i = index"
            >
              <wr-control-selector
                [control]="
                  control && control.key && control.key[0]
                    ? formGroup
                        .get('step' + documentStep.step)
                        ?.get(
                          control.key[0].length > 1
                            ? 'control' + i
                            : control.key
                        )
                    : null
                "
                [placeholder]="
                  control.options?.placeholder
                    ? control.options?.placeholder[selectedLanguage]
                    : ''
                "
                [documentControl]="control.type"
                [options]="control.options?.selectOptions"
                [label]="control.options?.label"
                [additionalInfo]="control.options?.additionalInfo"
                [title]="control?.title"
                [controlDesc]="control?.description"
                [icon]="control?.icon"
                [size]="control?.options?.size"
                [panelSteps]="control?.panelSteps"
                [description]="
                  documentStep.description && i === 0
                    ? documentStep.description[selectedLanguage]
                    : ''
                "
                [addNewItemText]="
                  control.options?.addNewItemLabel
                    ? control.options.addNewItemLabel[selectedLanguage]
                    : ''
                "
                [color]="
                  control.options?.color ? control.options.color : 'primary'
                "
                [max]="control.options?.max"
                [rowsCount]="control.options?.rowCount || rowsCount"
                [uniqueIdentifier]="uniqueIdentifier"
                [prevStepData]="formGroup.get('step0')"
                [autofocus]="i == 0"
                [caseEndDate]="caseEndDate"
                (valueChange)="handleValueChange(control.key)"
                [defaultValue]="
                  control.options?.defaultValue
                    ? control.options?.defaultValue[selectedLanguage]
                    : ''
                "
              ></wr-control-selector>
            </ng-container>
          </form>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hasAdditionalEndStep && steps">
    <ng-template #step>
      <ng-content select="[additionalEndStep]"></ng-content>
    </ng-template>
  </ng-container>
</wr-generic-stepper-dialog>
