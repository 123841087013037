import { Component, Inject, OnInit } from '@angular/core';
import { IInviteEmployeeFormData } from '@app/modules/setup/setup.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogId } from '@app/shared/dialogs.model';

@Component({
  selector: 'wr-select-excel-user',
  templateUrl: './select-excel-user.component.html',
  styleUrls: ['./select-excel-user.component.scss'],
})
export class SelectExcelUserComponent implements OnInit {
  filteredTeamData: IInviteEmployeeFormData[] = [];
  searchString = '';
  teamHrData: IInviteEmployeeFormData[] = [];
  constructor(
    private dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      preparedInviteList: IInviteEmployeeFormData[];
      title: string;
      multiSelect: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.setFilteredTeamData();
  }
  clearSearch(): void {
    this.searchString = '';
    this.setFilteredTeamData();
  }

  setFilteredTeamData(): void {
    console.log('22222', this.data);
    const lowerSearchText = this.searchString.toLowerCase();
    // Use the filter method to filter objects based on the search text
    this.filteredTeamData = this.data.preparedInviteList.filter((person) => {
      // Combine first name, last name, and email for comprehensive search
      const fullText =
        `${person.firstName} ${person.lastName} ${person.email}`.toLowerCase();

      // Check if the full text includes the search text
      return fullText.includes(lowerSearchText);
    });
  }

  addRemoveEmployee(data: IInviteEmployeeFormData) {
    if (this.data.multiSelect) {
      const empData = this.teamHrData?.find((x) => x.email === data.email);
      if (empData) {
        this.teamHrData = this.teamHrData.filter((x) => x.email != data.email);
      } else {
        this.teamHrData.push(data);
      }
    } else {
      this.teamHrData = [data];
    }
  }

  isSelected(data: IInviteEmployeeFormData) {
    return this.teamHrData?.find((x) => x.email === data.email);
  }

  handleSubmit(): void {
    if (this.teamHrData) {
      this.dialogHelperService.closeDialog(
        DialogId.ONBOARDING_SELECT_EXISTING_USER,
        { dialogResult: this.teamHrData },
      );
    }
  }
}
