import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { takeUntil } from 'rxjs/operators';

import {
  DocumentsIdentifiers,
  DocumentStatus,
  DocumentSummaryData,
} from '@app/shared/documents.model';
// import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DialogId } from '@app/shared/dialogs.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import {
  AGREEMENT_TITLES,
  AGREEMENT_TYPE_LABELS,
} from '@app/shared/constants/documents.constants';
import { UntypedFormControl } from '@angular/forms';
import { BREAKPOINT_MEDIUM_SCREEN } from '@app/shared/constants/breakpoints.constants';
// import {DocumentBuilderService} from "@app/shared/document-builder/document-builder.service";
import { ResponsivenessService } from '@app/core/services/responsiveness/responsiveness.service';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { ITemplateVersionData } from '@app/modules/content-creator/content-creatorer.model';
import { ContentCreatorService } from '@app/shared/content-creator/content-creator.service';
import { AccessUser } from '@app/modules/case-page/models/access.model';
import { IFollowupData } from '@app/shared/components/scheduler-main/scheduler.component';

@Component({
  selector: 'wr-follow-up-plan',
  templateUrl: './follow-up-plan.component.html',
})
export class FollowUpPlanComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  // private formGroup: FormGroup;

  summary: { [key: string]: DocumentSummaryData };
  documentTypeLabel: string;
  dialogTitle: string;
  mediumScreenBreakpoint = BREAKPOINT_MEDIUM_SCREEN;

  commentField = new UntypedFormControl('');

  templateData: ITemplateVersionData;
  accessUsers: AccessUser[] = [];
  docVersion = 1;

  caseType: string;
  employee: string;

  constructor(
    private readonly casesService: CasesService,
    // private readonly documentTemplatesService: DocumentTemplatesService,
    // private readonly documentBuilderService: DocumentBuilderService,
    public readonly responsivenessService: ResponsivenessService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly contentCreatorService: ContentCreatorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      caseId: string;
      caseNumber: string;
      caseType: string;
      caseUser: string;
      userId: string;
      type: DocumentTemplateUniqueIdentifier;
    },
  ) {}

  async ngOnInit(): Promise<void> {
    this.setTexts();
    // this.getDocumentFormData();
    this.getQuestionTemplates();
    this.accessUsers = await this.casesService
      .getCaseAccessUsers(this.data.caseId)
      .toPromise();
  }
  getQuestionTemplates(): void {
    this.contentCreatorService
      .getTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        this.templateData = templates;
      });
  }
  onSubmit(data: IFollowupData): void {
    this.casesService
      .addDocumentToCase(this.data.caseId, {
        docTemplateUniqueIdentifier: DocumentsIdentifiers.FOLLOW_UP_PLAN,
        docTemplateVersionNumber: this.docVersion,
        ownerId: this.data.userId,
        fields: {
          ...data,
          ...{ timeZone: new Date().getTimezoneOffset() },
        },
        status: DocumentStatus.DRAFT,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.FOLLOW_UP_PLAN, {
          dialogResult: true,
        });
      });
  }

  onClose(): void {
    this.dialogHelperService.closeDialog(DialogId.DOCUMENT_AGREEMENT, {
      dialogResult: false,
    });
  }

  private setTexts(): void {
    this.documentTypeLabel = AGREEMENT_TYPE_LABELS[this.data.type];
    this.dialogTitle = AGREEMENT_TITLES[this.data.type];
    this.caseType = this.data.caseType;
    this.employee = this.data.caseUser;
  }

  // private getDocumentFormData(): void {
  //   this.documentTemplatesService
  //       .getDocumentFormConfig(DocumentsIdentifiers.FOLLOW_UP_PLAN)
  //       .subscribe(workability => {
  //                 const { formGroup } = this.documentBuilderService.buildDocumentFrom(workability);
  //                 this.formGroup = formGroup;
  //       })
  //       // .pipe(
  //       //     switchMap((workability) => {
  //       //
  //       //
  //       //       const { formGroup, summarySubscription } = this.documentBuilderService.buildDocumentFrom(workability);
  //       //       this.formGroup = formGroup;
  //       //
  //       //       this.summarySubscription = summarySubscription;
  //       //       return this.casesService.getCaseDocument(this.data.caseId, this.data.documentId);
  //       //     }),
  //       //     takeUntil(this.onDestroy$),
  //       // )
  //       // .subscribe((document) => {
  //       //
  //       //
  //       //   this.documentBuilderService.updateFormValue(this.formGroup, document.fields);
  //       //   this.summary = (this.formGroup.get('summary') as FormGroup)?.getRawValue();
  //       // });
  // }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }
}
