import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITemplateContent, QuestionTypeEnum} from "@app/modules/content-creator/content-creatorer.model";
import {User} from "@app/shared/user.model";

@Component({
  selector: 'wr-comment-content',
  templateUrl: './comment-content.component.html',
  styleUrls: ['./comment-content.component.scss']
})
export class CommentContentComponent implements OnInit {

  @Input() content: ITemplateContent;
  @Input() assignValue = true;
  @Input() commentMode = false;
  @Input() currentUser: User;
  @Input() fillMode = false;

  @Output() answerFilled = new EventEmitter<null>();
  show = true;
  contentTypes = QuestionTypeEnum

  starCount = 5;
  ratingArr = [];
  constructor() {}

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }

  }
  valueChange(contentValue: any[], option: string | number, event): void {
    if (!contentValue) {
      contentValue = [];
    }
    // set the two-way binding here for the specific unit with the event
    if (event.checked) {
      contentValue.push(option);
    } else {
      const existingOption = contentValue.findIndex((opt) => opt === option);
      if (existingOption >= 0) {
        contentValue.splice(existingOption, 1);
      }
    }
  }

  showIcon(rating: number, index: number): string {
    if (rating >= index + 1) {
      return '../../../../assets/images/svg/star-fill.svg';
    } else {
      return '../../../../assets/images/svg/star-outline.svg';
    }
  }
}
