import { Injectable } from '@angular/core';
import sweetAlert, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor(private translateService: TranslateService) {}
  async showAlert(
    options: SweetAlertOptions,
    additionalTitle?: string,
    data: any = {},
  ): Promise<SweetAlertResult> {
    if (options.text) {
      options.text = this.translateService.instant(options.text);
    }
    if (options.html) {
      let htmlStr = '';
      this.translateService
        .get(options.html.toString(), data)
        .subscribe((text: string) => {
          htmlStr = text;
        });
      options.html = htmlStr;
    }
    if (options.title) {
      options.title =
        typeof options.title === 'string'
          ? this.translateService.instant(options.title)
          : options.title;
    }
    if (options.confirmButtonText) {
      options.confirmButtonText = this.translateService.instant(
        options.confirmButtonText,
      );
    }
    if (options.denyButtonText) {
      options.denyButtonText = this.translateService.instant(
        options.denyButtonText,
      );
    }
    if (additionalTitle) {
      options.title = options.title + ` ${additionalTitle}`;
    }
    const result = await sweetAlert.fire(options);
    return result;
  }
}
