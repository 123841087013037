<div class="congratulations-panel text-center p-5 bg-white">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div [innerHTML]="data.logo"></div>

    <h1 class="text-primary fw-bold mt-2 mb-4">{{ data.title | translate }}</h1>
    <h4 *ngIf="data.subTitle" [innerHTML]="data.subTitle | translate: { companyName: companyName }"></h4>
    <wr-onboarding-stepper [currentStep]="data.index" [teamSpaceStepMode]="data.isTeamSpace"></wr-onboarding-stepper>

    <p class="mb-4">
      <span [innerHTML]="data.messageBottom | translate"></span>
      <b [innerHTML]="data.info | translate"></b>
    </p>
    <button mat-flat-button color="primary" (click)="clicked.emit()">{{ data.btnText | translate }}</button>
  </div>
</div>
