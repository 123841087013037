import { Component, Input } from '@angular/core';

import { DocumentSummaryData } from '@app/shared/documents.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';

@Component({
  selector: 'wr-document-summary-card-details-default',
  templateUrl: './document-summary-card-details-default.component.html',
  styleUrls: ['./document-summary-card-details-default.component.scss'],
})
export class DocumentSummaryCardDetailsDefaultComponent {
  @Input() summaryControl: DocumentSummaryData;

  constructor(private readonly iconsHelperService: IconsHelperService) {
    this.iconsHelperService.registerSvgIcons('summary-current');

  }
}
