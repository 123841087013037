<d iv class="wizard wizard-workflow" *ngIf="panelSteps">
  <ul>
    <li
      *ngFor="let step of panelSteps"
      [ngClass]="{
        done: step.status === 'DONE',
        active: step.status === 'ACTIVE'
      }"
    >
      <div class="wizard-icon position-relative">
        <span class="step-count">{{ step.stepNo }}</span>
        <mat-icon *ngIf="step.status === 'DONE'">check</mat-icon>
      </div>
      <div class="wizard-text">
        {{ step.text[selectedLanguage] }}
      </div>
    </li>
  </ul>
</d>

<div class="text-center">
  <mat-icon
    class="d-inline-block mb-2 w-auto h-auto"
    style="font-size: 100px; line-height: 100px; color: #ff861c; opacity: 0.6"
    >{{ icon }}</mat-icon
  >

  <h1>{{ title || '' }}</h1>
  <h2 class="self-assessment-width m-auto text-dark">
    {{ description || '' }}
  </h2>
</div>
