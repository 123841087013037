import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Dayjs } from 'dayjs';

import {ITaskFilterData, ITaskRes, TaskColor} from '@app/modules/shared/models/tasks.model';
import { SegregatedTasks } from '@app/modules/tasks/model/tasks-list.model';
import { DatetimeService } from '@app/shared/datetime/datetime.service';
import { IPaginationData } from '@app/shared/interfaces/ICompanyAccount';
import {ApiList} from "@app/app.constants";
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(private readonly http: HttpClient, private readonly datetimeService: DatetimeService) {}

  getMyTasks(
    paginationData?: IPaginationData,
    searchText: string = '',
    filter: ITaskFilterData | any = {},
  ): Promise<{ data: ITaskRes[]; count: number }> {
    return this.http
      .post<{ data: ITaskRes[]; count: number }>(`${environment.baseUrl}${ApiList.tasks.my}`, filter, {
        params: {
          limit: paginationData.limit.toString(),
          skip: paginationData.skip.toString(),
          sort: paginationData.sort,
          searchString: searchText,
        },
      })
      .toPromise();
  }

  getMyTasksEvents(
    filterDate: string = moment().toString()
  ): Promise<ITaskRes[]> {
    const date = moment(filterDate).format('yyyy-MM')
    return this.http
      .post<ITaskRes[]>(`${environment.baseUrl}${ApiList.tasks._}${ApiList.tasks.events}`, {date})
      .toPromise();
  }
  segregateTasks(tasks: ITaskRes[]): SegregatedTasks {
    const currentDate: Dayjs = this.datetimeService.date();
    const pastDue: ITaskRes[] = [];
    const today: ITaskRes[] = [];
    const thisWeek: ITaskRes[] = [];
    const others: ITaskRes[] = [];
    tasks.forEach((task) => {
      if (task.completedDate) {
        others.push(task);
      } else {
        const taskDueDate: Dayjs = this.datetimeService.date(task.deadline);
        const daysDiff = taskDueDate.diff(currentDate, 'day');
        if (daysDiff < 0) {
          pastDue.push(task);
        } else if (daysDiff === 0) {
          today.push(task);
        } else if (daysDiff <= 7) {
          thisWeek.push(task);
        } else {
          others.push(task);
        }
      }
    });
    return { pastDue, today, thisWeek, others };
  }

  postponeTask(taskId: string, time: string): Promise<any> {
    return this.http
      .put<any>(
        `${environment.baseUrl}${ApiList.tasks._}${taskId}/${ApiList.tasks.postpone}${time}`,
        null,
      )
      .toPromise();
  }

  setTasksColor(tasks: ITaskRes[]): ITaskRes[] {
    const { pastDue, today, thisWeek, others } =
      this.segregateTasks(tasks);
    tasks.forEach((task) => {
      if (pastDue.some((pastDueTask) => pastDueTask._id === task._id)) {
        task.color = TaskColor.RED;
      } else if (today.some((todayTask) => todayTask._id === task._id)) {
        task.color = TaskColor.ORANGE;
      } else if (
        thisWeek.some((thisWeekTask) => thisWeekTask._id === task._id)
      ) {
        task.color = TaskColor.GREEN;
      } else if (others.some((othersTask) => othersTask._id === task._id)) {
        task.color = TaskColor.GRAY;
      }
    });
    return tasks;
  }
}
