import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

import {
    DocumentsIdentifiers,
    DocumentStatus,
    DocumentSummaryData,
    ReportIssueChoices,
} from '@app/shared/documents.model';
import {CasesService} from '@app/shared/cases/cases.service';
import {DocumentFormConfig} from '@app/modules/forms/models/document-templates.model';
import {DocumentTemplatesService} from '@app/modules/forms/services/document-templates.service';
import {CaseDocument, CaseTypes} from '@app/shared/cases.model';
import {DocumentBuilderService} from '@app/shared/document-builder/document-builder.service';
import {DOCUMENTS_TYPE_LABELS} from '@app/shared/constants/documents.constants';
import {DialogId} from '@app/shared/dialogs.model';
import {DialogHelperService} from '@app/shared/dialog-helper/dialog-helper.service';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

@Component({
    selector: 'wr-facilitation',
    templateUrl: './facilitation.component.html',
})
export class FacilitationComponent implements OnInit, OnDestroy {
    private readonly onDestroy$ = new Subject<null>();
    private summarySubscription: Subscription;
    private formGroup: UntypedFormGroup;
    private workabilityData: { [key: string]: any };

    userId: number;
    docVersion: number;
    getDocument$: Observable<DocumentFormConfig>;
    workabilityIssueSummary: { [key: string]: DocumentSummaryData };
    documentTypeLabel = DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FACILITATION];
    ResidualWorkCapacityDocumentTypeLabel = DOCUMENTS_TYPE_LABELS[ReportIssueChoices.RESIDUAL_WORK_CAPACITY];
    documents: CaseDocument;
    docTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;
    additionalReportType = DocumentTemplateUniqueIdentifier[this.docTemplateUniqueIdentifier.RESIDUAL_WORK_CAPACITY];

    fullResidualWorkCapacityTemplate: DocumentFormConfig;
    constructor(
        private readonly casesService: CasesService,
        private readonly documentTemplatesService: DocumentTemplatesService,
        private readonly documentBuilderService: DocumentBuilderService,
        private readonly dialogHelperService: DialogHelperService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            userId: string;
            caseId: string;
            caseType: CaseTypes;
            caseNumber: string;
            caseUser: string;
            documentId: string;
            type: DocumentTemplateUniqueIdentifier;
            hideSummary: boolean;
            hideStartNewStep?: boolean;
        },
    ) {
    }

    ngOnInit(): void {
        this.getDocumentFormData();
        this.getDocumentFormData2();
    }

    submit(fields: UntypedFormGroup): void {
        const preparedData = this.documentBuilderService.prepareDataBeforeSubmit(fields);
        this.casesService
            .addDocumentToCase(this.data.caseId, {
                status: DocumentStatus.DRAFT,
                docTemplateUniqueIdentifier: DocumentsIdentifiers.FACILITATION,
                docTemplateVersionNumber: this.docVersion,
                ownerId: this.data.userId,
                fields: {
                    ...preparedData,
                    ...this.workabilityData,
                },
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.dialogHelperService.closeDialog(DialogId.FACILITATION, {dialogResult: true});
            });
    }

    private getDocumentFormData2(): void {
        if (this.data.documentId) {
        this.casesService.getCaseDocument(this.data.caseId, this.data.documentId).subscribe((value) => {
            this.docVersion = value.docTemplateVersionNumber;
            this.documents = value;
        }),
            takeUntil(this.onDestroy$);
        }
    }

    private getDocumentFormData(): void {
        this.documentTemplatesService
            .getDocumentFormConfig(
                {
                    [CaseTypes.WORKABILITY]: ReportIssueChoices.WORKABILITY_UPDATE,
                    [CaseTypes.EQUIPMENT]: ReportIssueChoices.PROBLEM_WITH_EQUIPMENT,
                    [CaseTypes.ISSUE]: ReportIssueChoices.OTHER_ISSUES,
                    [CaseTypes.SICK_LEAVE]: ReportIssueChoices.RESIDUAL_WORK_CAPACITY,
                }[this.data.caseType],
            )
            .pipe(
                switchMap((workability) => {
                    const {formGroup, summarySubscription} = this.documentBuilderService.buildDocumentFrom(workability);
                    this.formGroup = formGroup;

                    this.fullResidualWorkCapacityTemplate = workability;
                    this.summarySubscription = summarySubscription;
                    return this.casesService.getCaseDocTemplate(this.data.caseId, DocumentsIdentifiers.FACILITATION);
                }),
                takeUntil(this.onDestroy$),
            )
            .subscribe((value) => {
                this.workabilityData = value.additionalData;
                this.getDocument$ = of(value.docTemplate);
                this.workabilityIssueSummary = (this.formGroup.get('summary') as UntypedFormGroup)?.getRawValue();
                if (this.documents) {
                    this.documentBuilderService.updateFormValue(this.formGroup, this.documents.fields);
                }
                Object.keys(this.workabilityIssueSummary).forEach(key => {
                    if (this.documents && this.workabilityIssueSummary[key] && this.documents.fields[this.workabilityIssueSummary[key].key]) {
                        this.workabilityIssueSummary[key].value = this.documents.fields[this.workabilityIssueSummary[key].key];
                    }
                    const stepsData = this.fullResidualWorkCapacityTemplate.versions[0].template.steps;
                    const stepWithKey: any = stepsData.find(step => step.controls[0].key === this.workabilityIssueSummary[key].key)?.controls[0];
                    if (stepWithKey && stepWithKey.options && stepWithKey.options.selectOptions) {
                        this.workabilityIssueSummary[key].selectOptions = stepWithKey.options.selectOptions
                    }
                })
            });
    }

    ngOnDestroy(): void {
        if (this.summarySubscription) {
            this.summarySubscription.unsubscribe();
        }
        this.onDestroy$.next();
    }
}
