<div class="card-details__value__container">
<table>
  <ng-container *ngFor="let value of summaryControl.value; let i = index">
    <tr
      *ngIf="value && summaryControl; else noData"
    >
      <td>
        <span class="card-details__value__text pe-4">
          {{
            summaryControl &&
            summaryControl.selectOptions &&
            summaryControl.selectOptions[i] &&
            summaryControl.selectOptions[i]['label'][selectedLanguage]
              ? summaryControl.selectOptions[i]['label'][selectedLanguage]
              : ''
          }}
        </span>
      </td>
      <td>
        <span
          class="border d-inline-block card-details__value__text mt-1 p-1 rounded"
          [ngClass]="{
            'status-high': value === 'HIGH',
            'status-none': value === 'NONE',
            'status-medium': value === 'MEDIUM'
          }"
          >{{
            ('BUTTON.' + (summaryControl &&
            summaryControl.selectOptions &&
            summaryControl.selectOptions[value]
              ? summaryControl.selectOptions[value]
              : value)) | translate
          }}</span
        >
      </td>
    </tr>
    <ng-template #noData>
      <span *ngIf="i === 0" class="card-details__no-value__text">{{
        summaryControl.noValueInfo || '-'
      }}</span>
      <div *ngIf="i === 0" class="card-details__no-value__text">
        {{ summaryControl.noValueInfo || '-' }}
      </div>
    </ng-template>
  </ng-container>
</table>
</div>

