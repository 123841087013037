<form [formGroup]="formArray.parent" class="multi-button-select__form">
  <!-- <h3 *ngIf="description" class="step-description">
      {{ description }}
    </h3> -->

  <table
    class="mx-auto multi-button-select__form__checkbox-buttons__container multi-button-select-custom-big"
  >
    <tr
      class="d-flex justify-content-between mb-2"
      *ngFor="let option of selectOptions; let i = index"
    >
      <td>
        <h4 class="mb-0 flex-grow-1 me-sm-5">{{ option.label }}</h4>
      </td>
      <td>
        <mat-radio-group
          class="flex-grow-0 mat-radio-group-custom mat-radio-group-custom-priority"
          (change)="changeVal($event.value, i)"
        >
          <mat-radio-button
            *ngFor="let prio of priorityList"
            class="horizontal-button-radio__form__radio-group__option button-type"
            [value]="prio"
            [checked]="formArray.value[i] === prio"
          >
            {{ ('BUTTON.' + prio) | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </td>
    </tr>
  </table>
</form>
