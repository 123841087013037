import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import {
  NotificationColor,
  NotificationPosition,
  NotificationShowTime,
} from '@app/core/services/notify/notify.model';
import { TranslateService } from '@ngx-translate/core';
import { useDevice } from '@app/modules/shared/services/common/device-state.service';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  currentDevice;
  constructor(
    private readonly snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.currentDevice = useDevice().isMobile;
  }

  showNotification(
    message: string,
    color: NotificationColor,
    showTime: NotificationShowTime = NotificationShowTime.MEDIUM,
    position: NotificationPosition = NotificationPosition.BOTTOM,
  ): void {
    position = this.currentDevice
      ? NotificationPosition.BOTTOM
      : NotificationPosition.TOP;
    try {
      message = this.translateService.instant(message);
    } catch {}
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: showTime,
      panelClass: color,
      data: { message },
      horizontalPosition: 'center',
      verticalPosition: position,
    });
  }
}
