import {Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  ITemplateSaveData,
  ITemplateVersionData,
  QuestionTypeEnum,
} from '@app/modules/content-creator/content-creatorer.model';
import {Subject} from 'rxjs';
import {DocumentFormConfigStep} from '@app/modules/forms/models/document-templates.model';
import {ContentCreatorService} from '@app/shared/content-creator/content-creator.service';
import {CaseDocument} from '@app/shared/cases.model';
import {takeUntil} from 'rxjs/operators';
import {CaseType} from "@app/modules/case-page/models/case-details.model";

@Component({
  selector: 'wr-questionaire-summary-mode',
  templateUrl: './questionaire-summary-mode.component.html',
  styleUrls: ['./questionaire-summary-mode.component.scss'],
})
export class QuestionaireSummaryModeComponent implements OnInit, OnDestroy, OnChanges {
  _value: CaseDocument;
  @Input()
  set caseDocumentData(value: CaseDocument) {
    if (value) {
      this._value = value;
    }
  }

  get caseDocumentData(): CaseDocument {
    return this._value;
  }

  @HostBinding('class.wr-fill-mode')
  templateData: ITemplateVersionData;
  private readonly onDestroy$ = new Subject<null>();
  preFilledData: ITemplateSaveData;
  stepperDataForTitle: DocumentFormConfigStep[] = [];
  constructor(private contentCreatorService: ContentCreatorService) {}

  async ngOnInit(): Promise<void> {
    this.getTemplate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.caseDocumentData) {
      this.preFilledData = this.caseDocumentData.fields;
      this.getTemplate();
    }
  }

  getTemplate(): void {
    this.contentCreatorService
      .getTemplates(CaseType.SICK_LEAVE, this.preFilledData?.versions?.versionNumber)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        this.templateData = templates;
        this.prepareTitles();
        this.setEmpltyValueForConetnt();
        this.changeFormForComments();
      });
  }

  changeFormForComments(): void {
    this.templateData.versions.pages.forEach((page, pageIndex) => {
      page.content.forEach((content, contentIndex) => {
        const singleValue = this.preFilledData.versions?.pages[pageIndex].content[contentIndex].value;
        const multiValue = this.preFilledData.versions?.pages[pageIndex].content[contentIndex].multipleValue;
        const comment = this.preFilledData.versions?.pages[pageIndex].content[contentIndex].comment;
        if (singleValue) {
          content.value = singleValue;
        } else if (multiValue) {
          content.multipleValue = multiValue;
        }
        if (comment) {
          content.comment = comment;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  onSubmit(): void {}

  setEmpltyValueForConetnt(): void {
    this.templateData.versions.pages.forEach((page) => {
      page.content.forEach((content) => {
        if (content.type === QuestionTypeEnum.CHECKBOX) {
          content.multipleValue = [];
        } else {
          content.value = '';
        }
        content.comment = '';
      });
    });
  }

  scrollToTop(): void {
    document.getElementById('title').scrollIntoView();
  }

  prepareTitles(): void {
    this.templateData?.versions.pages.forEach((page, pageIndex) => {
      this.stepperDataForTitle.push({
        step: pageIndex,
        title: { en: page.title },
        description: { en: page.subtitle },
        controls: [],
      });
    });
  }
}
