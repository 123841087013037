import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ContentCreatorService } from '@app/shared/content-creator/content-creator.service';
import { Subject, Subscription } from 'rxjs';
import {
  ITemplateVersionData,
  QuestionTypeEnum,
} from '@app/modules/content-creator/content-creatorer.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { User } from '@app/modules/shared/models/user.model';
import { UserService } from '@app/modules/shared/services/user/user.service';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { DocumentFormConfigStep } from '@app/modules/forms/models/document-templates.model';
import {
  CaseDocument,
  CaseTypes,
} from '@app/modules/shared/models/cases.model';
import {
  DocumentSummaryData,
  DocumentsIdentifiers,
} from '@app/modules/shared/models/documents.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { CasesService } from '@app/modules/shared/services/cases/cases.service';
import { DocumentBuilderService } from '@app/modules/shared/services/document-builder/document-builder.service';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { DOCUMENT_DESCRIPTIONS_TRANSLATION_KEYS } from '@app/modules/case-page/constants/documents.constants';
import { DialogId } from '@app/shared/dialogs.model';

@Component({
  selector: 'wr-preview-mode',
  templateUrl: './preview-mode.component.html',
  styleUrls: ['./preview-mode.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('*', style({ transform: 'translateX(0)' })),
      transition(':increment', [
        style({ transform: 'translateX(+100%)' }),
        animate(200),
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate(200),
      ]),
    ]),
  ],
})
export class PreviewModeComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  openContentTypeSelect = false;
  contentTypes: ContentTypeList[];
  templateData: ITemplateVersionData;
  currentUser: User;
  activePageIndex = 0;
  pageEditMode = false;

  @HostBinding('class.preview-mode') previewMode = 'preview-mode';

  private summarySubscription: Subscription;
  private summarySubscription1: Subscription;

  caseDocumentData: CaseDocument;
  caseDocumentData1: CaseDocument;

  summary: { [key: string]: DocumentSummaryData };
  formGroup: UntypedFormGroup;
  steps: DocumentFormConfigStep[]; // NEED
  stepControls: AbstractControl[];

  summary1: { [key: string]: DocumentSummaryData };
  formGroup1: UntypedFormGroup;
  steps1: DocumentFormConfigStep[]; // NEED
  stepControls1: AbstractControl[];
  docTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;
  isFollowupAvailable = false;
  replaceContentIndex: number | null = null;

  protected readonly DOCUMENT_DESCRIPTIONS_TRANSLATION_KEYS =
    DOCUMENT_DESCRIPTIONS_TRANSLATION_KEYS;
  reportTypes = DialogId;

  constructor(
    private contentCreatorService: ContentCreatorService,
    private readonly dialogRef: MatDialogRef<PreviewModeComponent>,
    private readonly userService: UserService,
    private casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      documentId: string;
      type: any;
      commentMode: boolean;
    },
  ) {
    this.contentTypes = [
      { icon: 'live_help', key: QuestionTypeEnum.SHORT, name: 'Short Answer' },
      {
        icon: 'unknown_document',
        key: QuestionTypeEnum.LONG,
        name: 'Long Answer',
      },
      { icon: 'check_box', key: QuestionTypeEnum.CHECKBOX, name: 'Checkbox' },
      {
        icon: 'radio_button_checked',
        key: QuestionTypeEnum.RADIO_BTN,
        name: 'Radio Button',
      },
      { icon: 'grade', key: QuestionTypeEnum.RATING, name: 'Rating' },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    this.getTemplate();
    await this.getFacilitationDocumentFormData(
      DocumentsIdentifiers.FACILITATION,
    );
    await this.getFollowupDocumentFormData(DocumentsIdentifiers.FOLLOW_UP_PLAN);
  }

  private getFacilitationDocumentFormData(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(DocumentsIdentifiers.FACILITATION)
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup = formGroup;
          this.steps = workability?.versions[0]?.template?.steps;
          this.stepControls = stepControls;
          this.summarySubscription = summarySubscription;
          return this.casesService.getCaseDocumentByType(
            this.data.caseId,
            DocumentsIdentifiers.FACILITATION,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        this.documentBuilderService.updateFormValue(
          this.formGroup,
          document.fields,
        );
        this.summary = (
          this.formGroup.get('summary') as UntypedFormGroup
        )?.getRawValue();
        this.caseDocumentData = document;
        if (
          this.data.type === DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP &&
          this.formGroup.value.step0.control1
        ) {
          this.summary = this.formGroup.value.step0.control1;
        }
      });
  }
  private getFollowupDocumentFormData(type: DocumentsIdentifiers): void {
    this.documentTemplatesService
      .getDocumentFormConfig(DocumentsIdentifiers.FOLLOW_UP_PLAN)
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription, stepControls } =
            this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup1 = formGroup;
          this.steps1 = workability?.versions[0]?.template?.steps;
          this.stepControls1 = stepControls;
          this.summarySubscription1 = summarySubscription;
          return this.casesService.getCaseDocumentByType(
            this.data.caseId,
            DocumentsIdentifiers.FOLLOW_UP_PLAN,
          );
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((document) => {
        if (document) {
          this.isFollowupAvailable = true;
          this.documentBuilderService.updateFormValue(
            this.formGroup1,
            document.fields,
          );
          this.summary1 = (
            this.formGroup1.get('summary') as UntypedFormGroup
          )?.getRawValue();
          this.caseDocumentData1 = document;
          if (
            this.data.type ===
              DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP &&
            this.formGroup1.value.step0.control1
          ) {
            this.summary1 = this.formGroup1.value.step0.control1;
          }
        }
      });
  }

  getTemplate(): void {
    this.contentCreatorService
      .getTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        this.templateData = templates;
      });
  }

  setEditMode(val: boolean): void {
    this.pageEditMode = val;
    this.previewMode = this.pageEditMode ? 'edit-mode' : 'preview-mode';
    if (!val) {
      this.templateData.versions.pages.forEach((page) => {
        page.content.forEach((el) => (el.isEditMode = false));
      });
    }
  }

  editText(pageIndex: number, contentIndex: number): void {
    if (!this.pageEditMode) return;
    this.templateData.versions.pages[pageIndex].content.forEach(
      (el) => (el.isEditMode = false),
    );
    this.templateData.versions.pages[pageIndex].content[
      contentIndex
    ].isEditMode = true;
  }

  disableEditMode(pageIndex: number, contentIndex: number): void {
    this.templateData.versions.pages[pageIndex].content[
      contentIndex
    ].isEditMode = false;
  }
  openAddContent(): void {
    this.openContentTypeSelect = true;
  }

  addNewContent(pageIndex: number, contentType: QuestionTypeEnum): void {
    const contentTypeWithOptions = [
      QuestionTypeEnum.CHECKBOX,
      QuestionTypeEnum.RADIO_BTN,
    ];
    if (this.replaceContentIndex >= 0 && this.replaceContentIndex !== null) {
      const existingContent =
        this.templateData.versions.pages[pageIndex].content[
          this.replaceContentIndex
        ];
      const existingValue =
        existingContent.value && existingContent.value.length > 0
          ? existingContent.value
          : ['#Option 1'];
      const values = contentTypeWithOptions.includes(contentType)
        ? existingValue
        : [];
      this.templateData.versions.pages[pageIndex].content.splice(
        this.replaceContentIndex,
        1,
        {
          type: contentType,
          values,
          questionNumber: 0,
          text: existingContent.text,
          required: existingContent.required,
        },
      );
    } else {
      const values = contentTypeWithOptions.includes(contentType)
        ? ['#Option 1']
        : [];
      this.templateData.versions.pages[pageIndex].content.push({
        type: contentType,
        values,
        questionNumber: 0,
        text: 'Question #' + pageIndex,
        required: true,
      });
    }
    this.closeContentTypeSelect();
  }

  saveTemplate(changeToPreview = true): void {
    this.contentCreatorService
      .saveTemplates(this.templateData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templates) => {
        if (changeToPreview) {
          this.setEditMode(false);
        }
      });
  }

  removeContent(pageIndex: number, contentIndex: number): void {
    this.templateData.versions.pages[pageIndex].content.splice(contentIndex, 1);
  }

  replaceContent(pageIndex: number, contentIndex: number): void {
    this.replaceContentIndex = contentIndex;
    this.openAddContent();
  }

  closeContentTypeSelect(): void {
    this.openContentTypeSelect = false;
    this.replaceContentIndex = null;
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    if (this.summarySubscription1) {
      this.summarySubscription1.unsubscribe();
    }
    this.onDestroy$.next();
  }

  close(): void {
    this.dialogRef.close(false);
  }
}

export class ContentTypeList {
  icon: string;
  key: QuestionTypeEnum;
  name: string;
}
