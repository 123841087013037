  <div class="date-range-picker__container" *ngIf="type === 'calendar'">
    <div class="date-range-picker__section">
      <div>
        <mat-calendar
          class="datepicker__open-calendar"
          maxlength="3"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (selectedChange)="onSelectedChange($event)"
          [selected]="selectedDateRange"
          [headerComponent]="calendarCustomHeader"
        ></mat-calendar>
      </div>
    </div>
    <div class="date-range-picker__section">
      <div class="date-range-section__container">
        <h2 class="date-range-picker__selected-days">
          {{
            getSelectedDaysCount(
              selectedDateRange?.start,
              selectedDateRange?.end
            )
          }}
          {{ 'DAYS_SELECTED' | translate }}
        </h2>

        <p class="date-range-picker__date-info">
          {{ 'COMMON.FILTERS.LABEL.START_DATE' | translate }}:
          {{
            selectedDateRange?.start
              ? (selectedDateRange?.start | customDate: 'dd/MM/YYYY')
              : '-'
          }}
        </p>
        <p class="date-range-picker__date-info">
          {{ 'COMMON.FILTERS.LABEL.END_DATE' | translate }}:
          {{
            selectedDateRange?.end
              ? (selectedDateRange?.end | customDate: 'dd/MM/YYYY')
              : '-'
          }}
        </p>
      </div>
    </div>
  </div>





  <div class="mat-calendar-custom position-relative" *ngIf="type === 'dropdown'">
    <mat-form-field
      appearance="fill"
      bg="white"
      class="mat-calendar-custom-input"
      (click)="picker.open(); test()"
    >
      <mat-icon color="primary" matPrefix>calendar_today</mat-icon>
      <mat-label>{{ 'FILTER.DATE_RANGE.LABEL' | translate }}</mat-label>

      <mat-date-range-input [rangePicker]="picker">
        <input [(ngModel)]="filterData.fromDate" matStartDate readonly />
        <input
          [(ngModel)]="filterData.toDate"
          matEndDate
          readonly
          (dateChange)="test()"
        />
      </mat-date-range-input>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-icon class="mat-calendar-custom-clear" (click)="clearPicker()"
    >close</mat-icon>
  </div>

  <div class="date-range-picker__container">
    <mat-error *ngIf="max?.value && !isValidRange()">{{
        max.errorMessage
      }}</mat-error>
  </div>
