import { DayInSummary } from '@app/shared/components/documents/document-summary/document-summary-card/document-summary-card-details-working-hours/document-summary-card-details-working-hours.model';

export const WORKING_HOURS_DAYS_IN_SUMMARY: DayInSummary[] = [
  {
    key: 'mon',
    label: 'Mon',
  },
  {
    key: 'tue',
    label: 'Tue',
  },
  {
    key: 'wed',
    label: 'Wed',
  },
  {
    key: 'thu',
    label: 'Thu',
  },
  {
    key: 'fri',
    label: 'Fri',
  },
  {
    key: 'sat',
    label: 'Sat',
  },
  {
    key: 'sun',
    label: 'Sun',
  },
];
