<div class="modal-body-inner">
  <mat-accordion class="example-headers-align accordion-custom scheduler-summary" multi>
    <mat-expansion-panel expanded *ngFor="let pages of templateData?.versions.pages; let pageIndex = index">
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">{{ pages.title }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item" *ngFor="let content of pages.content; let contentIndex = index">
          <div class="summary-card-item__title">Q: {{ content.text }}</div>
          <div class="summary-card-item__value" *ngIf="content.value">A: {{ content.value }}</div>
          <ng-container *ngIf="content.multipleValue && content.multipleValue.length > 0">
            <div class="summary-card-item__value" *ngFor="let val of content.multipleValue; let valIndex = index">
              {{ 'A#' + (valIndex + 1) }} {{ val }}
            </div>
          </ng-container>
          <div class="summary-card-item__value" *ngIf="content.comment">C: {{ content.comment }}</div>
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded *ngIf="preFilledData && preFilledData.versions && preFilledData.versions.note">
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">{{ 'COMMON.NOTE' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item" >
<!--          <div class="summary-card-item__title">Q: {{ content.text }}</div>-->
          <div class="summary-card-item__value">{{ preFilledData.versions.note}}</div>
        </div>
      </mat-card>
    </mat-expansion-panel>

  </mat-accordion>
</div>
