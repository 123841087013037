import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-single-button-select',
  templateUrl: './single-button-select.component.html',
  styleUrls: ['./single-button-select.component.scss'],
})
export class SingleButtonSelectComponent {
  @Input() control: UntypedFormControl;
  @Input() selectOptions: SelectOption<string>[];
  @Input() description: string;

  trackBy(index: number, option: SelectOption<string>): string {
    return option.value;
  }
}
