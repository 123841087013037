<wr-generic-report
  [getDocument$]="getDocument$"
  [hasAdditionalStep]="false"
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseType]="data.caseType"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  [reportType]="data.type"
  [rowsCount]="18"
  [taskType]="data.type"
  [caseId]="data.caseId"
  [documentId]="data.documentId"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
  [isAdditionalStepSummary]="false"
  [hideStepsIndex]="data.hideStartNewStep ? [4] : []"
>
<!--  hideStepsIndex used to remove the step witj start new or previous data which is in 4th index of the step-->
  <!-- <ng-container additionalStep>
    <ng-container *ngIf="workabilityIssueSummary">
      <wr-document-summary
        [documentSummaryData]="workabilityIssueSummary"
        [documentTypeLabel]=""
        [caseNumber]="data.caseNumber"
        [employee]="data.caseUser"
        [userId]="userId"
      >
      </wr-document-summary>
    </ng-container>
  </ng-container> -->
</wr-generic-report>
