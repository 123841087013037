import { Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { UntypedFormControl } from '@angular/forms';

import { SIMPLE_TIME_FORMAT } from '@app/shared/custom-components/simple-timepicker/simple-timepicker.constants';
import { DatetimeService } from '@app/shared/datetime/datetime.service';

@Injectable()
export class SimpleTimepickerService {
  constructor(private readonly datetimeService: DatetimeService) {}

  private countMinutesChange(date: Dayjs, initialDayjs: Dayjs, minutesInStep: number): [number, Dayjs] {
    let minutesChange = 0;
    if (initialDayjs && date) {
      const minutesInDay = 24 * 60;
      const initialTimeUnixMinutes = initialDayjs.unix() / 60;
      let initialMinutes = initialTimeUnixMinutes;
      if (initialTimeUnixMinutes >= minutesInDay) {
        initialMinutes = initialTimeUnixMinutes % minutesInDay;
      }
      const timeChange = ((date.unix() / 60 - initialMinutes) / minutesInStep) % 1;
      if (timeChange > 0 && timeChange < 1) {
        const minutesOverlap = Math.round(timeChange * minutesInStep);
        minutesChange = minutesOverlap < minutesInStep / 2 ? -minutesOverlap : minutesInStep - minutesOverlap;
        date = date.add(minutesChange, 'minute');
      }
    }
    return [minutesChange, date];
  }

  getCurrentValueDate(time: string | number): Dayjs {
    let dayjsDate = this.datetimeService.date(0, true);
    const { hours, minutes } = this.datetimeService.getHoursAndMinutesFromTime(time as string);
    dayjsDate = dayjsDate.add(hours, 'hour');
    dayjsDate = dayjsDate.add(minutes, 'minute');
    return dayjsDate;
  }

  processTimeToDisplayTime(
    time: string | number,
    initialDayjs: Dayjs,
    minutesInStep: number,
    isLongTime = false,
    control?: UntypedFormControl,
  ): string {
    let dayjsDate = this.getCurrentValueDate(time);
    let minutesChange: number;
    [minutesChange, dayjsDate] = this.countMinutesChange(dayjsDate, initialDayjs, minutesInStep);
    let formattedDate = dayjsDate.format(SIMPLE_TIME_FORMAT);
    const { hours, minutes } = this.datetimeService.getHoursAndMinutesFromTime(time as string);
    if (hours >= 24) {
      let hoursChange = 0;
      if (Math.abs(minutesChange) >= 60) {
        hoursChange = Math.floor(minutesChange / 60);
        minutesChange = minutesChange - hoursChange * 60;
      }
      formattedDate = `${`${hours - hoursChange}`.padStart(2, '0')}:${`${minutes + minutesChange}`.padStart(2, '0')}`;
      if(control)
      control.patchValue(formattedDate.replace(/^[0]/, ''), { emitEvent: false });
    }
    return `${hours < 100 && isLongTime ? '0' : ''}${formattedDate}`;
  }

  countNewControlValueFromDisplayTime(valueToAdd: number, formVal: string | number): string {
    let valueToReturn: string;
    const date = this.getCurrentValueDate(formVal).add(valueToAdd, 'second');
    const hours = date.diff(this.datetimeService.date(0, true), 'hour');
    valueToReturn = date.format('HH:mm').replace(/^\d+:/, `${hours}:`);
    return valueToReturn;
  }

  getSecondsFromTime(time: string): number {
    const { hours, minutes }: { hours: number; minutes: number } =
      this.datetimeService.getHoursAndMinutesFromTime(time);
    return (hours * 60 + minutes) * 60;
  }
}
