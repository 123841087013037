import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DocumentSummaryData } from '@app/shared/documents.model';

@Component({
  selector: 'wr-document-summary-card-details-popup',
  templateUrl: './document-summary-card-details-popup.component.html',
  styleUrls: ['./document-summary-card-details-popup.component.scss'],
})
export class DocumentSummaryCardDetailsPopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { summaryControls: DocumentSummaryData[] }) {}
}
