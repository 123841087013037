import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { ApiList } from '@app/app.constants';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private http: HttpClient) {
  }

  getCategories = (searchString = ''): Promise<any> => {
    return this.http
      .get<any>(
        `${environment.baseUrl}${ApiList.templateCategories._}`, {params: {searchString}},
      )
      .toPromise();
  };
  addCategory = (name: string, parentCategoryId?: string): Promise<any> => {
    return this.http
      .post<any>(
        `${environment.baseUrl}${ApiList.templateCategories._}`, {name, parentCategoryId}
      )
      .toPromise();
  }

  updateCategory = (name: string, categoryId?: string): Promise<any> => {
    return this.http
      .post<any>(
        `${environment.baseUrl}${ApiList.templateCategories._}`, {name, categoryId}
      )
      .toPromise();
  }

  deleteCategory = (parentCategoryId: string): Promise<any> => {
    return this.http
      .delete<any>(
        `${environment.baseUrl}${ApiList.templateCategories._}/${parentCategoryId}`
      )
      .toPromise();
  }
}
