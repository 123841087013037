import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'wr-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() selectedTime: string; // 24 HR FORMAT
  @Output() selectedTimeChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('hoursScrollable') hoursScrollable!: ElementRef;
  @ViewChild('minutesScrollable') minutesScrollable!: ElementRef;

  hours: string[] = this.generateTimeArray(24);
  minutes: string[] = this.generateTimeArray(75, 15, true);
  selectedHour = '';
  selectedMinute = '';
  // selectedPeriod = 'AM';
  // selectedTime12Hr: string;
  private generateTimeArray(limit: number, interval: number = 1, startWithZero = false): string[] {
    const options: string[] = [];
    for (let i = 0; i < limit; i += interval) {
      const timeString = (i + (startWithZero ? 0 : 1)).toString().padStart(2, '0');
      options.push(timeString);
    }
    return options;
  }

  ngOnInit(): void {
    if (this.selectedTime) {
      this.process24HrTo12Hr();
    }
  }

  process24HrTo12Hr(): void {
    const [hours24, minutes24] = this.selectedTime.split(':');
    // const hrPeriod = this.convert24To12(hours24);
    // this.selectedTime12Hr = hrPeriod.hour + ':' + minutes24 + ':' + hrPeriod.period

    // const [hours, minutes, period] = this.selectedTime12Hr.split(':');
    this.selectedHour = hours24;
    this.selectedMinute = minutes24;
    // this.selectedPeriod = period;
  }

  onHourSelected(hour: string): void {
    this.selectedHour = hour;
    this.emitSelectedTime();
  }

  onMinuteSelected(minute: string): void {
    this.selectedMinute = minute;
    this.emitSelectedTime();
  }

  // onPeriodSelected(period: string): void {
  //   this.selectedPeriod = period;
  //   this.emitSelectedTime();
  // }

  emitSelectedTime(): void {
    this.selectedTime = this.get24hrTime();
    this.selectedTimeChange.emit(this.selectedTime);
  }

  // get12hrTime(): string {
  //   const timeString = `${this.selectedHour}:${this.selectedMinute}:${this.selectedPeriod}`;
  //   return timeString;
  // }

  get24hrTime(): string {
    // const selectedHour24 = this.convert12To24(this.selectedHour, this.selectedPeriod);
    const timeString24 = `${this.selectedHour}:${this.selectedMinute}`;
    return timeString24
  }
  // private convert12To24(hour12: string, period: string): string {
  //   let hour = parseInt(hour12, 10);
  //   if (period === 'PM' && hour !== 12) {
  //     hour += 12;
  //   }
  //   if (period === 'AM' && hour === 12) {
  //     hour = 0;
  //   }
  //   return hour.toString().padStart(2, '0');
  // }

  // private convert24To12(hour24: string): { hour: string, period: string } {
  //   let hour = parseInt(hour24, 10);
  //   const period = hour >= 12 ? 'PM' : 'AM';
  //   if (hour > 12) {
  //     hour -= 12;
  //   }
  //   if (hour === 0) {
  //     hour = 12;
  //   }
  //   return {hour: hour.toString().padStart(2, '0'), period};
  // }

  onTimeChange(): void {
    if (this.selectedTime) {
      setTimeout(() => {
        const roundedTime = this.roundToNearest15Minutes(this.selectedTime);
        this.selectedTime = roundedTime;
        this.process24HrTo12Hr();
      }, 500)

    }
  }
  private roundToNearest15Minutes(timeString: string): string {
    // tslint:disable-next-line:prefer-const
    let [hours, minutes]: any = timeString.split(":");

    minutes = (Math.ceil(minutes / 15) * 15);
    if (minutes === 0) minutes = "00";
    if (minutes === 60) { minutes = "00"; }

    return hours + ":" + minutes

    }

}
