import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { CasesService } from '@app/shared/cases/cases.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { CaseTypes, ICheckIfCaseExistsResponse } from '@app/shared/cases.model';
import { DialogId } from '@app/shared/dialogs.model';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { Router } from '@angular/router';
import { RouteSegments } from '@app/modules/shared/models/routing.model';
import { SweetAlertResult } from 'sweetalert2';
import { SweetAlertService } from '@app/modules/core/services/alert/sweet-alert.service';

@Component({
  selector: 'wr-self-certification',
  templateUrl: './self-certification.component.html',
  styleUrls: ['./self-certification.component.scss'],
})
export class SelfCertificationComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  caseType = CaseTypes.SELF_CERTIFICATION;
  documentTypeLabel = 'COMMON.NEW'; // DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.SELF_CERTIFICATION];
  lastpageSelectedOption = 'No';
  constructor(
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly casesService: CasesService,
    private readonly sweetAlertService: SweetAlertService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseNumber: string;
      caseUser: string;
      userId: string;
      caseType: CaseTypes;
      existingCaseId: string;
    },
  ) {}

  ngOnInit(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(
      DocumentsIdentifiers.SELF_CERTIFICATION,
    );
  }

  submit(fields: { [key: string]: string | string[] }): void {
    this.casesService
      .createCase({
        documents: [
          {
            docTemplateUniqueIdentifier:
              DocumentsIdentifiers.SELF_CERTIFICATION,
            docTemplateVersionNumber: this.docVersion,
            ownerId: this.data.userId,
            fields,
          },
        ],
        type: CaseTypes.SELF_CERTIFICATION,
        caseId: this.data.existingCaseId ? this.data.existingCaseId : null,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.dialogHelperService.closeDialog(DialogId.SELF_CERTIFICATION, {
            dialogResult: true,
          });
          this.redirectToCases(data._id);
        },
        (error) => {
          // TODO : add error handling
          console.error(error);
        },
      );
  }
  redirectToCases(id: string): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`${RouteSegments.CASES}/${id}`]);
  }

  routeToCaseDetail(caseId: string, triggerTodoTask = false): void {
    this.router.navigate([`${RouteSegments.CASES}/${caseId}`], {
      queryParams: { triggerTodoTask },
    });
  }
  async checkIfCaseExists(
    caseType: CaseTypes,
  ): Promise<ICheckIfCaseExistsResponse> {
    const doesCaseExist = await this.casesService
      .checkIfCaseExists(caseType)
      .toPromise();
    return doesCaseExist;
  }

  proceedToExistingCase(): Promise<SweetAlertResult> {
    return this.sweetAlertService.showAlert({
      html: 'SELF_CERTIFICATION.CANCEL.DIALOG.QUESTION',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: 'Take me to existing case',
      denyButtonText: `Send the new update to feed`,
      confirmButtonColor: '#00c7b2',
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
