<form [formGroup]="control.parent" class="question-with-yes-no-answer__form">
  <h3 *ngIf="description" class="question-with-yes-no-answer__form-title">
    {{ description }}
  </h3>
  <div class="question-with-yes-no-answer__container">
    <p class="question-with-yes-no-answer__question">{{ label }}</p>
    <mat-radio-group [formControl]="control" class="question-with-yes-no-answer__radio-group">
      <mat-radio-button class="question-with-yes-no-answer__radio-group__button button-type" [value]="true">
        Yes
      </mat-radio-button>
      <mat-radio-button class="question-with-yes-no-answer__radio-group__button button-type" [value]="false">
        No
      </mat-radio-button>
    </mat-radio-group>
  </div>
</form>
