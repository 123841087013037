import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wr-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
  @Input() textControl: UntypedFormControl;
  @Input() description: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() rowsCount = 24;
  @Input() autofocus: boolean;
  @Input() defaultValue: string;
}
