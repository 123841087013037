<mat-form-field
  class="item__form-field__role"
  [floatLabel]="floatLabel"
  ngDefaultControl
  *ngIf="control"
>
  <mat-select
    [formControl]="control"
    (selectionChange)="valueSelect.emit($event.value)"
    [placeholder]="placeholder"
    [disabled]="readonly"
    [multiple]="multiple"
    #singleSelect
  >
    <mat-option *ngIf="searchEnabled">
      <ngx-mat-select-search
        [formControl]="searchControl"
        placeholderLabel="Search..."
        noEntriesFoundLabel="No matching entries"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option value *ngIf="emptyValue">
      {{ emptyValue | translate }}
    </mat-option>
    <mat-option
      *ngFor="let option of filteredOptions; trackBy: trackBy"
      [disabled]="option.disabled"
      [value]="option.value"
    >
      {{ option.label | translate }}
      <span *ngIf="option.icon" class="avatar avatar-sm">
        <figure
          [ngStyle]="{
            'background-image':
              'url(' + 'https://www.gstatic.com/webp/gallery3/1.sm.png' + ')'
          }"
        ></figure>
        <figcaption>
          {{ option.label }}
        </figcaption></span
      >
    </mat-option>
  </mat-select>

  <mat-error *ngIf="control.hasError('required')">
    {{ fieldName.toUpperCase() + '_FIELD_IS_REQUIRED' | translate }}
  </mat-error>
</mat-form-field>
