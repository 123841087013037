import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplatesRoutingModule } from './templates-routing.module';
import { TemplateCateogryAddComponent } from './template-category/template-cateogry-add/template-cateogry-add.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyOptionModule as MatOptionModule} from "@angular/material/legacy-core";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {SharedModule} from "@app/shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {WrButtonsModule} from "@app/shared/components/buttons/button.module";
import { TemplateCategoryComponent } from './template-category/template-category.component';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {MatTreeModule} from "@angular/material/tree";
import { ShortAnswerComponent } from './controls/short-answer/short-answer.component';
import { MultipleAnswerComponent } from './controls/multiple-answer/multiple-answer.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import { SingleAnswerComponent } from './controls/single-answer/single-answer.component';
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import { RatingComponent } from './controls/rating/rating.component';
import { SliderComponent } from './controls/slider/slider.component';
import {MatLegacySliderModule as MatSliderModule} from "@angular/material/legacy-slider";
import { ToggleComponent } from './controls/toggle/toggle.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import { DatePickerComponent } from './controls/date-picker/date-picker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { TimePickerComponent } from './controls/time-picker/time-picker.component';
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import { DateRangePickerComponent } from './controls/date-range-picker/date-range-picker.component';
import { WorkHoursComponent } from './controls/work-hours/work-hours.component';
import {HoursTimeRangeModule} from "@app/shared/directives/hours-time-range/hours-time-range.module";
import { AddressComponent } from './controls/address/address.component';
import { TemplatePageComponent } from './template-page/template-page.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatStepperModule} from "@angular/material/stepper";
import {MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { InputChipsComponent } from './controls/input-chips/input-chips.component';
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {SelectWithSearchModule} from "@app/shared/custom-components/select-with-search/select-with-search.module";


@NgModule({
  declarations: [
    TemplateCateogryAddComponent,
    TemplateCategoryComponent,
    ShortAnswerComponent,
    MultipleAnswerComponent,
    SingleAnswerComponent,
    RatingComponent,
    SliderComponent,
    ToggleComponent,
    DatePickerComponent,
    TimePickerComponent,
    DateRangePickerComponent,
    WorkHoursComponent,
    AddressComponent,

    TemplatePageComponent,
     InputChipsComponent
  ],
  exports: [
    ShortAnswerComponent,
    MultipleAnswerComponent,
    SingleAnswerComponent,
    RatingComponent,
    SliderComponent,
    ToggleComponent,
    DatePickerComponent,
    TimePickerComponent,
    DateRangePickerComponent,
    WorkHoursComponent,
    AddressComponent,
    InputChipsComponent
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
  imports: [
    CommonModule,
    TemplatesRoutingModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    WrButtonsModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMenuModule,
    HoursTimeRangeModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatDialogModule,
    MatStepperModule,
    MatCardModule,
    MatChipsModule,
    SelectWithSearchModule,
  ]
})
export class TemplatesModule { }
