<wr-generic-report
  #genericReport
  [getDocument$]="getDocument$"
  [caseType]="caseType"
  [hasAdditionalEndStep]="false"
  [documentTypeLabel]="documentTypeLabel"
  [caseNumber]="data.caseNumber"
  [employee]="data.caseUser"
  (submitForm)="submit($event)"
  (docVersion)="docVersion = $event"
  [hasHasCustomTitle]="true"
  [rowsCount]="14"
>
  <ng-container title>
    <h3
      *ngIf="genericReport?.steps?.length == genericReport?.selectedStep + 1"
      class="report-issue-title"
      mat-dialog-title
    >
      {{ documentTypeLabel | translate }}
    </h3>
  </ng-container>
</wr-generic-report>
