<div class="bg-primary-light p-4 mb-4 rounded">
  <h2 class="text-orange mb-0">Reminder</h2>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h4 class="mb-1">
        Followup via :
        <span class="fw-bold">{{
          schedulerData.docTemplateUniqueIdentifier == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL
            ? 'Call'
            : 'Message'
        }}</span>
      </h4>
      <!--                <p>{{ schedulerData. }}</p>-->
    </div>
  </div>
</div>

<div *ngIf="schedulerData.docTemplateUniqueIdentifier == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL">
  <div class="scheduler-summary mb-4 align-middle">
    <h5 class="text-primary">Did you make a call?</h5>
    <mat-card>
      <div class="scheduler-summary-item">
        <p>Postponed</p>
        <span>{{ schedulerData.fields.postponeValue }} minutes</span>
      </div>
      <div class="scheduler-summary-item">
        <p>Call note</p>
        <span>{{ schedulerData.fields.callNote }}</span>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="schedulerData.docTemplateUniqueIdentifier == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE">
  <div class="scheduler-summary mb-4 align-middle">
    <h5 class="text-primary">Message</h5>
    <mat-card>
      <div class="scheduler-summary-item">
        <mat-icon>Postponed</mat-icon>
        <span>{{ schedulerData.fields.message }}</span>
      </div>
    </mat-card>
  </div>
</div>
