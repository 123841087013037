<div
  class="app-sidenav__container"
  [ngClass]="{
    'is-animating': animationInProgress,
    'is-mobile': mobileQuery.matches
  }"
>
  <mat-sidenav-container
    class="sidenav__container"
    (backdropClick)="onToggleClick()"
    [ngClass]="{ 'is-opened': isOpened, 'is-closed': !isOpened }"
  >
    <mat-sidenav
      #sidenav
      (opened)="handleToggle(true)"
      (closed)="handleToggle(false)"
      [fixedInViewport]="mobileQuery.matches"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      fixedTopGap="85"
      class="isHide"
    >
      <a
        [routerLink]="['/' + routeSegments.LANDING_PAGE]"
        class="title__container"
      >
        <span class="app-logo">
          <img class="" src="../../../../../assets/images/svg/app-logo.svg" />
        </span>
      </a>
      <mat-nav-list>
        <button
          mat-icon-button-left
          class="toggle__button-left"
          (click)="sidenav.toggle(); onToggleClick()"
        >
          <mat-icon id="sidemenu-arrow" svgIcon="toggle-arrow"></mat-icon>
        </button>
        <div
          class="company"
          (click)="setIsShowedClassInMenus()"
          [matMenuTriggerFor]="companyMenu"
        >
          <div class="company__logo">
            <div class="avatar">
              <figure
                *ngIf="currentWorkspace?.logo"
                [ngStyle]="{
                  'background-image': 'url(' + currentWorkspace?.logo + ')'
                }"
              ></figure>
              <figcaption>
                {{ currentWorkspace?.companyName | nameInitials }}
              </figcaption>
            </div>
          </div>
          <div
            class="company__text"
            *ngIf="(isOpened && !openingInProgress) || closingInProgress"
          >
            <span class="company__name fw-bold text-primary text-truncate">{{
              currentWorkspace?.companyName
            }}</span>
            <div class="company__details">
              <span class="details__additional-info">{{
                currentWorkspace?.jobTitle
              }}</span>
            </div>
            <button
              *ngIf="userWorkspaceList.length > 0"
              mat-button
              class="workplace-action text-button"
              [style.height]="auto"
            >
              {{ 'MENU.SIDENAV.COMPANY.SWITCH_WORKPLACE' | translate }}
            </button>
          </div>
        </div>

        <div class="mat-nav-list-buttons mat-nav-list-buttons-first scroll">
          <ng-container
            *ngFor="let sidenavItem of sidenavItems; trackBy: trackBy"
          >
            <ng-template [ngIf]="checkVisibility(sidenavItem)">
              <button
                *ngIf="
                  actionMenus && actionMenus[sidenavItem.action];
                  else noMenuButton
                "
                mat-list-item
                [matMenuTriggerFor]="
                  actionMenus[sidenavItem.action] || dummyMenu
                "
                [ngClass]="{
                  'has-action': sidenavItem.action,
                  'is-active':
                    sidenavItem.link && sidenavItem.link === activatedRoute
                }"
                (click)="setIsShowedClassInMenus()"
              >
                <mat-icon mat-list-icon [svgIcon]="sidenavItem.icon"></mat-icon>
                <span
                  *ngIf="isOpened && !openingInProgress && !closingInProgress"
                  class="sidenav-item__label"
                >
                  <span class="sidenav-item__label--primary">
                    {{ sidenavItem.labelKey | translate }}
                  </span>
                  <span class="sidenav-item__label--secondary">
                    {{ sidenavItem.additionalLabelKey | translate }}
                  </span>
                </span>
              </button>
              <ng-template #noMenuButton>
                <button
                  mat-list-item
                  [ngClass]="{
                    'has-action': sidenavItem.action,
                    'is-active':
                      sidenavItem.link && sidenavItem.link === activatedRoute
                  }"
                  (click)="onSidenavItemClick(sidenavItem)"
                >
                  <mat-icon
                    mat-list-icon
                    [svgIcon]="sidenavItem.icon"
                  ></mat-icon>
                  <ng-container
                    *ngIf="isOpened && !openingInProgress && !closingInProgress"
                  >
                    {{ sidenavItem.labelKey | translate }}
                  </ng-container>
                </button>
              </ng-template>
            </ng-template>
          </ng-container>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="isMobile">
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu
  #companyMenu="matMenu"
  class="app-sidenav__company-menu"
  xPosition="after"
  yPosition="below"
>
  <div class="company-menu__content">
    <div class="company__active">
      <div class="company__logo pie__content">
        <div class="avatar">
          <figure
            *ngIf="currentWorkspace?.logo"
            [ngStyle]="{
              'background-image': 'url(' + currentWorkspace?.logo + ')'
            }"
          ></figure>
          <figcaption>
            {{ currentWorkspace?.companyName | nameInitials }}
          </figcaption>
        </div>
        <mat-icon
          class="material-icons"
          style="
            font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
          "
          >check</mat-icon
        >
      </div>

      <div class="company__text">
        <span class="company__name">{{ currentWorkspace?.companyName }}</span>
        <span class="company__details">{{ currentWorkspace?.jobTitle }}</span>
      </div>
    </div>
    <div class="company-menu__switch-workplace-list">
      <div
        class="workplaces-list__item"
        *ngFor="let workspace of userWorkspaceList"
      >
        <div class="company__logo">
          <div class="avatar">
            <figure
              [ngStyle]="{ 'background-image': 'url(' + workspace?.logo + ')' }"
            ></figure>
            <figcaption>{{ workspace?.companyName | nameInitials }}</figcaption>
          </div>
        </div>
        <div class="company__text">
          <span class="company__text--primary">{{
            workspace.companyName
          }}</span>
          <span class="company__text--secondary">{{ workspace.jobTitle }}</span>
        </div>
        <button
          mat-icon-button
          class="rounded-icon"
          color="dark"
          #tooltip="matTooltip"
          matTooltip="Switch Workspace"
          (click)="switchWorkspace(workspace._id)"
        >
          <mat-icon>swap_horiz</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-menu>
<mat-menu
  #newActionMenu="matMenu"
  class="app-sidenav__new-action-menu"
  xPosition="after"
  yPosition="below"
>
  <div class="new-action-menu__content">
    <span class="title" translate
      >MENU.SIDENAV.NEW_ACTION.WHAT_WOULD_YOU_LIKE_TO_DO</span
    >
    <div class="new-action-menu__actions">
      <ng-container *ngFor="let item of actionMenuItems">
        <button
          mat-button
          *wrHasPermission="item.permissions"
          (click)="item.action()"
        >
          <mat-icon [svgIcon]="item.icon"></mat-icon>
          {{ item.label | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</mat-menu>

<mat-menu #dummyMenu="matMenu"> </mat-menu>

<div class="mobile-menu" *ngIf="!accessData?.isExternal">
  <!-- <div class="mobile-menu__line"></div> -->
  <ng-container *ngFor="let sidenavItem of mobileBottomMenu; trackBy: trackBy">
    <button
      class="mobile-menu__btn"
      (click)="
        sidenavItem.link
          ? onMobileNavItemClick(sidenavItem.link)
          : openBottomAdditionalMenu()
      "
    >
      <mat-icon
        *ngIf="!sidenavItem.link && bottomAdditionaMenuOpened; else newAction"
        >close</mat-icon
      >
      <ng-template #newAction>
        <mat-icon [svgIcon]="sidenavItem.icon"></mat-icon>
      </ng-template>
    </button>
  </ng-container>
  <button
    class="mobile-menu__btn"
    (click)="openBottomMenu()"
    *ngIf="mobileAdditionalBottomMenu?.length > 0"
  >
    <mat-icon>{{ bottomMenuOpened ? 'close' : 'menu' }}</mat-icon>
  </button>
  <!-- <div class="toggle-button">
    <button mat-icon-button class="gle__button">
      <mat-icon svgIcon="toggle-arrow"></mat-icon>
    </button>
  </div> -->
</div>

<div class="mobile-menu mobile-menu-case d-none">
  <button class="mobile-menu__btn">
    <mat-icon>quick_reference_all</mat-icon>
    <span class="mobile-menu__text">Detail</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>Feed</mat-icon>
    <span class="mobile-menu__text">Feed</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>timeline</mat-icon>
    <span class="mobile-menu__text">Timeline</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>manage_accounts</mat-icon>
    <span class="mobile-menu__text">Access</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>quick_reference</mat-icon>
    <span class="mobile-menu__text">Form</span>
  </button>
</div>

<div class="mobile-menu mobile-menu-case-bottom d-none">
  <button
    class="mobile-menu__btn"
    (click)="onMobileNavItemClick(routeSegments.CASES)"
  >
    <mat-icon>description</mat-icon>
    <span class="mobile-menu__text">Case</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>quick_reference_all</mat-icon>
    <span class="mobile-menu__text">Detail</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>Feed</mat-icon>
    <span class="mobile-menu__text">Feed</span>
  </button>
  <button class="mobile-menu__btn">
    <mat-icon>timeline</mat-icon>
    <span class="mobile-menu__text">Timeline</span>
  </button>

  <button class="mobile-menu__btn" (click)="openBottomMenu()">
    <mat-icon>{{ bottomMenuOpened ? 'close' : 'menu' }}</mat-icon>
    <span class="mobile-menu__text">Menu</span>
  </button>
</div>
