<ng-container
  [ngTemplateOutlet]="languageSelector"
  languageSelector
></ng-container>
<ng-template #languageSelector>
  <div class="">
    <button
      mat-icon-button
      mat-menu-trigger-custom
      [matMenuTriggerFor]="menu"
      aria-label="Language"
      class="language-selector"
    >
      <img
        class="flag"
        [src]="
          selectedLanguage === languages.ENGLISH
            ? 'assets/images/png/united-kingdom-flag.png'
            : 'assets/images/png/norway-flag.png'
        "
      />
      <!-- <span class="language-text">{{(selectedLanguage===languages.ENGLISH)?"English":"Norwegian"}}</span> -->
    </button>
    <mat-menu #menu="matMenu">
      <small class="text-secondary m-3">Select Language</small>
      <button mat-menu-item (click)="onLanguageChange('en')">
        <img class="flag" src="assets/images/png/united-kingdom-flag.png" />
        <span>English</span>
      </button>
      <button mat-menu-item (click)="onLanguageChange('nb')">
        <img class="flag" src="assets/images/png/norway-flag.png" />
        <span>Norwegian</span>
      </button>
    </mat-menu>
  </div>
</ng-template>
