import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { NameValidationErrors, PasswordValidationErrors } from '@app/shared/form-validators/form-validators.model';
import { DatetimeService } from '@app/shared/datetime/datetime.service';

@Injectable()
export class FormValidatorsService {
  constructor(private readonly datetimeService: DatetimeService) {}

  static NameValidator(control: UntypedFormControl): null | NameValidationErrors {
    const errors: NameValidationErrors = {};

    if (FormValidatorsService.hasSmallLength(control.value)) {
      delete errors.noLength;
    } else {
      errors.noLength = true;
    }

    return Object.keys(errors).length ? errors : null;
  }

  static passwordValidator(control: UntypedFormControl): null | PasswordValidationErrors {
    const errors: PasswordValidationErrors = {};

    if (FormValidatorsService.hasUpperCase(control.value)) {
      delete errors.noUpperCase;
    } else {
      errors.noUpperCase = true;
    }

    if (FormValidatorsService.hasNumber(control.value)) {
      delete errors.noNumber;
    } else {
      errors.noNumber = true;
    }

    if (FormValidatorsService.hasSpecialChar(control.value)) {
      delete errors.noSpecialChar;
    } else {
      errors.noSpecialChar = true;
    }

    if (FormValidatorsService.hasCheck(control.value)) {
      delete errors.noCheck;
    } else {
      errors.noCheck = true;
    }

    return Object.keys(errors).length ? errors : null;
  }

  static checkIsSame(controlName1: string, controlName2: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): null | { notSame: boolean } => {
      const value1 = formGroup.get(controlName1).value;
      const value2 = formGroup.get(controlName2).value;
      return value1 === value2 ? null : { notSame: true };
    };
  }

  static shouldNotBeSame(controlName: string, matchingControlName: string) : any {
    return (formGroup: AbstractControl) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value === matchingControl.value) {
        matchingControl.setErrors({ isSame: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  private static hasSmallLength(text: string): boolean {
    return /(?=(.*[a-zA-Z]){2})/.test(text);
  }

  private static hasCheck(text: string): boolean {
    return /^.{12,}$/.test(text);
  }

  private static hasUpperCase(text: string): boolean {
    return /[A-Z]/.test(text);
  }

  private static hasNumber(text: string): boolean {
    return /\d/.test(text);
  }

  private static hasSpecialChar(text: string): boolean {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(text);
  }

  static ConfirmedValidator(controlName: string, matchingControlName: string): any {
    return (formGroup: AbstractControl) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  datesRangeValidator(
    dateFromCtrlName: string,
    dateToCtrlName: string,
    rangeVal: number,
    rangeUnit: 'day',
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup): null | { invalidRange: boolean } => {
      let isValid = true;
      const dateFrom = formGroup.get(dateFromCtrlName).value;
      const dateTo = formGroup.get(dateToCtrlName).value;
      const dateFromDayjs = this.datetimeService.date(dateFrom);
      const dateToDayjs = this.datetimeService.date(dateTo);

      if (dateFromDayjs.isValid() && dateToDayjs.isValid()) {
        isValid = dateToDayjs.diff(dateFromDayjs, rangeUnit) <= rangeVal;
      }

      if (!isValid) {
        formGroup.get(dateFromCtrlName).setErrors({ invalidRange: true });
        formGroup.get(dateToCtrlName).setErrors({ invalidRange: true });
      }

      return isValid ? null : { invalidRange: true };
    };
  }
}
