<mat-form-field
  class="form-control-password__field"
  floatLabel="{{ floatLabel || 'auto' }}"
>
  <input
    wrPermissionReadOnly
    [requiredPermissions]="[]"
    [type]="hide ? 'password' : 'text'"
    matInput
    [formControl]="passwordControl"
    [placeholder]="placeholder"
    [wrAutoFocus]="autoFocus"
    name="field1"
  />
  <button
    type="button"
    mat-icon-button
    matSuffix
    (mousedown)="hide = false"
    (mouseup)="hide = true"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hide"
    tabindex="-1"
  >
    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
  </button>
  <mat-error *ngIf="passwordControl.hasError('required'); else isSameError">
    {{ 'ENTRY.COMMON.CARD.ERROR_PASSWORD_IS_REQUIRED' | translate }}
  </mat-error>
  <ng-template #isSameError>
    <mat-error *ngIf="passwordControl.hasError('isSame'); else otherErrors">
      {{
        'ENTRY.COMMON.CARD.ERROR_CONFIRM_PASSWORD_PASSWORDS_SAME' | translate
      }}
    </mat-error>
  </ng-template>
  <ng-template #otherErrors>
    <mat-error *ngIf="passwordControl.hasError('noUpperCase')">
      {{ 'ENTRY.COMMON.CARD.ERROR_PASSWORD_NO_UPPER_CASE' | translate }}
    </mat-error>
    <mat-error *ngIf="passwordControl.hasError('noNumber')">
      {{ 'ENTRY.COMMON.CARD.ERROR_PASSWORD_NO_DIGIT' | translate }}
    </mat-error>
    <mat-error *ngIf="passwordControl.hasError('noSpecialChar')">
      {{ 'ENTRY.COMMON.CARD.ERROR_PASSWORD_NO_SPECIAL_CHARACTER' | translate }}
    </mat-error>
    <mat-error *ngIf="passwordControl.hasError('noCheck')">
      {{ 'ENTRY.COMMON.CARD.ERROR_PASSWORD_NO_SMALL_LENGTH' | translate }}
    </mat-error>

    <mat-error *ngIf="passwordControl.hasError('confirmedValidator')">
      {{
        'ENTRY.COMMON.CARD.ERROR_CONFIRM_PASSWORD_PASSWORDS_DIFFER' | translate
      }}
    </mat-error>
  </ng-template>
</mat-form-field>
