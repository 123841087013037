import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { TemplateService } from '@app/shared/user/template.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { NotifyService } from '@app/core/services/notify/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { TemplateCateogryAddComponent } from '@app/modules/templates/template-category/template-cateogry-add/template-cateogry-add.component';
import { NotificationColor } from '@app/core/services/notify/notify.model';
import Swal from 'sweetalert2';
class ITemplateNodes {
  _id: string;
  key: string;
  name: string;
  parentCategoryId: string;
  children?: ITemplateNodes[];
}

/** Flat node with expandable and level information */
class ITemplateFlatNodes {
  expandable: boolean;
  name: string;
  level: number;
  _id: string;
}

@Component({
  selector: 'wr-template-category',
  templateUrl: './template-category.component.html',
  styleUrls: ['./template-category.component.scss'],
})
export class TemplateCategoryComponent implements OnInit, AfterViewInit {
  flatNodeMap = new Map<ITemplateFlatNodes, ITemplateNodes>();
  nestedNodeMap = new Map<ITemplateNodes, ITemplateFlatNodes>();

  hasChild = (_: number, node: ITemplateFlatNodes) => node.expandable;

  private _transformer = (node: ITemplateNodes, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name
        ? existingNode
        : new ITemplateFlatNodes();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode._id = node._id;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );
  treeControl = new FlatTreeControl<ITemplateFlatNodes>(
    (node) => node.level,
    (node) => node.expandable,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  categoryTreeData: ITemplateNodes[] = [];
  @ViewChild('searchInput') input: ElementRef;
  searchText: string;
  constructor(
    private templateService: TemplateService,
    private dialogHelperService: DialogHelperService,
    private notifyService: NotifyService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.getCategories();
  }
  ngAfterViewInit(): void {
    // server-side search
    fromEvent(this.input.nativeElement, 'input')
      .pipe(
        filter(
          (v) =>
            this.input.nativeElement.value.trim().length > 2 ||
            this.input.nativeElement.value.length === 0,
        ),
        debounceTime(500),
        distinctUntilChanged(),
        map((text) => {
          this.searchText = this.input.nativeElement.value.trim();
          this.getCategories();
        }),
      )
      .subscribe();
  }
  async getCategories() {
    const res = await this.templateService.getCategories(this.searchText);
    this.categoryTreeData = res;
    this.dataSource.data = this.categoryTreeData;
  }

  insertItem(node: ITemplateFlatNodes) {
    const parentNode = this.flatNodeMap.get(node);

    if (!parentNode.children) parentNode.children = [];
    parentNode.children.push({
      _id: null,
      name: '',
      parentCategoryId: parentNode._id,
      key: '',
    });
    this.dataSource.data = this.categoryTreeData;
    this.treeControl.expand(node);
  }

  addCategory(categoryId?: string, categoryValue?: string) {
    this.dialogHelperService
      .openDialog(
        DialogId.ADD_TEMPLATE_CATEGORY,
        TemplateCateogryAddComponent,
        [DialogClassName.WR_DIALOG_SMALL],
        { updateValue: categoryValue },
        { disableClose: true },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(async (data: string) => {
        if (data) {
          let res;
          if (categoryValue) {
            res = await this.templateService.updateCategory(data, categoryId);
          } else {
            res = await this.templateService.addCategory(data, categoryId);
          }
          if (res) {
            this.notifyService.showNotification(
              'TEMPLATE.CATEGORY.ADDED.MESSAGE',
              NotificationColor.SUCCESS,
            );
            this.getCategories();
          }
        }
      });
  }
  async deleteCategory(categoryId: string) {
    const confirmDelete = await Swal.fire({
      title: this.translateService.instant(
        'TEMPLATE.TEMPLATE_CATEGORY.DELETE.CONFIRMATION',
      ),
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: 'Remove',
      denyButtonText: `Cancel`,
      confirmButtonColor: '#00c7b2',
    });
    if (confirmDelete && confirmDelete.isConfirmed) {
      const res = await this.templateService.deleteCategory(categoryId);
      if (res) {
        this.getCategories();
        Swal.fire({
          title: this.translateService.instant(
            'TEMPLATE.TEMPLATE_CATEGORY.DELETE.SUCCESS',
          ),
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#00c7b2',
        });
      }
    }
  }
}
