import * as dayjs from 'dayjs';
import { AbstractControl } from '@angular/forms';

import { DocumentsControls } from '@app/shared/documents.model';
import { SIMPLE_DATE_FORMAT } from '@app/shared/constants/datetime.constants';

const currentSimpleDate = dayjs().format(SIMPLE_DATE_FORMAT);

export const DOCUMENT_CONTROL_TYPE: {
  [key: string]: 'array' | 'control' | 'group';
} = {
  [DocumentsControls.TEXTAREA]: 'control',
  [DocumentsControls.TEXTBOXES_LIST]: 'array',
  [DocumentsControls.DROPDOWN_WITH_PERCENTAGE]: 'array',
  [DocumentsControls.LOCATION]: 'control',
  [DocumentsControls.TEXTBOX]: 'control',
  [DocumentsControls.PANEL]: 'control',
  [DocumentsControls.TEXTBOX_WITH_CHIPS]: 'array',
  [DocumentsControls.WORKING_HOURS]: 'group',
  [DocumentsControls.DATEPICKER]: 'control',
  [DocumentsControls.QUESTION_WITH_YES_NO_ANSWER]: 'control',
  [DocumentsControls.SMALL_TEXTAREA_LIST]: 'array',
  [DocumentsControls.FOLLOW_UP_PLAN_DATES]: 'group',
  [DocumentsControls.SINGLE_BUTTON_SELECT]: 'control',
  [DocumentsControls.SLIDE_TOGGLE]: 'control',
  [DocumentsControls.SELECT]: 'control',
  [DocumentsControls.DATE_RANGE]: 'group',
  [DocumentsControls.MULTI_BUTTON_SELECT]: 'array',
  [DocumentsControls.SLIDE_TOGGLE_LIST]: 'array',
  [DocumentsControls.PERCENTAGE_SLIDER_LIST]: 'array',
  [DocumentsControls.HORIZONTAL_BUTTON_RADIO]: 'control',
  [DocumentsControls.TEXTBOXES_WITH_SELECT_LIST]: 'array',
  [DocumentsControls.PERCENTAGE]: 'control',
  [DocumentsControls.PERCENTAGE_SLIDER]: 'control',
  [DocumentsControls.PRIORITY_SELECTOR]: 'array',
  [DocumentsControls.CUSTOM_DAYS_AND_HOURS]: 'array',

  [DocumentsControls.BASIC_SCHEDULER]: 'group',
  [DocumentsControls.BASIC_SCHEDULER_SUMMARY]: 'group',
  [DocumentsControls.FOLLOW_UP_PLAN]: 'group',
};

export const DOCUMENT_CONTROL_DEFAULT_VALUE: {
  [key: string]: string | string[] | object | boolean | number;
} = {
  [DocumentsControls.TEXTAREA]: '',
  [DocumentsControls.TEXTBOXES_LIST]: [''],
  [DocumentsControls.DROPDOWN_WITH_PERCENTAGE]: [
    {
      key0: '',
      key1: 50,
    },
  ],
  [DocumentsControls.TEXTBOX]: '',
  [DocumentsControls.LOCATION]: '',
  [DocumentsControls.PANEL]: '',
  [DocumentsControls.TEXTBOX_WITH_CHIPS]: [''],
  [DocumentsControls.WORKING_HOURS]: {
    key0: 40,
    key1: {
      mon: { from: '08:00', to: '16:00', active: false },
      tue: { from: '08:00', to: '16:00', active: false },
      wed: { from: '08:00', to: '16:00', active: false },
      thu: { from: '08:00', to: '16:00', active: false },
      fri: { from: '08:00', to: '16:00', active: false },
      sat: { from: '08:00', to: '16:00', active: false },
      sun: { from: '08:00', to: '16:00', active: false },
    },
  },
  [DocumentsControls.DATEPICKER]: new Date().toISOString(),
  [DocumentsControls.QUESTION_WITH_YES_NO_ANSWER]: true,
  [DocumentsControls.SMALL_TEXTAREA_LIST]: [''],
  [DocumentsControls.FOLLOW_UP_PLAN_DATES]: {
    followUpPlanStartDate: currentSimpleDate,
    followUpPlanEndDate: dayjs().add(12, 'week').format(SIMPLE_DATE_FORMAT),
    followUpPlanWeeks: 12,
    followUpPlanFrequency: 'weekly',
    followUpPlanPrefferedDay: 'mon',
    followUpPlanPrefferedTime: '08:00',
  },
  [DocumentsControls.SINGLE_BUTTON_SELECT]: '',
  [DocumentsControls.SLIDE_TOGGLE]: true,
  [DocumentsControls.SELECT]: '',
  [DocumentsControls.DATE_RANGE]: {
    key0: currentSimpleDate,
    key1: currentSimpleDate,
  },
  [DocumentsControls.MULTI_BUTTON_SELECT]: [],
  [DocumentsControls.SLIDE_TOGGLE_LIST]: [],
  [DocumentsControls.PERCENTAGE_SLIDER_LIST]: [],
  [DocumentsControls.HORIZONTAL_BUTTON_RADIO]: '',
  [DocumentsControls.TEXTBOXES_WITH_SELECT_LIST]: [{ key0: '', key1: '' }],
  [DocumentsControls.PERCENTAGE]: 50,
  [DocumentsControls.PERCENTAGE_SLIDER]: 100,
  [DocumentsControls.PRIORITY_SELECTOR]: [],
  [DocumentsControls.CUSTOM_DAYS_AND_HOURS]: [''],
  [DocumentsControls.BASIC_SCHEDULER]: {
    schedulerRepeat: false,
    schedulerRepeatValue: 0,
    schedulerRepeatFrequency: 'weeks',
    schedulerDurationValue: 0,
    schedulerDurationFrequency: 'weeks',
    schedulerPlanFrequency: 'weeks',
    schedulerPrefferedDate: '',
    schedulerPrefferedTime: '08:00',
    schedulerFollowupType: 'call',
    schedulerFollowupMessage: '',
  },
  [DocumentsControls.BASIC_SCHEDULER_SUMMARY]: {
    schedulerRepeat: false,
    schedulerRepeatValue: 0,
    schedulerRepeatFrequency: 'weeks',
    schedulerDurationValue: 0,
    schedulerDurationFrequency: 'weeks',
    schedulerPlanFrequency: 'weeks',
    schedulerPrefferedDate: '',
    schedulerPrefferedTime: '08:00',
    schedulerFollowupType: 'call',
    schedulerFollowupMessage: '',
  },
  [DocumentsControls.FOLLOW_UP_PLAN]: {
    schedulerRepeat: false,
    schedulerRepeatValue: 0,
    schedulerRepeatFrequency: 'weeks',
    schedulerDurationValue: 0,
    schedulerDurationFrequency: 'weeks',
    schedulerPlanFrequency: 'weeks',
    schedulerPrefferedDate: '',
    schedulerPrefferedTime: '08:00',
    schedulerFollowupType: 'call',
    schedulerFollowupMessage: '',
  },
};

export const DEFAULT_STEP_CHANGE_ACTIONS = (control: AbstractControl) => ({
  [DocumentsControls.TEXTAREA]: () => control.patchValue(control.value.trim()),
  [DocumentsControls.TEXTBOXES_LIST]: () => {
    control.patchValue(control.value.map((task) => task.trim()));
  },
  [DocumentsControls.DROPDOWN_WITH_PERCENTAGE]: () => {},
  [DocumentsControls.LOCATION]: () => {},
  [DocumentsControls.TEXTBOX]: () => {},
  [DocumentsControls.PANEL]: () => {},
  [DocumentsControls.TEXTBOX_WITH_CHIPS]: () => {},
  [DocumentsControls.WORKING_HOURS]: () => {},
  [DocumentsControls.DATEPICKER]: () => {},
  [DocumentsControls.QUESTION_WITH_YES_NO_ANSWER]: () => {},
  [DocumentsControls.SMALL_TEXTAREA_LIST]: () => {
    control.patchValue(control.value.map((task) => task.trim()));
  },
  [DocumentsControls.FOLLOW_UP_PLAN_DATES]: () => {},
  [DocumentsControls.SINGLE_BUTTON_SELECT]: () => {},
  [DocumentsControls.DATE_RANGE]: () => {},
  [DocumentsControls.SLIDE_TOGGLE]: () => {},
  [DocumentsControls.SELECT]: () => {},
  [DocumentsControls.MULTI_BUTTON_SELECT]: () => {},
  [DocumentsControls.SLIDE_TOGGLE_LIST]: () => {},
  [DocumentsControls.PERCENTAGE_SLIDER_LIST]: () => {},
  [DocumentsControls.HORIZONTAL_BUTTON_RADIO]: () => {},
  [DocumentsControls.TEXTBOXES_WITH_SELECT_LIST]: () => {},
  [DocumentsControls.PERCENTAGE]: () => {},
  [DocumentsControls.PERCENTAGE_SLIDER]: () => {},
  [DocumentsControls.PRIORITY_SELECTOR]: () => {},
  [DocumentsControls.CUSTOM_DAYS_AND_HOURS]: () => {},
  [DocumentsControls.BASIC_SCHEDULER]: () => {},
  [DocumentsControls.BASIC_SCHEDULER_SUMMARY]: () => {},
  [DocumentsControls.FOLLOW_UP_PLAN]: () => {},
});
