import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-textboxes-with-select-list',
  templateUrl: './textboxes-with-select-list.component.html',
  styleUrls: ['./textboxes-with-select-list.component.scss'],
})
export class TextboxesWithSelectListComponent implements OnInit {
  @Input() description: string;
  @Input() addNewItemText: string;
  @Input() formArray: UntypedFormArray;
  @Input() placeholder: string;
  @Input() selectOptions: SelectOption<string>[];

  fakeArray: Array<number>;
  inputControlName: string;
  selectControlName: string;
  maxArrayLength = 5;

  ngOnInit(): void {

    this.setControlNames();
    this.generateFakeArray();
  }

  private setControlNames(): void {
    [this.inputControlName, this.selectControlName] = Object.keys(this.formArray.getRawValue()[0]);
  }

  private generateFakeArray(): void {
    this.fakeArray = new Array(this.formArray.length);
  }

  addNext(): void {
    if (this.formArray.length < this.maxArrayLength) {
      this.formArray.push(
        new UntypedFormGroup(
          {
            [this.inputControlName]: new UntypedFormControl(''),
            [this.selectControlName]: new UntypedFormControl(''),
          },
          this.formArray.at(0).validator,
        ),
      );
    }

    this.generateFakeArray();
  }

  removeItem(index: number): void {
    this.formArray.removeAt(index);
    this.generateFakeArray();
  }
}
