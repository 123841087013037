import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TextMaskModule } from 'angular2-text-mask';
import { HttpClientModule } from '@angular/common/http';

import { SimpleTimepickerComponent } from '@app/shared/custom-components/simple-timepicker/simple-timepicker.component';
import { HoursTimeRangeModule } from '@app/shared/directives/hours-time-range/hours-time-range.module';
import { SimpleTimepickerService } from '@app/shared/custom-components/simple-timepicker/service/simple-timepicker.service';
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";

@NgModule({
  imports: [ReactiveFormsModule, CommonModule, TextMaskModule, MatInputModule, HoursTimeRangeModule, HttpClientModule, MatMenuModule],
  declarations: [SimpleTimepickerComponent],
  providers: [SimpleTimepickerService],
  exports: [SimpleTimepickerComponent],
})
export class SimpleTimepickerModule {}
