import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from "@angular/forms";
import {SelectOption} from "@app/shared/common.model";

@Component({
  selector: 'wr-percentage-slider-list',
  templateUrl: './percentage-slider-list.component.html',
  styleUrls: ['./percentage-slider-list.component.scss']
})
export class PercentageSliderListComponent implements OnInit{
  @Input() formArray: UntypedFormArray;
  @Input() selectOptions: SelectOption<string>[];
  @Input() description: string;
  @Output() valueChange = new EventEmitter<any>();

  value: string[];
  icons = {
    JOB_ROLE: 'person',
    TASKS: 'work',
    LOCATION: 'location_on',
    WORKING_HOURS: 'chronic',
  };

  constructor(private readonly fb: UntypedFormBuilder) {

  }

  ngOnInit(): void {
      if (this.selectOptions.length !== this.formArray.length) {
          this.selectOptions.forEach(opt => {
              this.formArray.push(this.fb.control({key: opt.value, value: 100}));
          })
      }
  }

    changeVal(val: any, index: number, option: string): void {
        this.formArray.at(index).patchValue({key: option, value: val})
        this.valueChange.emit(true)
    }
    trackBy(index: number, option: SelectOption<string>): string {
        return option.value;
    }
}
