import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';

import { AppRoutingModule } from '@app/app-routing.module';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppComponent } from '@app/app.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ErrorCatchingInterceptor } from '@app/shared/interceptors/error-catching.interceptor';
import { MarkdownModule } from 'ngx-markdown';
import { LoadingInterceptor } from '@app/core/interceptors/loading-interceptor/loading-interceptor';
import { NgxCaptureModule } from 'ngx-capture';
import localNbNo from '@angular/common/locales/nb';
import { PermissionService } from './modules/shared/services/common/permissions.service';
import { TemplatesComponent } from './modules/templates/templates.component';
import {MatTreeModule} from "@angular/material/tree";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatSortModule} from "@angular/material/sort";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {TemplatesModule} from "@app/modules/templates/templates.module";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {FormsModule} from "@angular/forms";
import { BuilderComponent } from './modules/builder/builder.component';
import {FollowUpComponent} from "@app/modules/follow-up/follow-up.component";
import {FollowUpModule} from "@app/modules/follow-up/follow-up.module";
import {MatTabsModule} from "@angular/material/tabs";
import {BuilderModule} from "@app/modules/builder/builder.module";
registerLocaleData(localNbNo);

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MarkdownModule.forRoot(),
    NgxCaptureModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    TemplatesModule,
    MatCardModule,
    FormsModule,
    FollowUpModule,
    MatTabsModule,
    BuilderModule,
  ],
  declarations: [AppComponent, TemplatesComponent, BuilderComponent, FollowUpComponent],
  bootstrap: [AppComponent],
  providers: [
    BreadcrumbService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    PermissionService
  ],
})
export class AppModule {}

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}
