import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import {
  DocumentsIdentifiers,
  DocumentStatus,
  DocumentSummaryData,
  ReportIssueChoices,
} from '@app/shared/documents.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { CasesService } from '@app/shared/cases/cases.service';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DocumentBuilderService } from '@app/shared/document-builder/document-builder.service';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CaseTypes } from '@app/shared/cases.model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DialogId } from '@app/shared/dialogs.model';

@Component({
  selector: 'wr-close-task',
  templateUrl: './close-task.component.html',
  styleUrls: ['./close-task.component.scss'],
})
export class CloseTaskComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  private formGroup: UntypedFormGroup;
  workabilityData: { [key: string]: any };

  userId: number;
  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  workabilityIssueSummary: { [key: string]: DocumentSummaryData };
  documentTypeLabel = DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.CLOSE_BY_LEADER];
  workabilityDocumentTypeLabel = DOCUMENTS_TYPE_LABELS[ReportIssueChoices.WORKABILITY_UPDATE];

  constructor(
    private readonly casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    private readonly dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
    },
  ) {}

  ngOnInit(): void {
    this.getDocumentFormData();
  }

  submit(fields: any): void {




    this.casesService
      .closeCase(this.data.caseId, {
        status: DocumentStatus.COMPLETED,
        docTemplateUniqueIdentifier: DocumentsIdentifiers.CLOSE_BY_LEADER,
        docTemplateVersionNumber: this.docVersion,
        ownerId: this.data.userId,
        fields: {
          message: fields.value.step1.message,
        },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.CLOSE_BY_LEADER, { dialogResult: true });
      });
  }

  private getDocumentFormData(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(DocumentsIdentifiers.CLOSE_BY_LEADER);

    return;
    this.documentTemplatesService
      .getDocumentFormConfig(
        {
          [CaseTypes.WORKABILITY]: ReportIssueChoices.WORKABILITY_UPDATE,
          [CaseTypes.EQUIPMENT]: ReportIssueChoices.PROBLEM_WITH_EQUIPMENT,
          [CaseTypes.ISSUE]: ReportIssueChoices.OTHER_ISSUES,
          [CaseTypes.SICK_LEAVE]: ReportIssueChoices.WORKABILITY_UPDATE,
          [CaseTypes.SELF_CERTIFICATION]: ReportIssueChoices.SELF_CERTIFICATION,
        }[this.data.caseType],
      )
      .pipe(
        switchMap((workability) => {
          const { formGroup, summarySubscription } = this.documentBuilderService.buildDocumentFrom(workability);
          this.formGroup = formGroup;
          this.summarySubscription = summarySubscription;
          return this.casesService.getCaseDocTemplate(this.data.caseId, DocumentsIdentifiers.CLOSE_BY_LEADER);
        }),
        takeUntil(this.onDestroy$),
      )
      .subscribe((value) => {
        this.workabilityData = value.additionalData;
        this.documentBuilderService.updateFormValue(this.formGroup, value.additionalData);
        this.getDocument$ = of(value.docTemplate);
        this.workabilityIssueSummary = (this.formGroup.get('summary') as UntypedFormGroup)?.getRawValue();
      });

  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }
}
