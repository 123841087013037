import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wr-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  @Input() textControl: UntypedFormControl;
  @Input() description: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() panelSteps: any;
  selectedLanguage = 'en';

  constructor(private readonly translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;

  }
}
