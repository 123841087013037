import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'wr-onboarding-stepper',
  templateUrl: './onboarding-stepper.component.html',
  styleUrls: ['./onboarding-stepper.component.scss'],
})
export class OnboardingStepperComponent implements OnInit, OnChanges {
  listOfSteps: ISetupStepperData[] = [];
  listOfWorkSpaceSetup: ISetupStepperData[] = [
    { status: 'default', icon: 'corporate_fare', text: 'SETUP.COMPANY' },
    { status: 'default', icon: 'work', text: 'SETUP.WORKSPACE' },
    // { status: 'default', icon: 'groups_3', text: 'SETUP.ORGANISATION' },
    // { status: 'default', icon: 'person', text: 'SETUP.ONBOARDING' },
  ];
  listOfTeamspaceSetup: ISetupStepperData[] = [
    { status: 'default', icon: 'work', text: 'SETUP.WORKSPACE' },
    { status: 'default', icon: 'groups_3', text: 'SETUP.TEAMSPACE' },
  ];
  @Input() currentStep: setupStepperSteps | teamspaceSetupSteps = setupStepperSteps.COMPANY;
  @Input() teamSpaceStepMode = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes): void {
    this.listOfSteps = this.teamSpaceStepMode ? this.listOfTeamspaceSetup : this.listOfWorkSpaceSetup;
    this.listOfSteps.forEach((step, index) => {
      if (index < this.currentStep) {
        step.status = 'done';
      } else if (index === this.currentStep) {
        step.status = 'active';
      } else {
        step.status = 'default';
      }
    });
  }
}

export interface ISetupStepperData {
  status: 'active' | 'done' | 'default';
  icon: string;
  text: string;
}

export enum setupStepperSteps {
  COMPANY = 0,
  WORKSPACE = 1,
  ORGANISATION = 2,
  ONBOARDING = 3,
}

export enum teamspaceSetupSteps {
  PROFILE = 0,
  INVITE = 1,
}
