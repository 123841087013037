import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-dropdown-with-percentage',
  templateUrl: './dropdown-with-percentage.component.html',
  styleUrls: ['./dropdown-with-percentage.component.scss'],
})
export class DropdownWithPercentageComponent implements OnInit, OnDestroy {
  @Input() description: string;
  @Input() formArray: UntypedFormArray;
  @Input() addNewItemLabel = 'add';
  @Input() selectOptions: SelectOption<string>[];

  private readonly onDestroy$ = new Subject<null>();

  fakeArray: Array<number>;
  roleSelectControlKey: string;
  percentageControlKey: string;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.watchFormValue();
    this.setControlNames();
    this.generateFakeArray();
  }

  private generateFakeArray(): void {
    this.fakeArray = new Array(this.formArray.length);
  }

  private watchFormValue(): void {
    if (this.formArray) {
      const formArrayFirstElEntries: [string, any][] = Object.entries(this.formArray.at(0)?.value);
      const percentageControlName: string = formArrayFirstElEntries?.find(
        ([_, value]) => typeof value === 'number',
      )?.[0];
      const roleControlName: string = formArrayFirstElEntries?.find(([_, value]) => typeof value !== 'number')?.[0];
      this.formArray.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
        this.disableAlreadySelectedOptions(roleControlName);
        this.updateAllNumbersInForm(value, percentageControlName);
      });
    }
  }

  private disableAlreadySelectedOptions(controlName: string): void {
    const selectedOptions =
      this.formArray
        .getRawValue()
        .map((item) => item[controlName])
        ?.filter((role) => role) || [];
    this.selectOptions = this.selectOptions.map((option) => ({
      ...option,
      disabled: selectedOptions.some((selectedOption) => selectedOption === option.value),
    }));
  }

  private updateAllNumbersInForm(value: { [key: string]: number }[], numberObjectKey: string): void {
    this.formArray.patchValue(
      value.map((item) => ({
        ...item,
        [numberObjectKey]: this.changeNumberToBeInRange(item[numberObjectKey]),
      })),
      { emitEvent: false },
    );
  }

  private changeNumberToBeInRange(value: number): number {
    let numberToReturn = Math.floor(value);
    if (numberToReturn < 0) {
      numberToReturn = 0;
    }
    if (numberToReturn > 100) {
      numberToReturn = 100;
    }
    return numberToReturn;
  }

  private setControlNames(): void {
    Object.entries(this.formArray.getRawValue()[0]).forEach(([key, value]) => {
      if (typeof value === 'string') {
        this.roleSelectControlKey = key;
      } else {
        this.percentageControlKey = key;
      }
    });
  }

  addNext(): void {
    const firstControlValue = (this.formArray.at(0) as UntypedFormGroup)?.getRawValue();
    Object.keys(firstControlValue).forEach((valueKey) => (firstControlValue[valueKey] = null));
    this.formArray.push(this.fb.group(firstControlValue));
    this.generateFakeArray();
  }

  removeItem(index: number): void {
    this.formArray.removeAt(index);
    this.generateFakeArray();
  }

  trackBy(index: number, item: SelectOption<string>): string {
    return item.value;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
