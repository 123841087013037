<div [ngClass]="{ 'edit-mode': pageEditMode }">
  <header>
    <div class="header-top d-flex justify-content-between py-2 px-3">
      <p class="mb-0">Title</p>
      <div class="d-flex gap-3 align-items-start">
        <div class="content-mode">
          {{ pageEditMode ? 'Edit Mode' : 'Preview Mode' }}
          <button
            *ngIf="!pageEditMode"
            (click)="setEditMode(true)"
            mat-icon-button
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
        <button
          (click)="close()"
          class="heading-close"
          color="dark"
          mat-icon-button
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="text-center overflow-auto">
      <div class="d-inline-flex px-4 gap-5">
        <div
          (click)="activePageIndex = pageIndex"
          *ngFor="
            let pages of templateData?.versions?.pages;
            let pageIndex = index
          "
          [ngClass]="
            pageIndex === activePageIndex ? 'thumbnail-template-active' : ''
          "
          class="thumbnail-template"
        >
          <div class="thumbnail-template-content"></div>
          <div class="thumbnail-template-count">{{ pageIndex + 1 }}</div>
          <div class="thumbnail-template-text">{{ pages.title }}</div>
        </div>
      </div>
    </div>
  </header>
  <mat-dialog-content class="m-0">
    <!-- <div class="slide-left">
      <mat-icon>navigate_before</mat-icon>
    </div>
    <div class="slide-right">
      <mat-icon>navigate_next</mat-icon>
    </div> -->
    <div class="template-navigation-arrow">
      <mat-icon (click)="activePageIndex = activePageIndex - 1">
        navigate_before</mat-icon
      >
    </div>

    <div
      class="preview-page d-flex justify-content-center w-100 position-relative"
    >
      <div *ngIf="templateData" class="w-100">
        <ng-container
          *ngFor="
            let pages of templateData.versions.pages;
            let pageIndex = index
          "
        >
          <div *ngIf="activePageIndex === pageIndex" class="preview-page-inner">
            <div class="preview-page-inner-container">
              <ng-container *ngIf="!pageEditMode">
                <div class="text-center">
                  <h5 class="mb-1 fw-bold text-dark">{{ pages.title }}</h5>
                  <p class="small">{{ pages.subtitle }}</p>
                </div>
              </ng-container>
              <div class="row">
                <h3 class="text-muted mb-n4" *ngIf="pageEditMode">
                  Click question to edit
                </h3>
                <div class="col-auto" *ngIf="pages.pageType === 1">
                  <mat-card class="content-review">
                    <mat-card-content>
                      <wr-document-summary
                        *ngIf="pageIndex === 1"
                        [documentSummaryData]="summary"
                        [caseDocumentData]="caseDocumentData"
                        [documentTypeLabel]="
                          reportTypes.FACILITATION | translate
                        "
                        [caseNumber]="data.caseNumber"
                        [employee]="data.caseUser"
                        [userId]="data.userId"
                        [reportType]="reportTypes.FACILITATION"
                        [caseId]="data.caseId"
                        [formGroup]="formGroup"
                        [steps]="steps"
                        class="mx-n3"
                      >
                      </wr-document-summary>
                      <!--TODO: THE REPORT TYPE MENTIONED BELOW NEEDS TO BE MANAGED FROM SOME EXISTING MODEL-->
                      <wr-document-summary
                        *ngIf="pageIndex === 2 && isFollowupAvailable"
                        [documentSummaryData]="summary1"
                        [caseDocumentData]="caseDocumentData1"
                        [documentTypeLabel]="'documentTypeLabel' | translate"
                        [caseNumber]="data.caseNumber"
                        [employee]="data.caseUser"
                        [userId]="data.userId"
                        [reportType]="'FOLLOW_UP_PLAN'"
                        [caseId]="data.caseId"
                        [formGroup]="formGroup1"
                        [steps]="steps"
                        class="mx-n3"
                      >
                      </wr-document-summary>
                      <ng-container
                        *ngIf="pageIndex === 2 && !isFollowupAvailable"
                      >
                        <span class="mh-100"
                          >Follow-up will visible when available</span
                        >
                      </ng-container>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col">
                  <mat-card class="text-start" *ngIf="pageEditMode">
                    <mat-card-content>
                      <label>Title</label>
                      <input
                        class="feed-comment mat-input-element-custom rounded-3 mb-3"
                        matInput
                        placeholder="Enter Title"
                        type="text"
                        [(ngModel)]="pages.title"
                      />
                      <label>Sub Title</label>
                      <input
                        class="feed-comment mat-input-element-custom rounded-3 mb-3"
                        matInput
                        placeholder="Enter subheader"
                        type="text"
                        [(ngModel)]="pages.subtitle"
                      />
                    </mat-card-content>
                  </mat-card>

                  <mat-card
                    *ngFor="
                      let content of pages.content;
                      let contentIndex = index
                    "
                    class="feed-listed card-content px-0 py-4"
                  >
                    <div
                      #previewMode
                      (click)="editText(pageIndex, contentIndex)"
                      *ngIf="!content.isEditMode"
                    >
                      <wr-preview-content
                        [currentUser]="currentUser"
                        [content]="content"
                      ></wr-preview-content>
                    </div>

                    <div
                      #editMode
                      *ngIf="content.isEditMode"
                      [tabindex]="contentIndex"
                      class="active"
                      onblur="disableEditMode(pageIndex, contentIndex)"
                    >
                      <wr-edit-content
                        [content]="content"
                        (contentDeleted)="
                          removeContent(pageIndex, contentIndex)
                        "
                        (contentReplace)="
                          replaceContent(pageIndex, contentIndex)
                        "
                      ></wr-edit-content>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="template-action d-flex flex-column gap-3">
        <button
          *ngIf="!pageEditMode"
          (click)="setEditMode(true)"
          mat-icon-button
        >
          <mat-icon [matTooltip]="'BUTTON.EDIT' | translate">edit</mat-icon>
        </button>
      </div>
      <div
        *ngIf="pageEditMode"
        class="template-action d-flex flex-column gap-3"
      >
        <button (click)="saveTemplate(false)" mat-icon-button>
          <mat-icon [matTooltip]="'BUTTON.SAVE' | translate">save</mat-icon>
        </button>
        <button (click)="openAddContent()" *ngIf="pageEditMode" mat-icon-button>
          <mat-icon [matTooltip]="'BUTTON.ADD' | translate"
            >add_circle</mat-icon
          >
        </button>
        <button (click)="saveTemplate()" *ngIf="pageEditMode" mat-icon-button>
          <mat-icon [matTooltip]="'BUTTON.SAVE_AND_CONTINUE' | translate"
            >check_circle_outline</mat-icon
          >
        </button>
      </div>
      <div
        *ngIf="openContentTypeSelect"
        class="content-type-selector slider-wrapper"
      >
        <div class="slider-right bg-light">
          <div class="d-flex">
            <p class="mb-4">
              Please select the type of question you want to add.
            </p>
            <span (click)="closeContentTypeSelect()" class="cursor-pointer">
              <mat-icon>close</mat-icon>
            </span>
          </div>
          <div class="row d-flex flex-wrap">
            <div *ngFor="let contentType of contentTypes" class="col-sm-6 mb-4">
              <div
                (click)="addNewContent(activePageIndex, contentType.key)"
                class="text-center bg-white content-type p-4 rounded-3"
              >
                <div class="mb-2">
                  <mat-icon class="fs-1">{{ contentType.icon }}</mat-icon>
                </div>
                <span> {{ contentType.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="template-navigation-arrow right">
      <mat-icon
        *ngIf="activePageIndex < templateData?.versions?.pages?.length - 1"
        (click)="activePageIndex = activePageIndex + 1"
      >
        navigate_next</mat-icon
      >
    </div>
  </mat-dialog-content>
</div>
