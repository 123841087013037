import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';

export const DOCUMENT_DESCRIPTIONS_TRANSLATION_KEYS = {
  [DocumentTemplateUniqueIdentifier.SICK_LEAVE]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_SICK_LEAVE',
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_SELF_CERTIFICATION',
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_TEMPORARY_CHANGE_REQUEST',
  [DocumentTemplateUniqueIdentifier.EQUIPMENT]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_EQUIPMENT',
  [DocumentTemplateUniqueIdentifier.ISSUE]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_ISSUE',
  [DocumentTemplateUniqueIdentifier.WORKABILITY_UPDATE]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_WORKABILITY_UPDATE',
  [DocumentTemplateUniqueIdentifier.FACILITATION]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_FACILITATION',
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_FACILITATION_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_1]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_DIALOGUE_MEETING_1',
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_2]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_DIALOGUE_MEETING_2',
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_3]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_DIALOGUE_MEETING_3',
  [DocumentTemplateUniqueIdentifier.NAV_FILLING]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_NAV_FILLING',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_FOLLOW_UP_PLAN_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_FOLLOW_UP_PLAN',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT]:
    'CASES.CASE_PAGE.FORMS_LIST_CARD.DOCUMENT_DESCRIPTION_FOLLOW_UP_DOCUMENT',
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING],
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS],
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE],
  [DocumentsIdentifiers.BASIC_FOLLOW_UP]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP],
  [DocumentsIdentifiers.BASIC_FOLLOW_UP_CALL]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP_CALL],
  [DocumentsIdentifiers.BASIC_FOLLOW_UP_MESSAGE]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP_MESSAGE],
  [DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY],
  [DocumentsIdentifiers.WORKPLACE_ACCOMMODATION]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.WORKPLACE_ACCOMMODATION],
};
