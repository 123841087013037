<wr-generic-dialog [caseNumber]="data.caseNumber" [caseType]="data.caseType" [employee]="data.caseUser">
  <ng-container title>
    {{ 'Follow-up ' | translate }} -
    {{ data.type == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL ? 'Call' : 'Message' }}
  </ng-container>
  <ng-container content>
    <div class="bg-primary-light p-4 mb-4 rounded" style="min-width: 650px">
      <h2 class="text-orange text-center mb-2 lh-1">Reminder</h2>
      <div class="d-flex justify-content-center align-items-center">
        <div class="text-center">
          <h5 class="mb-3">
            Followup via :
            <span class="fw-bold">{{
              data.type == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL ? 'Call' : 'Message'
            }}</span>
          </h5>
          <div>
            <p class="mb-0 text-primary">{{ data.caseUser }}</p>
            <div class="d-flex gap-3">
              <p class="d-flex mb-0"><mat-icon class="opacity-50">event</mat-icon>Jan 28, 2023</p>
              <p class="d-flex mb-0"><mat-icon class="opacity-50">schedule</mat-icon>Jan 28, 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data.type == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL">
      Did you make a call?
      <mat-form-field class="width-auto mx-3" appearance="fill">
        <mat-select [(ngModel)]="madeTheCall" [placeholder]="'Postpone' | translate">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="!madeTheCall">
        <mat-form-field class="width-auto mx-3" appearance="fill">
          <mat-select
            [(ngModel)]="postponeValue"
            [placeholder]="'Postpone' | translate"
            (selectionChange)="postponeValueChanged()"
          >
            <mat-option *ngFor="let item of postponeItems" [value]="item">{{ item }} minutes</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-stroked-button
          class="mat-flat-button matStepperPrevious mat-stroked-button me-2"
          (click)="data.type = documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE"
        >
          Send a message instead
        </button>
      </ng-container>
      <ng-container *ngIf="madeTheCall">
        <hr class="mt-3 mb-4" />
        <div class="pe-3">
          Notes on your call:
          <textarea [wrAutoFocus] class="mt-2 mb-1 p-2 resize-off" [(ngModel)]="callNote" rows="10" matInput></textarea>
        </div>
      </ng-container>
    </div>
    <div *ngIf="data.type == documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE">
      <div class="pe-3">
        Type the message to send:
        <textarea [wrAutoFocus] class="mt-2 mb-1 p-2 resize-off" [(ngModel)]="message" rows="10" matInput></textarea>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="footer__action__container">
      <button
        mat-stroked-button
        class="mat-flat-button matStepperPrevious mat-stroked-button me-2"
        (click)="data.type = documentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL"
      >
        Back
      </button>
      <button mat-button class="step-button step-submit" (click)="submit()">Send</button>
    </div>
  </ng-container>
</wr-generic-dialog>
