import { Component, Input } from '@angular/core';

@Component({
  selector: 'wr-document-summary-header',
  templateUrl: './document-summary-header.component.html',
  styleUrls: ['./document-summary-header.component.scss'],
})
export class DocumentSummaryHeaderComponent {
  @Input() caseNumber: string;
  @Input() employee: string;
  @Input() documentTypeLabel: string;
}
