import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import {
  DocumentsIdentifiers,
  DocumentStatus,
  DocumentSummaryData,
} from '@app/shared/documents.model';
import { CasesService } from '@app/shared/cases/cases.service';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { CaseDocument, CaseTypes } from '@app/shared/cases.model';
import { DocumentBuilderService } from '@app/shared/document-builder/document-builder.service';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

@Component({
  selector: 'wr-workplace-accommodations',
  templateUrl: './workplace-accommodations.component.html',
})
export class WorkplaceAccommodationComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  private summarySubscription: Subscription;
  private workabilityData: { [key: string]: any };
  // private formGroup: FormGroup;

  userId: number;
  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  workabilityIssueSummary: { [key: string]: DocumentSummaryData };
  documentTypeLabel =
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.WORKPLACE_ACCOMMODATION];
  documents: CaseDocument;
  docTemplateUniqueIdentifier = DocumentTemplateUniqueIdentifier;

  constructor(
    private readonly casesService: CasesService,
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly documentBuilderService: DocumentBuilderService,
    private readonly dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      documentId: string;
      type: DocumentTemplateUniqueIdentifier;
      hideSummary: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.getDocumentFormData();
  }

  private getDocumentFormData(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(
      DocumentsIdentifiers.WORKPLACE_ACCOMMODATION,
    );
  }

  submit(fields: UntypedFormGroup): void {
    const preparedData =
      this.documentBuilderService.prepareDataBeforeSubmit(fields);
    this.casesService
      .addDocumentToCase(this.data.caseId, {
        status: DocumentStatus.COMPLETED,
        docTemplateUniqueIdentifier:
          DocumentsIdentifiers.WORKPLACE_ACCOMMODATION,
        docTemplateVersionNumber: this.docVersion,
        ownerId: this.data.userId,
        fields: {
          ...preparedData,
          ...this.workabilityData,
        },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.WORKPLACE_ACCOMMODATION, {
          dialogResult: true,
        });
      });
  }

  skip(eventData: boolean): void {
    this.casesService
      .skipTask(this.data.caseId, DocumentsIdentifiers.WORKPLACE_ACCOMMODATION)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(DialogId.WORKPLACE_ACCOMMODATION, {
          dialogResult: true,
        });
      });
  }

  ngOnDestroy(): void {
    if (this.summarySubscription) {
      this.summarySubscription.unsubscribe();
    }
    this.onDestroy$.next();
  }
}
