import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { take } from 'rxjs/operators';

import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { defaultDialogConfiguration } from '@app/shared/constants/dialogs.constants';

@Injectable()
export class DialogHelperService {
  constructor(private readonly dialog: MatDialog) {}

  openDialog(
    dialogId: DialogId,
    dialogComponent: ComponentType<any>,
    dialogClass: DialogClassName | string | string[],
    data: object = {},
    config?: MatDialogConfig,
  ): MatDialogRef<any> {
    return this.dialog.open(dialogComponent, {
      ...defaultDialogConfiguration,
      ...config,
      id: dialogId,
      data: {
        ['dialogId']: dialogId,
        ...data,
      },
      panelClass: dialogClass,
    });
  }

  closeDialog(dialogId: DialogId, options?: { afterClose?: () => void; dialogResult?: any }): void {
    const dialogs: MatDialogRef<any>[] = this.dialog.openDialogs;
    const dialog: MatDialogRef<any> | undefined = dialogs.find((d) => d.id === dialogId);
    if (dialog) {
      dialog.close(options.dialogResult);
      if (options.afterClose) {
        dialog.afterClosed().pipe(take(1)).subscribe(options.afterClose);
      }
    }
  }
}
