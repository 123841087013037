<div
  class="wa-container pb-0"
  style="
    background-image: url('../../../../assets/images/svg/followUp-Banner.svg');
    background-repeat: repeat-x;
    background-size: auto 500px;
  "
>
  <div class="page-header">
    <div class="row">
      <div class="col-12">
        <wr-breadcrumb
          [breadcrumbList]="[{ title: 'CASES.CASES_LIST.HEADER.TITLE' }]"
        ></wr-breadcrumb>
      </div>
      <div class="col-12 col-sm">
        <h1 class="page-title">
          <!-- {{ 'CASES.CASES_LIST.HEADER.TITLE' | translate }} -->
          Follow Up or title
        </h1>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="col-12">
      <h2 class="text-center mt-3 mb-4 text-primary">Edit this template</h2>
      <div class="row mb-5">
        <div class="col">
          <button
            class="position-relative mat-button-white"
            [color]="'white'"
            mat-flat-button
          >
            <mat-icon>chevron_left</mat-icon>
            Choose template
          </button>
        </div>
        <div class="col-auto">
          <div class="d-flex gap-3">
            <button
              class="position-relative pe-4"
              [color]="'primary'"
              [matMenuTriggerFor]="menu1"
              mat-menu-trigger-custom
              mat-flat-button
            >
              Save template
            </button>
            <mat-menu #menu1="matMenu">
              <button mat-menu-item>Item 1</button>
              <button mat-menu-item>Item 2</button>
            </mat-menu>

            <button class="mat-button-white text-dark" mat-flat-button>
              Schedule this follow-up
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-flex gap-md-5 mb-5">
      <div class="w-100 mb-3 mb-md-0">
        <mat-card
          class="boxSizing h-100 px-5 py-4 shadow-none bg-light mat-elevation-z"
          style="background-color: #e4e9ee !important"
        >
          <h2 class="text-dark">Template Name</h2>
          <span
            mat-menu-trigger-custom
            class="position-relative"
            [matMenuTriggerFor]="menu"
          >Category</span
          >
          <mat-menu #menu="matMenu">
            <button mat-menu-item>Item 1</button>
            <button mat-menu-item>Item 2</button>
          </mat-menu>
          <p class="mb-4 fw-bold">Sick Leave</p>
          <div class="d-flex gap-3 align-items-end">
            <p class="text-truncate-3 mb-0">
              This template is designed to streamline the follow-up process for
              sick leave requests within an organizatio
            </p>
            <mat-icon class="text-muted">edit_square</mat-icon>
          </div>
        </mat-card>
      </div>
      <div class="w-100 mb-3 mb-md-0">
        <mat-card class="p-5">
          <span
            class="d-inline-block bg-light-orange follow-up-template-icon pt-5 px-2 pb-2 mb-5 mt-n5 rounded-bottom"
          >
            <mat-icon class="text-warning icon-48 w-auto h-auto"
            >manage_search</mat-icon
            >
          </span>
          <div>
            <span
              mat-menu-trigger-custom
              class="position-relative fs-3 text-muted"
              [matMenuTriggerFor]="menu"
            >How</span
            >
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Item 1</button>
              <button mat-menu-item>Item 2</button>
            </mat-menu>
            <p class="mb-0 text-dark mt-1">Sick Leave</p>
          </div>
        </mat-card>
      </div>
      <div class="w-100 mb-3 mb-md-0">
        <mat-card class="p-5">
          <span
            class="d-inline-block bg-light-blue follow-up-template-icon pt-5 px-2 pb-2 mb-5 mt-n5 rounded-bottom"
          >
            <mat-icon class="text-blue icon-48 w-auto h-auto"
            >manage_history</mat-icon
            >
          </span>
          <div>
            <span
              mat-menu-trigger-custom
              class="position-relative fs-3 text-muted"
              [matMenuTriggerFor]="menu"
            >Frequency</span
            >
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Item 1</button>
              <button mat-menu-item>Item 2</button>
            </mat-menu>
            <p class="mb-0 text-dark mt-1">Once a month</p>
          </div>
        </mat-card>
      </div>
    </div>
    <div>
      <div class="text-uppercase builder-separation-line mb-4" (click)="scrollToChild(builderSection)" >
        <span class="mb-3 fw-bold text-muted fs-12 d-inline-block"
        >Builder</span
        >
        <mat-icon>expand_more</mat-icon>
      </div>
    </div>
  </div>
<!--  -->


  <div #builderSection id="builderSection">
    <wr-builder-container></wr-builder-container>
  </div>


  <div class="container template-builder-container d-flex gap-5 mb-0">
    <div class="template-builder-elements minimize rounded overflow-auto">
      <div class="template-builder-elements-header d-flex mb-3">
        <span class="text-truncate w-100 me-2">Elements</span>
        <mat-icon>unfold_less</mat-icon>
      </div>
      <mat-card class="mb-3">
        <!-- <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> This is the expansion title </mat-panel-title>
              <mat-panel-description>
                This is a summary of the content
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>This is the primary content of the panel.</p>
          </mat-expansion-panel>
        </mat-accordion> -->
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
      </mat-card>
      <mat-card class="mb-3">
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
      </mat-card>
      <mat-card class="mb-3">
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
        <div class="template-builder-element rounded text-center">
          <img src="../../../assets/images/png/tb-rating.png" />
        </div>
      </mat-card>
    </div>
    <div class="template-builder flex-grow-1 position-relative overflow-hidden">
      <mat-card class="boxSizing template-builder-core">
        <div class="row mt-3">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <input type="text" matInput placeholder="Page Title" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <input type="text" matInput placeholder="Description" />
            </mat-form-field>
          </div>
        </div>
        <div class="template-builder-dropbox d-flex">
          <div class="template-builder-placeholder m-auto">
            <span>Drag and drop the component to build a template</span>
            <div class="template-builder-placeholder-question"></div>
            <div class="template-builder-placeholder-answer"></div>
          </div>
        </div>
      </mat-card>
      <button class="template-builder-save-btn" mat-button>Save</button>
      <div
        class="tb-pages-container-minimize tb-pages-container position-absolute bottom-0 w-100 text-center"
      >
        <span class="rounded-top tb-pages-pin d-inline-flex align-items-center"
        >Pages <mat-icon>push_pin</mat-icon></span
        >
        <div class="d-flex gap-3 overflow-auto p-3 rounded-top tb-pages">
          <div class="tb-pages-preview-container">
            <div class="tb-pages-preview rounded overflow-hidden">
              <img src="../../../assets/images/png/pages-preview.png" />
            </div>
            <div class="tb-pages-preview-title text-truncate w-100 mt-1">
              Title will goes here
            </div>
          </div>
          <div
            class="tb-pages-add rounded d-flex align-items-center justify-content-center"
          >
            <mat-icon>add</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container template-builder-layout">
    <mat-card class="px-md-5">
      <mat-card-title class="my-5 text-muted">Add a page</mat-card-title>
      <div class="row">
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white button-builder-large w-100"
          >
            <mat-icon class="text-warning"
            >sentiment_very_dissatisfied</mat-icon
            >
            <span>Meme</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <mat-icon class="text-blue">chat</mat-icon>
            <span>Message</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <mat-icon class="text-danger">contact_support</mat-icon>
            <span>Question</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <mat-icon class="text-primary">tv_options_edit_channels</mat-icon>
            <span>Agenda</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <mat-icon class="text-muted">stylus_note</mat-icon>
            <span>Review</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <mat-icon class="text-dark">other_admission</mat-icon>
            <span>Summary</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <mat-icon class="text-warning">mark_unread_chat_alt</mat-icon>
            <span>Comment</span>
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="container template-builder-layout">
    <mat-card class="px-md-5">
      <mat-card-title class="my-5 text-muted"
      >Choose Page Layout</mat-card-title
      >
      <div class="row">
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white button-builder-large w-100"
          >
            <img src="../../../assets/images/svg/single.svg" />
            <span class="fw-bold mt-3 text-muted">Single Question</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <img src="../../../assets/images/svg/checklist.svg" />
            <span class="fw-bold mt-3 text-muted">Checklist</span>
          </button>
        </div>
        <div class="col-sm-4 mb-4">
          <button
            mat-flat-button
            class="mat-button-white mat-button-border button-builder-large w-100"
          >
            <img src="../../../assets/images/svg/Mult_question.svg" />
            <span class="fw-bold mt-3 text-muted">Multiple Question</span>
          </button>
        </div>
      </div>
      <div class="text-center">
        <button
          class="position-relative mat-button-white mt-4"
          [color]="'white'"
          mat-flat-button
        >
          <mat-icon>chevron_left</mat-icon>
          Choose different page type
        </button>
      </div>
    </mat-card>
  </div>
</div>
