<div class="modal-body-inner">
  <mat-accordion
    class="example-headers-align accordion-custom scheduler-summary"
    multi
  >
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark"
          >How would you like to follow-up?</mat-panel-title
        >
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <mat-icon>sort</mat-icon>
          <span>{{ followUpDataVal?.followupTaskOrMeeting }}</span>
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark"
          >Followup content document</mat-panel-title
        >
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <mat-icon>description</mat-icon>
          <span> {{ questionTemplateData?.description }}</span>
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">Occurance</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <p>How often do you want to follow?</p>
          <span>{{ followUpDataVal?.followUpType }}</span>
        </div>
        <div class="scheduler-summary-item">
          <p>How long do you want to follow-up last for?</p>
          <span>{{ followUpDataVal?.duration }} </span>
          <span>{{ followUpDataVal?.frequency }}</span>
        </div>
        <div class="scheduler-summary-item">
          <p>Preferred days and time for follow-up?</p>
          <span
            class="badge"
            *ngFor="let days of followUpDataVal?.preferDays"
            >{{ days }}</span
          >
          <span class="text-muted ms-3">
            <b class="me-2">Time:</b>{{ followUpDataVal?.preferTime }}
          </span>
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">Date</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="scheduler-summary-item">
          <p>Schedule the first event</p>
          <span class="text-muted me-5">
            <mat-icon class="me-0">calendar_today</mat-icon>
            {{ followUpDataVal?.firstEventDate | customDate }}
            <span class="d-inline-block mx-2">-</span>
            {{ followUpDataVal?.preferTime }}
          </span>
        </div>
        <div class="scheduler-summary-item">
          <p>Location</p>
          <span class="text-muted me-5">
            {{ followUpDataVal?.location }}
          </span>
        </div>
        <div class="scheduler-summary-item">
          <p>Next Follow-up</p>
          <span class="text-muted me-5">
            {{ followUpDataVal?.firstEventDate | customDate }}
            <span class="d-inline-block mx-2">-</span>
            {{ followUpDataVal?.preferTime }}
          </span>
        </div>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header class="align-middle">
        <mat-panel-title class="text-primary-dark">Attendees</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card>
        <div class="row">
          <div
            class="col-12 mb-3"
            *ngFor="let attendie of followUpDataVal?.attendees"
          >
            <div class="d-flex align-items-center">
              <div>
                <p class="mb-0 small">{{ attendie }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3" *ngFor="let attendie of accessUsers">
            <div class="d-flex align-items-center">
              <span class="avatar me-2">
                <figure
                  [ngStyle]="{
                    'background-image': 'url(' + attendie?.photo + ')'
                  }"
                ></figure>
                <figcaption>{{ attendie?.name | nameInitials }}</figcaption>
              </span>
              <div>
                <h5 class="mb-0 text-dark">{{ attendie.name }}</h5>
                <!-- <p class="mb-0 small"></p> -->
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</div>
