import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  TASK_DIALOG_CLASSES,
  TASK_DIALOG_COMPONENT,
  TASK_DIALOG_ID,
} from '@app/modules/case-page/constants/tasks.constants';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { CaseTypes } from '@app/shared/cases.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'wr-changes-to-task-confirmation',
  templateUrl: './changes-to-task-confirmation.component.html',
  styleUrls: ['./changes-to-task-confirmation.component.scss'],
})
export class ChangesToTaskConfirmationComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();
  makeChangeFacilitation = false;
  makeChangeFollowup = false;
  makeChangeImmediate = false;
  constructor(
    private dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      type: DocumentTemplateUniqueIdentifier;
    },
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  openFacilitation(): void {
    this.dialogHelperService
      .openDialog(
        TASK_DIALOG_ID[DocumentTemplateUniqueIdentifier.FACILITATION],
        TASK_DIALOG_COMPONENT[DocumentTemplateUniqueIdentifier.FACILITATION],
        // [DialogClassName.WR_DIALOG_MEDIUM,DialogClassName.WR_DIALOG_SHOW_STEPPER_ON_HEADER,DialogClassName.WR_DIALOG_BG],
        [DialogClassName.WR_DIALOG_MEDIUM],
        {
          caseId: this.data.caseId,
          caseType: this.data.caseType,
          caseNumber: this.data.caseNumber,
          caseUser: this.data.caseUser,
          userId: this.data.userId,
          type: DocumentTemplateUniqueIdentifier.FACILITATION,
          hideSummary: true,
          hideStartNewStep: true,
        },
        { disableClose: true },
      )
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.checkIfFollowupShouldBeOpened();
      });
  }

  openFollowUp(): void {
    this.dialogHelperService
      .openDialog(
        TASK_DIALOG_ID[DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN],
        TASK_DIALOG_COMPONENT[DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN],
        // [DialogClassName.WR_DIALOG_MEDIUM,DialogClassName.WR_DIALOG_SHOW_STEPPER_ON_HEADER,DialogClassName.WR_DIALOG_BG],
        TASK_DIALOG_CLASSES[
          DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN
        ] || [DialogClassName.WR_DIALOG_MEDIUM],
        {
          caseId: this.data.caseId,
          caseType: this.data.caseType,
          caseNumber: this.data.caseNumber,
          caseUser: this.data.caseUser,
          userId: this.data.userId,
        },
        { disableClose: true },
      )
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.dialogHelperService.closeDialog(
          DialogId.CHANGES_TO_TASK_CONFIRMATION,
          { dialogResult: true },
        );
      });
  }

  laterOn(): void {}

  close(): void {
    this.dialogHelperService.closeDialog(
      DialogId.CHANGES_TO_TASK_CONFIRMATION,
      { dialogResult: true },
    );
  }

  submit(): void {
    if (this.makeChangeFacilitation) {
      if (this.makeChangeImmediate) {
        this.openFacilitation();
      } else {
        this.laterOn();
        this.checkIfFollowupShouldBeOpened();
      }
    } else {
      this.checkIfFollowupShouldBeOpened();
    }
  }

  checkIfFollowupShouldBeOpened(): void {
    if (this.makeChangeFollowup) {
      if (this.makeChangeImmediate) {
        this.openFollowUp();
      } else {
        this.laterOn();
        this.closeChangesToTaskModal();
      }
    } else {
      this.closeChangesToTaskModal();
    }
  }

  closeChangesToTaskModal(): void {
    this.dialogHelperService.closeDialog(
      DialogId.CHANGES_TO_TASK_CONFIRMATION,
      { dialogResult: true },
    );
  }
}
