import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { SelectOption } from '@app/shared/common.model';
import { TextboxWithChipsComponent } from '@app/shared/components/documents/form-controls/textbox-with-chips/textbox-with-chips.component';

@Component({
  selector: 'wr-custom-days-and-hours',
  templateUrl: './custom-days-and-hours.component.html',
  styleUrls: ['./custom-days-and-hours.component.scss'],
})
export class CustomDaysAndHoursComponent implements OnInit {
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() formArray: UntypedFormArray;
  @Input() availableOptions: SelectOption<string>[];

  @ViewChild(TextboxWithChipsComponent, { static: false }) textboxWithChipsComponent: TextboxWithChipsComponent;

  fromToGroup: UntypedFormGroup;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createFromToGroup();
  }

  private createFromToGroup(): void {
    this.fromToGroup = this.fb.group({
      from: ['08:00', Validators.required],
      to: ['16:00', Validators.required],
    });
  }

  addCustom(): void {
    const { from, to } = this.fromToGroup.getRawValue();
    this.textboxWithChipsComponent.add({ value: `${from} - ${to}` } as MatChipInputEvent);
  }
}
