<mat-tab-group
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab label="">
<mat-card class="px-md-5">
  <mat-card-title class="my-5 text-muted">Add a page</mat-card-title>
  <div class="row">
    <div class="col-sm-4 mb-4" *ngFor="let pageType of pageTypeList">
      <button
        mat-flat-button
        (click)="stepNext(pageType.name)"
        class="mat-button-white button-builder-large w-100 mat-button-border"
      >
        <mat-icon [class]="pageType.iconClass ? pageType.iconClass : ''"
        >{{ pageType.icon }}</mat-icon
        >
        <span>{{ pageType.name }}</span>
      </button>
    </div>
  </div>
</mat-card>
  </mat-tab>

  <mat-tab label="">
    <mat-card class="px-md-5">
      <mat-card-title class="my-5 text-muted"
      >Choose Page Layout</mat-card-title
      >
      <div class="row">
        <div class="col-sm-4 mb-4" *ngFor="let questionType of questionTypeList">
          <button
            (click)="submit(questionType.type);"
            mat-flat-button
            class="mat-button-white button-builder-large mat-button-border w-100"
          >
            <img [src]="questionType.img" />
            <span class="fw-bold mt-3 text-muted" >{{ questionType.name }}</span>
          </button>
        </div>

      </div>
      <div class="text-center">
        <button
          class="position-relative mat-button-white mt-4"
          (click)="stepPrevious()"
          [color]="'white'"
          mat-flat-button
        >
          <mat-icon>chevron_left</mat-icon>
          Choose different page type
        </button>
      </div>
    </mat-card>
  </mat-tab>

</mat-tab-group>
