<wr-generic-dialog
  class="wr-stepper-dot-style"
  [showFooter]="true"
  [showBreadcrumb]="false"
>
  <ng-container title>
    {{
      titles[
        (stepper.selectedIndex + (showExcelImportedData ? -1 : 0)).toString()
      ]?.title | translate
    }}
  </ng-container>
  <ng-container subTitle>
    {{
      titles[
        (stepper.selectedIndex + (showExcelImportedData ? -1 : 0)).toString()
      ]?.subtitle | translate
    }}
  </ng-container>
  <ng-container content>
    <mat-horizontal-stepper #stepper linear headerPosition="bottom">
      <mat-step class="h-100" completed *ngIf="showExcelImportedData">
        <ng-template matStepLabel>0</ng-template>
        <ng-template [ngTemplateOutlet]="Page_0"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>a</ng-template>
        <ng-template [ngTemplateOutlet]="Page_1"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>a</ng-template>
        <ng-template [ngTemplateOutlet]="Page_2"></ng-template>
      </mat-step>
      <!--      <mat-step completed>-->
      <!--        <ng-template matStepLabel>a</ng-template>-->
      <!--        <ng-template [ngTemplateOutlet]="Page_3"></ng-template>-->
      <!--      </mat-step>-->
      <!--      <mat-step completed>-->
      <!--        <ng-template matStepLabel>a</ng-template>-->
      <!--        <ng-template [ngTemplateOutlet]="Page_4"></ng-template>-->
      <!--      </mat-step>-->
      <!--      <mat-step completed>-->
      <!--        <ng-template matStepLabel>a</ng-template>-->
      <!--        <ng-template [ngTemplateOutlet]="Page_5"></ng-template>-->
      <!--      </mat-step>-->
    </mat-horizontal-stepper>
  </ng-container>

  <ng-container footer>
    <div class="footer__actions__container">
      <button
        *ngIf="stepper.selectedIndex > 0"
        (click)="stepper.previous()"
        class="me-2 mat-focus-indicator mat-button mat-button-base mat-stroked-button"
        mat-flat-button
      >
        {{ 'BUTTON.BACK' | translate }}
      </button>
      <button
        *ngIf="stepper.selectedIndex < stepper.steps.length - 1"
        (click)="stepper.next()"
        [class]="'mat-button-active'"
        class="me-2"
        mat-flat-button
        [disabled]="!validateOrgSetup()"
      >
        {{ 'BUTTON.NEXT' | translate }}
      </button>
      <button
        wrButton
        [loading]="loading"
        class="me-2"
        *ngIf="stepper.selectedIndex === stepper.steps.length - 1"
        (click)="invitePreparedData(preparedInviteList)"
      >
        {{ 'BUTTON.FINISH' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>

<ng-template #Page_0>
  <ng-container content>
    <div *ngIf="parsedData.length <= 0" class="h-100">
      <div class="text-center entry__container-scroll">
        <!-- <h2>
          {{ 'ONBOARDING.EXCEL_UPLOAD.TITLE' | translate }}
        </h2> -->
        <div
          class="text-center entry__container-scroll terms-condition-container text-muted"
          [innerHTML]="'ONBOARDING.EXCEL_UPLOAD.SUB_TITLE' | translate"
        ></div>
      </div>
      <div
        class="drop-area d-flex justify-content-center align-content-center flex-column"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
      >
        <p>{{ 'ONBOARDING.DRAG_AND_DROP.INPUT.TEXT' | translate }}</p>
        <span class="text-muted">or</span>
        <label for="fileInput" class="custom-file-upload mt-3">
          <button wrButton class="me-2" (click)="openFileInput()">
            {{ 'BUTTON.SELECT_EXCEL_FILE.TEXT' | translate }}
          </button>
        </label>
        <input
          #fileInput
          id="fileInput"
          type="file"
          (change)="onFileChange($event.target.files[0])"
          style="display: none"
        />
      </div>
    </div>
    <div
      class="overflow-auto d-flex justify-content-center"
      *ngIf="parsedData.length > 0"
    >
      <table
        [dataSource]="dataSource"
        class="bg-transparent mat-table-white mat-table-custom mat-table-custom-last-column-action table-custom-bg"
        mat-table
        style="min-width: 50%"
        matSort
      >
        <ng-container matColumnDef="firstName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'ENTRY.COMMON.CARD.LABEL_FIRST_NAME' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'ENTRY.COMMON.CARD.LABEL_FIRST_NAME' | translate"
          >
            <span class="avatar avatar-table">
              <figcaption>
                {{
                  (element.firstName + ' ' + element.lastName).trim() ??
                    element.email | nameInitials
                }}
              </figcaption>
            </span>
            {{ element.firstName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'ENTRY.COMMON.CARD.LABEL_LAST_NAME' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'ENTRY.COMMON.CARD.LABEL_LAST_NAME' | translate"
          >
            <span>
              {{ element.lastName | translate }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'KEY.EMAIL' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'KEY.EMAIL' | translate"
          >
            <span>{{ element.email }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th *matHeaderCellDef mat-header-cell>
            <div class="th-action">{{ 'KEY.CASES.ACTION' | translate }}</div>
          </th>
          <td *matCellDef="let element" mat-cell class="row-action">
            <button
              mat-icon-button
              aria-label="Example icon button with a vertical three dot icon"
              (click)="deleteParsedData(element.email)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
    </div>
  </ng-container>
</ng-template>

<ng-template #Page_1>
  <ng-container content>
    <div class="w-100 text-end">
      <button wrButton class="text-end me-2" (click)="initiateExcelImport()">
        {{ 'BUTTON.BULK_UPLOAD.TEXT' | translate }}
      </button>
    </div>

    <wr-onboarding-hierarchy-setup
      [existingInviteList]="preparedInviteList"
      (preparedInviteListChanged)="setPreparedData($event)"
      [currentUser]="currentUser"
    ></wr-onboarding-hierarchy-setup>
  </ng-container>
</ng-template>

<ng-template #Page_2>
  <ng-container content>
    <wr-onboarding-hierarchy-setup
      [existingInviteList]="preparedInviteList"
      [readOnlyMode]="true"
      [currentUser]="currentUser"
    ></wr-onboarding-hierarchy-setup>
  </ng-container>
</ng-template>
