<div
  class="org-member add-org-member"
  *ngIf="mode === 0 && !data && !showExcelButton"
>
  <div
    (click)="addData()"
    class="add-org-icon p-2 d-flex flex-column justify-content-center text-center float-start align-items-center"
    z
  ></div>
</div>
<div
  class="org-member add-org-member add-org-option-hover"
  *ngIf="mode === 0 && !data && showExcelButton"
>
  <div
    class="add-org-icon p-2 d-flex flex-column justify-content-center text-center float-start align-items-center"
  >
    <div class="add-org-option">
      <mat-radio-group
        class="radio-hide-style d-flex flex-column flex-wrap justify-content-center"
      >
        <mat-radio-button
          class="radio-custom radio-custom-icon radio-custom-primary-light"
          (click)="selectExcelData()"
        >
          <!-- <span class="material-icons icon-circle icon-circle-sm">
          person_search
        </span> -->
          <span
            class="fw-bold text-center"
            [innerHTML]="'ONBOARDING.SELECT_EXISTING_PERSON.TEXT' | translate"
          ></span>
        </mat-radio-button>
        <div class="text-center fw-bold text-muted my-1">
          <small>{{ 'COMMON.OR' | translate }}</small>
        </div>
        <mat-radio-button
          class="radio-custom radio-custom-icon radio-custom-primary-light"
          (click)="addData()"
        >
          <!-- <span class="material-icons icon-circle icon-circle-sm">
          person_add
        </span> -->
          <span
            class="fw-bold text-center"
            [innerHTML]="'ONBOARDING.ADD_NEW_PERSON.TEXT' | translate"
          ></span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<div
  class="org-member add-org-member add-org-option-hover"
  *ngIf="mode === 1 && !data"
>
  <div
    class="add-org-icon p-2 d-flex flex-column justify-content-center text-center float-start align-items-center"
  >
    <div class="add-org-option">
      <mat-radio-group
        class="radio-hide-style d-flex flex-column flex-wrap justify-content-center"
      >
        <mat-radio-button
          class="radio-custom radio-custom-icon radio-custom-primary-light"
          (click)="searchData()"
        >
          <!-- <span class="material-icons icon-circle icon-circle-sm">
          person_search
        </span> -->
          <span
            class="fw-bold text-center"
            [innerHTML]="'ONBOARDING.SELECT_EXISTING_PERSON.TEXT' | translate"
          ></span>
        </mat-radio-button>
        <div class="text-center fw-bold text-muted my-1">
          <small>{{ 'COMMON.OR' | translate }}</small>
        </div>
        <mat-radio-button
          class="radio-custom radio-custom-icon radio-custom-primary-light"
          (click)="addData()"
        >
          <!-- <span class="material-icons icon-circle icon-circle-sm">
          person_add
        </span> -->
          <span
            class="fw-bold text-center"
            [innerHTML]="'ONBOARDING.ADD_NEW_PERSON.TEXT' | translate"
          ></span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<div
  class="org-member add-org-member filled"
  *ngIf="data"
  [class.readonly]="mode === 2"
>
  <div
    class="add-org-icon add-org-icon-filled p-2 d-flex flex-column justify-content-center text-center float-start align-items-center"
    #tooltip="matTooltip"
    matTooltipClass="mat-tooltip"
    [matTooltip]="getTooltipText()"
  >
    <span class="remove-org-member" (click)="deleteData()" *ngIf="mode !== 2">
      <mat-icon>delete</mat-icon></span
    >
    <div class="avatar avatar mb-2">
      {{ getFullName() + ' ' + data.email | nameInitials }}
    </div>
    <div class="text-primary mw-100 text-truncate">
      {{ getFullName() }}
    </div>
    <small class="mw-100 text-truncate text-muted">{{ data.email }}</small>
  </div>
</div>
