<div class="d-flex">
  <div class="m-auto location-container">
    <mat-radio-group
      [(ngModel)]="checkboxVal"
      (change)="radioButtonChanged(true)"
    >
      <mat-radio-button [value]="false">
        {{ workFromHomeVal | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="true">
        {{ 'OTHER_LOCATION' | translate }}
      </mat-radio-button>
    </mat-radio-group>
    <form
      [formGroup]="textControl.parent"
      class="textbox__form"
      *ngIf="checkboxVal"
    >
      <mat-form-field [floatLabel]="false" class="textbox__form-field">
        <mat-icon color="primary" matPrefix>location_on</mat-icon>
        <input
          type="text"
          matInput
          [formControl]="textControl"
          [wrAutoFocus]="autofocus"
          class="ms-2"
        />
      </mat-form-field>
    </form>
  </div>
</div>
