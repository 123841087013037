<wr-generic-dialog
  class="wa-stepper-inner-scroll wa-dialog-scheduler"
  [showFooter]="true"
  [caseType]="caseType"
  [employee]="employee"
  [documentTypeLabel]="documentTypeLabel"
>
  <ng-container title> {{ 'SCHEDULER.TITLE' | translate }} </ng-container>
  <ng-container content>
    <mat-horizontal-stepper
      #stepper
      linear
      class="wa-stepper-inner"
      headerPosition="bottom"
    >
      <mat-step completed>
        <ng-template matStepLabel>{{
          'SCHEDULER.STEPS.TAB.HOW' | translate
        }}</ng-template>
        <ng-template [ngTemplateOutlet]="Page_1"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>{{
          'SCHEDULER.STEPS.TAB.CONTENT' | translate
        }}</ng-template>
        <ng-template [ngTemplateOutlet]="Page_2"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>{{
          'SCHEDULER.STEPS.TAB.OCCURANCE' | translate
        }}</ng-template>
        <ng-template [ngTemplateOutlet]="Page_3"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>{{
          'SCHEDULER.STEPS.TAB.DATE' | translate
        }}</ng-template>
        <ng-template [ngTemplateOutlet]="Page_4"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>{{
          'SCHEDULER.STEPS.TAB.ATTENDEES' | translate
        }}</ng-template>
        <ng-template [ngTemplateOutlet]="Page_5"></ng-template>
      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>{{
          'SCHEDULER.STEPS.TAB.SUMMARY' | translate
        }}</ng-template>
        <ng-template [ngTemplateOutlet]="Page_0"></ng-template>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>

  <ng-container footer>
    <div class="footer__actions__container">
      <button
        (click)="stepperPrev()"
        class="me-2 mat-focus-indicator mat-button mat-button-base mat-stroked-button"
        mat-flat-button
        *ngIf="currentStep > 0"
      >
        {{ 'BUTTON.BACK' | translate }}
      </button>
      <button
        (click)="stepperNext()"
        [class]="'mat-button-active'"
        class="me-2"
        mat-flat-button
        *ngIf="currentStep < 5"
      >
        {{ 'BUTTON.NEXT' | translate }}
      </button>
      <button
        wrButton
        [loading]="loadingService.isLoading"
        (click)="submit()"
        class="me-2"
        *ngIf="currentStep === 5"
      >
        {{ 'BUTTON.SUBMIT' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
<ng-template #Page_1>
  <div class="modal-body-inner text-center d-flex">
    <div class="m-auto py-4">
      <div class="d-flex flex-wrap justify-content-center pb-2">
        <div class="fs-4 fw-normal text-muted mb-4 w-75">
          {{ 'SCHEDULER.HOW_WOULD_YOU_LIKE_TO_FOLLOW_UP.TITLE' | translate }}
        </div>
        <p class="w-75">
          {{ 'SCHEDULER.HOW_WOULD_YOU_LIKE_TO_FOLLOW_UP.SUBTITLE' | translate }}
        </p>
      </div>
      <div class="d-flex flex-wrap gap-5 justify-content-center">
        <button
          class="mat-button-square"
          mat-flat-button
          (click)="
            followupData.followupTaskOrMeeting =
              FollowupTaskOrMeetingE.MEETING_ONLY
          "
          [class]="
            followupData.followupTaskOrMeeting ===
            FollowupTaskOrMeetingE.MEETING_ONLY
              ? 'mat-button-active'
              : 'mat-button-secondary-fill'
          "
        >
          <mat-icon>groups_3</mat-icon>
          <p>{{ 'SCHEDULER.OPTIONS.LABEL.MEETING_ONLY' | translate }}</p>
        </button>
        <button
          class="mat-button-square"
          mat-flat-button
          (click)="
            followupData.followupTaskOrMeeting =
              FollowupTaskOrMeetingE.MEETING_WITH_TASK
          "
          [class]="
            followupData.followupTaskOrMeeting ===
            FollowupTaskOrMeetingE.MEETING_WITH_TASK
              ? 'mat-button-active'
              : 'mat-button-secondary-fill'
          "
        >
          <mat-icon>add_task</mat-icon>
          <p>
            {{ 'SCHEDULER.OPTIONS.LABEL.MEETING_WITH_PRE_TASK' | translate }}
          </p>
        </button>
        <button
          class="mat-button-square"
          mat-flat-button
          (click)="
            followupData.followupTaskOrMeeting =
              FollowupTaskOrMeetingE.TASK_ONLY
          "
          [class]="
            followupData.followupTaskOrMeeting ===
            FollowupTaskOrMeetingE.TASK_ONLY
              ? 'mat-button-active'
              : 'mat-button-secondary-fill'
          "
        >
          <mat-icon>sort</mat-icon>
          <p>{{ 'SCHEDULER.OPTIONS.LABEL.TASK_ONLY' | translate }}</p>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Page_2>
  <div class="modal-body-inner text-center d-flex">
    <div class="m-auto py-4">
      <div class="fs-4 fw-normal text-muted mb-3">
        {{ 'SCHEDULER.SELECTED_FOLLOW_UP_TEMPLATE' | translate }}
      </div>
      <p class="mb-5">
        Please click the icon to review and edit the questions you want to cover
        with your employee during this follow-up.
      </p>
      <button
        class="mat-button-square"
        mat-flat-button
        (click)="templatePreview()"
      >
        <mat-icon>description</mat-icon>
        <!-- <p class="text-wrap">{{ questionTemplateData?.name }}</p> -->
        <p class="text-wrap">{{ questionTemplateData?.description }}</p>
      </button>
      <div class="mt-3" (click)="templatePreview()">
        <button mat-flat-button class="mat-button-white text-dark">
          <mat-icon class="fs-5 me-2">edit_document</mat-icon>
          <span>Preview or Edit</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #Page_3>
  <div class="modal-body-inner">
    <p class="text-black">How often do you want to follow?</p>
    <div class="d-flex gap-2 mb-3">
      <button
        *ngFor="let followUpType of followUpTypes | keyvalue: originalOrder"
        (click)="followupData.followUpType = followUpType.value"
        [class]="
          followupData.followUpType == followUpType.value
            ? 'mat-button-active'
            : 'mat-button-secondary-fill'
        "
        class="mat-button-secondary-fill"
        mat-flat-button
      >
        {{ followUpType.key | translate }}
      </button>
    </div>
    <ng-template [ngIf]="followupData.followUpType == followUpTypes.CUSTOM">
      <div class="d-flex gap-2">
        <div class="form-inline-label">Every</div>
        <mat-form-field class="width-auto" appearance="fill">
          <input
            [(ngModel)]="followupData.customFollowupDuration"
            autocomplete="off"
            matInput
            placeholder="Enter days"
            type="number"
            min="1"
          />
        </mat-form-field>
        <mat-form-field class="width-auto" appearance="fill">
          <mat-select
            [(ngModel)]="followupData.customFollowupFrequency"
            [placeholder]="'DAYS' | translate"
          >
            <mat-option *ngFor="let item of frequencyList" [value]="item">
              {{ 'TIME.' + item | uppercase | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
    <hr class="mt-3 mb-4" />
    <p class="text-black">How long do you want the follow-up to last for?</p>
    <div class="d-flex gap-2">
      <mat-form-field class="width-auto" appearance="fill">
        <input
          [(ngModel)]="followupData.duration"
          autocomplete="off"
          matInput
          placeholder="Enter days"
          type="number"
          min="1"
          max="52"
        />
      </mat-form-field>
      <mat-form-field class="width-auto" appearance="fill">
        <mat-select
          [(ngModel)]="followupData.frequency"
          [placeholder]="'Options' | translate"
        >
          <mat-option *ngFor="let item of frequencyList" [value]="item">{{
            'TIME.' + item | uppercase | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <hr class="mt-3 mb-4" />
    <p class="text-black">Preferred days and time for follow-up</p>
    <div class="d-flex flex-wrap gap-2">
      <ng-container *ngFor="let days of daysList">
        <button
          (click)="selectDays(days)"
          [class]="
            followupData.preferDays.includes(days)
              ? 'mat-button-active'
              : 'mat-button-secondary-fill'
          "
          class="me-2 mb-2 mat-button-switch"
          mat-flat-button
        >
          {{ days | translate }}
        </button>
      </ng-container>
      <wr-simple-timepicker
        [formControl]="timeForm.get('time')"
        [minutesInStep]="15"
        [showIcon]="true"
      >
      </wr-simple-timepicker>
    </div>
    <div
      *ngIf="
        followupData.followupTaskOrMeeting !=
        FollowupTaskOrMeetingE.MEETING_ONLY
      "
    >
      <hr class="mt-3 mb-4" />
      <p class="text-black">
        Deadline for the employee to complete the task before meeting
      </p>
      <div class="d-flex gap-2">
        <mat-form-field appearance="fill" class="width-auto">
          <input
            [(ngModel)]="followupData.deadlineDuration"
            autocomplete="off"
            matInput
            type="number"
            min="1"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="width-auto" width="100px">
          <mat-select
            [(ngModel)]="followupData.deadlineFrequency"
            [placeholder]="'Options' | translate"
          >
            <mat-option *ngFor="let item of frequencyList" [value]="item">{{
              'TIME.' + item | uppercase | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Page_4>
  <div class="modal-body-inner">
    <div class="col-12">
      <div class="row">
        <div class="col-md-6">
          <br />
          <p class="text-black">Schedule the first event</p>
          <mat-form-field class="mat-date-custom" appearance="fill">
            <input
              matInput
              [matDatepicker]="datepicker"
              [(ngModel)]="followupData.firstEventDate"
              placeholder="Enter date"
              [min]="minDate"
              (focus)="datepicker.open()"
            />
            <mat-datepicker-toggle matPrefix [for]="datepicker">
              <mat-icon matDatepickerToggleIcon color="primary"
                >calendar_month</mat-icon
              >
            </mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>

          <hr class="mt-1 mb-3" />
          <p class="text-black">Set the location</p>
          <mat-form-field appearance="fill">
            <input
              [(ngModel)]="followupData.location"
              autocomplete="off"
              matInput
              placeholder="Please enter location here"
            />
            <mat-hint
              >Please enter meeting location or link to online
              meeting.</mat-hint
            >
          </mat-form-field>

          <hr class="mt-1 mb-3" />
          <p class="text-black">Attendees</p>
          <div class="d-flex flex-wrap gap-2 mb-4">
            <div
              *ngFor="let existingTeam of accessUsers; let ind = index"
              class="badge-custom border border-1 border-primary d-inline-flex align-items-center p-1 pe-3 rounded-pill text-dark"
            >
              <span class="avatar me-2">
                <figure
                  [ngStyle]="{
                    'background-image': 'url(' + existingTeam.photo + ')'
                  }"
                ></figure>
                <figcaption>{{ existingTeam?.name | nameInitials }}</figcaption>
              </span>
              <div>
                <h5 class="mb-0">{{ existingTeam?.name }}</h5>
              </div>
              <span
                class="material-icons p-1 cursor-pointer ms-1"
                (click)="deleteEmailAccessUser(ind)"
                >close</span
              >
            </div>
            <div
              *ngFor="let email of followupData.attendees; let ind = index"
              class="badge-custom border border-1 border-primary d-inline-flex align-items-center p-1 pe-3 rounded-pill text-dark"
            >
              <mat-icon class="icon-circle icon-circle-sm" matListItemIcon
                >person</mat-icon
              >
              <div>
                <h5 class="mb-0">{{ email }}</h5>
              </div>
              <span
                class="material-icons p-1 cursor-pointer ms-1"
                (click)="deleteEmail(ind)"
                >close</span
              >
            </div>
          </div>

          <button mat-flat-button (click)="changeStepToAddAttendess()">
            {{ 'Add attendees' | translate }}
          </button>
        </div>
        <div class="col-md-6" *ngIf="followupData.firstEventDate">
          <div class="bg-primary-light p-4 rounded">
            <h2 class="text-orange mb-2">Next follow-up</h2>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4 class="fw-bold mb-1">
                  {{ followupData.firstEventDate | customDate }}
                </h4>
                <p>Time: {{ followupData.preferTime }}</p>
              </div>
              <div>
                <span class="rounded-pill px-3 py-1 bg-primary text-white"
                  >Meeting</span
                >
              </div>
            </div>
          </div>

          <mat-card class="mt-4 mat-card-list">
            <mat-card-header>
              <mat-card-title>Upcoming follow-ups</mat-card-title>
            </mat-card-header>
            <mat-card-content class="upcoming-list">
              <mat-list role="list">
                <mat-list-item
                  role="listitem"
                  *ngFor="let followupDate of getUpcommingFollowupDates"
                >
                  <div
                    class="d-flex justify-content-between align-items-center py-2 w-100"
                  >
                    <span class="upcoming-list-time"
                      ><mat-icon>calendar_today</mat-icon>
                      {{ followupDate.date | customDate }}</span
                    >
                    <span class="upcoming-list-time ps-4"
                      ><mat-icon>schedule</mat-icon
                      >{{ followupDate.time }}</span
                    >
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #Page_5>
  <div class="modal-body-inner">
    <div class="attendees-optional">
      <p class="mb-4 small">
        {{ 'ATTENDEES' | translate }}
      </p>

      <mat-list class="custom-mat-list custom-mat-list-action" role="list">
        <mat-list-item
          role="listitem"
          *ngIf="!addMode"
          (click)="addMode = true"
        >
          <mat-icon class="icon-circle" matListItemIcon>person_add</mat-icon>
          <span matListItemTitle>{{ 'Add attendee' | translate }}</span>
        </mat-list-item>
        <mat-list-item
          class="custom-mat-list-add"
          role="listitem"
          *ngIf="addMode"
        >
          <mat-form-field class bg="white">
            <input
              type="email"
              matInput
              [(ngModel)]="emailId"
              name="inviteEmail"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              #inviteEmail="ngModel"
              [placeholder]="'Email' | translate"
            />
            <mat-error *ngIf="inviteEmail.hasError('required')">
              {{ 'SETUP.TEAMSPACE.EMAIL_IS_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="inviteEmail.hasError('pattern')">
              {{ 'EMAIL_FORMAT_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-icon-button
            color="primary"
            [disabled]="
              inviteEmail.hasError('required') ||
              inviteEmail.hasError('pattern')
            "
            (click)="submitEmailId()"
          >
            <!-- {{ 'A' | translate }} -->
            <mat-icon>add_circle</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
      <div class="overflow-auto list-teamspace">
        <mat-list class="custom-mat-list custom-action-onhover" role="list">
          <mat-list-item
            role="listitem"
            *ngFor="let email of followupData.attendees; let ind = index"
          >
            <mat-icon class="icon-circle" matListItemIcon>person</mat-icon>
            <div matListItemTitle class="flex-grow-1">{{ email }}</div>
            <button
              mat-icon-button
              aria-label=""
              (click)="deleteEmail(ind)"
              class="no-background"
            >
              <mat-icon matSuffix class="text-black-50">delete</mat-icon>
            </button>
          </mat-list-item>
          <mat-list-item
            role="listitem"
            *ngFor="let existingTeam of accessUsers; let ind = index"
          >
            <div class="avatar avatar-setup">
              <figure
                [ngStyle]="{
                  'background-image': 'url(' + existingTeam.photo + ')'
                }"
              ></figure>
              <figcaption>{{ existingTeam?.name | nameInitials }}</figcaption>
            </div>
            <div matListItemTitle class="flex-grow-1">
              <h5 class="mb-0 text-truncate">{{ existingTeam?.name }}</h5>
              <!--          <p class="mb-0 text&#45;&#45;small text-truncate">{{ existingTeam?.email}}</p>-->
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #Page_0>
  <div class="modal-body-inner">
    <div class="scheduler-summary mb-4 align-middle">
      <h5 class="text-primary">How would you like to follow-up?</h5>
      <mat-card>
        <div class="scheduler-summary-item">
          <mat-icon>sort</mat-icon>
          <span>{{ followupData.followupTaskOrMeeting }}</span>
        </div>
      </mat-card>
    </div>
    <div class="scheduler-summary mb-4 align-middle">
      <h5 class="text-primary">Followup content document</h5>
      <mat-card>
        <div class="scheduler-summary-item">
          <mat-icon>description</mat-icon>
          <span> {{ questionTemplateData?.description }}</span>
        </div>
      </mat-card>
    </div>
    <div class="scheduler-summary mb-4 align-middle">
      <h5 class="text-primary">Occurance</h5>
      <mat-card>
        <div class="scheduler-summary-item">
          <p>How often do you want to follow?</p>
          <span>{{ followupData.followUpType }}</span>
        </div>
        <div class="scheduler-summary-item">
          <p>How long do you want to follow-up last for?</p>
          <span>{{ followupData.duration }}</span>
          <span>{{ followupData.frequency }}</span>
        </div>
        <div class="scheduler-summary-item">
          <p>Preferred days and time for follow-up?</p>
          <span class="badge" *ngFor="let days of followupData.preferDays">{{
            days
          }}</span>
          <span class="text-muted ms-3">
            <b class="me-2">Time:</b>{{ followupData.preferTime }}
          </span>
        </div>
      </mat-card>
    </div>
    <div class="scheduler-summary mb-4 align-middle">
      <h5 class="text-primary">Date</h5>
      <mat-card>
        <div class="scheduler-summary-item">
          <p>Schedule the first event</p>
          <span class="text-muted me-5">
            <mat-icon class="me-0">calendar_today</mat-icon>
            {{ followupData.firstEventDate | customDate }}
            <span class="d-inline-block mx-2">-</span>
            {{ followupData.preferTime }}
          </span>
        </div>
        <div class="scheduler-summary-item">
          <p>Location</p>
          <span class="text-muted me-5">
            {{ followupData.location }}
          </span>
        </div>
        <div class="scheduler-summary-item">
          <p>Next Follow-up</p>
          <span class="text-muted me-5">
            {{ followupData.firstEventDate | customDate }}
            <span class="d-inline-block mx-2">-</span>
            {{ followupData.preferTime }}
          </span>
        </div>
      </mat-card>
    </div>
    <div class="scheduler-summary mb-4 align-middle">
      <h5 class="text-primary">Attendees Optional</h5>
      <mat-card>
        <div class="row">
          <div
            class="col-12 mb-3"
            *ngFor="let attendie of followupData.attendees"
          >
            <div class="d-flex align-items-center">
              <div>
                <p class="mb-0 small">{{ attendie }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3" *ngFor="let attendie of accessUsers">
            <div class="d-flex align-items-center">
              <span class="avatar me-2">
                <figure
                  [ngStyle]="{
                    'background-image': 'url(' + attendie?.photo + ')'
                  }"
                ></figure>
                <figcaption>{{ attendie?.name | nameInitials }}</figcaption>
              </span>
              <div>
                <h5 class="mb-0 text-dark">{{ attendie.name }}</h5>
                <p class="mb-0 small"></p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
