<div [formGroup]="displayTimeForm" class="time-input__container w-auto">
  <label class="" *ngIf="timeMask">
    <span *ngIf="showIcon"
      class="align-items-center d-inline-flex material-icons fs-5 text-primary"
      style="margin-right: -26px; padding-left: 8px; z-index: 9"
      >schedule</span
    >
    <input
      #inputElement
      [hoursRange]="hoursRange"
      [textMask]="{ mask: timeMask, guide: false }"
      [ngClass]="{ 'only-text': formControl.disabled }"
      [allowMaxRangeValue]="allowMaxRangeValue"
      (keydown)="onKeyDown($event)"
      [matMenuTriggerFor]="menu"
      placeholder="HH:mm"
      formControlName="time"
      wrHoursTimeRange
    />
    <div *ngIf="!formControl.disabled" class="time-changers__container">
      <svg
        class="svg-triangle"
        (click)="
          changeTime(-minutesInStep);
          $event.stopPropagation();
          $event.preventDefault()
        "
        x="0px"
        y="0px"
        viewBox="0 0 256 256"
        style="enable-background: new 0 0 256 256"
        xml:space="preserve"
      >
        <polygon
          points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128 		"
        />
      </svg>
    </div>
  </label>
</div>
<mat-menu #menu="matMenu">
  <div class="time-picker-container" (click)="$event.stopPropagation()">
    <div class="scrollable-div">
      <div class="scrollable-inner" #hoursScrollable>
        <div
          *ngFor="let hour of hours"
          [class.selected]="
            hour === displayTimeForm.value['time']?.split(':')[0]
          "
          (click)="
            displayTimeForm.controls['time'].setValue(
              hour + ':' + displayTimeForm.value['time']?.split(':')[1]
            )
          "
        >
          {{ hour }}
        </div>
      </div>
    </div>
    <div class="scrollable-div">
      <div class="scrollable-inner" #minutesScrollable>
        <div
          *ngFor="let minute of minutes"
          [class.selected]="
            minute === displayTimeForm.value['time'].split(':')[1]
          "
          (click)="
            displayTimeForm.controls['time'].setValue(
              displayTimeForm.value['time'].split(':')[0] + ':' + minute
            )
          "
        >
          {{ minute }}
        </div>
      </div>
    </div>
    <!--    <div class="am-pm-selector">-->
    <!--      <div-->
    <!--        [class.selected]="selectedPeriod === 'AM'"-->
    <!--        (click)="onPeriodSelected('AM')"-->
    <!--      >-->
    <!--        AM-->
    <!--      </div>-->
    <!--      <div-->
    <!--        [class.selected]="selectedPeriod === 'PM'"-->
    <!--        (click)="onPeriodSelected('PM')"-->
    <!--      >-->
    <!--        PM-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</mat-menu>
