import { IInviteResponse } from './components/company/company.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UserOnboardingStatus } from '@app/shared/user.model';
import { RouteSegments } from '@app/shared/routing.model';
import { RoleName } from '../shared/constants/role.constants';

export interface IInviteEmployeeFormConfig {
  isNameRequired: boolean;
  backButton?: boolean;
  autoSave?: boolean;
  promptSaveAsAdmin?: boolean;
  isLeaderRequired?: boolean;
  saveAsAdminLabel?: string;
}

export interface IInviteEmployeeFormSubmit {
  component: string;
  data: IInviteResponse;
}

export interface IInviteEmployeeFormDataReturn {
  component: string;
  data: IInviteEmployeeFormData;
}

export interface IInviteEmployeeFormData {
  firstName?: string;
  lastName?: string;
  email: string;
  userWorkspaceId?: string;
  parentUserWorkspaceId?: string;
  companyId?: string;
  hrContactId?: string;
  userType: RoleName[];
  tier?: number[];
  saveAsAdmin?: boolean;
}

export interface IInviteReqData extends IInviteEmployeeFormData {
  phoneNumber?: string;
}

export interface IInvitesList {
  email: string;
  name?: string;
  firstName: string;
  lastName: string;
  userType: RoleName[];
  parentUserWorkspaceId?: any;
  hrContactId?: any;
  hrContactName?: string;
  _id: string;
  child?: IInvitesList[] | MatTableDataSource<IInvitesList>;
  tier?: number[];
}

export interface IWorkspaceProfileRes {
  companyName: string;
  hrContactName: string;
  jobRole: string;
  jobTitle: string;
  leaderName: string;
  teamName: string;
  hrContactId: string;
  parentUserWorkspaceId: string;
}

export interface IWorkSpaceUpdateData {
  name?: string;
  jobRole?: string;
  jobTitle?: string;
  status?: UserOnboardingStatus;
}
export interface IJobRolePagination {
  searchString?: string;
  limit?: number;
  skip?: number;
}

export interface IJobRoleResponse {
  count: number;
  data: IJobRoleList[];
}

export interface IJobRoleList {
  _id: string;
  name: ITranslations;
}
export interface ITranslations {
  en: string;
  no: string;
}
export interface ITeamList {
  email: string;
  name: string;
  status: string;
  _id: string;
}

export interface IChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const StepToUserOnboardingStatusMapping: {
  route: string;
  status: UserOnboardingStatus[];
  fragment: boolean;
}[] = [
  {
    route: RouteSegments.SETUP + '/' + RouteSegments.WELCOME,
    status: [UserOnboardingStatus.COMPANY],
    fragment: true,
  },
  {
    route: RouteSegments.SETUP + '/' + RouteSegments.COMPANY_SETUP,
    status: [UserOnboardingStatus.COMPANY_TNC],
    fragment: true,
  },
  {
    route: RouteSegments.SETUP + '/' + RouteSegments.WORKSPACE_SETUP,
    status: [UserOnboardingStatus.WORKSPACE],
    fragment: false,
  },
  {
    route: RouteSegments.COMPANY_PROFILE,
    status: [UserOnboardingStatus.ACCOUNT, UserOnboardingStatus.ONBOARDING],
    fragment: false,
  },
  {
    route: RouteSegments.SETUP + '/' + RouteSegments.TEAMSPACE,
    status: [UserOnboardingStatus.USER_WORKSPACE],
    fragment: false,
  },
  {
    route: RouteSegments.SETUP + '/' + RouteSegments.TEAMSPACE_INVITE,
    status: [
      UserOnboardingStatus.USER_TEAMSPACE,
      UserOnboardingStatus.USER_INVITATION,
    ],
    fragment: true,
  },
];

export interface ITeamDropdownRes {
  name: string;
  photo: string;
  roles: RoleName[];
  _id: string;
  userWorkspaceName: string;
}
