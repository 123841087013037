<div class="card-details__value__container">
<table>
<ng-container *ngIf="isNumberType; else daysObject">

  <h4 *ngIf="summaryControl.heading && summaryControl.heading!==''" class="card-details__title">{{ summaryControl.heading }}</h4>
  <tr [style.paddingBottom.px]="16" *ngIf="summaryControl.value; else noData">
    <td rowspan="2" class="card-details__value__label pe-5"><b>{{ summaryControl.value }}</b> {{'WORK_HOURS.HOURS_PER_WEEK' | translate}}</td>
  </tr>
</ng-container>

<ng-template #daysObject>
  <ng-container *ngIf="daysInSummary">
    <ng-container *ngFor="let day of daysInSummary">
      <tr *ngIf="day.active">
        <td class="card-details__value__label pe-5" [class.is-active]="day.active">{{ day.label }}</td>
        <td class="card-details__value__text ps-2">
          {{ (day.from || '-') + ' - ' + (day.to || '-') }}
        </td>
      </tr>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noData>
  <span class="card-details__no-value__text">{{ summaryControl.noValueInfo || '-' }}</span>
</ng-template>
    
</table>
</div>