import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StatusCodes } from 'http-status-codes';

import { SecurityService } from '@app/core/services/security/security.service';
import { RouteSegments } from '@app/shared/routing.model';
import { TokenKey } from '@app/modules/shared/models/common.model';
import {ApiList} from "@app/app.constants";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly securityService: SecurityService, private readonly router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers: any =
      req.method === 'POST' &&
      (req.url.includes(ApiList.auth.register) ||
        req.url.includes(ApiList.auth.login) ||
        req.url.includes(ApiList.auth.reset) || // TODO: Check for easier solution
        req.url.includes(ApiList.auth.confirmReset))
        ? {}
        : {
            Authorization: `Bearer ${this.securityService.getToken(TokenKey.ACCESS)}`,
          };

    const authRequest = req.clone({
      setHeaders: headers,
    });

    return next.handle(authRequest).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === StatusCodes.UNAUTHORIZED) {
          this.router.navigate([RouteSegments.ENTRY]);
        }
        throw error;
      }),
    );
  }
}
