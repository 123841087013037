<wr-generic-dialog
  [showHeaderTitle]="true"
  [showFooter]="true"
  [showBreadcrumb]="false"
>
  <ng-container title>
    <div class="wa-account-card-title">
      <p class="mb-0 small">
        {{ 'ONBOARDING.SELECT_HR_FUNCTION.TITLE' | translate }}
      </p>
      <h1 class="text-primary font-weight-bold">
        {{ 'ONBOARDING.SELECT_HR_FUNCTION.SUBTITLE' | translate }}
      </h1>
    </div>
  </ng-container>
  <ng-container content>
    <ng-container>
      <div class="d-flex">
        <mat-form-field appearance="fill" class="me-2 search-input w-100">
          <mat-icon color="primary" matPrefix>search</mat-icon>
          <input
            (keyup)="setFilteredTeamData()"
            [(ngModel)]="searchString"
            [placeholder]="'FILTERS.LABEL.SEARCH' | translate"
            autocomplete="off"
            matInput
          />
          <mat-icon color="light" matSuffix (click)="clearSearch()"
            >close</mat-icon
          >
        </mat-form-field>
      </div>

      <div class="d-flex search-team">
        <mat-list
          class="custom-mat-list custom-mat-list-hover w-100"
          role="list"
        >
          <mat-list-item
            (click)="teamHrData = teamData"
            *ngFor="let teamData of filteredTeamData"
            class="bg-light"
            role="listitem"
          >
            <div
              class="text-truncate-depend gap-2 align-items-center d-flex w-100"
            >
              <mat-icon class="icon-circle" matListItemIcon>person</mat-icon>
              <div class="w-100">
                <div class="text-truncate">
                  {{ teamData.firstName }} {{ teamData.lastName }}
                </div>
                <div class="text-truncate" matListItemLine>
                  {{ teamData.email }}
                </div>
              </div>
            </div>
            <mat-icon
              *ngIf="teamHrData && teamHrData.email === teamData.email"
              class="icon-circle icon-circle-sm bg-primary ms-auto"
              >check
            </mat-icon>
            <!--            <mat-icon class="icon-circle icon-circle-sm bg-secondary ms-1"-->
            <!--            >close-->
            <!--            </mat-icon>-->
          </mat-list-item>
        </mat-list>
      </div>
    </ng-container>
  </ng-container>
  <ng-container footer>
    <div class="mt-auto d-flex gap-2 justify-content-end p-3">
      <button
        (click)="handleSubmit()"
        variant="primary"
        wrButton
        [disabled]="!teamHrData"
      >
        {{ 'BUTTON.SELECT' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
