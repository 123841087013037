import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { ITemplateVersionData } from '@app/modules/content-creator/content-creatorer.model';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { PreviewModeComponent } from '@app/modules/content-creator/preview-mode/preview-mode.component';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { KeyValue } from '@angular/common';
import { ScheduleFollowUpDto } from '@app/shared/components/scheduler/scheduler.component';
import { SchedulerService } from '@app/shared/scheduler/scheduler.service';
import { AccessUser } from '@app/modules/case-page/models/access.model';
import { MatStepper } from '@angular/material/stepper';
import { CaseType } from '@app/modules/case-page/models/case-details.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CaseTypes } from '../../models/cases.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingService } from '@app/core/services/loading/loading.service';

@Component({
  selector: 'wr-scheduler-main',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerMainComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private readonly onDestroy$ = new Subject<null>();

  @ViewChild('stepper') stepper: MatStepper;

  @Input() questionTemplateData: ITemplateVersionData;
  @Input() accessUsers: AccessUser[];
  @Input() caseType: CaseType;
  @Input() employee: string;
  @Output() submitAction = new EventEmitter<IFollowupData>();

  currentStep = 0;
  documentTypeLabel = 'Schedule a follow-up';

  followUpTypes = FollowupTypesEnum;
  FollowupTaskOrMeetingE = FollowupTaskOrMeetingEnum;
  daysList = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  frequencyList = ['days', 'weeks', 'months'];

  followupData: IFollowupData = {
    followupTaskOrMeeting: FollowupTaskOrMeetingEnum.MEETING_ONLY,
    customFollowupFrequency: 'weeks',
    customFollowupDuration: 0,
    duration: 0,
    followUpCommunicationType: '',
    note: '',
    followUpType: FollowupTypesEnum.WEEKLY,
    attendees: [],
    preferTime: '',
    preferDays: [],
    location: '',
    deadlineFrequency: 'days',
    deadlineDuration: 0,
    firstEventDate: new Date(),
    frequency: 'weeks',
    templateId: '',
    templateVersion: 0,
    timeZone: 0,
  };
  emailId: string;
  addMode = false;

  minDate = new Date();
  timeForm: UntypedFormGroup;

  constructor(
    private readonly dialogHelperService: DialogHelperService,
    private readonly schedulerService: SchedulerService,
    public loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      caseId: string;
      caseType: CaseTypes;
      caseNumber: string;
      caseUser: string;
      documentId: string;
      type: any;
      commentMode: boolean;
    },
    private readonly fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.questionTemplateData) {
      this.followupData.templateId = this.questionTemplateData._id;
      this.followupData.templateVersion =
        this.questionTemplateData.versions.versionNumber;
    }

    this.createTimeFormGroup();
    this.watchTimeVal();
  }
  ngAfterViewInit(): void {
    this.stepper._getIndicatorType = () => 'number';
  }
  private watchTimeVal(): void {
    const control = this.timeForm.get('time');
    if (control) {
      control.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((value) => {
          this.onTimeSelectionChange(value);
        });
    }
  }

  createTimeFormGroup(): void {
    this.timeForm = this.fb.group({
      time: ['08:00', Validators.required],
    });
  }
  selectDays(days: string): void {
    const existingIndex = this.followupData.preferDays.indexOf(days);
    if (existingIndex >= 0) {
      this.followupData.preferDays.splice(existingIndex, 1);
    } else {
      this.followupData.preferDays.push(days);
    }
    const nextDate = this.getNextDayAndDateFromList(
      this.followupData.preferDays,
    );

    this.followupData.firstEventDate = nextDate;
  }

  remove(email: string): void {
    const index = this.followupData.attendees.indexOf(email);

    if (index >= 0) {
      this.followupData.attendees.splice(index, 1);
    }
  }

  getNextDayAndDateFromList(dayList: string[]): Date {
    // Find the index of the current day in the sorted list of days
    const sortedList = dayList.slice().sort((a, b) => {
      const indexA = this.daysList.indexOf(a);
      const indexB = this.daysList.indexOf(b);
      return indexA - indexB;
    });
    if (!sortedList || sortedList.length <= 0) {
      return new Date();
    }
    const currentDayIndex = new Date().getDay();
    const firstDay = sortedList[0];
    const targetDayIndex = this.daysList.indexOf(firstDay);

    // Calculate the number of days until the next occurrence of the target day
    let daysUntilNextOccurrence = targetDayIndex - currentDayIndex + 1;
    if (daysUntilNextOccurrence <= 0) {
      daysUntilNextOccurrence += 7; // If the target day is earlier in the week, add 7 days
    }

    // Calculate the date of the next occurrence
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + daysUntilNextOccurrence);

    return currentDate;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.followupData.attendees.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  templatePreview(): void {
    this.dialogHelperService.openDialog(
      DialogId.ACCESS_SETTINGS,
      PreviewModeComponent,
      [
        DialogClassName.WR_DIALOG_SLIDE,
        DialogClassName.WR_DIALOG_SHOW_STEPPER_ON_HEADER,
      ],
      { ...this.data },
      { backdropClass: 'dark-backdrop', disableClose: true },
    );
  }

  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>,
  ): number => {
    return 0;
  };

  get getUpcommingFollowupDates(): { date: string; time: string }[] {
    const schedulerData = this.followupData;
    let taskDeadline;
    let followupFrequency = this.getDaysFromFollowupTypes(
      schedulerData.followUpType,
    );
    if (schedulerData.followUpType === FollowupTypesEnum.CUSTOM) {
      followupFrequency = {
        duration: schedulerData.customFollowupDuration,
        frequency: schedulerData.customFollowupFrequency,
      };
    }
    if (
      schedulerData.followupTaskOrMeeting !==
      FollowupTaskOrMeetingEnum.MEETING_ONLY
    ) {
      taskDeadline = {
        interval: schedulerData.deadlineDuration,
        frequency: schedulerData.deadlineFrequency,
      };
    }
    const schedulerObj: ScheduleFollowUpDto = {
      frequency: {
        interval: followupFrequency.duration,
        unit: followupFrequency.frequency,
      },
      duration: {
        interval: schedulerData.duration,
        unit: schedulerData.frequency,
      },
      firstEventDate: schedulerData.firstEventDate,
      preferDays: schedulerData.preferDays,
      preferTime: schedulerData.preferTime,
      followUpType: schedulerData.followUpType,
      deadline: taskDeadline,
    };
    return this.schedulerService.getFollowUpDates(schedulerObj);
  }

  getDaysFromFollowupTypes(type: FollowupTypesEnum): {
    duration: number;
    frequency: string;
  } {
    let returnValue = { duration: 1, frequency: 'days' };
    switch (type) {
      case FollowupTypesEnum.DAILY:
        returnValue = { duration: 1, frequency: 'days' };
        break;
      case FollowupTypesEnum.WEEKLY:
        returnValue = { duration: 7, frequency: 'days' };
        break;
      case FollowupTypesEnum.MONTHLY:
        returnValue = { duration: 1, frequency: 'month' };
        break;
      case FollowupTypesEnum.BI_WEEKLY:
        returnValue = { duration: 2, frequency: 'weeks' };
        break;
      default:
        returnValue = { duration: 1, frequency: 'days' };
        break;
    }

    return { duration: returnValue.duration, frequency: returnValue.frequency };
  }

  submitEmailId(): void {
    this.followupData.attendees.push(this.emailId);
    this.emailId = '';
    this.addMode = false;
  }

  deleteEmail(index: number): void {
    this.followupData.attendees.splice(index, 1);
  }
  deleteEmailAccessUser(index: number): void {
    this.accessUsers.splice(index, 1);
  }

  submit(): void {
    this.followupData.timeZone = new Date().getTimezoneOffset();
    this.submitAction.emit(this.followupData);
  }

  changeStepToAddAttendess(): void {
    this.currentStep += 1;
    this.stepper.selectedIndex = this.currentStep;
  }

  changeStepperToEnd(): void {
    this.stepper.selectedIndex = 5;
  }

  stepperNext(): void {
    if (this.currentStep === 3) this.currentStep = 5;
    else this.currentStep += 1;
    this.stepper.selectedIndex = this.currentStep;
  }

  stepperPrev(): void {
    if (this.currentStep === 5) this.currentStep = 3;
    else this.currentStep -= 1;
    this.stepper.selectedIndex = this.currentStep;
  }
  onTimeSelectionChange(value: string): void {
    this.followupData.preferTime = value;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}

export interface IFollowupData {
  followupTaskOrMeeting: FollowupTaskOrMeetingEnum;
  customFollowupFrequency: string;
  customFollowupDuration: number;
  duration: number;
  followUpCommunicationType: string;
  note: string;
  followUpType: FollowupTypesEnum;
  attendees: string[];
  preferTime: string;
  preferDays: string[];
  location: string;
  deadlineDuration: number;
  deadlineFrequency: string;
  firstEventDate: Date;
  frequency: string;
  timeZone: number;
  templateId: string;
  templateVersion: number;
}

export enum FollowupTaskOrMeetingEnum {
  MEETING_ONLY = 'MEETING_ONLY',
  MEETING_WITH_TASK = 'MEETING_WITH_TASK',
  TASK_ONLY = 'TASK_ONLY',
}

export enum FollowupTypesEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  CUSTOM = 'CUSTOM',
}
