<div class="notification-menu-body">
  <div
    mat-menu-item
    class="notification-menu-item d-flex border-bottom"
    *ngFor="let notification of notifications; let i = index"
    (click)="navigateToCase(notification)"
  >
    <mat-icon class="notification-menu-item-icon me-2">{{
      notification.notificationType === 'CASE'
        ? icons[notification.caseType]
        : icons[notification.notificationType]
    }}</mat-icon>
    <div
      class="w-100 d-flex gap-2 flex-column"
      [ngSwitch]="notification.notificationType"
    >
      <div *ngSwitchCase="'CASE'">
        <span class="fw-bold text-dark text-uppercase"
          >{{ 'NOTIFICATION.' + notification?.caseType | translate }} :
        </span>
        <b
          >{{
            notification.user.userId === currentUser?._id
              ? ('COMMON.YOU' | translate)
              : notification?.user?.name
          }}
        </b>
        {{ 'NOTIFICATION.FILED_A_NEW_REPORT.TEXT' | translate }}
        {{
          notification.content.case.startDate &&
          notification.content.case.endDate
            ? 'for '
            : ''
        }}<b>{{ getDateDifference(notification.content.case) }}</b>
      </div>
      <div *ngSwitchCase="'STATUS_UPDATE'">
        <span class="fw-bold text-dark text-uppercase"
          >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? ('COMMON.YOU' | translate)
            : notification.user.name
        }}</b>
        {{ 'COMMON.COMPLETED' | translate }}
        <b
          >{{
            'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_' +
              notification.content.nextTask.currentStatus | translate
          }}
        </b>
        <ng-container *ngIf="notification.content.nextTask.nextStatus">
          <mat-icon class="m-0 text-center">arrow_forward</mat-icon>
          {{ 'NOTIFICATION.NEW_STATUS' | translate }}
          :
          <b>{{
            'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_' +
              notification.content.nextTask.nextStatus | translate
          }}</b>
        </ng-container>
      </div>
      <div *ngSwitchCase="'NEW_FEED'">
        <span class="fw-bold text-dark text-uppercase"
          >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? ('COMMON.YOU' | translate)
            : notification.user.name
        }}</b>
        {{ 'NOTIFICATION.ADDED_NEW_POST_TO_FEED.TEXT' | translate }}
      </div>
      <div *ngSwitchCase="'NEW_COMMENT'">
        <span class="fw-bold text-dark text-uppercase"
          >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? ('COMMON.YOU' | translate)
            : notification.user.name
        }}</b>
        {{ 'NOTIFICATION.ADDED_COMMENT_TO_FEED.TEXT' | translate }}
      </div>
      <div *ngSwitchCase="NotificationTypeEnum.REMINDER">
        <span class="fw-bold text-dark text-uppercase"
          >{{ 'NOTIFICATION.' + notification?.notificationType | translate }} :
        </span>
        <b>{{
          notification.user.userId === currentUser?._id
            ? ('COMMON.YOU' | translate)
            : notification.user.name
        }}</b>
        <span
          *ngIf="notification.eventName === NotificationEventName.TASKS_EXPIRED"
        >
          {{ 'NOTIFICATION.TEXT.REMINDER_EXPIRED' | translate }}
        </span>
        <span
          *ngIf="
            notification.eventName === NotificationEventName.TASKS_DUE_TODAY
          "
        >
          {{ 'NOTIFICATION.TEXT.REMINDER_DUE_TODAY' | translate }}
        </span>
      </div>
      <div *ngIf="notification.content?.contentText?.length > 0">
        <small
          ><i>{{ notification.content?.contentText }} </i></small
        >
      </div>
      <div
        class="notification-menu-item-task d-flex align-items-center justify-content-between py-2 px-3 rounded"
        *ngIf="
          notification.content?.nextTask?.type &&
          notification.content?.nextTask?.type !== ''
        "
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon
            class="me-0"
            [style.color]="getTaskColor(notification.content.nextTask.dueDate)"
            >draft</mat-icon
          >
          <p class="mb-0 fw-bold">
            {{
              'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_' +
                notification.content.nextTask.type | translate
            }}
          </p>
        </div>
        <span
          >{{ 'KEY.TASKS.DUE_DATE' | translate }}:
          {{ notification.content.nextTask.dueDate | customDate }}</span
        >
      </div>
      <div
        class="notification-menu-item-task d-flex align-items-center justify-content-between py-2 px-3 rounded"
        *ngIf="
          notification.content?.nextTask?.currentStatus &&
          notification.content?.nextTask?.currentStatus !== '' &&
          notification.notificationType === NotificationTypeEnum.REMINDER
        "
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon
            class="me-0"
            [style.color]="getTaskColor(notification.content.nextTask.dueDate)"
            >draft</mat-icon
          >
          <p class="mb-0 fw-bold">
            {{
              'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_' +
                notification.content.nextTask.currentStatus | translate
            }}
          </p>
        </div>
        <span
          >{{ 'KEY.TASKS.DUE_DATE' | translate }}:
          {{ notification.content.nextTask.dueDate | customDate }}</span
        >
      </div>

      <div
        class="notification-menu-item-avatar d-flex justify-content-end align-items-center gap-1"
      >
        <span class="rounded-pill border py-1 px-2 me-1">{{
          'COMMON.DOCUMENTS.' + notification?.caseType + '.NAME' | translate
        }}</span>
        <span class="avatar avatar-sm">
          <figure
            *ngIf="notification?.user?.photo"
            [ngStyle]="{
              'background-image': 'url(' + notification?.user?.photo + ')'
            }"
          ></figure>
          <figcaption>{{ notification?.user?.name | nameInitials }}</figcaption>
        </span>
        <span>{{ notification?.user?.name }}</span>
        <span>&#x2022;</span>
        <span>{{ getCreatedDisplayDate(notification?.createdAt) }}</span>
      </div>
    </div>
    <span
      [ngClass]="{ 'notification-menu-item-unread': notification.status === 0 }"
    ></span>
  </div>
</div>
