<br />
<form *ngIf="workspaceProfileForm" [formGroup]="workspaceProfileForm" class="form_container">
  <mat-form-field>
    <input
      MatAutoFocusDirective
      [placeholder]="'ENTRY.COMMON.CARD.LABEL_COMPANY_NAME' | translate"
      formControlName="companyName"
      matInput
      type="text"
    />
    <mat-error *ngIf="workspaceProfileForm.get('companyName').hasError('required')">
      {{ 'SETUP.WORKSPACE_PROFILE.COMPANY_NAME_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" *ngIf="workspaceProfileData.teamName">
    <input
      [placeholder]="'ENTRY.COMMON.CARD.LABEL_TEAM_NAME' | translate"
      formControlName="teamName"
      matInput
      type="text"
    />
    <mat-error *ngIf="workspaceProfileForm.get('teamName').hasError('required')">
      {{ 'SETUP.WORKSPACE_PROFILE.TEAM_NAME_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>

    <wr-select-with-search
            [control]="workspaceProfileForm.get('parentUserWorkspaceId')"
            [fieldName]="'LEADER_CONTACT'"
            [options]="leaderUserList"
            [placeholder]="'ENTRY.COMMON.CARD.LABEL_LEADER_NAME' | translate"
    ></wr-select-with-search>
    <mat-error *ngIf="workspaceProfileForm.get('parentUserWorkspaceId').hasError('required')">
      {{ 'SETUP.WORKSPACE_PROFILE.LEADER_NAME_IS_REQUIRED' | translate }}
    </mat-error>
    <wr-select-with-search
            *wrHasPermission="[]"
            [control]="workspaceProfileForm.get('hrContactId')"
            [fieldName]="'HR_CONTACT'"
            [options]="hrLeaderUserList"
            [placeholder]="'ENTRY.COMMON.CARD.LABEL_HR_CONTACT' | translate"
    ></wr-select-with-search>
    <mat-error *ngIf="workspaceProfileForm.get('hrContactId').hasError('required')">
      {{ 'SETUP.WORKSPACE_PROFILE.HR_CONTACT_IS_REQUIRED' | translate }}
    </mat-error>
  <mat-form-field>
    <input
      wrPermissionReadOnly
      [requiredPermissions]="[]"
      autofocus
      [placeholder]="'ENTRY.COMMON.CARD.LABEL_JOB_TITLE' | translate"
      formControlName="jobTitle"
      matInput
      type="text"
    />
    <mat-error *ngIf="workspaceProfileForm.get('jobTitle').hasError('required')">
      {{ 'SETUP.WORKSPACE_PROFILE.JOB_TITLE_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <!-- <mat-form-field *ngIf="jobRoleList">
    <mat-label>{{ 'ENTRY.COMMON.CARD.LABEL_JOB_ROLE' | translate }}</mat-label>
    <mat-select wrFocus [wrFocus]="wrFocusElement" formControlName="jobRole">
      <input #wrFocusElement (keyup)="onKey($event.target.value)" placeholder="Search" />
      <mat-option *ngFor="let jobs of jobRoleList" [value]="jobs.name['no']">{{ jobs.name[selectedLanguage] }}</mat-option>
    </mat-select>
    <mat-error *ngIf="workspaceProfileForm.get('jobRole').hasError('required')">
      {{ 'SETUP.WORKSPACE_PROFILE.JOB_ROLE_IS_REQUIRED' | translate }}
    </mat-error>
  </mat-form-field> -->

  <div class="d-flex justify-content-end gap-3">
    <button mat-button matStepperPrevious *ngIf="backButton" (click)="back()">{{ 'BUTTON.BACK' | translate }}</button>
    <button
      wrPermissionReadOnly
      [requiredPermissions]="[]"
      wrButton variant="primary" [loading]="loading" [disabled]="workspaceProfileForm.invalid" (click)="save()">
      {{ btnName | translate }}
    </button>
  </div>
</form>
