import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOption } from '@app/shared/common.model';
import { AccessType } from '@app/modules/case-page/models/access.model';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SweetAlertService } from '@app/core/services/alert/sweet-alert.service';
import { CommonService } from '@app/shared/user/common.service';

@Component({
  selector: 'wr-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactUsFrom: UntypedFormGroup;
  categoryList = [
    { value: '1', label: 'HELP.IMPROVEMENTS' },
    { value: '2', label: 'HELP.TECHNICAL_PROBLEMS' },
    { value: '3', label: 'HELP.USER_HELP' },
    { value: '4', label: 'HELP.ACCOUNT_HELP' },
  ];
  loading = false;
  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private sweetAlertService: SweetAlertService,
    private readonly dialogRef: MatDialogRef<ContactUsComponent>,
  ) {}

  ngOnInit(): void {
    this.buildContactUsForm();
  }

  buildContactUsForm(): void {
    this.contactUsFrom = this.fb.group({
      category: ['', [Validators.required]],
      subject: ['', [Validators.required, Validators.minLength(5)]],
      message: ['', [Validators.required, Validators.minLength(5)]],
    });
  }
  trackBy(index: number, option: SelectOption<AccessType>): string {
    return option.value;
  }

  async submit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.contactUsFrom.value;
      data.type = 0;
      const res = await this.commonService.contactFormSubmit(data).toPromise();
      if (res) {
        this.sweetAlertService.showAlert({
          icon: 'success',
          html: 'CONTACT_US.SUCCESS.MESSAGE',
        });
        this.dialogRef.close();
      }
    } catch (err) {
      this.loading = false;
    }
    this.loading = false;
  }
}
