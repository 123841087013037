import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ITemplatePageType} from "@app/modules/builder/builder.component";

export enum EPageTypes {
  MEME = 'Meme',
  CHAT = 'Chat',
  QUESTION = 'Question',
  AGENDA = 'Agenda',
  REVIEW = 'Review',
  SUMMARY = 'Summary',
  COMMENT = 'Comment'
}

export enum EQuestionsType {
  SINGLE_QUESTION = 'single-question',
  CHECKLIST = 'checklist',
  MULTIPLE_QUESTION = 'multiple-question',
}

@Component({
  selector: 'wr-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss']
})
export class PageSelectorComponent {
  @Output()
  pageTypeSet = new EventEmitter<any>();
  selected = new FormControl(0);
  type: ITemplatePageType = {
    pageType: null,
    questionType: null
  }


  pageTypeList = [
    {name: EPageTypes.MEME, icon: 'sentiment_very_dissatisfied', iconClass: 'text-warning'},
    {name: EPageTypes.CHAT, icon: 'chat', iconClass: 'text-blue'},
    {name: EPageTypes.QUESTION, icon: 'contact_support', iconClass: 'text-danger'},
    {name: EPageTypes.AGENDA, icon: 'tv_options_edit_channels', iconClass: 'text-primary'},
    {name: EPageTypes.REVIEW, icon: 'stylus_note', iconClass: 'text-muted'},
    {name: EPageTypes.SUMMARY, icon: 'other_admission', iconClass: 'text-dark'},
    {name: EPageTypes.COMMENT, icon: 'mark_unread_chat_alt', iconClass: 'text-warning'}
  ]

  questionTypeList = [
    {name: 'Single Question', img: '../../../../assets/images/svg/single.svg', type: EQuestionsType.SINGLE_QUESTION},
    {name: 'Checklist', img: '../../../../assets/images/svg/checklist.svg', type: EQuestionsType.CHECKLIST},
    {name: 'Multiple Question', img: '../../../../assets/images/svg/Mult_question.svg', type: EQuestionsType.MULTIPLE_QUESTION}
  ]

  stepNext(pageType: EPageTypes) {
    this.type.pageType = pageType;
    this.selected.setValue(this.selected.value + 1)
  }

  stepPrevious() {
    this.selected.setValue(this.selected.value - 1)
  }

  submit(questionType: EQuestionsType) {
    this.type.questionType = questionType;
    if (this.type.pageType && this.type.questionType) {
      this.pageTypeSet.emit(this.type)
    }
  }

}
