<mat-form-field class="mat-date-custom" appearance="fill">
  <input
    id="timePicker"
    type="time"
    autocomplete="off"
    placeholder="Enter Time"
    matInput
    [(ngModel)]="selectedTime"
    [matMenuTriggerFor]="menu"
    (change)="onTimeChange()"
    step="900"
  />

  <span matPrefix class="d-inline-block">
    <mat-icon color="primary" class="mx-2">schedule</mat-icon>
  </span>
</mat-form-field>
<mat-menu #menu="matMenu">
  <div class="time-picker-container" (click)="$event.stopPropagation()">
    <div class="scrollable-div">
      <div class="scrollable-inner" #hoursScrollable>
        <div
          *ngFor="let hour of hours"
          [class.selected]="hour === selectedHour"
          (click)="onHourSelected(hour)"
        >
          {{ hour }}
        </div>
      </div>
    </div>
    <div class="scrollable-div">
      <div class="scrollable-inner" #minutesScrollable>
        <div
          *ngFor="let minute of minutes"
          [class.selected]="minute === selectedMinute"
          (click)="onMinuteSelected(minute)"
        >
          {{ minute }}
        </div>
      </div>
    </div>
<!--    <div class="am-pm-selector">-->
<!--      <div-->
<!--        [class.selected]="selectedPeriod === 'AM'"-->
<!--        (click)="onPeriodSelected('AM')"-->
<!--      >-->
<!--        AM-->
<!--      </div>-->
<!--      <div-->
<!--        [class.selected]="selectedPeriod === 'PM'"-->
<!--        (click)="onPeriodSelected('PM')"-->
<!--      >-->
<!--        PM-->
<!--      </div>-->
<!--    </div>-->
  </div>
</mat-menu>
