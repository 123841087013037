import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FollowUpRoutingModule } from './follow-up-routing.module';
import { TemplateListComponent } from './template-list/template-list.component';
import { FollowUpTemplateComponent } from './follow-up-template/follow-up-template.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

@NgModule({
  declarations: [TemplateListComponent, FollowUpTemplateComponent],
  exports: [FollowUpTemplateComponent, TemplateListComponent],
  imports: [
    CommonModule,
    FollowUpRoutingModule,
    MatIconModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    TranslateModule,
    SharedModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
  ],
})
export class FollowUpModule {}
