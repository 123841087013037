<wr-generic-dialog
  [employee]="data.caseUser"
  [documentTypeLabel]="documentTypeLabel | translate"
  [caseNumber]="data.caseNumber"
  [caseType]="data.caseType"
>
  <ng-container title>
    {{ dialogTitle | translate }}
  </ng-container>

  <ng-container content>
    <wr-document-summary
      [documentSummaryData]="summary"
      [caseDocumentData]="caseDocumentData"
      [documentTypeLabel]="documentTypeLabel | translate"
      [caseNumber]="data.caseNumber"
      [employee]="data.caseUser"
      [userId]="data.userId"
      [reportType]="data.type"
      [caseId]="data.caseId"
      [formGroup]="formGroup"
      [steps]="steps"
      class="mx-n3"
    >
    </wr-document-summary>
  </ng-container>

  <ng-container footer>
    <div class="footer__actions__container">
      <div class="footer__action__container">
        <button wrButton variant="outline" (click)="onClose()">Close</button>
      </div>
    </div>
  </ng-container>
</wr-generic-dialog>
