import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

import {
  AccessBodyData,
  AccessUpdateBodyData,
  // CaseChannel,
  CaseDocument,
  CaseIssueData,
  CaseIssueResponse,
  CaseTypes,
  DetailedCase,
  ICaseFilterOptionsResponse,
  ICheckIfCaseExistsResponse,
} from '@app/shared/cases.model';
import { ITaskRes } from '@app/shared/tasks.model';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
// import { ArrayResponse } from '@app/shared/common.model';
import {
  AccessUser,
  IAccessInviteReq,
} from '@app/modules/case-page/models/access.model';
// import { ConversationChannel, GroupedConversationChannel } from '@app/shared/conversations.model';
// import { GroupedChannel } from '@app/shared/channels.model';
// import { ConversationsService } from '../conversations/conversations.service';
// import { exhaustMap } from 'rxjs/operators';
import { IPaginationData } from '@app/shared/interfaces/ICompanyAccount';
import {
  ICaseListRes,
  ICasesFilterData,
} from '@app/modules/case-page/models/case-details.model';
import {
  FeedAccessTypes,
  IFeedComments,
  IFeedResponse,
} from '../../models/feeds.model';
import { DialogId } from '@app/shared/dialogs.model';
import { ApiList } from '@app/app.constants';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CasesService {
  constructor(
    private readonly http: HttpClient, // private conversationsService: ConversationsService
  ) {}

  getCases = (
    paginationData: IPaginationData,
    searchText: string = '',
    filter: ICasesFilterData,
  ): Promise<{ count: number; data: ICaseListRes[] }> => {
    return this.http
      .post<{ count: number; data: ICaseListRes[] }>(
        `${environment.baseUrl}${ApiList.cases._}${ApiList.cases._my}`,
        { filter },
        {
          params: {
            limit: paginationData.limit.toString(),
            skip: paginationData.skip.toString(),
            sort: paginationData.sort,
            searchString: searchText,
          },
        },
      )
      .toPromise();
  };

  getCaseFilterData = (): Promise<ICaseFilterOptionsResponse> => {
    return this.http
      .get<ICaseFilterOptionsResponse>(
        `${environment.baseUrl}${ApiList.cases._}${ApiList.cases._filterData}`,
      )
      .toPromise();
  };

  createCase(data: CaseIssueData): Observable<CaseIssueResponse> {
    return this.http.post<CaseIssueResponse>(
      `${environment.baseUrl}${ApiList.cases._}`,
      data,
    );
  }

  createCaseForAssessment(
    caseType: DialogId,
    data: CaseIssueData,
  ): Observable<CaseIssueResponse> {
    return this.http.post<CaseIssueResponse>(
      `${environment.baseUrl}${ApiList.cases._}${caseType}`,
      data,
    );
  }

  getCaseForAssessment(caseType: DialogId): Observable<CaseIssueResponse> {
    return this.http.get<CaseIssueResponse>(
      `${environment.baseUrl}${ApiList.cases._}${caseType}`,
    );
  }

  getCaseTasks(caseId: string): Observable<ITaskRes[]> {
    return this.http.get<ITaskRes[]>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._tasks}`,
    );
  }

  closeCase(caseId: string, document?: CaseDocument): Observable<ITaskRes[]> {
    return this.http.put<ITaskRes[]>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._closed}`,
      document,
    );
  }
  skipTask(caseId: string, docIdentifier?: string): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._docTemplate}${docIdentifier}/skip`,
      null,
    );
  }

  getCaseDocuments(caseId: string): Observable<CaseDocument[]> {
    return this.http.get<CaseDocument[]>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._documents}`,
    );
  }

  getCaseDocument(
    caseId: string,
    documentId: string,
  ): Observable<CaseDocument> {
    return this.http.get<CaseDocument>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._documents}${documentId}`,
    );
  }

  getCaseDocumentByType(
    caseId: string,
    documentType: string,
  ): Observable<CaseDocument> {
    return this.http.get<CaseDocument>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._documentType}${documentType}`,
    );
  }

  addDocumentToCase(caseId: string, document: CaseDocument): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._documents}`,
      document,
    );
  }

  closeCaseWithDocument(
    caseId: string,
    document: CaseDocument,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/closed`,
      document,
    );
  }

  getCase(id?: string): Observable<DetailedCase> {
    return this.http.get<DetailedCase>(
      `${environment.baseUrl}${ApiList.cases._}${id}#no-track`,
    );
  }

  getCaseFeed(
    id: string,
    skip: number,
    limit: number,
  ): Observable<IFeedResponse> {
    return this.http.get<IFeedResponse>(
      `${environment.baseUrl}${ApiList.feed._}${ApiList.feed._casesFeed}${id}`,
      {
        params: {
          limit: limit.toString(),
          skip: skip.toString(),
        },
      },
    );
  }
  getSingleCaseFeed(caseId: string, feedId: string): Observable<IFeedResponse> {
    return this.http.get<IFeedResponse>(
      `${environment.baseUrl}${ApiList.feed._}${ApiList.feed._casesFeed}${caseId}`,
      {
        params: {
          feedId,
        },
      },
    );
  }
  checkIfCaseExists(
    caseType: CaseTypes,
  ): Observable<ICheckIfCaseExistsResponse> {
    return this.http.get<ICheckIfCaseExistsResponse>(
      `${environment.baseUrl}${ApiList.cases._}${ApiList.cases._check}${caseType}`,
    );
  }

  postFeed(
    caseId: string,
    accessType: FeedAccessTypes,
    text: string,
    images: File[],
    files: File[],
    documents: string[],
  ): Observable<IFeedResponse> {
    const formData: FormData = new FormData();
    for (const image of images) {
      formData.append('images', image);
    }
    for (const file of files) {
      formData.append('files', file);
    }
    for (let i = 0; i < documents.length; i++) {
      formData.append(`documents[${i}]`, documents[i]);
    }

    formData.append('text', text);
    formData.append('accessType', accessType.toString());
    formData.append('caseId', caseId);

    return this.http.post<IFeedResponse>(
      `${environment.baseUrl}${ApiList.feed._}`,
      formData,
    );
  }

  postCaseFeedComment(feedId: string, text: string): Observable<IFeedResponse> {
    return this.http.post<IFeedResponse>(
      `${environment.baseUrl}${ApiList.feed._}${feedId}/${ApiList.feed._comment}`,
      { text },
    );
  }

  getCaseFeedComments(feedId: string): Observable<IFeedComments> {
    return this.http.get<IFeedComments>(
      `${environment.baseUrl}${ApiList.feed._}${feedId}/${ApiList.feed._comments}`,
    );
  }

  // getCaseAssignedChannels(caseId: string): Observable<ArrayResponse<CaseChannel>> {
  //   return this.http.get<ArrayResponse<CaseChannel>>(
  //     `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._channels}`,
  //   );
  // }

  // getCaseAssignedGroupedChannels(caseIds: string): Observable<GroupedConversationChannel[]> {
  //   return this.http
  //     .get<ArrayResponse<CaseChannel>>(
  //       `${environment.baseUrl}${ApiList.cases._}${caseIds}/${ApiList.cases._channels}`,
  //     )
  //     .pipe(
  //       exhaustMap(async (channels) => {
  //         const groupedChannels = [
  //           {
  //             caseId: caseIds,
  //             channels: [],
  //             name: 'Case Channels',
  //             owner: 'self',
  //           },
  //         ];
  //         groupedChannels[0].channels = channels.data;
  //         const promises = groupedChannels.map(async (groupedChannel) =>
  //           this.convertGroupedChannelToGroupedConversationChannel(groupedChannel),
  //         );
  //         return await Promise.all(promises);
  //       }),
  //     );
  // }

  // private async convertGroupedChannelToGroupedConversationChannel(
  //   groupedChannel: GroupedChannel,
  // ): Promise<GroupedConversationChannel> {
  //   const channels: ConversationChannel[] = [];
  //   const promises = groupedChannel.channels.map(async (channel) => {
  //     let conversation = await this.conversationsService.getConversationBySid(channel.conversationSid);

  //     if (!conversation) {
  //       return channel;
  //     }

  //     const conversationMessages = conversation.messages.map((message) => {
  //       const channelParticipant = channel.participants.find((participant) => {
  //         return participant.userWorkspaceId === message.author.userWorkspaceId;
  //       });
  //       const author = {
  //         userWorkspaceId: channelParticipant.userWorkspaceId,
  //         name: channelParticipant.name,
  //       };
  //       return { ...message, author };
  //     });

  //     conversation = { ...conversation, messages: conversationMessages };
  //     channels.push({ ...channel, ...conversation });
  //   });

  //   await Promise.all(promises);
  //   return { ...groupedChannel, channels };
  // }
  getCaseDocTemplate(
    caseId: string,
    docIdentifier: DocumentsIdentifiers,
  ): Observable<{ docTemplate: DocumentFormConfig; additionalData: any }> {
    return this.http
      .get<DocumentFormConfig>(`assets/data/flows/${docIdentifier}.json`)
      .pipe(
        mergeMap((template: DocumentFormConfig) => {
          return this.http
            .get<{
              docTemplate: DocumentFormConfig;
              additionalData: any;
            }>(
              `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._docTemplate}${docIdentifier}`,
            )
            .pipe(
              map(
                (data: {
                  docTemplate: DocumentFormConfig;
                  additionalData: any;
                }) => {
                  return {
                    docTemplate: template,
                    additionalData: data.additionalData,
                  };
                },
              ),
            );
        }),
      );
  }

  inviteUserToCase(
    caseId: string,
    accessData: AccessBodyData,
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._invitation}`,
      accessData,
    );
  }

  inviteUserToCaseWithAccess(
    caseId: string,
    accessData: IAccessInviteReq,
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._invitation}`,
      accessData,
    );
  }

  inviteUserToCaseWithAccessUpdate(
    caseId: string,
    caseAccessId: string,
    accessData: IAccessInviteReq,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._accesses}${caseAccessId}`,
      accessData,
    );
  }

  getCaseAccessUsers(caseId: string): Observable<AccessUser[]> {
    return this.http.get<AccessUser[]>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._accesses}`,
    );
  }

  getAccessDetails(
    caseId: string,
    caseAccessId: string,
  ): Observable<AccessUser> {
    return this.http.get<AccessUser>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._accesses}${caseAccessId}`,
    );
  }

  acceptUserAccess(caseId: string, caseAccessId: string): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._accesses}${caseAccessId}/${ApiList.cases._accepted}`,
      null,
    );
  }

  updateUserAccess(
    caseId: string,
    caseAccessId: string,
    updateData: AccessUpdateBodyData,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.baseUrl}${ApiList.cases._}${caseId}/${ApiList.cases._accesses}${caseAccessId}`,
      updateData,
    );
  }
}
