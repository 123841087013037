import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RouteSegments } from '@app/shared/routing.model';
import { AuthGuard } from '@app/shared/guards/authGuard/authguard.guard';
import { SetupFlowGuard } from '@app/shared/guards/setupFlowGuard/setup-flow.guard';
import { PermissionGuard } from './modules/shared/guards/permissionGuard/permission.guard';
import { PERMISSIONS } from './modules/shared/constants/permissions.contants';
import { SetupStepsGuard } from './modules/shared/guards/setupStepsGuard/setup-steps.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RouteSegments.ENTRY,
  },
  {
    path: RouteSegments.SYS_ADMIN,
    canActivate: [AuthGuard, SetupStepsGuard, PermissionGuard],
    loadChildren: () =>
      import('./modules/sys-admin/sys-admin.module').then(
        (m) => m.SysAdminModule,
      ),
    data: {
      permissions: [PERMISSIONS.ADMINISTRATOR.DASHBOARD],
    },
  },
  {
    path: RouteSegments.MY_WORKABILITY,
    canActivate: [AuthGuard, SetupStepsGuard, PermissionGuard],
    loadChildren: () =>
      import('./modules/my-workability/my-workability.module').then(
        (m) => m.MyWorkabilityModule,
      ),
    data: { permissions: [PERMISSIONS.MY_WORKABILITY] },
  },
  {
    path: RouteSegments.ENTRY,
    loadChildren: () =>
      import('./modules/entry/entry.module').then(
        (module) => module.EntryModule,
      ),
  },
  {
    path: RouteSegments.SETUP,
    canActivate: [AuthGuard, SetupFlowGuard],
    loadChildren: () =>
      import('./modules/setup/setup.module').then(
        (module) => module.SetupModule,
      ),
  },
  {
    path: RouteSegments.COMPANY_PROFILE,
    canActivate: [AuthGuard, SetupFlowGuard, PermissionGuard],
    loadChildren: () =>
      import('./modules/company-profile/company-profile.module').then(
        (module) => module.CompanyProfileModule,
      ),
    data: { permissions: [PERMISSIONS.ACCOUNT.VIEW] },
  },
  {
    path: RouteSegments.LANDING_PAGE,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/chat/chat.module').then((module) => module.ChatModule),
  },
  {
    path: RouteSegments.CHAT,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/chat/chat.module').then((module) => module.ChatModule),
  },
  {
    path: RouteSegments.CASES,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/case-page/case-page.module').then(
        (module) => module.CasePageModule,
      ),
  },
  {
    path: RouteSegments.FOLLOW_UP,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/follow-up/follow-up.module').then(
        (module) => module.FollowUpModule,
      ),
  },
  {
    path: RouteSegments.FORMS,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/forms/forms.module').then(
        (module) => module.FormsModule,
      ),
  },
  {
    path: RouteSegments.TASKS,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/tasks/tasks.module').then(
        (module) => module.TasksModule,
      ),
  },
  {
    path: RouteSegments.TEAMS,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/teams/teams.module').then(
        (module) => module.TeamsModule,
      ),
  },
  {
    path: RouteSegments.MESSAGE_CENTER,
    canActivate: [AuthGuard, SetupStepsGuard],
    loadChildren: () =>
      import('./modules/message-center-page/message-center-page.module').then(
        (module) => module.MessageCenterPageModule,
      ),
  },
  {
    path: RouteSegments.PROFILE_PAGE,
    loadChildren: () =>
      import('./modules/profile-page/profile-page.module').then(
        (module) => module.ProfilePageModule,
      ),
  },
  {
    path: RouteSegments.CONTENT_CREATOR,
    loadChildren: () =>
      import('./modules/content-creator/content-creator.module').then(
        (module) => module.ContentCreatorModule,
      ),
  },
  {
    path: RouteSegments.WELLNESS_CENTER,
    loadChildren: () =>
      import('./modules/wellness-center/wellness-center.module').then(
        (module) => module.WellnessCenterModule,
      ),
  },
  {
    path: RouteSegments.CONSENT_APPROVAL,
    loadChildren: () =>
      import(
        './modules/entry/components/consent-approval/consent-approval.module'
      ).then((module) => module.ConsentApprovalModule),
  },
  {
    path: RouteSegments.LEADERSHIP_PORTAL,
    loadChildren: () =>
      import('./modules/my-leadership/my-leadership.module').then(
        (module) => module.MyLeadershipModule,
      ),
  },
  {
    path: RouteSegments.NOTIFICATIONS,
    loadChildren: () =>
      import('./modules/notifications/notifications.module').then(
        (module) => module.NotificationsModule,
      ),
  },
  {
    path: RouteSegments.CONTACT_US,
    loadChildren: () =>
      import('./modules/contact-us/contact-us.module').then(
        (module) => module.ContactUsModule,
      ),
  },
  {
    path: RouteSegments.FEEDBACK,
    loadChildren: () =>
      import('./modules/feedback/feedback.module').then(
        (module) => module.FeedbackModule,
      ),
  },
  {
    path: RouteSegments.EVENT_CALENDAR,
    loadChildren: () =>
      import('./modules/event-calendar/event-calendar.module').then(
        (module) => module.EventCalendarModule,
      ),
  },
  {
    path: RouteSegments.FEEDBACK_LIST,
    loadChildren: () =>
      import(`./modules/feedback-ticket/feedback-ticket.module`).then(
        (module) => module.FeedbackTicketModule,
      ),
  },

  {
    path: RouteSegments.EVENT_CALENDAR,
    loadChildren: () =>
      import('./modules/event-calendar/event-calendar.module').then(
        (module) => module.EventCalendarModule,
      ),
  },
  {
    path: RouteSegments.TEMPLATES,
    loadChildren: () =>
      import('./modules/templates/templates.module').then(
        (module) => module.TemplatesModule,
      ),
  },
  {
    path: RouteSegments.BUILDER,
    loadChildren: () =>
      import('./modules/builder/builder.module').then(
        (module) => module.BuilderModule,
      ),
  },
  {
    path: RouteSegments.FORBIDDEN,
    loadChildren: () =>
      import('./modules/forbidden/forbidden.module').then(
        (module) => module.ForbiddenModule,
      ),
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: RouteSegments.ENTRY,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
