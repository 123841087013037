import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsHelperService {
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

  registerSvgIcons(iconName: string | string[]): void {
    if (Array.isArray(iconName)) {
      iconName.forEach((name) => {
        this.registerSingleIcon(name);
      });
    } else {
      this.registerSingleIcon(iconName);
    }
  }

  private registerSingleIcon(iconName: string): void {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/svg/${iconName}.svg`),
    );
  }
}
