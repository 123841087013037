import {
  DocumentsIdentifiers,
  ReportIssueChoices,
} from '@app/shared/documents.model';
import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';

export const DOCUMENTS_TYPE_LABELS = {
  [ReportIssueChoices.WORKABILITY_UPDATE]:
    'COMMON.DOCUMENTS.WORKABILITY_UPDATE.NAME',
  [ReportIssueChoices.PROBLEM_WITH_EQUIPMENT]:
    'COMMON.DOCUMENTS.PROBLEM_WITH_EQUIPMENT.NAME',
  [ReportIssueChoices.OTHER_ISSUES]: 'COMMON.DOCUMENTS.OTHER_ISSUES.NAME',
  [DocumentsIdentifiers.FACILITATION]: 'COMMON.DOCUMENTS.FACILITATION.NAME',
  [DocumentsIdentifiers.WORKABILITY]: 'COMMON.DOCUMENTS.WORKABILITY.NAME',
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING]:
    'COMMON.DOCUMENTS.FOLLOW_UP_DOCUMENT_MEETING.NAME',
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING_REVIEW]:
    'COMMON.DOCUMENTS.FOLLOW_UP_DOCUMENT_MEETING_REVIEW.NAME',
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_REVIEW]:
    'COMMON.DOCUMENTS.FOLLOW_UP_DOCUMENT_REVIEW.NAME',

  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE]:
    'COMMON.DOCUMENTS.FOLLOW_UP_DOCUMENT_UPDATE.NAME',
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS]:
    'COMMON.DOCUMENTS.FOLLOW_UP_DOCUMENT_QUESTIONS.NAME',
  [DocumentsIdentifiers.FOLLOW_UP_PLAN]: 'COMMON.DOCUMENTS.FOLLOW_UP_PLAN.NAME',
  [DocumentsIdentifiers.SICK_LEAVE]: 'COMMON.DOCUMENTS.SICK_LEAVE.NAME',
  [DocumentsIdentifiers.SELF_CERTIFICATION]:
    'COMMON.DOCUMENTS.SELF_CERTIFICATION.NAME',
  [DocumentsIdentifiers.FML]: 'COMMON.DOCUMENTS.FML.NAME',
  [DocumentsIdentifiers.TEMPORARY_CHANGE_REQUEST]:
    'COMMON.DOCUMENTS.TEMPORARY_CHANGE_REQUEST.NAME',
  [DocumentsIdentifiers.STANDARD_FACILITATION]:
    'COMMON.DOCUMENTS.STANDARD_FACILITATION.NAME',
  [DocumentsIdentifiers.BASIC_FOLLOW_UP]:
    'COMMON.DOCUMENTS.BASIC_FOLLOW_UP.NAME',
  [DocumentsIdentifiers.BASIC_FOLLOW_UP_CALL]:
    'COMMON.DOCUMENTS.BASIC_FOLLOW_UP_CALL.NAME',
  [DocumentsIdentifiers.BASIC_FOLLOW_UP_MESSAGE]:
    'COMMON.DOCUMENTS.BASIC_FOLLOW_UP_MESSAGE.NAME',
  [DocumentsIdentifiers.SELF_ASSESSMENT]:
    'COMMON.DOCUMENTS.SELF_ASSESSMENT.NAME',
  [DocumentsIdentifiers.RESIDUAL_WORK_CAPACITY]:
    'COMMON.DOCUMENTS.RESIDUAL_WORK_CAPACITY',
  [DocumentsIdentifiers.WORKPLACE_ACCOMMODATION]:
    'COMMON.DOCUMENTS.WORKPLACE_ACCOMMODATION',
  [DocumentsIdentifiers.FML_NEW_REPORT]: 'COMMON.DOCUMENTS.FML_NEW_REPORT',
  [DocumentsIdentifiers.FML_AGREEMENT]: 'COMMON.DOCUMENTS.FML_AGREEMENT',
};

export const AGREEMENT_TYPE_LABELS = {
  [DocumentTemplateUniqueIdentifier.FACILITATION]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.FACILITATION_AGREEMENT.TITLE',
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.FACILITATION_AGREEMENT.TITLE',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.FOLLOW_UP_PLAN_AGREEMENT.TITLE',
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.SELF_CERTIFICATION_AGREEMENT.TITLE',
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.ISSUE_NEW_REPORT_AGREEMENT.TITLE',
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.EQUIPMENT_NEW_REPORT_AGREEMENT.TITLE',
};

export const AGREEMENT_TITLES = {
  [DocumentTemplateUniqueIdentifier.FACILITATION]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.AGREEMENT_TYPE.FACILITATION_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.AGREEMENT_TYPE.FACILITATION_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.AGREEMENT_TYPE.FOLLOW_UP_PLAN_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    'DOCUMENTS.DOCUMENT_AGREEMENT.AGREEMENT_TYPE.FOLLOW_UP_PLAN_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]:
    'SUMMARY.TITLE.SELF_CERTIFICATION',
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]:
    'SUMMARY.TITLE.ISSUE_NEW_REPORT_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]:
    'SUMMARY.TITLE.EQUIPMENT_NEW_REPORT_AGREEMENT',
};

export const AGREEMENT_DOCUMENT_TEMPLATE = {
  [DocumentTemplateUniqueIdentifier.FACILITATION]:
    DocumentsIdentifiers.FACILITATION,
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    DocumentsIdentifiers.FACILITATION,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]:
    DocumentsIdentifiers.FOLLOW_UP_PLAN,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    DocumentsIdentifiers.FOLLOW_UP_PLAN,
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST]:
    DocumentsIdentifiers.TEMPORARY_CHANGE_REQUEST,
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_AGREEMENT]:
    DocumentsIdentifiers.TEMPORARY_CHANGE_REQUEST,
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]:
    DocumentsIdentifiers.SELF_CERTIFICATION,
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]:
    DocumentsIdentifiers.ISSUE,
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]:
    DocumentsIdentifiers.EQUIPMENT,
  [DocumentTemplateUniqueIdentifier.EQUIPMENT]: DocumentsIdentifiers.EQUIPMENT,
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION]:
    DocumentsIdentifiers.SELF_CERTIFICATION,
  [DocumentTemplateUniqueIdentifier.ISSUE]: DocumentsIdentifiers.ISSUE,
  [DocumentTemplateUniqueIdentifier.FML]: DocumentsIdentifiers.FML,
  [DocumentTemplateUniqueIdentifier.FML_AGREEMENT]: DocumentsIdentifiers.FML,
};

export const SHARED_LABELS = {
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    DocumentsIdentifiers.FACILITATION,
};
