<wr-generic-dialog
  [caseType]="data.caseType"
  [documentTypeLabel]=""
  [employee]="data.caseUser"
  [showBreadcrumb]="true"
  [showCloseIcon]="true"
  [showHeaderTitle]="true"
>
  <!-- <ng-container title>
        {{'Follow up'}}
    </ng-container>
    <ng-container subTitle>
        {{'Meeting'}}
    </ng-container> -->
  <ng-container content>
    <mat-card class="p-4 mb-4">
      <mat-card-content>
        <div class="d-flex flex-column ">
          <div class="mb-3" style="min-width: 650px">
            <p class="mb-3 text-center">
              {{ 'CHANGES_TO_TASK.DO_YOU_WANT_TO_MAKE_CHANGE.TEXT' | translate }}
              <span class="fw-bold">{{ 'COMMON.DOCUMENTS.FACILITATION.NAME' | translate }}</span>
            </p>
            <div class="text-center">
              <button
                (click)="makeChangeFacilitation = false"
                [class]="makeChangeFacilitation ? 'mat-button-secondary-fill' : 'mat-button-active'"
                class="me-2 mat-button-switch"
                mat-flat-button
              >
                {{ 'BUTTON.NO' | translate }}
              </button>
              <button
                (click)="makeChangeFacilitation = true"
                [class]="makeChangeFacilitation ? 'mat-button-active' : ' mat-button-secondary-fill'"
                class="me-2 mat-button-switch"
                mat-flat-button
              >
                {{ 'BUTTON.YES' | translate }}
              </button>
            </div>
          </div>
          <div class="mb-3" style="min-width: 650px">
            <p class="mb-3 text-center">
              {{ 'CHANGES_TO_TASK.DO_YOU_WANT_TO_MAKE_CHANGE.TEXT' | translate }}
              <span class="fw-bold">{{ 'COMMON.DOCUMENTS.FOLLOW_UP_PLAN.NAME' | translate }}</span>
            </p>
            <div class="text-center">
              <button
                (click)="makeChangeFollowup = false"
                [class]="makeChangeFollowup ? 'mat-button-secondary-fill' : 'mat-button-active'"
                class="me-2 mat-button-switch"
                mat-flat-button
              >
                {{ 'BUTTON.NO' | translate }}
              </button>
              <button
                (click)="makeChangeFollowup = true"
                [class]="makeChangeFollowup ? 'mat-button-active' : ' mat-button-secondary-fill'"
                class="me-2 mat-button-switch"
                mat-flat-button
              >
                {{ 'BUTTON.YES' | translate }}
              </button>
            </div>
          </div>

          <div *ngIf="makeChangeFacilitation || makeChangeFollowup" class="m-auto" style="min-width: 650px">
            <p class="mb-3 text-center">
              {{ 'CHANGES_TO_TASK.WHEN_TO_MAKE_CHANGES.TEXT' | translate }}
            </p>
            <div class="text-center">
              <button
                (click)="makeChangeImmediate = true"
                [class]="makeChangeImmediate ? 'mat-button-active' : ' mat-button-secondary-fill'"
                class="me-2 mat-button-switch"
                mat-flat-button
              >
                {{ 'BUTTON.IMMEDIATELY' | translate }}
              </button>
              <button
                (click)="makeChangeImmediate = false"
                [class]="makeChangeImmediate ? 'mat-button-secondary-fill' : ' mat-button-active'"
                class="me-2 mat-button-switch"
                mat-flat-button
              >
                {{ 'BUTTON.LATER_ON' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <ng-container footer>
    <div class="mt-0">
      <button (click)="submit()" wrButton>
        {{ 'BUTTON.SUBMIT' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
