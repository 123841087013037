import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '@app/shared/common.model';
import { SecurityService } from '@app/core/services/security/security.service';
import { localStorageKeys } from '@app/core/services/security/security.service.constants';
import * as moment from 'moment';
import * as dayjs from 'dayjs';

import 'moment/locale/nb';
import 'dayjs/locale/nb';
@Injectable({
  providedIn: 'root',
})
export class GlobalLanguageService {
  selectedLanguage: Languages;

  constructor(
    private readonly translateService: TranslateService,
    private securityService: SecurityService,
  ) {}

  setLanguage(language: string): void {
    this.selectedLanguage = language as Languages;
    this.securityService.setLocalStorageItem(
      localStorageKeys.LANGUAGE,
      this.selectedLanguage,
    );
    this.translateService.use(language);
    moment.locale(language);
    dayjs.locale(language);
  }

  checkAndSetLanguage(): void {
    const prevLang = this.securityService.getLocalStorageItem(
      localStorageKeys.LANGUAGE,
    );
    if (prevLang) {
      this.setLanguage(prevLang);
    } else
      this.selectedLanguage = this.translateService.currentLang as Languages;
  }

  getSelectedLanguage(): Languages {
    return this.translateService.currentLang as Languages;
  }
}
