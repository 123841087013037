import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DocumentFormConfig } from '@app/modules/forms/models/document-templates.model';
import { StandardFacilitationOption } from '@app/shared/components/documents/reports/standard-facilitation/standard-facilitation.model';
import { UntypedFormControl } from '@angular/forms';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { DocumentTemplatesService } from '@app/modules/forms/services/document-templates.service';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';

@Component({
  selector: 'wr-standard-facilitation',
  templateUrl: './standard-facilitation.component.html',
  styleUrls: ['./standard-facilitation.component.scss'],
})
export class StandardFacilitationComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  standardFacilitationOption: typeof StandardFacilitationOption = StandardFacilitationOption;
  selectedOption = new UntypedFormControl(StandardFacilitationOption.NEW);
  docVersion: number;
  selectedStep = 0;
  getDocument$: Observable<DocumentFormConfig>;
  documentTypeLabel = DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.STANDARD_FACILITATION];

  constructor(
    private readonly documentTemplatesService: DocumentTemplatesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseNumber: string;
      caseUser: string;
    },
  ) {}

  submit(fields: { [key: string]: string | string[] }): void {}

  ngOnInit(): void {
    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(DocumentsIdentifiers.STANDARD_FACILITATION);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
