import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY
} from "@angular/material/datepicker";
import {BehaviorSubject, Subject} from "rxjs";
import {
  CalendarCustomHeaderComponent
} from "@app/shared/components/documents/form-controls/calendar-custom-header/calendar-custom-header.component";
import {DatetimeService} from "@app/shared/datetime/datetime.service";
import {IconsHelperService} from "@app/core/services/icons-helper/icons-helper.service";
import {SIMPLE_DATE_FORMAT} from "@app/constants/datetime.constants";

@Component({
  selector: 'wr-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
  @Input() type: 'calendar' | 'dropdown' = "dropdown";
  @Input() description: string;
  @Input() max: {
    value: number;
    errorMessage: string;
  };
  @Input() minDate = new Date();
  @Input() maxDate: string;
  selectedDateRange: DateRange<Date>;
  filterData = {
    fromDate: '',
    toDate: ''
  }
  private readonly onDestroy$ = new Subject<null>();

  daysSelected$ = new BehaviorSubject<number>(-1);
  calendarCustomHeader = CalendarCustomHeaderComponent;


  constructor(
    private readonly datetimeService: DatetimeService,
    private readonly iconsHelperService: IconsHelperService,
  ) {
    this.iconsHelperService.registerSvgIcons('calendar');
  }

  ngOnInit(): void {
    this.formatSimpleDatesToISOStrings({from: 'asd111'});
    this.formatISOStringsToSimpleDates({from: 'asd'});
  }

  onSelectedChange(date: Date): void {
    this.dateRangeSelection(date);
  }
  dateRangeSelection = (date: Date) => {
    const selectedDate = new Date(date);
    if (!this.selectedDateRange) {
      this.selectedDateRange = new DateRange(date, date);
    }
    if (selectedDate.getTime() < this.selectedDateRange.start.getTime()) {
      this.selectedDateRange = new DateRange(
        date,
        this.selectedDateRange.start,
      );
    }

    if (selectedDate.getTime() > this.selectedDateRange.end.getTime()) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date,
      );
    }

    if (selectedDate.getTime() < this.selectedDateRange.end.getTime()) {
      this.selectedDateRange = new DateRange(date, this.selectedDateRange.end);
    }
  };
  getSelectedDaysCount(startDate: Date, endDate: Date): number {
    if (!startDate || !endDate) return 0;
    if (startDate === endDate) {
      return 1;
    }
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate(),
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
  }

  isValidRange(): boolean {
    if (!this.max || !this.selectedDateRange) return true;

    return (
      this.getSelectedDaysCount(
        this.selectedDateRange.start,
        this.selectedDateRange.end,
      ) <= this.max.value
    );
  }


  // private setRangeValidator(): void {
  //   if (this.max) {
  //     this.groupCopy.setValidators(
  //       this.formValidatorsService.datesRangeValidator(
  //         this.fromControlName,
  //         this.toControlName,
  //         this.max.value,
  //         'day',
  //       ),
  //     );
  //   }
  // }



  // private watchValuesChangesAndFormatDates(): void {
  //   this.groupCopy.valueChanges
  //     .pipe(
  //       filter(
  //         () =>
  //           this.groupCopy.valid ||
  //           isEqual(this.groupCopy.errors, { invalidRange: true }),
  //       ),
  //       distinctUntilChanged((previous, current) =>
  //         isEqual(
  //           this.formatSimpleDatesToISOStrings(previous),
  //           this.formatSimpleDatesToISOStrings(current),
  //         ),
  //       ),
  //       takeUntil(this.onDestroy$),
  //     )
  //     .subscribe((value) => {
  //       const valueToPatchWith = this.groupCopy.getRawValue();
  //       const newGroupValue =
  //         this.formatISOStringsToSimpleDates(valueToPatchWith);
  //       if (Object.values(value).every((v) => v)) {
  //         if (!isEqual(newGroupValue, this.group.getRawValue())) {
  //           this.group.patchValue(newGroupValue);
  //         }
  //       } else {
  //         const newGroupCopyValue =
  //           this.formatSimpleDatesToISOStrings(valueToPatchWith);
  //         this.groupCopy.patchValue(newGroupCopyValue);
  //         this.group.patchValue(newGroupValue);
  //       }
  //       this.setDaysSelected();
  //     });
  // }

  // private setDaysSelected(): void {
  //   if (Object.values(this.group.getRawValue()).every((value) => value)) {
  //     const [firstDate, secondDate] = Object.values(
  //       this.group.getRawValue() as { [key: string]: string },
  //     ).map((date) => {
  //       this.onSelectedChange(
  //         this.datetimeService.formatSimpleDateToDayjs(date).toDate(),
  //       );
  //       return this.datetimeService.formatSimpleDateToDayjs(date);
  //     });
  //
  //     this.daysSelected$.next(
  //       Math.abs(
  //         this.datetimeService
  //           .date(firstDate)
  //           .diff(this.datetimeService.date(secondDate), 'day'),
  //       ) + 1,
  //     );
  //   } else {
  //     this.daysSelected$.next(0);
  //   }
  // }

  private formatSimpleDatesToISOStrings(simpleDates: {
    [key: string]: string;
  }): { [key: string]: string } {
    const valueToReturn = Object.assign({}, simpleDates);
    Object.entries(valueToReturn).forEach(([key, value]: [string, string]) => {
      if (this.datetimeService.checkIsDateFormatSimple(value)) {
        valueToReturn[key] = value
          ? this.datetimeService.formatSimpleDateToDayjs(value).toISOString()
          : null;
      }
    });
    return valueToReturn;
  }

  private formatISOStringsToSimpleDates(ISOString: { [key: string]: string }): {
    [key: string]: string;
  } {
    const valueToReturn = Object.assign({}, ISOString);
    Object.entries(valueToReturn).forEach(([key, value]: [string, string]) => {
      if (!this.datetimeService.checkIsDateFormatSimple(value)) {
        valueToReturn[key] = value
          ? this.datetimeService.date(value).format(SIMPLE_DATE_FORMAT)
          : null;
      }
    });
    return valueToReturn;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  clearPicker(): void {
    this.filterData.toDate = '';
    this.filterData.fromDate = '';
  }

  test() {
    if(this.filterData.toDate && this.filterData.fromDate)
    this.selectedDateRange = new DateRange(new Date(this.filterData.fromDate), new Date(this.filterData.toDate));
  }
}
