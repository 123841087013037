import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { TranslateService } from '@ngx-translate/core';

import { Languages, SelectOption } from '@app/shared/common.model';
import { SelectWithSearchComponent } from '@app/shared/custom-components/select-with-search/select-with-search.component';

@Component({
  selector: 'wr-textbox-with-chips',
  templateUrl: './textbox-with-chips.component.html',
  styleUrls: ['./textbox-with-chips.component.scss'],
})
export class TextboxWithChipsComponent implements OnInit {
  @Input() description: string;
  @Input() inputLabel: string;
  @Input() color: string;
  @Input() formArray: UntypedFormArray;
  @Input() availableOptions: SelectOption<string>[];
  @Input() autofocus: boolean;

  @ViewChild(SelectWithSearchComponent, { static: false }) selectWithSearch: SelectWithSearchComponent;

  private selectedLanguage: Languages;

  chipsValues: string[] = [];
  chipsLabels: { [key: string]: string } = {};
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private readonly fb: UntypedFormBuilder, private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang as Languages;
    this.prepareChipsLabel();
    this.chipsValues = this.formArray.getRawValue().filter((value) => value);
    this.disableAlreadySelectedOptions();
  }

  private disableAlreadySelectedOptions(): void {
    if (this.availableOptions) {
      this.chipsValues.forEach((value) => {
        this.setOptionDisabledStatus(value, true);
      });
    }
  }

  private setOptionDisabledStatus(value: string, disabled: boolean): void {
    (this.availableOptions.find((option) => option.value === value) || ({} as SelectOption<string>)).disabled =
      disabled;
  }

  private prepareChipsLabel(): void {
    this.availableOptions?.forEach((option) => {
      this.chipsLabels[option.value] = option.label;
      if (typeof this.chipsLabels[option.value] === 'object') {
        this.chipsLabels[option.value] = this.chipsLabels[option.value][this.selectedLanguage];
      }
    });
  }

  private updateFormArray(value: string): void {
    this.chipsValues.push(value);
    if (this.chipsValues.length > 1) {
      this.formArray.push(this.fb.control(value));
    } else {
      this.formArray.patchValue(this.chipsValues);
    }
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;
    const trimmedValue = (value || '').trim();
    if (trimmedValue && !this.chipsValues.some((chipValue) => chipValue === trimmedValue)) {
      this.updateFormArray(trimmedValue);
    }
    if (input) {
      input.value = '';
    }
  }

  remove(chipValue: string): void {
    const index = this.chipsValues.indexOf(chipValue);
    if (index >= 0) {
      this.chipsValues.splice(index, 1);
      this.formArray.removeAt(index);
    }
    this.formArray.patchValue(this.chipsValues);
    if (this.availableOptions) {
      this.setOptionDisabledStatus(chipValue, false);
    }
    if (this.chipsValues.length === 0) {
      this.formArray.push(this.fb.control(''));
    }
  }

  handleSelectionChange(value: string): void {
    const trimmedValue = value.trim();
    this.updateFormArray(trimmedValue);
    this.setOptionDisabledStatus(value, true);
    this.selectWithSearch.resetValue();
  }

  trackBy(index: number, value: string): string {
    return value;
  }
}
