import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  INotificationResponse,
  NotificationEventName,
  NotificationTypeEnum,
  StatusEnum,
} from '@app/modules/shared/models/notifications.model';
import { RouteSegments } from '@app/modules/shared/models/routing.model';
import { TaskColor } from '@app/modules/shared/models/tasks.model';
import { User } from '@app/modules/shared/models/user.model';
import { DatetimeService } from '@app/modules/shared/services/datetime/datetime.service';
import { NotificationsService } from '@app/modules/shared/services/notifications/notifications.service';
import { CommonService } from '@app/modules/shared/services/user/common.service';
import { Dayjs } from 'dayjs';
import * as moment from 'moment';

@Component({
  selector: 'wr-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  _notificationVal: INotificationResponse[] = [];
  @Input() currentUser: User;
  @Input() set notifications(data) {
    this._notificationVal = data;
  }
  get notifications(): any {
    return this._notificationVal;
  }
  icons = {
    SICK_LEAVE: 'sick',
    TASK: 'description',
    STATUS_UPDATE: 'description',
    NEW_FEED: 'post_add',
    NEW_COMMENT: 'mode_comment',
    ANNOUNCEMENT: 'brand_awareness',
    REMINDER: 'acute',
    SELF_CERTIFICATION: 'sick',
    FML: 'sick',
    TEMPORARY_CHANGE_REQUEST: 'description',
    ISSUE: 'description',
  };

  readonly NotificationTypeEnum = NotificationTypeEnum;
  readonly NotificationEventName = NotificationEventName;
  constructor(
    private readonly router: Router,
    private readonly datetimeService: DatetimeService,
    private readonly notificationService: NotificationsService,
    private readonly commonService: CommonService,
  ) {}
  async ngOnInit(): Promise<void> {}

  getCreatedDisplayDate(date: string): string {
    return moment(date).fromNow(true); // eg. 1 day ago, 2 hours ago etc
  }

  getDateDifference(data: any): string {
    return this.commonService.getDateDifference(data);
  }

  navigateToCase(notification: INotificationResponse): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let elementIdToFocus = '';
    if (
      notification.notificationType === NotificationTypeEnum.NEW_COMMENT ||
      notification.notificationType === NotificationTypeEnum.NEW_FEED
    ) {
      elementIdToFocus = notification.content.feed.feedId;
    } else if (
      notification.content?.nextTask &&
      notification.content?.nextTask?.type
    ) {
      elementIdToFocus = notification.content.nextTask.type;
    } else if (
      notification.notificationType === NotificationTypeEnum.REMINDER &&
      (notification.eventName === NotificationEventName.TASKS_EXPIRED ||
        notification.eventName === NotificationEventName.TASKS_DUE_TODAY) &&
      notification.content?.nextTask?.currentStatus
    ) {
      elementIdToFocus = notification.content.nextTask.currentStatus;
    }
    this.router.navigate([`${RouteSegments.CASES}/${notification.caseId}`], {
      queryParams: {
        focusItem: notification.notificationType,
        focusId: elementIdToFocus,
      },
    });
    if (notification.status === StatusEnum.UNREAD) {
      this.notificationService.setReadStatus(notification._id);
      this._notificationVal.find((x) => x._id === notification._id).status =
        StatusEnum.READ;
    }
  }

  getTaskColor(deadline): string {
    const currentDate: Dayjs = this.datetimeService.date();
    const taskDueDate: Dayjs = this.datetimeService.date(deadline);
    const daysDiff = taskDueDate.diff(currentDate, 'day');
    if (daysDiff < 0) {
      return TaskColor.RED;
    } else if (daysDiff === 0) {
      return TaskColor.ORANGE;
    } else if (daysDiff <= 7) {
      return TaskColor.GREEN;
    } else {
      return TaskColor.GRAY;
    }
  }
}
