import { DocumentTemplateUniqueIdentifier } from '@app/modules/case-page/models/case-statuses.model';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { FacilitationComponent } from '@app/shared/components/documents/reports/facilitation/facilitation.component';
import { FollowUpDocumentComponent } from '@app/shared/components/documents/reports/follow-up-document/follow-up-document.component';
import { ReportIssueComponent } from '@app/shared/components/documents/reports/report-issue/report-issue.component';
import { DocumentAgreementComponent } from '@app/shared/components/documents/reports/document-agreement/document-agreement.component';
import { FollowUpPlanComponent } from '@app/shared/components/documents/reports/follow-up-plan/follow-up-plan.component';
import { DocumentsIdentifiers } from '@app/shared/documents.model';
import { DOCUMENTS_TYPE_LABELS } from '@app/shared/constants/documents.constants';
import { FollowUpScheduledEventComponent } from '@app/shared/components/documents/reports/follow-up-scheduled-event/follow-up-scheduled-event.component';
import { FollowUpSchedulerComponent } from '@app/shared/components/documents/reports/follow-up-scheduler/follow-up-scheduler.component';
import { CloseTaskComponent } from '@app/shared/components/documents/reports/close-task/close-task.component';
import { DialogueMeetingSummaryComponent } from '@app/shared/components/documents/reports/dialogue-meeting-summary/dialogue-meeting-summary.component';
import { FillModeComponent } from '@app/modules/content-creator/fill-mode/fill-mode.component';
import { ResidualWorkCapacityComponent } from '@app/shared/components/documents/reports/residual-work-capacity/residual-work-capacity.component';
import { WorkplaceAccommodationComponent } from '@app/modules/shared/components/documents/reports/workplace-accommodations/workplace-accommodations.component';

export const TASK_DESCRIPTION = {
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_ISSUE_NEW_REPORT',
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_EQUIPMENT_NEW_REPORT',
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_NEW_REPORT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_TEMPORARY_CHANGE_REQUEST_NEW_REPORT',
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_NEW_REPORT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_SELF_CERTIFICATION_NEW_REPORT',
  [DocumentTemplateUniqueIdentifier.SICK_LEAVE_NEW_REPORT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_SICK_LEAVE_NEW_REPORT',
  [DocumentTemplateUniqueIdentifier.WORKABILITY_NEW_REPORT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_WORKABILITY_NEW_REPORT',

  [DocumentTemplateUniqueIdentifier.WORKABILITY_UPDATE]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_WORKABILITY_UPDATE',
  [DocumentTemplateUniqueIdentifier.FACILITATION]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FACILITATION',
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FACILITATION_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_1]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_DIALOGUE_MEETING_1',
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_2]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_DIALOGUE_MEETING_2',
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_3]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_DIALOGUE_MEETING_3',
  [DocumentTemplateUniqueIdentifier.NAV_FILLING]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_NAV_FILLING',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FOLLOW_UP_PLAN_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FOLLOW_UP_PLAN',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FOLLOW_UP_DOCUMENT',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_REVIEW]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FOLLOW_UP_DOCUMENT_REVIEW',
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_FOLLOW_UP_DOCUMENT_MEETING_REVIEW',
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_AGREEMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_TEMPORARY_CHANGE_REQUEST_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_SELF_CERTIFICATION_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.ISSUE_NEW_REPORT_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]:
    'CASES.CASE_PAGE.TIMELINE_CARD.EQUIPMENT_NEW_REPORT_AGREEMENT',
  [DocumentTemplateUniqueIdentifier.CLOSE_BY_LEADER]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_CLOSE_BY_LEADER',
  [DocumentTemplateUniqueIdentifier.RESIDUAL_WORK_CAPACITY]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_RESIDUAL_WORK_CAPACITY',
  [DocumentTemplateUniqueIdentifier.WORKPLACE_ACCOMMODATION]:
    'CASES.CASE_PAGE.TIMELINE_CARD.TASK_DESCRIPTION_WORKPLACE_ACCOMMODATION',

  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING],
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS],
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE],
  [DocumentsIdentifiers.BASIC_FOLLOW_UP]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP],
  [DocumentsIdentifiers.BASIC_FOLLOW_UP_CALL]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP_CALL],
  [DocumentsIdentifiers.BASIC_FOLLOW_UP_MESSAGE]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.BASIC_FOLLOW_UP_MESSAGE],
  [DocumentsIdentifiers.FML_NEW_REPORT]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FML_NEW_REPORT],
  [DocumentsIdentifiers.FML_AGREEMENT]:
    DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.FML_AGREEMENT],
};

export const TASK_DIALOG_ID = {
  [DocumentTemplateUniqueIdentifier.WORKABILITY_UPDATE]: DialogId.REPORT_ISSUE,
  [DocumentTemplateUniqueIdentifier.FACILITATION]: DialogId.FACILITATION,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]: DialogId.FOLLOW_UP_PLAN,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    DialogId.SUMMARY_CARD_DETAILS,
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    DialogId.SUMMARY_CARD_DETAILS,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT]:
    DialogId.FOLLOW_UP_DOCUMENT,
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING]:
    DialogId.FOLLOW_UP_DOCUMENT_MEETING,
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING_REVIEW]:
    DialogId.FOLLOW_UP_DOCUMENT_MEETING_REVIEW,
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS]:
    DialogId.FOLLOW_UP_DOCUMENT,
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE]: DialogId.FOLLOW_UP_DOCUMENT,
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_AGREEMENT]:
    DialogId.SUMMARY_CARD_DETAILS,
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]:
    DialogId.SUMMARY_CARD_DETAILS,
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]:
    DialogId.SUMMARY_CARD_DETAILS,
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]:
    DialogId.SUMMARY_CARD_DETAILS,
  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP]: DialogId.BASIC_FOLLOW_UP,
  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL]:
    DialogId.BASIC_FOLLOW_UP_CALL,
  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE]:
    DialogId.BASIC_FOLLOW_UP_MESSAGE,
  [DocumentTemplateUniqueIdentifier.CLOSE_BY_LEADER]: DialogId.CLOSE_BY_LEADER,
  [DocumentTemplateUniqueIdentifier.RESIDUAL_WORK_CAPACITY]:
    DialogId.RESIDUAL_WORK_CAPACITY,
  [DocumentTemplateUniqueIdentifier.WORKPLACE_ACCOMMODATION]:
    DialogId.WORKPLACE_ACCOMMODATION,
};

export const TASK_DIALOG_COMPONENT = {
  [DocumentTemplateUniqueIdentifier.WORKABILITY_UPDATE]: ReportIssueComponent,
  [DocumentTemplateUniqueIdentifier.FACILITATION]: FacilitationComponent,
  [DocumentTemplateUniqueIdentifier.WORKPLACE_ACCOMMODATION]:
    WorkplaceAccommodationComponent,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]: FollowUpPlanComponent,
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]:
    DocumentAgreementComponent,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]:
    DocumentAgreementComponent,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT]:
    FollowUpDocumentComponent,

  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_MEETING]:
    DialogueMeetingSummaryComponent,
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_QUESTIONS]: FillModeComponent,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_REVIEW]:
    DialogueMeetingSummaryComponent,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW]:
    DialogueMeetingSummaryComponent,
  [DocumentsIdentifiers.FOLLOW_UP_DOCUMENT_UPDATE]: FollowUpDocumentComponent,
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_AGREEMENT]:
    DocumentAgreementComponent,

  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP]:
    FollowUpSchedulerComponent,
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_1]:
    FollowUpSchedulerComponent,

  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL]:
    FollowUpScheduledEventComponent,
  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE]:
    FollowUpScheduledEventComponent,

  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]:
    DocumentAgreementComponent,
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]:
    DocumentAgreementComponent,
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]:
    DocumentAgreementComponent,
  [DocumentTemplateUniqueIdentifier.FML_AGREEMENT]: DocumentAgreementComponent,
  [DocumentTemplateUniqueIdentifier.CLOSE_BY_LEADER]: CloseTaskComponent,
  [DocumentTemplateUniqueIdentifier.RESIDUAL_WORK_CAPACITY]:
    ResidualWorkCapacityComponent,
};

export const TASK_DIALOG_CLASSES = {
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]: [
    DialogClassName.WR_DIALOG_MEDIUM,
    DialogClassName.WR_DIALOG_SHOW_STEPPER_ON_HEADER,
    DialogClassName.WR_DIALOG_BG,
  ],
};

export const TASK_NUMBERING = {
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_AGREEMENT]: 2,
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_1]: 10,
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_2]: 11,
  [DocumentTemplateUniqueIdentifier.DIALOGUE_MEETING_3]: 12,
  [DocumentTemplateUniqueIdentifier.FACILITATION]: 3,
  [DocumentTemplateUniqueIdentifier.FACILITATION_AGREEMENT]: 4,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN]: 5,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_PLAN_AGREEMENT]: 6,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING]: 6,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_QUESTIONS]: 6,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_MEETING_REVIEW]: 7,
  [DocumentTemplateUniqueIdentifier.FOLLOW_UP_DOCUMENT_REVIEW]: 7,
  [DocumentTemplateUniqueIdentifier.NAV_FILLING]: 8,
  [DocumentTemplateUniqueIdentifier.WORKABILITY_UPDATE]: 1,
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT]: 1,
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT]: 1,
  [DocumentTemplateUniqueIdentifier.TEMPORARY_CHANGE_REQUEST_NEW_REPORT]: 1,
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_NEW_REPORT]: 1,
  [DocumentTemplateUniqueIdentifier.SICK_LEAVE_NEW_REPORT]: 1,
  [DocumentTemplateUniqueIdentifier.WORKABILITY_NEW_REPORT]: 2,
  [DocumentTemplateUniqueIdentifier.SELF_CERTIFICATION_AGREEMENT]: 2,
  [DocumentTemplateUniqueIdentifier.ISSUE_NEW_REPORT_AGREEMENT]: 2,
  [DocumentTemplateUniqueIdentifier.EQUIPMENT_NEW_REPORT_AGREEMENT]: 2,
  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_CALL]: 3,
  [DocumentTemplateUniqueIdentifier.BASIC_FOLLOW_UP_MESSAGE]: 3,
  [DocumentTemplateUniqueIdentifier.RESIDUAL_WORK_CAPACITY]: 2,
  [DocumentTemplateUniqueIdentifier.WORKPLACE_ACCOMMODATION]: 2,
  [DocumentTemplateUniqueIdentifier.FML_NEW_REPORT]: 1,
  [DocumentTemplateUniqueIdentifier.FML_AGREEMENT]: 2,
};

export const TASK_ACTION_DESCRIPTION_SEE_AGREEMENT =
  'CASES.CASE_PAGE.TIMELINE_CARD.ACTION_SEE_AGREEMENT';
export const TASK_ACTION_DESCRIPTION_COMPLETE_THE_FORM =
  'CASES.CASE_PAGE.TIMELINE_CARD.ACTION_COMPLETE_THE_FORM';
