<wr-generic-dialog [breadCrumData]="getBreadcrumbItems()" [showFooter]="true" class="wr-dialog-body-padding-0">
  <ng-container title> Please select the workspace to transfer</ng-container>

  <ng-container content>
    <mat-horizontal-stepper #stepper class="wa-stepper-inner" headerPosition="bottom" linear>
      <ng-container *ngFor="let page of templateData">
        <mat-step *ngIf="!page.hidden" [aria-labelledby]="0 <= stepper.selectedIndex ? 'filled-previously' : null"
                  completed>
          <ng-template matStepLabel>{{ 'FIRST PAGE' | translate }}</ng-template>
          <div class="m-auto text-center">
            <h3>{{ page.title }}</h3>
            <mat-spinner *ngIf="loading; else mainContent" [diameter]="50"></mat-spinner>
            <ng-template #mainContent>
              <ng-container *ngFor="let control of page.controls">
                <mat-card *ngIf="!control.hidden" class="d-flex justify-content-center gap-3 mb-4">
                  <h4>{{ control.label }}</h4>
                  <ng-container [ngSwitch]="control.type">
                    <ng-container *ngSwitchCase="'text'">
                      <wr-short-answer (valueChange)="handleControlVisibility(control)" [(value)]="control.value"
                                       [inputLabel]="control.inputLabel"></wr-short-answer>
                    </ng-container>
                    <ng-container *ngSwitchCase="'multiAnswer'">
                      <wr-multiple-answer (valueChange)="handleControlVisibility(control)" [(value)]="control.value"
                                          [inputLabel]="control.inputLabel"
                                          [selectOptions]="control.options"></wr-multiple-answer>
                    </ng-container>
                    <ng-container *ngSwitchCase="'singleAnswer'">
                      <wr-single-answer (valueChange)="handleControlVisibility(control)" [(value)]="control.value"
                                        [inputLabel]="control.inputLabel"
                                        [selectOptions]="control.options"></wr-single-answer>
                    </ng-container>
                    <ng-container *ngSwitchCase="'rating'">
                      <wr-rating (valueChange)="handleControlVisibility(control)" [(value)]="control.value" inputLabel="Rating label"
                                 type='star'></wr-rating>
                    </ng-container>
                    <ng-container *ngSwitchCase="'slider'">
                      <wr-slider (valueChange)="handleControlVisibility(control)" [(value)]="control.value"
                                 inputLabel="Rating label"></wr-slider>
                    </ng-container>
                    <ng-container *ngSwitchCase="'toggle'">
                      <wr-toggle (valueChange)="handleControlVisibility(control)" [(value)]="control.value" inputLabelOff="OFF"
                                 inputLabelOn="ON"></wr-toggle>
                    </ng-container>
                  </ng-container>
                </mat-card>
              </ng-container>

            </ng-template>
          </div>
        </mat-step>
      </ng-container>
      <mat-step [aria-labelledby]="0 <= stepper.selectedIndex ? 'filled-previously' : null" completed>
        <ng-template matStepLabel>{{ 'SUMMARY PAGE' | translate }}</ng-template>
        <div class="m-auto text-center">
          <h3>SUMMARY</h3>
          <mat-card *ngFor="let page of templateData" class="d-flex justify-content-center gap-3 mb-4">
            <h1>{{ page.title }}</h1>
            <div *ngFor="let control of page.controls">
              <span>{{ control.label }}: {{ test(control.value) }}</span>
            </div>
          </mat-card>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>

  <ng-container footer>
    <div class="footer__actions__container">
      <button
        (click)="stepperPrev()"
        class="me-2 mat-focus-indicator mat-button mat-button-base mat-stroked-button"
        mat-flat-button
        *ngIf="currentStep > 0"
      >
        {{ 'BUTTON.BACK' | translate }}
      </button>
      <button
        class="primary me-auto"
        mat-flat-button
        (click)="stepperNext()"
      >
        {{ 'BUTTON.NEXT' | translate }}
      </button>

      <button  class="primary me-auto" mat-flat-button >
        {{ 'BUTTON.SUBMIT' | translate }}
      </button>
    </div>
  </ng-container>
</wr-generic-dialog>
