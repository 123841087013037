import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { RouteSegments } from '@app/shared/routing.model';
import { AppHeaderService } from '@app/core/services/app-header/app-header.service';
import { HeaderStates } from '@app/core/app-header/app-header.model';
import { ResponsivenessService } from '@app/core/services/responsiveness/responsiveness.service';
import { Breakpoints } from '@app/shared/breakpoints.model';
import { IconsHelperService } from '@app/core/services/icons-helper/icons-helper.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { UserService } from '@app/modules/shared/services/user/user.service';
import { User } from '@app/modules/shared/models/user.model';
import { CommonService } from '@app/shared/user/common.service';
import { SecurityService } from '@app/core/services/security/security.service';
import { BREAKPOINT_MOBILE } from '@app/shared/constants/breakpoints.constants';
import { MediaMatcher } from '@angular/cdk/layout';
import { NotificationsService } from '@app/modules/shared/services/notifications/notifications.service';
import { INotificationResponse } from '@app/modules/shared/models/notifications.model';
import { DialogClassName, DialogId } from '@app/shared/dialogs.model';
import { filter } from 'rxjs/operators';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';
import { ContactUsComponent } from '@app/modules/contact-us/contact-us.component';
import { TokenKey } from '@app/shared/common.model';
import { FeedbackComponent } from '@app/modules/feedback/feedback.component';
import { environment } from '@environment/environment';
import { JwtResponse } from '@app/modules/shared/models/jwt.model';

@Component({
  providers: [UserService],
  selector: 'wr-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  accessData: JwtResponse;
  routeSegments: typeof RouteSegments = RouteSegments;
  headerStates: typeof HeaderStates = HeaderStates;
  breakpoints: typeof Breakpoints = Breakpoints;
  headerState: HeaderStates;
  currentBreakpoint: Breakpoints;
  currentUserWorkspaceId: string;
  companyId: string;
  mobileMenuOpened = false;
  mobileQuery: MediaQueryList;
  notificationList: INotificationResponse[] = [];
  unreadNotificationCount = 0;
  isToggleChecked = false;
  isToggleVisible = true;
  @Output() screenshotClicked = new EventEmitter<any>();
  private mobileQueryListener: () => void;

  constructor(
    private readonly appHeaderService: AppHeaderService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly iconsHelperService: IconsHelperService,
    private readonly securityService: SecurityService,
    private readonly dialogRef: MatDialog,
    private readonly router: Router,
    private userService: UserService,
    private commonService: CommonService,
    private readonly media: MediaMatcher,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationService: NotificationsService,
    private readonly dialogHelperService: DialogHelperService,
    private elementRef: ElementRef,
  ) {
    this.iconsHelperService.registerSvgIcons([
      'menu-message-centre',
      'menu-notifications',
      'ms_app_header_chat',
      'ms_app_header_notification',
      'feedback-icon',
    ]);
    this.runResponsivenessActions();
    this.accessData = this.userService.getAuthTokenData();
    this.companyId = this.accessData.companyId;
    this.currentUserWorkspaceId = this.accessData.userWorkspaceId;
    this.isToggleVisible = !environment.production;
  }
  currentUser: User;
  imgUrl: any = '../../../../../assets/images/temp/avatar.png';

  async ngOnInit(): Promise<void> {
    this.appHeaderService.getProfilePicture().subscribe((img) => {
      this.imgUrl = img;
    });
    this.watchHeaderState();
    this.watchCurrentBreakpoint();
    this.watchNotificationChanges();
    this.getNotifications();
    await this.getCurrentUserInfo();
    this.isToggleChecked =
      this.securityService.getLocalStorageItem('full-mode');
    this.commonService.productionEnabled = this.isToggleChecked;
  }
  navigate(route: string): void {
    this.dialogRef.closeAll();
    this.router.navigate([route]);
  }
  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeMobileMenu();
    }
  }
  async getCurrentUserInfo(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    if (this.currentUser.photo) {
      this.imgUrl = this.currentUser.photo;
      this.appHeaderService.setProfilePicture(this.imgUrl);
    }
  }
  async getNotifications(): Promise<void> {
    const notifications = await this.notificationService.fetchNotifications();
    this.commonService.addNotifications(notifications);
  }

  private watchHeaderState(): void {
    this.appHeaderService.headerState$.subscribe((state) => {
      this.headerState = state;
    });
  }

  private watchCurrentBreakpoint(): void {
    this.responsivenessService.currentBreakpoint$.subscribe((breakpoint) => {
      this.currentBreakpoint = breakpoint;
    });
  }

  watchNotificationChanges(): void {
    this.commonService.notification$.subscribe((val) => {
      this.notificationList = this.commonService.getNotifications();
      this.unreadNotificationCount = this.commonService.getNotificationCount();
    });
  }

  onLogoutClick(): void {
    this.userService.logout();
  }

  private runResponsivenessActions(): void {
    this.mobileQuery = this.media.matchMedia(
      `(max-width: ${BREAKPOINT_MOBILE}px)`,
    );
    this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  openMobileMenu(): void {
    this.mobileMenuOpened = true;
  }
  closeMobileMenu(): void {
    this.mobileMenuOpened = false;
  }

  gotoHome(): void {
    if (this.accessData.isExternal) {
      this.router.navigate([RouteSegments.CASES]);
    } else {
      this.router.navigate([RouteSegments.LANDING_PAGE]);
    }
  }

  navigateToNotifications(): void {
    this.router.navigate([this.routeSegments.NOTIFICATIONS]);
  }

  markAllAsRead(): void {
    this.notificationService.setReadStatus();
    this.commonService.setNotificationAsRead();
  }

  async initiateFeedback(): Promise<void> {
    this.securityService.setToken(TokenKey.SCREENSHOT, null);

    this.dialogHelperService
      .openDialog(
        DialogId.FEEDBACK,
        FeedbackComponent,
        [DialogClassName.WR_DIALOG_MAX_WIDTH_900],
        null,
        { disableClose: true },
      )
      .afterClosed();
    // .pipe(filter(Boolean))
    // .subscribe((feedbackWIthScreenshot: boolean) => {
    //   if (feedbackWIthScreenshot) {
    //     this.screenshotClicked.emit(true);
    //   }
    // });
    // let proceedToFeedback: SweetAlertResult | undefined;
    // proceedToFeedback = await this.proceedWithFeedback();
    // if ((proceedToFeedback && proceedToFeedback.isDismissed) || !proceedToFeedback) {
    // } else if (proceedToFeedback.isConfirmed) {
    //   this.commonService.openFeedback();
    // } else if (proceedToFeedback.isDenied) {
    // this.screenshotClicked.emit(true);
    // }
  }

  openContactUs(): void {
    this.dialogHelperService
      .openDialog(
        DialogId.CONTACT_US,
        ContactUsComponent,
        [DialogClassName.WR_DIALOG_MAX_WIDTH_900],
        null,
        { disableClose: false },
      )
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((dialogResult) => {
        if (dialogResult) {
          // this.getTeams();
        }
      });
  }

  onToggleChange(event: any): void {
    this.securityService.setLocalStorageItem('full-mode', this.isToggleChecked);
    this.commonService.productionEnabled = this.isToggleChecked;
  }

  openLink(link: string): void {
    window.open(link, '_blank');
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }
}
