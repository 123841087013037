import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HoursTimeRangeDirective } from '@app/shared/directives/hours-time-range/hours-time-range.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HoursTimeRangeDirective],
  exports: [HoursTimeRangeDirective],
})
export class HoursTimeRangeModule {}
