import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationColor, NotificationPosition, NotificationShowTime } from '@app/core/services/notify/notify.model';
import { NotifyService } from '@app/core/services/notify/notify.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private notifyService: NotifyService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = ` Message: ${error.error.message}`;
          this.notifyService.showNotification(
            errorMsg,
            NotificationColor.ERROR,
            NotificationShowTime.MEDIUM,
            NotificationPosition.TOP,
          );
        }

        return throwError(errorMsg);
      }),
    );
  }
}
