import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  EPageTypes, EQuestionsType
} from "@app/modules/builder/builder-container/question-builder/page-selector/page-selector.component";

@Component({
  selector: 'wr-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss']
})
export class BuilderComponent implements OnInit {
  @ViewChild('builderSection') builderSection!: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  scrollToChild(section: HTMLDivElement) {
    section.scrollIntoView({behavior: 'smooth'});

  }


}

export interface ITemplatePageType {
  pageType: EPageTypes | null,
  questionType: EQuestionsType | null
}
