import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'wr-location-textbox',
    templateUrl: './location-textbox.component.html',
    styleUrls: ['./location-textbox.component.scss']
})
export class LocationTextboxComponent implements OnInit {
    @Input() textControl: UntypedFormControl;
    @Input() description: string;
    @Input() inputLabel: string;
    @Input() autofocus: boolean;
    checkboxVal = false;
    workFromHomeVal = 'WORK_FROM_HOME'
    ngOnInit(): void {
        if (this.textControl.value && this.textControl.value.length > 0 && this.textControl.value !== this.workFromHomeVal) {
            this.checkboxVal = true;
        }
        this.radioButtonChanged()

    }

    radioButtonChanged(reset = false): void {
        if (reset) {
            this.textControl.setValue('')
        }
        if (!this.checkboxVal) {
            this.textControl.setValue('WORK_FROM_HOME')
        }
    }
}
