import {Component, OnInit, ViewChild} from '@angular/core';
import {defaultPageSizeOption, defaultPaginationData} from "@app/shared/paginationConfig.model";
import {MatSort, Sort} from "@angular/material/sort";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {LegacyPageEvent as PageEvent} from "@angular/material/legacy-paginator";

@Component({
  selector: 'wr-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit{
  @ViewChild('tableSort', { static: true }) sort: MatSort;
  totalData = 0;
  displayedColumns = [
    'name',
    'type',
    'how',
    'frequency',
    'created',
    'employee',
    'action'
  ];
  dataSource = new MatTableDataSource<any>([]);
  protected readonly pageSizeOptions = defaultPageSizeOption;
  paginationData = defaultPaginationData;
  // MatPaginator Output
  pageEvent: PageEvent;
  ngOnInit() {
    this.dataSource = new MatTableDataSource([
      {
        name: 'Name hae',
        type: 'Type hae',
        how: 'meeting',
        frequency: 'everyday',
        created: 'Dec 12, 2024',
        employee: [
          {name: 'Atul Shrestha', photo: ''},
          {name: 'Saroj Poudel', photo: ''},
          {name: 'Sashis Stha', photo: ''},
        ],
      }
    ])

  }
  sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      this.paginationData.sort = '';
    } else {
      const ascDesc = sort.direction === 'asc' ? '-' : '';
      this.paginationData.sort = ascDesc + sort.active;
    }
  }
  pagination = (event: PageEvent) => {
    this.pageEvent = event;
    this.paginationData.limit = event.pageSize;
    this.paginationData.skip = event.pageIndex || 0;
  };
}
