import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { BehaviorSubject } from 'rxjs';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { CommonService } from '@app/shared/user/common.service';
import { NotificationsService } from './notifications/notifications.service';
import { ApiList } from '@app/app.constants';
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private readonly http: HttpClient,
    public platform: Platform,
    private commonService: CommonService,
    private notificationService: NotificationsService,
  ) {
    // this.angularFireMessaging.app.automaticDataCollectionEnabled;

    this.angularFireMessaging.onMessage((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission(): void {
    this.angularFireMessaging.requestToken.subscribe(
      async (token) => {
        if (token) {
          await this.setTokenToDB(token).toPromise();
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      },
    );
  }

  receiveMessage(): void {
    this.angularFireMessaging.messages.subscribe(async (payload: any) => {
      const data = await this.notificationService.fetchNotification(
        payload.data.id,
      );
      if (data) {
        this.commonService.addNotification(data[0]);
        this.currentMessage.next(payload);
      }
    });
  }

  setTokenToDB(token: string): any {
    const platform = this.platform.isBrowser
      ? DeviceType.WEB
      : this.platform.ANDROID
      ? DeviceType.ANDROID
      : this.platform.IOS
      ? DeviceType.IOS
      : DeviceType.WEB;
    return this.http.post<any>(
      `${environment.baseUrl}${ApiList.users.device}`,
      { token, platform },
    );
  }
}

export enum DeviceType {
  WEB = '0',
  IOS = '1',
  ANDROID = '2',
}
