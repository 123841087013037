import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { SelectOption } from '@app/shared/common.model';

@Component({
  selector: 'wr-multiple-answer',
  templateUrl: './multiple-answer.component.html',
  styleUrls: ['./multiple-answer.component.scss'],
})
export class MultipleAnswerComponent {
  @Input() type: 'checkbox' | 'select' | 'button' = 'checkbox';
  @Input() formArray: UntypedFormArray;
  @Input() inputLabel: string;
  @Input() description: string;
  @Input() selectOptions: SelectOption<string>[];
  _value: string[];
  @Input() set value(val: Array<string>) {
    this._value = val;
  }
  get value(): Array<string> {
    return this._value;
  }
  @Output() valueChange = new EventEmitter<any>();

  handleValueChange(value: string, checked: boolean) {
    if (checked) {
      this.value.push(value);
    } else {
      const currentIndex = this.value.findIndex((val) => val === value);
      if (currentIndex >= 0) {
        this.value.splice(currentIndex, 1);
      }
    }

    this.valueChange.emit(this.value);
  }

  handleSelectValueChange(value) {
    this.value = value;

    this.valueChange.emit(this.value);
  }
}
