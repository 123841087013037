<div>
  <div class="account-container__accounts-sections">
    <!--TABLE-->
    <div class="overflow-auto">
      <table
        #tableSort="matSort"
        (matSortChange)="sortData($event)"
        [dataSource]="dataSource"
        class="w-100 bg-transparent mat-table-white mat-table-custom mat-table-custom-last-column-action mat-table-custom-list"
        mat-table
        matSort
      >
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'KEY.NAME' | translate }}
          </th>
          <td *matCellDef="let element" mat-cell class="has-row-action">
            <mat-icon
              class="mat-icon-rounded-bg"
              [ngClass]="{ 'mat-icon-inactive': element.status == 'CLOSED' }"
              [svgIcon]="'report-work-issue-button-icon'"
            ></mat-icon>
            {{ element.name | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'KEY.TYPE' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'KEY.TYPE' | translate"
          >
            <span> {{ element?.type }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="how">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'SCHEDULER.STEPS.TAB.HOW' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'SCHEDULER.STEPS.TAB.HOW' | translate"
          >
            <span class="status status-active"
            >{{ element.how  }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="frequency">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'KEY.FREQUENCY' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="element.frequency"
          >
              <span>
                {{
                  element.frequency
                }}
              </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'COMMON.FILTERS.LABEL.CREATED' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'COMMON.FILTERS.LABEL.CREATED' | translate"
          >
            <span>{{ element.created  }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="employee">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'ROLENAME.EMPLOYEE' | translate }}
          </th>
          <td
            *matCellDef="let element"
            mat-cell
            [attr.data-col]="'ROLENAME.EMPLOYEE' | translate"
          >
              <span
                *ngFor="let employee of element.employee"
                class="avatar avatar-table avatar-sm avatar-overlap"
                [matTooltip]="employee.name"
              >
                <figure
                  [ngStyle]="{
                    'background-image': 'url(' + employee.photo + ')'
                  }"
                ></figure>
                <figcaption>{{ employee.name | nameInitials }}</figcaption>
              </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th *matHeaderCellDef mat-header-cell>
            <div class="th-action">
              {{ 'KEY.CASES.ACTION' | translate }}
            </div>
          </th>
          <td *matCellDef="let element" mat-cell class="row-action">
            <button
              mat-icon-button
              aria-label="Example icon button with a vertical three dot icon"
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
    </div>
    <mat-paginator
      (page)="pagination($event)"
      *ngIf="totalData > 5"
      [length]="totalData"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="paginationData.limit"
      aria-label="Select page"
      class="bg-transparent"
    >
    </mat-paginator>
    <!--TABLE-->
  </div>
</div>
