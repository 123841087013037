import { RouteSegments } from '@app/shared/routing.model';

export const NO_SIDENAV_ROUTES: string[] = [
  `/${RouteSegments.ENTRY}/${RouteSegments.LOGIN}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.REGISTER}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.CONFIRM_VALIDATE}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.EMAIL_VERIFY}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.ENABLE_2FACTOR}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.AUTHENTICATE_2FACTOR}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.CONFIRM_REGISTER}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.RESET}`,
  `/${RouteSegments.ENTRY}/${RouteSegments.CONFIRM_RESET}`,
  `/${RouteSegments.SETUP}/${RouteSegments.WELCOME}`,
  `/${RouteSegments.SETUP}/${RouteSegments.COMPANY_SETUP}`,
  `/${RouteSegments.SETUP}/${RouteSegments.WORKSPACE_SETUP}`,
  `/${RouteSegments.SETUP}/${RouteSegments.WORKSPACE_ONBOARDING}`,
  `/${RouteSegments.SETUP}/${RouteSegments.WORKSPACE_ORGANISATION}`,
  `/${RouteSegments.SETUP}/${RouteSegments.TEAMSPACE_INVITE}`,
  `/${RouteSegments.SETUP}/${RouteSegments.TEAMSPACE}`,
  `/${RouteSegments.PROFILE_PAGE}/${RouteSegments.EMAIL_VERIFICATION_PROCESS}`,
  `/${RouteSegments.CONSENT_APPROVAL}`,
];

export const ApiList = {
  auth: {
    _: 'api/auth/',
    register: 'api/auth/register/',
    login: 'api/auth/login/',
    reset: 'api/auth/reset/',
    confirmReset: 'api/auth/confirm-reset/',
    requestQr: 'api/auth/mfa/request/qr',
    activate: 'api/auth/mfa/activate',
    request: 'api/auth/mfa/request',
    authenticate: 'api/auth/mfa/authenticate',
    verify: 'verify',
    getUserInfo: 'get-user-info',
  },
  documentTemplates: {
    templates: 'api/doc-templates/',
    _fields: 'fields/',
    _uniqueIdentifier: 'uniqueIdentifier/',
  },
  templates: {
    _: 'api/templates/',
    _fields: 'fields/',
    _uniqueIdentifier: 'uniqueIdentifier/',
  },
  cases: {
    _: 'api/cases/',
    _docTemplate: 'doc-template/',
    _tasks: 'tasks/',
    _closed: 'closed/',
    _documents: 'documents/',
    _documentType: 'documents/type/',
    _invitation: 'invitation/',
    _my: 'my/',
    _filterData: 'filters/data',
    _channels: 'channels/',
    _accesses: 'accesses/',
    _accepted: 'accepted/',
    _check: 'check/',
    _mySelfAssessment: 'self-assessment/me/',
    _mySelfAssessmentDocuments: 'self-assessment/documents/me',
  },
  chat: {
    _: 'api/chat/',
    _currentStep: 'current-step/',
    _history: 'history/my/',
    _historyDetail: 'history/details',
    _search: 'articles/search',
  },
  feed: {
    _: 'api/feed/',
    _casesFeed: 'case/',
    _comment: 'comment/',
    _comments: 'comments/',
  },
  users: {
    me: 'api/users/me',
    uploadProfilePicture: 'api/users/upload',
    changePassword: 'api/users/change-password',
    device: 'api/users/device',
    termsAndCondition: 'api/tnc',
    language: 'api/users/language',
    updateExistingEmail: 'api/users/verify-email',
    verifyOtpAndToken: 'api/users/update-email',
    mfa: {
      _: 'api/users/mfa',
      status: 'api/users/mfa/status',
    },
  },
  tnc: {
    history: 'api/tnc/history',
  },
  documents: {
    _: 'api/documents/',
    _completed: 'completed/',
  },
  tasks: {
    _: 'api/tasks/',
    my: 'api/tasks/my/',
    postpone: 'postpone/',
    next: 'next',
    questionnaires: 'api/questionnaires/',
    answers: 'answers',
    events: 'events',
  },
  channels: {
    _: 'api/channels/',
    _my: 'my/',
  },
  accounts: {
    _: 'api/companies',
    create: 'api/companies',
    getList: 'api/companies',
    uploadCompanyLogo: 'api/companies/upload',
    workspaceDetail: '/workspace-details',
  },
  setup: {
    companies: 'api/companies',
    companiesDropdown: 'api/companies/dropdown',
    getWorkspacePolicies: 'api/policies',
    workspaces: 'api/workspaces',
    invitation: 'api/user-workspaces/invite',
    inviteAll: 'api/user-workspaces/invite/all',
    userWorkspace: 'api/user-workspaces',
    userWorkspaceProfile: 'api/user-workspaces/profile',
    jobRoles: 'api/job-roles',
    userWorkspaceTeam: 'api/user-workspaces/team',
    contactPerson: 'api/user-workspaces/contact-person',
  },
  contactForm: {
    _: 'api/contact',
  },
  feedbackForm: {
    _: 'api/feedbacks',
  },
  userWorkspace: {
    _: 'api/user-workspaces',
    invite: 'api/user-workspaces/invite',
    switch: 'api/user-workspaces/switch',
    status: 'api/user-workspaces/status',
    team: 'api/user-workspaces/team',
    myteam: 'api/user-workspaces/myteam',
    availableWorkspaces: '/available-workspaces',
    leaders: '/leaders',
    assignAsEmployee: '/assign-as-employee',
    transfer: '/transfer',
    leaderExists: '/leaderExists',
    resend: '/resend',
    remove: '/remove',
    dropdownTeam: 'api/user-workspaces/team/dropdown',
    workspaceStatus: 'api/user-workspaces/workability-status',
    role: '/role',
    roleRemove: 'role/remove',
    roleAssign: 'role/assign',
    hrRoleAssign: 'role/assign-hr-role',
  },
  countries: {
    countries: 'api/countries',
  },
  subscription: {
    _: 'api/subscription',
    plans: 'api/subscription/plans',
    cancel: 'cancel',
    status: 'status',
    upgrade: 'upgrade',
    resume: 'resume',
  },
  notifications: {
    _: 'api/notifications',
    status: 'status',
  },
  activeUsers: {
    _: 'api/active-users',
    history: 'history',
  },
  articles: {
    _: 'api/articles',
  },
  contact: {
    _: 'api/contact',
    all: 'all',
  },
  checklistTracker: {
    _: 'api/checklist-tracker',
    cancel: 'cancel',
  },
  workspaces: {
    _: 'api/workspaces',
    address: 'api/workspaces/address',
    policies: 'api/workspaces/policies',
    _address: '/address',
  },
  healthServiceProvider: {
    _: 'api/hsp',
  },
  roles: {
    _: 'api/roles',
    permissions: {
      _: 'permissions',
      all: 'permissions/all',
    },
  },
  templateCategories: {
    _: 'api/template-categories',
  },
};
