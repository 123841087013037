import { Component, Inject, OnInit } from '@angular/core';
import { IInviteEmployeeFormData } from '@app/modules/setup/setup.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogId } from '@app/shared/dialogs.model';
import { DialogHelperService } from '@app/shared/dialog-helper/dialog-helper.service';

@Component({
  selector: 'wr-select-existing-user-with-search',
  templateUrl: './select-existing-user-with-search.component.html',
  styleUrls: ['./select-existing-user-with-search.component.scss'],
})
export class SelectExistingUserWithSearchComponent implements OnInit {
  filteredTeamData: IInviteEmployeeFormData[] = [];
  searchString = '';
  teamHrData: IInviteEmployeeFormData | null = null;
  constructor(
    private dialogHelperService: DialogHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: { preparedInviteList: IInviteEmployeeFormData[] },
  ) {}

  ngOnInit(): void {
    this.setFilteredTeamData();
  }
  clearSearch(): void {
    this.searchString = '';
    this.setFilteredTeamData();
  }

  setFilteredTeamData(): void {
    const lowerSearchText = this.searchString.toLowerCase();
    // Use the filter method to filter objects based on the search text
    this.filteredTeamData = this.data.preparedInviteList.filter((person) => {
      // Combine first name, last name, and email for comprehensive search
      const fullText =
        `${person.firstName} ${person.lastName} ${person.email}`.toLowerCase();

      // Check if the full text includes the search text
      return fullText.includes(lowerSearchText);
    });
    this.filteredTeamData = this.getDistinctUsers(this.filteredTeamData);
  }

  // Function to get distinct objects based on email property
  getDistinctUsers(users: IInviteEmployeeFormData[]): IInviteEmployeeFormData[] {
    const uniqueEmails = new Set<string>();
    const distinctUsers: IInviteEmployeeFormData[] = [];

    for (const user of users) {
      if (!uniqueEmails.has(user.email)) {
        distinctUsers.push(user);
        uniqueEmails.add(user.email);
      }
    }

    return distinctUsers;
  }
  handleSubmit(): void {
    if (this.teamHrData) {
      this.dialogHelperService.closeDialog(
        DialogId.ONBOARDING_SELECT_EXISTING_USER,
        { dialogResult: { ...this.teamHrData } },
      );
    }
  }
}
