import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {DocumentFormConfig} from "@app/modules/forms/models/document-templates.model";
import {CaseTypes} from "@app/shared/cases.model";
import {DocumentTemplatesService} from "@app/modules/forms/services/document-templates.service";
import {DialogHelperService} from "@app/shared/dialog-helper/dialog-helper.service";
import {CasesService} from "@app/shared/cases/cases.service";
import {Router} from "@angular/router";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {DialogId} from "@app/shared/dialogs.model";
import {DocumentsIdentifiers} from "@app/shared/documents.model";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'wr-fml',
  templateUrl: './fml.component.html',
  styleUrls: ['./fml.component.scss']
})
export class FmlComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject<null>();

  docVersion: number;
  getDocument$: Observable<DocumentFormConfig>;
  caseType = CaseTypes.FML;
  documentTypeLabel = 'New'; // DOCUMENTS_TYPE_LABELS[DocumentsIdentifiers.SELF_ASSESSMENT];

  constructor(
    private readonly documentTemplatesService: DocumentTemplatesService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly casesService: CasesService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseNumber: string;
      caseUser: string;
      userId: string;
      caseType: CaseTypes;
      existingCaseId: string;
      dialogId: DialogId;

    },
  ) {}

  ngOnInit(): void {

    this.getDocument$ = this.documentTemplatesService.getDocumentFormConfig(DocumentsIdentifiers.FML);
  }

  submit(fields: { [key: string]: string | string[] }): void {
    this.casesService
      .createCase({
        documents: [
          {
            docTemplateUniqueIdentifier:
            DocumentsIdentifiers.FML,
            docTemplateVersionNumber: this.docVersion,
            ownerId: this.data.userId,
            fields,
          },
        ],
        type: CaseTypes.FML,
        caseId: this.data.existingCaseId ? this.data.existingCaseId : null,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data) => {
          this.dialogHelperService.closeDialog(DialogId.FML, {
            dialogResult: true,
          });
          this.redirectToCases(data._id);
        },
        (error) => {
          // TODO : add error handling
          console.error(error);
        },
      );
  }

  redirectToCases(id: string): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(`/cases/${id}`);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
