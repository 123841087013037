import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectOption} from "@app/shared/common.model";

@Component({
  selector: 'wr-select-tiles-with-search',
  templateUrl: './select-tiles-with-search.component.html',
  styleUrls: ['./select-tiles-with-search.component.scss']
})
export class SelectTilesWithSearchComponent implements OnInit {
  _options: SelectOption<any>[];
  @Input() set options(data: SelectOption<any>[]) {
    this._options = data;
    this.setFilteredList()
  }
  @Output() valueSelect = new EventEmitter<any>();
  searchString = "";
  filteredOptions: SelectOption<string>[];
  selectedValue: string = '';
  constructor() { }

  ngOnInit(): void {
  }
  setFilteredList() {
    const lowerSearchText = this.searchString.toLowerCase();
    // Use the filter method to filter objects based on the search text
    this.filteredOptions = this._options.filter((opts) => {
      // Combine first name, last name, and email for comprehensive search
      const fullText =
        `${opts.label}`.toLowerCase();

      // Check if the full text includes the search text
      return fullText.includes(lowerSearchText);
    });
  }

  clearSearch() {
    this.searchString = '';
    this.setFilteredList();
  }

  handleSelectionChange(value:string) {
    this.selectedValue = value;
    this.valueSelect.emit(value);
  }
}
