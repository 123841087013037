<h4 class="card-details__title">{{ summaryControl.heading }}</h4>
<div class="card-details__value__container" *ngIf="summaryControl.value; else noData">
  <mat-icon class="card-details__value__icon" svgIcon="summary-percentage" color="primary"></mat-icon>
  <span class="card-details__value__text">
    {{ summaryControl.selectOptions[summaryControl.value] || summaryControl.value | humanizeBoolean }}
  </span>
</div>
<ng-template #noData>
  <span class="card-details__no-value__text">{{ summaryControl.noValueInfo || '-' }}</span>
</ng-template>
