import {
  SidenavItem,
  SidenavItemsActions,
} from '@app/core/app-sidenav/app-sidenav.model';
import { PERMISSIONS } from '@app/modules/shared/constants/permissions.contants';
import { RouteSegments } from '@app/shared/routing.model';

export const SIDENAV_ITEMS: SidenavItem[] = [
  {
    icon: 'menu-new-action',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_NEW_ACTION',
    additionalLabelKey:
      'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_CLICK_TO_SUBMIT_A_NEW_FORM',
    action: SidenavItemsActions.NEW_ACTION,
    permissions: [
      PERMISSIONS.FOLLOW_UP.CREATE.SICK_LEAVE,
      PERMISSIONS.FOLLOW_UP.CREATE.SELF_CERTIFICATION,
      PERMISSIONS.FOLLOW_UP.CREATE.OTHER_ISSUE,
      PERMISSIONS.FOLLOW_UP.CREATE.FAMILY_MEDICAL_LEAVE,
      PERMISSIONS.FOLLOW_UP.CREATE.TEMPORARY_CHANGE_REQUEST,
      PERMISSIONS.FOLLOW_UP.CREATE.WORKABILITY_SELF_ASSESSMENT,
    ],
    productionEnabled: false,
    mobileIndex: 2,
  },
  {
    icon: 'menu-home',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_HOME',
    link: RouteSegments.LANDING_PAGE,
    permissions: [],
    mobileIndex: 0,
  },
  {
    icon: 'menu-my-cases',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_MY_CASES',
    link: RouteSegments.CASES,
    permissions: [PERMISSIONS.FOLLOW_UP.LIST],
    mobileIndex: 1,
  },
  {
    icon: 'menu-my-tasks',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_MY_TASKS',
    link: RouteSegments.TASKS,
    permissions: [PERMISSIONS.TASKS],
    mobileIndex: 3,
  },
  {
    icon: 'menu-my-teams',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_MY_TEAMS',
    link: RouteSegments.TEAMS,
    permissions: [PERMISSIONS.TEAMS.LIST],
    mobileIndex: 4,
  },
  {
    icon: 'menu-system-admin',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_SYS_ADMIN',
    link: RouteSegments.SYS_ADMIN,
    permissions: [PERMISSIONS.ADMINISTRATOR.DASHBOARD],
    mobileIndex: 5,
  },
  {
    icon: 'menu-system-admin',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_MY_WORKABILITY',
    link: RouteSegments.MY_WORKABILITY,
    permissions: [PERMISSIONS.MY_WORKABILITY],
    productionEnabled: true,
    mobileIndex: 6,
  },
  {
    icon: 'leadership-portal',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_LEADERSHIP_PORTAL',
    link: RouteSegments.LEADERSHIP_PORTAL,
    permissions: [PERMISSIONS.QUESTION_OF_THE_DAY],
    productionEnabled: false,
    mobileIndex: 7,
  },
  {
    icon: 'menu-wellness-center',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_WELLNESS_CENTER',
    link: RouteSegments.WELLNESS_CENTER,
    permissions: [],
    productionEnabled: false,
    mobileIndex: 8,
  },
  {
    icon: 'calendar',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.EVENT_CALENDAR',
    link: RouteSegments.EVENT_CALENDAR,
    permissions: [PERMISSIONS.EVENTS],
    productionEnabled: false,
    mobileIndex: 9,
  },
  {
    icon: 'grid_guides',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.TEMPLATES',
    link: RouteSegments.TEMPLATES,
    permissions: [PERMISSIONS.TEAMS.LIST],
    productionEnabled: false,
    devEnabled: false,

    mobileIndex: 10,
  },
  {
    icon: 'grid_guides',
    labelKey: 'MENU.SIDENAV.BUILDER.TEMPLATES',
    link: RouteSegments.BUILDER,
    productionEnabled: false,
    devEnabled: false,
    permissions: [],
    mobileIndex: 11,
  },
  {
    icon: 'grid_guides',
    labelKey: 'MENU.SIDENAV.FOLLOW_UP.TEMPLATES',
    link: RouteSegments.FOLLOW_UP,
    productionEnabled: false,
    devEnabled: false,
    permissions: [],
    mobileIndex: 11,
  },
];

export const MOBILE_SIDENAV_ITEMS: SidenavItem[] = [
  {
    icon: 'menu-system-admin',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_MY_WORKABILITY',
    link: RouteSegments.MY_WORKABILITY,
    permissions: [],
  },
  {
    icon: 'menu-wellness-center',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_WELLNESS_CENTER',
    link: RouteSegments.WELLNESS_CENTER,
    permissions: [],
  },
  {
    icon: 'menu-my-teams',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_MY_TEAMS',
    link: RouteSegments.TEAMS,
    permissions: [],
  },
  {
    icon: 'leadership-portal',
    labelKey: 'MENU.SIDENAV.NEW_ACTION.MENU_ITEM_LEADERSHIP_PORTAL',
    link: RouteSegments.LEADERSHIP_PORTAL,
    permissions: [],
  },
];
