import { Injectable } from '@angular/core';
import { TokenKey } from '@app/modules/shared/models/common.model';
import * as CryptoJS from 'crypto-js';
import { environment } from '@environment/environment';
// import jwt_decode from 'jwt-decode';
// import { JwtResponse } from '@app/shared/jwt.model';

@Injectable()
export class SecurityService {
  getToken(tokenKey: TokenKey): string {
    return sessionStorage.getItem(tokenKey);
  }

  setToken(tokenKey: TokenKey, token: string): void {
    sessionStorage.setItem(tokenKey, token);
  }

  removeToken(tokenKey: TokenKey): void {
    localStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenKey);
  }
  removeTokenByStr(tokenKey: string): void {
    localStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenKey);
  }
  setSessionItem(key: string, data: any): void {
    const encData = data; // this.encryptObj(data)
    sessionStorage.setItem(key, encData);
  }
  getSessionItem(key: string): any {
    const encData = sessionStorage.getItem(key);
    return encData;
  }
  setLocalStorageItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getLocalStorageItem(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  removeLocalStorageItem(key: string): void {
    localStorage.removeItem(key);
  }

  clearAllTokens(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  decrypt(cipherText: string): string {
    if (cipherText && cipherText.length > 0){
    const password = environment.cryptoPassword;
    const reb64 = CryptoJS.enc.Hex.parse(cipherText);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, password);
    const plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
    }
    return undefined;
  }

  encrypt(plainText: string): string {
    const password = environment.cryptoPassword;
    const b64 = CryptoJS.AES.encrypt(plainText, password).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }

  encryptObj(obj: any): string {
    return this.encrypt(JSON.stringify(obj));
  }

  decryptObj(chiperText: string): any{
    const decryptedText = this.decrypt(chiperText);
    if (decryptedText != undefined) return JSON.parse(decryptedText);
  }

  // getJwtData(): JwtResponse {
  //   const token = sessionStorage.getItem(TokenKey.ACCESS) || '';
  //   return jwt_decode(token) as JwtResponse;
  // }
}
