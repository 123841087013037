import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { ITemplateVersionData } from '@app/modules/content-creator/content-creatorer.model';
import {ApiList} from "@app/app.constants";
import {Languages} from "@app/shared/common.model";
import {TranslateService} from "@ngx-translate/core";
import {CaseType} from "@app/modules/case-page/models/case-details.model";

@Injectable({
  providedIn: 'root',
})
export class ContentCreatorService {
  selectedLanguage: Languages
  constructor(private readonly http: HttpClient, private translateService: TranslateService) {
    this.selectedLanguage = this.translateService.currentLang as Languages;
  }

  getTemplates(caseType = CaseType.SICK_LEAVE, version?: number): Observable<ITemplateVersionData> {
    let param = {language: this.selectedLanguage};
    if (version) {
      param = {...param, ...{version: version.toString()}}
    }
    return this.http.get<ITemplateVersionData>(`${environment.baseUrl}${ApiList.templates._}${caseType}`, {params: param});
  }
  saveTemplates(data: ITemplateVersionData): Observable<ITemplateVersionData> {
    delete data._id;
    delete data.user;
    const modDataWithVersionArray = (JSON.parse(JSON.stringify(data))) as any;
    modDataWithVersionArray.versions = [data.versions];
    return this.http.post<ITemplateVersionData>(`${environment.baseUrl}${ApiList.templates._}`, modDataWithVersionArray);
  }
}
