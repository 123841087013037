<div class="scheduler-summary-item" *ngIf="summaryControl.value; else noData">
  <p
    class="card-details__subtitle"
    *ngIf="
      summaryControl && summaryControl.heading && summaryControl.heading !== ''
    "
  >
    {{ summaryControl.heading }}
  </p>
  <span translate>
    {{
      summaryControl &&
      summaryControl.selectOptions &&
      summaryControl.selectOptions[summaryControl.value]
        ? summaryControl.selectOptions[summaryControl.value]
        : summaryControl && summaryControl.value
        ? (summaryControl?.value | humanizeBoolean)
        : ''
    }}</span
  >
</div>
<ng-template #noData>
  <span class="card-details__no-value__text">{{
    summaryControl.noValueInfo || '-'
  }}</span>
</ng-template>
